export default {
  fieldName: 'trade_type_id',
  getSuggestion: async ({ $store, results }) => {
    const id = results.stage_id?.all[0]?.object?.trade_type_id ?? null
    return {
      best: id,
      all: id
        ? [
            {
              object: {
                trade_type_id: id
              },
              rank: 1,
              score: 1
            }
          ]
        : []
    }
  }
}
