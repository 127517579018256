<template>
  <div class="quote-pres--heading-outer w-full">
    <div
      v-if="currentScreen.showHeading"
      class="bg-flame-white grid grid-cols-1 md:grid-cols-2 w-full h-full min-h-80"
      :key="`${presentationTemplateId}${currentScreen.styleVariables}`"
    >
      <!--   Logo container   -->
      <div
        class="relative w-full h-full col-1 p-6 md:p-10 flex flex-col justify-between min-h-80"
        :style="headingStyle"
      >
        <presentation-alter
          v-if="editable && editingLocal"
          icon="ban"
          text="Hide heading"
          @click="toggleHeading"
        />

        <!--   Logo   -->
        <div class="text-4xl font-bold max-w-[50%] w-fit">
          <presentation-alter
            v-if="
              editable &&
              editingLocal &&
              !currentScreen.showLogo &&
              $store.state.session.company.company_logo_file_id
            "
            icon="image"
            text="Show logo"
            @click="toggleLogo"
          />
          <presentation-alter
            v-else-if="editable && editingLocal"
            icon="image"
            :text="`${currentScreen.logoFileId ? 'Change' : 'Add'} logo`"
            @click="changeLogo"
          />
          <template v-if="currentScreen.showLogo && currentScreen.logoFileId">
            <img
              class="quote-pres--logo bg-flame-white/30 p-2 rounded w-10/12 xl:w-full max-w-80 max-h-44 object-contain"
              :src="logo"
              alt="Company Logo"
            />
          </template>
          <template v-else>
            <span class="bg-flame-white/30 p-2 rounded">{{
              $store.state.session.company.company_name_short
            }}</span>
          </template>
        </div>

        <!--    Credentials    -->
        <div
          class="flex flex-wrap w-fit bg-flame-white/80 text-pitch-black rounded shadow py-1 px-2"
          v-if="props.length && props[0] && props[0][0]"
        >
          <div v-for="(prop, index) in visibleProps" :key="index" class="flex items-center px-2">
            <font-awesome-icon icon="badge-check" class="text-lg" />
            <div class="flex flex-col ml-2">
              <span class="text-sm font-medium" v-if="prop[0]">{{ prop[0] }}</span>
              <span
                class="text-base text-pitch-back leading-none font-light"
                v-if="prop[0] && prop[1]"
                >{{ prop[1] }}</span
              >
            </div>
            <div
              class="border-r-2 border-flame-white w-1 h-3/4 ml-3"
              v-if="index !== visibleProps.length - 1"
            />
          </div>
        </div>
      </div>

      <!--   Project details container   -->
      <div
        class="w-full h-full relative flex flex-col flex-wrap md:flex-row gap-3 col-1 p-6 md:p-10"
      >
        <presentation-alter
          v-if="editable && editingLocal"
          icon="ban"
          text="Hide project details"
          @click="toggleScreenValue('showProjectDetails')"
        />
        <template v-if="currentScreen.showProjectDetails">
          <!--     Project information     -->
          <div class="min-w-80 w-fit flex flex-col gap-3">
            <div class="flex flex-col">
              <span class="text-sm !text-surface-500 mb-0.5">
                {{ l('Client name') }}
              </span>
              <span class="text-lg text-pitch-back">
                <template v-if="client.company_name">Attn: </template>
                {{ $f.capitalize(client.user_fname) }} {{ $f.capitalize(client.user_lname) }}
              </span>
            </div>
            <div class="flex flex-col">
              <span class="text-sm !text-surface-500 mb-0.5">
                {{ l('Project') }}
              </span>
              <span class="text-lg text-pitch-back leading-tight">
                {{ quote_name }}
              </span>
              <span class="text-lg text-pitch-back leading-tight">
                {{ quote_suite ? `${quote_suite} ` : '' }}
                {{ quote_address ? `${quote_address} ` : '' }}
              </span>
              <span class="text-lg text-pitch-back leading-tight">
                {{ quote_city ? `${quote_city} ` : '' }}
                {{ quote_prov ? `${quote_prov} ` : '' }}
                {{ quote_postal ? `${quote_postal} ` : '' }}
              </span>
            </div>
            <div class="flex flex-col">
              <span class="text-sm !text-surface-500 mb-0.5">
                {{ l('Estimate') }}
              </span>
              <span class="text-lg text-pitch-back">
                {{ $f.date(change_order_time_sent || quote_time_last_modified) }}
              </span>
            </div>
            <div
              class="!relative flex flex-col gap-1"
              :class="{ 'large-presenter': showLargeProfilePic }"
            >
              <presentation-alter
                v-if="editable && editingLocal"
                icon="ban"
                text="Hide presenter"
                @click="togglePresenter"
              />
              <span class="text-sm !text-surface-500 mt-1">
                {{ l('Presented by') }}
              </span>
              <div class="flex items-center gap-2">
                <div class="quote-pres--presenter-avatar max-h-8">
                  <Avatar
                    :image="userPic"
                    shape="circle"
                    size="normal"
                    class="border border-cool-gray-300"
                  />
                  <file-list v-show="false" ref="files" v-model="picFile" />
                </div>
                <div class="quote-pres--presenter-name flex gap-1">
                  <span class="text-lg text-pitch-back">{{ owner.user_fname }}</span>
                  <span class="text-lg text-pitch-back">{{ owner.user_lname }}</span>
                </div>
              </div>
            </div>
          </div>

          <!--    Expiration and contact      -->
          <div
            class="flex flex-col w-full md:w-fit md:items-end gap-3"
            v-if="currentScreen.showPresenter"
          >
            <div v-if="quote_time_expired > 0" class="flex flex-col w-full justify-start">
              <span class="text-sm !text-surface-500 mb-0.5">
                {{ l('Expiration date') }}
              </span>
              <span class="text-lg text-pitch-back">
                {{ $f.date(quote_time_expired) }}
              </span>
            </div>
            <div v-if="currentScreen.showContact" class="relative flex flex-col gap-3">
              <presentation-alter
                v-if="editable && editingLocal"
                icon="ban"
                text="Hide contact"
                @click="toggleContact"
              />
              <div
                class="flex items-center gap-2 cursor-pointer hover:bg-surface-100 p-2 w-fit rounded-sm"
                @click="showChat"
              >
                <font-awesome-icon icon="messages" fixed-width class="text-sm" />
                <span class="text-base">{{ l('Chat with us') }}</span>
              </div>
              <div
                class="flex items-center gap-2 cursor-pointer hover:bg-surface-100 p-2 w-fit rounded-sm"
                @click="openCompanyProfile"
                v-if="$store.state.session.company.company_id"
              >
                <font-awesome-icon icon="shop" fixed-width class="text-sm" />
                <span class="text-base">{{ l('Company profile') }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'primevue/avatar'
import PresentationAlter from './PresentationAlter.vue'
import PresentationMixin from './PresentationMixin'
import ObjectDistinct from '../../mixins/ObjectDistinct'
import TranslationMixin from './languages/TranslationMixin'

export default {
  name: 'PresentationHeading',
  mixins: [ObjectDistinct('quote'), PresentationMixin, TranslationMixin],
  components: {
    PresentationAlter,
    Avatar
  },
  data() {
    return {
      showSummary: 0
    }
  },
  computed: {
    headingStyle() {
      let style = {}
      let url = this.backgroundUrlFinal

      url = url && /^url\(/i.test(url) ? url : `url(${url})`

      if (url) {
        style.background = `${url} !important`
        style.backgroundSize = 'cover !important'
        style.backgroundRepeat = 'no-repeat !important'
        style.backgroundPosition = 'center center !important'
      }

      return style
    },
    visibleProps() {
      return this.props.filter((prop) => prop[0])
    }
  },
  methods: {
    showChat() {
      return this.$store.commit({
        type: 'SET_PREVIEW',
        preview: {
          channel_type: 'QUOTE_CLIENT',
          channel_type_id: `${this.quote_id}-${this.client_user_id}`
        }
      })
    },
    openCompanyProfile() {
      const companyId = this.$store.state.session.company.company_id
      this.$store.dispatch('to', `/pub/company/${companyId}`)
    }
  },
  props: {
    contentWidth: {
      type: String,
      default: '1200px'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import '@/theme/presentations/colors.scss';
@import '@/theme/presentations/mixins.scss';
@import '@/theme/presentations/variables/quote.scss';
@import '@/theme/presentations/structure/quote.scss';
</style>
