<template>
  <div
    :class="`card-list-item list-group-item container-element rounded ${seamless ? 'py-1 px-0' : 'py-4 px-6 first:pt-6 last:pb-6'}`"
    @click="() => emit('click')"
    @touchstart="() => emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
const emit = defineEmits(['click'])
defineProps({
  seamless: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss" rel="stylesheet/scss">
.list-group-item.card-list-item,
.list-group-item.card-list-field {
  overflow: hidden;
  line-height: 1.1;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  contain: paint layout;
  &.center {
    text-align: center;
  }
  &.item-row {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  p:not([class]) {
    margin-bottom: 1em;
  }
  ul:not([class]) {
    margin-top: 1em;
  }
  li:not([class]) {
    margin-bottom: 0.5em;
    line-height: 1.2;
  }

  $highlightBorderSize: 4px;

  &.highlight {
    border-bottom: $highlightBorderSize solid rgba($purple-500, 0.8);

    &.highlight-primary {
      border-bottom: $highlightBorderSize solid rgba($primary, 0.8);
    }
    &.highlight-dark {
      border-bottom: $highlightBorderSize solid rgba($cool-gray-600, 0.8);
    }
    &.highlight-light {
      border-bottom: $highlightBorderSize solid rgba($flame-white, 0.8);
    }
    &.highlight-danger {
      border-bottom: $highlightBorderSize solid rgba($deep-red-800, 0.8);
    }
    &.highlight-warning {
      border-bottom: $highlightBorderSize solid rgba($level-yellow, 0.8);
    }
    &.highlight-info {
      border-bottom: $highlightBorderSize solid rgba($blue-print-700, 0.8);
    }
    &.highlight-success {
      border-bottom: $highlightBorderSize solid rgba($matcha-500, 0.8);
    }
    &.highlight-secondary {
      border-bottom: $highlightBorderSize solid rgba(var(--secondary), 0.8);
    }
  }

  &.primary {
    background-color: $primary !important;
    color: $primary-300;
    div.card-list-field > :first-child {
      color: $primary-300 !important;
    }
  }
  &.dark {
    background-color: $cool-gray-700 !important;
    color: $flame-white;
  }
  &.light,
  &.light {
    background-color: $flame-white !important;
    color: $pitch-black;
  }
  &.danger {
    background-color: $deep-red-800 !important;
    color: $flame-white;
  }
  &.warning {
    background-color: $level-yellow !important;
    color: $flame-white;
  }
  &.info {
    background-color: $blue-print-700 !important;
    color: $flame-white;
  }
  &.success {
    background-color: $matcha-500 !important;
    color: $flame-white;
  }
  &.secondary {
    background-color: var(--secondary) !important;
    color: var(--secondary-300) !important;
  }

  &.muted {
    background-color: $cool-gray-300 !important;
    color: $cool-gray-600 !important;
  }

  &.under {
    background-color: $cool-gray-200 !important;
    color: $cool-gray-700 !important;
    &:hover,
    &:active,
    &.focused {
      box-shadow: inset -5px 0px 0px -2px rgba($blue-print-700, 0.8) !important;
    }
  }

  &.under-first {
    background-color: $cool-gray-200 !important;
    color: $cool-gray-700 !important;
    box-shadow: inset 0px 20px 20px -20px rgba($cool-gray-600, 0.4);
    &:hover,
    &:active,
    &.focused {
      box-shadow:
        inset 0px 20px 20px -20px rgba($cool-gray-600, 0.4),
        inset -5px 0px 0px -2px rgba($blue-print-700, 0.8) !important;
    }
  }

  &.under-last {
    background-color: $cool-gray-200 !important;
    color: $cool-gray-700 !important;
    position: relative;
    display: flex;
    &:after {
      pointer-events: none;
      content: ' ';
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0px -20px 20px -20px rgba($cool-gray-600, 0.4) !important;
    }
    &:hover,
    &:active,
    &.focused {
      &:after {
        box-shadow:
          inset 0px -20px 20px -20px rgba($cool-gray-600, 0.4),
          inset -5px 0px 0px -2px rgba($blue-print-700, 0.8) !important;
      }
    }
  }
  &.under-under {
    background-color: $cool-gray-300 !important;
    color: $cool-gray-700 !important;
    &:hover,
    &:active,
    &.focused {
      box-shadow: inset -5px 0px 0px -2px rgba($blue-print-700, 0.8) !important;
    }
  }

  &.primary-light {
    background-color: rgba($primary, 0.3) !important;
    color: $primary-600;
  }
  &.dark-light {
    background-color: rgba($cool-gray-600, 0.3) !important;
    color: $cool-gray-700;
  }
  &.danger-light {
    background-color: rgba($deep-red-800, 0.3) !important;
    color: $deep-red-800;
  }
  &.warning-light {
    background-color: rgba($level-yellow, 0.3) !important;
    color: $level-yellow;
  }
  &.info-light {
    background-color: rgba($blue-print-400, 0.3) !important;
    color: $blue-print-700;
  }
  &.success-light {
    background-color: rgba($matcha-500, 0.3) !important;
    color: $green-600;
  }
  &.secondary-light {
    background-color: rgba(var(--secondary), 0.3) !important;
    color: var(--secondary-600);
  }
}
</style>
