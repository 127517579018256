<template>
  <div
    class="flex justify-end w-full delay-300"
    :class="approvedIds.length <= 0 ? '-bottom-24' : 'bottom-0 '"
  >
    <Btn
      class="mr-4"
      @click="cancelApproval"
      v-if="!hideClear"
      :link="true"
      :disabled="approvedIds.length === 0"
      severity="tertiary"
    >
      Clear
    </Btn>
    <Btn @click="onConfirm" :disabled="approvedIds.length === 0" severity="primary-black" size="xl">
      <slot>
        Approve {{ approvedIds.length > 0 ? `${approvedIds.length}` : '' }} item{{
          approvedIds.length > 1 ? 's' : ''
        }}
      </slot>
    </Btn>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import useApproval from '@/components/composables/Approval'

const props = defineProps({
  hideClear: {
    type: Boolean,
    default: true
  },
  confirmed: {
    type: Function
  }
})

const { approvedIds, approvalAllSelected } = useApproval()

const onConfirm = () => {
  approvalAllSelected()
  if (props.confirmed) props.confirmed()
}
</script>
