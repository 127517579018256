<template>
  <div class="sticky top-2 z-20 p-2" v-if="showDateDivider">
    <div class="flex items-center justify-center">
      <Chip
        :label="formatedTimeCreatedDate"
        class="border font-medium bg-white"
        :pt="{
          label: 'pt-1 pb-1 text-sm'
        }"
      />
    </div>
  </div>
  <div class="max-w-xl w-full" v-if="showNewMessageDivider">
    <Divider align="center">
      <p class="text-xs text-surface-400 px-4">NEW MESSAGE</p>
    </Divider>
  </div>
  <div
    ref="activityItem"
    :id="`activity-${activityId}`"
    class="activity-channel-item w-full max-w-xl flex relative"
    @onMentionClick="(e) => onMentionClick(e.detail.mention_type, e.detail.mention_type_id)"
  >
    <ActivityMessageItem
      v-if="activityBaseType === 'MESSAGE'"
      @onReply="onReply"
      @onEdit="onEdit"
      @onDelete="onDelete"
      :activity="activity"
      :prevActivity="prevActivity"
    />
    <ActivityActionItem
      v-if="activityBaseType === 'ACTION'"
      @onReply="onReply"
      :activity="activity"
      :prevActivity="prevActivity"
    />
    <ActivityVersionItem
      v-if="activityBaseType === 'VERSION'"
      @onReply="onReply"
      :activity="activity"
      :prevActivity="prevActivity"
    />
  </div>
</template>

<script setup>
import Chip from 'primevue/chip'
import Divider from 'primevue/divider'
import { computed, onMounted, ref, watch } from 'vue'
import ActivityMessageItem from '@/components/Activities/ActivityItemType/ActivityMessageItem.vue'
import ActivityActionItem from '@/components/Activities/ActivityItemType/ActivityActionItem.vue'
import ActivityVersionItem from '@/components/Activities/ActivityItemType/ActivityVersionItem.vue'
import useActivityItem from '@/components/composables/ActivityItem'

// ======== Props ======== //
const props = defineProps({
  activity: {
    type: Object,
    required: true
  },
  prevActivity: {
    type: Object,
    required: true
  },
  channelId: {
    type: String,
    required: true
  },
  parentIntersectionObserver: {
    type: Object,
    default: null
  }
})
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['onReply', 'onEdit', 'onDelete'])
// ====================== //

// ======== Refs ======== //
const activityItem = ref(null)
// ====================== //

// ======== Composables ======== //
const {
  activityId,
  activityTimeCreated,
  prevActivityTimeCreated,
  formatedTimeCreatedDate,
  activityBaseType,
  hasSeenActivity,
  hasSeenPrevActivity,
  hasNotification,
  onMentionClick
} = useActivityItem(props)
// ====================== //

// ======== Computed ======== //
const showDateDivider = computed(() => {
  if (!props.prevActivity) return true
  const currentDate = new Date(activityTimeCreated.value * 1000)
  const nextDate = new Date(prevActivityTimeCreated.value * 1000)

  return (
    currentDate.getUTCMonth() !== nextDate.getUTCMonth() ||
    currentDate.getUTCDay() !== nextDate.getUTCDay()
  )
})
const showNewMessageDivider = computed(() => hasSeenPrevActivity.value && !hasSeenActivity.value)
// ====================== //

// ======== Methods ======== //
const initIntersectionObserver = (observer) => {
  if (!(hasSeenActivity.value && !hasNotification.value) && !props.activity.temp) {
    observer.observe(activityItem.value)
  }
}
const onReply = () => {
  emit('onReply')
}
const onEdit = () => {
  emit('onEdit')
}
const onDelete = () => {
  emit('onDelete')
}
// ====================== //

// ======== Watchers ======== //
watch(
  () => props.parentIntersectionObserver,
  () => {
    initIntersectionObserver(props.parentIntersectionObserver)
  }
)
watch(hasNotification, (value) => {
  if (value && props.parentIntersectionObserver) {
    initIntersectionObserver(props.parentIntersectionObserver)
  }
})
// ====================== //

// ======== LifeCycle ======== //
onMounted(() => {
  // attach activity item to parent intersection observer if not seen //
  if (props.parentIntersectionObserver) {
    initIntersectionObserver(props.parentIntersectionObserver)
  }
})
// ====================== //
</script>

<style lang="scss" scoped></style>
