<template>
  <div v-if="showProperties" class="pl-1">
    <ul class="list-disc pl-4">
      <li
        class="text-base font-light text-surface-700"
        v-for="(attribute, key) in propsList"
        :key="key"
      >
        <Linkify :content="attribute[0]" /> <Linkify :content="attribute[1]" />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Linkify from '../../ui/Linkify.vue'

const props = defineProps({
  object: {
    required: true,
    type: Object
  }
})

const propsList = computed(() => {
  return (props.object.aoProperties || [])
    .filter(
      (attribute) =>
        attribute && Array.isArray(attribute) && typeof attribute[0] === 'string' && attribute[0]
    )
    .map((attribute) => [
      attribute.length > 1 && attribute[1] ? `${attribute[0] || ''}:` : `${attribute[0] || ''}`,
      attribute.length > 1 && attribute[1] ? `${attribute[1] || ''}` : ''
    ])
})

const showProperties = computed(() => {
  return (
    props.object.aoProperties &&
    props.object.aoProperties.length &&
    props.object.aoProperties[0] &&
    props.object.aoProperties[0][0] &&
    props.object.aoProperties[0][0].length
  )
})
</script>
