<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['selected'])
const $store = useStore()

const props = defineProps({
  /**
   * Object/entity type
   * Defaults to the generated type
   */
  type: {
    required: true
  },

  /**
   * Object/entity type
   * Defaults to the generated type
   */
  store: {
    required: false
  },

  /**
   * This is the prop that will set the refId variable
   *
   * ObjectManipulator components don't usually
   *   take a refId, it is sorted out after the
   *   fetching is done etc.  If that is done
   *   externally however, you can pass a reference
   *   which is the refId
   */
  refId: {
    required: false,
    default: null
  },

  /**
   * Provide the entity id for the object to manipulate here, or provide
   * the whole object in object below
   */
  id: {
    required: false,
    default: null
  },

  /**
   * Provide the object to manipulate here, or provide the id above
   */
  object: {
    type: Object,
    required: false,
    default: null
  },

  forceBlank: {
    type: Boolean,
    default: false
  },

  /**
   * Require a full object, cannot be fetch{fast:true} or remnants
   */
  forceFull: {
    type: Boolean,
    default: true
  },

  /**
   * Require a full re-fetch every time, even if a full one exists in store
   */
  forceFetch: {
    type: Boolean,
    default: false
  }
})

const refId = ref(props.refId)
const id = ref(props.id)
const type = computed(() => props.type)
const store = computed(() => props.store ?? c.titleCase(type.value))

const selected = ref(false)
const error = ref(false)

const select = async () => {
  if (selected.value) {
    return { refId: refId.value }
  }

  // If an object is provided to load from, that is either full=true
  // or not required to be full, and that we aren't force to fetch
  // on each reload, then go ahead and fillFetch the object
  // to avoid a server call.
  if (
    !props.forceBlank &&
    id.value &&
    Object.keys(props.object ?? {}).length &&
    (props.object.full || !props.forceFull) &&
    // If we already have it, use that
    !(String(id.value) in $store.state[store.value].all) &&
    !props.forceFetch &&
    !refId.value
  ) {
    console.log('fill fetch?')
    ;({ id: id.value } = await $store.dispatch(`${store.value}/fillFetch`, {
      object: props.object
    }))
  }

  // If we required to fetch, or object not full and we require a full,
  // or if only id was provided, we do a select existing, and send forceFull
  // and forceFetch flags so it knows what it needs to do
  // If it is already normalized and selected, then use the normalized one
  // which selectExisting will do automatically.
  // If neither id nor reference nor object are provided,
  // then build out and select a totally blank object of this 'type.value'
  if (!refId.value) {
    console.log('fetching?', !props.forceBlank && id.value)
    ;({ refId: refId.value } =
      !props.forceBlank && id.value
        ? await $store.dispatch(`${store.value}/selectExisting`, {
            id: id.value,
            type: type.value,
            force: props.forceFetch,
            full: props.forceFull,
            filters: props.filters,
            override: false
          })
        : await $store.dispatch(`${store.value}/selectBlank`, {
            object: props.object,
            type: type.value
          }))
    console.log('got refid', refId.value)
  }

  selected.value = refId.value

  emit('selected', {
    refId: refId.value,
    store: store.value
  })

  if (!refId.value) error.value = true
}
onMounted(async () => select())
</script>

<template>
  <slot v-if="refId" :refId="refId" :store="store" :type="type" :key="refId"></slot>
  <div v-else-if="!error" class="w-full h-full flex flex-col justify-start items-stretch">
    <div class="flex items-stretch justify-stretch gap-2">
      <Skeleton shape="circle" width="100" height="100" class="grow-0" />
      <Skeleton shape="rectangle" height="100" class="grow-1" />
    </div>
    <div class="flex items-stretch justify-stretch gap-2">
      <Skeleton shape="circle" width="100" height="100" class="grow-0" />
      <Skeleton shape="rectangle" height="100" class="grow-1" />
    </div>
  </div>
  <div v-else>There was an error loading this item. Please try again.</div>
</template>
