<script setup>
import { ref, computed, defineExpose, inject } from 'vue'
import EntityList from '@/components/Sheets/quote/list/EntityList.vue'
import { useStore } from 'vuex'
import CostType from '@/components/bodies/CostType.vue'

CostType.compatConfig = { MODE: 3 }
import Header from '@/components/bodies/CostItem/Header.vue'
import ChangeTracker from '@/components/bodies/UtilityComponents/ChangeTracker.vue'
import Assembly from '@/components/bodies/Assembly.vue'
import AutoCost from '../../../../../imports/api/AutoCost'

const props = defineProps({
  rowProcessor: { type: Function, required: true },
  variations: { type: Object, required: true },
  orderedVtypes: { type: Array, required: true },
  target: { type: Object, required: true },
  changeHandler: { type: Object, required: true },
  mapVariationItemToCostType: { type: Object, required: true }
})

const emit = defineEmits(['change', 'changes'])

const variations = computed(() => props.variations)
const orderedVtypes = computed(() => props.orderedVtypes)

const getPermutationKey = inject('getPermutationKey')

const permutationKeyAttributions = computed(
  () =>
    variations.value?.items?.reduce((acc, item) => {
      const pkey = getPermutationKey(item.variations)
      return {
        ...acc,
        [pkey]: [...(acc[pkey] ?? []), item.uid]
      }
    }, {}) ?? {}
)

const uidsWithDuplicatedCombos = computed(() =>
  Object.values(permutationKeyAttributions.value || {}).reduce(
    (acc, ids) => [...acc, ...(ids.length > 1 ? ids : [])],
    []
  )
)

const rowsWithDuplicateCombos = computed(() =>
  uidsWithDuplicatedCombos.value
    ?.map((uid) => refEntityList.value?.refSheet?.getRowFromId(uid))
    .sort()
)
const collapsedRowsWithDuplicateCombos = computed(() =>
  rowsWithDuplicateCombos.value.reduce(
    (acc, row) =>
      acc[acc.length - 1]?.[1] === row - 1
        ? [...acc.slice(0, acc.length - 2), [acc[acc.length - 1][0], row]]
        : [...acc, [row, row]],
    []
  )
)

const $store = useStore()

const refEntityList = ref(null)

const editingItem = ref(null)
const refModal = ref(null)

const loading = ref(0)

const itemEditorProps = ref(null)

const modalChanges = ref({})
const modalChangesHandler = (changes) => {
  modalChanges.value = changes.allRaw?.[itemEditorProps.value.refId] ?? {}
}
const editItem = async (uid) => {
  loading.value = 1

  const originalItem = refEntityList.value.items[uid] // should be full item
  // const costTypeId = originalItem.cost_type_id
  const store = _.titleCase(originalItem.type)

  const object = {
    ...originalItem,
    ...(refEntityList.value?.fullChanges?.[uid] ?? {})
  }

  editingItem.value = uid
  const { refId } = await $store.dispatch(`${store}/selectBlank`, {
    object: object,
    type: originalItem.type
  })

  itemEditorProps.value = { store, type: originalItem.type, refId }

  setTimeout(() => refModal.value.open())

  setTimeout(() => {
    loading.value = 0
  })
}

const handleEditorCancel = () => {
  refModal.value.close()
}
const handleEditorSave = async () => {
  // Grab item, denormalize it
  const { store, refId } = itemEditorProps.value
  c.throttle(
    async () => {
      refEntityList.value.setItemFields({ [editingItem.value]: modalChanges.value })
      editingItem.value = null
      $store.dispatch(`${store}/deselect`, { refId })
    },
    { delay: 400 }
  )
}

const columns = computed(() => [
  {
    field: 'name',
    mapField: ({ type }) => (type === 'assembly' ? 'assembly_name' : 'cost_type_name'),

    formatting: {
      width: 250,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    }
  },

  {
    field: 'metaType',
    title: ' ',
    action: (cellValue, rowData) => {
      if (rowData.livePriceRef) {
        return $store.dispatch('alert', {
          error: true,
          message: 'Cannot modify live price items'
        })
      }
      editItem(rowData.id, rowData.type)
    },
    actionText: '',
    tooltip: 'Edit...',
    formatting: {
      width: 40,
      align: 'center',
      borders: {
        right: {
          thickness: 1
        },
        left: {
          thickness: 1
        }
      },
      preventDefaultDraw: true,
      draw: ({ ctx, clipTo, text: metaType, drawIcon, lightGrayTrans, getRowData }) => {
        let icon = 'cube'
        let color = lightGrayTrans
        const { id, livePriceRef } = getRowData()
        const obj = { ...refEntityList.value.items[id] }
        let iconType = livePriceRef ? 'AutoCost' : metaType || 'cube'
        if (obj.type === 'assembly') {
          iconType = 'cubes'
        } else if (
          obj.cost_type_is_parent &&
          refEntityList.value.refSheet.collapsedGroups.includes(id)
        ) {
          iconType = 'folder'
        } else if (
          obj.cost_type_is_parent &&
          !refEntityList.value.refSheet.collapsedGroups.includes(id)
        ) {
          iconType = 'folder-open'
        } else if (obj.cost_type_is_fee) {
          iconType = 'Fee'
        } else if (obj.cost_type_is_variation_parent || obj.cost_type_is_addon_group) {
          iconType = 'Variation'
        }
        switch (iconType) {
          case 'Fee':
            icon = 'percent'
            break
          case 'Variation':
            icon = 'swatchbook'
            break
          case 'Assembly':
            icon = 'cubes'
            break
          case 'AutoCost':
            icon = 'circle-dollar'
            break
          case 'Task':
            icon = 'check-square'
            break
          case 'Cost Item':
            icon = 'cube'
            break
          case 'Gallery':
            icon = 'images'
            break
          case 'Text':
            icon = 'align-left'
            break
          default:
            icon = iconType
            break
        }
        // }

        drawIcon(ctx, clipTo, icon, color)
        return ctx
      }
    }
  },

  ...(orderedVtypes.value?.map((vtype) => ({
    title: vtype,
    field: `vtype.${vtype}`,
    conditionalFormatting: (value, cell, { id }) => {
      if (!uidsWithDuplicatedCombos.value.includes(id)) {
        return {}
      }

      const dr = c.getCssColor('deep-red-500')

      return {
        background: c.hexWithOpacity(c.rgbToHex(dr), 0.2),

        borders: {
          left: {
            thickness: 2,
            color: dr
          },
          right: {
            thickness: 2,
            color: dr
          },
          bottom: {
            thickness: 2,
            color: dr
          },
          top: {
            thickness: 2,
            color: dr
          }
        }
      }
    },
    formatting: {
      width: 80,
      align: 'left',
      borders: {
        left: {
          thickness: 2,
          color: '#222222'
        },
        right: {
          thickness: 2,
          color: '#222222'
        },
        bottom: {
          thickness: 2,
          color: '#222222'
        },
        top: {
          thickness: 2,
          color: '#222222'
        }
      },
      draw: ({ ctx, text: trait, lightGrayTrans, drawText }) => {
        if (!trait)
          drawText(ctx, [2, 1, 40, 40], 'None', {
            color: lightGrayTrans,
            fontSize: 14
          })
      }
    }
  })) || []),

  {
    title: 'Desc',
    tooltip: 'Sales description',
    formatting: {
      wordWrap: true,
      width: 40,
      align: 'left',
      verticalAlign: 'top',
      preventDefaultDraw: true,
      draw: ({ ctx, clipTo, text: desc, drawIcon, blueTrans, drawText }) => {
        if (!desc) {
          // drawIcon(ctx, clipTo, 'input-text', lightGrayTrans);
          return ctx
        }

        drawIcon(ctx, clipTo, 'input-text', blueTrans)

        drawText(ctx, [41, 0, 400, 40], desc, {
          fontSize: 14,
          align: 'left',
          verticalAlign: 'top'
        })
      }
    },
    field: 'desc',
    mapField: ({ type }) => (type === 'assembly' ? 'quote_notes' : 'cost_type_desc')
  },
  {
    title: 'Pics',
    field: 'file_ids',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },

  {
    superHeader: 'Production details',
    title: 'Construction stage',
    field: 'stage_id',
    mapTo: 'stage',
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Prod',
    mapField: ({ type }) =>
      type === 'assembly' ? 'quote_production_notes' : 'cost_type_production_notes',
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent,
    tooltip: 'Production notes',
    formatting: {
      wordWrap: true,
      width: 40,
      align: 'left',
      verticalAlign: 'top',
      preventDefaultDraw: true,
      draw: ({ ctx, clipTo, text: desc, drawIcon, blueTrans, drawText }) => {
        if (!desc) {
          // drawIcon(ctx, clipTo, 'input-text', lightGrayTrans);
          return ctx
        }

        drawIcon(ctx, clipTo, 'input-text', blueTrans)

        drawText(ctx, [41, 0, 400, 40], desc, {
          fontSize: 14,
          align: 'left',
          verticalAlign: 'top'
        })
      }
    }
  },

  {
    title: 'Units',
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      width: 80,
      bold: true,
      format: (...args) => `${args[2].type === 'assembly' ? 'each' : `${args[3]}`}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent,
    readOnly: ({ type }) => type === 'assembly'
  },

  {
    superHeader: 'Materials costs',
    title: 'Materials',
    field: 'cost_type_has_materials',
    icon: 'box-taped',
    background: c.getCssColor('materials').replace(/\)$/, ',0.2)'),
    color: c.getCssColor('materials'),
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Default supplier',
    field: 'vendor_id',
    mapTo: 'vendor',
    formatting: {
      width: 150,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    title: 'Purchase format',
    field: 'purchase_unit_of_measure_id',
    choose: {
      order: [['unit_of_measure_name', 'asc']]
    },
    mapTo: 'cost_type',
    formatting: {
      width: 150,
      format: (...args) => `Bought by the ${args[3]}`,
      deformat: (value) => value
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 3,
    superHeader: 'Materials costs',
    title: 'Coverage per purchase unit',
    field: 'cost_type_materials_purchase_qty_per_unit',
    formatting: { width: 80, bold: true, borders: { right: { thickness: 0 } } },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      width: 50,
      bold: true,
      borders: { left: { thickness: 0 }, right: { thickness: 0 } }
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    superHeader: 'Materials costs',
    title: 'Purchase unit (box etc)',
    field: 'purchase_unit_of_measure_id',
    choose: {
      order: [['unit_of_measure_name', 'asc']]
    },
    mapTo: 'cost_type',
    formatting: {
      width: 100,
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3]}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    superHeader: 'Materials costs per unit',
    title: 'Purchase cost',
    field: 'cost_type_materials_purchase_price_net',
    formatting: {
      width: 80,
      borders: { right: { thickness: 0 } }
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    superHeader: 'Materials costs',
    title: 'Purchase unit (box etc)',
    field: 'purchase_unit_of_measure_id',
    choose: {
      order: [['unit_of_measure_name', 'asc']]
    },
    mapTo: 'cost_type',
    formatting: {
      width: 100,
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3]}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    superHeader: 'Materials costs per unit',
    title: 'Unit materials cost',
    field: 'cost_matrix_materials_cost_net',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } },
      format: 'currency'
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3]}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },

  {
    superHeader: 'Materials costs',
    title: 'Waste factor %',
    field: 'cost_type_material_waste_factor_net',
    get: (entityValue) => entityValue * 100,
    set: (tableValue) => tableValue / 100,
    formatting: {
      format: (v) => `${c.format(Math.round(v), 'currency', 0)}%`,
      deformat: (v) => `${c.format(c.toNum(v, 20, true), 'currency', 20)}`
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },

  {
    title: 'Labor',
    field: 'cost_type_has_labor',
    icon: 'user-helmet-safety',
    background: c.getCssColor('labor').replace(/\)$/, ',0.2)'),
    color: c.getCssColor('labor'),
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Labor type',
    field: 'labor_type_id',
    mapTo: 'labor_type',
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    title: 'Hourly labor cost',
    field: 'labor_type_rate_net',
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    title: 'Man-hours per unit',
    field: 'cost_type_hours_per_unit',
    format: 'hours',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } }
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3]}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    title: 'Unit labor costs',
    field: 'cost_matrix_labor_cost_net',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } },
      format: 'currency'
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3]}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    title: 'Sub',
    field: 'cost_type_is_subcontracted',
    icon: 'truck',
    background: c.getCssColor('subcontractor').replace(/\)$/, ',0.2)'),
    color: c.getCssColor('subcontractor'),
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Default sub',
    field: 'vendor_id',
    mapTo: 'vendor',
    formatting: {
      width: 150,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    },
    disabled: ({ type, cost_type_is_parent, cost_type_is_subcontracted }) =>
      type === 'assembly' || cost_type_is_parent || !cost_type_is_subcontracted
  },

  {
    title: 'Subcontractor costs',
    titleColSpan: 2,
    field: 'subcosts',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } },
      format: 'currency'
    },
    mapField: ({ type }) =>
      type === 'assembly' ? 'quote_materials_cost_net' : 'cost_matrix_materials_cost_net',
    disabled: ({ type, cost_type_is_parent, cost_type_is_subcontracted }) =>
      type === 'assembly' || cost_type_is_parent || !cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3]}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ type, cost_type_is_parent, cost_type_is_subcontracted }) =>
      type === 'assembly' || cost_type_is_parent || !cost_type_is_subcontracted
  },
  {
    superHeader: 'Totals',
    title: 'Your unit costs',
    mapField: ({ type }) =>
      type === 'assembly' ? 'quote_total_cost_net' : 'cost_matrix_aggregate_cost_net',
    readOnly: ({ type }) => type === 'assembly',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },
  {
    superHeader: 'Totals',
    title: 'Profit %',
    mapField: ({ type }) => (type === 'assembly' ? 'quote_markup_net' : 'cost_matrix_markup_net'),
    get: (entityValue) => c.markupToMargin(entityValue) * 100,
    set: (tableValue) => c.marginToMarkup(tableValue / 100),
    formatting: {
      // Since it is always whole percentage numbers, no value transformation required here
      format: (v) => `${c.format(v, 'currency', 2)}%`,
      deformat: (v) => `${c.format(c.toNum(v, 20, true), 'currency', 20)}`
    },
    readOnly: ({ type }) => type === 'assembly',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },
  {
    superHeader: 'Totals',
    title: 'Client unit price',
    mapField: ({ type }) => (type === 'assembly' ? 'quote_subtotal_net' : 'cost_matrix_rate_net'),
    readOnly: ({ type }) => type === 'assembly',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },
  {
    idField: true,
    field: 'uid',
    hidden: true
  },
  {
    field: 'cost_type_id',
    mapField: ({ type }) => (type === 'assembly' ? 'assembly_id' : 'cost_type_id'),
    hidden: true
  },
  {
    field: 'parentId',
    mapField: () => 'parent_cost_type_id',
    hidden: true
  },
  { field: 'childrenIds', hidden: true },
  { field: 'cost_type_is_parent', hidden: true },
  { field: 'cost_type_is_variation_parent', hidden: true },
  { field: 'variation_parent_cost_type_id', hidden: true },
  { field: 'type', hidden: true },
  { field: 'livePriceRef', hidden: true }
])

const superHeaders = computed(() => {
  const push = orderedVtypes.value?.length
  return [
    {
      title: 'Variation traits',
      icon: 'swatchbook',
      span: [2, orderedVtypes.value?.length + 1],
      expanded: 1
    },
    {
      title: 'Materials costing',
      icon: 'box-taped',
      span: [7 + push, 17 + push],
      formatting: {
        background: c.getCssColor('materials').replace(/\)$/, ',0.2)')
      },
      expanded: false
    },
    {
      title: 'Labor costing',
      icon: 'user-helmet-safety',
      span: [18 + push, 24 + push],
      formatting: {
        background: c.getCssColor('labor').replace(/\)$/, ',0.2)')
      },
      expanded: false
    },
    {
      title: 'Subcontractor costing',
      icon: 'truck',
      span: [25 + push, 28 + push],
      formatting: {
        background: c.getCssColor('subcontractor').replace(/\)$/, ',0.2)')
      },
      expanded: false
    }
  ]
})

const collapseGroups = {
  sortField: 'location',
  rootId: null,
  isParent: ({ cost_type_is_parent, cost_type_is_variation_parent }) =>
    !!(cost_type_is_parent || cost_type_is_variation_parent),
  getParent: ({ parentId, variation_parent_cost_type_id }) =>
    variation_parent_cost_type_id || parentId || null,
  isExpanded: () => false,
  parentFormatting: {
    preset: 'heading',
    bold: true
  }
}

// Filters get set only on mount. After mount, items must be added or removed manually
const savedItemsIds = variations.value?.items?.map((it) => it.id).filter((id) => !!id) ?? []
const filters = {
  cost_type_id: savedItemsIds?.length ? savedItemsIds.join('||') : 'do not search'
}

const restrictFields = [
  ...Object.keys(c.getConstructor('cost_type').getComputedDependants()),
  ...columns.value.reduce(
    (acc, col) => [
      ...acc,
      ...(col.field ? [col.field] : []),
      ...(col.mapField
        ? [col.mapField({ type: 'assembly' }), col.mapField({ type: 'cost_type' })]
        : [])
    ],
    []
  )
]

const isDirtyHandler = (isDirty) => emit('isDirty', isDirty)
const save = () => refEntityList.value?.save?.()

const itemsByUid = inject('itemsByUid')

const addPermutationChoice = inject('addPermutationChoice')
const create = async ({
  duplicating = false,
  placeholderRow,
  addedFrom: { rowIndex, item }
} = {}) => {
  const dup = duplicating
    ? {
        ...itemsByUid.value[item.id],
        id: undefined,
        [`${itemsByUid.value[item.id]}_id`]: undefined
      }
    : null
  addPermutationChoice(rowIndex + 1, dup, placeholderRow)
}

const fetchRows = async () => {
  // for autocost items //
  const normalized = $store.state.Quote.normalized
  const rootRefId = c.getNormalizedRootRefId(normalized)
  const quote = normalized[rootRefId]
  const company = $store.state.session.company
  const zipcode = AutoCost.getAutoCostZipcode(company, quote)

  const items = variations.value?.items || []

  const livePriceItems = await Promise.all(
    items
      .filter((item) => item.livePriceRef)
      .map(async (item) => {
        const res = await $store.dispatch('CostType/getDefaultLivePriceItem', {
          livePriceRef: item.livePriceRef,
          company,
          zipcode
        })
        return {
          ...res,
          uid: item.uid,
          livePriceRef: item.livePriceRef
        }
      })
  )
  const { set: costTypeItems } = await $store.dispatch(`CostType/search`, {
    filters
  })

  const costTypeObjects = costTypeItems.reduce(
    (acc, costTypeItem) => ({ ...acc, [costTypeItem.cost_type_id]: costTypeItem }),
    {}
  )
  const livePriceObjects = livePriceItems.reduce(
    (acc, livePriceItem) => ({ ...acc, [livePriceItem.uid]: livePriceItem }),
    {}
  )

  return items.map((item) => {
    if (item.livePriceRef) return livePriceObjects[item.uid]
    if (item.id)
      return {
        ...costTypeObjects[item.id],
        uid: item.uid
      }
    return {}
  })
}

const itemActions = [
  {
    name: 'Delete',
    icon: 'trash',
    action: (rows, { sheet }) => {
      sheet.deleteRows(rows)
      sheet.selectedRows.value = []
      sheet.gripRow.value = null
    },
    multiple: true,
    single: true
  },
  {
    name: 'Advanced item options',
    icon: 'pencil',
    action: (rows, { sheet }) => {
      const data = sheet.getRowData(rows[0])
      if (data.livePriceRef) {
        return $store.dispatch('alert', {
          error: true,
          message: 'Cannot modify live price items'
        })
      }
      editItem(data.id)
    },
    multiple: false,
    single: true
  }
]

defineExpose({
  save,
  refEntityList,
  collapsedRowsWithDuplicateCombos
})
</script>

<template>
  <div class="absolute inset-0 flex flex-col basis-full shrink justify-start items-center gap-2">
    <Danger v-if="uidsWithDuplicatedCombos.length">
      <div class="text-lg">
        Fix or delete the following items with duplicate variation traits for the selector to work
        properly:
      </div>
      <ul class="list-disc ml-5">
        <li v-for="range in collapsedRowsWithDuplicateCombos" :key="range">
          Row{{ range[0] === range[1] ? '' : 's' }} {{ range[0] + 1 }}
          {{ range[0] === range[1] ? '' : `to ${range[1] + 1}` }}
        </li>
      </ul>
    </Danger>
    <div class="basis-full shrink relative w-full h-full overflow-hidden">
      <EntityList
        class="inset-0 !absolute h-full w-full"
        ref="refEntityList"
        type="cost_type"
        title="Variation items"
        icon="swatchbook"
        :fetchOnMount="true"
        @isDirty="isDirtyHandler"
        @change="(e) => emit('change', e)"
        @changes="(e) => emit('changes', e)"
        @removedRows="(e) => emit('removedRows', e)"
        :selectedRowOptions="itemActions"
        :filters="filters"
        :rowProcessor="props.rowProcessor"
        :create="create"
        :fetchRows="fetchRows"
        :session="{ save: false }"
        :columns="columns"
        :collapseGroups="collapseGroups"
        :superHeaders="superHeaders"
        :restrictFields="restrictFields"
        :limit="10000"
        :freeze="1"
        :sort="[
          ['cost_type_is_parent', 'desc'],
          ['type', 'asc'],
          ['parent_cost_type_id', 'desc'],
          ['assembly_name', 'asc'],
          ['cost_type_name', 'asc']
        ]"
      >
        <template #overlay>
          <Loader :loading="loading" />
        </template>
        <template #after>
          <MiniModal
            :key="itemEditorProps?.type"
            ref="refModal"
            :full="itemEditorProps?.type === 'assembly'"
            :fixed="itemEditorProps?.type === 'assembly'"
            scrollable
            :size="itemEditorProps?.type !== 'assembly' ? 'sm' : 'full'"
            v-if="editingItem !== null"
            :pt="{
              closeButton: { class: '!w-0 !p-0 !m-0 !h-0' }
            }"
            :closeable="false"
            :showCloseButton="false"
          >
            <template #header>
              <div class="flex justify-between items-start w-full">
                <Header :show-save-button="false" v-if="editingItem" v-bind="itemEditorProps" />
                <ChangeTracker
                  v-bind="itemEditorProps"
                  @changes="modalChangesHandler"
                  v-if="itemEditorProps.type !== 'assembly'"
                >
                  <template #default="{ isDirty }">
                    <div class="flex justify-start gap-1 items-center">
                      <Btn
                        v-if="isDirty"
                        size="large"
                        severity="tertiary"
                        :action="handleEditorCancel"
                      >
                        Cancel
                      </Btn>
                      <Btn
                        v-if="isDirty"
                        size="large"
                        severity="bolster"
                        :action="handleEditorSave"
                      >
                        Save changes
                      </Btn>
                      <Btn v-else size="large" severity="tertiary" :action="handleEditorCancel">
                        Done
                      </Btn>
                    </div>
                  </template>
                </ChangeTracker>
              </div>
            </template>
            <Assembly
              v-bind="itemEditorProps"
              v-if="itemEditorProps.type === 'assembly'"
              ref="assemblyEditor"
              :show-title="false"
            />
            <CostType v-bind="itemEditorProps" v-else ref="costTypeEditor" :show-title="false" />
          </MiniModal>
          <slot name="after"> </slot>
        </template>
      </EntityList>
    </div>
  </div>
</template>
