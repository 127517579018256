// import PdfVuer from 'pdfvuer';
import { jsPDF } from '~/jspdf/dist/jspdf.es'
import PDFObject from 'pdfobject'
import '@/assets/fonts/jsPDF/ProductSansRegular-normal'
import '@/assets/fonts/jsPDF/ProductSansBold-bold'
import '@/assets/fonts/jsPDF/arial-normal'
import '@/assets/fonts/jsPDF/arrowFont'
import '@/assets/fonts/jsPDF/Whisper-Regular-normal'

export default {
  data() {
    return {
      doc: null,
      pdfName: 'defaut-name',
      pageCount: 0,
      output: null,
      loadingPdf: true,
      width: null,
      height: null,
      marginX: 18,
      marginY: 18,
      maxWidth: 0.66,
      containerMargin: 8,
      lineHeight: 5,
      textLineHeight: 1.5,
      theme: null,
      fonts: {
        h1: {
          size: 20,
          weight: 'bold',
          family: 'ProductSansBold'
        },
        h2: {
          size: 16,
          weight: 'bold',
          family: 'ProductSansBold'
        },
        h3: {
          size: 12,
          weight: 'bold',
          family: 'ProductSansBold'
        },
        h4: {
          size: 11,
          weight: 'bold',
          family: 'ProductSansBold'
        },
        h5: {
          size: 11,
          weight: 'bold',
          family: 'ProductSansBold'
        },
        p: {
          size: 10,
          weight: 'normal',
          family: 'arial'
        },
        b: {
          size: 10,
          weight: 'bold',
          family: 'ProductSansBold'
        },
        small: {
          size: 8,
          weight: 'normal',
          family: 'arial'
        },
        icon: {
          size: 10,
          weight: 'normal',
          family: 'OpenArrow'
        },
        signature: {
          size: 24,
          weight: 'normal',
          family: 'Whisper-Regular'
        }
      }
    }
  },
  computed: {
    supportsPDFs() {
      return PDFObject.supportsPDFs
    },
    pageWidth() {
      return this.width - this.marginX
    },
    pageHeight() {
      return this.height - this.marginY
    },
    pageMarginX() {
      return this.marginX / 2
    },
    pageMarginY() {
      return this.marginY / 2
    },
    textXY() {
      const x = this.pageMarginX + this.containerMargin
      const y = this.pageMarginY + this.containerMargin
      return {
        x,
        y
      }
    },
    maxLineWidth() {
      return this.pageWidth * this.maxWidth
    },
    alignRight() {
      return this.width - this.pageMarginX
    }
  },
  methods: {
    async getFileType(id) {
      const fileType = await this.$store.dispatch('File/getFileType', {
        id
      })
      return fileType.split('/')[1].toUpperCase()
    },
    convertImageToCanvas(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const data = imgData.data
          const color = 255
          for (let i = 0; i < data.length; i += 4) {
            if (data[i + 3] < color) {
              data[i] = color
              data[i + 1] = color
              data[i + 2] = color
              data[i + 3] = color
            }
          }
          ctx.putImageData(imgData, 0, 0)
          resolve(canvas)
        }
        img.onerror = (e) => reject(e)
        img.crossOrigin = 'Anonymous'
        img.src = url
      })
    },
    convertImage(image) {
      return new Promise((resolve, reject) => {
        this.convertImageToCanvas(image)
          .then((canvas) => {
            if (canvas instanceof HTMLCanvasElement) {
              const img = new Image()
              img.src = canvas.toDataURL('image/jpeg')
              resolve(img)
            }

            reject('Instance not of canvas type')
          })
          .catch(reject)
      })
    },
    initDoc() {
      this.doc = new jsPDF({
        lineHeight: this.textLineHeight
      })
      this.setWidthHeight()
    },
    getImageMeta(url) {
      const img = new Image()
      return new Promise((resolve, reject) => {
        img.onload = () => resolve(img)
        img.onerror = () => reject()
        img.src = url
      })
    },
    savePDF(name) {
      this.doc.save(`${name || this.pdfName}.pdf`)
    },
    setName(name) {
      this.pdfName = name
    },
    pageLine(y, c, dashed = false, startAt = null) {
      let d = [0, 0, 0]
      if (this.theme) {
        const { r, g, b } = this.theme.backgroundColor
        d = [r, g, b]
      }
      let marginX = this.pageMarginX
      if (startAt) {
        marginX = startAt
      }
      this.doc.setLineWidth(0.3)
      this.doc.setDrawColor(...(c || d))
      if (dashed) this.doc.setLineDash([2.5])
      this.doc.line(marginX, y, this.width - this.pageMarginX, y)
    },
    setBlobUrl(secureUrl) {
      if (!secureUrl) {
        this.pageCount = this.doc.internal.getNumberOfPages()
        const blob = this.doc.output('blob')
        this.output = URL.createObjectURL(blob)
      } else {
        this.output = secureUrl
      }
      PDFObject.embed(this.output, '#pdf-viewer', {
        fallbackLink: false
      })
    },
    setWidthHeight() {
      this.width = this.doc.internal.pageSize.getWidth()
      this.height = this.doc.internal.pageSize.getHeight()
    },
    getTextHeight(text, t) {
      const font = this.fonts[t]
      this.doc.setFontSize(font.size)
      const textLines = this.doc.splitTextToSize(text?.trim(), this.maxLineWidth)

      const fontSize = this.doc.internal.getFontSize()
      const textHeight = textLines.length * fontSize * this.doc.internal.getLineHeightFactor()
      return textHeight / this.doc.internal.scaleFactor
    },
    getParagraphHeight(text, width) {
      try {
        const t = 'p'
        const font = this.fonts[t]
        const lines = this.doc.splitTextToSize(text.trim(), width)
        const textHeight = lines.length * font.size * this.doc.internal.getLineHeightFactor()
        return textHeight / this.doc.internal.scaleFactor
      } catch (e) {
        return 0
      }
    },
    getTextWidth(text) {
      return (
        this.doc.getStringUnitWidth(text) *
        (this.doc.internal.getFontSize() / this.doc.internal.scaleFactor)
      )
    },
    getTextOffset(text) {
      const textWidth = this.getTextWidth(text)
      return (this.doc.internal.pageSize.width - textWidth) / 2
    },
    indent(textLines) {
      return textLines.map((line) => {
        if (line.search('•') < 0) {
          return `   ${line}`
        }
        return line
      })
    },
    txt(
      t = 'p',
      text = '',
      x = 0,
      y = 0,
      align = 'left',
      color = [0, 0, 0],
      customMax = null,
      indent = false
    ) {
      const font = this.fonts[t]
      this.doc.setTextColor(...color)
      this.doc.setFont(font.family, font.weight)
      this.doc.setFontSize(font.size)
      let textLines = this.doc.splitTextToSize(text, customMax || this.maxLineWidth)
      const textOffset = align === 'center' ? this.getTextOffset(text) : null
      const height = this.getTextHeight(text, t)

      if (y + height > this.pageHeight) {
        let lastTextBlock = null
        const linesPerPage = Math.floor(this.pageHeight / this.lineHeight)
        const numLines = textLines.length
        let numLinesDisplayed = 0
        let pageLinesLeft = linesPerPage - Math.ceil(y / this.lineHeight)
        let page = 0

        do {
          let textBlock = textLines.slice(numLinesDisplayed, numLinesDisplayed + pageLinesLeft)
          if (indent) {
            textBlock = this.indent(textBlock)
          }

          this.doc.text(
            textBlock,
            textOffset || x,
            numLinesDisplayed === 0 ? y : this.marginY,
            null,
            null,
            align === 'center' ? 'left' : align
          )

          numLinesDisplayed += pageLinesLeft
          pageLinesLeft = linesPerPage - Math.ceil(this.marginY / this.lineHeight)
          if (numLinesDisplayed < numLines) {
            this.doc.addPage()
            page += 1
          } else {
            lastTextBlock = textBlock
          }
        } while (numLinesDisplayed < numLines)

        const h = this.getTextHeight(lastTextBlock.join(' '), t)
        const w = this.getTextWidth(lastTextBlock.join(' '), t)

        return {
          h,
          w,
          pageAdded: page > 0
        }
      }
      if (indent) {
        textLines = this.indent(textLines)
      }
      this.doc.text(textLines, textOffset || x, y, null, null, align === 'center' ? 'left' : align)

      const h = this.getTextHeight(text, t)
      const w = this.getTextWidth(text, t)
      // const dimensions = this.doc.getTextDimensions(text);
      return {
        h,
        w
      }
    },
    callout(t, p, x, y, align, hc, pc, type = 'h3', max = null) {
      this.txt(type, t, x, y, align, hc, max)
      const h = this.getTextHeight(t, type)
      let lineHeight = this.lineHeight / 2
      if (type === 'h4') {
        lineHeight = this.lineHeight / 3
      }
      this.txt('p', p, x, y + lineHeight + h, align, pc, max)
    },
    setTheme(theme) {
      this.theme = {
        primary: c.hexToRgb(theme['main-primary-color']),
        secondary: c.hexToRgb(theme['main-secondary-color']),
        headingFont: c.hexToRgb(theme['heading-text-font-color']),
        subHeadingFont: c.hexToRgb(theme['heading-subheading-font-color']),
        bg: c.hexToRgb(theme['background-url']),
        backgroundColor: c.hexToRgb('#e9ecef'),
        backgroundColorDarker: c.hexToRgb('#adb5bd')
      }
    },
    async getImageDimWithMax(image, maxWidth, maxHeight) {
      let imageWidth = 200
      let imageHeight = 50
      const imageMeta = await this.getImageMeta(image)
      imageWidth = imageMeta.width
      imageHeight = imageMeta.height
      if (imageHeight > maxHeight) {
        const width = imageWidth * (maxHeight / imageHeight)
        imageHeight = maxHeight
        imageWidth = width
      }
      if (imageWidth > maxWidth) {
        const height = imageHeight * (maxWidth / imageWidth)
        imageWidth = maxWidth
        imageHeight = height
      }

      return {
        w: imageWidth,
        h: imageHeight
      }
    }
  }
}
