import Button from '../../mixins/Button'

export default {
  mixins: [Button],
  watch: {
    mappedRequiredDimensions(newDim, old) {
      c.throttle(() => {
        if (!c.jsonEquals(old, newDim)) {
          this.backupDimensions = old
        }
      })
    },
    value(val) {
      this.dimensionsLocal = val
    },

    dimensionsLocal(val) {
      this.$emit('input', val)
    },

    awaitingAudit(aa) {
      if (aa === 0) {
        c.throttle(() => {
          if (!this.awaitingAudit) {
            this.endLoading()
          }
        })
      }
    },
    visibleDimensions() {
      this.sortDimensions()
    }
  },

  data() {
    return {
      dimensionsLocal: this.value,
      showDerivedDimensionsLocal: this.showDerivedDimensions,
      backupDimensions: this.dimensionsLocal,
      sortedAbbrs: []
    }
  },

  props: {
    value: {
      type: Object
    },

    autoCommit: {
      default: false
    },

    reference: {
      type: String,
      required: true
    },

    store: {
      type: String,
      required: true,
      default: 'Quote'
    },

    object: {
      type: Object,
      required: true
    },

    showDerivedDimensions: {
      default: false
    }
  },
  emits: ['input'],

  mounted() {
    this.$nextTick(() => {
      this.sortDimensions()
    })
  },

  methods: {
    sortDimensions() {
      const dims = { ...this.dimensionsLocal }
      const vis = [...this.visibleDimensions]

      // if local dimensions have not been rendered yet return empty array
      if (!dims) return []

      // Get flags
      const mapped = Object.values(
        _.sortDimensions(
          vis.reduce((acc, abbr) => {
            const dim = dims[abbr]

            return {
              ...acc,
              [abbr]: {
                ...dim,
                error: abbr in this.circ
              }
            }
          }, {}),
          false,
          null,
          true
        )
      )

      this.sortedAbbrs = Object.values(mapped)
    },
    /**
     * Handler for area-dimension
     */
    setDimension(key, dimension) {
      this.addLoading()
      this.dimensionsLocal = {
        ...this.dimensionsLocal,
        [key]: { ...dimension }
      }
    }
  },

  computed: {
    circ() {
      const dims = { ...this.dimensionsLocal }
      const vis = [...this.visibleDimensions]
      const { dependencies } = c.getDimensionDependencies(dims, vis)
      return c.getCircularDimensionDependencies(dependencies)
    },
    circMessage() {
      if (!Object.keys(this.circ).length) return ''
      return c.getCircularDimensionWarning(this.circ, this.dimensionsLocal)
    },
    awaitingAudit() {
      return Object.keys(this.$store.state[this.store].unauditedChanges).length ||
        this.$store.state[this.store].auditing
        ? 1
        : 0
    },

    /**
     * Dimensions that are required to be set in this context
     * @returns {string[]}
     */
    visibleDimensions() {
      return this.object.asDimensionsUsed
    },

    mappedRequiredDimensions() {
      const dims = { ...this.dimensionsLocal }
      const vis = [...this.sortedAbbrs].map((dim) => dim.abbr)

      // if local dimensions have not been rendered yet return empty array
      if (!dims) return []

      return vis.map((abbr, index) => ({
        ...dims[abbr],
        demote: this.sortedAbbrs[index].demote
      }))
    },

    countDemoted() {
      return this.mappedRequiredDimensions.filter((dim) => dim.demote).length
    }
  }
}
