import $$h from './Helpers'
import Shorten from './Shorten'

const { getShortFromId } = Shorten
import UserError from './UserError.js'

let uniqueScopeId = Date.now() - 1615521490000

/**
 *  Set the unique scope id which should usually
 *  be the user id in base 32 format. Number(234).toString(32).
 *
 * @param id
 */
const setUniqueScopeId = (id) => {
  uniqueScopeId = $$h.toNum(id)
}

const getUniqueScopeId = () => {
  if (!uniqueScopeId) {
    const rnd = Math.random() * 50000000
    setUniqueScopeId(getShortFromId(rnd))
  }

  return uniqueScopeId
}

/**
 * Get a mapped subset from a normalized set based on provided list of refIds
 * @param refIds array
 * @param norm object
 * @returns normalized object
 */
const map = (refIds, norm = {}) =>
  refIds.reduce(
    (acc, refId) => ({
      ...acc,
      [refId]: refId && refId in norm && norm[refId] ? $$h.imm(norm[refId]) : {}
    }),
    {}
  )

let refIdsGenerated = []

const mergeChanges = (changes, ...toMerge) => {
  let merged = { ...changes }

  for (let j = 0; j < toMerge.length; j += 1) {
    const merging = toMerge[j]
    const mergeRefs = Object.keys(merging)
    for (let i = 0; i < mergeRefs.length; i += 1) {
      merged = {
        ...merged,
        [mergeRefs[i]]: {
          ...(merged[mergeRefs[i]] || {}),
          ...merging[mergeRefs[i]]
        }
      }
    }
  }

  return merged
}

/**
 * Generate a refId
 * @param type
 * @param id
 */
let refIdi = 0
const generateNormalizedRefId = (type) => {
  const scopeid = getUniqueScopeId()
  if (!scopeid) {
    throw new Error('You must supply a unique scope id first')
  }

  const typeInt = type.substring(0, 2)
  const time = Date.now() - 1615521490000
  const sessionUnique = $$h.uniqueId()
  const rand = Math.floor(Math.random() * Math.floor(1000)) + 1

  refIdi += 1
  const bases = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const num = (sessionUnique + rand) * refIdi * (time * 21)
  const short = getShortFromId(num, bases)
  const inter = getShortFromId(refIdi * time * scopeid, bases)

  const refId = `${typeInt}_${inter}_${short}`

  if (refIdsGenerated.includes(refId)) {
    throw new Error('An error occurred normalizing that data')
  }

  refIdsGenerated.push(refId)

  return refId
}

/**
 * Get top level refId from a normalized set.
 *
 * If the normalized set contains two distinct trees, provide a refId parameter
 * to search for the root refId in the tree in that the provided refId belongs.
 *
 * @param set
 * @param refId
 * @returns {*}
 */
const getNormalizedRootRefId = (set, refId = null) => {
  let cursorRef = refId || Object.keys(set)[0]
  const allCursors = [] // Prevent recursion
  if (typeof set === 'object' && cursorRef && cursorRef in set) {
    while (
      typeof set[cursorRef] === 'object' &&
      'parentRefId' in set[cursorRef] &&
      set[cursorRef].parentRefId &&
      set[cursorRef].parentRefId in set &&
      allCursors.indexOf(set[cursorRef].parentRefId) === -1
    ) {
      cursorRef = set[cursorRef].parentRefId
      allCursors.push(cursorRef)
    }
    return cursorRef
  }
  return false
}

/**
 * Turn a set of normalized objects, indexed by reference ids
 *   into a hierarchical json type object
 * @param set - entire set of normalized objects
 * @param refId = Select only this object
 * @param keepRefids = keep refids in the object (they are removed by default)
 * @param keepWhole - by default any fields with save: false, in their schema
 *   are removed on denormalize, keepWHole will keep them in
 * @returns {*}
 */
const denormalize = (rawSet, rootRef, keepRefIds = true) => {
  const rootRefId = rootRef || getNormalizedRootRefId(rawSet)
  // Get the top level object, there can only be one.
  const root =
    rawSet[rootRefId || Object.keys(rawSet).filter((refId) => !rawSet[refId].parentRefId)[0]]

  const plump = (parent) => {
    if (!parent) return {}

    // eslint-disable-next-line no-unused-vars
    const { parentRefId: omit, refId: omitRef, ...rest } = parent

    const newParent = $$h.imm(!keepRefIds ? rest : parent)

    if (!newParent.aoChildren) return newParent

    newParent.aoChildren = $$h
      .makeArray(newParent.aoChildren)
      .filter((subRefId) => subRefId in rawSet)
      .map((subRefId) => plump(rawSet[subRefId]))

    return newParent || {}
  }

  return plump(root)
}

/**
 * Turns a hierarchical object into a normalized object keyed with refId's
 *
 * @param obj - Object to be normalized/flattened
 * @param overrideRefIds - if the object(s)
 *    comes with existing refIds,
 *    override them. By default
 *    it will use any existing refIds
 * @param forceRootRefId - Provide a root refId
 *    that will point to the @param obj you
 *    provide.  Otherwise one will be created randomly.
 * @returns {{}}
 */
const shouldNormalizeField = (constructor, field) => field === 'aoChildren'

/**
 * If you have a refId from a normalized set, and you want to know
 * which field in the parent it comes from, provide the whole set,
 * the parentRefId, and the childRefId and it will return the name of the
 * field that the childRef is part of, or null if it isn't found.
 * @param set
 * @param parentRefId
 * @param childRefId
 * @returns {string|null}
 */
const getNormalizedParentField = (set, parentRefId, childRefId) => {
  const parent = set[parentRefId]
  if (!parent) {
    return null
  }

  const fieldName = 'aoChildren'

  if (!$$h.makeArray(parent[fieldName]).includes(childRefId)) {
    return null
  }

  return fieldName
}

/**
 * Get array of refIds that represent orphaned objects in a normalized set
 * @param set
 * @returns {string[]}
 */
const getOrphanedRefIds = (set) => {
  const norm = set
  // Rebuild without orphans
  return Object.keys(norm).filter((refId) => {
    const child = norm[refId]
    const parentRefId = child.parentRefId

    if (!parentRefId) {
      return false
    }

    const parent = norm[parentRefId]

    if (!parent) {
      return true
    }

    return !getNormalizedParentField(norm, parentRefId, refId)
  })
}

/**
 * Remvoe the objects indicated by refIds provided
 * @param set object
 * @param refIds array
 * @returns {*}
 */
const removeNormalized = (set, refIds) => {
  // Create a shallow copy to avoid mutating the original set
  const norm = { ...set }

  // Build a parent-to-children map for efficient child lookups
  const parentMap = {}
  for (const refId in norm) {
    const parentRefId = norm[refId].parentRefId
    if (parentRefId) {
      if (!parentMap[parentRefId]) {
        parentMap[parentRefId] = []
      }
      parentMap[parentRefId].push(refId)
    }
  }

  // Ensure refIds is an array
  const toDelete = Array.isArray(refIds) ? [...refIds] : [refIds]

  // Use a stack to iteratively remove refIds and their descendants
  while (toDelete.length > 0) {
    const refId = toDelete.pop()

    if (!(refId in norm)) {
      continue
    }

    // Delete the refId from norm
    delete norm[refId]

    // Add its children to the toDelete stack
    const children = parentMap[refId]
    if (children) {
      toDelete.push(...children)
    }
  }

  return norm
}

/**
 * Remove references to children in aoChidlren when
 * the child doesnt actually exist
 * @param set
 * @returns {*}
 */
const detachOrphanChildren = (set) => {
  for (const ref in set) {
    const item = set[ref]
    const aoChildren = item.aoChildren
    if (!aoChildren) continue

    let writeIndex = 0
    for (let readIndex = 0; readIndex < aoChildren.length; readIndex++) {
      const child = aoChildren[readIndex]
      if (typeof child !== 'string' || child in set) {
        aoChildren[writeIndex] = child
        writeIndex++
      }
    }
    aoChildren.length = writeIndex // Truncate the array to remove invalid entries
  }
  return set
}

/**
 * Clean up fragmented normalized sets, remove
 * any children with no parent.
 *
 * @param set
 * @returns {object}
 */
const removeOrphaned = (set) => {
  const orphans = getOrphanedRefIds(set)
  return detachOrphanChildren(removeNormalized(set, orphans))
}

/**
 *
 * @param obj
 * @param overrideRefIds
 * @param forceRootRefId
 * @param currentRefIds
 * @returns {{}}
 */
const normalize = (
  obj,
  overrideRefIds = false,
  forceRootRefId = null,
  currentRefIds = [],
  formatter = (type, item) => item,
  skipDupRefIdCheck = false
) => {
  if (obj && !Object.keys(obj).length) {
    return obj
  }

  if (!obj || !('type' in obj) || !obj.type) {
    throw new Error(`Every object passed
      to normalize must have a 'type' property
      defining the object type it is.  The following
      object was missing the type property`)
  }
  const flatChildren = {}
  const id = obj[`${obj.type}_id`] || null
  const clone = $$h.imm(obj)

  // Track refIds to ensure there are no duplicates
  let refIds = currentRefIds
  let countChildren = 1 // count number of entities to make sure there was no error

  if (!Object.keys(clone).length) {
    return {}
  }

  if (refIds && refIds.length) {
    refIdsGenerated = $$h.uniq(refIdsGenerated, refIds)
  }

  let rootRefId
  if ((overrideRefIds || !obj.refId) && !forceRootRefId) {
    rootRefId = generateNormalizedRefId(clone.type, id)
  } else {
    rootRefId = forceRootRefId || obj.refId
  }
  clone.refId = rootRefId

  // Recursive function to flatten children
  const flatten = (object, type) => {
    let parent = formatter(type, object)

    // Make sure it has some sort of id
    if (
      (overrideRefIds && (!parent.refId || parent.refId !== rootRefId)) ||
      !parent.refId ||
      (!skipDupRefIdCheck &&
        parent.refId !== rootRefId &&
        parent.refId &&
        refIdsGenerated.includes(parent.refId))
    ) {
      // Don't just remove refId but all other unique references
      /* eslint-disable */
      const {
        refId: omit11,
        item_id: omit0,
        parent_item_id: omit1,
        originalRefId: omit2,
        children_item_ids: omit3,
        ...rest
      } = parent
      /* eslint-enable */
      parent = rest
      parent.refId = generateNormalizedRefId(type)
    } else {
      // eslint-disable-next-line no-self-assign
      parent.refId = parent.refId
    }

    parent.item_id = parent.item_id || parent.refId

    refIds = [...refIds, parent.refId]

    const fields = {
      aoChildren: {
        type: 'array',
        filter: false,
        format: false,
        mapTo: (child) =>
          child.type !== 'cost_type' && child.type !== 'item' ? child.type : 'cost_item',
        title: 'Line items',
        possibleChildTypes: ['cost_item', 'assembly']
      }
    }

    // Iterate those fields with mapTo set, which are essentially
    //  sub-objects, which are going to be put into flatChildren, to
    //  be made flat;
    Object.keys(fields).forEach((field) => {
      const mappedArray = $$h.makeArray(parent[field])
      const childType = fields[field].mapTo

      countChildren += mappedArray.length || 0

      parent[field] = mappedArray.map((child) => {
        if (typeof child !== 'object') {
          return child
        }

        let defaultedChild = child

        const computedType = typeof childType === 'function' ? childType(child) : childType

        defaultedChild = {
          ...formatter(computedType, child),
          type: computedType
        }

        // set parentrefId
        defaultedChild.parentRefId = parent.refId

        return flatten(defaultedChild, computedType)
      })

      // Set children to the ids of the children instead
      parent[field] = parent[field].map((child) => child.refId)
    })

    flatChildren[parent.refId] = parent

    return parent
  }

  flatten(clone, clone.type)

  // Check for errors
  if (Object.keys(flatChildren).length !== countChildren) {
    throw new UserError({
      userMessage: 'An error occurred loading that assembly, try it again.',
      message: `Children count and output do not match, ${countChildren} vs ${Object.keys(flatChildren).length}.`
    })
  }

  return flatChildren
}

/**
 * Normalize based on an array of non-normalized/hierarchical JS objects
 * @param set
 * @param embue
 * @param overrideRefIds
 * @returns {[null,null]}
 */
const normalizeSet = (set = [], embue = {}, overrideRefIds = false) => {
  let norm = {}
  let refIds = []

  set.forEach((o) => {
    let refId
    if (!overrideRefIds && o.refId) {
      refId = o.refId
    } else {
      refId = generateNormalizedRefId(o.type, o[`${o.type}_id`])
    }
    const normObj = normalize({ ...o, ...embue, refId }, overrideRefIds, refId)
    norm = { ...norm, ...normObj }
    refIds = [...refIds, refId]
  })

  return [norm, refIds]
}

/**
 * Denoramlzie a set from an array of refids
 * @param set
 * @param refIds
 * @param keepRefIds
 */
const denormalizeSet = (set, refIds = [], keepRefIds = true) =>
  $$h.makeArray(refIds).map((r) => denormalize(set, r, keepRefIds))

const getAllConnectedRefIds = (set, rootRefId = getNormalizedRootRefId(set)) => {
  const selectedObj = denormalize(set, rootRefId, true, true)
  const newSet = normalize(selectedObj, true, rootRefId)
  return Object.keys(newSet)
}

/**
 * Change refIds in the normalized 'set' param.
 * 'refIds' will change
 * the refIds provided into NEW (randomly generated) refIds, or refIds relating to the
 * same index if newREfIds is provided..
 *
 * If 'newRefIds' is provided then 'refIds' must also be provided. They must have the same
 * number of entries. Eeach refIds will be changed to a newRefIds, ie: refIds[2] will become
 * newRefIds[2].
 *
 * Can ONLY convert child refIds from the aoChildren field. Other normalized fields
 * cannot be rereferenced, for example oClient cannot be rereferenced if it has been normalized.
 *
 * @param set         full normalized set, even entries where refIds are not being changed
 * @param refIds      list of refIds to change
 * @param newRefIds   list of refIds correlating to index of refIds to change to (optional)
 * @returns object    altered normalized set
 */
const rereference = (set, refsToConvert = [], refsToConvertTo = []) => {
  const newSet = {}
  const convertedRefIds = {}
  const changeSet = {}

  const getConvertedRef = (refToConvert) => {
    // Not needing to be converted, so leave it
    if (!refToConvert || !refsToConvert.includes(refToConvert)) {
      return refToConvert
    }

    // Check if we have alredy converted this ref, and use the one we
    // already converted to
    if (!(refToConvert in convertedRefIds) || !convertedRefIds[refToConvert]) {
      // First check if we have a prescribed refId for this one:
      const index = refsToConvert.indexOf(refToConvert)
      if (index > -1 && refsToConvertTo[index]) {
        convertedRefIds[refToConvert] = refsToConvertTo[index]
      } else {
        // If nothing prescribed, generate a random one.
        convertedRefIds[refToConvert] = generateNormalizedRefId(set[refToConvert].type)
      }
    }

    // Return the converted ref on record
    return convertedRefIds[refToConvert]
  }

  Object.keys(set).forEach((oldRefId) => {
    const item = $$h.imm(set[oldRefId])
    const localChanges = {}
    const hasChildren = item.aoChildren && Array.isArray(item.aoChildren) && item.aoChildren.length

    if (hasChildren) {
      const newChildren = item.aoChildren.map((childRef) => getConvertedRef(childRef))
      if (!$$h.jsonEquals(newChildren, item.aoChildren)) {
        localChanges.aoChildren = newChildren
      }
      item.aoChildren = newChildren
    }

    const newRef = getConvertedRef(oldRefId)
    const newParentRef = getConvertedRef(item.parentRefId)
    const newOriginal = item.originalRefId || oldRefId

    // Change changeSet only when it differs
    if (newRef !== item.refId) {
      localChanges.refId = newRef
    }

    if (newParentRef !== item.parentRefId) {
      localChanges.parentRefId = newParentRef
    }

    if (newOriginal !== item.originalRefId) {
      localChanges.originalRefId = newOriginal
    }

    // Always change item, for new set
    item.refId = newRef
    item.item_id = newRef
    item.parentRefId = newParentRef
    item.originalRefId = newOriginal

    newSet[item.refId] = item
    changeSet[item.refId] = localChanges
  })

  return {
    set: newSet,
    convertedRefIds,
    changeSet
  }
}

/**
 * Get the parents all the way up the chain, of a list of items based on a list of refIds
 * @param set
 * @param childRefIds
 * @returns {[]|Array}
 */
const getParents = (set, childRefIds, includeChildren = false, filter = () => true) => {
  const parents = []

  // Now make sure there is a shell for each parent
  // of each change required
  const addParent = (childRefId) => {
    if (!(childRefId in set)) {
      return childRefId
    }

    const parentRefId = set[childRefId].parentRefId

    if (!parentRefId) {
      return childRefId
    }

    if (!parents.includes(parentRefId) && filter(parentRefId)) {
      parents.push(parentRefId)
    }

    return addParent(parentRefId)
  }

  childRefIds.forEach((childRefId) => addParent(childRefId))

  return $$h.uniq([...(includeChildren ? childRefIds : []), ...parents])
}

/**
 * Get the downstream children from a list of parent refids
 * @param set
 * @param parentRefIds
 */
const getChildren = (set, parentRefIds, includeParents = false, filter = () => true) => {
  parentRefIds = Array.isArray(parentRefIds) ? parentRefIds : [parentRefIds]

  let children = []

  const addChildren = (parentRefId) => {
    if (!(parentRefId in set)) {
      return []
    }

    const parentChildren = set[parentRefId].aoChildren

    if (!parentChildren || !parentChildren.length) {
      return []
    }

    children = [...children, ...parentChildren.filter((childRef) => filter(childRef))]

    return parentChildren.forEach((child) => addChildren(child))
  }

  parentRefIds.forEach((parentRefId) => addChildren(parentRefId))

  return $$h.uniq([...(includeParents ? parentRefIds : []), ...children])
}

/**
 * Extract items from a set (including their parents all the way up the chain) from a
 * normalized set, and a list of refIds
 * @param set
 * @param refIds
 * @returns {*}
 */
const extractParents = (set, childRefIds, includeChildren = true, filter = () => true) => {
  const parentRefIds = getParents(set, childRefIds, includeChildren, filter)

  return map(parentRefIds, set)
}

/**
 * Pull out all the children starting at a given point
 * @param set
 * @param parentRefIds
 * @returns {*}
 */
const extractChildren = (set, parentRefIds, includeParents = true, filter = () => true) => {
  const childRefIds = getChildren(set, parentRefIds, includeParents, filter)

  return map(childRefIds, set)
}

/**
 * @param set object  normalized set object
 * @param childRefId string
 */
const removeChild = (set, childRefId, deorphan = true) => {
  if (!(childRefId in set)) {
    return { set: $$h.imm(set), refId: childRefId }
  }

  let changes = {}

  const newSet = $$h.imm(set)

  const parentRefId = newSet[childRefId].parentRefId

  const removeRefIds = deorphan
    ? Object.keys(extractChildren(set, [childRefId], true))
    : [childRefId]

  const siblings = parentRefId && parentRefId in newSet ? newSet[parentRefId].aoChildren : false

  if (siblings && siblings.indexOf(childRefId) > -1) {
    siblings.splice(siblings.indexOf(childRefId), 1)

    newSet[parentRefId].aoChildren = siblings
    changes = {
      ...changes,
      [parentRefId]: {
        aoChildren: siblings
      }
    }
  }

  removeRefIds.forEach((rref) => {
    delete newSet[rref]
  })

  return {
    set: newSet,
    refId: childRefId,
    changes
  }
}

/**
 * @param set object  normalizedset
 * @param child object  denormalized child object
 * @param parentRefId string parent ref id
 * @param at int index of child in children list
 */
const addChild = (set, child, parentRefId, at = null) => {
  let newSet = $$h.imm(set)

  let childRefId = child.refId || generateNormalizedRefId(child.type)

  const childNorm = normalize(
    child,
    false,
    childRefId,
    Object.keys(set),
    (type, item) => item,
    true
  )

  const { set: rereferenced, convertedRefIds } = rereference(childNorm, Object.keys(childNorm))

  // Get reref'ed childREfId
  childRefId = convertedRefIds[childRefId]

  // Add child to parent
  const children = newSet[parentRefId].aoChildren || []
  const index = at !== null && at > -1 ? at : children.length
  children.splice(index, 0, childRefId)

  rereferenced[childRefId].parentRefId = parentRefId
  rereferenced[childRefId].item_id = childRefId
  newSet[parentRefId].aoChildren = children

  const changeSet = mergeChanges(rereferenced, {
    [parentRefId]: {
      aoChildren: newSet[parentRefId].aoChildren
    }
  })

  // Merge sets
  newSet = {
    ...newSet,
    ...rereferenced
  }

  return {
    set: newSet,
    refId: childRefId in convertedRefIds ? convertedRefIds[childRefId] : childRefId,
    convertedRefIds,
    changeSet
  }
}

/**
 * @param set object  normalized set
 * @param replacementChild object   denormalized child to add
 * @param replacedChildRefId string   refId of child to remove
 */
const replaceChild = (set, replacementChild, replacedChildRefId) => {
  const parentRefId = set[replacedChildRefId].parentRefId
  const index = set[parentRefId].aoChildren.indexOf(replacedChildRefId)

  let newSet = $$h.imm(set)
  if (index > -1) {
    const { set: removedSet } = removeChild(newSet, replacedChildRefId)
    newSet = removedSet
  }

  const { set: addSet, refId } = addChild(newSet, replacementChild, parentRefId, index)

  return {
    set: addSet,
    refId
  }
}

/**
 *
 * @param set
 * @param parentRefId
 * @param childrenRefIds
 */
const replaceChildren = (set, parentRefId, childrenRefIds) => {
  const newSet = $$h.imm(set)

  newSet[parentRefId].aoChildren = childrenRefIds
  childrenRefIds.forEach((childRef) => {
    newSet[childRef].parentRefId = parentRefId
  })

  return {
    set: newSet,
    refId: parentRefId
  }
}

/**
 * Sort a list of refIds in the natural order as placed as children in the denormalized set
 * @param norm
 * @param list
 * @param refId
 * @param childrenses
 * @returns {*[]|*}
 */
const sortNatural = (norm, list = null, refId = null, childrenses = null) => {
  const rootRef = refId ?? getNormalizedRootRefId(norm)
  const childrens = childrenses ?? norm[rootRef].aoChildren

  const preferredOrder = [rootRef]

  const goDown = (children) => {
    children.forEach((childRef) => {
      preferredOrder.push(childRef)
      if (norm[childRef].aoChildren) goDown(norm[childRef].aoChildren)
    })
  }
  goDown(childrens)

  if (Array.isArray(list) && list.filter(Boolean).length > 0) {
    return $$h.uniq(list).sort((a, b) => {
      return preferredOrder.indexOf(a) - preferredOrder.indexOf(b)
    })
  }

  return preferredOrder
}

export default {
  shouldNormalizeField,
  normalize,
  normalizeSet,
  denormalize,
  rereference,
  denormalizeSet,
  generateNormalizedRefId,
  getNormalizedRootRefId,
  getAllConnectedRefIds,
  getNormalizedParentField,
  removeOrphaned,
  removeNormalized,
  getOrphanedRefIds,
  setUniqueScopeId,
  addChild,
  removeChild,
  replaceChild,
  replaceChildren,
  map,

  getParents,
  getChildren,
  getAncestors: getParents,
  getDescendants: getChildren,

  extractParents,
  extractChildren,
  extractAncestors: extractParents,
  extractDescendants: extractChildren,

  sortNatural,
  mergeChanges
}
