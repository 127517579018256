<template>
  <ActivityChannelDetails
    v-if="showChannelDetails"
    :channelType="channelType"
    :channelTypeId="channelTypeId"
    ref="activityChannelDetailsRef"
  />
  <ActivityChannelMembers
    v-if="channelType !== 'QUOTE' && channelType !== 'QUOTE_CLIENT' && channelMembers?.length > 2"
    :channelType="channelType"
    :channelTypeId="channelTypeId"
    ref="activityChannelMembersRef"
  />
  <ActivityChannelCreateModal
    ref="activityChannelCreateRef"
    :channelType="channelType"
    :channelTypeId="channelTypeId"
  />
  <div class="activity-channel-header p-4 h-20 flex justify-between items-center">
    <div class="activity-channel-header-left flex items-center">
      <img
        v-if="channelType === 'SUPPORT'"
        src="@/assets/SVG/Bolster_Icon_Black.svg"
        alt="bolster icon"
        class="w-8 h-8 mr-2"
      />
      <font-awesome-icon
        v-else-if="channelType !== 'CHAT'"
        size="xl"
        :icon="[
          'far',
          channelType === 'COMPANY'
            ? 'scroll'
            : channelType === 'COMPANY_GENERAL'
              ? 'walkie-talkie'
              : 'house-building'
        ]"
        class="mr-2"
      />
      <font-awesome-icon
        v-else-if="channelMembers?.length > 2"
        :icon="['far', 'hashtag']"
        class="mr-2"
      />
      <PersonAvatar
        v-else
        shape="circle"
        :name="channelName"
        :full="true"
        class="h-8 w-8 inline-flex items-center justify-center rounded-full bg-surface-0 border mr-2 flex-shrink-0"
        size="no"
      />
      <div class="w-full">
        <div class="flex items-center">
          <p class="font-medium line-clamp-1 text-xl">{{ channelName }}</p>
          <p
            v-if="channelStatus"
            class="ml-2 inline-block text-xs font-medium p-0.5 rounded"
            :class="{
              'bg-sky-100 text-sky-800': channelStatus === 'p',
              'bg-emerald-100 text-emerald-800': channelStatus === 'k',
              'bg-violet-100 text-violet-800': channelStatus === 'f',
              'bg-red-300 text-red-800': channelStatus === 'g'
            }"
          >
            {{ getStatusCodeName(channelStatus) }}
          </p>
        </div>
        <p v-if="channelClientName" class="text-xs text-surface-400 line-clamp-1">
          Client name: {{ channelClientName }}
        </p>
      </div>
    </div>
    <div class="activity-channel-header-right h-full flex items-center">
      <AvatarGroup
        v-if="
          channelType !== 'QUOTE' && channelType !== 'QUOTE_CLIENT' && channelMembers?.length > 2
        "
        class="mx-2 cursor-pointer hidden lg:block"
        @click="onClickMembers"
      >
        <Avatar
          v-for="(member, index) in channelMembers?.slice(0, 4)"
          shape="circle"
          :label="`${member.user_fname[0] || '?'}`"
          :pt="{
            root: 'relative -r-2 h-12 w-12 inline-flex items-center justify-center rounded-full bg-surface-0 border -ml-5'
          }"
          :key="index"
        />
        <Avatar
          v-if="channelMembers?.length > 4"
          shape="circle"
          :label="`+${channelMembers?.length - 4}`"
          :pt="{
            root: 'h-12 w-12 inline-flex items-center justify-center rounded-full border bg-primary text-flame-white -ml-5 z-10'
          }"
        />
      </AvatarGroup>
      <div class="h-max mr-2 cursor-pointer">
        <Btn
          @click="onClickChannelMute"
          class="rounded-r-none rounded-l-none bg-transparent h-10 w-10 hover:bg-surface-200"
          v-tooltip="channelMuted ? 'Unmute' : 'Mute'"
        >
          <font-awesome-icon
            v-if="channelMuted"
            :icon="['far', 'bell-slash']"
            class="text-surface-900"
          />
          <font-awesome-icon v-else :icon="['far', 'bell']" class="text-surface-900" />
        </Btn>
      </div>
      <div class="h-max mr-2 cursor-pointer">
        <Btn
          @click="onClickChannelArchive"
          class="rounded-r-none rounded-l-none bg-transparent h-10 w-10 hover:bg-surface-200"
          v-tooltip="channelArchived ? 'Unarchive' : 'Archive'"
        >
          <font-awesome-icon
            v-if="channelArchived"
            :icon="['fas', 'box-archive']"
            class="text-surface-900"
          />
          <font-awesome-icon v-else :icon="['far', 'box-archive']" class="text-surface-900" />
        </Btn>
      </div>
      <div v-if="showChannelDetails" class="h-max mr-2 cursor-pointer">
        <Btn
          @click="onClickBoxes"
          class="rounded-r-none rounded-l-none bg-transparent h-10 w-10 hover:bg-surface-200"
        >
          <font-awesome-icon :icon="['far', 'boxes-stacked']" class="text-surface-900" />
        </Btn>
      </div>
      <div class="h-max cursor-pointer">
        <Btn
          @click="onClickEllipsis"
          class="rounded-r-none rounded-l-none bg-transparent h-10 w-10 hover:bg-surface-200"
        >
          <font-awesome-icon :icon="['far', 'gear']" class="text-surface-900" />
        </Btn>
      </div>
      <div class="block h-max cursor-pointer lg:hidden">
        <Btn
          @click="onClickBars"
          class="rounded-r-none rounded-l-none bg-transparent h-10 w-10 hover:bg-surface-200"
        >
          <font-awesome-icon :icon="['far', 'bars']" class="text-surface-900" />
        </Btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import useActivityChannel from '@/components/composables/ActivityChannel'
import ActivityChannelDetails from '@/components/Activities/ActivityChannelDetails.vue'
import ActivityChannelCreateModal from '@/components/Activities/ActivityChannelCreateModal.vue'
import ActivityChannelMembers from '@/components/Activities/ActivityChannelMembers.vue'
import AvatarGroup from 'primevue/avatargroup'
import Avatar from 'primevue/avatar'
import eventBus from '@/eventBus'

// ======== Props ======== //
const props = defineProps({
  channelType: {
    type: String,
    required: true
  },
  channelTypeId: {
    type: String,
    required: true
  }
})
// ====================== //

// ======== Composables ======== //
const {
  channelName,
  channelMuted,
  channelArchived,
  toggleMuteChannel,
  toggleArchiveChannel,
  channelMembers,
  channelStatus,
  channelClientName,
  getStatusCodeName
} = useActivityChannel(props)
// ====================== //

// ======== Refs ======== //
const activityChannelMembersRef = ref(null)
const activityChannelDetailsRef = ref(null)
const activityChannelCreateRef = ref(null)
// ====================== //

// ======== Computed ======== //
const showChannelDetails = computed(
  () => props.channelType === 'QUOTE' || props.channelType === 'INVOICE'
)
// ====================== //

// ======== Methods ======== //
const onClickEllipsis = () => {
  activityChannelCreateRef.value?.open()
}
const onClickMembers = () => {
  activityChannelMembersRef.value.toggleShowSidebar()
}
const onClickBoxes = () => {
  activityChannelDetailsRef.value.toggleShowSidebar()
}
const onClickChannelMute = () => {
  toggleMuteChannel()
}
const onClickChannelArchive = () => {
  toggleArchiveChannel()
}
const onClickBars = () => {
  eventBus.$emit('activityChannelsOpen')
}
// ====================== //
</script>

<style lang="scss" scoped></style>
