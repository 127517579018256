import { computed, ref, watch } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useMediaQuery } from '@/composables/mediaQuery'

export const useSidePanel = defineStore('sidepanel', () => {
  const store = useStore()
  const route = useRoute()

  const { smallFormat } = useMediaQuery()

  const open = ref(false)
  const size = ref('300')
  const minimumSize = ref('250')
  const collapsed = ref(false)

  const shouldShowSidePanel = computed(() => {
    if (route.meta.hideMenu) return false
    return (
      store.state.session.isLoggedIn &&
      (route.meta.menu || route.meta.menuContext) &&
      route.name !== 'Intake' &&
      route.name !== 'Project preview' &&
      (open.value || !smallFormat.value)
    )
  })

  const shouldShowSidePanelSearch = computed(() => {
    return (
      route.meta.type !== 'client' &&
      route.meta.menuContext !== 'client' &&
      route.meta.menuContext !== 'vendor'
    )
  })

  const computedSize = computed(() => {
    if (collapsed.value) {
      return 80
    }
    return size.value < minimumSize.value ? minimumSize.value : size.value
  })

  function toggle() {
    open.value = !open.value
  }

  watch(
    () => route.query?.tab,
    (tab) => {
      collapsed.value = tab === 'Preview' && !smallFormat.value
    },
    { immediate: true }
  )

  return {
    open,
    size,
    minimumSize,
    collapsed,
    shouldShowSidePanel,
    shouldShowSidePanelSearch,
    computedSize,
    toggle
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSidePanel, import.meta.hot))
}
