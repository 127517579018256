<template>
  <div class="flex flex-col gap-12">
    <ProgressTotals>
      <template #leftSide>
        <div class="flex flex-col w-full">
          <div class="mb-2 text-2xl font-medium text-pitch-black">{{ l('Progress') }}</div>
          <div>
            <MeterGroup :value="segments">
              <template #label><span class="hidden"></span></template
            ></MeterGroup>
          </div>
        </div>
      </template>
      <template #rightSide>
        <div class="flex items-end justify-end w-full gap-6">
          <div class="flex flex-col justify-center items-center basis-[100px] shrink-0 gap-1">
            <div class="font-medium text-md text-green-500 leading-none whitespace-nowrap">
              {{ l('Paid') }}
            </div>
            <div class="font-medium text-2xl tabular-nums leading-none whitespace-nowrap">
              {{ $f.currency(project.quote_paid_gross) }}
            </div>
          </div>

          <div class="flex flex-col justify-center items-center basis-[100px] shrink-0 gap-1">
            <div class="font-medium text-md text-purple-500 leading-none whitespace-nowrap">
              {{ l('Processing') }}
            </div>
            <div class="font-medium text-2xl tabular-nums leading-none whitespace-nowrap">
              {{ $f.currency(project.quote_pending_gross) }}
            </div>
          </div>

          <div class="flex flex-col justify-center items-center basis-[100px] shrink-0 gap-1">
            <div class="font-medium text-md text-deep-red-500 leading-none whitespace-nowrap">
              {{ l('Billed') }}
            </div>
            <div class="font-medium text-2xl tabular-nums leading-none whitespace-nowrap">
              {{ $f.currency(project.quote_pending_gross) }}
            </div>
          </div>

          <div class="flex flex-col justify-center items-center basis-[100px] shrink-0 gap-1">
            <div class="font-medium text-md text-surface-500 leading-none whitespace-nowrap">
              {{ l('Incomplete') }}
            </div>
            <div class="font-medium text-2xl tabular-nums leading-none whitespace-nowrap">
              {{ $f.currency(project.quote_uninvoiced_gross) }}
            </div>
          </div>
        </div>
      </template>
    </ProgressTotals>

    <SectionContainer
      :hideTitle="true"
      :section="approvalSection"
      :selectedItems="selectedItems"
      :loading="loading"
      :select="toggleFromItems"
      :featured="true"
      :type="approvalSection.type"
    >
      <template #above="{ section, items: featuredItems }">
        <div class="w-full flex justify-between mb-3">
          <div class="flex items-center">
            <font-awesome-icon :icon="['far', 'badge-check']" class="text-3xl mr-4" />
            <div class="flex flex-col">
              <h3>{{ section.title }} {{ !isBundled ? `(${section.totalItems})` : '' }}</h3>
              <p class="text-cool-gray-400 text-sm">
                Please review and approve the items by checking them off.
              </p>
            </div>
          </div>
          <div v-if="featuredItems.length > 0 && !isBundled" class="flex flex-col">
            <div class="flex justify-end items-center">
              <small class="text-blue-print mr-3">Approve all</small>
              <checkbox
                @input="() => toggleAllItems(approveAll, featuredItems)"
                class="ml-2 info small"
                v-model="approveAll"
              ></checkbox>
            </div>
          </div>
        </div>
      </template>
      <template #below>
        <ProgressPayment
          v-if="!disablePayment"
          ref="progressPayment"
          @openPaymentMethod="openTutorial"
          :isBundled="isBundled"
        />
        <ProgressApprovalBar
          :confirmed="onProgressApproved"
          v-if="displayApprovalBar || disablePayment"
        />
      </template>
    </SectionContainer>
    <template v-if="!isBundled">
      <SectionContainer
        v-for="section in filteredItemSections"
        :key="section.title"
        :condensed="true"
        :asCard="false"
        :loading="loading"
        :section="section"
      />
    </template>
    <SectionContainer
      :loading="loading"
      :condensed="true"
      :asCard="false"
      :section="paidSection"
      type="receipt"
    >
    </SectionContainer>
    <ProgressTutorial
      ref="tutorial"
      @addMethod="openPaymentMethod"
      :disablePayment="disablePayment"
      :disableBack="tutorialDisableBack"
      :startingPage="tutorialStartingPage"
    />
  </div>
</template>

<script setup>
/* global $tt:readonly */
import { computed, inject, toRefs, defineProps, onMounted, ref, watch } from 'vue'
import MeterGroup from 'primevue/metergroup'
import ProgressTotals from './ProgressTotals.vue'
import SectionContainer from '@/components/ui/SectionContainer.vue'
import Statuses from '@/../imports/api/Statuses'
import useItemizedPayments from '@/components/composables/ItemizedPayments'
import ProgressPayment from './ProgressPayment.vue'
import useFilters from '@/components/composables/UseFilters'
import useInvoice from '@/components/composables/Invoice'
import usePayment from '@/components/composables/Payment'
import usePayFac from '@/components/composables/PayFac'
import eventBus from '@/eventBus'
import useApproval from '@/components/composables/Approval'
import useTranslation from '@/components/composables/Translation'
import ProgressApprovalBar from '@/components/progress/ProgressApprovalBar.vue'
import ProgressTutorial from './ProgressTutorial.vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  approverId: {
    type: String
  },
  approverRole: {
    type: String
  },
  loading: {
    type: Number,
    default: 0
  },
  disablePayment: {
    type: Boolean,
    default: true
  },
  displayApprovalBar: {
    type: Boolean,
    default: false
  },
  displayApproved: {
    type: Boolean,
    default: false
  }
})

const { l } = useTranslation()
const {
  toggleFromItems,
  items: selectedItems,
  toggleBundle,
  getPercentageOfProject,
  itemizedTotal
} = useItemizedPayments()
const { settings, checkout } = usePayment()
const { makeItemizedInvoice } = usePayFac()
const approveAll = ref(0)
const { items, loading, approverRole, approverId } = toRefs(props)
const tutorial = ref()
const tutorialDisableBack = ref(false)
const tutorialStartingPage = ref(0)
const progressPayment = ref()

const { InProgress: inProgressStatus } = Statuses.statuses

const { session, getStepsNeedingApprovalByApprover, isApprovedByApprover } = useApproval()

const { openInvoice, invoice, invoiceGross } = useInvoice()

const isProcessing = (item) =>
  item.invoice_status && (item.invoice_status === 'pr' || item.invoice_status === 'p')

const isPaid = (item) =>
  item.invoice_id &&
  item.invoice_status &&
  (item.invoice_status === 'e' || item.invoice_status === 'a')

const isPaymentRecord = (item) =>
  item.invoice_id && item.invoice_status && item.invoice_status !== 'x'

const isOutstanding = (item) =>
  item.invoice_id && item.invoice_status && item.invoice_status === 'o'

const project = inject('project')

const segments = computed(() => {
  // paid amount
  const paidTotal = c.toNum(project.value.quote_paid_gross, 2)
  // unpaid amount
  const unpaidTotal = c.toNum(project.value.quote_unpaid_gross, 2)
  // pending total
  const pendingTotal = project.value.quote_pending_gross
  // uninvoiced total
  const uninvoicedTotal = c.toNum(project.value.quote_uninvoiced_gross, 2)
  // segments
  return [
    {
      label: l('Paid'),
      value: (paidTotal / project.value.quote_total_cost_net || 0) * 100,
      color: $tt.accentColor['green'][500]
    },
    {
      label: l('Awaiting payment'),
      value: (unpaidTotal / project.value.quote_total_cost_net || 0) * 100,
      color: $tt.accentColor['deep-red'][500]
    },
    {
      label: l('Processing'),
      value: (pendingTotal / project.value.quote_total_cost_net || 0) * 100,
      color: $tt.accentColor.purple[500]
    },
    {
      label: l('Incomplete'),
      value: (uninvoicedTotal / project.value.quote_total_cost_net || 0) * 100,
      color: $tt.accentColor.surface[200]
    }
  ]
})

const awaitingApprovalIds = computed(() => {
  const approvedItemIds = approvedItems.value.map(({ item_id }) => item_id)
  const awaitingApprovalItemsIds = awaitingApprovalItems.value.map(({ item_id }) => item_id)
  return [...approvedItemIds, ...awaitingApprovalItemsIds]
})

// upcoming items
const upcomingItems = computed(() =>
  items.value.filter(
    (item) =>
      item.item_status !== inProgressStatus &&
      (!item.change_order_status || item.change_order_status === 'p') &&
      !isPaymentRecord(item) &&
      !awaitingApprovalIds.value.includes(item.item_id)
  )
)

// scoped approved items
const scopedApprovedItems = computed(() =>
  items.value.filter(
    (item) =>
      item.item_status !== inProgressStatus &&
      item.change_order_status &&
      item.change_order_status !== 'p' &&
      !isPaymentRecord(item) &&
      !awaitingApprovalIds.value.includes(item.item_id)
  )
)

// approved items
const approvedItems = computed(() =>
  items.value.reduce((acc, item) => {
    const hasBeenApproved = item.approval && isApprovedByApprover(item.approval, approverId.value)
    if (!hasBeenApproved) return acc
    if (isPaymentRecord(item)) return acc
    acc.push({
      ...item,
      invoice_status: 'p',
      item_line_type: 'quote'
    })
    return acc
  }, [])
)

// items in progress
const inProgressItems = computed(() =>
  items.value.filter(
    (item) =>
      item.item_status === inProgressStatus &&
      !isPaymentRecord(item) &&
      !awaitingApprovalIds.value.includes(item.item_id)
  )
)

// items awaiting approval
const awaitingApprovalItems = computed(() =>
  items.value.filter((item) => {
    if (
      !item.approval ||
      isProcessing(item) ||
      isPaid(item) ||
      isOutstanding(item) ||
      loading.value
    )
      return false
    const hasStepsToApprove =
      getStepsNeedingApprovalByApprover(item.approval, approverId.value).length > 0
    return hasStepsToApprove
  })
)

// completed and paid items
const completedItems = computed(() =>
  items.value.filter((item) => isPaid(item) || isProcessing(item))
)

const isBundled = computed(
  () =>
    (settings.value.aggregatedPayments || project.value.quote_is_aggregated_payment) &&
    props.approverRole === 'client'
)

const bundledGroupedItems = computed(() => {
  // need to loop through the awaiting approval tally the cost
  // divide by the project total
  const projectTotal = c.toNum(project.value.quote_price_gross, 2)
  const percentage = getPercentageOfProject(projectTotal, awaitingApprovalItems.value)
  return [
    {
      title: `${Math.ceil(percentage)}% of the project`,
      items: awaitingApprovalItems.value || [],
      action: (val) => toggleBundle(val, awaitingApprovalItems.value),
      totalItems: awaitingApprovalItems.value ? awaitingApprovalItems.value.length : 0
    }
  ]
})

// completed and paid grouped by invoice
const completedItemsGrouped = computed(() =>
  completedItems.value.reduce((acc, item) => {
    if (!isPaymentRecord(item)) return acc
    const index = acc.findIndex((i) => i.invoice_id === item.invoice_id)
    if (index === -1) {
      acc.push({
        title: `Receipt #${item.invoice_id}`,
        action: {
          event: () => openInvoice(item.invoice_id),
          label: 'View receipt'
        },
        invoice_id: item.invoice_id,
        items: [item],
        item_line_type: 'receipt'
      })
      return acc
    }
    acc[index].items.push(item)
    return acc
  }, [])
)

const headers = computed(() => [
  'Item name',
  'Location',
  'Start date',
  ...(settings.value.displayItemizedPricing ? ['Amount'] : [])
])

const itemSections = computed(() => [
  {
    title: l('In progress'),
    icon: 'clipboard-check',
    description: l('Project tasks being worked on'),
    items: inProgressItems,
    totalItems: inProgressItems.value.length,
    headers: headers.value,
    showStartDate: true
  },
  {
    title: l('Scope Unapproved'),
    icon: 'house-circle-exclamation',
    description: l('Client has not yet approved scope.'),
    items: upcomingItems,
    showApproval: false,
    headers: headers.value,
    totalItems: upcomingItems.value.length
  },
  {
    title: l('Scope approved'),
    icon: 'house-circle-check',
    description: l('Client has approved scope but work is not started'),
    items: scopedApprovedItems,
    showApproval: true,
    headers: headers.value,
    totalItems: scopedApprovedItems.value.length
  }
])

const billedItems = computed(() => [...approvedItems.value, ...completedItemsGrouped.value])

const paidSection = computed(() => ({
  title: l('Billed'),
  icon: 'money-bill-wave',
  description: l('Items sent for billing'),
  items: billedItems,
  headers: ['Item name', 'Status', '# of items', 'Receipt'],
  totalItems: billedItems.value.length || 0
}))

const approvalSection = computed(() => {
  return {
    title: l('Awaiting Approval'),
    description: l('Please review and approve the items by checking them off'),
    items: isBundled.value ? bundledGroupedItems : awaitingApprovalItems,
    totalItems: awaitingApprovalItems.value.length,
    type: isBundled.value ? 'bundled' : 'quote'
  }
})

const toggleAllItems = (selected, selectedItems) => {
  eventBus.$emit(`approve-all-${session.value.id}`, {
    selected: !selected,
    selectedItems
  })
  selectedItems.forEach((item) => toggleFromItems({ item, selected: !selected }))
}

const { filteredItemSections } = useFilters(itemSections)

const openTutorial = async () => {
  tutorialDisableBack.value = true
  tutorialStartingPage.value = 1
  await tutorial.value.openTutorial(true) // force open
}

const openPaymentMethod = async () => {
  await tutorial.value.closeTutorial()
  progressPayment.value.changeMethod()
}

const onProgressApproved = () => {
  // if the role is client and payments are disabled create the itemized invoice automatically when approving
  if (session.value.role === 'client' && props.disablePayment) {
    checkout.value.invoice = {
      ...invoice.value,
      oSettings: {
        enableItemizedInvoicing: 1
      },
      aoItems: selectedItems.value
    }
    makeItemizedInvoice()
  }
}

watch(itemizedTotal, (newGross) => {
  console.log(newGross, 'newGross')
  invoiceGross.value = newGross
})

onMounted(async () => {
  session.value.role = approverRole.value
  session.value.userId = approverId.value
  if (session.value.role === 'client') await tutorial.value.openTutorial()
})
</script>
