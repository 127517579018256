import { toRefs, reactive } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import Statuses from '@/../imports/api/Statuses'
import eventBus from '@/eventBus'
import useGantt from '@/components/ui/gantt/Gantt'
import useSchedule from '@/components/schedule/Schedule'
import useStage from '@/components/schedule/Stage'

export const statuses = [
  {
    status: 'p',
    name: 'Not Started',
    severity: 'secondary',
    tagClasses: 'text-pitch-black bg-cool-gray-200',
    color: 'gray'
  },
  {
    status: 'f',
    name: 'In Progress',
    severity: 'info',
    tagClasses: 'bg-blue-100 dark:bg-blue-400 text-blue-900',
    color: 'blue'
  },
  {
    status: 'c',
    name: 'Completed',
    severity: 'success',
    tagClasses: 'bg-green-100 dark:bg-green-400 text-green-900',
    color: 'green'
  },
  {
    status: 'u',
    name: 'Flagged',
    severity: 'warning',
    tagClasses: 'bg-orange-100 dark:bg-orange-400 text-yellow-800',
    color: 'red'
  }
]

export const materialStatuses = [
  {
    status: 'o',
    label: 'Not ordered',
    color: 'gray',
    icon: 'empty-set'
  },
  {
    status: 'f',
    label: 'Ordered',
    color: 'blue',
    icon: 'truck-fast'
  },
  {
    status: 'c',
    label: 'Delivered',
    color: 'green',
    icon: 'truck-ramp-box'
  },
  {
    status: 'u',
    label: 'Flagged',
    color: 'red',
    icon: 'flag'
  }
]

export const priorities = [
  {
    key: 0,
    label: 'Not set',
    tagClasses: 'bg-red-100 dark:bg-red-400 text-red-900',
    color: 'red'
  },
  {
    key: 1,
    label: 'Highest',
    tagClasses: 'bg-red-100 dark:bg-red-400 text-red-900',
    color: 'red'
  },
  {
    key: 2,
    label: 'High',
    tagClasses: 'bg-orange-100 dark:bg-orange-400 text-orange-900',
    color: 'yellow'
  },
  {
    key: 3,
    label: 'Low',
    tagClasses: 'bg-yellow-100 dark:bg-yellow-400 text-yellow-900',
    color: 'green'
  },
  {
    key: 4,
    label: 'Lowest',
    tagClasses: 'bg-blue-100 dark:bg-blue-400 text-blue-900',
    color: 'blue'
  }
]

const defaultNewTask = {
  type: 'subtask',
  reference_type: 'item',
  progress: 0,
  priority: 4,
  assignees: [],
  is_material_item: 0,
  is_labor_item: 1,
  cost_item_is_materials_purchased: 0,
  cost_type_has_materials: 0,
  cost_type_has_labor: 1,
  cost_item_materials_status: 'o',
  item_user_name: '',
  item_vendor_name: '',
  internal_notes: '',
  cost_type_id: null,
  isNew: true
}

export const initialState = {
  statuses,
  priorities,
  materialStatuses,
  newTasks: [],
  newLinks: [],
  newTaskParent: null,
  newTemplate: null,
  creatingTask: 0,
  template: null
}

// global state
let context = reactive({
  ...initialState
})

export default () => {
  // Composables
  const store = useStore()
  const { gantt, data, unmountVueInstance } = useGantt()
  const {
    projectId,
    rootRefId,
    orderLists,
    calculateDurationBasedOnCrewSize,
    adjustDates,
    calculateEndDate,
    getTheLatestDate
  } = useSchedule()
  const {
    getCompletedCount,
    getActionItems,
    getTimeRemaining,
    updateChildren,
    getMaterialList,
    saveChangesToChildren,
    createTaskForStage,
    findPredecessorTask,
    generateWorkDays
  } = useStage()

  // methods

  /**
   * Get all the links linking to a task
   * @param {String} id
   * @returns {Array}
   */
  const getLinksForTask = (id) => {
    const links = gantt.value.getLinks()
    return links.filter((l) => l.target === id || l.source === id)
  }

  /**
   * Get the reference id of a task
   * @param {String} id
   * @returns {String}
   */
  const getRefId = (id) => {
    return Object.keys(store.state.Quote.normalized).find(
      (refId) => store.state.Quote.normalized[refId].item_id === id
    )
  }

  /**
   * Get a scoped id for the project schedule
   * @param {String} id
   * @param {String} scope
   * @returns
   */
  const scopeId = (id, scope) => {
    return `${scope}_${id}`
  }

  /**
   * Check to see if the id is a scoped id
   * @param {String} id
   * @returns {Boolean}
   */
  const idIsScoped = (id) => {
    if (!id) return
    if (typeof id !== 'string') return
    const check = id.split('_')
    return check.length > 0 && check[0] !== 'co'
  }

  const getScopedId = (id) => {
    if (idIsScoped(id)) {
      const check = id.split('_')
      return check[1]
    }
    return id
  }

  /**
   * Get the parent from the scope id
   * @param {String} id
   * @returns
   */
  const getScopedParent = (id) => {
    if (idIsScoped(id)) {
      const check = id.split('_')
      return check[0]
    }
    return null
  }

  /**
   * Save the item field
   * @param {*} val
   * @param {String} field
   * @param {String} id
   * @returns
   */
  const saveItemField = (val, field, id) => {
    return updateTaskByType('item', {
      id,
      changes: {
        [field]: val
      }
    })
  }

  /**
   * Save changes to the cost item
   * @param {*} val
   * @param {String} field
   * @param {String} id
   * @param {String} reference
   * @returns
   */
  const saveCostItemField = async (val, field, id, reference) => {
    try {
      const refId = getRefId(id)
      await store.dispatch('Quote/field', {
        explicit: true,
        skipAudit: true,
        skipLocalAudit: true,
        refId,
        changes: {
          [field]: val
        }
      })
      // save the changes to latest change order
      await store.dispatch('Quote/save', {
        refId: reference,
        force: true
      })
      await store.dispatch('CostType/partialUpdate', {
        selected: [
          {
            type: 'cost_type',
            cost_type_id: id,
            [field]: val
          }
        ]
      })
      const item = gantt.value.getTask(id)
      if (!item) return
      const changes = [
        {
          id,
          [field]: val
        }
      ]
      eventBus.$emit('refresh-gantt-items', {
        items: changes
      })
    } catch (e) {
      store.dispatch('alert', {
        message: e.message || 'We could not update schedule',
        error: true
      })
    }
  }

  /**
   * Delete a dependency link from item
   * @param {*} link
   * @returns
   */
  const onLinkDeleted = ({ id, item: link }) => {
    const { isNew = false, schedule_link_id = null } = link
    if (isNew && !schedule_link_id) return
    return store.dispatch('ajax', {
      path: `schedule_link/delete/${schedule_link_id || id}`
    })
  }

  /**
   * Get all the dependency links attached to an item
   * @param {String} id
   * @returns
   */
  const getLinksForItem = (id) => {
    const links = gantt.value.getLinks()
    return links.filter((l) => l.target === id || l.source === id)
  }

  /**
   * Update the stored link with new data
   * @param {String} id
   * @param {Object} updatedLink
   */
  const updateStoredLink = (id, updatedLink) => {
    const link = gantt.value.getLink(id)
    link.schedule_link_id = updatedLink.schedule_link_id
    gantt.value.updateLink(id)
  }

  /**
   * Save the dependency link
   * @param {Object} link
   */
  const saveLink = async (link) => {
    if (!link) return
    const { source, target, type, id } = link
    // if (isNew) return
    const linkSource = getScopedId(source)
    const linkTarget = getScopedId(target)
    const parent = getScopedParent(source)
    try {
      const { object } = await store.dispatch('ajax', {
        path: 'schedule_link/save',
        data: {
          object: {
            source_item_id: linkSource,
            target_item_id: linkTarget,
            schedule_link_type: type,
            parent_item_id: parent
          }
        }
      })
      updateStoredLink(id, object)
      return object
    } catch (e) {
      const existingLink = gantt.value.getLink(id)
      if (existingLink) gantt.value.deleteLink(id)
      console.log(e, 'could not save link')
    }
  }

  /**
   * Update dependency links
   * @param {Object} item
   * @param {*} source
   */
  const updateLinks = (item, source) => {
    const { id } = item
    const links = getLinksForItem(id)
    // at least add the one link if none exist
    if (links.length === 0) {
      gantt.value.addLink({
        source,
        target: id,
        type: 1,
        isNew: true
      })
      return
    }
    links.forEach((link) => {
      const { type } = link
      // delete existing
      // on delete event will remove from DB
      gantt.value.deleteLink(link.id)
      // create new one
      // add the link through gantt it will trigger link added event
      // which will add the link in the DB
      gantt.value.addLink({
        source,
        target: id,
        type
      })
    })
  }

  /**
   * When a link is created
   * @param {Object} params
   * @returns
   */
  const onLinkAdded = ({ item }) => {
    // mark as new so we don't try to delete it on our side
    gantt.value.getLink(item.id).isNew = true
    gantt.value.updateLink(item.id)
    saveLink(item)
  }

  /**
   * When a link is updated
   * @param {Object} params
   * @returns
   */
  const onLinkUpdated = () => {}

  const getStageEndDate = (stage) => {
    if (!stage.end_date && stage.duration) {
      // Calculate end date based on duration if end date is missing
      const endDate = gantt.value.calculateEndDate(stage.start_date, stage.duration)
      return moment(endDate).endOf('day').toDate()
    }
    return moment(stage.end_date).endOf('day').toDate()
  }

  const adjustStartDate = (task, stage, adjustedDuration) => {
    const adjustedStartDate = stage.start_date
    const stageEndDate = getStageEndDate(stage)
    // If the task's duration fits within the stage, calculate new end date
    if (adjustedDuration <= gantt.value.calculateDuration(stage.start_date, stageEndDate)) {
      const adjustedEndDate = gantt.value.calculateEndDate(adjustedStartDate, adjustedDuration)
      return { start_date: adjustedStartDate, end_date: adjustedEndDate }
    }

    // If task's duration exceeds stage's duration, set it to fit within stage
    return {
      start_date: stage.start_date,
      end_date: stageEndDate,
      duration: gantt.value.calculateDuration(stage.start_date, stageEndDate)
    }
  }

  // Adjust end date if it is after the stage's end date
  const adjustEndDate = (task, stage, adjustedDuration) => {
    const adjustedEndDate = getStageEndDate(stage)

    // If the task's duration fits within the stage, calculate new start date
    if (adjustedDuration <= gantt.value.calculateDuration(stage.start_date, stage.end_date)) {
      const adjustedStartDate = gantt.value.calculateEndDate(adjustedEndDate, -adjustedDuration)
      return { start_date: adjustedStartDate, end_date: adjustedEndDate }
    }

    // If task's duration exceeds stage's duration, set it to fit within stage
    return {
      start_date: stage.start_date,
      end_date: stage.end_date,
      duration: gantt.value.calculateDuration(stage.start_date, stage.end_date)
    }
  }

  /**
   * When a stage is assigned
   * @param {Object} stage
   * @param {Array} selected
   * @returns
   */
  const onAssignStage = async (stage, selected) => {
    if (!stage) return
    const { stage_id: stageId, stage_name: stageName } = stage
    // lets update all the cost items selected with the new stage
    const scopedId = scopeId(stageId, projectId.value)
    let parent = gantt.value.getTask(scopedId)
    let index = null
    // we need to create a task for the stage first
    if (!parent) {
      const predecessor = findPredecessorTask(stage)
      const duration = generateWorkDays(selected)
      index = predecessor?.$index || null
      parent = createTaskForStage(stage, index, duration || 1)
    }
    const updates = selected.reduce((acc, id) => {
      const item = gantt.value.getTask(id)
      if (!item) return acc
      const itemDuration = gantt.value.calculateDuration({
        start_date: new Date(item.start_date),
        end_date: new Date(item.end_date)
      })
      // Ensure minimum 1-day duration
      let adjustedDuration = itemDuration < 1 ? 1 : itemDuration

      // Check if start or end dates need adjustment
      const startDateNeedsAdjustment = moment(item.start_date).isBefore(parent.start_date)
      const endDateNeedsAdjustment = moment(item.end_date).isAfter(parent.end_date)

      let additional = { duration: adjustedDuration }

      // Adjust start date if necessary
      if (startDateNeedsAdjustment) {
        additional = { ...additional, ...adjustStartDate(item, parent, adjustedDuration) }
      }
      // Adjust end date if necessary
      if (endDateNeedsAdjustment) {
        additional = { ...additional, ...adjustEndDate(item, parent, adjustedDuration) }
      }

      acc.push({
        id: item.id,
        parent: parent.id,
        stage_id: stageId,
        // Update the stage name to display
        stage_name: parent.text,
        // Set the item to the start date of the new stage
        // start_date: parent.start_date,
        ...additional
      })
      // save the new stage parent to the BE
      updateTaskByType('item', {
        id,
        changes: {
          stage_id: stageId,
          stage_name: stageName
        }
      })
      return acc
    }, [])
    // refresh all the items
    eventBus.$emit('refresh-gantt-items', {
      items: updates
    })
    // after items have been updated, update the links of selected
    selected.forEach((id) => {
      const item = gantt.value.getTask(id)
      updateLinks(item, parent.id)
    })
    eventBus.$emit('clear-gantt-selected')
  }

  /**
   * Extracts and formats information about an assignee based on the provided input.
   *
   * @param {Object} assignee - The assignee object, which can have various properties.
   * @param {string} [assignee.type] - The type of assignee, can be 'user' or 'vendor'.
   * @param {string} [assignee.id] - The ID of the assignee.
   * @param {string} [assignee.name] - The name of the assignee.
   * @param {string} [assignee.user_id] - The ID of the user if the assignee is a user.
   * @param {string} [assignee.vendor_id] - The ID of the vendor if the assignee is a vendor.
   * @param {string} [assignee.item_assignee_object_id] - The ID of the assignee.
   * @param {string} [assignee.item_assignee_object_type] - The type of assignee.
   *
   * @returns {Object} - An object containing:
   *   - `item_assignee_object_id`: The ID of the assignee.
   *   - `item_assignee_object_type`: The type of the assignee ('user' or 'vendor').
   *   - `user_name` (optional): The name of the user if the assignee is a user.
   *   - `vendor_name` (optional): The name of the vendor if the assignee is a vendor.
   */
  const extractAssignee = (assignee) => {
    if (assignee.type) {
      return {
        item_assignee_object_id: assignee.id,
        item_assignee_object_type: assignee.type,
        ...(assignee.type === 'user'
          ? { user_name: assignee.name }
          : { vendor_name: assignee.name })
      }
    }
    const {
      user_id: userId,
      vendor_id: vendorId,
      // vendor_user_id: vendorUserId,
      item_assignee_object_id: itemAssigneeObjectId,
      item_assignee_object_type: itemAssigneeObjectType
    } = assignee
    return {
      item_assignee_object_id: userId || vendorId || itemAssigneeObjectId,
      item_assignee_object_type: itemAssigneeObjectType || userId ? 'user' : 'vendor'
    }
  }

  /**
   * When an assignee is assigned
   * @param {Array} assignees
   * @param {Array} selected
   */
  const onAssignAssignees = async (assignees, selected) => {
    c.throttle(
      async () => {
        try {
          let changes = []
          let scheduleChanges = []
          const formattedAssignees = []
          // assign the items
          let data = assignees.reduce((acc, assignee) => {
            const formatted = extractAssignee(assignee)
            formattedAssignees.push(formatted)
            // format the item assignee data
            const updates = selected.map((id) => ({
              item_id: id,
              item_assignee_type: 'primary',
              ...formatted
            }))
            // format the changes made
            const ids = assignees.map((i) => parseInt(i.user_id || i.vendor_id || i.id, 10))
            const newChanges = []
            const newScheduleChanges = []
            selected.forEach((id) => {
              // recalculate the duration based on the crew size
              const newData = calculateDurationBasedOnCrewSize(id, assignees)
              newChanges.push({
                id,
                assignees: formattedAssignees,
                assignee_ids: ids,
                ...newData
              })
              // with the items being assigned we need to lock the start date and end date in
              // so the assignee knows when this task will occur
              newScheduleChanges.push({
                id,
                start_date: newData.start_date,
                end_date: newData.end_date
              })
            })
            changes = [...changes, ...newChanges]
            scheduleChanges = [...scheduleChanges, ...newScheduleChanges]
            return [...acc, ...updates]
          }, [])
          if (assignees.length === 0) {
            data = selected.map((id) => ({
              item_id: id,
              item_assignee_object_id: null
            }))
            // setup empty changes so tasks in gantt get refreshed
            changes = selected.map((id) => ({
              id,
              assignees: [],
              assignee_ids: []
            }))
          }

          // store item start and end dates
          await store.dispatch('ajax', {
            path: 'schedule/saveScheduleChanges',
            data: {
              changes: scheduleChanges
            }
          })

          // update assignments
          await store.dispatch('Assignee/reassign', {
            assignments: data,
            quoteId: projectId.value
          })

          selected.forEach((id) => unmountVueInstance(`${id}_assignee_select`))

          // refresh all the items with changes so the changes appear immediately
          eventBus.$emit('refresh-gantt-items', {
            items: changes
          })

          store.dispatch('alert', {
            message: 'Assignees have been successfully saved.'
          })

          // regenerate order lists
          eventBus.$emit('refresh-order-lists', orderLists)
          eventBus.$emit('clear-gantt-selected')

          return changes
        } catch (e) {
          store.dispatch('alert', {
            message: e.message || 'We could not update assignees',
            error: true
          })
          return []
        }
      },
      { delay: 800 }
    )
  }

  const onSetToTemplate = (parent, selected) => {
    store.dispatch(`Quote/transferChildren`, {
      childRefIds: selected,
      parentRefId: parent,
      refId: rootRefId.value
    })
  }

  /**
   * Save the duration of an item
   * @param {Object} changes
   */
  const saveDuration = async (changes) => {
    try {
      await store.dispatch('ajax', {
        path: 'schedule/saveScheduleChanges',
        data: {
          changes
        }
      })
      store.dispatch('alert', {
        message: 'Schedule has been updated.'
      })
    } catch (e) {
      store.dispatch('alert', {
        message: e.message || 'We could not update schedule',
        error: true
      })
    }
  }

  /**
   * Update the status of an item
   * @param {*} val
   * @param {Object} item
   * @returns
   */
  const updateStatus = async (val, item) => {
    const id = item.id || item.item_id
    await saveItemField(val, 'item_status', id)
    const completedCount = getCompletedCount(id)
    const siblingIds = gantt.value.getSiblings(id)
    const siblings = siblingIds.map((i) => gantt.value.getTask(i))
    const actionList = getActionItems(siblings)
    const remainingTime = getTimeRemaining(id)
    const updates = [
      {
        id,
        progress: val === Statuses.statuses.Completed ? 1 : 0
      }
    ]
    eventBus.$emit('refresh-gantt-items', {
      items: updates
    })
    const changes = {
      completed_count: completedCount,
      remaining_time: remainingTime,
      progress: completedCount / actionList.length
    }
    return changes
  }

  /**
   * When assigning
   * @param {Array} selected
   * @param {Object} item
   * @param {String} field
   * @param {Function} callback
   * @returns
   */
  const onAssignment = async (selected, item, field = 'id', callback = null) => {
    const changes = await onAssignAssignees(selected, [item[field]])
    if (callback) callback(changes)
    return changes
  }

  /**
   * After changes are made to an item we must readjust all items affected
   * @param {Object} item
   * @param {String} startDate
   * @param {String} endDate
   * @returns
   */
  const readjust = (item, startDate, endDate) => {
    const { parent, id } = item
    // get children
    const childrenIds = gantt.value.getChildren(parent)
    const children = childrenIds.map((i) => gantt.value.getTask(i))
    // add the updated item to the list of items
    const updated = { ...item, end_date: endDate }
    const index = children.findIndex((i) => i.id === id)
    children[index] = {
      ...children[index],
      ...updated
    }
    // get the latest date
    const latestEndDate = getTheLatestDate(children)
    // update parent and material list
    const materialList = getMaterialList(children)
    const parentItem = gantt.value.getTask(parent)
    // we need to update the item changed but we also need to update the
    // material list and parent to reflect the change
    const changes = [
      {
        id,
        start_date: startDate,
        end_date: endDate
      },
      ...(parentItem
        ? [
            {
              id: parent,
              start_date: parentItem.start_date,
              end_date: latestEndDate
            }
          ]
        : []),
      ...(materialList
        ? [
            {
              id: materialList.id,
              start_date: materialList.start_date,
              end_date: latestEndDate
            }
          ]
        : [])
    ]
    eventBus.$emit('refresh-gantt-items', {
      items: changes
    })
    // manually trigger the autoSchedule for the parent
    gantt.value.autoSchedule(parent)
    return changes
  }

  /**
   * When changing the workdays on an item
   * @param {Number} workdays
   * @param {Object} item
   * @param {*} type
   * @returns
   */
  const onChangeWorkdays = (workdays, item, type) => {
    if (workdays <= 0) return
    const {
      start_date: startDate,
      id,
      parent,
      stage_id: stageId = null,
      project_id: projectId = null
    } = item
    // calculate the end date using working time and days
    const endDate = calculateEndDate(startDate, workdays)
    const start = startDate
    const { endDate: end } = adjustDates(startDate, endDate)
    const newStartDate = new Date(start)
    const newEndDate = new Date(end)
    const changes = readjust(item, newStartDate, newEndDate)
    const parentChanges = changes.filter((c) => c.id === parent)
    if (parentChanges) saveChangesToParent(parentChanges)
    const childrenChanges = updateChildren(id, new Date(newEndDate), new Date(newStartDate))
    if (childrenChanges) saveChangesToChildren(childrenChanges)
    updateParent(parent, newEndDate)
    // save the changes
    if (type === 'stage') {
      return store.dispatch('ajax', {
        path: 'schedule/saveStageScheduleChanges',
        data: {
          changes: [
            {
              id: stageId,
              start_date: start,
              end_date: end,
              quote_id: projectId
            }
          ]
        }
      })
    }
    saveDuration([
      {
        id,
        start_date: start,
        end_date: end
      }
    ])
  }

  const updateTask = (refId) => {
    const item = store.state.Quote.normalized[refId]
    const { item_id: itemId, stage_id: stageId, cost_type_name: name } = item
    const task = gantt.value.getTask(itemId)
    if (task) {
      task.text = name
      gantt.value.updateTask(itemId)
      return task
    }
    const quoteId = projectId.value
    const scopedParentId = scopeId(stageId, quoteId)
    // get parent task
    const parent = gantt.value.getTask(scopedParentId)
    const { id, start_date: startDate, text, duration } = parent
    // add details
    const response = gantt.value.addTask({
      ...item,
      id: item.item_id,
      text: name || 'Add a name to this task',
      start_date: startDate,
      duration,
      parent: id,
      stage_id: stageId,
      project: quoteId,
      stage_name: text,
      ...defaultNewTask
    })
    gantt.value.addLink({
      source: id,
      target: item.item_id,
      type: 1
    })
    return response
  }

  /**
   * Update the parent of a task item
   * @param {String} parentId
   * @param {String} endDate
   * @returns
   */
  const updateParent = (parentId, endDate) => {
    const parent = gantt.value.getTask(parentId)
    const end = moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    if (!parent) return
    if (endDate.getTime() > parent.end_date.getTime()) {
      const duration = gantt.value.calculateDuration({
        start_date: new Date(parent.start_date),
        end_date: new Date(endDate)
      })
      const updates = [
        {
          duration,
          start_date: parent.start_date,
          end_date: endDate,
          id: parent.id
        }
      ]
      eventBus.$emit('refresh-gantt-items', {
        items: updates
      })
      gantt.value.autoSchedule(parentId)
      return [
        {
          duration,
          start_date: parent.start_date,
          end_date: end,
          id: parent.id
        }
      ]
    }
    return
  }

  /**
   * Save changes to the parent item
   * @param {Array} updates
   * @returns
   */
  const saveChangesToParent = (updates) => {
    const changes = updates.map(({ start_date: startDate, end_date: endDate, id }) => {
      const parts = id.split('_')
      const { startDate: start, endDate: end } = adjustDates(startDate, endDate)
      return {
        start_date: start,
        end_date: end,
        id: parts[1],
        quote_id: parts[0]
      }
    })
    return store.dispatch('ajax', {
      path: 'schedule/saveStageScheduleChanges',
      data: {
        changes
      }
    })
  }

  /**
   * Handle a user clicking create
   * @param {Object} selected
   */
  const onCreateTask = async (selected = null) => {
    context.newTaskParent = selected
    context.newTasks = []
    if (selected) addAnotherTask()
  }

  const formatNewTask = (parent) => {
    return {
      text: `Subtask of ${parent.text} ${context.newTasks.length}`,
      start_date: gantt.value.roundDate(parent.start_date),
      end_date: gantt.value.roundDate(parent.end_date),
      quote_id: context.projectId,
      stage_name: parent.text,
      ...defaultNewTask
    }
  }

  /**
   * Quickly add another task to the stage and template
   */
  const addAnotherTask = () => {
    const formattedTask = formatNewTask(context.newTaskParent)
    const newTaskId = gantt.value.createTask(formattedTask, context.newTaskParent.id)
    const newTask = gantt.value.getTask(newTaskId)
    context.newTasks.push(newTask)
    const linkId = gantt.value.addLink({
      source: context.newTaskParent.id,
      target: newTaskId,
      type: 1
    })
    const newLink = gantt.value.getLink(linkId)
    context.newLinks.push(newLink)
    // need a timeout as the gantt initially re-renders with new task so the re-render of open is ignored
    setTimeout(() => {
      gantt.value.open(context.newTaskParent.id)
    }, 400)
    return newTask
  }

  /**
   * Remove the task from queued new tasks and from gantt
   * @param {*} id
   */
  const removeTask = (id) => {
    gantt.value.deleteTask(id)
    context.newTasks = context.newTasks.filter((t) => t.id !== id)
  }

  const deleteTask = (id) => {
    gantt.value.deleteTask(id)
    store.dispatch(`Quote/removeChild`, {
      refId: id
    })
  }

  /**
   * Abort adding the tasks and remove them from the gantt
   */
  const cancelAddingTasks = () => {
    context.newTasks.forEach(({ id }) => {
      const task = gantt.value.getTask(id)
      if (!task) return
      gantt.value.deleteTask(task.id)
    })
    context.newTasks = []
    context.newTaskParent = null
  }

  /**
   * Add a new template/assembly ot the quote
   */
  const addNewTemplate = () => {
    return store.dispatch('Quote/addTemplateItem', {
      reference: rootRefId.value,
      parent: rootRefId.value,
      embue: {
        item_name: context.newTemplate.assembly_name,
        assembly_name: context.newTemplate.assembly_name
      }
    })
  }

  /**
   * Add a new task item to the quote
   * @param {Object} task
   * @param {String} parent
   * @returns {Object}
   */
  const addNewTaskItem = async (task, parent) => {
    const stageId = context.newTaskParent.stage_id
    const embue = {
      item_name: task.text,
      cost_type_name: task.text,
      stage_id: stageId,
      cost_type_is_task: 1,
      cost_type_has_materials: 0,
      cost_type_has_labor: 1,
      is_material_item: 0,
      is_labor_item: 1,
      aoAssignees: [],
      oMeta: {
        itemType: 'task'
      }
    }
    const itemId = await store.dispatch('Quote/addTaskItem', {
      reference: rootRefId.value,
      parent,
      embue
    })

    // change the task id to the new item id
    gantt.value.changeTaskId(task.id, itemId)
    const newTask = gantt.value.getTask(itemId)
    // add task to gantt data
    data.value.data.push(newTask)
    return {
      id: task.id,
      item_id: itemId,
      ...embue
    }
  }

  /**
   * Confirm adding the tasks to the quote
   * @returns {Object}
   */
  const confirmAddingTask = async () => {
    context.creatingTask = 1
    let newTemplateId = null
    if (context.newTemplate) newTemplateId = await addNewTemplate()
    if (context.template) newTemplateId = context.template.item_id
    const parent = newTemplateId || rootRefId.value
    const items = await Promise.all(context.newTasks.map((task) => addNewTaskItem(task, parent)))
    // update the dependency links
    const links = await Promise.all(
      context.newLinks.map((link) => {
        // remove the is new so it will save
        // delete link.isNew;
        data.value.links.push(link)
        // save the link the BE
        return saveLink(link)
      })
    )
    context.creatingTask = 0
    return {
      links,
      items
    }
  }

  const forceSaveItem = ({ id, changes }) => {
    return store.dispatch('Item/forceSave', {
      id,
      changes
    })
  }

  const updateTaskByType = (type = 'item', changes) => {
    return store.dispatch(`${c.capitalize(type)}/partialUpdate`, {
      selected: [
        {
          type,
          [`${type}_id`]: changes.id,
          ...changes.changes
        }
      ]
    })
  }

  const onUpdateTaskName = (taskId, name) => {
    const task = gantt.value.getTask(taskId)
    task.text = name
    // update in gantt
    gantt.value.updateTask(taskId)
    let id = taskId
    if (task.reference_type === 'stage') id = task.stage_id
    // update on the FE
    if (task.reference_type !== 'stage') {
      const refId = getRefId(id)
      store.dispatch('Quote/field', {
        explicit: true,
        skipAudit: true,
        skipLocalAudit: true,
        refId,
        changes: {
          cost_type_name: name
        }
      })
      forceSaveItem({
        id,
        changes: {
          [`${task.reference_type}_name`]: name,
          [`cost_type_name`]: name
        }
      })
      return
    }
    // update on the BE
    updateTaskByType(task.reference_type, {
      id,
      changes: {
        [`${task.reference_type}_name`]: name
      }
    })
  }

  const onChangeDate = async (task, date, field) => {
    const { parent, id, reference_type: referenceType } = task
    const dates = {
      start_date: task.start_date,
      end_date: task.end_date
    }
    dates[field] = date

    const { startDate: start, endDate: end } = adjustDates(dates.start_date, dates.end_date)
    const newStartDate = new Date(start)
    const newEndDate = new Date(end)
    const duration = gantt.value.calculateDuration({
      start_date: newStartDate,
      end_date: newEndDate
    })
    const newEnd = calculateEndDate(newStartDate, duration)
    const newEndAdjusted = moment(newEnd).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    const finalEndDate = new Date(newEndAdjusted)
    task.duration = duration
    task.end_date = newEndAdjusted
    eventBus.$emit('refresh-gantt-items', {
      items: [
        {
          start_date: newStartDate,
          end_date: finalEndDate,
          id
        }
      ]
    })
    const parentChanges = updateParent(parent, finalEndDate)
    if (parentChanges) saveChangesToParent(parentChanges)
    try {
      let path = 'schedule/saveScheduleChanges'
      let itemId = id
      let quoteId = null
      if (referenceType === 'stage') {
        path = 'schedule/saveStageScheduleChanges'
        itemId = getScopedId(id)
        quoteId = projectId.value
      }
      await store.dispatch('ajax', {
        path,
        data: {
          changes: [
            {
              start_date: newStartDate,
              end_date: newEndAdjusted,
              id: itemId,
              quote_id: quoteId
            }
          ]
        }
      })
      store.dispatch('alert', {
        message: 'Schedule changes have been saved.'
      })
    } catch (e) {
      store.dispatch('alert', {
        message: e.message || 'We could not update assignees',
        error: true
      })
    }
  }

  const adjustTaskDateRange = (task, date, field) => {
    if (!date || !field) return
    onChangeDate(task, date, field)
  }

  /**
   * Mark all the items completed
   * @param {Array} items
   * @returns
   */
  const markAllComplete = (items) => items.forEach((item) => updateStatus(statuses.Completed, item))

  // Return
  return {
    ...toRefs(context),
    // utility
    getRefId,
    scopeId,
    idIsScoped,
    getScopedParent,
    getScopedId,
    // changes
    updateTaskByType,
    saveCostItemField,
    saveItemField,
    getLinksForTask,
    getLinksForItem,
    updateLinks,
    saveDuration,
    updateStatus,
    readjust,
    onChangeWorkdays,
    updateTask,
    removeTask,
    deleteTask,
    updateParent,
    saveChangesToParent,
    addAnotherTask,
    cancelAddingTasks,
    onCreateTask,
    confirmAddingTask,
    adjustTaskDateRange,
    formatNewTask,
    forceSaveItem,
    // handlers
    onLinkDeleted,
    onLinkAdded,
    onLinkUpdated,
    onAssignStage,
    onAssignAssignees,
    onSetToTemplate,
    onChangeDate,
    onAssignment,
    markAllComplete,
    onUpdateTaskName
  }
}
