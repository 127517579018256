<template>
  <div class="scheduler absolute inset-0 w-full h-full">
    <!-- The Gantt Chart -->
    <Gantt
      v-if="!loadingSchedule"
      :data="data"
      :columns="columns"
      :refId="reference"
      @on-ready="onReady"
      @task-dragged="onTaskDragged"
      @item-click="onItemClick"
      @link-added="onLinkAdded"
      @link-deleted="onLinkDeleted"
      @link-updated="onLinkUpdated"
      @row-dragged="onRowDragged"
      @auto-schedule-update="onAutoScheduleUpdate"
      @clear-filters="onClearFilters"
      @reload-data="reloadData"
      @reload="reload"
      @delete-stage="onDeleteStage"
      :onTaskLayerRender="onTaskLayerRender"
      :onTooltipRender="onTooltipRender"
      :onBarRender="onBarRender"
      :selectable="!!projectId"
      :filters="filters"
      :hideTaskActions="!isProjectSchedule"
      :goToToday="goToToday"
      :hideFilters="!isProjectSchedule"
      :hideGroupBy="!isProjectSchedule"
      :hideExpandAll="!isProjectSchedule"
      :lazyLoad="!isProjectSchedule"
      :correctWorkTime="true"
      :startDate="projectStartDate"
      :endDate="projectEndDate"
    >
      <template #gantt-actions-top>
        <div class="flex items-center p-2 gap-2">
          <div class="text-2xl font-medium">Schedules</div>
          <Tag severity="info">Beta</Tag>
        </div>
      </template>
      <template #gantt-actions>
        <div class="flex items-center">
          <Btn v-if="projectId" severity="tertiary" size="lg" class="mr-2" :action="onCreate">
            <template #icon>
              <font-awesome-icon :icon="['far', 'list-check']" />
            </template>
            Create
          </Btn>
        </div>
      </template>
      <template v-if="projectId" #filters>
        <h4 class="mb-2">Filter by</h4>
        <h5 class="mb-3">Status</h5>
        <div
          v-for="status of itemStatuses"
          :key="status.status"
          class="flex align-items-center gap-2 my-2"
        >
          <Checkbox
            :inputId="status.status"
            @input="(val) => handleFilterSelect('item_status', val, status.status)"
            name="filter-statuses"
            :value="filters.item_status.includes(status.status)"
          />
          <label :for="status.status">
            {{ status.name }}
          </label>
        </div>
        <h5 class="mt-5 mb-3">Priority</h5>
        <div
          v-for="priority of priorities"
          :key="priority.key"
          class="flex align-items-center gap-2 my-2"
        >
          <Checkbox
            :inputId="priority.key"
            @input="(val) => handleFilterSelect('priority', val, priority.key)"
            name="filter-priority"
            :value="filters.priority.includes(priority.key)"
          />
          <label :for="priority.key">
            {{ priority.label }}
          </label>
        </div>
        <div class="mt-5">
          <h5 class="my-4">Assignees</h5>
          <Assignees
            @assign="
              (users) => {
                assignees = users
                onFilterByAssignees(users)
              }
            "
            :asField="true"
            placeholder="Assignees"
            btnClass="!h-10"
            :value="assignees"
          />
        </div>
      </template>
      <template #multi-actions="{ selected }">
        <ButtonGroup class="flex">
          <Btn
            @click="clearSelected"
            :pt="{ root: 'bg-white hover:!bg-cool-gray-100' }"
            size="lg"
            severity="secondary"
            v-tooltip.bottom="'Clear selected items'"
          >
            <template #icon>
              <FontAwesomeIcon :icon="['far', 'xmark']" />
            </template>
            {{ selected.length || 0 }} selected
          </Btn>
          <Choose
            @close="onAssignMultiple"
            :multiple="true"
            :return-array="true"
            schema="assignee:assignee_id"
          >
            <Btn
              :pt="{ root: 'bg-white hover:!bg-cool-gray-100' }"
              size="lg"
              severity="secondary"
              v-tooltip.bottom="'Assign to assignees'"
            >
              <template #icon>
                <FontAwesomeIcon :icon="['far', 'users']" />
              </template>
            </Btn>
          </Choose>
          <Choose
            @input="onSetStageMultiple"
            schema="cost_type:stage_id"
            :order="[['stage_order', 'asc']]"
          >
            <Btn
              :pt="{ root: 'bg-white hover:!bg-cool-gray-100' }"
              size="lg"
              severity="secondary"
              v-tooltip.bottom="'Assign to stage'"
            >
              <template #icon>
                <FontAwesomeIcon :icon="['far', 'list-check']" />
              </template>
            </Btn>
          </Choose>
          <TaskTemplate @input="onSetAssemblyMultiple">
            <Btn
              :pt="{ root: 'bg-white hover:!bg-cool-gray-100' }"
              size="lg"
              severity="secondary"
              v-tooltip.bottom="'Add to assembly'"
            >
              <template #icon>
                <FontAwesomeIcon :icon="['far', 'boxes-stacked']" />
              </template>
            </Btn>
          </TaskTemplate>
        </ButtonGroup>
      </template>
    </Gantt>
    <!-- End The Gantt Chart -->

    <div class="p-4" v-if="loadingSchedule">
      <div class="flex flex-col">
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
        <Skeleton height="3rem" class="mb-2" borderRadius="3px"></Skeleton>
      </div>
    </div>

    <!-- The Item Modal -->
    <mini-modal
      :size="modalSize"
      classes="white item-modal"
      @close="selected = null"
      scrollable
      :pt="{
        header: '!bg-white',
        content: '!bg-white'
      }"
      ref="taskModal"
    >
      <template #header>
        <h2 class="font-black">Task details</h2>
      </template>
      <template #body>
        <ItemModal />
      </template>
    </mini-modal>
    <!-- End The Item Modal -->

    <!-- The Stage Modal -->
    <mini-modal
      size="lg"
      @close="selected = null"
      :pt="{
        header: '!bg-white',
        content: '!bg-white'
      }"
      scrollable
      classes="white stage-modal"
      ref="stageModal"
    >
      <template #header>
        <h2 v-if="selected" class="font-black" name="title">Stage details</h2>
      </template>
      <template #body>
        <StageModal />
      </template>
    </mini-modal>
    <!-- End The Stage Modal -->
  </div>
</template>

<script setup>
import {
  defineProps,
  toRefs,
  ref,
  createApp,
  computed,
  onMounted,
  onUnmounted,
  reactive
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@/fontAwesome'
import appRouter from '@/router'

// util
import Status from '../../../imports/api/Statuses'
import $f from '@/filters'
import eventBus from '@/eventBus'

// composables
import useGantt from '@/components/ui/gantt/Gantt'
import useGantts from '@/components/ui/gantt/Gantts'
import useSchedule from '@/components/schedule/Schedule'
import useStage from '@/components/schedule/Stage'
import useTask from '@/components/schedule/Task'
import useApproval from '@/components/composables/Approval'
import useAssignees from '@/components/composables/Assignees'
import useBreakpoint from '@/components/composables/Breakpoint'

// primeVue
import PrimeVue from 'primevue/config'
import bolsterTheme from '@/theme/presets/bolster'
import Tooltip from 'primevue/tooltip'
import InputText from 'primevue/inputtext'
import ProgressBar from 'primevue/progressbar'
import ButtonGroup from 'primevue/buttongroup'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Chip from 'primevue/chip'
import Button from 'primevue/button'

// ui
import Gantt from '@/components/ui/gantt/Gantt.vue'
import Btn from '@/components/ui/Btn.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import Choose from '@/components/ui/Choose/Choose.vue'
import BtnGroup from '@/components/ui/BtnGroup.vue'
import Fade from '@/components/transitions/TransitionFade.vue'
import Hotkey from '@/components/ui/Hotkey.vue'
import Icon from '@/components/ui/Icon.vue'

// modals
import ItemModal from '@/components/schedule/items/ItemModal.vue'
import StageModal from '@/components/schedule/stages/StageModal.vue'

// fields
import CreateTask from '@/components/schedule/fields/CreateTask.vue'
import Drop from '@/components/ui/Drop.vue'
import TaskName from '@/components/schedule/fields/TaskName.vue'
import StartDate from '@/components/schedule/fields/StartDate.vue'
import ProgressIndicator from '@/components/progress/ProgressIndicator.vue'
import ProgressBarComp from '@/components/schedule/fields/ProgressBar.vue'
import TaskActions from '@/components/schedule/fields/TaskActions.vue'
import TaskNote from '@/components/schedule/fields/TaskNote.vue'
import TaskTemplate from '@/components/schedule/fields/TaskTemplate.vue'
import Assignees from '@/components/schedule/fields/Assignees.vue'
import ClientView from '@/components/schedule/fields/ClientView.vue'

// props
const props = defineProps({
  projectId: {
    type: [String, Number, null]
  },
  reference: {
    type: String
  },
  startDate: {
    type: [Number, Date]
  },
  endDate: {
    type: [Number, Date]
  },
  goToToday: {
    type: Boolean,
    default: false
  }
})

// global composables
const router = useRouter()
const route = useRoute()
const store = useStore()
const { session } = useApproval()

// reactive local state
const stageModal = ref()
const taskModal = ref()
const materialListModal = ref()
const assignees = ref([])
const { projectId } = toRefs(props)

// local composables
const {
  data,
  gantt,
  selected: selectedItems,
  settings,
  setData,
  setColumns,
  setFilters,
  clearSelected,
  mountVueInstance
} = useGantt()

const { getGantt, clearGantt } = useGantts()

const {
  calculateEndDate,
  formatDuration,
  formatDateRange,
  adjustDates,
  saveProjectDates,
  reportChanges,
  onReady,
  onAutoScheduleUpdate,
  onClearFilters,
  reloadData,
  reload,
  setProject,
  setRootRefId,
  setProjectId,
  loadSchedule,
  onFilterByAssignees,
  filters,
  selected,
  loadingSchedule,
  hoursPerDayTotal
} = useSchedule()

const {
  onAssignStage,
  statuses: itemStatuses,
  priorities,
  onCreateTask,
  onAssignAssignees,
  updateParent,
  saveChangesToParent,
  onLinkAdded,
  onLinkDeleted,
  onLinkUpdated,
  onUpdateTaskName,
  adjustTaskDateRange,
  onSetToTemplate
} = useTask()

const {
  updateChildren,
  saveChangesToChildren,
  onUpdateStageOrderOfOperation,
  onCreateStage,
  onDeleteStage
} = useStage()

const { mapSelectedAssigneeToAssignees } = useAssignees()

// computed fields

const companyId = computed(() => store.state.session.company.company_id)

const isProjectSchedule = computed(() => (projectId.value ? true : false))

const projectStartDate = computed(() => {
  const startDate = props.startDate || data.value?.project?.startDate
  // Check if startDate is a Date object
  if (startDate instanceof Date) return startDate

  // If startDate is a number or numeric string, convert it to a Date
  if (typeof startDate === 'number' || !isNaN(startDate)) {
    const timestamp = Number(startDate)

    // Check if it's in seconds or milliseconds
    if (timestamp < 1e12) {
      return new Date(timestamp * 1000) // Convert from seconds to milliseconds
    } else {
      return new Date(timestamp) // Already in milliseconds
    }
  }

  // Return null if it's not a valid date/timestamp
  return null
})

const projectEndDate = computed(() => {
  const endDate = props.endDate || data.value?.project?.endDate
  // Check if endDate is a Date object
  if (endDate instanceof Date) return endDate

  // If endDate is a number or numeric string, convert it to a Date
  if (typeof endDate === 'number' || !isNaN(endDate)) {
    const timestamp = Number(endDate)

    // Check if it's in seconds or milliseconds
    if (timestamp < 1e12) {
      return new Date(timestamp * 1000) // Convert from seconds to milliseconds
    } else {
      return new Date(timestamp) // Already in milliseconds
    }
  }

  // Return null if it's not a valid date/timestamp
  return null
})

const columns = computed(() => [
  ...(isProjectSchedule.value
    ? [
        {
          name: 'actions',
          label: '',
          width: 62,
          min_width: 62,
          resize: false,
          onrender: (item, node) => renderActions(item, node)
        }
      ]
    : []),
  {
    name: 'text',
    label: 'Name',
    tree: false,
    width: 230,
    min_width: 230,
    resize: true,
    onrender: (item, node) => renderTaskName(item, node)
  },
  ...(isProjectSchedule.value
    ? [
        {
          name: 'progress',
          label: 'Progress',
          align: 'center',
          width: 130,
          min_width: 130,
          resize: false,
          onrender: (item, node) => renderProgressIndicator(item, node)
        },
        {
          name: 'startDate',
          label: 'Start date',
          align: 'center',
          width: 70,
          min_width: 70,
          resize: false,
          onrender: (item, node) => renderDateRange(item, node)
        },
        {
          name: 'assignee',
          label: 'Assign',
          width: 50,
          min_width: 50,
          type: 'select',
          align: 'center',
          resize: false,
          onrender: (item, node) => renderAssigneeSelect(item, node)
        }
      ]
    : [
        {
          name: 'status',
          label: 'Project',
          align: 'center',
          width: 75,
          min_width: 75,
          resize: false,
          template: ({ status }) =>
            `<div class="inset-0 w-full h-full text-xs flex justify-center items-center text-center text-${Status.statusColors[status || 'p']}-600 bg-${Status.statusColors[status || 'p']}-100">${Status.statuses[status || 'p'].toLowerCase()}</div>`
        },
        {
          name: 'client',
          label: 'Client',
          align: 'center',
          width: 50,
          min_width: 50,
          resize: false,
          onrender: (item, node) => renderClient(item, node)
        }
      ])
])

const { isMobile, isTablet } = useBreakpoint()

const modalSize = computed(() => {
  return isTablet.value || isMobile.value ? 'lg' : 'md'
})

// methods

/**
 * Assign assignees to multiple items
 */
const onAssignMultiple = (assigneeIds) => {
  const assignees = mapSelectedAssigneeToAssignees(assigneeIds)
  onAssignAssignees(assignees, selectedItems.value)
  clearSelected()
}

/**
 * Set multiple items to a stage
 */
const onSetStageMultiple = async (stagId) => {
  const { object: stage } = await store.dispatch('Stage/resolveObject', { id: stagId, quick: true })
  onAssignStage(stage, selectedItems.value)
  clearSelected()
}

/**
 * Set multiple items to a new parent assembly
 */
const onSetAssemblyMultiple = (assembly) => {
  onSetToTemplate(assembly, selectedItems.value)
  clearSelected()
}

/**
 * When selecting filters update data
 */
const handleFilterSelect = (field, chosen, value) => {
  if (!filters.value[field]) return filters.value
  if (chosen) {
    filters.value[field].push(value)
    return filters.value
  }
  const index = filters.value[field].findIndex((v) => v === value)
  filters.value[field].splice(index, 1)
  return filters.value[field]
}

/**
 * Handle clicking an item bar
 */
const onItemClick = (item) => {
  const { id, reference_type: referenceType } = item
  // go to the project
  if (referenceType === 'project') {
    router.push(`/${route.params.scopeRoute}/project/${id}?tab=Schedule`)
  }

  if (!isProjectSchedule.value) return

  // open the stage modal
  if (referenceType === 'stage') {
    const childrenIds = gantt.value.getChildren(id)
    const children = childrenIds.map((i) => gantt.value.getTask(i))
    openStage({ ...item, children })
  }
  // open item
  if (referenceType === 'item') {
    openItem(item)
  }
  // open material list
  if (referenceType === 'list') {
    openMaterialList(item)
  }
}

/**
 * Handle opening stage
 */
const openStage = (item) => {
  selected.value = item
  stageModal.value.open()
}

/**
 * Handle opening material list
 */
const openMaterialList = (item) => {
  selected.value = item
  materialListModal.value.open()
}

/**
 * Handling opening task item modal
 */
const openItem = (item) => {
  selected.value = item
  taskModal.value.open()
}

/**
 * Handle after row is dragged
 */
const onRowDragged = ({ id, item, parent }) => {
  const task = gantt.value.getTask(id)
  if (item.reference_type === 'item') onAssignStage(parent, [id])
  if (item.reference_type === 'stage') onUpdateStageOrderOfOperation(task)
}

/**
 * Handle rendering of the tooltip
 */
const onTooltipRender = (start, end, item) => {
  const {
    text,
    vendor,
    item_status: itemStatus,
    completed_count: completedCount,
    total_count: totalCount,
    start_date: startDate,
    end_date: endDate,
    asAssemblyPath,
    skipDuration,
    reference_type: refType,
    priority,
    duration,
    address,
    client,
    item_total_hours: itemTotalHours,
    total_hours: stageTotalHours
  } = item
  // find the status object to get label
  const itemStatusLabel = itemStatuses.value.find(({ status: s }) => s === itemStatus)
  // find the priority to get label
  const priorityObj = priorities.value.find((i) => i.key === priority)
  // get the duration to display
  const formattedDuration = duration
    ? formatDuration(duration)
    : formatDateRange({
        startDate,
        endDate
      })
  let formattedEnd = moment(end).format('ddd, MMM D')
  const formattedStart = moment(start).format('ddd, MMM D')
  if (!skipDuration)
    formattedEnd = moment(calculateEndDate(startDate, duration)).format('ddd, MMM D')
  // if the type of item project
  if (refType === 'project') {
    return `
        <div class="gantt-tooltip text-sm min-w-40">
          <p class="mb-2 flex text-[1.4em] items-center">
            ${text}
          </p>
          <ul class="list-group">
            <li class="flex flex-col  justify-between items-start">
              <p><b>Address</b></p>
              ${address}
            </li>
            <li class="flex flex-col  justify-between items-start">
              <p><b>Client</b></p>
              ${client.client_name}
            </li>
            <li class="flex flex-col  justify-between items-start">
              <p><b>Dates</b></p>
              ${formattedStart} - ${formattedEnd}
            </li>
            <li class="flex flex-col  justify-between items-start">
              <p><b>Workdays</b></p>
              ${formattedDuration}
            </li>
          </ul>
           <p class="mt-2 text-blue-print">Click to view details</p>
        </div>`
  }
  // if the type of item is a stage or project
  if (refType === 'stage') {
    return `
        <div class="gantt-tooltip text-sm min-w-40">
          <p class="mb-2 flex text-[1.4em]">
            ${text}
          </p>
          <ul class="list-group">
            <li class="flex flex-col justify-between items-start mb-2 gantt-lead">
              <p><b>Completion</b></p>
              ${completedCount} of ${totalCount} completed
            </li>
            <li class="flex flex-col  justify-between items-start mb-2">
              <p><b>Dates</b></p>
              ${formattedStart} - ${formattedEnd}
            </li>
            <li class="flex flex-col  justify-between items-start">
              <p><b>Total working stage hours:</b></p>
              <span class="d-flex align-items-center">
              ${Math.ceil(stageTotalHours)} hr${stageTotalHours > 1 ? 's' : ''} (${Math.ceil(stageTotalHours)} / ${hoursPerDayTotal.value}) workdays
              </span>
            </li>
          </ul>
          <p class="mt-2 text-blue-print">Click to view details</p>
        </div>`
  }
  // if the type of item is an item
  let path = ''
  const paths = asAssemblyPath
    ? asAssemblyPath.slice(asAssemblyPath.length - 3, asAssemblyPath.length - 1)
    : []
  if (paths.length > 1) {
    paths.forEach((p) => {
      path += `<span>${p}</span>`
    })
  }

  return `
      <div class="gantt-tooltip text-sm min-w-40">
        ${path ? `<small class="gantt-item--path flex mb-3 text-muted">${path}</small>` : ''}
        <p class="w-100 flex text-[1.4em] items-center mb-3">
          ${text}
        </p>
        <ul class="list-group">
          <li class="flex flex-col justify-between items-start mb-2">
            <p><b>Status</b></p>
            <span class="flex">
              <span class="rounded px-2 py-1 ${itemStatusLabel && itemStatusLabel.tagClasses}">${itemStatusLabel ? itemStatusLabel.name : 'Not started'}</span>
              <span class="ml-2 rounded px-2 py-1 ${priorityObj ? priorityObj.tagClasses : 'bg-cool-gray-100 dark:bg-cool-gray-400 text-cool-gray-900'}">${priorityObj ? priorityObj.label : 'Low'}</span>
            </span>
          </li>
          <li class="flex flex-col  justify-between items-start mb-2">
            <p><b>Dates</b></p>
            ${formattedStart} - ${formattedEnd}
          </li>
          <li class="flex flex-col justify-between items-start mb-2">
            <p><b>Total EST. hours:</b></p>
            <span class="d-flex align-items-center">
            ${Math.ceil(itemTotalHours || 0)} hr${itemTotalHours > 1 ? 's' : ''} (${Math.ceil(itemTotalHours || 0)} / ${hoursPerDayTotal.value}) workdays
            </span>
          </li>
          <li class="flex flex-col  justify-between items-start">
            <p><b>Assignee</b></p>
            ${vendor ? vendor.vendor_name : 'unassigned'}
          </li>
        </ul>
        <p class="mt-2 text-blue-print">Click to view details</p>
      </div>`
}

/**
 * Handle after task is dragged
 */
const onTaskDragged = async ({ before, after }) => {
  c.throttle(
    () => {
      const {
        id: itemId,
        start_date: startDate,
        reference_type: referenceType,
        duration,
        stage_id: stageId,
        item_id: refId,
        parent
      } = after
      const adjustedEndDate = calculateEndDate(startDate, duration)
      const { startDate: start, endDate: end } = adjustDates(startDate, adjustedEndDate)
      // turned on project drag which triggers saving the stages so just return null for project
      if (referenceType === 'project') {
        // clear the stored version
        clearGantt(itemId)
        return store.dispatch('ajax', {
          path: 'schedule/saveProjectScheduleChanges',
          data: {
            id: itemId,
            startDate: start,
            endDate: end
          }
        })
      }

      // changes to stage's items
      if (referenceType === 'stage') {
        const quoteId = itemId.split('_')[0]
        const childrenChanges = updateChildren(itemId, new Date(end), new Date(start))
        if (childrenChanges) saveChangesToChildren(childrenChanges)
        const changes = [
          {
            id: stageId,
            quote_id: quoteId,
            start_date: start,
            end_date: end
          }
        ]
        // save the change to the schedule on the b-e
        store.dispatch('ajax', {
          path: 'schedule/saveStageScheduleChanges',
          data: {
            changes
          }
        })
        return saveProjectDates()
      }
      // we need to refresh the stage if the children end dates are larger than stage
      if (referenceType === 'item' || referenceType === 'list') {
        const changes = updateParent(parent, new Date(end))
        if (changes) {
          saveChangesToParent(changes)
          const parentStart = changes[0].start_date
          const childChanges = updateChildren(parent, new Date(end), new Date(parentStart))
          if (childChanges) saveChangesToChildren(childChanges)
        }
      }
      // single item changes
      const changes = [
        {
          id: referenceType === 'list' ? refId : itemId,
          start_date: start,
          end_date: end
        }
      ]
      reportChanges({
        before: [
          {
            id: referenceType === 'list' ? refId : itemId,
            start_date: before.start_date,
            end_date: before.end_date
          }
        ],
        after: changes
      })

      // save the change to the schedule on the b-e
      store.dispatch('ajax', {
        path: 'schedule/saveScheduleChanges',
        data: {
          changes
        }
      })
      return saveProjectDates()
    },
    { delay: 400 }
  )
}

const primeVueConfig = (app) => {
  app.use(PrimeVue, {
    unstyled: true,
    pt: bolsterTheme
  })
}

/**
 * Render client component into column
 */
const renderClient = (item, element) => {
  const { client } = item
  const key = `${item.id}_client`
  const props = { client }

  const createInstance = () => {
    const instance = createApp(ClientView, props)
    primeVueConfig(instance)
    instance.use(store)
    instance.component('Drop', Drop)
    instance.component('BtnGroup', BtnGroup)
    instance.component('FontAwesomeIcon', FontAwesomeIcon)
    instance.component('Btn', Btn)
    instance.component('Button', Button)
    instance.component('Hotkey', Hotkey)
    instance.component('Fade', Fade)
    instance.mount(element.firstChild)
    return instance
  }

  mountVueInstance(key, props, element, createInstance)
}

const renderActions = (item, element) => {
  if (item.reference_type === 'project') return
  const key = `${item.id}_action`
  const props = reactive({
    item, // Passing the item as a prop
    edit: (item) => (item.reference_type === 'item' ? openItem(item) : openStage(item)) // Handling edit action
  })

  const createInstance = () => {
    // Create a new Vue instance with the TaskActions component
    const instance = createApp(TaskActions, props)
    // Apply PrimeVue configuration and register components/directives
    primeVueConfig(instance)
    instance.use(store)
    instance.component('Checkbox', Checkbox)
    instance.directive('tooltip', Tooltip)
    instance.component('FontAwesomeIcon', FontAwesomeIcon)
    instance.component('CreateTask', CreateTask)
    instance.component('Btn', Btn)
    instance.component('Button', Button)
    instance.component('Hotkey', Hotkey)
    instance.component('Drop', Drop)
    instance.component('Fade', Fade)
    return instance
  }

  mountVueInstance(key, props, element, createInstance)
}

const renderTaskName = (item, element) => {
  if (!isProjectSchedule.value) {
    return `<span class="px-3">${item.text}</span>`
  }
  const key = `${item.id}_task_name`
  const props = reactive({
    item,
    update: (val) => onUpdateTaskName(item.id, val)
  })

  const createInstance = () => {
    const instance = createApp(TaskName, props)
    primeVueConfig(instance)
    instance.use(store)
    instance.component('InputText', InputText)
    instance.directive('tooltip', Tooltip)
    instance.component('FontAwesomeIcon', FontAwesomeIcon)
    return instance
  }

  mountVueInstance(key, props, element, createInstance)
}
/**
 * Render assignee select into column
 */
const renderAssigneeSelect = (item, element) => {
  const { assignees, id, reference_type: type } = item
  if (type !== 'item') return

  const key = `${item.id}_assignee_select`
  const props = reactive({
    value: assignees,
    size: 'small',
    center: false,
    onAssign: (selected) => onAssignAssignees(selected, [id])
  })

  const createInstance = () => {
    const instance = createApp(Assignees, props)
    primeVueConfig(instance)
    instance.use(store)
    instance.use(appRouter)
    instance.component('FontAwesomeIcon', FontAwesomeIcon)
    instance.component('Btn', Btn)
    instance.component('Button', Button)
    instance.component('Hotkey', Hotkey)
    instance.component('Choose', Choose)
    instance.component('Avatar', Avatar)
    instance.component('Icon', Icon)
    instance.component('AvatarGroup', AvatarGroup)
    instance.component('Chip', Chip)
    return instance
  }

  mountVueInstance(key, props, element, createInstance)
}

const renderProgressIndicator = (item, element) => {
  const key = `${item.id}_progress_indicator`
  // stage progress indicator
  if (item.reference_type === 'stage') {
    const props = reactive({
      progress: item.progress * 100
    })
    const createInstance = () => {
      const instance = createApp(ProgressBarComp, props)
      primeVueConfig(instance)
      instance.component('ProgressBar', ProgressBar)
      instance.use(store)
      return instance
    }
    mountVueInstance(key, props, element, createInstance)
    return
  }
  // item progress indicator
  const props = {
    item
  }
  const createInstance = () => {
    const instance = createApp(ProgressIndicator, props)
    primeVueConfig(instance)
    instance.component('FontAwesomeIcon', FontAwesomeIcon)
    instance.directive('tooltip', Tooltip)
    instance.use(store)
    return instance
  }
  mountVueInstance(key, props, element, createInstance)
}

const renderDateRange = (item, element) => {
  const key = `${item.id}_date_range`
  const props = reactive({
    item,
    input: (date, field) => adjustTaskDateRange(item, date, field)
  })
  const createInstance = () => {
    const instance = createApp(StartDate, props)
    primeVueConfig(instance)
    instance.component('DatePicker', DatePicker)
    instance.component('FontAwesomeIcon', FontAwesomeIcon)
    instance.component('Drop', Drop)
    instance.component('Btn', Btn)
    instance.component('Button', Button)
    instance.component('Hotkey', Hotkey)
    instance.component('Fade', Fade)
    instance.use(store)
    return instance
  }
  mountVueInstance(key, props, element, createInstance)
}

const onBarRender = (start, end, task) => {
  let icon = null
  if (task.reference_type === 'item' && task.is_task) icon = 'square-check'
  if (task.reference_type === 'item' && !task.is_task) icon = 'box'
  if (task.reference_type === 'stage') icon = 'list-check'
  return `<span> ${icon ? `<i class="fal fa-${icon} mr-1"></i>` : ''} ${$f.truncate(task.text, 20)}</span>`
}

const onTaskLayerRender = () => {
  gantt.value.addTaskLayer({
    renderer: {
      render: (task) => {
        if (task.reference_type !== 'item') return
        if (!task.internal_notes) return
        const sizes = gantt.value.getTaskPosition(task, task.start_date, task.end_date)
        const el = document.createElement('div')
        el.className = `task-note--container note-${task.isTask ? 'task' : 'item'}`
        el.style.left = `${sizes.left + sizes.width - 15}px`
        el.style.height = `${sizes.height}px`
        el.style.top = `${sizes.top + 7}px`
        return el
      },
      onrender: (item, node) => {
        const instance = createApp(TaskNote, {
          item
        })
        // instance.use(store)
        primeVueConfig(instance)
        instance.component('FontAwesomeIcon', FontAwesomeIcon)
        instance.directive('tooltip', Tooltip)
        return instance.mount(node)
      }
    }
  })
}

const fetchSchedule = async () => {
  try {
    const { payload } = await loadSchedule()
    setData({
      data: [...data.value.data, ...(payload.data || [])],
      links: [...data.value.links, ...(payload.links || [])],
      resources: [...data.value.resources, ...(payload.resources || [])]
    })
  } catch (error) {
    store.dispatch('alert', {
      error: true,
      message: error.userMessage || 'Cannot load the schedule.'
    })
  }
}

const onCreate = async () => {
  const choice = await store.dispatch('modal/asyncConfirm', {
    message: 'What would you like to create?',
    choices: [
      {
        icon: 'list-check',
        title: 'New stage',
        desc: 'Create a custom construction stage and drag to define order of operation.',
        value: 'stage'
      },
      {
        icon: 'square-check',
        title: 'New task',
        desc: 'Create a new task, add the task to an assembly to save in a task list for future user.',
        value: 'task'
      }
    ]
  })

  if (choice === 'task') await onCreateTask()

  if (choice === 'stage') await onCreateStage()
}

onMounted(async () => {
  setProjectId(projectId.value)

  // events
  eventBus.$on(`fetch-schedule-${projectId.value}`, async () => {
    await fetchSchedule()
    gantt.value.parse(data.value)
  })

  // set the project information for gantt
  const rootRefId = await store.dispatch('Quote/getRootRefId', {})
  setProject(store.state.Quote.normalized[rootRefId])
  setRootRefId(rootRefId)
  setFilters(filters.value)

  // approval session data
  session.value.role = 'company'
  session.value.userId = companyId.value
  // set settings
  if (store.state.session.company.asWorkdays && store.state.session.company.asWorkdays.length > 0)
    settings.value.asWorkdays = store.state.session.company.asWorkdays
  if (store.state.session.company.oWorkingHours)
    settings.value.oWorkingHours = store.state.session.company.oWorkingHours
  // check if cached gantt exists
  const existing = getGantt(props.reference)
  if (existing) {
    loadingSchedule.value = 0
    return
  }

  loadingSchedule.value = 1
  // load the schedule

  await fetchSchedule()
  // set columns for gantt
  setColumns(columns.value)
  loadingSchedule.value = 0
})

onUnmounted(() => {
  eventBus.$off(`fetch-schedule-${projectId.value}`)
})
</script>

<style lang="scss" rel="stylesheet/scss">
// item path for the tooltips
.gantt-item--path {
  > span:not(:first-of-type) {
    display: flex;
    &::before {
      content: '/';
      padding: 0 0.3em;
      display: block;
    }
  }
}

// notes on the task bars
.task-note--container {
  @apply absolute w-4 z-10 transition cursor-pointer rounded-r-sm flex items-center justify-center;
  &.note-task {
    background-color: $matcha-200;
  }
  &.note-item {
    @apply bg-teal-200;
  }
  svg {
    @apply text-pitch-black;
  }
}

// avoid padding for the company wide schedule status
.gantt_cell[data-column-name='status'] {
  padding: 0 !important;
}
</style>
