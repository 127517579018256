export default {
  root: ({ props }) => ({
    class: [
      //Font
      'text-xs font-medium',

      //Alignments
      'inline-flex items-center justify-center',

      //Spacing
      'px-1.5 py-0.25',

      //Shape
      {
        'rounded-sm': !props.rounded,
        'rounded-full': props.rounded
      },

      //Colors
      'dark:flame-white',
      'border',
      {
        'border-primary-500 dark:border-primary-400 text-primary-800':
          props.severity == null || props.severity == 'primary',
        'border-green-500 dark:border-green-400 text-green-800': props.severity == 'success',
        'border-blue-500 dark:border-blue-400 text-blue-print-700': props.severity == 'info',
        'border-orange-500 dark:border-orange-400 text-orange-700': props.severity == 'warning',
        'border-red-500 dark:border-red-400 text-red-700': props.severity == 'danger',
        'bg-pitch-black text-level-yellow': props.severity == 'bolster'
      }
    ]
  }),
  value: {
    class: 'leading-normal'
  },
  icon: {
    class: 'mr-1 text-sm'
  }
}
