<template>
  <div class="review-all--assembly">
    <div class="review-all-assembly--content">
      <div @click="() => (toggle = !toggle)" class="assembly-name">
        <font-awesome-icon
          :icon="toggle ? 'chevron-down' : 'chevron-right'"
          class="chevron-icon close-section mr-2"
        />
        {{ object.assembly_name }}
      </div>
      <div
        class="assembly-totals text-muted"
        v-if="(showQty && (qty > 1 || qty < 1)) || showPrice || !qty"
        :class="!qty ? 'excluded' : ''"
      >
        <template v-if="!qty">
          {{ l('not included') }}
        </template>
        <template v-else>
          <template v-if="showQty && qty > 1">
            {{ $f.number(qty) }} {{ l('each') }}
            <span v-if="showPrice" class="mx-2">•</span>
          </template>

          <template v-if="showPrice">
            {{ $f.currency(price) }}
          </template>
        </template>
      </div>
    </div>

    <div v-show="toggle" class="review-all--children">
      <ReviewAllItem
        v-for="ref in object.aoChildren"
        :key="ref"
        :parentRefId="parentRefId"
        :object="normalized[ref]"
        :normalized="normalized"
        :presentationSettings="presentationSettings"
        :refId="ref"
      />
    </div>
  </div>
</template>

<script>
import ReviewAllItem from './ReviewAllItem.vue'
import PresentationAssemblyMixin from '../presentation/PresentationAssemblyMixin'
import TranslationMixin from '../presentation/languages/TranslationMixin'

export default {
  name: 'ReviewAllAssembly',
  mixins: [PresentationAssemblyMixin, TranslationMixin],
  data() {
    return {
      objectLocal: this.object || {},
      toggle: false
    }
  },
  components: {
    ReviewAllItem
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    presentationSettings: {
      type: Object
    },
    store: {
      type: String,
      default: 'Quote'
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    normalized: {
      type: Object
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.review-all--assembly {
  width: 100%;
  .review-all-assembly--content {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $cool-gray-400;
    padding: 0.5em 0;
    .assembly-totals {
      font-size: 0.9em;
      font-style: italic;
    }
  }
  .assembly-name {
    cursor: pointer;
  }
  .review-all--children {
    padding-left: 0.75em;
    @media (max-width: 576px) {
      padding-left: 0.4em;
    }
  }
}
</style>
