<template>
  <modal v-bind="$props" class="modal-mini" ref="modal" size="lg" :full="false" :clickAway="false">
    <template #header>
      <h2>Review Terms & approval</h2>
    </template>
    <template #body>
      <terms-and-conditions
        v-bind="modal"
        ref="body"
        :termsAccepted="termsAccepted"
        @declined="termsDeclined"
      />
    </template>
  </modal>
</template>

<script>
import TermsAndConditions from '../bodies/TermsAndConditions.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalTermsAndConditions',
  mixins: [ObjectModalMixin],
  methods: {
    termsAccepted({ terms, signature }) {
      return this.modal.saved.call(this, terms, signature)
    },
    termsDeclined(terms) {
      return this.modal.closed.call(this, terms)
    }
  },
  components: { TermsAndConditions },
  props: {
    type: {
      default: 'company'
    }
  }
}
</script>
