<template>
  <div class="relative">
    <ItemEmphasisControl :isInQuoteEditor="isInQuoteEditor" :object="object" v-if="isAssembly" />
    <Accordion
      v-if="isAssembly"
      :multiple="true"
      class="md:border-l-2 border-surface-200 my-1 md:mt-0"
      :active-index="getActiveIndex"
    >
      <AccordionTab
        :header="object.assembly_name"
        :selections="true"
        :disabled="disabledList.includes(refId)"
        :pt="{
          header: 'p-6 mb-1 font-medium md:font-normal',
          headerTitle: 'font-medium md:font-normal'
        }"
      >
        <div v-if="isInteractiveAssembly || hasAssemblyDescription" :id="refId">
          <SelectionsItem
            :ref-id="refId"
            :parent-ref-id="parentRefId"
            :dimensions="dimensions"
            :presentation-settings="presentationSettings"
            :editable="editable"
            :store="store"
            :disabled="disabled || disabledList.includes(refId)"
            :isInQuoteEditor="isInQuoteEditor"
          />
        </div>
        <div v-for="childRef of groups" :key="childRef" class="md:pl-2">
          <RecursiveSelectionsItem
            :ref-id="childRef"
            :parent-ref-id="norm[childRef].parentRefId"
            :dimensions="dimensions"
            :presentation-settings="presentationSettings"
            :editable="editable"
            :store="store"
            :interactiveRefs="interactiveRefs"
            :isInQuoteEditor="isInQuoteEditor"
            :disabledList="disabledList"
          />
        </div>
      </AccordionTab>
    </Accordion>
    <div v-else class="md:border-l-2 border-surface-200" :id="refId">
      <SelectionsItem
        :ref-id="refId"
        :parent-ref-id="parentRefId"
        :dimensions="dimensions"
        :presentation-settings="presentationSettings"
        :editable="editable"
        :store="store"
        :disabled="disabled || disabledList.includes(refId)"
        :isInQuoteEditor="isInQuoteEditor"
      />
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import SelectionsItem from './SelectionsItem.vue'
import eventBus from '@/eventBus'
import ItemEmphasisControl from '@/components/quote/presentation/ItemEmphasisControl.vue'

export default {
  name: 'RecursiveSelectionsItem',
  components: {
    ItemEmphasisControl,
    SelectionsItem,
    Accordion,
    AccordionTab
  },
  emits: ['add-to-disabled-list', 'remove-from-disabled-list'],
  data() {
    return {
      activeIndices: []
    }
  },
  props: {
    refId: {
      type: String,
      required: true
    },
    parentRefId: {
      type: String,
      required: true
    },
    dimensions: {
      type: Object
    },
    presentationSettings: {
      type: Object,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    store: {
      type: String,
      required: true
    },
    interactiveRefs: {
      type: Array,
      required: true
    },
    isInQuoteEditor: {
      type: Boolean,
      required: true
    },
    disabledList: {
      type: Array,
      required: true
    }
  },
  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },
    object() {
      return this.norm[this.refId]
    },
    preferredOrder() {
      const preferredOrder = [this.object.refId]
      const goDown = (children) => {
        children.forEach((childRef) => {
          preferredOrder.push(childRef)
          if (this.norm[childRef]?.aoChildren) goDown(this.norm[childRef]?.aoChildren)
        })
      }
      goDown(this.object.aoChildren)

      return preferredOrder
    },
    groups() {
      return _.uniq(this.object.aoChildren)
        .filter(this.shouldShowRef)
        .sort((a, b) => this.preferredOrder.indexOf(a) - this.preferredOrder.indexOf(b))
    },
    getActiveIndex() {
      return Array.from({ length: this.groups.length }, (_, i) => i)
    },
    isAssembly() {
      return this.object.type === 'assembly'
    },
    isInteractiveAssembly() {
      return this.interactiveRefs.includes(this.refId)
    },
    hasAssemblyDescription() {
      return this.isAssembly && (this.object.cost_type_desc || this.object.quote_notes)
    },
    disabled() {
      const hasMissingInputs = (this.object.oInputRequired?.inputs || []).some(
        (input) =>
          (input.required && !input.input.text) ||
          (!input.input.data && !Object.keys(input.input.data).length)
      )

      if (this.isAssembly && !this.object.assembly_is_included) {
        eventBus.$emit('add-to-disabled-list', this.refId)
      }
      return !!hasMissingInputs
    }
  },
  methods: {
    shouldShowRef(r) {
      if (this.editable) return true

      const item = this.norm[r]

      if (item.assembly_emphasis && item.assembly_emphasis < -2) return false
      if (item.cost_type_emphasis && item.cost_type_emphasis < 0) return false
      if (item?.oMeta?.itemType === 'task') return false

      return true
    }
  }
}
</script>
