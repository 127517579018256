<template>
  <Galleria
    v-if="uniqueImages && uniqueImages.length"
    v-model:activeIndex="galleryActiveIndex"
    :value="images"
    :responsiveOptions="responsiveOptions"
    :numVisible="numVisible"
    :thumbnailsPosition="thumbnailPosition"
    :hideArrows="hideArrows"
    :showThumbnails="showThumbnails"
    :circular="true"
    :pt="pt"
  >
    <template #item="{ item }">
      <div class="bg-flame-white w-full h-full flex justify-center items-center">
        <img
          class="bg-flame-white w-full max-h-96 min-h-96 object-contain rounded-sm"
          :src="item"
        />
      </div>
    </template>
    <template #thumbnail="{ item }">
      <img
        class="rounded-sm h-12 md:h-20 aspect-square transition"
        :class="
          item === uniqueImages[galleryActiveIndex] ? 'opacity-100' : 'opacity-25 hover:opacity-50'
        "
        :src="item"
      />
    </template>
  </Galleria>
</template>

<script setup>
import Galleria from 'primevue/galleria'
import { ref, defineProps, computed } from 'vue'
import _ from 'lodash'

const galleryActiveIndex = ref(0)

const props = defineProps({
  images: {
    required: true,
    default: []
  },
  numVisible: {
    required: false,
    default: 5
  },
  thumbnailPosition: {
    required: false,
    default: 'left'
  },
  responsiveOptions: {
    required: false,
    default: {}
  },
  hideArrows: {
    required: false,
    default: false
  },
  pt: {
    type: Object,
    default: () => ({})
  },
  showThumbnails: {
    type: Boolean,
    default: true
  }
})

const uniqueImages = computed(() => _.uniq(props.images))
</script>
