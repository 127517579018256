import _ from '../Helpers'
import * as Types from '../../../src/store/mutation-types'
import NormalizeUtilities from '../NormalizeUtilities'
import { v4 } from 'uuid'

const getPriceField = (addon) =>
  addon.type === 'assembly' ? 'quote_subtotal_net' : 'cost_item_price_net'

const getCostField = (addon) =>
  addon.type === 'assembly' ? 'quote_total_cost_net_base' : 'cost_matrix_aggregate_cost_net'

const getQtyField = (addon) =>
  addon.type === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'

const getLastModifiedField = (addon) =>
  addon.type === 'assembly' ? 'assembly_time_last_modified' : 'cost_type_time_last_modified'

const getEquationField = (addon) =>
  addon.type === 'assembly' ? 'assembly_qty_equation' : 'cost_type_qty_equation'

const getQuantityField = (addon) =>
  addon.type === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'

const getNameField = (addon) => (addon.type === 'assembly' ? 'assembly_name' : 'cost_type_name')

const getDescField = (addon) => (addon.type === 'assembly' ? 'quote_notes' : 'cost_type_desc')

const getIdField = (addon) => (addon.type === 'assembly' ? 'assembly_id' : 'cost_type_id')

const getMarkupField = (addon) =>
  addon.type === 'assembly' ? 'quote_markup_net' : 'cost_matrix_markup_net'

const getMarkup = (object) => {
  if (object.type === 'assembly') {
    return _.divide(object.quote_subtotal_net, object.quote_total_cost_net_base)
  }

  return object.cost_matrix_markup_net
}

const getAddonHasOverriddenMarkup = (addon) => {
  if ('hasOverriddenMarkup' in addon) return addon.hasOverriddenMarkup

  return (
    addon.embue &&
    (addon.embue.assembly_markup_percentage_adjustment ||
      addon.embue.quote_markup_percentage_adjustment ||
      addon.embue.cost_matrix_markup_net ||
      addon.embue.cost_item_markup_net_adjusted)
  )
}

const getMarkupAdjustment = (addon, audited, target) => {
  const targetPrice = target[getPriceField(target)]
  const addonCost = audited[getCostField(audited)]
  const addonPrice = audited[getPriceField(audited)]
  const addonOriginalMarkup = audited[getMarkupField(audited)]
  const addonTargetPrice = addon.targetPrice || targetPrice
  const addonTargetAdjustment = targetPrice - addonTargetPrice + (addon.targetPriceAdjustment || 0)
  let embue = {}

  if (getAddonHasOverriddenMarkup(addon) || _.eq(addonTargetAdjustment, 0, 2)) return embue

  const adjustedPrice = addonPrice + addonTargetAdjustment
  const targetMarkup = adjustedPrice / addonCost

  if (addon.type === 'assembly') {
    embue = {
      quote_markup_percentage_adjustment:
        (targetMarkup - 1 - (addonOriginalMarkup - 1)) /
        (_.eq(addonOriginalMarkup - 1, 0, 2) ? 1 : addonOriginalMarkup - 1)
    }
  } else {
    embue = {
      cost_item_markup_net_adjusted: targetMarkup
    }
  }

  return embue
}

const getTargetKey = (target) =>
  `${(+target[getPriceField(target)]).toFixed(3)}-${(+target[getCostField(target)]).toFixed(
    3
  )}-${(+target[getQtyField(target)]).toFixed(3)}`

const getQuantity = (object, target, addon) => {
  if (!target) {
    return {
      qty: 1,
      linkable: 0
    }
  }
  const currentType = object.type
  // const prevType = target.type

  const currUom = String(object.unit_of_measure_id || 'count')
  const prevUom = String(target.unit_of_measure_id || 'count')

  const targetQtyField = getQtyField(target)
  const prevQty = target[targetQtyField]

  const currLinkable = _.isUnitOfMeasureLinkable(currUom)
  const prevLinkable = _.isUnitOfMeasureLinkable(prevUom)
  const prevLinked = target.cost_item_link_qty ? 1 : 0

  const isReplacement = addon.addonType !== 'option'

  let qty = 1
  let linkable = 0

  // const prevEach = prevUom === '2';
  // const currEach = currUom === '2';
  // @TODO does this line cause a miscalc??:
  const isCostItem = currentType === 'cost_item' || currentType === 'cost_type'
  const sameUnit = currUom === prevUom

  qty = sameUnit && isReplacement ? prevQty : 1

  linkable = isCostItem && currLinkable && (prevLinked || !prevLinkable) ? 1 : 0

  const targetMt = _.getMeasureTypeForUnitOfMeasure(prevUom)
  const objectMt = _.getMeasureTypeForUnitOfMeasure(currUom)
  const compatibleMeasures = objectMt === targetMt

  let equation = null
  // get overridden equation

  const field = !isCostItem ? 'assembly_qty_equation' : 'cost_type_qty_equation'
  const overrideEq = addon.embue[field]
  if (overrideEq) {
    equation = overrideEq
  } else if (!compatibleMeasures && object[field]) {
    equation = object[field]
  } else if (compatibleMeasures && isCostItem && target[field]) {
    // @todo should isCostItem check that BOTH target and object are cost item?
    equation = target[field]
  }

  // No equation found, convert the qty directly
  if (!equation && compatibleMeasures) {
    // requires conversion
    const amt = target[targetQtyField]
    const from = prevUom
    const to = currUom
    const conv = _.convertMeasure(amt, from, to)
    qty = conv === false ? amt : conv
  }

  return {
    qty,
    linkable,
    equation
  }
}

/**
 * Determines if the given addon is able to adopt the quantity of the terget (item to be replaced) or if the quantity
 * must be determined independantly
 * @param addon
 * @param item
 * @param target
 * @returns {boolean}
 */
const addonCanAdoptTargetQuantity = (addon, item, target) => {
  const targetUnit = String(target.unit_of_measure_id || 'count')
  const itemUnit = String(item.unit_of_measure_id || 'count')
  const sameUnit = itemUnit === targetUnit
  const bothCostTypes =
    /cost_item|cost_type/.test(item.type) && /cost_item|cost_type/.test(target.type)
  const compatibleUnit =
    _.getMeasureTypeForUnitOfMeasure(targetUnit) === _.getMeasureTypeForUnitOfMeasure(itemUnit)
  const eqField = getEquationField(item)

  if (sameUnit || (compatibleUnit && bothCostTypes && target[eqField])) return true // use the target's qty equation
  return false
}

/**
 *
 * @param addon
 * @param item
 * @param target
 * @returns default|manual|saved(deprecated)
 *            - default: adopt the target's (item to be replaced) quantity and equation
 *            - manual: use the overriding qty or equation (in addon.embue)
 *            - saved: use the saved cost_type's qty equation (legacy, for backwards compaat only) -  to avoid this
 *                      the saved equation should be populated into the embued- field when the addon is made
 *                      and the equation should be managed in the addons list only, thus turning it into 'manual' qty type
 */
const getAddonQtyType = (addon, item, target) => {
  const eqField = getEquationField(item)
  const hasOverridden = !!addon.embue?.[eqField]

  if (addonCanAdoptTargetQuantity(addon, item, target) && !hasOverridden) return 'default'

  const targetUnit = String(target.unit_of_measure_id || 'count')
  const itemUnit = String(item.unit_of_measure_id || 'count')
  const compatibleUnit =
    _.getMeasureTypeForUnitOfMeasure(targetUnit) === _.getMeasureTypeForUnitOfMeasure(itemUnit)
  if (!compatibleUnit && item[eqField] && !hasOverridden) return 'saved' // use the equation saved in the item (legacy)

  return 'manual'
}

const setTargetKeys = (addons, target, originalKey = null, targetKey = null) => {
  const newAddons = addons.map((addon) => ({
    ...addon,
    targetKey: targetKey || getTargetKey(target),
    original: originalKey || addon.original
  }))

  return newAddons
}

const getAddonFromObject = (payload) => {
  const {
    addon = {},
    auditedObject = null,
    addonType = 'replace',
    preventIf = null,
    target = {},
    mockQty = 1
  } = payload

  const type = auditedObject.type === 'assembly' ? 'assembly' : 'cost_type'
  const id = auditedObject[getIdField(auditedObject)]

  const targetPrice = target[getPriceField(target)] ?? target.cost_matrix_rate_net
  return {
    ..._.imm(_.defaultAddon),
    ...addon,
    type,
    id,
    price: auditedObject[getPriceField(auditedObject)] ?? auditedObject.cost_matrix_rate_net,
    qty: auditedObject[getQtyField(auditedObject)] ?? mockQty,
    revision: auditedObject[getLastModifiedField(auditedObject)],
    addonType,
    name: auditedObject[getNameField(auditedObject)],
    asDimensionsRequired: auditedObject.asDimensionsRequired || [], // requiredDimensions,
    preventIf: preventIf || addon.preventIf || (addonType === 'option' ? 'anywhere' : 'sibling'),
    file_id:
      (auditedObject.file_ids && auditedObject.file_ids.length && auditedObject.file_ids[0]) ||
      addon.file_id ||
      auditedObject.file_id ||
      null,
    image_external:
      (auditedObject.aoImages && auditedObject.aoImages.length && auditedObject.aoImages[0]) ||
      null,
    usages: +(auditedObject.item_usage_count || 0),
    desc: _.removeHtml(auditedObject[getDescField(auditedObject)] || ''),
    unit: auditedObject.unit_of_measure_id || 'count',
    equation: auditedObject[getEquationField(auditedObject)],
    isgrp: auditedObject.cost_type_is_addon_group ? 1 : 0,
    markup: getMarkup(auditedObject),
    livePriceRef: auditedObject.live_price_reference || null,
    hasOverriddenMarkup: getAddonHasOverriddenMarkup(addon),
    targetPriceAdjustment:
      targetPrice - (addon.targetPrice || targetPrice) + (addon.targetPriceAdjustment || 0),
    targetPrice,
    uid: addon.uid ?? v4()
  }
}

const getAddonsToFetch = async ({ rootState }, payload) => {
  const { refIds = null, store = 'Quote', norm = rootState[store].normalized } = payload

  const addons = []
  const refs = refIds || Object.keys(norm)
  refs.forEach((key) => {
    const obj = norm[key]

    if (obj && obj.aoAddons && Array.isArray(obj.aoAddons) && obj.aoAddons.length) {
      obj.aoAddons.forEach((addon) => {
        if (!addon.id || !addon.type) return
        addons.push({
          id: addon.id,
          type: addon.type === 'assembly' ? 'assembly' : 'cost_type'
        })
      })
    }
  })

  return addons
}

const getQuoteRefIds = async ({ rootState }, payload) => {
  const { store = 'Quote', norm = rootState[store].normalized } = payload

  return Object.keys(norm)
}

/**
 * Function takes the assemblyRefId (string) and the changedDimensions (array)
 * and returns all the children in the given assembly that use the changed dimension
 *
 *
 * @param rootState
 * @param payload
 * @returns {Promise<*[]>}
 */
const getLinkedDimensionRefIds = async ({ rootState }, payload) => {
  const {
    store = 'Quote',
    norm = rootState[store].normalized,
    assemblyRefId = null,
    changedDimensions = null
  } = payload

  if (!assemblyRefId || !changedDimensions) return

  const assemblyObject = norm[assemblyRefId]
  const refs = []

  // Get all the children, grandchildren, etc. of a refId
  const getChildren = (children) => {
    children.forEach((ref) => {
      const object = norm[ref]
      if (containsChangedDimension(object)) {
        refs.push(ref)
      }
      if (object.aoChildren) {
        getChildren(object.aoChildren)
      }
    })
  }

  // determine if the equation uses anh of the changed dimensions
  const containsChangedDimension = (object) => {
    const equationField = getEquationField(object)
    let usesDimension = false
    changedDimensions.forEach((dimension) => {
      if (usesDimension) return
      if (object[equationField] && object[equationField].includes(dimension)) {
        usesDimension = true
      }
    })
    return usesDimension
  }

  if (assemblyObject.aoChildren) {
    getChildren(assemblyObject.aoChildren)
  }

  return refs
}

// const getAdjustedUpgradePrice = async (payload) => {
//   const { dispatch, norm, object, original, target } = payload
//
//   const originalAddon = original
//   let adjustments = {}
//
//   if (object.type === 'assembly') {
//     const parentRef = target.parentRefId
//     const parent = norm[parentRef]
//     const d = _.n(originalAddon.quote_price_net) / _.n(originalAddon.quote_total_cost_net)
//     const m = _.n(object.quote_markup_net)
//     const q = _.n(parent.quote_markup_percentage_adjustment)
//
//     // d = ((a + q + aq) * (m - 1)) + m
//     // d = (a + q + aq)(m - 1) + m
//     // d - m = (a + q + aq)(m - 1)
//     // (d - m) / (m - 1) = (a + q + aq)
//     // (d - m) / (m - 1) = (a + q + aq)
//     // ((d - m) / (m - 1)) - q = a + aq
//     // ((d - m) / (m - 1)) - q = a(1 + q)
//     // (((d - m) / (m - 1)) - q) / (1 + q) = a
//     const a = ((d - m) / (m - 1 || 0.000000001) - q) / (1 + q || 0.000000001)
//
//     adjustments = {
//       assembly_markup_percentage_adjustment: a
//     }
//   } else {
//     const originalUnitPrice =
//       originalAddon.cost_item_price_net / originalAddon.cost_item_qty_net ||
//       originalAddon.cost_matrix_rate_net ||
//       originalAddon.cost_matrix_rate_net_index
//
//     const { changes } = await dispatch(
//       'CostType/setPrice',
//       {
//         store: 'CostItem',
//         price: originalUnitPrice,
//         object
//       },
//       { root: true }
//     )
//
//     adjustments = changes
//   }
//
//   return adjustments
// }

const getNormalizedRootRefId = (set, refId = Object.keys(set)[0]) => {
  let cursorRef = refId
  const allCursors = [] // Prevent recursion
  if (
    typeof set === 'object' &&
    refId &&
    cursorRef in set /* && typeof set[cursorRef] === 'object' */
  ) {
    while (
      typeof set[cursorRef] === 'object' &&
      'parentRefId' in set[cursorRef] &&
      !_.isempty(set[cursorRef].parentRefId) &&
      set[cursorRef].parentRefId in set &&
      allCursors.indexOf(set[cursorRef].parentRefId) === -1
    ) {
      cursorRef = set[cursorRef].parentRefId
      allCursors.push(cursorRef)
    }
    return cursorRef
  }
  return false
}

const getItemTag = (item) => {
  const type = item.type === 'assembly' ? 'assembly' : 'cost_type'
  return `${type}:${item[`${type}_id`] || 'blank'}`
}

const removeAddonTags = (object) => {
  const {
    /* eslint-disable no-unused-vars */
    addon_is_original,
    addon_is_saved,
    addon_is_upgraded,
    addon_is_a_downgrade,
    addon_is_notified,
    addon_upgraded_by,
    addon_time_upgraded,
    /* eslint-enable no-unused-vars */
    ...rest
  } = object

  return rest
}

const getObjectAsBulkAddon = (object, target) => {
  return getAddonFromObject({
    addon: {
      bulk: removeAddonTags(object),
      original: 1 // getItemTag(object), // bulk must always be original
    },
    auditedObject: object,
    addonType: 'replace',
    target
  })
}

const getSelectedAsBulkAddon = ({ norm, refId, target }) => {
  const object = NormalizeUtilities.denormalize(norm, refId, true, true)
  return getObjectAsBulkAddon(object, target)
}

const convertFromCostTypeToCostItem = ({ dispatch }, costType) => {
  if (`${costType.unit_of_measure_id}` === '2') {
    costType.unit_of_measure_id = 'count'
    costType.unit_of_measure_abbr = 'each'
    costType.unit_of_measure_name = 'each'
  }
  return dispatch('buildDefaultObject', { embue: costType, type: 'cost_item' })
}

const convertFromLivePriceToCostType = async ({ dispatch }, { company, livePriceItem }) => {
  const { object: defaultCostType } = await dispatch(
    'CostType/buildDefaultObject',
    {},
    { root: true }
  )
  const rate = livePriceItem.material_rate * (company.company_default_markup || 1)
  return {
    ...defaultCostType,
    cost_type_name: livePriceItem.name,
    cost_type_desc: livePriceItem.description,
    cost_type_hours_per_unit: livePriceItem.hours_per_unit,
    cost_type_has_material: 1,
    cost_type_has_labor: 0,
    aoImages: livePriceItem.aoImages,
    cost_matrix_materials_cost_net: livePriceItem.material_rate,
    cost_matrix_labor_cost_net: 0,
    cost_matrix_aggregate_cost_net_index: livePriceItem.material_rate,
    cost_matrix_use_company_markup: 1,
    cost_matrix_markup_net: company.company_default_markup || 1,
    cost_matrix_rate_net_index: rate,
    unit_of_measure_id: livePriceItem.unit_of_measure_id,
    unit_of_measure_abbr: livePriceItem.unit_of_measure_abbr,
    unit_of_measure_name: livePriceItem.unit_of_measure_abbr,
    live_price_reference: livePriceItem.live_price_reference
  }
}

const getAddons = async (payload) => {
  const { dispatch, addons, norm, company } = payload

  if (!addons || !addons.length) return {}

  const rootRefId = getNormalizedRootRefId(norm)
  const zipcode = norm[rootRefId].quote_postal

  const { object } = await dispatch('ajax', {
    path: 'quote/getAddons',
    data: {
      addons,
      zipcode
    }
  })

  const res = {}
  for (const type in object) {
    res[type] = {}
    for (const obj of object[type]) {
      if (type === 'assembly' || type === 'cost_type') {
        res[type][String(obj[`${obj.type}_id`])] = obj
      }
      if (type === 'live_price') {
        res[type][String(obj.live_price_reference)] = await convertFromLivePriceToCostType(
          { dispatch },
          { livePriceItem: obj, company }
        )
      }
    }
  }

  return res
}

const getAddonMarkup = (addon, target) => {
  const addonMarkup = addon.markup
  const targetMarkup = target[getMarkupField(target)]

  return addonMarkup ?? targetMarkup
}

const getAddonMarkupAdjustment = (targetMarkup, originalMarkup) => {
  return (
    (targetMarkup - 1 - (originalMarkup - 1)) /
    (_.eq(originalMarkup - 1, 0, 2) ? 1 : originalMarkup - 1)
  )
}

const getAddonQuantity = (addon, fetched, target) => {
  // this will potentially be overriden if there is an equation
  return _.convertMeasures(
    target[getQuantityField(target)],
    target.unit_of_measure_id || 'count',
    fetched?.unit_of_measure_id || addon.unit
  )
}

const getAddonQuantityEquation = (addon, target) => {
  return addon.equation || target.equation // does not use fetched item for equation, to avoid issues
}

const getAddonContext = async (addon, fetched, target, dispatch) => {
  const defaultCostItem = (await dispatch('CostItem/buildDefaultObject', {}, { root: true })).object
  const isAssembly = addon.type === 'assembly'

  const markupField = getMarkupField(fetched)
  const qtyField = getQtyField(addon)
  const eqField = getEquationField(addon)

  let quantizedObject = { ...defaultCostItem, ...fetched }
  quantizedObject.type = isAssembly ? 'assembly' : 'cost_item'
  quantizedObject[markupField] = getAddonMarkup(addon, target)
  quantizedObject[qtyField] = getAddonQuantity(addon, fetched, target)
  quantizedObject[eqField] = getAddonQuantityEquation(addon, target)
  quantizedObject.oDimensions = isAssembly ? target.oDimensions : undefined
  quantizedObject.assembly_markup_percentage_adjustment = isAssembly
    ? // if there is a diff between original markup frm fetched, vs the new quantized target markup
      // and the type is assembly, we will need to create an adjustment for it to register
      getAddonMarkupAdjustment(quantizedObject[markupField], fetched[markupField])
    : undefined

  quantizedObject = Object.assign(quantizedObject, addon.embue)

  // reference to context
  quantizedObject.refId = target.refId
  quantizedObject.parentRefId = target.parentRefId

  return quantizedObject
}

const remodAddon = async (object, mod, dispatch) => {
  // reMod full tree
  const normalized =
    object.type === 'assembly'
      ? NormalizeUtilities.normalize(object, false, object.refId)
      : { [object.refId]: object }
  const remoded = await dispatch(
    'CostType/reModNormalized',
    {
      set: normalized,
      refId: object.refId,
      newMod: mod
    },
    { root: true }
  )
  return NormalizeUtilities.denormalize(remoded, object.refId, true)
}

const auditForAddon = async (payload) => {
  const {
    store = 'Quote',
    dispatch,

    norm,
    fetched,
    addon,
    refId: key,

    target = norm[key],

    possibleDimensions = await dispatch('Dimension/getPossibleDimensions', {}, { root: true }),
    parent = norm[target.parentRefId],
    mod = await dispatch(
      'Quote/getQuoteMod',
      {
        store,
        refId: key
      },
      { root: true }
    )
  } = payload

  let quantizedObject = await getAddonContext(addon, fetched, target, dispatch)
  quantizedObject = await remodAddon(quantizedObject, mod, dispatch)

  if (quantizedObject.type === 'assembly') {
    // audit in place required
    ;({ object: quantizedObject } = await dispatch(
      `${store}/auditInPlace`,
      {
        object: quantizedObject,
        targetRefId: target.refId,
        insertType: addon.addonType
      },
      { root: true }
    ))
  } else {
    // cascade dependencies sufficient
    const {
      changes: { [key]: itemChanges }
    } = await dispatch(
      `${store}/cascadeDependencies`,
      {
        set: {
          [key]: {
            ...quantizedObject,
            // equation and quantities only are set on cost items
            // so if it is a cost type, null them out now and
            // set qty to one
            ...(target.type === 'cost_type'
              ? {
                  cost_item_qty_net_base: 1,
                  cost_item_qty_net: 1,
                  cost_type_qty_equation: null
                }
              : {})
          },
          // including the parent will allow any adjustemnts to be picked up by the cascade as well
          [target.parentRefId]: parent
        },
        refId: key,
        changes: {},
        possibleDimensions
      },
      { root: true }
    )
    quantizedObject = {
      ...quantizedObject,
      ...itemChanges
    }
  }

  return quantizedObject
}

const recalcAddons = async ({ rootState, dispatch, getters, commit }, payload = {}) => {
  const {
    refIds = null,
    force = false,
    addons: prefetched = null,
    store = 'Quote',
    norm = rootState[store].normalized,
    loading = true,
    mock = false
  } = payload

  if (loading) await dispatch('addLoading', {}, { root: true })

  const toFetch = await dispatch('getAddonsToFetch', { refIds, norm, store })
  const addons =
    prefetched ||
    (await getAddons({ addons: toFetch, dispatch, norm, company: rootState.session.company }))

  const changes = {}

  const refs = refIds || Object.keys(norm)
  const mod = await dispatch(
    'Quote/getQuoteMod',
    {
      store,
      refId: refs[0]
    },
    { root: true }
  )
  const defaultCostItem = (await dispatch('CostItem/buildDefaultObject', {}, { root: true })).object
  const possibleDimensions = await dispatch('Dimension/getPossibleDimensions', {}, { root: true })

  // Go through the whole set, and find each item/assembly with addons
  for (const key of refs) {
    const target = norm[key]
    if (!target || !target.aoAddons || !Array.isArray(target.aoAddons) || !target.aoAddons.length) {
      continue
    }

    const targetKey = getTargetKey(target)
    const parent = norm[target.parentRefId]

    // Iterate addons
    let hasChanged = false
    const newAddons = []
    for (const addon of target.aoAddons) {
      // Get relevant addon from fetched
      const fetched = addon.bulk || (addons[addon.type] && addons[addon.type][String(addon.id)])

      if (!fetched) {
        newAddons.push(addon)
        continue
      }

      const lastModifiedField = getLastModifiedField(addon)
      const qtyField = getQtyField(addon)
      const eqField = getEquationField(addon)

      // Check if the addons need to be recalced
      const recalc =
        force ||
        !_.eq(addon.revision, fetched[lastModifiedField], 0) ||
        addon.targetKey !== targetKey ||
        !('hasOverriddenMarkup' in addon) ||
        !getAddonHasOverriddenMarkup(addon) // for backwards compat

      if (!recalc) {
        newAddons.push(addon)
        continue
      }

      hasChanged = true

      const newAddon = {
        ...addon
      }

      const final = await auditForAddon({
        target,
        fetched,
        addon,
        dispatch,
        norm,
        refId: key,
        getters,
        possibleDimensions,
        parent,
        store,
        defaultCostItem,
        mod,
        qtyField,
        eqField
      })

      // Convert final audited object into addon
      newAddons.push({
        ...newAddon,
        ...getAddonFromObject({
          auditedObject: final,
          target,
          addon: newAddon
        })
      })
    }

    if (hasChanged) {
      changes[key] = {
        aoAddons: setTargetKeys(newAddons, target, null, targetKey)
      }
    }
  }

  // Commit changes to store
  if (!mock) {
    commit({
      type: Types.ADD_NORMALIZED,
      object: changes,
      integrate: true
    })
  }

  if (loading) dispatch('removeLoading', {}, { root: true })

  return {
    changes
  }
}

export default {
  getAddons,
  recalcAddons,
  getAddonsToFetch,
  auditForAddon,
  getAddonFromObject,
  getItemTag,
  getObjectAsBulkAddon,
  getSelectedAsBulkAddon,
  setTargetKeys,
  getTargetKey,
  removeAddonTags,
  getQuoteRefIds,
  getAddonHasOverriddenMarkup,
  getLinkedDimensionRefIds,
  getCostField,
  getMarkupAdjustment,
  getLastModifiedField,
  getQuantity,
  getQuantityField,
  getEquationField,
  getQtyField,
  getIdField,
  getMarkupField,
  getPriceField,
  getDescField,
  getNameField,
  getAddonQtyType,
  addonCanAdoptTargetQuantity,
  convertFromCostTypeToCostItem,
  convertFromLivePriceToCostType
}
