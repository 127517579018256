import eventBus from '../../../eventBus'

export default {
  data() {
    return {
      overflowWidth: '50vw',
      leftOffset: '0px'
    }
  },

  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },

    object() {
      return this.norm[this.refId]
    },

    variation() {
      return this.object.cost_type_is_variation_parent
    },

    variationsCount() {
      if (!this.variation) return 0

      return (this.object.aoVariationItems || []).length
    },

    cheapestVariation() {
      if (!this.variation || !this.qty) return -1

      // sort all variations by price
      const sortedVariations = [...this.object.aoVariationItems]
      sortedVariations.sort((a, b) => a.rate - b.rate)

      // get the price difference between current variation and cheapest variation
      if (sortedVariations.length) {
        return sortedVariations[0].rate * this.qty
      }
      return -1
    },

    optional() {
      return this.object.cost_item_is_optional || this.object.assembly_is_optional
    },

    input() {
      return (
        this.object.oInputRequired &&
        this.object.oInputRequired.inputs &&
        this.object.oInputRequired.inputs.length
      )
    },

    name() {
      return this.object.cost_type_name || this.object.assembly_name
    },

    properties() {
      return c.makeArray(this.object.aoProperties || this.object.aoProperties || [])
    },

    desc() {
      return this.object.cost_type_desc || this.object.quote_notes
    },

    included() {
      return _.toNumber(this.object[`${this.object.type}_is_included`])
    },

    type() {
      return this.object.type
    },

    units() {
      return this.type === 'cost_item' ? this.object.unit_of_measure_abbr : 'each'
    },

    qty() {
      return this.object.cost_item_qty_net || this.object.quote_qty_net
    },

    origPrice() {
      const field = `${this.type}_orig_price_net`

      return this.object[field]
    },

    price() {
      if (this.presentationSettings?.showBundledProfit) {
        return this.cost
      }
      return this.origPrice || this.object.cost_item_price_net || this.object.quote_price_net
    },

    cost() {
      return this.object.cost_item_total_cost_net_base * this.artificialMultiplier
    },
    addons: {
      get() {
        return this.object.aoAddons || []
      }
    },
    isGallery() {
      return this.object.oMeta?.itemType === 'gallery'
    },
    isTextBlock() {
      return this.object.oMeta?.itemType === 'text'
    },
    isAssembly() {
      return this.object.type === 'assembly'
    },
    isParent() {
      return this.object.cost_type_is_parent
    }
  },
  methods: {
    getOverflowWidth() {
      const el = (this.$refs.title && this.$refs.title.$el) || this.$el

      if (!el.getBoundingClientRect) return 0

      const { left: elLeft } = el.getBoundingClientRect()
      const scrollParent = c.getScrollParent(el)

      const { left, right } = scrollParent.getBoundingClientRect()
      const width = right - left

      // const parent = c.getScrollParent(el);
      // const { left: parentLeft } = parent.getBoundingClientRect();
      const parentLeft = left

      this.overflowWidth = `${width - 25}px`

      this.leftOffset = `${elLeft - parentLeft}px`

      return this.overflowWidth
    },
    cast() {
      return this.object
    },
    getField(fieldName) {
      return this.object[fieldName]
    },
    setField(fieldName, value, explicit = false, skipAudit = false) {
      eventBus.$emit('PresentationModifyItem', {
        refId: this.object.refId,
        changes: {
          [fieldName]: value
        },
        skipAudit,
        explicit
      })
    },
    openVariations() {
      if (this.variation) this.chooseSelf()
      this.$refs.variations.open()
    },
    closeVariations() {
      this.$refs.variations.close()
    },
    async chooseSelf() {
      if (this.optional && !this.included) {
        this.keepOrRemove(1)
      }
    },
    async keepOrRemove(incl) {
      if (incl === c.toNum(this.included, 0)) {
        return
      }

      await this.$store.dispatch('Quote/toggleIncluded', {
        included: incl,
        store: this.store,
        explicit: true,
        refId: this.refId
      })

      await this.$emit('update-excludes', { refId: this.refId, incl })
    }
  },

  async mounted() {
    await this.$nextTick()
    this.getOverflowWidth()

    eventBus.$on('resize', this.getOverflowWidth)
  },
  beforeUnmount() {
    eventBus.$off('resize', this.getOverflowWidth)
  },
  props: {
    // Array of refIds
    refId: {
      required: true
    },
    deselectOnDestroy: {
      default: false
    },
    store: {
      type: String,
      required: true
    },
    dimensions: {
      type: Object,
      required: true
    },
    parentRefId: {
      type: String,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    /**
     * See QuotePresenetationAssembly
     */
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    showGeneralPrice: {
      default: true
    }
  },
  emits: ['update-excludes']
}
