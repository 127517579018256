<template>
  <section class="mt-4">
    <slot v-if="!hideTitle" name="title">
      <div class="flex items-center py-4">
        <FontAwesomeIcon
          class="mr-3 text-3xl text-surface-500"
          v-if="section.icon"
          :icon="section.icon"
        />
        <div class="flex flex-col">
          <div v-if="section.title" class="text-2xl font-medium leading-tight">
            <span>{{ section.title }}</span>
            <span v-if="section.items.length">{{ `(${section.items.length})` }}</span>
          </div>
          <div class="text-surface-400 text-md leading-none font-medium">
            {{ section.description }}
          </div>
        </div>
      </div>
    </slot>
    <Card
      :highlight="featured ? 'orange-600' : ''"
      class="mt-0 relative"
      :border="asCard"
      :loading="loading"
      :pt="pt"
      :flat="!asCard"
    >
      <template #text>
        <slot name="above" :items="paginatedItems" :section="section"></slot>
      </template>
      <slot name="list" :paginatedItems="paginatedItems">
        <template v-if="!section.groups">
          <div
            v-if="section.totalItems === 0"
            class="w-full text-center text-surface-400 text-xl bg-surface-100/70 p-4 rounded font-medium"
          >
            {{ loading ? fetchingMessage : noResultsMessage }}
          </div>
          <div v-else>
            <div
              :class="`border border-b-0 border-cool-gray-300 rounded-t gap-2 grid grid-cols-${section.headers.length}`"
              v-if="section.headers"
            >
              <div
                v-for="header in section.headers"
                :key="header"
                class="text-cool-gray-500 text-xs px-4 py-3 text-center first:text-left last:text-right"
              >
                {{ header }}
              </div>
            </div>
            <component
              v-for="(item, index) in paginatedItems"
              :key="`${item.cost_type_id}_${index}`"
              :item="item"
              :select="onSelect"
              :showStartDate="true"
              :is="`${$f.capitalize(item.item_line_type || props.type)}Item`"
            />
          </div>
        </template>
        <template v-else>
          <div v-for="groupKey in Object.keys(section.groups)" :key="groupKey" class="w-full mb-4">
            <div class="flex mb-2">
              <slot
                name="groupTitle"
                :section="section"
                :item="section.groups[groupKey]"
                :groupKey="groupKey"
              />
            </div>
            <div>
              <slot name="groupItems" :section="section" :groupKey="groupKey">
                <div
                  v-if="section.groups[groupKey].items.length === 0"
                  class="w-full text-center text-cool-gray-300 p-4 border border-cool-gray-100 text-sm"
                >
                  {{ loading ? fetchingMessage : noResultsMessage }}
                </div>
                <component
                  v-for="(item, index) in section.groups[groupKey].items"
                  :key="`${item.cost_type_id}_${index}`"
                  :item="item"
                  :select="onSelect"
                  :is="`${$f.capitalize(item.item_line_type || props.type)}Item`"
                />
              </slot>
            </div>
          </div>
          <div
            v-if="Object.keys(section.groups).length === 0"
            class="w-full text-center text-cool-gray-300 p-4 border border-cool-gray-100 text-sm"
          >
            {{ loading ? fetchingMessage : noResultsMessage }}
          </div>
        </template>
      </slot>

      <div class="flex justify-center mt-6">
        <Btn v-if="canLoadMore" severity="tertiary" outlined class="load-more" @click="loadMore">
          Load more
        </Btn>
      </div>
      <slot name="below"></slot>
    </Card>
  </section>
</template>

<script setup>
import { computed, defineProps, watch } from 'vue'
import usePagination from '@/components/composables/UsePagination'
import FontAwesomeIcon from '@/fontAwesome'

const props = defineProps({
  section: {
    type: Object,
    default: () => ({})
  },
  loading: {
    type: Number
  },
  type: {
    type: String,
    default: 'quote'
  },
  fetchingMessage: {
    type: String,
    default: 'Retrieving items...'
  },
  noResultsMessage: {
    type: String,
    default: 'No items to show'
  },
  select: {
    type: Function
  },
  featured: {
    type: Boolean,
    default: false
  },
  fetchMore: {
    type: Function
  },
  asCard: {
    type: Boolean,
    default: true
  },
  hideTitle: {
    type: Boolean,
    default: false
  },
  condensed: {
    type: Boolean,
    default: false
  }
})

const pt = computed(() => ({
  body: props.condensed ? '!p-0' : ''
}))

const { loadMore, setItems, canLoadMore, paginatedItems } = usePagination({
  fetchMore: props.fetchMore,
  perPage: 15
})

watch(
  () => props.section.items,
  (newItems) => {
    setItems(newItems)
  },
  { deep: true, immediate: true }
)

const onSelect = (params) => {
  if (props.select) props.select(params)
}
</script>
