export default {
  "languageName": "French (CA)",

  "Estimate": "Soumission",
  "Expires": "Valide jusqu'au",
  "Project": "Projet",
  "Client": "Client",
  "Call us": "Nous joindre",
  "Presented by": "Présenté par",
  "Expand": "Voir",
  "to see options": "le détail",
  "other customers recently upgraded their": "récemment, d'autres client ont opté pour un surclassement de",
  "other options for": "leur option pour",
  "included": "inclus",
  "not included": "exclu",
  "Other options for": "D'autres options pour",
  "Preview": "Devis de construction",
  "selected": "sélectionné",
  "other customers selected this recently": "d'autres clients ont récemment sélectionné ceci",
  "Make this selection": "Choisir cette option",
  "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project": "Les options offertes en surclassement peuvent varier selon les spécifications du projet de même que de leur disponibilité. Les images sont à titre indicatif et peuvent ne pas être représentative de ce qui sera appliqué à votre projet. Voir avec le conseiller aux ventes.",
  "Download": "Télécharger",
  "Total": "Total",
  "Subtotal": "Sous-total",
  "Discount": "Rabais",
  "Please review the proposal and choose accept to proceed": "Merci de bien vouloir réviser la soumission et sélectionner «Accepter» pour poursuivre",
  "See": "Voir",
  "More": "Plus",
  "Remove": "Retirer",
  "Project files": "Documents du projet",
  "Notes": "Notes",
  "Upgraded item": "Items surclassés",
  "Clients address": "Adresse des clients",
  "more general items": "plus d'items généraux",
  "Upgrades subject to final approval and availability": "Surclassements sujet à la disponibilité et à l'approbation de l'Entrepreneur",
  "Cost": "Coût de construction",
  "Taxes": "Taxes",
  "Project total": "Total du projet",
  "Review terms and approve this": "Réviser les termes et conditions et approuver",
  "Review terms and approve": "Réviser les termes et conditions et approuver",
  "Review terms": "Réviser les termes",
  "Request revision": "Demande de révision",
  "Decline": "Refuser",
  "Options available - look for the upgrades symbol": "Options available - look for the upgrades symbol",
  "Upgrades available": "Surclassements disponibles",
  "You agree to accept and proceed with the project as detailed": "Vous acceptez le projet tel que détaillé et passer à l'étape suivante",
  "This version was approved on": "Cette version a été approuvée le",
  "This version was declined": "Cette version est refusée",
  "Terms and conditions": "Termes et conditions",
  "In order to continue, review and accept to the terms below": "Afin de poursuivre, veuillez réviser et accepter les termes et conditions ci-après",
  "I accept the terms and conditions": "J'accepte les termes et conditions",
  "I do not accept the terms and conditions": "Je refuse les termes et conditions",
  "Thank you for booking": "Merci d'avoir choisi ce projet",
  "Please wait while we upgrade your records": "Merci de patienter alors que nous mettons à jour vos sélections",
  "Thank you": "Merci",
  "Project fully booked": "Projet approuvé",
  "Thank you for booking with us": "Merci d'avoir choisi de collaborer avec nous",
  "We will get in touch as soon as possible to confirm details": "Nous vous contacterons dès que possible afin de vous confirmer les détails",
  "Your project is fully booked": "Votre projet est confirmé",
  "Come back to Bolster to follow updates or to request or approve change-orders": "Revenez vers la plateforme Bolster afin de suivre les mises à jour ou pour approuver ou faire de nouvelles demandes",
  "See projects": "Voir les projets",
  "There was an error booking this project. Please try again.": "Une erreur est survenue lors de la confirmation de ce projet. Merci d'essayer à nouveau.",
  "Request a new quote": "Demander une nouvelle soumission",
  "items included": "items inclus",
  "items excluded": "items exclus",
  "options": "options",
  "Review all": "Réviser toute la proposition",
  "New feature": "Nouvelle possibilité",
  "See more": "En voir plus",

  "Electronic signature agreement": "Approbation de la signature électronique",
  "By clicking the \"I accept the terms and conditions\" button below, I am accepting to reserve the work as detailed and priced in this proposal by": "En sélectionnant le bouton «J'accepte les termes et condition» ci-après, je confirme que le projet est accepté tel que détaillé et selon le prix affiché à cette soumission par",
  "(the Contractor) and acknowledge and agree that clicking to sign this Agreement will have the same legal affect as a handwritten signature.": "(l'Entrepreneur) et je reconnaît et accepte que cliquer pour signer cette Entente aura la même portée légale qu'une signature manuscrite.",
  "The Agreements may be executed in one or more counterparts, each of which will be deemed to be an original copy and all of which, when taken together, will be deemed to constitute one and the same agreement, and will be effective when acceptance of the counterparts have been recorded electronically by both the Contractor and myself.": "Cette entente pourrait circuler en plusieurs copies et toutes et chacune d'entre elles seront considérées comme étant partie prenante de cette même Entente qui ne sera valide qu'une fois signée par l'Entrepreneur et moi-même.",
  "I accept that modifications that I make to the project through Bolster are subject to approval by the Contractor in order to take into consideration any existing or special conditions particular to my property, and may not be possible in all cases.": "Je reconnais que les modifications que j'effectue au projet via Bolster sont sujettes à l'approbation de l'Entrepreneur afin que ce dernier puisse évaluer la faisabilité considérant la nature de mon projet de construction et que toutes pourraient ne pas être applicables.",
  "I acknowledge that this is an agreement only between the Contractor and myself.": "Je reconnais que cette proposition est valide uniquement entre moi-même et l'Entrepreneur.",
  "I consent to the Bolster terms and conditions and privacy policy (found at https://costcertified.cc/pub/legal) while using Bolster.": "Pour la durée de la démarche, je conscens aux termes et conditions et à la politique de confidentialité de Bolster (disponible ici https://costcertified.cc/pub/legal).",

  "Thank you for the opportunity to provide you this": "Merci de nous permettre de vous proposer ceci",
  "change-order": "modifications",
  "proposal": "soumission",
  "for project": "pour le projet",
  "at": "à",
  "After you review the": "Après avoir révisé la",
  "and make desired selections (if applicable), please choose to accept, request an alteration or decline": "et faire les sélections souhaitées (si applicable), merci de choisir pour accepter, demander une modification ou refuser",
  "Review your": "Réviser votre",
  "You're receiving this email because you requested information form a company subscribed to": "Vous recevez ce courriel puisque vous avec demander des informations à propos d'une entreprise enregistrée à",
  "You have a": "Vous avez un",
  "Your request from": "Votre demande",
  "to review": "pour révision",
  "to review + approve": "pour révision et approbation",
  "has sent you a": "vous a transmis un",

  "Each": "Chaque",
  "each": "chaque",
  "Sales tax": "Taxes de vente",
  "Items": "Items",

  "tax": "taxes",
  "on": "sur",
  "prices": "prix",
  "derived from": "substitut de",
  "general": "général",
  "How can we improve this proposal for you?": "De quelle manière pourrions-nous améliorer cette soumission pour vous?",
  "Cancel": "Annuler",
  "Save": "Sauvegarder",
  "Approval": "Approbation",
  "This project is currently not booked. Book it now to ensure you can to proceed.": "Ce projet n'est actuellement pas confirmé. Approuvez-le maintenant afin de pouvoir poursuivre.",
  "Review project": "Réviser le projet",
  "Decline project": "Refuser le projet",
  "Please provide a reason for declining the project": "Merci de bien vouloir nous fournir le motif de ce refus",
  "Please wait while we process your approval": "Merci de patienter pendant que nous traitons votre demande",

  "Project not yet approved": "Projet en cours d'approbation",
  "Review this project carefully then follow the steps to book the project if you wish to proceed.": "Bien vouloir réviser attentivement l'ensemble du projet et suivez les étapes pour le confirmer et poursuivre.",
  "This project is approved! Check back here soon for updates.": "Le projet est approuvé! Revenez ici bientôt afin de suivre les mises à jour de votre dossier.",
  "Revisions requested.. Check your email soon for a new version": "Révision demandée. Surveillez vos courriels prochainement afin d'être informé de la mise à jour",

  "You declined this project": "Vous avez refusé ce projet",
  "See project details": "Voir les détails du projet",
  "Yes, go ahead..": "Oui, aller de l'avant",
  "No, cancel..": "Non, annuler",

  "Pending": "En suspens",
  "You have approved this project. Waiting on your changes to be approved by the company.": "Vous avez approuvé ce projet. En attente de l'approbation de l'Entrepreneur.",
  "If you've changed your mind, you can still book this project however it is subject to review by the vendor. Depending on the time since the proposal was created, prices and availability might change.": "En cas de changement d'avis, vous aurez encore l'occasion de confirmer ce projet bien qu'il sera sujet à approbation de la part de l'Entrepreneur. Selon les délais depuis le dépôt de la soumission, les prix et disponibilités pourront avoir changés.",

  "Proposal marked as declined.": "Soumission catégorisée comme refusée.",
  "Revisions requested. Check your email soon for a new version.": "Révision demandée. Surveillez vos courriels prochainement afin d'être informé de la mise à jour",
  "Changes to approve": "Modifications à approuver",
  "There are some changes that need your approval!": "Des modifications attendent votre approbation!",
  "Review changes": "Réviser les modifications",
  "Include": "Inclus",
  "Sorry I couldn't help you further! Please help me do a better job next time by letting us know why you declined": "Nous sommes désolés d'apprendre que nous n'avons pu répondre à vos attentes! En nous indiquant le motif de votre refus, vous contribuerez à nous améliorer pour nos projets futurs",

  "Home": "Accueil",
  "Your projects": "Vos projets",
  "Your proposals": "Vos soumissions",
  "Your companies": "Vos compagnies",
  "Management fee": "Frais d'administration",

  "You approved work": "Vous avez approuvé le travail",
  "Client approved and paid": "Client a approuvé et payé",
  "Contractor approved work": "L'entrepreneur a approuvé le travail",
  "You completed work": "Vous avez terminé le travail",
  "Subcontractor completed work": "Sous-traitant a terminé le travail",
  "Waiting for you to review and approve work": "En attente de votre examen et approbation du travail",
  "Waiting for client to review and approve work": "En attente de l'examen et de l'approbation du travail par le client",
  "Waiting for contractor to review work": "En attente de l'entrepreneur pour examiner le travail",
  "Waiting for subcontractor to complete work": "En attente du sous-traitant pour terminer le travail",
  "Client will review and approve work once completed": "Le client examinera et approuvera le travail une fois terminé",
  "You can review and approve work after contractor": "Vous pouvez examiner et approuver le travail après l'entrepreneur",
  "You can review work once completed": "Vous pouvez examiner le travail une fois terminé",
  "Waiting for you to review work": "En attente de votre examen du travail",
  "Contractor will review work once complete": "L'entrepreneur examinera le travail une fois terminé",
  "Waiting for you to complete work": "En attente de votre achèvement du travail",
  "Waiting for subcontractor to start working": "En attente que le sous-traitant commence à travailler",
  "You approved and paid, payment is processing": "Vous avez approuvé et payé, le paiement est en traitement",
  'PLEASE READ THE TERMS AND CONDITIONS AND INDICATE YOUR ACCEPTANCE BY ENTERING YOUR NAME ABOVE AND CLICKING THE "I ACCEPT THE TERMS AND CONDITIONS" BUTTON AT THE BOTTOM OF THIS CHECKOUT PAGE. ACCEPTANCE OF THE AGREEMENT MEANS THAT YOU ARE BOUND BY ITS TERMS. PLEASE RETAIN IT FOR YOUR RECORDS.': 'VEUILLEZ LIRE LES TERMES ET CONDITIONS ET INDIQUER VOTRE ACCEPTATION EN SAISISSANT VOTRE NOM CI-DESSUS ET EN CLIQUANT SUR LE BOUTON "J\'ACCEPTE LES TERMES ET CONDITIONS" EN BAS DE CETTE PAGE DE PAIEMENT. L\'ACCEPTATION DE L\'ACCORD SIGNIFIE QUE VOUS ÊTES LIÉ PAR SES TERMES. VEUILLEZ LE CONSERVER POUR VOS DOSSIERS.',
  
  "Thank you for your submission! We will get back to you as soon as possible": "Merci pour votre soumission! Nous vous répondrons dès que possible.",
  "Submission Successful": "Soumission réussie",
  "Could not find form": "Impossible de trouver le formulaire",
  "This form does not exist. Please double check the url": "Ce formulaire n'existe pas. Veuillez vérifier l'URL.",
  "Saving form submission": "Enregistrement de la soumission du formulaire",
  "Submit": "Soumettre",
  "To get started creating an estimate, please select the options provided below": "Pour commencer à créer un devis, veuillez sélectionner les options ci-dessous.",
  "About us": "À propos de nous",
  "Get a quote": "Obtenez un devis",
  "Company rating": "Évaluation de l'entreprise",
  "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality": "Un de nos agents dévoués examinera vos exigences pour vous fournir une réponse personnalisée. Consultez votre boîte de réception pour la confirmation et les détails. Nous sommes ravis de vous aider à réaliser la maison de vos rêves.",
  "You will receive a copy of the estimate by email for your records": "Vous recevrez une copie de l'estimation par courriel pour vos dossiers.",
  "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Merci pour votre soumission! Nous avons reçu vos informations et travaillons déjà sur l'élaboration d'une estimation pour votre projet. Nous vous contacterons sous peu pour discuter de l'estimation et des prochaines étapes.",
  "First Name": "Prénom",
  "Last Name": "Nom de famille",
  "Email": "Email",
  "Phone": "Téléphone",
  "Suite": "Appartement",
  "Address": "Adresse",
  "City": "Ville",
  "Zip Code / Postal Code": "Code postal",
  "Project Details": "Détails du projet",
  "Project Type": "Type de projet",
  "Project Budget": "Budget du projet",
  "Project Timeline": "Calendrier du projet",
  "Tell us about your project.": "Parlez-nous de votre projet.",
  "Tell us the type of project you need (ex. basement renovation).": "Dites-nous quel type de projet vous avez besoin (ex. rénovation de sous-sol).",
  "Tell us your budget for the project.": "Indiquez-nous votre budget pour le projet.",
  "Tell us when you want the project to start and any other important dates.": "Dites-nous quand vous souhaitez commencer le projet et toute autre date importante.",
  "Simply choose one of the options below that best match your needs, and we'll instantly generate a shoppable estimate. You'll be redirected to the estimate, where you can make upgrades, refine your selections, and submit a request for proposal to the contractor with just one click.": "Choisissez simplement l’une des options ci-dessous qui correspond le mieux à vos besoins, et nous générerons instantanément un devis achetable. Vous serez redirigé vers le devis, où vous pourrez faire des améliorations, affiner vos sélections et soumettre une demande de proposition au prestataire en un seul clic.",
  "View quote": "Voir le devis",
  "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Veuillez remplir le formulaire ci-dessous, et nous générerons instantanément un devis personnalisable. Vous pourrez effectuer des améliorations, affiner vos choix et soumettre une demande de proposition." 
};
