<script setup>
import { ref, defineEmits } from 'vue'
import Divider from 'primevue/divider'
import Card from 'primevue/card'
import BathroomImg from '@/assets/template_graphics/bathroom.png'
import KitchenImg from '@/assets/template_graphics/kitchen.png'
import RooflineImg from '@/assets/template_graphics/roofline.png'
import Tutorial from '@/assets/template_graphics/tutorial.png'
import Scratch from '@/assets/template_graphics/scratch.png'
import Fencing from '@/assets/template_graphics/fencing.png'
import Garage from '@/assets/template_graphics/garage3.png'
import House from '@/assets/template_graphics/house.png'
import Basement from '@/assets/template_graphics/basement.png'
import Siding from '@/assets/template_graphics/siding2.png'

const templates = ref([
  {
    title: 'Tutorial',
    subtitle: 'Get the basics down',
    description:
      '5 minute tutorial to quickly learn how to add items, drag and drop and set pricing.',
    image: Tutorial,
    key: 'tutorial'
  },
  {
    title: 'Start from scratch',
    subtitle: 'Blank slate',
    description: 'Build a detailed estimate from scratch. Best for experienced users.',
    image: Scratch,
    key: 'blank'
  },
  {
    title: 'Kitchen',
    subtitle: 'Estimate kitchen projects',
    description: 'Create estimates for kitchen renovations, including all necessary details.',
    image: KitchenImg,
    key: 'kitchen'
  },
  {
    title: 'Bathroom',
    subtitle: 'Estimate bathroom projects',
    description: 'Create estimates for bathroom renovations, including all necessary details.',
    image: BathroomImg,
    key: 'bathroom'
  },
  {
    title: 'Roof',
    subtitle: 'Estimate roofing projects',
    description: 'Create estimates for roof installations or repairs.',
    image: RooflineImg,
    key: 'roof'
  },
  {
    title: 'Fence',
    subtitle: 'Estimate fencing projects',
    description: 'Create estimates for fence installations.',
    image: Fencing,
    key: 'fence'
  },
  {
    title: 'Garage',
    subtitle: 'Estimate garage projects',
    description: 'Create estimates for building or renovating garages.',
    image: Garage,
    key: 'garage'
  },
  {
    title: 'New house',
    subtitle: 'Estimate new house projects',
    description: 'Create comprehensive estimates for building new houses.',
    image: House,
    key: 'new_house'
  },
  {
    title: 'Basement',
    subtitle: 'Estimate basement projects',
    description: 'Create estimates for basement finishing or remodeling.',
    image: Basement,
    key: 'basement'
  },
  {
    title: 'Exterior siding',
    subtitle: 'Estimate siding projects',
    description: 'Create estimates for exterior siding installations or repairs.',
    image: Siding,
    key: 'exterior_siding'
  },
  {
    title: 'Interior or exterior painting',
    subtitle: 'Estimate painting projects',
    description: 'Create estimates for interior or exterior painting jobs.',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'painting'
  },
  {
    title: 'Addition',
    subtitle: 'Estimate home addition projects',
    description: 'Create estimates for home additions.',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'addition'
  },
  {
    title: 'Additional dwelling unit',
    subtitle: 'Estimate ADU projects',
    description: 'Create estimates for building additional dwelling units (ADUs).',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'adu'
  },
  {
    title: 'Deck',
    subtitle: 'Estimate deck projects',
    description: 'Create estimates for deck building or repairs.',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'deck'
  },
  {
    title: 'Landscaping',
    subtitle: 'Estimate landscaping projects',
    description: 'Create estimates for landscaping work.',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'landscaping'
  },
  {
    title: 'Pool',
    subtitle: 'Estimate pool projects',
    description: 'Create estimates for pool installations or repairs.',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'pool'
  },
  {
    title: 'Foundation',
    subtitle: 'Estimate foundation projects',
    description: 'Create estimates for foundation work.',
    image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
    key: 'foundation'
  }
])

const emit = defineEmits(['choose'])
const choose = (option) => emit('choose', option)
</script>

<template>
  <div class="flex flex-col gap-5 user-select-none">
    <p class="text-xl mb-8">
      We've built thousands of estimates, so leverage our experience and start with a Bolster
      template to maximize your sales, and minimize your pain! If you insist, you can definitely
      build from scratch. And either way, just like everything in Bolster, your estimate will
      transition seamlessly into project management -- automatically
      <font-awesome-icon icon="sparkles" />
    </p>
    <div class="border-b border-surface-300 py-2 font-medium text-large">Start from a template</div>
    <div class="py-2 gap-4 flex flex-row flex-wrap justify-stretch items-stretch">
      <Card
        style="width: 25rem; overflow: hidden"
        v-for="(option, index) in templates"
        :key="index"
        class="hover:bg-surface-100/35 border border-surface-200"
      >
        <template #header>
          <div class="w-full h-40 overflow-hidden relative">
            <img
              alt="user header"
              :src="option.image"
              class="object-cover object-center w-full h-60"
            />
          </div>
        </template>
        <template #title>{{ option.title }}</template>
        <template #subtitle>{{ option.subtitle }}</template>
        <template #content>
          <p class="m-0">
            {{ option.description }}
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Btn severity="tertiary" class="w-full" :action="() => choose(option)">Choose this</Btn>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
