<template>
  <div class="quote-summary--container">
    <MiniModal
      size="full"
      :full="true"
      :scrollable="true"
      @close="onCloseSettings"
      ref="documentViewOptions"
    >
      <PresentationSettings v-model="combinedPresentationSettings" />
    </MiniModal>

    <Card class="quote-summary--content-card">
      <div class="w-full flex justify-between mb-5">
        <Btn severity="tertiary" @click="$emit('update-show-summary', 0)" size="md">
          <template #icon>
            <font-awesome-icon icon="arrow-left" />
          </template>
          Go back to presentation
        </Btn>
        <div>
          <Btn
            severity="tertiary"
            @click="viewDocumentOptions()"
            v-if="!$store.getters.isGuestUser && showDocumentOptions"
          >
            <template #icon>
              <font-awesome-icon icon="cog" />
            </template>
            Document options
          </Btn>

          <Btn severity="tertiary" :action="() => exportToPdf(1)" size="md">
            <template #icon>
              <font-awesome-icon icon="cloud-arrow-down" />
            </template>
            Download PDF
          </Btn>
        </div>
      </div>
      <PdfViewer
        :loading="loadingPdf"
        :supportForPDF="supportsPDFs"
        :download="savePDF"
        :screen-size="true"
      />
    </Card>
  </div>
</template>

<script>
import ObjectDistinct from '../../mixins/ObjectDistinct'
import PresentationMixin from '../presentation/PresentationMixin'
import PresentationSettings from '../presentation/PresentationSettings.vue'
import ExportToWord from '../../mixins/ExportToWord'
import QuotePDFMixin from './QuotePDFMixin'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'

export default {
  name: 'QuoteSummary',
  components: {
    PresentationSettings,
    PdfViewer
  },
  mixins: [ObjectDistinct('quote'), PresentationMixin, ExportToWord, QuotePDFMixin],

  data() {
    return {
      showImages: true,
      zoomInterval: 0.1,
      zoom: 0.8,
      loading: 0,
      presentationSettingsOverride: this.presentationSettingsOverrides
    }
  },

  methods: {
    viewDocumentOptions() {
      return this.$refs.documentViewOptions.open()
    },
    async exportToWord() {
      // @todo must add style sheets for each element based on computed style
      return this.exportToMicrosoftWord(
        this.$refs.content,
        this.quote_name.replace(/[^0-9a-zA-Z_-]/, '_')
      )
    },
    async exportToPdf() {
      this.$store.dispatch('alert', {
        message: 'Building the PDF now. This may take a minute...'
      })
      this.loading = 1
      this.savePDF(`${this.quote_name.replace(/[^0-9a-zA-Z_-]/, '_')}`)

      // await html2pdf()
      //   .set({
      //     margin: 10,
      //     html2canvas: {
      //       dpi: 800,
      //       letterRendering: true,
      //       // useCORS: true,
      //       scale,
      //       // letterRendering: true,
      //       useCORS: true,
      //     },
      //     jsPDF: { format: 'letter', orientation: 'portrait', compressPdf: true },
      //     image: { type: 'png', quality: 1 },
      //     filename: `${this.quote_name.replace(/[^0-9a-zA-Z_-]/, '_')}.pdf`,
      //     pagebreak: {
      //       before: ['.quote-summary--toc', '.quote-summary--approvals'],
      //       mode: ['css', 'legacy'],
      //       avoid: [
      //         'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      //         '.quote-summary--totals',
      //         '.quote-summary--cost-item',
      //         '.assembly-bottom',
      //         '.assembly-top',
      //       ],
      //     },
      //   })
      //   .from(this.$refs.content)
      //   .save();

      this.loading = 0
      // this.showImages = true;
    }
  },

  computed: {
    norm() {
      if (this.normalized) return this.normalized

      return this.$store.state.Quote.normalized
    },
    items() {
      return this.norm
    },
    presentationSettings: {
      get() {
        return this.combinedPresentationSettings
      },
      set(val) {
        this.combinedPresentationSettings = val
      }
    },
    combinedPresentationSettings: {
      get() {
        return {
          ...this.oPresentationSettings,
          ...this.presentationSettingsOverride
        }
      },
      set(settings) {
        this.presentationSettingsOverride = {
          ...settings,
          forceTemplateSettings: 1
        }
      }
    }
  },

  mounted() {
    if (this.presentationSettings.showApprovals) this.getChangeOrders()
  },

  props: {
    rootRefId: {
      default: null
    },
    normalized: {
      default: null
    },
    deselectOnDestroy: {
      default: false
    },
    showToc: {
      default: true
    },
    showDocumentOptions: {
      default: false
    },
    presentationSettingsOverrides: {
      default: () => ({})
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.quote-summary--container {
  .quote-summary--content {
    background: white;
    width: 100%;

    .quote-pres--sandbox {
      overflow: hidden !important;
      border-radius: 1em !important;
      display: block;

      .quote-pres--heading-content {
        padding: 1em 1em !important;
        flex-direction: row !important;
      }
      .quote-pres--logo {
        max-height: 300px !important;
      }

      justify-content: space-between;
      align-items: flex-start;
      .quote-pres--heading-content {
        align-items: flex-start;
      }
      .quote-pres--logo {
        max-width: 60% !important;
        max-height: 300px;
      }

      .quote-pres--heading-left {
        align-items: flex-start !important;
        text-align: left !important;
        justify-content: flex-start !important;
        margin-bottom: 0 !important;
        .quote-pres--heading-title {
          justify-content: flex-start;
          text-align: left;
        }
      }
      .quote-pres--heading-right {
        align-items: flex-end;
        text-align: right;
      }

      @media (max-width: 700px) {
        font-size: 0.9em !important;
        justify-content: space-between;
        align-items: flex-start;
        .quote-pres--heading-content {
          align-items: flex-start;
        }
        .quote-pres--logo {
          max-width: 60% !important;
          max-height: 300px;
        }

        .quote-pres--heading-left {
          align-items: flex-start !important;
          text-align: left !important;
          justify-content: flex-start !important;
          .quote-pres--heading-title {
            justify-content: flex-start;
            text-align: left;
          }
        }
        .quote-pres--heading-right {
          align-items: flex-end;
          text-align: right;
        }
      }
    }
  }
  .quote-summary--content-card {
    background: white !important;
  }
  .quote-summary--toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5em 0;
    > * + * {
      margin-left: 0.25em;
    }
  }

  .quote-notes,
  .prod-notes {
    margin: 1em 0;
    padding: 1em;
  }

  .prod-notes {
    border: 2px solid $deep-red-800;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    white-space: pre-line;
    > strong {
      margin-bottom: 0.5em;
    }
    > p {
      font-size: 1em !important;
      font-weight: 500;
    }
  }
}
</style>
