<template>
  <div class="quote-body--preview-toolbar sticky top-0 h-fit z-10">
    <div class="quote-body--preview-toolbar-details w-full">
      <div class="quote-body--preview-toolbar-options w-full">
        <div
          class="quote-body--preview-toolbar-options-inner flex justify-center md:justify-between w-full"
        >
          <Btn
            severity="tertiary-borderless"
            v-tooltip="'Edit presentation'"
            @click="isEditing = !isEditing"
            v-if="!$store.state.session.isGuestUser && !('quote' in $store.state.session.scope)"
            data-label="edit_presentation"
          >
            <span class="inline-block mr-2">Presentation editor</span>
            <toggle v-model="isEditing" />
          </Btn>
        </div>
      </div>
    </div>
    <mini-modal size="full" :full="true" :scrollable="true" :closeable="true" ref="showHide">
      <template #header>Presentation Settings</template>
      <PresentationSettings
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :type="type"
        :editing="editingLocal"
        @editing="(b) => (editingLocal = b)"
        v-model="subComponentInterface"
      />
    </mini-modal>
  </div>
</template>

<script>
import ObjectDistinct from '@/components/mixins/ObjectDistinct'
import PresentationMixin from './PresentationMixin'
import PresentationSettings from '@/components/quote/presentation/PresentationSettings.vue'
import Toggle from '@/components/ui/Toggle.vue'

export default {
  name: 'PresentationToolbar',
  mixins: [ObjectDistinct('quote'), PresentationMixin],
  components: {
    Toggle,
    PresentationSettings
  },
  data() {
    return {
      unsavedChanges: 0
    }
  },
  watch: {
    oPresentationSettings(a) {
      if (!this.templateFull || !this.templateFull.oMeta) return

      const original = {
        ...this.templateFull
      }
      const current = {
        ...this.templateFull,
        oMeta: a
      }

      const eq = c.jsonEquals(original.oMeta, current.oMeta, true)
      if (!eq) {
        this.unsavedChanges = 1
      }
    }
  },
  methods: {
    toggleSettings() {
      this.$refs.showHide.toggle()
    },
    async handleResetChanges() {
      await this.loadTemplate(this.presentationTemplateId)
    },
    handleSaveTemplate() {
      this.templateFull.company_id ? this.saveTemplate() : this.savePresentationTemplateNew()
    },
    async handleLoadTemplate() {
      await this.loadTemplate(this.presentation_template_id)
    }
  },
  mounted() {
    this.handleLoadTemplate()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.quote-body--preview-toolbar {
  box-shadow: 0px 20px 20px -20px rgba(115, 120, 123, 0.4);

  height: fit-content;
  flex: 0 0 5em;

  .quote-body--preview-toolbar-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: fit-content;

    .quote-body--preview-toolbar-options {
      flex-wrap: nowrap;
      overflow-x: auto;
      // @extend %scrollbarSm;
      background-color: $flame-white !important;

      /*row-gap: 0.5rem;*/
      > *:not(:last-child) {
        margin-right: 0.5rem !important;
      }

      .quote-body--preview-toolbar-options-inner {
        @apply px-4 py-1.5 w-full;
        > * {
          margin-right: 0.5em;
          width: fit-content;
          min-width: fit-content;
          flex: 0 auto;
        }
      }
    }
  }
}

#app.small-format {
  .quote-body--preview-toolbar-options {
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
      margin-bottom: 0.15em;
    }
  }
}
</style>
