<template>
  <ListItem :condensed="true">
    <div class="grid grid-cols-4 gap-2 w-full">
      <div class="flex items-center">
        <p class="mb-0">{{ item.title }}</p>
      </div>
      <div class="flex items-center justify-center">
        <Chip
          :pt="{
            root: '!rounded !px-2 !text-sm min-w-[136px] justify-center !flex',
            label: '!my-1'
          }"
          :ptOptions="{ mergeProps: true }"
          :class="billingStatusClasses"
          :label="billingMessage"
        />
      </div>
      <div class="flex items-center justify-center">
        <span class="ml-3 text-sm">
          {{ item.items.length }} item{{ item.items.length > 1 ? 's' : '' }} included
        </span>
      </div>
      <div class="flex justify-end items-center" @click="() => openInvoice(item.invoice_id)">
        <a
          class="text-blue-print-300 cursor-pointer hover:text-blue-print-800 transition-all text-nowrap"
        >
          View receipt
        </a>
      </div>
    </div>
    <template v-if="showFooter" #footer>
      <slot name="footer" />
    </template>
  </ListItem>
</template>

<script setup>
import { defineProps, computed, toRef } from 'vue'
import Chip from 'primevue/chip'
import useInvoice from '../../composables/Invoice'
import ListItem from './ListItem.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
    required: true
  }
})

const item = toRef(props, 'item')

const billingStatusClasses = computed(() => {
  if (!item.value.items || item.value.items.length === 0) return '!bg-blue-100 !text-blue-900'
  const lineItem = item.value.items[0]
  if (lineItem.invoice_status === 'p') return '!bg-orange-100 !text-orange-900'
  if (lineItem.invoice_status === 'e' || lineItem.invoice_status === 'a')
    return '!bg-matcha-100 !text-matcha-900'
  return '!bg-blue-100 !text-blue-900'
})

const billingMessage = computed(() => {
  if (!item.value.items || item.value.items.length === 0) return 'Processing'
  const lineItem = item.value.items[0]
  if (lineItem.invoice_status === 'p') return 'Awaiting Payment'
  if (lineItem.invoice_status === 'e' || lineItem.invoice_status === 'a') return 'Paid'
  return 'Processing'
})

const { openInvoice } = useInvoice()
</script>
