import { toRefs, reactive } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import useSchedule from '@/components/schedule/Schedule'
import useGantt from '@/components/ui/gantt/Gantt'
import eventBus from '@/eventBus'
import statuses from '@/../imports/api/Statuses'

export const initialState = {
  predecessor: null,
  successor: null,
  currentPredecessor: null
}

const defaultStage = {
  text: 'New construction stage',
  reference_type: 'stage',
  type: 'task'
}

// global state

export default () => {
  const context = reactive({
    ...initialState
  })

  // Composables
  const store = useStore()
  const { gantt, data } = useGantt()
  const { adjustDates, projectId, calculateEndDate, hoursPerDayTotal, calculateCrewSize } =
    useSchedule()

  /**
   * Get a scoped id for the project schedule
   * @param {String} id
   * @param {String} scope
   * @returns
   */
  const scopeId = (id) => `${projectId.value}_${id}`

  /**
   * Get material lists
   * @param {Array} items
   * @returns
   */
  const getMaterialList = (items) => items.find((s) => s.type === 'list')

  /**
   * Get labor items
   * @param {Array} items
   * @returns
   */
  const getActionItems = (items) => items.filter((s) => s.type !== 'list')

  /**
   * Get all the remaining items
   * @param {Array} items
   * @returns
   */
  const getRemainingItems = (items) => items.filter((s) => s.item_status !== statuses.Completed)

  /**
   * Get all the completed items
   * @param {Array} items
   * @returns
   */
  const getCompletedItems = (items) => items.filter((s) => s.item_status === statuses.Completed)

  /**
   * Retrieves a task from the Gantt chart using the provided stage ID.
   *
   * This function generates a task ID based on the given stage ID using the `scopeId` function,
   * and then retrieves the corresponding task object from the Gantt chart.
   *
   * @param {string|number} stageId - The ID of the stage for which the task needs to be retrieved.
   * @returns {Object|null} - The task object corresponding to the provided stage ID, or null if no such task exists.
   */
  const getStageTask = (stageId) => {
    const taskId = scopeId(stageId)
    return gantt.value.getTask(taskId)
  }

  /**
   * Update all the children of the stage
   * @param {String} itemId
   * @param {String} endDate
   * @param {String} startDate
   * @returns
   */
  const updateChildren = (itemId, endDate, startDate) => {
    const itemsWithinStage = gantt.value.getChildren(itemId)
    const children = itemsWithinStage.map((id) => gantt.value.getTask(id))
    const updates = children.reduce((acc, i) => {
      // always update the material list to match stage start and end
      if (i.reference_type === 'list') {
        const duration = gantt.value.calculateDuration({
          start_date: new Date(startDate),
          end_date: new Date(endDate)
        })
        acc.push({
          duration,
          start_date: startDate,
          end_date: endDate,
          id: i.id
        })
        return acc
      }

      const itemDuration = gantt.value.calculateDuration({
        start_date: new Date(i.start_date),
        end_date: new Date(i.end_date)
      })

      // if the parent start is greater than the items start we
      // need to adjust the children
      if (startDate.getTime() > i.start_date.getTime()) {
        const newEnd = moment.min([moment(startDate).add(itemDuration, 'days'), moment(endDate)])._d
        const duration = gantt.value.calculateDuration({
          start_date: new Date(startDate),
          end_date: new Date(newEnd)
        })
        acc.push({
          duration,
          start_date: startDate,
          end_date: newEnd,
          id: i.id
        })
      }
      // if the date is less than the items we need to correct the items
      // they cannot go past the end of the stage
      if (i.end_date.getTime() > endDate.getTime()) {
        const newStart = moment.max([
          moment(endDate).subtract(itemDuration, 'days'),
          moment(startDate)
        ])._d
        const duration = gantt.value.calculateDuration({
          start_date: new Date(newStart),
          end_date: new Date(endDate)
        })
        acc.push({
          duration,
          start_date: newStart,
          end_date: endDate,
          id: i.id
        })
      }
      return acc
    }, [])
    if (updates.length > 0) {
      eventBus.$emit('refresh-gantt-items', {
        items: updates
      })
    }
    return updates
  }

  /**
   * Save all the updates to the children of the stage
   * @param {Array} updates
   * @returns
   */
  const saveChangesToChildren = (updates) => {
    const changes = updates.map(({ start_date: startDate, end_date: endDate, id }) => {
      const { startDate: start, endDate: end } = adjustDates(startDate, endDate)
      return {
        start_date: start,
        end_date: end,
        id
      }
    })
    return store.dispatch('ajax', {
      path: 'schedule/saveScheduleChanges',
      data: {
        changes
      }
    })
  }

  /**
   * Get the total completed item count
   * @param {String} id
   * @returns
   */
  const getCompletedCount = (id) => {
    const siblingIds = gantt.value.getSiblings(id)
    const siblings = siblingIds.map((i) => gantt.value.getTask(i))
    const materialList = getMaterialList(siblings)
    const actionList = getActionItems(siblings)
    let items = actionList
    if (materialList) items = [...items, ...materialList.list]
    const completed = getCompletedItems(items)
    return completed.length || 0
  }

  /**
   * Get the remaining time of stage
   * @param {String} id
   * @returns
   */
  const getTimeRemaining = (id) => {
    const siblingIds = gantt.value.getSiblings(id)
    const siblings = siblingIds.map((i) => gantt.value.getTask(i))
    const actionItems = getActionItems(siblings)
    const remaining = getRemainingItems(actionItems)
    const remainingTime = remaining.reduce((total, item) => {
      total = total + (item.item_total_hours || 0)
      return total
    }, 0)
    return remainingTime * 1000
  }

  /**
   * Create a stage
   * @param {Object} stage
   * @returns
   */
  const createStage = (stage) => {
    return store.dispatch('Stage/save', {
      object: stage,
      go: false
    })
  }

  /**
   * Creates a new task for a given stage in the Gantt chart.
   *
   * This function creates a new task for the provided stage and adds it to the Gantt chart.
   * It removes unnecessary properties from the stage, calculates the start date based on the last stage,
   * and sets various properties for the new task such as start date, end date, stage name, and duration.
   *
   * @param {Object} stage - The stage object containing details of the stage to be added as a task.
   * @param {number|null} [index=null] - The position at which to insert the new task in the Gantt chart. Default is null.
   * @param {number|null} [duration=null] - The duration of the new task. Default is null.
   * @returns {Object} - The newly created task object from the Gantt chart.
   */

  const createTaskForStage = (stage, index = null, duration = null) => {
    if (stage.id) delete stage.id
    if (stage.type) delete stage.type
    const lastStage = getLastStage()
    const stageTaskId = gantt.value.addTask(
      {
        ...defaultStage,
        ...stage,
        // set the start date to the end date of the last stage
        ...(lastStage ? { start_date: lastStage.end_date } : {}),
        ...(stage.end_date ? { end_date: stage.end_date } : {}),
        ...(stage.start_date ? { start_date: stage.start_date } : {}),
        ...(stage.stage_name ? { text: stage.stage_name } : {}),
        ...(duration ? { duration } : stage.end_date ? {} : { duration: 1 }),
        id: scopeId(stage.stage_id)
      },
      null,
      index
    )
    return gantt.value.getTask(stageTaskId)
  }

  /**
   * Retrieves an existing task for a given stage or creates a new one if none exists.
   *
   * This function checks if a task associated with a given stage ID already exists. If it does,
   * it returns the existing task. If not, it creates a new task for the given stage.
   *
   * @param {Object} stage - The stage object for which the task is being retrieved or created.
   * @param {number|null} [index=null] - Optional index parameter for the new task creation.
   * @returns {Object} - The existing or newly created task associated with the stage.
   */
  const getOrCreateTaskForStage = (stage, index = null) => {
    const stageTask = getStageTask(stage.stage_id)
    if (stageTask) return stageTask
    return createTaskForStage(stage, index)
  }

  /**
   * Update a stage
   * @param {Object} stage
   * @param {Object} changes
   * @returns
   */
  const updateStage = (stageId, changes) => {
    return store.dispatch('Stage/partialUpdate', {
      selected: [
        {
          type: 'stage',
          stage_id: stageId,
          ...changes
        }
      ]
    })
  }

  /**
   * Update a stage
   * @param {Object} stage
   * @param {Object} changes
   * @returns
   */
  const updateStageOrder = async (stageId, predecessorStageId) => {
    if (stageId === predecessorStageId) return
    return store.dispatch('ajax', {
      path: 'Stage_Order/reorder',
      data: {
        stageId,
        predecessorStageId,
        quoteId: projectId.value
      }
    })
  }

  /**
   * Updates the stage of a specified item.
   *
   * This function dispatches an AJAX request to update the stage of an item with the given ID.
   * It sends the stage ID in the request data to the backend endpoint `Item/updateStage/{id}`.
   *
   * @param {number|string} id - The ID of the item to update.
   * @param {number|string} stageId - The ID of the new stage to assign to the item.
   * @returns {Promise} - A promise that resolves when the AJAX request is completed.
   */
  const updateStageOfItem = (id, stageId) => {
    return store.dispatch('ajax', {
      path: `Item/updateStage/${id}`,
      data: {
        stageId
      }
    })
  }

  /**
   * Find the previous stage task based on order of operation
   * @param {Object} stage
   * @returns {Object|null}
   */
  const findPredecessor = (stage) => {
    let prevStageId = gantt.value.getPrev(stage.id)

    // Loop through previous tasks until a stage task is found or no more tasks exist
    while (prevStageId) {
      const previousStage = gantt.value.getTask(prevStageId)

      // Check if the previous task is a stage (exclude item tasks)
      if (previousStage.reference_type === 'stage') {
        return previousStage
      }

      // If it's not a stage, get the previous task and continue
      prevStageId = gantt.value.getPrev(prevStageId)
    }

    return null
  }

  /**
   * Find the next stage task based on order of operation
   * @param {Object} stage
   * @returns {Object|null}
   */
  const findSuccessor = (stage) => {
    let nextStageId = gantt.value.getNext(stage.id)

    // Loop through next tasks until a stage task is found or no more tasks exist
    while (nextStageId) {
      const nextStage = gantt.value.getTask(nextStageId)

      // Check if the next task is a stage (exclude item tasks)
      if (nextStage.reference_type === 'stage') {
        return nextStage
      }

      // If it's not a stage, get the next task and continue
      nextStageId = gantt.value.getNext(nextStageId)
    }

    return null
  }

  /**
   * Remove all links associated with the given stage ID.
   * @param {String|Number} stageId
   */
  const removeLinks = (stageId) => {
    const links = gantt.value.getLinks()
    links.forEach((link) => {
      if (link.source === stageId || link.target === stageId) {
        const task = gantt.value.getTask(link.target)
        if (task && task.reference_type === 'item') return
        gantt.value.deleteLink(link.id)
      }
    })
  }

  /**
   * Recursively update the predecessor and successor links for the stages.
   * @param {Object} stage - The current stage
   */
  const updateStageLinksRecursively = (stage) => {
    const stageId = stage.id

    // 1. Find predecessor and successor
    const predecessor = findPredecessor(stage)
    const successor = findSuccessor(stage)

    // 2. Remove existing links for the stage
    removeLinks(stageId)

    // 3. Add new links based on predecessor and successor
    if (predecessor) {
      gantt.value.addLink({
        source: predecessor.id,
        target: stageId,
        parent: projectId.value,
        type: 0
      })
    }

    if (successor) {
      gantt.value.addLink({
        source: stageId,
        target: successor.id,
        parent: projectId.value,
        type: 0
      })

      // Recursively update the next stage (successor)
      updateStageLinksRecursively(successor)
    }
  }

  /**
   * Handle updating the successor node and all its relations
   * @param {String} stageId
   * @returns
   */
  const updateSuccessor = async (stageId) => {
    if (!context.successor) return
    // update the stage
    updateStageOrder(context.successor.stage_id, stageId, context.successor.stage_order_id || null)
    // check to see if new successor and create new src link
    const successor = findSuccessor(context.successor)
    if (!successor) return

    updateStageOrder(
      successor.stage_id,
      context.successor.stage_id,
      successor.stage_order_id || null
    )
  }

  /**
   * Create a copy
   * @param {*} stage
   */
  const createCopyOfStage = async (stage) => {
    // need to create a copy of the stage and update order of operation
    const { object } = await createStage({
      stage_name: `${stage.text}`,
      trade_type_id: stage.trade_type_id,
      stage_desc: stage.stage_desc,
      after_stage_id: context.predecessor?.stage_id || null
    })
    const newStageId = object.stage_id
    // create the new stage on the gantt chart
    // make sure to set the index so it appears in the right spot
    const newTaskStage = createTaskForStage({ ...stage, ...object }, context.predecessor.$index)
    // update all the items to include new stage
    const children = gantt.value.getChildren(stage.id)
    await Promise.all(
      children.map(async (childId) => {
        const child = gantt.value.getTask(childId)
        await updateStageOfItem(childId, newStageId)
        gantt.value.setParent(child, newTaskStage.id)
        gantt.value.updateTask(childId)
      })
    )
    // remove the old stage task
    gantt.value.deleteTask(stage.id)
  }

  const handleGlobalStage = async (stage) => {
    await createCopyOfStage(stage)
    // update for resulting predecessor and successor changes
    updateSuccessor(stage.stage_id)
  }

  /**
   * Loop through all successors and re-evaluate start dates
   */
  const updateDateRanges = (stage) => {
    updateDateRange(stage)
    // update all children
    updateStagesChildren(stage)
    const successor = findSuccessor(stage)
    if (!successor) return
    // call update date ranges until there are no successors
    updateDateRanges(successor)
  }
  const updateStagesChildren = (stage) => {
    const { start_date: stageStartDate, end_date: stageEndDate } = stage
    const children = gantt.value.getChildren(stage.id)

    children.forEach((childId) => {
      const child = gantt.value.getTask(childId)
      const childStartDate = new Date(child.start_date)
      const childEndDate = new Date(child.end_date)

      // Calculate the task duration (in days)
      const childDuration = gantt.value.calculateDuration(childStartDate, childEndDate)

      // If the child's start date is before the stage's start date, adjust it while keeping the duration
      if (childStartDate < new Date(stageStartDate)) {
        const newStartDate = new Date(stageStartDate)
        const newEndDate = gantt.value.calculateEndDate(newStartDate, childDuration)
        child.start_date = newStartDate
        child.end_date = newEndDate
      }

      // If the child's start date is greater than the stage's end date, adjust to fit within the stage
      if (childStartDate > new Date(stageEndDate)) {
        const newEndDate = new Date(stageEndDate)
        const newStartDate = gantt.value.calculateEndDate(newEndDate, -childDuration) // Moves the start date back by the duration
        child.start_date = newStartDate
        child.end_date = newEndDate
      }

      // If the child's end date exceeds the stage's end date, adjust it and calculate the new start date
      if (childEndDate > new Date(stageEndDate)) {
        const newEndDate = new Date(stageEndDate)
        const newStartDate = gantt.value.calculateEndDate(newEndDate, -childDuration) // Move back by the duration
        child.start_date = newStartDate
        child.end_date = newEndDate
      }

      // If the child's start date is greater than the end date, reset the task with a minimum duration of 1 day
      if (new Date(child.start_date) >= new Date(child.end_date)) {
        const newEndDate = gantt.value.calculateEndDate(new Date(child.start_date), 1) // Sets minimum 1 day duration
        child.end_date = newEndDate
      }

      // Update the task in Gantt
      gantt.value.updateTask(child.id)
    })
  }

  const updateDateRange = (stage) => {
    const predecessor = findPredecessor(stage)
    if (!predecessor) return
    // get the start date of
    const { end_date: startDate } = predecessor
    const { duration, id } = stage
    const endDate = calculateEndDate(startDate, duration)
    const { endDate: end, startDate: start } = adjustDates(startDate, endDate)
    const change = {
      id,
      start_date: new Date(start),
      end_date: new Date(end)
    }
    // refresh the gantt items
    eventBus.$emit('refresh-gantt-items', {
      items: [change]
    })
    // save ths stage schedule changes
    return store.dispatch('ajax', {
      path: 'schedule/saveStageScheduleChanges',
      data: {
        changes: [
          {
            ...change,
            id: stage.stage_id,
            quote_id: projectId.value
          }
        ]
      }
    })
  }

  /**
   * Update the order of operation for a stage
   * @param {Object} stage
   * @returns
   */
  const onUpdateStageOrderOfOperation = async (stage) => {
    // need to be able to get new order of operation
    context.predecessor = findPredecessor(stage)
    context.successor = findSuccessor(stage)

    // custom stage so just update stage
    updateStageOrder(
      stage.stage_id,
      context.predecessor?.stage_id || null,
      stage.stage_order_id || null
    )

    updateStageLinksRecursively(stage)

    // update for resulting predecessor and successor changes
    updateSuccessor(stage.stage_id)

    // update the stage date range to fit in where previous stage was
    updateDateRanges(stage)
  }

  const onCreateStage = async () => {
    // add a new stage task to gantt
    // add to bottom of schedule
    // stage name input is highlighted
    const lastStage = getLastStage()
    const { object } = await createStage({
      stage_name: `${defaultStage.text}`,
      after_stage_id: lastStage.stage_id || null
    })
    const stageTask = createTaskForStage({
      ...object,
      isActive: true
    })
    // make sure the stage is in the stored data set
    data.value.data.push(stageTask)
    if (lastStage && lastStage.id !== stageTask.id) {
      gantt.value.addLink({
        source: lastStage.id,
        target: stageTask.id,
        type: 0
      })
    }
    setTimeout(() => eventBus.$emit(`focus-task-name-${stageTask.id}`))
    return stageTask
  }

  /**
   * Retrieves the last stage object from a dataset.
   *
   * This function iterates through the dataset in reverse order and returns the
   * last object where the `reference_type` is 'stage'. If no such object is found,
   * it returns null.
   *
   * @returns {Object|null} - The last stage object if found, otherwise null.
   */
  const getLastStage = () => {
    let lastStageObject = null
    for (let i = data.value.data.length - 1; i >= 0; i--) {
      if (data.value.data[i].reference_type === 'stage') {
        lastStageObject = data.value.data[i]
        break
      }
    }
    return lastStageObject
  }

  /**
   * Finds the predecessor task for a given stage.
   *
   * This function searches for a task that precedes the given stage. It first looks for a predecessor
   * based on the `after_stage_id` property of the stage. If no predecessor is found, it then considers
   * the `stage_order` property to determine the predecessor.
   *
   * @param {Object} stage - The stage object for which the predecessor task needs to be found.
   * @param {number} stage.after_stage_id - The ID of the stage that should come before the current stage.
   * @param {number} stage.stage_order - The order of the current stage.
   * @returns {Object|null} - Returns the predecessor task object if found, otherwise returns null.
   */
  const findPredecessorTask = (stage) => {
    const tasks = data.value.data.filter((t) => t.reference_type === 'stage')
    let predecessor = null
    if (stage.after_stage_id)
      predecessor = tasks.find((task) => task.stage_id === stage.after_stage_id)
    if (predecessor) return predecessor
    if (stage.stage_order) {
      let previous = null
      tasks.forEach((task) => {
        if (task.stage_order && task.stage_order > stage.stage_order && !predecessor) {
          predecessor = previous
        }
        previous = task
      })
    }
    return predecessor
  }

  /**
   * Get the total hours by items
   * @param {Array} items
   * @return {number}
   */
  const getHourTotalByItems = (items) => {
    let total = 0
    items.forEach((item) => {
      let task = item
      if (typeof item === 'string') task = gantt.value.getTask(item)
      // account for the crew size, larger the crew less time the task will take
      const crewSize = calculateCrewSize(item.assignees)
      const hours = task.item_total_hours || 0
      total += crewSize > 1 ? hours / crewSize : hours
    })
    return total
  }

  /**
   * Get the total day count either based on items or generate one
   * @param {Array} items
   * @return {number}
   */
  const generateDuration = (items) => {
    const duration = getHourTotalByItems(items)
    const totalWorkingHours = hoursPerDayTotal.value
    // default to one working day
    return duration > totalWorkingHours ? Math.ceil(duration) : totalWorkingHours
  }

  /**
   * Calculate the working days based on the hour duration of the stage and working hours set
   * @param {Array} items
   * @return {number}
   */
  const generateWorkDays = (items) => {
    const duration = generateDuration(items)
    const workingHours = hoursPerDayTotal.value
    const days = duration / workingHours
    return days > 1 ? Math.ceil(days) - 1 : 1
  }

  const onDeleteStage = (stageId) => {
    const stage = gantt.value.getTask(stageId)
    // check if stage has successor
    const successor = findSuccessor(stage)
    const predecessor = findPredecessor(stage)
    // remove link
    removeLinks(stageId)
    // add new link to successor from predecesspr
    gantt.value.addLink({
      source: predecessor.id,
      target: successor.id,
      type: 0
    })
    gantt.value.deleteTask(stageId)
  }

  // Return
  return {
    ...toRefs(context),
    updateChildren,
    getTimeRemaining,
    getCompletedCount,
    saveChangesToChildren,
    getMaterialList,
    getActionItems,
    getRemainingItems,
    getCompletedItems,
    onUpdateStageOrderOfOperation,
    updateSuccessor,
    onCreateStage,
    updateStage,
    updateStageOrder,
    handleGlobalStage,
    getStageTask,
    createTaskForStage,
    getOrCreateTaskForStage,
    getLastStage,
    findPredecessorTask,
    generateWorkDays,
    generateDuration,
    getHourTotalByItems,
    onDeleteStage
  }
}
