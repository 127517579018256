<template>
  <div v-if="!object.cost_type_is_task" class="review-all--item">
    <component
      :is="
        object.item_type === 'assembly' || object.type === 'assembly'
          ? 'ReviewAllAssembly'
          : 'ReviewAllCostItem'
      "
      :object="object"
      :parentRefId="parentRefId"
      :normalized="normalized"
      :presentationSettings="presentationSettings"
      :refId="refId"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewAllItem',
  data() {
    return {
      objectLocal: this.object
    }
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    presentationSettings: {
      type: Object
    },
    store: {
      type: String,
      default: 'Quote'
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    normalized: {
      type: Object
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.review-all--item {
  width: 100%;
}
</style>
