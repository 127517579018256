<template>
  <RecursiveSelectionsItem
    v-for="ref of groups"
    :key="ref"
    :ref-id="ref"
    :parent-ref-id="norm[ref].parentRefId"
    :dimensions="possDimensions"
    :presentation-settings="presentationSettings"
    :editable="editable"
    :store="store"
    :interactiveRefs="interactiveRefs"
    :isInQuoteEditor="isInQuoteEditor"
    :disabledList="disabledList"
  />
</template>

<script>
import TranslationMixin from './languages/TranslationMixin'
import eventBus from '../../../eventBus'
import RecursiveSelectionsItem from '@/components/quote/presentation/RecursiveSelectionsItem.vue'
import { useMediaQuery } from '@/composables/mediaQuery'

export default {
  name: 'Selections',
  mixins: [TranslationMixin],
  emits: [
    'disabled-list',
    'groups',
    'options',
    'setEmphasizedItems',
    'update-interactive-items',
    'highlight-item',
    'update-all-items'
  ],
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  data() {
    return {
      fullRemoval: [],
      notOptional: [],
      titleStyle: {},
      excludeList: [],
      activeIndices: [],
      callback: null,
      currentInteractiveIndex: 0,
      disabledList: []
    }
  },
  created() {
    // Listen to the event emitted by QuotePresentationTOC
    eventBus.$on('toggle-section', ({ ref, isOpen }) => {
      if (isOpen) {
        this.activeIndices = {
          ...this.activeIndices,
          [ref]: this.groups[ref]?.subRefs?.map((_, index) => index) || []
        }
      } else {
        this.activeIndices = {
          ...this.activeIndices,
          [ref]: []
        }
      }
    })

    eventBus.$on('toggle-section-from-toc', ({ ref, isOpen }) => {
      if (isOpen) {
        this.$set(
          this.activeIndices,
          ref,
          this.groups[ref]?.subRefs?.map((_, index) => index) || []
        )
      } else {
        this.$set(this.activeIndices, ref, [])
      }
    })

    // Restore active indices if a section is reopened
    eventBus.$on('restore-active-index', (ref) => {
      if (!this.activeIndices[ref] || this.activeIndices[ref].length === 0) {
        this.$set(
          this.activeIndices,
          ref,
          this.groups[ref]?.subRefs?.map((_, index) => index) || []
        )
      }
    })

    eventBus.$on('add-to-disabled-list', (refId) => {
      if (!this.disabledList.includes(refId)) {
        this.disableChildren(refId)
      }
    })

    eventBus.$on('remove-from-disabled-list', (refId) => {
      this.enableChildren(refId)
    })
  },
  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },

    object() {
      return this.norm[this.refId]
    },

    preferredOrder() {
      const preferredOrder = [this.object.refId]
      const goDown = (children) => {
        children.forEach((childRef) => {
          preferredOrder.push(childRef)
          if (this.norm[childRef]?.aoChildren) goDown(this.norm[childRef]?.aoChildren)
        })
      }
      goDown(this.object.aoChildren)
      return preferredOrder
    },

    subRefs() {
      return Object.keys(this.norm)
    },

    allDimensionZones() {
      const norm = this.norm
      const req = this.object.asRequiredDimensions.length
        ? { [this.refId]: this.object.asRequiredDimensions }
        : {}

      this.subRefs
        .filter((subRef) => norm[subRef].type === 'assembly')
        .forEach((subRef) => {
          if (!norm[subRef].asRequiredDimensions.length) return

          if (this.fullRemoval.includes(subRef)) return

          const { dependencies } = _.getDimensionDependencies(
            norm[subRef].oDimensions,
            norm[subRef].asRequiredDimensions
          )

          const nonDerived = norm[subRef].asRequiredDimensions.filter(
            (abbr) => !dependencies[abbr].length
          )

          if (!nonDerived.length) return

          req[subRef] = [...nonDerived]
        })

      return req
    },

    subRefsEmphasized() {
      const norm = this.norm

      return this.subRefs.filter(
        (ref) =>
          (norm[ref].assembly_emphasis && norm[ref].assembly_emphasis > 0) ||
          (norm[ref].cost_type_emphasis && norm[ref].cost_type_emphasis > 0)
      )
    },

    subRefsVariations() {
      const norm = this.norm

      return this.subRefs.filter(
        (ref) => norm[ref].variation_parent_cost_type_id || norm[ref].cost_type_is_variation_parent
      )
    },

    subRefsOptional() {
      const norm = this.norm

      return this.subRefs.filter(
        (ref) => norm[ref].cost_item_is_optional || norm[ref].assembly_is_optional
      )
    },

    subRefsInput() {
      const norm = this.norm

      return Object.keys(norm).filter(
        (r) =>
          norm[r].oInputRequired &&
          norm[r].oInputRequired.inputs &&
          norm[r].oInputRequired.inputs.length
      )
    },

    subRefsAddons() {
      const norm = this.norm

      return Object.keys(norm).filter((r) => norm[r].aoAddons && norm[r].aoAddons.length)
    },
    interactiveRefs() {
      const order = this.preferredOrder
      const interactiveRefs = [
        ...this.subRefsOptional,
        ...this.subRefsVariations,
        ...this.subRefsInput,
        ...this.subRefsAddons,
        ...this.subRefsEmphasized
      ]
      const uniqueInteractiveRefs = _.uniq(interactiveRefs)
        .filter((ref) => this.shouldShowRef(ref) && !this.excludes.includes(ref))
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
      this.setInteractiveItems(uniqueInteractiveRefs)
      return uniqueInteractiveRefs
    },
    standardRefs() {
      return this.subRefs.filter((ref) => !this.interactiveRefs.includes(ref))
    },
    subRefsAll() {
      const prefo = this.preferredOrder
      const allRefs = _.uniq([...this.interactiveRefs, ...this.standardRefs])
        .filter((ref) => this.shouldShowRef(ref))
        .sort((a, b) => prefo.indexOf(a) - prefo.indexOf(b))
      this.setAllItems(allRefs)
      return allRefs
    },

    excludes() {
      return this.excludeList
    },

    groups() {
      const groups = Object.values(this.object.aoChildren)
      const orderedGroups = _.uniq(groups)
        .filter((ref) => this.shouldShowRef(ref))
        .sort((a, b) => this.preferredOrder.indexOf(a) - this.preferredOrder.indexOf(b))
      this.$emit('groups', orderedGroups)
      return orderedGroups
    },

    variationItemIds() {
      const norm = this.norm

      const grouped = this.subRefsVariations.reduce(
        (acc, ref) => ({
          ...acc,
          [norm[ref].variation_parent_cost_type_id || norm[ref].cost_type_id]: ref
        }),
        {}
      )

      grouped.refs = this.subRefsVariations

      return grouped
    },
    getActiveIndex() {
      return Array.from({ length: this.groups.length }, (_, i) => i)
    }
  },
  watch: {
    subRefsOptional() {
      this.excludeList = this.initiated
        ? this.excludeList
        : this.subRefsOptional.filter(
            (refId) => !this.norm[refId][`${this.norm[refId].type}_is_included`]
          )
      this.initiated = 1
    },
    subRefsAll: {
      immediate: true,
      handler(rs) {
        this.$emit('options', rs.length - this.subRefsEmphasized.length)
      }
    },
    subRefsEmphasized: {
      immediate: true,
      handler(value) {
        this.$emit('setEmphasizedItems', value.length)
      }
    }
  },
  asyncComputed: {
    possDimensions: {
      async get() {
        return this.$store.dispatch('Dimension/getPossibleDimensions')
      },
      default: () => ({})
    }
  },
  methods: {
    async setOffsets() {
      const firstSectionTitle = this.$refs.sectionTitle && this.$refs.sectionTitle[0]
      if (!firstSectionTitle) return
      const parent = c.getScrollParent(firstSectionTitle)
      const { top, left: parentLeft } = parent.getBoundingClientRect()

      this.callback = () => {
        this.$refs.sectionTitle.forEach((head) => {
          const headTop = head.getBoundingClientRect().top
          const diff = headTop - top
          if (Math.abs(diff) < 5) {
            head.classList.add('current')
          } else if (Math.abs(diff) > 15) {
            head.classList.remove('current')
          }
        })
      }

      await this.$nextTick()
      parent.addEventListener('scroll', this.callback, { passive: true })

      const lgFormatExtra = this.smallFormat ? 0 : 50
      const smallFormatExtra = this.smallFormat ? 25 : 0

      const { left } = firstSectionTitle.getBoundingClientRect()
      const padDiff = left - parentLeft + lgFormatExtra

      this.titleStyle = {
        marginLeft: `${-padDiff}px`,
        paddingLeft: `${padDiff + smallFormatExtra}px`
      }
    },
    async clearOffsets() {
      window.removeEventListener('scroll', this.callback)
      const firstSectionTitle = this.$refs.sectionTitle && this.$refs.sectionTitle[0]
      if (firstSectionTitle) {
        const parent = c.getScrollParent(firstSectionTitle)
        parent.removeEventListener('scroll', this.callback, { passive: true })
      }

      this.titleStyle = {}
      await c.throttle(() => {}, { delay: 500 })
      await this.$nextTick()
    },
    shouldShowRef(r) {
      if (this.editable) return true

      const item = this.norm[r]

      if (item.assembly_emphasis && item.assembly_emphasis < -2) return false
      if (item.cost_type_emphasis && item.cost_type_emphasis < 0) return false
      if (item.oMeta?.itemType === 'task') return false

      return true
    },
    keepOrRemove(ref, incl) {
      const type = this.norm[ref].type

      if (incl === c.toNum(this.norm[ref][`${type}_is_included`], 0)) {
        return
      }

      this.$store.dispatch(`${this.store}/field`, {
        changes: {
          [`${type}_is_included`]: incl,
          [`${type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base`]: incl ? 1 : 0,
          [`${type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net`]: incl ? 1 : 0
        },
        refId: ref,
        explicit: true
      })
    },
    async onResize() {
      await this.clearOffsets(this)
      return this.setOffsets(this)
    },
    handleAccordionToggle(subRef, newIndex) {
      const currentIndices = this.activeIndices[subRef] || []

      if (currentIndices.includes(newIndex)) {
        // If the index is already active, remove it
        this.activeIndices[subRef] = currentIndices.filter((index) => index !== newIndex)
      } else {
        // Otherwise, add it
        this.activeIndices[subRef] = [...currentIndices, newIndex]
      }
    },
    setInteractiveItems(items) {
      this.$emit('update-interactive-items', items)
    },
    setAllItems(items) {
      this.$emit('update-all-items', items)
    },
    disableChildren(refId) {
      const object = this.norm[refId]
      if (object.aoChildren && object.aoChildren.length > 0) {
        object.aoChildren.forEach((childRefId) => {
          // Add child to disabledList
          if (!this.disabledList.includes(childRefId)) {
            this.disabledList.push(childRefId)
            // Recursively disable further children
            this.disableChildren(childRefId)
          }
        })
      }
      this.$emit('disabled-list', this.disabledList)
    },

    enableChildren(refId) {
      const object = this.norm[refId]
      if (object.aoChildren && object.aoChildren.length > 0) {
        // Filter out children from disabledList
        this.disabledList = this.disabledList.filter((id) => !object.aoChildren.includes(id))
        // Recursively enable further children
        object.aoChildren.forEach((childRefId) => {
          this.enableChildren(childRefId)
        })
      }
      this.$emit('disabled-list', this.disabledList)
    }
  },
  components: {
    RecursiveSelectionsItem
    /* ChangeAudit, */
  },
  props: {
    // Array of refIds
    refId: {
      required: true
    },
    deselectOnDestroy: {
      default: false
    },
    store: {
      type: String,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    interactiveItems: {
      type: Array
    },
    /**
     * See QuotePresenetationAssembly
     */
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    showGeneralPrice: {
      default: true
    },
    isInQuoteEditor: {
      type: Boolean
    },
    setDisableList: {
      type: Function
    }
  },
  mounted() {
    if (this.$refs.sectionTitle && this.$refs.sectionTitle.length) {
      this.setOffsets(this)
    }

    eventBus.$on('resize', this.onResize)
  },
  beforeUnmount() {
    this.clearOffsets(this)
    eventBus.$off('resize', this.onResize)
    eventBus.$off('add-to-disabled-list')
    eventBus.$off('remove-from-disabled-list')
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-pres--sandbox {
  padding-bottom: 0;
}

.configure-section--assembly-title {
  padding: 0.5em 0 1em 0;
  letter-spacing: 0.05em;
  @media (max-width: 576px) {
    margin-left: -15vw !important;
  }
}

.quote-pres--sandbox .quote-pres-items--list-wrapper {
  display: flex !important;
  flex-direction: row !important;
}

.quote-pres--sandbox .quote-pres-items--list-container {
  align-items: flex-start !important;
  height: auto !important;
}

.quote-pres-selections--container,
.quote-pres-selections--container.quote-pres--content-item {
  display: flex;
  align-items: center !important;
  flex-direction: row;

  section.configure-section {
    margin-bottom: 0 !important;
    padding: 0 !important;

    &:last-child {
      padding: 0 !important;
    }

    .configure-section-title {
      height: 75px;
      position: sticky;
      top: 0;
      z-index: 9;
      padding: 0.75em 0.75em 0.75em 0.75em;
      width: 100%;
      transition: all 0.25s ease-in-out;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter: blur(1rem);
        background-color: rgba($cool-gray-200, 0.5);
        border-top: 1px solid $cool-gray-200;
        width: 150vw;
        margin-left: -50vw;
      }

      > * {
        z-index: 2;
        position: relative;
      }

      h5 {
        margin-bottom: 0 !important;
        margin-top: 0.2em;
        padding: 0 !important;
      }

      @media (max-width: 576px) {
        & {
          padding: 0.35em;

          &::before {
            background-color: rgba($cool-gray-200, 0.8);
          }

          margin-left: -40px !important;
        }
        .item-location {
          font-size: 0.75em;
          margin-left: -15vw !important;
        }
        h5 {
          font-size: 0.9em;
        }
      }

      &.current {
        .desc {
          display: none;
        }
      }

      .item-location {
        color: $cool-gray-700 !important;
        font-weight: 100 !important;

        .item-location--highlighted {
          color: $cool-gray-700 !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

$br: 10px * 2;
.optional-toggle {
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    min-height: 3em;
    padding: 1.5em 1em;
    border: 1px solid $cool-gray-500;
    flex: 0 50%;
    cursor: pointer;
    text-align: center;
    min-width: 100%;

    &:first-child {
      border-top-left-radius: $br;
      border-bottom-left-radius: $br;
    }
    &:last-child {
      border-top-right-radius: $br;
      border-bottom-right-radius: $br;
    }

    .toggle-title {
      font-size: 1em;
      font-weight: 500;
      text-align: left;
      flex: 1 60%;
    }
    .toggle-desc {
      text-align: center;
      flex: 1 30%;
    }
    .toggle-price {
      font-size: 0.8em;
      font-weight: 100;
      text-align: right;
    }

    &:hover {
      border-color: $cool-gray-700;
      cursor: pointer;
    }

    &.active {
      border-width: 1px;
      border-color: $blue-print-700;
      pointer-events: none;
    }

    &.see-available {
      border-width: 1px;
      border-color: $blue-print-700;

      &:hover {
        box-shadow: 0 0 2px;
      }
    }
  }

  + .optional-toggle {
    margin-top: 1em;
  }
}

#app.small-format {
  .selection-list-item {
    padding: 1em 0.5em;
  }
  .quote-pres-selections--container,
  .quote-pres-selections--container.quote-pres--content-item {
    section.configure-section {
      padding: 0 0 3em 0 !important;

      &:last-child {
        padding: 0 0 3em 0 !important;
      }

      .configure-section-title {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        @media (max-width: 767px) {
          margin-left: 1em !important;
        }
        @media (min-width: 1025px) {
          margin-left: 0;
        }
      }
    }

    .selection-list-item.card-list-item-field .card-list-field {
      flex-direction: column;
      > * {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      > *:first-child {
        max-width: 100%;
      }
    }
  }
}
</style>
