import { ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export const useActivityChannels = () => {
  const store = useStore()
  const toastService = useToast()

  // ======== Data =========== //
  const initQuoteChannelLoading = ref(true)
  const initInvoiceChannelLoading = ref(true)
  // ====================== //

  // ======== Methods ======== //
  const getChannel = (type, typeId) => {
    return store.dispatch('activityChat/getChannelByType', {
      channelType: type,
      channelTypeId: typeId
    })
  }
  const createChannel = (
    type,
    typeId,
    channelName = '',
    clientUserId = null,
    clientFname = null,
    clientLname = null,
    channelStatus = null
  ) => {
    const companyId = store.state.session.company.company_id
    return store.dispatch('activityChat/postChannel', {
      companyId,
      channelType: type,
      channelTypeId: typeId,
      channelName: channelName || `${type}: ${typeId}`,
      clientUserId,
      clientFname,
      clientLname,
      channelStatus
    })
  }
  const addChannelUser = (channelId, userId, userFName, userLName, channelType) => {
    return store.dispatch('activityChat/postChannelMember', {
      channelId,
      userId,
      userFName,
      userLName,
      channelType
    })
  }
  const getChannelUser = (channelId, userId) => {
    return store.dispatch('activityChat/getChannelMember', {
      channelId,
      userId
    })
  }
  const getInvoiceChannel = (invoiceId) => {
    return getChannel('INVOICE', invoiceId)
  }
  const getQuoteChannel = (quoteId) => {
    return getChannel('QUOTE', quoteId)
  }
  const getCompanyChannel = (companyId) => {
    return getChannel('COMPANY', companyId)
  }
  const getCompanyGeneralChannel = (companyId) => {
    return getChannel('COMPANY_GENERAL', companyId)
  }
  const createInvoiceChannel = (invoiceId, invoiceName) => {
    return createChannel('INVOICE', invoiceId, invoiceName)
  }
  const createQuoteChannel = (quoteId, quoteName) => {
    return createChannel('QUOTE', quoteId, quoteName)
  }
  const createCompanyChannel = (companyId) => {
    return createChannel('COMPANY', companyId, 'Activities')
  }
  const createCompanyGeneralChannel = (companyId) => {
    return createChannel('COMPANY_GENERAL', companyId, 'General')
  }
  const getNotifications = (userId, limit, nextToken) => {
    return store.dispatch('activityChat/getNotificationsByUser', {
      userId,
      limit,
      nextToken
    })
  }
  const getAllNotifications = (userId) => {
    return store.dispatch('activityChat/getAllNotificationsByUser', {
      userId
    })
  }
  const getChannels = (userId, limit, nextToken) => {
    return store.dispatch('activityChat/getChannelsByUser', {
      userId,
      limit,
      nextToken
    })
  }
  const getAllChannels = (userId) => {
    return store.dispatch('activityChat/getAllChannelsByUser', {
      userId
    })
  }
  const getCompanyInitChannels = (companyId) => {
    return store.dispatch('activityChat/getInitChannelsByCompany', {
      companyId
    })
  }
  const getInitChannels = (userId) => {
    return store.dispatch('activityChat/getInitChannelsByUser', {
      userId
    })
  }
  const getFormattedChannelName = (channel) => {
    if (!channel) return null
    return channel.channel_type !== 'CHAT' || channel.channel_members.items.length > 2
      ? channel.channel_name
      : channel.channel_members.items.reduce((acc, member) => {
          if (
            member.user_id !==
            (store.state.session.authorizedUser?.user_id || store.state.session.user.user_id)
          ) {
            acc = `${member.user_fname} ${member.user_lname}`
          }
          return acc
        }, '')
  }
  const getTotalChannelNotificationsLength = (channelId) => {
    const allChannelIds = [
      channelId,
      ...Object.values(store.state.activityChat.channels || {})
        .filter((channel) => channel.parent_channel_id === channelId)
        .map((channel) => channel.channel_id)
    ]

    return allChannelIds.reduce(
      (acc, id) => acc + (store.state.activityChat.channelNotifications[id]?.length || 0),
      0
    )
  }
  const subscribeToNewChannels = (userId, toastService) => {
    return store.dispatch('activityChat/subscribeToNewChannels', {
      userId,
      toastService
    })
  }
  const unsubscribeToNewChannels = () => {
    return store.dispatch('activityChat/unsubscribeToNewChannels')
  }
  const subscribeToNotifications = (userId, toastService) => {
    return store.dispatch('activityChat/subscribeToNotifications', {
      userId,
      toastService
    })
  }
  const unsubscribeToNotifications = () => {
    return store.dispatch('activityChat/unsubscribeToNotifications')
  }
  const initInvoiceChannel = async (userId, userFName, userLName, invoiceId, invoiceName) => {
    // get quote channel //
    let invoiceChannel = await getInvoiceChannel(invoiceId.toString())
    if (!invoiceChannel) {
      invoiceChannel = await createInvoiceChannel(invoiceId.toString(), invoiceName)
    }

    initInvoiceChannelLoading.value = false
  }
  const initQuoteChannel = async (
    userId,
    userFName,
    userLName,
    quoteId,
    quoteName,
    clientUserId,
    clientUserFName,
    clientUserLName,
    quoteStatus
  ) => {
    if (!quoteId) return

    // get quote channel //
    let quoteChannel = await getQuoteChannel(quoteId.toString())
    if (!quoteChannel) {
      quoteChannel = await createQuoteChannel(
        quoteId.toString(),
        quoteName,
        clientUserId,
        clientUserFName,
        clientUserLName,
        quoteStatus
      )
    }
    initQuoteChannelLoading.value = false
  }
  const initCompanyChannel = async (companyId, companyName) => {
    // get company channel //
    return Promise.all([
      (async () => {
        let companyChannel = await getCompanyChannel(companyId.toString())
        if (!companyChannel) {
          companyChannel = await createCompanyChannel(companyId.toString(), companyName)
        }
      })(),
      (async () => {
        let companyGeneralChannel = await getCompanyGeneralChannel(companyId.toString())
        if (!companyGeneralChannel) {
          companyGeneralChannel = await createCompanyGeneralChannel(
            companyId.toString(),
            companyName
          )
        }
      })(),
      getCompanyInitChannels(companyId)
    ])
  }
  const initActivityNotifications = async (userId) => {
    await Promise.all([getAllNotifications(userId), getInitChannels(userId)])

    await subscribeToNotifications(userId, toastService)
    await subscribeToNewChannels(userId, toastService)
  }
  const getStatusCodeName = (statusCode) => {
    switch (statusCode) {
      case 'p':
        return 'Pending'
      case 'k':
        return 'Booked'
      case 'f':
        return 'In-progress'
      case 'g':
        return 'Closed'
      default:
        return null
    }
  }
  // ====================== //

  return {
    initQuoteChannelLoading,
    initInvoiceChannelLoading,
    addChannelUser,
    createChannel,
    createCompanyChannel,
    createInvoiceChannel,
    createQuoteChannel,
    getAllChannels,
    getAllNotifications,
    getChannel,
    getChannels,
    getChannelUser,
    getCompanyChannel,
    getInitChannels,
    getCompanyInitChannels,
    getInvoiceChannel,
    getNotifications,
    getQuoteChannel,
    getFormattedChannelName,
    initActivityNotifications,
    initCompanyChannel,
    initInvoiceChannel,
    initQuoteChannel,
    unsubscribeToNewChannels,
    unsubscribeToNotifications,
    getTotalChannelNotificationsLength,
    getStatusCodeName
  }
}
