<template>
  <div>
    <template v-if="!loading">
      <card-section>
        <card-list>
          <card-list-field>
            <span>
              Estimate name
              <br />
              <small> Optionally choose a name for this estimate. </small>
            </span>
            <field schema="quote:quote_name" :validate="{ required: false }" v-model="name" />
          </card-list-field>
          <card-list-field>
            <span>
              Client
              <br />
              <small> Select the client that you are building this estimate for. </small>
            </span>
            <choose
              :return-array="false"
              schema="quote:client_id"
              :order="[
                ['client_count_quotes', 'asc'],
                ['client_time_last_modified', 'desc']
              ]"
              :validate="{
                required: true
              }"
              v-model="id"
            />
          </card-list-field>
        </card-list>
      </card-section>
    </template>

    <div class="flex justify-center items-center" v-else>
      <spinner size="4em" :loading="1" style="margin: 0 auto" />
    </div>

    <div class="flex justify-end mt-5">
      <btn-group style="margin-left: auto">
        <btn size="lg" :action="saveAndGo" :disabled="!id" severity="primary-black"> Create </btn>
      </btn-group>
    </div>
  </div>
</template>

<script>
import BodyMixin from '../mixins/Body'
import eventBus from '../../eventBus'

export default {
  mixins: [BodyMixin],

  data() {
    return {
      name: '',
      id: null,
      loading: 0,
      rfq: 0
    }
  },

  watch: {},

  methods: {
    async setRfq(rfq) {
      this.rfq = rfq

      await this.$store.dispatch('Quote/addDefaults', {
        object: { quote_is_rfq: rfq },
        defaults: ['quote_is_rfq']
      })
    },
    async saveAndGo() {
      this.loading = 1
      return this.$store.dispatch('Client/quote', {
        id: this.id,
        embue: {
          quote_name: this.name,
          quote_is_rfq: this.rfq,
          live_price_zipcode: this.$store.state.session.company.default_live_price_zipcode
        }
      })
    }
  },

  async beforeCreate() {
    const { object } = await this.$store.dispatch('Quote/buildDefaultObject')
    this.rfq = object.quote_is_rfq
    eventBus.$on(`${this.uid}-embued`, () => {
      this.$nextTick(() => {
        if (this.client_id) {
          this.id = this.client_id
        }
      })
    })
  },

  async mounted() {
    this.id = this.clientId
  },

  beforeUnmount() {
    eventBus.$off(`${this.uid}-embued`)
  },

  props: {
    deselectOnDestroy: {
      default: true
    },
    forceFetch: {
      default: false
    },
    forceFull: {
      default: true
    },
    go: {
      default: false
    },
    autoSaveEnabled: {
      default: false
    },
    clientId: {
      default: null
    }
  }
}
</script>
