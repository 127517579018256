<script setup>
import { useStore } from 'vuex'
import SidebarPrime from 'primevue/sidebar'
import { computed, ref, watch, watchEffect } from 'vue'
import ActivityChannelBody from '@/components/Activities/ActivityChannelBody.vue'

// ======== Composables ======== //
const $store = useStore()
// ====================== //

// ======== Data ======== //
const post = ref(false)
// ====================== //

// ======== Computed ======== //
const type = computed(() => $store.state.session.preview.type)
const id = computed(() => $store.state.session.preview.id)
const formattedType = computed(() => _.ucfirst(type.value.replace('_', ' ')))
const hasEntityType = computed(() => type.value && id.value)

const activityChannelType = computed(() => $store.state.session.preview.channel_type)
const activityChannelTypeId = computed(() => $store.state.session.preview.channel_type_id)
const hasActivityChannel = computed(() => activityChannelType.value && activityChannelTypeId.value)

const visible = computed({
  get: () => hasEntityType.value || hasActivityChannel.value,
  set: (value) =>
    !value
      ? $store.commit({
          type: 'SET_PREVIEW',
          preview: {}
        })
      : null
})
// ====================== //

// ======== Methods ======== //
const edit = () => {
  let idToUse = id.value
  if (type.value === 'user') {
    idToUse = String(idToUse).replace(/[^0-9]/g, '')
  }

  $store.dispatch('edit', { type: type.value, id: idToUse })
  visible.value = false
}
// ====================== //

// ======== Watchers ======== //
watch(id, (a, b) => {
  if (a !== b) post.value = false
})
// ====================== //

const object = ref(null)
const name = ref('...')
watchEffect(async () => {
  if (!type.value || (!id.value && !hasActivityChannel.value)) {
    object.value = null
    name.value = '...'
    return
  }

  if (type.value && id.value) {
    const { object: fetched } = await $store.dispatch(`${_.titleCase(type.value)}/fetch`, {
      id: id.value
    })
    object.value = fetched
    name.value = object.value[`${type.value}_name`]
  }
})

const zIndex = computed(() => $store?.state?.modal?.topZIndex || 2000)
</script>

<template>
  <SidebarPrime
    v-model:visible="visible"
    position="right"
    :autoZIndex="false"
    :pt="{
      mask: `!z-[${zIndex}]`
    }"
    :ptOptions="{ mergeProps: true }"
    v-if="object || hasActivityChannel"
    :key="`${type}:${id}`"
  >
    <div class="h-full w-full flex flex-col overflow-hidden">
      <section v-if="object" class="sidebar-entity flex items-center mb-8">
        <PersonAvatar :id="id" :type="type" :name="name" size="xlarge" shape="square" :key="name" />
        <div class="ml-2">
          {{ name }}
          <Btn outline severity="tertiary" size="xs" :action="edit" class="mb-1">Edit</Btn>
          <p class="text-xs">{{ formattedType }}</p>
        </div>
      </section>
      <section
        v-if="hasActivityChannel"
        class="sidebar-activity flex flex-col overflow-hidden h-full"
      >
        <ActivityChannelBody
          class="flex-1 max-w-xl"
          :channelType="activityChannelType"
          :channelTypeId="activityChannelTypeId"
        />
      </section>
    </div>
  </SidebarPrime>
</template>
