<script setup>
import { defineProps, computed, ref } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import _ from '../../../../../imports/api/Helpers.js'
import Utilities from '../../../../../imports/api/Changes/Utilities.js'
import VersionControl from '@/VersionControl.js'
import Objects from '../../../../../imports/api/Objects.js'

const props = defineProps(['refId', 'store', 'type'])
const computedFields = EntityComputedFields.useEntityComputedFields({
  store: props.store,
  refId: props.refId,
  type: props.type
})
const {
  cost_type_id,
  cost_type_hash: savedHash,
  cost_type_current_hash: currentHash,
  object
} = computedFields

const same = computed(() => savedHash?.value === currentHash?.value)

const $store = useStore()

const savedVersion = ref(null)

const getSavedVersion = async () => {
  if (!savedVersion.value) {
    let { object: saved } = await $store.dispatch('CostType/fetch', {
      id: cost_type_id.value,
      quick: true,
      force: true
    })
    savedVersion.value = saved
  }

  return savedVersion.value
}

const getFormatType = (field) => c.getFieldFormatType(props.type, field)

const generateChanges = async () => {
  const fields = await VersionControl.getVersionCheckFields('cost_type')

  const saved = _.pick(await getSavedVersion(), fields)
  const current = _.pick({ ...object.value }, fields)

  saved.refId = props.refId
  current.refId = props.refId

  saved.type = 'cost_type'
  current.type = 'cost_type'

  const normChanges = Utilities.diffNormalized({ [props.refId]: saved }, { [props.refId]: current })

  let fc = normChanges[props.refId]?.fieldChanges ?? {}
  fc = Object.keys(fc).reduce((acc, field) => {
    const ft = getFormatType(field)
    const from = ft ? c.format(fc[field].from, ft) : fc[field].from
    const to = ft ? c.format(fc[field].to, ft) : fc[field].to
    const fieldTitle = Objects.getFieldTitle(field, 'cost_type')
    return {
      ...acc,
      [fieldTitle]: {
        ...fc[field],
        to,
        from
      }
    }
  }, {})
  changes.value = fc

  showChanges.value = true
}

const importSaved = async () => {
  const fields = await VersionControl.getVersionCheckFields('cost_type')
  const saved = await getSavedVersion()
  const pruned = _.pick(saved, fields)

  $store.dispatch(`${props.store}/field`, {
    changes: { [props.refId]: pruned },
    explicit: true
  })
  showChanges.value = false
}
const saveChanges = (asNew) => {
  const action =
    props.type === 'cost_item' ? 'CostType/saveFromCostItem' : 'Assembly/saveFromAssembly'
  return $store.dispatch(action, {
    refId: props.refId,
    store: props.store,
    asSuper: false,
    asNew,
    quiet: true
  })
}

const changes = ref({})
const showChanges = ref(false)

const compare = async () => {
  console.log(props, object.value)
  return VersionControl.compareToSaved({ ...object.value, type: 'cost_type' })
  // await $store.dispatch('CostType/getVersionHash', {
  //   object: object.value,
  //   coerceType: 'cost_type'
  // })
}
</script>

<template>
  <div class="text-sm leading-snug flex flex-col gap-4">
    <Btn :action="compare">Check version</Btn>
    <template v-if="same && cost_type_id">
      <div class="text-blue-print flex items-center gap-1 font-medium">
        <Icon icon="svg:catalogSaved" /> This item is saved to your catalog
      </div>
    </template>
    <template v-else-if="!same && cost_type_id">
      <div class="flex flex-col gap-6">
        <div class="text-black inline font-medium">
          <Icon icon="svg:catalogSave" class="inline" />
          This item differs from the saved version.
          <div v-if="!showChanges">
            <Btn
              unstyled
              :action="() => generateChanges()"
              class="!p-0 underline text-blue-print cursor-pointer user-select-none"
              >Show differences?</Btn
            >
          </div>
        </div>

        <div v-if="showChanges" class="overflow-x-scroll">
          <table class="w-full">
            <tr>
              <th class="whitespace-nowrap">Field</th>
              <th class="whitespace-nowrap">Estimate</th>
              <th class="whitespace-nowrap">Saved</th>
            </tr>

            <tr v-for="(log, field) in changes" v-tooltip="log.desc" :key="field">
              <td class="border border-surface-300 text-center p-1 font-medium">{{ field }}</td>
              <td class="border border-surface-300 text-center p-1">{{ log.to }}</td>
              <td class="border border-surface-300 text-center p-1">{{ log.from }}</td>
            </tr>
          </table>
        </div>

        <div class="flex gap-1">
          <Btn severity="tertiary" size="xs" :action="() => saveChanges(false)">
            <Icon icon="svg:catalogSave" />
            Save changes to catalog
          </Btn>
          <Btn severity="tertiary" size="xs" :action="() => importSaved()">
            <font-awesome-icon icon="arrow-down-left" />
            Load saved version
          </Btn>
        </div>
      </div>
    </template>
    <template v-else-if="!cost_type_id && props.type === 'cost_item'">
      <div class="flex flex-col gap-6">
        <div class="text-black inline">
          <Icon icon="svg:catalogAdd" class="inline" />
          This item hasn't been added to your catalog yet.
        </div>
        <div class="flex gap-1">
          <Btn severity="tertiary" size="xs">
            <Icon icon="svg:catalogAdd" />
            Save to your catalog?
          </Btn>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
