<script setup>
import { computed } from 'vue'
import FocusBar from '../layout/header/FocusBar.vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import StringField from '@/components/ui/Calculator/StringField.vue'
import QuoteSave from '@/components/headers/QuoteSave.vue'
import { useRouter } from 'vue-router'
import { useMediaQuery } from '@/composables/mediaQuery'

const $router = useRouter()
const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const {
  quote_name: qname,
  client,
  client_id,
  quote_id
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})

const { smallFormat } = useMediaQuery()

const isPublicQuote = computed(() => {
  return $router.currentRoute.value.name === 'Presentation External'
})
</script>

<template>
  <FocusBar :hideSaveButtons="isPublicQuote" v-if="!(isPublicQuote && !smallFormat)">
    <template #middle>
      <div
        v-if="!isPublicQuote && !smallFormat"
        class="flex justify-center items-center gap-x-2 max-w-full w-full"
      >
        <PersonAvatar
          v-if="client"
          :key="client.user_fname"
          :id="client_id"
          type="client"
          channelType="QUOTE_CLIENT"
          :channelTypeId="`${quote_id}-${client.client_user_id}`"
          :name="`${client.user_fname || ''} ${client.user_lname || ''}`"
        />
        <font-awesome-icon icon="file-signature" />
        <StringField v-model="qname" placeholder="Name this estimate" class="truncate" />
      </div>
      <div v-else-if="isPublicQuote && smallFormat">In person approval</div>
    </template>

    <template #saveStatus>
      <QuoteSave />
    </template>
  </FocusBar>
</template>

<style scoped lang="scss"></style>
