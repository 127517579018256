import BodyMixin from '@/components/mixins/Body'
import DefaultColors from '@/theme/presentations/colors.scss?raw'
import Milestones from '../../mixins/Milestones'
import TranslationMixin from './languages/TranslationMixin'
import ThemeDefaultVariables from '@/theme/presentations/variables/quote.scss?raw'
import UserError from '../../../../imports/api/UserError'
import eventBus from '../../../eventBus'

const templateVariablesToSave = [
  '$headcover-background-blend-mode',
  '$main-primary-color',
  '$main-secondary-color',
  '$background-url',
  '$heading-main-heading-font-color',
  '$heading-subheading-font-color',
  '$heading-text-font-color'
]
export default {
  mixins: [BodyMixin, Milestones, TranslationMixin],
  data() {
    return {
      slidesEnabled: 0,
      hideMessage: 0,
      styleEverLoaded: 0,
      screenIndex: 0,
      theme: false,
      saveCount: 0,
      editingLocal: this.editing,
      picFile: [],
      moving: false,
      styleError: 0,
      styleErrors: {},
      advancedStyle: 0,
      originalSettings: {},
      showAddonTop: 1,
      showAddonBottom: 1,
      viewingFile: false,
      viewingFileSrc: null,
      showToolbar: true,
      viewPdf: false,
      enableTemplateSave: false,
      unsavedChanges: 0,
      showRequestRevision: true,
      templateFull: null
    }
  },
  watch: {
    editing(b) {
      this.editingLocal = b
      this.$emit('editing', b)
    },
    async viewingFile(file, oldFile) {
      if (file && file.file_id && file.file_id !== oldFile.file_id) {
        const fileType = await this.$store.dispatch('File/getFileType', {
          id: file.file_id
        })
        const { link } = await this.$store.dispatch('link', {
          path: `file/view/${file.file_id}`,
          query: c.isImageFileType(fileType)
            ? {
                max: 1000
              }
            : {}
        })
        this.viewingFile = {
          ...this.viewingFile,
          file_type: fileType
        }
        this.viewingFileSrc = link
        return true
      }

      if (!file || !file.file_id) this.viewingFileSrc = null

      return false
    }
  },
  computed: {
    presentationSettings: {
      get() {
        return { ...this.oPresentationSettings, ...this.presentationSettingsOverrides }
      },
      set(opres) {
        this.oPresentationSettings = opres
      }
    },
    coverLetterProcessed() {
      // Remove any html from the text to prevent XSS
      const sanitized = $('<div>')
        .text(this.coverLetter || '')
        .html()
      return c.linkify(
        c.embedYouTubeLink(
          sanitized
            .replace(
              '{quote_type}',
              this.quote_is_change_order ? this.l('change-order') : this.l('proposal')
            )
            .replace('{client_name}', this.oClient.client_name)
            .replace('{quote_name}', this.quote_name)
            .replace('{quote_address}', this.quote_address)
        )
      )
    },
    combinedFiles() {
      return [
        ...c.makeArray(this.plan_file_ids),
        ...c.makeArray(this.file_ids),
        ...c.makeArray(this.alwaysIncludedFileIds)
      ]
    },
    ...templateVariablesToSave.reduce((acc, key) => {
      const camelKey = _.camelCase(key.replace(/-/g, '_').replace('$', ''))
      return {
        ...acc,
        [camelKey]: {
          get() {
            const val = this.resolveStyleVariable(key, this.themeVariables)
            if (val === 'null') return null
            return val
          },
          set(val) {
            this.changeStyleVariable(key, val)
          }
        }
      }
    }, {}),
    headingTitleStyle() {
      return {
        color: `${this.headingMainHeadingFontColor} !important`
      }
    },
    headingSubHeadingStyle() {
      return {
        color: `${this.headingSubheadingFontColor} !important`
      }
    },
    headingTextStyle() {
      return {
        color: `${this.headingTextFontColor} !important`
      }
    },
    touch() {
      return matchMedia('(hover: none)').matches
    },
    notes() {
      return c.removeHtml(this.quote_notes)
    },
    documentName() {
      return this.quote_is_change_order ? this.l('change-order') : this.l('proposal')
    },
    fileIdFiles() {
      return this.file_ids.map((id) => c.buildDefaultObject('file', { file_id: id }))
    },
    pdfLink() {
      return c.link(`quote/view/${this.quote_id}`, {}, true, _.getStorage('scope'))
    },
    mergedSettings() {
      return {
        ...this.presentationSettings,
        quote_show_itemized_prices: this.quote_show_itemized_prices,
        quote_show_expanded_assemblies: this.quote_show_expanded_assemblies,
        quote_show_costs: this.quote_show_costs,
        quote_show_assembly_prices: this.quote_show_assembly_prices,
        quote_show_cost_item_prices: this.quote_show_cost_item_prices,
        quote_show_quantities: this.quote_show_quantities
      }
    },
    screensToShow() {
      // get slide summaries
      let screenPreviews = []
      const original = this.presentationSettings.screens
      const upperLimitIndex = this.screenIndex + 2
      const lowerLimitIndex = this.screenIndex - 2

      // Get all screens that are visible +-2 from the current index
      // then later if the 2 spots on either side of the current screen
      // does not have a screen, we will pad with an 'add screen' view
      // implant actual index
      const visScreens = this.presentationSettings.screens
        .map((s, index) => ({ ...s, index }))
        .slice(Math.max(lowerLimitIndex, 0), upperLimitIndex + 1)

      screenPreviews = visScreens.map((screen) => {
        const after = screen.index > this.screenIndex && this.screenIndex !== screen.index

        let title
        let subtitle
        let contentIcons = []

        if (screen.screenType === 'main') {
          title = 'Items'
          subtitle = 'Main slide'
        } else if (after) {
          title = 'Additional slide'
          subtitle = ''
        } else {
          title = 'Additional slide'
          subtitle = ''
        }

        if (screen && screen.content.length) {
          contentIcons = _.makeArray(
            screen.content.map((content) => {
              if (content.type === 'file' && content.file_type) {
                if (/pdf/.test(content.file_type)) {
                  return 'file-pdf'
                } else if (/img|image|png|bmp|tif|jpg|jpeg|gif/.test(content.file_type)) {
                  return 'picture-o'
                } else if (/mov|mp4/.test(content.file_type)) {
                  return 'file-video-o'
                }
              }
              return 'question-circle'
            })
          )
        } else if (screen.screenType === 'main') {
          contentIcons = ['list']
        } else {
          contentIcons = ['square-plus']
        }

        const fromCurrent = Math.abs(screen.index - this.screenIndex)
        return {
          title,
          subtitle,
          contentIcons,
          class:
            screen.index === this.screenIndex
              ? 'slide-current'
              : `slide-${after ? 'plus' : 'minus'}-${fromCurrent}`,
          showAdd: false,
          before: !after,
          ref: screen.ref,
          screenType: screen.screenType,
          index: screen.index
        }
      })

      if (screenPreviews.length < 5) {
        // padd down
        if (this.screenIndex < 2) {
          const fromCurrent = this.screenIndex === 0 ? 1 : 2
          screenPreviews = [
            {
              title: false,
              subtitle: false,
              contentIcons: [],
              class: `slide-minus-${fromCurrent}`,
              showAdd: true,
              before: true,
              ref: 'before',
              screenType: 'add',
              screenIndex: 0
            },
            ...screenPreviews
          ]
        }

        // padd up
        if (screenPreviews.length - this.screenIndex <= 2) {
          const fromCurrent = Math.min(2, original.length - (this.screenIndex + 1) + 1)
          // still not full
          screenPreviews = [
            ...screenPreviews,
            {
              title: false,
              subtitle: false,
              contentIcons: [],
              class: `slide-plus-${fromCurrent}`,
              showAdd: true,
              before: false,
              ref: 'after',
              screenType: 'add',
              screenIndex: this.presentationSettings.screens.length
            }
          ]
        }
      }
      return screenPreviews
    },
    toolbarHeight() {
      return this.showToolbar ? 5 : 1
    },
    logo() {
      return c.link(`file/view/${this.currentScreen.logoFileId}`, {}, true, _.getStorage('scope'))
    },
    presentationTemplateId: {
      get() {
        return this.presentation_template_id
      },
      set(id) {
        this.loadTemplate(id)
      }
    },
    currentScreen() {
      return this.presentationSettings.screens[this.screenIndex]
    },
    styleVariables() {
      return `${this.presentationSettings.styleVariables || ''}${this.currentScreen.styleVariables || ''}`
    },
    themeVariables() {
      const colors = this.parseStyleVariables(DefaultColors)
      const defaults = this.parseStyleVariables(ThemeDefaultVariables)
      const explicitlySet = this.parseStyleVariables(this.styleVariables)
      return {
        ...colors,
        ...defaults,
        ...explicitlySet
      }
    },
    parsedStyleVariables() {
      const parsedCustom = this.themeVariables
      const parsedDefault = this.parseStyleVariables(ThemeDefaultVariables)
      const combined = {
        ...parsedDefault,
        ...parsedCustom
      }
      const groups = {}
      Object.keys(combined).forEach((k) => {
        const groupName = k.split('-')[0].replace('$', '')
        groups[groupName] = {
          ...(groups[groupName] || {}),
          name: groupName,
          styles: {
            ...(groups[groupName] && groups[groupName].styles ? groups[groupName].styles : {}),
            [k]: combined[k]
          }
        }
      })
      return groups
    },
    userPic() {
      const fileId = this.oOwner.profile_file_id
      return c.link(`file/pic/${fileId}`, {}, true, _.getStorage('scope'))
    },
    screensShowHeading: {
      get() {
        return this.presentationSettings.screens.every((screen) => screen.showHeading) ? 1 : 0
      },
      set(v) {
        this.modifyScreen({ showHeading: v }, true)
      }
    },
    screensShowPresenter: {
      get() {
        return this.presentationSettings.screens.every((screen) => screen.showPresenter) ? 1 : 0
      },
      set(v) {
        this.modifyScreen({ showPresenter: v }, true)
      }
    },
    screensShowContact: {
      get() {
        return this.presentationSettings.screens.every((screen) => screen.showContact) ? 1 : 0
      },
      set(v) {
        this.modifyScreen({ showContact: v }, true)
      }
    },
    screensShowLogo: {
      get() {
        return this.presentationSettings.screens.every((screen) => screen.showLogo) ? 1 : 0
      },
      set(v) {
        this.modifyScreen({ showLogo: v }, true)
      }
    },
    screensDecline: {
      get() {
        return this.presentationSettings.screens.every((screen) => screen.showDecline) ? 1 : 0
      },
      set(v) {
        this.modifyScreen({ showDecline: v }, true)
      }
    },
    screensShowProjectDetails: {
      get() {
        return this.presentationSettings.screens.every((screen) => screen.showProjectDetails)
          ? 1
          : 0
      },
      set(v) {
        this.modifyScreen({ showProjectDetails: v }, true)
      }
    },
    bookingMessage: {
      get() {
        return this.presentationSettings.bookingMessage
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          bookingMessage: v
        }
      }
    },

    showCosts: {
      get() {
        return this.presentationSettings.showCosts
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showCosts: v
        }
      }
    },

    showBundledProfit: {
      get() {
        return this.presentationSettings.showBundledProfit
      },
      set(v = null) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showBundledProfit: v || null
        }
      }
    },

    showQuantities: {
      get() {
        return this.presentationSettings.showQuantities
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showQuantities: v
        }
      }
    },

    showAssemblyPrices: {
      get() {
        return this.presentationSettings.showAssemblyPrices
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showAssemblyPrices: v
        }
      }
    },

    showCostItemPrices: {
      get() {
        return this.presentationSettings.showCostItemPrices
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showCostItemPrices: v
        }
      }
    },

    showItemizedPrices: {
      get() {
        return this.presentationSettings.showItemizedPrices
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showItemizedPrices: v
        }
      }
    },

    showLargeProfilePic: {
      get() {
        return this.presentationSettings.showLargeProfilePic
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showLargeProfilePic: v
        }
      }
    },
    alwaysIncludedFileIds: {
      get() {
        return this.presentationSettings.alwaysIncludedFileIds
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          alwaysIncludedFileIds: v
        }
      }
    },
    requirePlans: {
      get() {
        return this.presentationSettings.requirePlans
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          requirePlans: v
        }
      }
    },
    termsAndConditions: {
      get() {
        return this.presentationSettings.termsAndConditions
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          termsAndConditions: v
        }
      }
    },
    coverLetter: {
      get() {
        return this.presentationSettings.coverLetter || ''
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          coverLetter: v || ''
        }
      }
    },
    defaultChangeOrderMessage: {
      get() {
        return this.presentationSettings.defaultChangeOrderMessage || ''
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          defaultChangeOrderMessage: v || ''
        }
      }
    },
    props: {
      get() {
        return this.presentationSettings.props || []
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          props: c.makeArray(v) || []
        }
      }
    },
    badges: {
      get() {
        return this.presentationSettings.badges || []
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          badges: c.makeArray(v) || []
        }
      }
    },
    showSlidePreviews: {
      get() {
        return this.presentationSettings.showSlidePreviews ? 1 : 0
      },
      set(v) {
        this.presentationSettings = {
          ...this.presentationSettings,
          showSlidePreviews: v
        }
      }
    },
    defaultColors() {
      return this.parseStyleVariables(DefaultColors, false)
    },

    backgroundUrlFinal() {
      if (!this.currentScreen.headingFileId) return this.backgroundUrl

      return c.link(
        `file/view/${this.currentScreen.headingFileId}`,
        {},
        true,
        _.getStorage('scope')
      )
    },
    isEditing: {
      get() {
        return this.editingLocal
      },
      set(val) {
        this.editingLocal = val
        this.$emit('editing', val)
      }
    }
  },
  methods: {
    getTemplate() {
      return _.imm({
        ...(this.templateFull || {}),
        type: 'template',
        template_id: this.presentationTemplateId,
        oMeta: this.presentationSettings
      })
    },

    async saveTemplate(template = null, message = 'Template saved!') {
      const { object } = await this.$store.dispatch('Template/save', {
        object: template || this.getTemplate(),
        go: false,
        alert: false
      })

      this.$store.dispatch('alert', {
        message
      })

      await this.$nextTick()

      const saveField = String(object.template_id) !== String(this.presentation_template_id)

      await this.setTemplateValues(object)

      if (saveField) this.saveFields(['presentation_template_id'])

      this.unsavedChanges = 0
    },

    newPresentationTemplate() {
      return this.savePresentationTemplateNew()
    },

    async savePresentationTemplateNew() {
      const name = await this.$store.dispatch('prompt', {
        message: 'What do you want to call your new presentation?'
      })

      if (!name) return false

      const template = {
        ...this.getTemplate(),
        template_name: name,
        template_id: null,
        template_type_id: 101
      }

      return this.saveTemplate(
        template,
        'Created new presentation, and set this project to use it.'
      )
    },

    resolveStyleVariable(key, variables) {
      let value = key in variables ? variables[key] : null

      try {
        if (value && /\$[a-z0-9-]*\b/.test(value)) {
          value = value.replace(/(\$[a-z0-9-]*)\b/, (...matches) =>
            this.resolveStyleVariable(matches[1], variables)
          )
        }
      } catch (e) {
        //
      }

      return value
    },

    async loadTemplate(id) {
      if (!id || this.$store.getters.isGuestUser) return

      this.addLoading()

      const { object } = await this.$store.dispatch('Template/fetch', {
        id
      })

      await this.setTemplateValues(object)

      this.removeLoading()

      this.unsavedChanges = 0
    },

    async defaultPresentationSettings(embue) {
      const { object } = await this.$store.dispatch('Quote/buildDefaultObject', {
        embue: {
          oPresentationSettings: embue
        }
      })

      return object.oPresentationSettings
    },

    async setTemplateValues(object) {
      // default ometa
      const presentationSettings = await this.defaultPresentationSettings(object.oMeta)
      this.templateFull = {
        ...object,
        oMeta: presentationSettings
      }
      this.presentation_template_id = object.template_id
      this.presentation_template_name = object.template_name
      this.presentationSettings = presentationSettings
    },

    setTemplateStyle(template) {
      const screens = !Array.isArray(template.oMeta.screens)
        ? Object.values(template.oMeta.screens)
        : template.oMeta.screens
      const styleVariables = screens[0].styleVariables
      this.presentationSettings = {
        ...this.presentationSettings,
        screens: this.presentationSettings.screens.map((s) => ({
          ...s,
          styleVariables
        }))
      }
      return Promise.resolve()
    },
    previewPdf() {
      this.viewPdf = true
      this.$nextTick().then(() => {
        this.$refs.pdfPreview.open()
      })
    },
    goToFirstUpgrade() {
      c.scrollTo($('.addon_list-title, .has-upgrades')[0])
    },
    /**
     * Only designe dto be called by end user
     * @returns {Promise<any>}
     */
    decline() {
      // this.mustBeClient();
      return this.$store.dispatch('ChangeOrder/decline', {
        id: this.change_order_id,
        message: this.l(
          "Sorry I couldn't help you further! Please help me do a better job next time by letting us know why you declined:"
        )
      })
    },
    /**
     * Only designe dto be called by end user
     * @returns {Promise<any>}
     */
    requestRevision() {
      // this.mustBeClient();
      return this.$store.dispatch('ChangeOrder/requestRevision', {
        id: this.change_order_id,
        message: this.l('How can we improve this proposal for you?')
      })
    },

    isUserTheClient() {
      return String(this.oClient.client_user_id) === String(this.$store.state.session.user.user_id)
    },

    mustBeClient() {
      if (!this.isUserTheClient()) {
        this.$store.dispatch('alert', {
          message: 'Only this client can approve this project',
          error: true
        })
        throw new UserError({ userMessage: 'Only the client can do that.' })
      }
    },

    async expiredAlert() {
      return this.$store.dispatch('alert', { message: 'This proposal has expired.' })
    },

    async book() {
      let terms = 'No specific terms.'
      let additionalTerms = this.presentationSettings.termsAndConditions || ''
      if (additionalTerms) {
        additionalTerms += '\r\n'
      }
      additionalTerms += this.quote_terms || ''

      const { terms: approvedTerms, signature } = await this.$store.dispatch(
        'sign/termsAndConditions',
        {
          id: this.change_order_id,
          additionalTerms,
          normalized: this.norm,
          paymentTerms: this.paymentTerms || 'pay',
          presentationSettings: this.presentationSettings,
          parentRefId: this.refId,
          language: this.l
        },
        { root: true }
      )
      terms = approvedTerms

      try {
        // this.mustBeClient();
        this.$store.dispatch('addLoading')
        this.bodyLoading = 1
        this.hideMessage = true

        await this.$store.dispatch('Quote/clearRequestQueue')

        await this.$store.dispatch('Quote/saveAndBook', {
          refId: this.refId,
          go: false,
          requireChanges: true,
          changeOrderId: this.change_order_id,
          additionalTerms: this.presentationSettings.termsAndConditions,
          termsAccepted: terms,
          getTermsApproval: false,
          language: this.l,
          signature
        })

        this.$store.dispatch(
          'alert',
          {
            message: `${this.l('Thank you for booking')}! ${this.l('Please wait while we process your approval')}.`
          },
          { root: true }
        )

        await this.$nextTick()

        const quoteId = this.$store.state.session.scope.quote
        const quoteName = this.$store.state.session.quote.quote_name
          .replace(/\s+/, '')
          .replace(/[^A-Za-z0-9-]/, '')
        this.$router.push(`/${quoteId}-${quoteName}/customer/project/progress`)
        await this.$store.dispatch('removeLoading')
        this.$store.dispatch('getBaseValues')

        return true
      } catch (err) {
        this.bodyLoading = 0
        console.log(err)
        this.$store.dispatch('alert', {
          message: this.l('There was an error booking this project. Please try again.'),
          error: 1
        })

        // const scopeRoute = await this.$store.dispatch('getScopeRouteFromScope', {
        //   scope: {
        //     company: this.$store.state.session.company.company_id,
        //     quote: this.quote_id,
        //     user: this.$store.state.session.user.user_id,
        //   },
        // });
        this.$store.dispatch('endLoading')

        // return this.$store.dispatch('to', `${scopeRoute}/project`);
        return true
      }
    },
    async seeFile(file) {
      if (!file.file_type && file.file_id) {
        const { object: fetched } = await this.$store.dispatch('File/fetch', {
          id: file.file_id
        })
        this.viewingFile = fetched
      } else if (!file.file_id) {
        this.$store.dispatch('alert', {
          error: true,
          message: 'Not able to view that picture at this time.'
        })
      } else {
        this.viewingFile = file
      }

      await this.$nextTick()

      this.$refs.filePreview.open()
    },
    modifyItem({ refId, changes, explicit = false, skipAudit = false }) {
      const payload = {
        refId,
        changes,
        skipAudit,
        explicit
      }

      return this.$store.dispatch(`${this.storeName}/field`, payload)
    },
    modifyFiles({ refId, files }) {
      return this.$store.dispatch(`${this.storeName}/replaceChildren`, {
        refId,
        field: 'aoFiles',
        children: files
      })
    },
    removeEmphasis(refId) {
      return this.$store.dispatch(`${this.storeName}/removeEmphasis`, {
        refId
      })
    },
    addEmphasis(refId) {
      return this.$store.dispatch(`${this.storeName}/addEmphasis`, {
        refId
      })
    },
    removeItem(refId) {
      return this.$store.dispatch(`${this.storeName}/removeOptionalItem`, {
        refId,
        confirm: !this.editingLocal
      })
    },

    throwStyleError(err) {
      this.styleError = 1
      this.$store.dispatch('alert', {
        message: `Error in your styles: ${err.message}`,
        error: 1
      })
    },
    applySlideSettingsToAll() {
      const {
        showHeading,
        showPresenter,
        showContact,
        showLogo,
        showBook,
        showDecline,
        showProjectDetails
      } = this.presentationSettings.screens[this.screenIndex]
      this.modifyScreen(
        {
          showHeading,
          showPresenter,
          showContact,
          showLogo,
          showBook,
          showDecline,
          showProjectDetails
        },
        true
      )
    },
    applySlideStylingToAll() {
      const styleVars = Object.keys(this.themeVariables).reduce(
        (acc, key) => `${acc}${key}:${this.themeVariables[key]};`,
        ''
      )

      this.modifyScreen(
        {
          styleVariables: styleVars
        },
        true
      )
    },
    changeStyleVariable(variableName, newValue = 'none', allScreens = false) {
      const fixedValue = newValue === '' ? 'none' : newValue

      const newStyleVariables = {
        ...this.themeVariables,
        [variableName]: fixedValue
      }

      const styleVars = Object.keys(newStyleVariables)
        .filter((key) => templateVariablesToSave.includes(key))
        .reduce((acc, key) => `${acc}${key}:${newStyleVariables[key]};`, '')

      this.modifyScreen(
        {
          styleVariables: styleVars
        },
        allScreens
      )
    },
    parseStyleVariables(string, convertDefaultColors = true) {
      let parsed = {}
      let convertColorRegex = false
      if (convertDefaultColors) {
        convertColorRegex = new RegExp(
          `(${Object.keys(this.defaultColors).reduce(
            (acc, c) => `${acc ? `${acc}|` : ''}${c.replace('$', '\\$').trim()}`,
            false
          )})`,
          'g'
        )
      }
      String(string)
        .split(/;/)
        .forEach((s) => {
          const found = s.match(/(?:(\$[a-zA-Z0-9-_]+)\s*:\s*(.*?))$/)
          let key
          let value
          if (found) {
            key = found[1]
            value = found[2]
          }
          if (key && value) {
            let parsedValue = value.replace('!default', '').trim()
            if (convertColorRegex && convertColorRegex.test(parsedValue)) {
              parsedValue = parsedValue.replace(convertColorRegex, (...matches) => {
                const subColor = matches[1]
                let actualColor
                if (/^\$/.test(this.defaultColors[subColor])) {
                  actualColor = this.defaultColors[this.defaultColors[subColor]]
                } else actualColor = this.defaultColors[subColor]
                return actualColor
              })
            }
            parsed = {
              ...parsed,
              [key.trim()]: parsedValue
            }
          }
        })

      return parsed
    },
    generateScreenRef() {
      return `screen-${_.uniqueId()}-${new Date().valueOf()}`
    },
    removeCurrentSlide() {
      const screens = this.presentationSettings.screens
      screens.splice(this.screenIndex, 1)
      this.screenIndex = 0
      this.presentationSettings = {
        ...this.presentationSettings,
        screens
      }
    },
    resetToDefault() {
      this.$store
        .dispatch('modal/quickConfirm', {
          message:
            'This will remove all your custom slides and style changes, are you sure you would like to continue?'
        })
        .then(() => {
          this.screenIndex = 0
          this.presentationSettings = {}
        })
    },
    screenExists(index) {
      return this.presentationSettings.screens.length > index
    },
    getScreenKeyByIndex(orderIndex) {
      return this.presentationSettings.screens[orderIndex]
    },
    getScreenIndexByRef(ref) {
      return this.presentationSettings.screens.find((screen) => screen.ref === ref)
    },
    getDefaultSlide() {
      return _.imm(c.getConstructor('quote').fields.oPresentationSettings.default().screens.main)
    },
    goToScreen(to) {
      // if it is more than one step away,
      // go to one screen at a time
      this.moving = true
      const from = this.screenIndex
      const start = Math.min(to, from)
      const end = Math.max(to, from)
      const each = _.range(start, end + 1)
      const ordered = to === start ? each.reverse() : each
      ordered.forEach((intermediateIndex, index) => {
        setTimeout(() => {
          this.screenIndex = intermediateIndex
          if (index === ordered.length - 1) {
            this.moving = false
            c.scrollTo($('.quote-pres--sandbox')[0])
          }
        }, index * 140)
      })
    },
    addSlideBefore(newRef = this.generateScreenRef()) {
      let ref = newRef
      if (/before|after/.test(newRef)) {
        ref = this.generateScreenRef()
      }
      const currentFirstScreen = _.imm(this.presentationSettings.screens[0])
      const newSettings = {
        ...this.presentationSettings,
        screens: [
          {
            ...currentFirstScreen,
            showProjectDetails: 0,
            ref,
            screenType: 'content',
            content: []
          },
          ...this.presentationSettings.screens
        ]
      }
      this.presentationSettings = newSettings
      this.goToScreen(0)
    },
    addSlideAfter(newRef = this.generateScreenRef()) {
      let ref = newRef
      if (/before|after/.test(newRef)) {
        ref = this.generateScreenRef()
      }
      const currentLastScreen = _.imm(
        this.presentationSettings.screens[this.presentationSettings.screens.length - 1]
      )
      const newSettings = {
        ...this.presentationSettings,
        screens: [
          ...this.presentationSettings.screens,
          {
            ...currentLastScreen,
            showProjectDetails: 0,
            ref,
            screenType: 'content',
            content: []
          }
        ]
      }
      this.presentationSettings = newSettings
      this.goToScreen(this.presentationSettings.screens.length - 1)
    },
    slidePreviewLeft() {},
    slidePreviewRight() {},
    removeHeadingPic() {
      this.modifyScreen(
        {
          headingFileId: null
        },
        true
      )
    },
    async changeHeadingPic(file = false) {
      if (file === null) {
        this.removeHeadingPic()
        return
      }

      const { selected } = file
        ? { selected: [{ file_id: file }] }
        : await this.$store.dispatch('modal/selector', {
            type: 'file',
            filters: {
              file_type: '%png%||%gif%||%jpg%||%jpeg%||%bmp%||%tif%||folder'
            },
            multiple: false
          })

      const sel = c.makeArray(selected)
      console.log('sel', sel)
      if (sel.length) {
        this.modifyScreen(
          {
            headingFileId: sel[0].file_id
          },
          true
        )
      }
    },
    toggleLogo() {
      this.modifyScreen(
        {
          logoFileId: this.$store.state.session.company.company_logo_file_id,
          showLogo: 1
        },
        true
      )
    },
    async changeLogo(file = false) {
      if (file === null) {
        this.modifyScreen(
          {
            logoFileId: null,
            showLogo: 0
          },
          true
        )
        return
      }

      const { selected } = file
        ? { selected: [{ file_id: file }] }
        : await this.$store.dispatch('modal/selector', {
            type: 'file',
            filters: {
              file_type: '%png%||%gif%||%jpg%||%jpeg%||%bmp%||%tif%||folder'
            },
            multiple: false
          })

      const sel = c.makeArray(selected)
      if (sel.length) {
        if (!this.$store.state.session.company.company_logo_file_id) {
          this.$store.dispatch('Company/save', {
            selected: [
              {
                ...this.$store.state.session.company,
                company_logo_file_id: sel[0].file_id
              }
            ],
            go: false,
            view: false
          })
        }
        this.modifyScreen(
          {
            logoFileId: sel[0].file_id,
            showLogo: 1
          },
          true
        )
      }
    },
    togglePresenter() {
      this.toggleScreenValue('showPresenter')
    },
    toggleHeading() {
      this.toggleScreenValue('showHeading')
    },
    toggleContact() {
      this.toggleScreenValue('showContact')
    },
    toggleScreenValue(key) {
      this.modifyScreen({
        [key]: !this.currentScreen[key] ? 1 : 0
      })
    },
    modifyScreen(obj, allScreens = false) {
      let screens = this.presentationSettings.screens

      if (!allScreens) {
        screens.splice(this.screenIndex, 1, {
          ...screens[this.screenIndex],
          ...obj
        })
      } else {
        screens = screens.map((screen) => ({
          ...screen,
          ...obj
        }))
      }

      console.log(
        'updating pre',
        _.imm({
          presSett: {
            ...this.presentationSettings,
            screens
          }
        })
      )
      this.presentationSettings = {
        ...this.presentationSettings,
        screens
      }
    },
    async copyStyleFrom() {
      const { selected = [] } = await this.$store.dispatch('modal/selector', {
        type: 'template',
        filters: {
          template_type_id: 101
        },
        multiple: false
      })
      if (selected.length) {
        this.setTemplateStyle(selected[0])
        this.enableTemplateSave = 1
        this.unsavedChanges = 1
      }

      return this.presentationSettings
    }
  },
  props: {
    deselectOnDestroy: {
      default: false
    },
    editable: {
      default: true
    },
    editing: {
      default: false
    },
    presentationSettingsOverrides: {
      default: () => ({})
    }
  },
  emits: ['editing'],
  mounted() {
    eventBus.$on('PresentationSeeFile', this.seeFile)
    eventBus.$on('PresentationModifyItem', this.modifyItem)
    eventBus.$on('PresentationFiles', this.modifyFiles)
    eventBus.$on('PresentationMoreEmphasis', this.addEmphasis)
    eventBus.$on('PresentationLessEmphasis', this.removeEmphasis)
    eventBus.$on('PresentationRemoveItem', this.removeItem)
  },
  beforeUnmount() {
    eventBus.$off('PresentationSeeFile', this.seeFile)
    eventBus.$off('PresentationModifyItem', this.modifyItem)
    eventBus.$off('PresentationFiles', this.modifyFiles)
    eventBus.$off('PresentationMoreEmphasis', this.addEmphasis)
    eventBus.$off('PresentationLessEmphasis', this.removeEmphasis)
    eventBus.$off('PresentationRemoveItem', this.removeItem)
  }
}
