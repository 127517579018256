import { computed, onMounted, onBeforeUnmount, ref, defineOptions, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ChangeTracking from '@/components/mixins/ChangeTracking'
import ChangeWatcher from '@/components/Sheets/ChangeWatcher.js'
import eventBus from '@/eventBus.js'
import normalizeUtilities from '../../../imports/api/NormalizeUtilities'

export default {
  useRouteEntityContext({
    changeHandler: chh = () => {},
    resetHandler: rhh = () => {},
    trackChanges = false
  } = {}) {
    defineOptions({
      mixins: [ChangeTracking]
    })

    const $store = useStore()
    const $router = useRouter()
    const $route = computed(() => $router.currentRoute.value)
    const routeId = computed(() => $route.value.params.id ?? null)
    const type = computed(() => $route.value.meta.type)
    const storeName = computed(() => c.titleCase(type.value))
    const all = computed(() => $store.state[storeName.value].all)

    const selectBlank = computed(() => $route.value.meta.selectBlank || !routeId.value)
    const fetchingId = computed(() => (selectBlank.value ? 'blank' : routeId.value))
    const fetchingInitiated = computed(
      () => fetchingId.value in ($store.state[storeName?.value]?.fetching || [])
    )
    const fetchedRefId = computed(
      () => $store.state?.[storeName.value]?.fetching?.[fetchingId.value] ?? null
    )

    if (selectBlank.value && !fetchingInitiated.value) {
      // do not await, set it in motion and let the refId computed pick up the change automatically down the line
      // but don't run it unless if fetching hasn't been initiated elsewhere
      $store.dispatch(`${storeName.value}/selectBlank`, {
        type: type.value
      })
    } else if (routeId.value && !fetchingInitiated.value) {
      // if is fetching just wait, if not then fetch it
      // do not await, set it in motion and let the refId computed pick up the change automatically down the line
      // but don't run it unless if fetching hasn't been initiated elsewhere
      $store.dispatch(`${storeName.value}/selectExisting`, {
        id: routeId.value,
        type: type.value,
        full: true
      })
    }

    const refId = computed(() => {
      if (selectBlank.value) return fetchedRefId.value
      return normalizeUtilities.getNormalizedRootRefId(all.value?.[`${routeId.value}`] ?? {})
    })

    const loading = computed(() => (refId.value ? 0 : 1))

    const isDirty = ref(false)
    const changes = ref({})
    const normalized = computed(() => $store.state[storeName.value].normalized)
    const object = computed(() => normalized.value[refId.value])

    const changeHandler = (changePayload) => {
      const { isDirty: is, changeManager } = changePayload
      isDirty.value = is
      changes.value = changeManager.getChangeLogs()
      chh(changePayload)
      if (routeId.value)
        eventBus.$emit(`store-changed-${type.value}-${routeId.value}`, changePayload)
    }

    const resetHandler = (changePayload) => {
      const { changeManager } = changePayload
      isDirty.value = false
      changes.value = changeManager.getChangeLogs()
      rhh(changePayload)
      if (routeId.value) eventBus.$emit(`store-reset-${type.value}-${routeId.value}`, changePayload)
    }

    const initiateChangeWatcher = () => {
      const { initiate } = ChangeWatcher.useChangeWatcher({
        store: storeName,
        refId: refId,
        changeHandler,
        resetHandler,
        $store
      })
      if (trackChanges) initiate()
    }

    watch(refId, () => {
      if (refId.value) initiateChangeWatcher()
    })

    onMounted(() => {
      // if (routeId.value) refId.value = Object.keys(all.value?.[`${routeId.value}`] ?? {})?.[0]
      if (refId.value) initiateChangeWatcher()
    })

    onBeforeUnmount(() => {
      // Clean up any event listeners or other side effects
    })

    const isInPresentation = computed(() => {
      const path = $route.value.fullPath
      const presentationRoutes = ['tab=Preview', 'customer/project/preview', 'pub/presentation']
      return presentationRoutes.some((route) => path.includes(route))
    })

    return {
      isDirty,
      changes,
      routeId,
      type,
      storeName,
      refId,
      normalized,
      object,
      loading,
      selectBlank,
      isInPresentation
    }
  }
}
