export default {
  languageName: 'English (US)',
  // Presentation
  Estimate: 'Estimate',
  Expires: 'Expires',
  Project: 'Project',
  Client: 'Client',
  'Call us': 'Call us',
  'Presented by': 'Presented by',
  'Expand': 'Expand',
  'Expand to see options': 'Expand to see options',
  'other customers recently upgraded their': 'other customers recently upgraded their',
  'other options for': 'other options for',
  'Included': 'Included',
  'Not included': 'Not included',
  'Preview': 'Preview',
  'Download': 'Download',
  'Subtotal': 'Subtotal',
  'Cost': 'Cost',
  'Taxes': 'Taxes',
  'Project total': 'Project total',
  'Review terms and approve this proposal': 'Review terms and approve this proposal',
  'Review terms and approve': 'Review terms and approve',
  'Review terms': 'Review terms',
  'Request revision': 'Request revision',
  'Decline': 'Decline',
  'Options available - look for the upgrades symbol': 'Options available - look for the upgrades symbol',
  'Upgrades available': 'Upgrades available',
  'This version was approved on': 'This version was approved on',
  'Terms and conditions': 'Terms and conditions',
  'In order to continue, review and accept to the terms below': 'In order to continue, review and accept to the terms below',
  'I accept the terms and conditions': 'I accept the terms and conditions',
  'I do not accept the terms and conditions': 'I do not accept the terms and conditions',
  'Thank you for booking': 'Thank you for booking',
  'Please wait while we upgrade your records': 'Please wait while we upgrade your records',
  'Thank you': 'Thank you',
  'Project fully booked': 'Project fully booked',
  'Thank you for booking with us': 'Thank you for booking with us',
  'We will get in touch as soon as possible to confirm details': 'We will get in touch as soon as possible to confirm details',
  'Your project is fully booked': 'Your project is fully booked',
  'Come back to Bolster to follow updates or to request or approve change orders': 'Come back to Bolster to follow updates or to request or approve change orders',
  'See projects': 'See projects',
  'Request a new quote': 'Request a new quote',
  'Discount': 'Discount',
  'items included': 'items included',
  'items excluded': 'items excluded',
  'options': 'options',
  'Review all': 'Review all',
  'New feature': 'New feature',
  'See more': 'See more',
  "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Email": "Email",
  "Phone": "Phone",
  "Suite": "Suite",
  "Address": "Address",
  "City": "City",
  "Zip Code / Postal Code": "Zip Code / Postal Code",
  "Project Details": "Project Details",
  "Project Type": "Project Type",
  "Project Budget": "Project Budget",
  "Project Timeline": "Project Timeline",
  "Tell us about your project.": "Tell us about your project.",
  "Tell us the type of project you need (ex. basement renovation).": "Tell us the type of project you need (ex. basement renovation).",
  "Tell us your budget for the project.": "Tell us your budget for the project.",
  "Tell us when you want the project to start and any other important dates.": "Tell us when you want the project to start and any other important dates.",

  // Email
  'Thank you for the opportunity to provide you this': 'Thank you for the opportunity to provide you this',
  'change-order': 'change-order',
  'proposal': 'proposal',
  'for project': 'for project',
  'at': 'at',
  'After you review the': 'After you review the',
  'and make desired selections (if applicable), please choose to accept, request an alteration or decline': 'and make desired selections (if applicable), please choose to accept, request an alteration or decline',
  'Review your': 'Review your',
  'You\'re receiving this email because you requested information form a company subscribed to': 'You\'re receiving this email because you requested information form a company subscribed to',

  "Could not find form": "Could not find form",
  "This form does not exist. Please double check the url": "This form does not exist. Please double check the url",
  "Saving form submission": "Saving form submission",
  "Submit": "Submit",
  "To get started creating an estimate, please select the options provided below": "To get started creating an estimate, please select the options provided below",
  "About us": "About us",
  "Get a quote": "Get a quote",
  "Company rating": "Company rating",
  "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We're excited to help make your dream home a reality": "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We're excited to help make your dream home a reality",
  "You will receive a copy of the estimate by email for your records": "You will receive a copy of the estimate by email for your records",
  "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal."

};
