<script setup>
import { ref, computed, defineProps, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import StringField from '@/components/ui/Calculator/StringField.vue'
import CostItemHeader from './CostItem/Header.vue'
CostItemHeader.compatConfig = { MODE: 3 }
import ItemAddons from '@/components/composables/ItemAddons.js'
import AddonsEditor from '@/components/bodies/CostItem/AddonsEditor.vue'
AddonsEditor.compatConfig = { MODE: 3 }
import Estimating from '@/components/Sheets/quote/estimating/Estimating.vue'
import ProjectDimensions from '@/components/quote/ProjectDimensions/ProjectDimensions.vue'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'
import AreaDimensions from '@/components/quote/ProjectDimensions/AreaDimensions.vue'
import MeterGroup from 'primevue/metergroup'
import { useMediaQuery } from '@/composables/mediaQuery'
import AutoCost from '../../../imports/api/AutoCost.js'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'assembly' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  showTitle: { type: Boolean, default: true },
  showInfo: { type: Boolean, default: true },
  showContents: { type: Boolean, default: true },
  oMod: { type: Object, required: false }
})

const $store = useStore()

const refId = ref(props.refId || props.reference || 'root')
const type = ref(props.type || 'assembly')
const store = ref(props.store || props.storeName)

const { smallFormat } = useMediaQuery()

const obj = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store
})

const {
  norm,
  object,
  parentRefId,
  oDimensions,

  assembly_is_optional,
  assembly_is_included,

  // country_id: cid,
  assembly_country, //  should use country_id instead

  assembly_name,
  quote_notes,
  aoProperties,
  file_ids,
  quote_production_notes,
  parent_cost_type_id,

  quote_subtotal_net_equation,
  quote_subtotal_net,
  quote_materials_cost_net,
  quote_labor_cost_net,
  quote_profit_net,
  assembly_markup_percentage_adjustment,
  quote_profit_net_equation,
  quote_total_cost_net,

  addons,

  assembly_emphasis,
  assembly_show_itemized_prices,
  assembly_minimum_qty_net_equation,
  assembly_minimum_qty_net
} = obj

onBeforeMount(async () => {
  if (!inCompanyScope.value && !publicCategories.value.length) await getPublicCategories()
})

const profit = computed({
  get() {
    return quote_subtotal_net.value - quote_total_cost_net.value
  },
  set(profit) {
    return (quote_subtotal_net.value = profit + quote_total_cost_net.value)
  }
})
const margin = computed({
  get() {
    return c.divide(profit.value, quote_subtotal_net.value) * 100
  },
  set(mr) {
    quote_subtotal_net.value = quote_total_cost_net.value * c.marginToMarkup(c.divide(mr, 100))
  }
})

const recalcAddonsFor = ref([])
watch(recalcAddonsFor, async (refIds) => {
  if (!refIds?.length) return
  // Recalc linked dimension addons
  c.throttle(async () => {
    const urefids = _.cleanArray(refIds)
    recalcAddonsFor.value = [] // flush
    await $store.dispatch('Quote/recalcAddons', { refIds: urefids, loading: false })
  })
})

const dimensions = computed({
  get() {
    return { ...(oDimensions.value ?? {}) }
  },

  async set(dims) {
    if (!dims || _.jsonEquals(dims, dimensions.value)) return

    const keys = Object.keys(dims)
    const changedDimensions = keys.filter((key) => !_.jsonEquals(dims[key], dimensions.value[key]))

    oDimensions.value = dims

    const refIds = await $store.dispatch(`Quote/getLinkedDimensionRefIds`, {
      store: store.value,
      assemblyRefId: refId.value,
      changedDimensions
    })
    recalcAddonsFor.value = [...recalcAddonsFor.value, ...refIds]
  }
})

const descendantDimensions = computed(() => {
  if (!refId.value) return { rooms: [], roomsWithRequired: [] }
  const descendants = NormalizeUtilities.extractDescendants(norm.value ?? {}, [refId.value], true)

  const rooms = Object.keys(descendants).filter(
    (rr) => descendants[rr].type === 'assembly' || descendants[rr].type === 'quote'
  )

  return {
    rooms,
    roomsWithRequired: rooms.filter((rr) => descendants[rr].asRequiredDimensions?.length)
  }
})

// const country_id = computed({
//   get() {
//     return (
//       cid.value ??
//       ((assembly_country.value === 'CAN' && 1) || (assembly_country.value === 'USA' && 2) || null)
//     )
//   },
//   set(id) {
//     cid.value = id
//     const sid = `${id}`
//     assembly_country.value = (sid === '1' && 'CAN') || (sid === '2' && 'USA') || null
//   }
// })

const attrs = computed({
  get() {
    return [...(aoProperties.value || []), ['', '']]
  },
  set(props) {
    aoProperties.value = props.filter(([a, b]) => a || b)
  }
})

const handleAttr = (attrIndex, valIndex, val) => {
  const arr = [...attrs.value]
  if (!arr[attrIndex]) arr[attrIndex] = ['', '']
  arr[attrIndex] = [...arr[attrIndex]]
  arr[attrIndex][valIndex] = val

  attrs.value = arr
}

const showPlus = ref(0)
const forceImageOpen = ref(file_ids.value?.length)
const refFileList = ref(null)

const addonTypeChoice = ref(null)
const { isAddonItem } = ItemAddons.useItemAddons({
  type,
  refId,
  store,
  autoload: false
})

if (isAddonItem.value) addonTypeChoice.value = 'addon'

const forceShowAddonOptions = ref(false)
const hasVariationsOrAddons = computed({
  get() {
    return isAddonItem.value || forceShowAddonOptions.value
  },
  set(b) {
    forceShowAddonOptions.value = b
  }
})
const isOptionsInitialized = computed(() => {
  if (addons.value?.length) return true
  return false
})
const viewMode = ref('settings')

const accordionIndex = ref([])

const inCompanyScope = computed(() => $store.state.session.scope.company)
const isSuperUser = computed(() => $store.getters.isSuperUser)

const accordionPt = computed(() => ({
  root: () => ({}),
  tab: () => ({}),
  accordionTab: () => ({
    root: ({ context }) => ({
      class: [
        'border rounded-sm my-4',
        {
          'bg-surface-200/50 border-transparent shadow': context.active,
          'border-transparent': !context.active
        }
      ]
    }),
    header: () => ({
      class: ['hover:bg-surface-300/30 border-b-0 py-0 px-2']
    }),
    headerIcon: () => ({
      class: [
        {
          hidden: true
        }
      ]
    }),
    headerAction: () => ({
      class: ['px-4 py-4 min-h-20 text-lg text-surface-900']
    }),
    content: () => ({
      class: ['!px-4']
    })
  }),
  hooks: () => ({})
}))

const labelClass =
  'text-surface-800 flex justify-start items-center gap-4 font-medium basis-3/5 grow-0 shrink-1 text-lg leading-tight pr-2'
const numberClass = 'text-surface-600 text-lg tabular-nums text-right'
const rowClass = 'flex justify-between items-center hover:bg-surface-200/20 h-[70px]'
const valueClass = 'flex flex-nowrap justify-end items-center !text-surface-600 text-lg'
const dashIconClass = 'rounded-sm p-2 size-6 border-2'
const dashIconDisabledClass = 'text-surface-300 border-surface-300 !border'
const fieldGroupClass = 'divide-y divide-surface-300'

const upgradesModal = ref(null)
const handleOptionsManager = () => {
  upgradesModal.value.open()
}

const costMeter = computed(() => [
  {
    label: 'Materials + subcontractors',
    color: c.getCssColor('materials'),
    value: quote_materials_cost_net.value
  },
  {
    label: 'Labor',
    color: c.getCssColor('labor'),
    value: quote_labor_cost_net.value
  },
  {
    label: 'Profit',
    color: c.getCssColor('profit'),
    value: quote_profit_net.value
  }
])

let publicCategories = ref([])
const getPublicCategories = async () => {
  const categories = await AutoCost.getAutoCostCategories($store)
  publicCategories.value = categories.map((category) => {
    return {
      text: category.cost_type_name,
      value: category.cost_type_id
    }
  })
}
</script>

<template>
  <div class="flex flex-col gap-6 items-center w-full h-full basis-full">
    <div v-if="showContents">
      <SelectionToggle
        v-model="viewMode"
        :options="[
          { value: 'settings', text: 'Assembly settings', icon: 'toggle-on' },
          { value: 'contents', text: 'Assembly contents', icon: 'table' }
        ]"
      />
    </div>

    <div
      class="max-w-screen-lg flex justify-start flex-col w-full gap-12 basis-1/5 shrink"
      v-if="viewMode === 'settings'"
    >
      <Automation v-if="props.showInfo">
        <div>
          <font-awesome-icon icon="cubes" /> <span class="font-medium">An assembly</span> is a group
          of items and other assemblies that can be re-used, sorta like templates.
        </div>

        <div>
          <font-awesome-icon icon="ruler-triangle" />
          <span class="font-medium">You can add dimensions</span> -- for example create a "Fence"
          assembly with items that require the "fence height" and "fence length" dimensions, then
          add the assembly to an estimate -- the system will automatically notify you that you need
          to supply those dimensions.
        </div>

        <div>
          <font-awesome-icon icon="diagram-next" />
          <span class="font-medium">Assemblies can be nested inside other assemblies </span> -- for
          example create a single "General construction" assembly with your framing, permit and
          drywall items inside -- then re-use that assembly inside your "House", "Garage" and
          "Finished basement" assemblies -- it'll automatically calculate quantities based on its
          parent assembly.
        </div>

        <div>
          <font-awesome-icon icon="swatchbook" />
          <span class="font-medium">Assemblies can be used as a selection</span> -- for example
          create a 'Shower' assembly as part of an option in "Tub/shower selection", that includes
          the base, tile, and glass as component items.
        </div>

        <div>
          <font-awesome-icon icon="toggle-on" />
          <span class="font-medium">Make any assembly optional</span> -- for example, as part of a
          renovation, you could make the 'Main floor bathroom' assembly optional -- and the customer
          can add or remove the entire scope, without asking you to re-estimate it!
        </div>
      </Automation>
      <div>
        <div class="pb-6" v-if="props.showTitle">
          <CostItemHeader v-bind="props" :showSaveButton="false" />
        </div>
        <div class="flex flex-col lg:flex-row justify-between items-stretch gap-4">
          <div class="flex flex-col items-start justify-start gap-4">
            <StringField
              :class="[
                'text-md h-fit !min-h-[3.4rem] w-full !whitespace-pre-line',
                {
                  'bg-surface-200/50 text-surface-400 !border-transparent': !quote_notes
                }
              ]"
              placeholder="Add a description"
              :validate="{ required: true }"
              v-model="quote_notes"
            />

            <div class="flex flex-col items-start justify-start gap-1">
              <div
                :class="['flex justify-start items-center gap-6 w-[400px]']"
                v-for="(attr, index) in attrs"
                :key="index"
              >
                <font-awesome-icon
                  icon="fas fa-circle"
                  :class="[
                    '',
                    {
                      'text-surface-300/30 w-[10px]': !attr[0] && !attr[1],
                      'text-surface-700 w-[10px]': attr[0] || attr[1]
                    }
                  ]"
                />

                <div class="flex justify-start items-center gap-0">
                  <StringField
                    :class="[
                      'text-sm min-h-8 ml-4 whitespace-break-spaces leading-relaxed',
                      {
                        'bg-surface-200/50 text-surface-400 w-[170px] !border-transparent':
                          !attr[0],
                        'w-fit font-medium': attr[0]
                      }
                    ]"
                    placeholder="Add attributes/traits"
                    :validate="{ required: true }"
                    :value="attr[0]"
                    @input="(val) => handleAttr(index, 0, val)"
                  />
                  <span v-if="attr[0] && attr[1]">:</span>
                </div>

                <StringField
                  :class="[
                    'text-sm min-h-8 ml-4 whitespace-break-spaces leading-relaxed',
                    {
                      'bg-surface-200/50 text-surface-400 w-[50px] !border-transparent': !attr[1],
                      'w-fit ': attr[1]
                    }
                  ]"
                  placeholder=""
                  :validate="{ required: true }"
                  :value="attr[1]"
                  @input="(val) => handleAttr(index, 1, val)"
                />
              </div>
            </div>
          </div>
          <div class="grow-1">
            <div
              @mouseover="showPlus = 1"
              @mouseout="showPlus = 0"
              @click="
                () => {
                  if (!forceImageOpen) {
                    forceImageOpen = true
                    $nextTick(() => {
                      refFileList.showAddOptions = true
                    })
                  }
                }
              "
              :class="[
                'h-full min-h-20 lg:min-w-[200px] rounded-md flex gap-2 cursor-pointer relative transition',
                {
                  'bg-surface-200/50 hover:bg-surface-300/50 !border-transparent': !forceImageOpen
                }
              ]"
            >
              <font-awesome-icon
                icon="camera"
                size="xl"
                class="text-surface-400 self-center justify-self-center w-full"
                v-if="!showPlus && !forceImageOpen"
              />
              <font-awesome-icon
                icon="fas fa-plus"
                size="xl"
                class="text-surface-400 self-center justify-self-center w-full"
                v-else-if="showPlus && !forceImageOpen"
              />
              <FileList
                v-model="file_ids"
                heroFirst
                idList
                ref="refFileList"
                v-else
                class="max-w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="relative w-full h-full shrink grow basis-full min-h-[500px] overflow-hidden flex justify-start flex-col items-center"
      v-if="viewMode === 'contents'"
    >
      <Estimating
        :key="`${refId}-${viewMode}`"
        v-if="refId && assembly_name"
        store="Assembly"
        type="assembly"
        title="Assembly contents"
        icon="cubes"
        :refId="refId"
      />
      <!--      :checkedOutChangeOrderId="checkedOutChangeOrderId"-->
      <!--      :autoCostDisabledWarning="autoCostDisabledWarning"-->
      <!--      :showLivePriceWarning="showLivePriceWarning"-->
      <!--      :hasLivePricingEnabled="hasLivePricingEnabled"-->
      <!--      @fixMissingDimensions="activeTabs = ['Dimensions']"-->
      <!--      @updateAutocost="() => fetchQuoteLivePrices()"-->
      <!--      @resetCheckout="() => resetCheckout()"-->
    </div>

    <div
      class="max-w-screen-lg flex justify-start flex-col w-full basis-4/5 shrink grow"
      v-if="viewMode === 'settings'"
    >
      <Accordion
        class="w-full"
        :pt="accordionPt"
        v-model:activeIndex="accordionIndex"
        expandIcon="pi pi-plus"
        collapseIcon="pi pi-minus"
        multiple
      >
        <AccordionTab>
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(0)
                    }
                  ]"
                />
                Takeoff dimensions
              </div>

              <div
                class="flex flex-row-reverse md:flex-row justify-end md:justify-start flex-nowrap white-space-nowrap"
              >
                <div class="flex gap-2">
                  <font-awesome-icon
                    icon="ruler-triangle"
                    :class="[
                      dashIconClass,
                      {
                        [dashIconDisabledClass]: !descendantDimensions?.roomsWithRequired?.length,
                        'border-blue-print text-blue-print':
                          descendantDimensions?.roomsWithRequired?.length
                      }
                    ]"
                    size="lg"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-if="Object.keys(dimensions ?? {}).length">
            <AreaDimensions
              :full="!smallFormat"
              v-model="dimensions"
              :showDerivedDimensions="true"
              :object="object"
              :store="store"
              :reference="refId"
              :key="refId"
            />
          </template>
          <template v-else>
            <div class="font-medium text-lg">No dimensions required on this assembly.</div>

            <ProjectDimensions
              :key="refId"
              :show-derived-dimensions="true"
              :reference="refId"
              :store="store"
            />
          </template>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(1)
                    }
                  ]"
                />
                Pricing
              </div>

              <div class="flex flex-row-reverse md:flex-row justify-end md:justify-start gap-4">
                <div class="flex flex-col items-end justify-center w-fit">
                  <div
                    class="flex flex-row items-center justify-end flex-nowrap white-space-nowrap"
                  >
                    <CalculatorFormatted
                      format="$"
                      v-model="quote_subtotal_net"
                      v-model:equation="quote_subtotal_net_equation"
                      class="text-right"
                    />&nbsp;&nbsp;/ each
                  </div>

                  <MeterGroup :value="costMeter" :max="cost_matrix_rate_net" class="w-full">
                    <template #label><span class="hidden"></span></template>
                  </MeterGroup>
                </div>

                <div class="flex gap-2">
                  <div
                    v-tooltip="
                      assembly_markup_percentage_adjustment
                        ? 'This assembly has a profit adjustment that affects all its child items and that affects the overall price and profit on this assembly.'
                        : 'There is no profit/pricing adjustment on this assembly yet'
                    "
                  >
                    <font-awesome-icon
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: !assembly_markup_percentage_adjustment,
                          '!border-deep-red-500 !text-deep-red-500':
                            assembly_markup_percentage_adjustment
                        }
                      ]"
                      icon="tilde"
                      size="lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="flex flex-col justify-start items-stretch gap-1 py-2">
            <div :class="[rowClass]">
              <div :class="labelClass">Profit</div>

              <div class="flex flex-row items-center justify-center flex-grow">
                <CalculatorFormatted
                  :class="numberClass"
                  style="max-width: 100px"
                  format="number"
                  v-model="margin"
                />
                %
              </div>

              <div class="text-success value flex flex-nowrap justify-end items-center">
                <CalculatorFormatted
                  format="$"
                  v-model="profit"
                  v-model:equation="quote_profit_net_equation"
                  :class="numberClass"
                />&nbsp;&nbsp;/ each
              </div>
            </div>

            <div :class="[rowClass, 'border-t border-pitch-black']">
              <div :class="labelClass">Price</div>

              <div :class="valueClass">
                <CalculatorFormatted
                  format="$"
                  v-model="quote_subtotal_net"
                  v-model:equation="quote_subtotal_net_equation"
                  class="text-right"
                />&nbsp;&nbsp;/ each
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab>
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(2)
                    }
                  ]"
                />
                Sales
              </div>

              <div
                class="flex flex-row-reverse md:flex-row justify-end md:justify-start flex-nowrap white-space-nowrap"
              >
                <div class="flex gap-2">
                  <font-awesome-icon
                    icon="swatchbook"
                    :class="[
                      dashIconClass,
                      {
                        [dashIconDisabledClass]: !hasVariationsOrAddons,
                        'border-blue-print text-blue-print': hasVariationsOrAddons
                      }
                    ]"
                    size="lg"
                  />

                  <font-awesome-icon
                    icon="toggle-on"
                    :class="[
                      dashIconClass,
                      {
                        [dashIconDisabledClass]: !assembly_is_optional,
                        'border-blue-print text-blue-print': assembly_is_optional
                      }
                    ]"
                    size="lg"
                  />
                </div>
              </div>
            </div>
          </template>

          <div :class="fieldGroupClass">
            <div :class="[rowClass]" v-if="parentRefId">
              <div :class="[labelClass]">Make this item optional for your customer?</div>

              <div :class="[valueClass, 'gap-2']">
                <Choose
                  v-if="assembly_is_optional"
                  schema="cost_item:assembly_is_included"
                  v-model="assembly_is_included"
                  :return-array="false"
                  placeholder="Included"
                  :default="1"
                  size="sm"
                  :static-set="[
                    {
                      text: 'Included by default',
                      value: 1
                    },
                    {
                      text: 'Excluded by default',
                      value: 0
                    }
                  ]"
                  :allow-deselect="false"
                />

                <Checkbox
                  v-model="assembly_is_optional"
                  name="optional"
                  value="Optional"
                  inputId="opt"
                  :trueValue="1"
                  :falseValue="0"
                />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">Make this item optional for your customer?</div>

              <div :class="[valueClass, 'gap-2']">
                <Choose
                  v-if="assembly_is_optional"
                  schema="assembly:assembly_is_included"
                  v-model="assembly_is_optional"
                  :return-array="false"
                  placeholder="Included"
                  :default="1"
                  size="sm"
                  :static-set="[
                    {
                      text: 'Included by default',
                      value: 1
                    },
                    {
                      text: 'Excluded by default',
                      value: 0
                    }
                  ]"
                  :allow-deselect="false"
                />

                <Checkbox
                  v-model="assembly_is_optional"
                  name="optional"
                  value="Optional"
                  inputId="opt"
                  :trueValue="1"
                  :falseValue="0"
                />
              </div>
            </div>

            <div :class="[rowClass, 'border-b-none']">
              <div :class="[labelClass]">Include alternative selections for this item?</div>

              <div :class="[valueClass, 'gap-2']">
                <Checkbox v-model="hasVariationsOrAddons" />
              </div>
            </div>

            <template v-if="hasVariationsOrAddons">
              <div :class="[rowClass, '!h-fit !border-none !justify-center']">
                <div :class="[valueClass, 'gap-6 !h-fit']">
                  <div
                    :class="[
                      {
                        'border-pitch-black bg-surface-100':
                          isAddonItem || addonTypeChoice === 'addon',
                        'border-surface-400 bg-surface-100/60':
                          !isAddonItem && addonTypeChoice !== 'addon',
                        'w-[17rem] h-[17rem]': true // !isAddonItem && !isVariationItem,
                      },
                      'flex flex-col justify-between gap-2 rounded-sm border-2 p-4 hover:opacity-100 cursor-pointer opacity-90 hover:border-pitch-black'
                    ]"
                    @click.capture.stop.prevent="
                      () => {
                        addonTypesChoice = 'addon'
                        handleOptionsManager()
                      }
                    "
                  >
                    <div class="flex flex-col leading-tight gap-0.5">
                      <span class="font-medium text-pitch-black leading-tight">Simple list</span>
                      <span class="text-sm leading-tight"
                        >Recommended for 1-10 options, or where the items are very different and may
                        require more details</span
                      >
                    </div>
                    <div class="flex gap-2 justify-center items-center">
                      <div
                        class="w-12 h-[8rem] bg-surface-300 flex items-center justify-center rounded-sm border border-transparent"
                      >
                        1
                      </div>
                      <div
                        class="w-12 h-[8rem] bg-surface-300/90 flex items-center justify-center rounded-sm border border-pitch-black/50"
                      >
                        2
                      </div>
                      <div
                        class="w-12 h-[8rem] bg-surface-300/80 flex items-center justify-center rounded-sm border border-transparent"
                      >
                        3
                      </div>
                      <div
                        class="w-12 h-[8rem] bg-surface-300/60 flex items-center justify-center rounded-sm border border-transparent"
                      >
                        ...
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="flex justify-center items-center !border-none p-4"
                v-if="isAddonItem || addonTypeChoice"
              >
                <Btn
                  severity="bolster"
                  size="xlarge"
                  :action="handleOptionsManager"
                  :class="[
                    {
                      'animate-bounce': !isOptionsInitialized
                    }
                  ]"
                >
                  {{ isOptionsInitialized ? 'Manage selections...' : 'Get started!' }}
                </Btn>
              </div>
            </template>

            <div :class="[rowClass]">
              <div :class="[labelClass]">Assembly appearance on the proposal</div>

              <div :class="[valueClass, 'gap-2']">
                <choose
                  v-model="assembly_emphasis"
                  :default="0"
                  schema="assembly:assembly_emphasis"
                  :allowCreate="false"
                  :return-array="false"
                  :staticSet="[
                    {
                      value: 1,
                      text: 'Emphasize the assembly on proposal'
                    },
                    {
                      value: 0,
                      text: 'Show the assembly normally'
                    },
                    {
                      value: -1,
                      text: 'Display as emphasized single item (classic preview only)'
                    },
                    {
                      value: -2,
                      text: 'Display as normal single item (classic preview only)'
                    },
                    {
                      value: -3,
                      text: 'Hide assembly from the proposal'
                    }
                  ]"
                />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">
                Reveal the individual prices of items within this assembly to your customer?
              </div>

              <div :class="[valueClass, 'gap-2']">
                <Checkbox v-model="assembly_show_itemized_prices" :trueValue="1" :falseValue="0" />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">Minimum quantity</div>

              <div :class="[valueClass, 'gap-2']">
                <CalculatorFormatted
                  :class="numberClass"
                  format="number"
                  v-model="assembly_minimum_qty_net"
                  v-model:equation="assembly_minimum_qty_net_equation"
                />
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab>
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(3)
                    }
                  ]"
                />
                Production
              </div>

              <div
                class="flex flex-row-reverse md:flex-row justify-end md:justify-start flex-nowrap white-space-nowrap"
              >
                <div class="flex gap-2">
                  <div
                    v-tooltip="
                      quote_production_notes
                        ? `Production notes: ${quote_production_notes.slice(0, 50)}...`
                        : 'No production notes'
                    "
                  >
                    <font-awesome-icon
                      icon="comment-exclamation"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: !quote_production_notes,
                          'text-blue-print border-blue-print': quote_production_notes
                        }
                      ]"
                      size="lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div :class="fieldGroupClass">
            <div :class="[rowClass, '!h-fit py-2']">
              <div :class="[labelClass, '!basis-2/5']">Production notes</div>

              <div :class="valueClass">
                <Field
                  class="min-w-[300px]"
                  type="textarea"
                  v-model="quote_production_notes"
                  placeholder="Add internal-only production notes (not visible to your client)"
                />
              </div>
            </div>

            <div v-if="inCompanyScope" :class="[rowClass]">
              <div :class="[labelClass]">Where do you want this to be saved in your catalog?</div>

              <div :class="valueClass">
                <Choose
                  v-model="parent_cost_type_id"
                  schema="cost_type:cost_type_id"
                  placeholder="Root category"
                  :custom-options="[
                    {
                      value: 'NULL',
                      text: 'Root directory',
                      html: 'Root directory'
                    }
                  ]"
                  :allow-create="true"
                  :order="[['cost_type_name', 'asc']]"
                  :filters="{
                    cost_type_is_parent: 1,
                    company_id: $store.state.session.company?.company_id || 'NULL'
                  }"
                />
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab v-show="isSuperUser">
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(4)
                    }
                  ]"
                />
                Super user options
              </div>
            </div>
          </template>

          <div :class="fieldGroupClass">
            <div :class="[rowClass]">
              <div :class="[labelClass]">
                Which country to enable this assembly for? (AutoCost assemblies only)
              </div>

              <div :class="valueClass">
                <Choose
                  schema="country:country_id"
                  v-model="assembly_country"
                  :return-array="false"
                  placeholder="Any"
                  :allow-deselect="true"
                  :default="null"
                />
              </div>
            </div>

            <div :class="[rowClass, '!h-fit py-2']">
              <div :class="[labelClass, '!basis-2/5']">Production notes</div>

              <div :class="valueClass">
                <Field
                  :class="['min-w-[350px]']"
                  type="textarea"
                  v-model="quote_production_notes"
                  placeholder="Add internal-only production notes (not visible to your client)"
                />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">Where do you want this to be saved in your catalog?</div>

              <div :class="valueClass">
                <Choose
                  v-if="inCompanyScope"
                  v-model="parent_cost_type_id"
                  schema="cost_type:cost_type_id"
                  placeholder="Root category"
                  :custom-options="[
                    {
                      value: 'NULL',
                      text: 'Root directory',
                      html: 'Root directory'
                    }
                  ]"
                  :allow-create="false"
                  :order="[['cost_type_name', 'asc']]"
                  :filters="{
                    cost_type_is_parent: 1,
                    company_id: $store.state.session.company?.company_id || 'NULL'
                  }"
                />
                <Choose
                  v-else-if="publicCategories.length"
                  v-model="parent_cost_type_id"
                  :staticSet="publicCategories"
                  placeholder="None selected"
                />
                <spinner v-else :loading="true" size="2em" />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>

    <MiniModal
      size="full"
      :full="true"
      :closeable="true"
      :includeGutter="true"
      :hasFooter="false"
      scrollable
      ref="upgradesModal"
    >
      <template #header>
        <font-awesome-icon icon="swatchbook" /> Selection options editor
      </template>

      <AddonsEditor v-if="addonTypeChoice !== null" v-bind="props" />
    </MiniModal>
  </div>
</template>
