import _ from '../../../../imports/api/Helpers'
import CostType from '../../../../imports/api/schemas/CostType'
import QuoteAddons from '../../../../imports/api/schemas/QuoteAddons'
import eventBus from '../../../eventBus'
import AutoCost from '../../../../imports/api/AutoCost'

const costTypeDeps = CostType.getComputedDependants()

export default {
  props: {
    editable: {
      default: false
    },
    /**
     * Addon object
     * {
     *  // object type, assembly or cost_type
     *  type: 'assembly',
     *
     *  // item id
     *  id: '1234',
     *
     *  // replace or option
     *  // replace replaces a different item
     *  // option adds to parent
     *  // for cost_items/_types, it is always
     *  // a replace!
     *  addonType: 'replace',
     *
     *  // sibling or anywhere
     *  // for sibling, this option will show
     *  // if this item does not exist in
     *  // the immediate siblings, or for an assembly
     *  // also within its children
     *  // For a 'anywhere' option, it cannot exist anywhere
     *  // in the active quote tree.
     *  preventIf: 'sibling',
     * }
     */
    addon: {
      type: Object,
      default: {}
    },
    /**
     * Only required when it is an option/replace,
     * we need to know the existing price to offer
     * a difference
     */
    currentPrice: {
      type: Number,
      default: 0
    },
    /**
     * Item to get replaced, if addonType === 'replace'
     * or to be added INTO if addonType === 'option'
     */
    targetRefId: {
      required: true
    },
    dimensions: {
      type: Object,
      required: true
    },
    store: {
      default: 'Quote'
    }
  },
  emits: ['addon', 'get', 'disabled'],

  data() {
    return {
      loading: 0,
      fetching: 0,
      object: {},
      auditedObject: false,
      disabled: false
    }
  },

  watch: {},

  computed: {
    target() {
      return this.$store.state[this.store].normalized[this.targetRefId]
    },

    targetUnitPrice() {
      if (this.target.type === 'assembly') return this.target.quote_subtotal_net
      return this.target.cost_matrix_rate_net
    },

    /**
     * Localized
     * @returns {*}
     */
    targetQuantity() {
      const type = this.target.type
      let field

      if (type === 'assembly') {
        field = 'quote_qty_net_base'
      } else {
        field = 'cost_item_qty_net_base'
      }

      const qty = this.target[field]
      const from = this.target.unit_of_measure_id
      const conv = c.convertMeasures(qty, from, this.uomIdUsed)

      return conv
    },
    targetMeasure() {
      const target = this.target
      const prevUom = String(target.unit_of_measure_id || 'count')
      const targetMt = c.getMeasureTypeForUnitOfMeasure(prevUom)
      return targetMt
    },
    objectMeasure() {
      const object = this.auditedObject
      const currUom = String(object.unit_of_measure_id || 'count')
      const objectMt = c.getMeasureTypeForUnitOfMeasure(currUom)
      return objectMt
    },
    compatibleMeasures() {
      return this.objectMeasure === this.targetMeasure
    },
    overrideQty: {
      get() {
        return this.addon.qty
      },
      set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'
        this.setEmbueValue(field, v, 'qty')
      }
    },
    overrideEquation: {
      get() {
        return this.addon.equation
      },
      async set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'assembly_qty_equation' : 'cost_type_qty_equation'
        if (v) this.overrideQty = null
        await c.throttle(() => {})
        this.setEmbueValue(field, v, 'equation')
      }
    },
    overrideName: {
      get() {
        return this.addon.name
      },
      set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'assembly_name' : 'cost_type_name'
        this.setEmbueValue(field, v, 'name')
      }
    },
    rating: {
      get() {
        return this.addon.rating
      },
      set(v) {
        this.emitAddonValue({
          rating: !v ? null : +v
        })
      }
    },
    overrideDesc: {
      get() {
        return this.addon.desc
      },
      set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'quote_notes' : 'cost_type_desc'
        this.setEmbueValue(field, v, 'desc')
      }
    },
    overrideMarkup: {
      get() {
        return this.addon.markup
      },
      set(v) {
        this.setOverrideMarkup(v)
      }
    },
    hasOverridenMarkup() {
      const type = this.object.type

      if ('hasOverriddenMarkup' in this.addon) return this.addon.hasOverriddenMarkup

      if (type === 'assembly') {
        return (
          'assembly_markup_percentage_adjustment' in this.addon.embue ||
          'quote_markup_percentage_adjustment' in this.addon.embue
        )
      }

      return (
        'cost_matrix_markup_net' in this.addon.embue ||
        'cost_item_markup_net_adjusted' in this.addon.embue
      )
    },

    dimensionsUsed() {
      if (this.target.type !== 'assembly' || this.addon.type !== 'assembly') {
        return this.dimensionsRequired.reduce(
          (acc, dim) => ({
            ...acc,
            [dim.abbr]: this.parentDimensions[dim.abbr] || {}
          }),
          {}
        )
      }

      return this.dimensionsRequired.reduce(
        (acc, dim) => ({
          ...acc,
          [dim.abbr]: this.object.oDimensions[dim.abbr].inherit
            ? {
                ...(this.parentDimensions[dim.abbr] || {}),
                parent: 1
              }
            : {
                ...(this.localDimensions[dim.abbr] || {}),
                parent: 0
              }
        }),
        {}
      )
    },

    localDimensions() {
      return this.target.type === 'assembly' && this.object.type === 'assembly'
        ? this.target.oDimensions
        : {}
    },

    missingDimension() {
      if (!this.dimensionsRequired.length) return []

      return this.dimensionsRequired.filter(
        (dim) =>
          (!this.parentDimensions[dim.abbr] || !this.parentDimensions[dim.abbr].value) &&
          (!this.localDimensions[dim.abbr] || !this.localDimensions[dim.abbr].value)
      )
    },

    parent() {
      const store = this.store || this.storeName
      const state = store && this.$store.state[store]
      const norm = state && state.normalized
      const obj = norm && norm[this.targetRefId]
      const parentRef = obj.parentRefId
      const parent = norm && norm[parentRef]

      return parent
    },
    parentDimensions() {
      const dims = this.parent && this.parent.oDimensions

      return dims || {}
    },
    parentName() {
      return (
        (this.parent && (this.parent.assembly_name || this.parent.quote_name)) ||
        'the parent/container assembly'
      )
    },
    dimensionsRequired() {
      const abbrs = _.makeArray(this.addon.asDimensionsRequired)
      return abbrs.filter((abbr) => abbr in this.dimTypes).map((abbr) => this.dimTypes[abbr])
    },
    qtyUsed() {
      return this.addon.qty
    },
    uomIdUsed() {
      return this.addon.unit
    },
    uomUsed() {
      return this.uomIdUsed
    },
    currentIndex() {
      const current = this.target
      const type = current.type === 'assembly' ? 'assembly' : 'cost_type'
      if (!current[`${type}_id`] && current.live_price_reference) {
        return `live_price:${current.live_price_reference}`
      }
      return `${type}:${current[`${type}_id`]}`
    },
    addonIndex() {
      let id
      let type

      if (this.addon.bulk) {
        type = this.addon.livePriceRef
          ? 'live_price'
          : this.addon.bulk.type === 'assembly'
            ? 'assembly'
            : 'cost_type'
        id = this.addon.livePriceRef ?? this.addon.bulk[`${type}_id`]
      } else {
        type = this.addon.livePriceRef ? 'live_price' : this.addon.type
        id = this.addon.livePriceRef ?? this.addon.id
      }
      return `${type}:${id}`
    },
    isCurrent() {
      const addonIndex = this.addonIndex
      const currentIndex = this.currentIndex
      return addonIndex === currentIndex
    },
    fileIds() {
      const ids = c.makeArray(this.object.file_ids)
      return ids.length
        ? ids
        : [
            ...(this.addon.file_id ? [this.addon.file_id] : []),
            ...(this.object.file_id ? [this.object.file_id] : [])
          ]
    },
    picFile() {
      if (this.fileIds.length) return [c.buildDefaultObject('file', { file_id: this.fileIds[0] })]
      else if (this.addon.image_external) return [this.addon.image_external]
      return []
    },
    pic() {
      if (this.picFile.length && this.picFile[0].file_id) {
        const url = `file/view/${this.picFile[0].file_id}`
        return c.link(url)
      }
      if (this.picFile.length && this.picFile[0].file_url) {
        return this.picFile[0].file_url
      }
      const tagType = this.object.type === 'assembly' ? 'assembly' : 'cost_type'
      const url = `${tagType}/pic/${this.object[`${tagType}_id`]}`
      return c.link(url)
    },
    uploadTags() {
      const tagType = this.object.type === 'assembly' ? 'assembly' : 'cost_type'
      return {
        [`${tagType}_id`]: this.object[`${tagType}_id`]
      }
    },
    desc() {
      return this.addon.desc
    },
    name() {
      return this.addon.name
    },
    priceDifference() {
      const auditPrice = this.addon.price || 0

      if (this.addon.addonType === 'replace') {
        return auditPrice - this.currentPrice
      }

      return auditPrice
    },
    priceDifferenceUnit() {
      const auditPrice = c.divide(this.addon.price, this.addon.qty)

      if (this.addon.addonType === 'replace') {
        return auditPrice - this.targetUnitPrice
      }

      return auditPrice
    },
    dimTypes() {
      return this.dimensions
    }
  },

  methods: {
    openSettings() {
      if (!this.object || !Object.keys(this.object).length) {
        this.fetch()
      }

      this.$refs.settings.open()
    },
    async resetQty() {
      this.overrideEquation = null
      await c.throttle(() => {})
      this.overrideQty = null
    },
    async setObject(object, audit = true) {
      const objType = object.type === 'assembly' ? 'assembly' : 'cost_item'

      const { object: quantizedObject } = await this.$store.dispatch(
        `${c.titleCase(objType)}/buildDefaultObject`,
        {
          type: objType,
          embue: object
        }
      )

      // Fix qty equation for cost types created before the dimension update
      if (objType === 'cost_item') {
        quantizedObject.cost_type_qty_equation =
          costTypeDeps.cost_type_qty_equation(quantizedObject)
      }

      this.object = quantizedObject

      if (audit) await this.audit()
      else this.auditedObject = quantizedObject

      return this.auditedObject
    },

    /**
     * Get the quantity for the object provided,
     * using the target (parent or replaced item) as a guide
     * @param object
     * @param target
     * @returns { qty: *, linkable: 0|1 };
     */
    getQuantity(object, target) {
      if (!target) {
        return {
          qty: 1,
          linkable: 0
        }
      }
      const currentType = object.type
      const prevType = target.type

      const currUom = String(object.unit_of_measure_id || 'count')
      const prevUom = String(target.unit_of_measure_id || 'count')

      const prevQtyPrefix = prevType === 'assembly' ? 'quote' : 'cost_item'
      const targetQtyField = `${prevQtyPrefix}_qty_net_base`
      const prevQty = target[targetQtyField]

      const currLinkable = c.isUnitOfMeasureLinkable(currUom)
      const prevLinkable = c.isUnitOfMeasureLinkable(prevUom)
      const prevLinked = target.cost_item_link_qty ? 1 : 0

      const isReplacement = this.addon.addonType !== 'option'

      let qty = 1
      let linkable = 0

      // const prevEach = prevUom === '2';
      // const currEach = currUom === '2';
      const isCostItem = currentType === 'cost_item' || currentType === 'cost_type'
      const sameUnit = currUom === prevUom

      qty = sameUnit && isReplacement ? prevQty : 1

      linkable = isCostItem && currLinkable && (prevLinked || !prevLinkable) ? 1 : 0

      const equationField =
        prevType === 'cost_item' || prevType === 'cost_type'
          ? 'cost_type_qty_equation'
          : 'assembly_qty_equation'

      const targetMt = c.getMeasureTypeForUnitOfMeasure(prevUom)
      const objectMt = c.getMeasureTypeForUnitOfMeasure(currUom)
      const compatibleMeasures = objectMt === targetMt

      let equation = null
      // get overridden equation

      const field = !isCostItem ? 'assembly_qty_equation' : 'cost_type_qty_equation'
      const overrideEq = this.addon.embue[field]
      if (overrideEq) {
        equation = overrideEq
      } else if (!compatibleMeasures && object[equationField]) {
        equation = object[equationField]
      } else if (compatibleMeasures && isCostItem && target[equationField]) {
        equation = target[equationField]
      }

      // No equation found, convert the qty directly
      if (!equation && compatibleMeasures) {
        // requires conversion
        const amt = target[targetQtyField]
        const from = prevUom
        const to = currUom
        const conv = c.convertMeasure(amt, from, to)
        qty = conv === false ? amt : conv
      }

      return {
        qty,
        linkable,
        equation
      }
    },

    savePic() {
      if (!this.addon.id) return Promise.resolve()
      return this.$store.dispatch(`${c.titleCase(this.addon.type)}/partialUpdate`, {
        alert: false,
        go: false,
        selected: [
          {
            type: this.addon.type,
            [`${this.addon.type}_id`]: this.addon.id,
            file_id: this.addon.file_id
          }
        ]
      })
    },
    fileAdded(files = []) {
      if (Array.isArray(files) && files.length && files[0] && files[0].file_id) {
        this.emitAddonValue({
          file_ids: files[0].file_id
        })
      } else {
        this.emitAddonValue({
          file_ids: null
        })
      }
    },
    setEmbueValue(key, value, addonKey = null) {
      if (value === null) {
        // eslint-disable-next-line no-unused-vars
        const { [key]: omit, ...rest } = this.addon.embue
        this.emitAddonValue({
          ...(addonKey && value !== null ? { [addonKey]: null } : {}),
          embue: rest
        })
      } else {
        this.emitAddonValue({
          ...(addonKey && value !== null ? { [addonKey]: value } : {}),
          embue: {
            ...this.addon.embue,
            [key]: value
          }
        })
      }

      c.throttle(() => this.audit(), { delay: 1000 })
    },
    async setOverrideMarkup(overrideMarkup) {
      const type = this.object.type
      const unsetting = overrideMarkup === false || overrideMarkup === null

      if (type === 'assembly' && !unsetting) {
        const { changes } = await this.$store.dispatch('Quote/setTargetMarkup', {
          object: { ...this.auditedObject, ...this.addon.embue },
          targetMarkup: overrideMarkup
        })

        return this.setEmbue(
          {
            ...this.addon.embue,
            ...changes
          },
          {
            markup: overrideMarkup,
            hasOverriddenMarkup: true
          }
        )
      } else if (type === 'assembly' && unsetting) {
        const {
          // eslint-disable-next-line no-unused-vars
          assembly_markup_percentage_adjustment: omit,
          // eslint-disable-next-line no-unused-vars
          quote_markup_percentage_adjustment: omit1,
          ...rest
        } = this.addon.embue
        return this.setEmbue(rest, {
          hasOverriddenMarkup: false
        })
      } else if (unsetting) {
        const {
          // eslint-disable-next-line no-unused-vars
          cost_matrix_markup_net: omit,
          // eslint-disable-next-line no-unused-vars
          cost_item_markup_net_adjusted: omit2,
          ...rest
        } = this.addon.embue
        return this.setEmbue(rest, {
          hasOverriddenMarkup: false
        })
      }

      return this.setEmbue(
        {
          ...this.addon.embue,
          cost_item_markup_net_adjusted: overrideMarkup
        },
        {
          markup: overrideMarkup,
          hasOverriddenMarkup: true
        }
      )
    },
    setEmbue(embue = {}, addonEmbue = {}) {
      this.emitAddonValue({ ...addonEmbue, embue })

      c.throttle(() => this.audit(), { delay: 1000 })
    },
    emitAddonValue(toEmbue = {}) {
      this.$emit('addon', { ...this.addon, ...toEmbue })
    },
    addFiles(files) {
      this.$store.dispatch('CostType/addChildren', {
        refId: this.targetRefId,
        children: files,
        field: 'aoFiles'
      })
      eventBus.$emit('changing', {
        refId: this.targetRefId
      })
    },
    get() {
      this.loading = 1
      this.$nextTick(() => {
        this.$emit('get', this.auditedObject, this.addon)
      })
      setTimeout(() => {
        this.loading = 0
      }, 4000)
    },

    async getAdjustedUpgradePrice(object, original = this.object) {
      const originalAddon = original
      let adjustments = {}

      if (object.type === 'assembly') {
        const parentRef = this.target.parentRefId
        const parent = this.$store.state[this.store].normalized[parentRef]
        const d = _.n(originalAddon.quote_price_net) / _.n(originalAddon.quote_total_cost_net)
        const m = _.n(object.quote_markup_net)
        const q = _.n(parent.quote_markup_percentage_adjustment)

        // d = ((a + q + aq) * (m - 1)) + m
        // d = (a + q + aq)(m - 1) + m
        // d - m = (a + q + aq)(m - 1)
        // (d - m) / (m - 1) = (a + q + aq)
        // (d - m) / (m - 1) = (a + q + aq)
        // ((d - m) / (m - 1)) - q = a + aq
        // ((d - m) / (m - 1)) - q = a(1 + q)
        // (((d - m) / (m - 1)) - q) / (1 + q) = a
        const a = ((d - m) / (m - 1 || 0.000000001) - q) / (1 + q || 0.000000001)

        adjustments = {
          assembly_markup_percentage_adjustment: a
        }
      } else {
        // Shouldn't need cost_matrix_rate_net_index, however a back-end bug needs to be fixed
        // this is launched quickly to get it out
        const originalUnitPrice =
          originalAddon.cost_matrix_rate_net || originalAddon.cost_matrix_rate_net_index
        const { changes } = await this.$store.dispatch('CostType/setPrice', {
          store: 'CostItem',
          price: originalUnitPrice,
          object
        })

        adjustments = changes
      }

      return adjustments
    },

    rebuildAddonFromAudited() {
      this.emitAddonValue({
        ...this.addon,
        ...QuoteAddons.getAddonFromObject({
          target: this.target,
          auditedObject: this.auditedObject,
          addonType: this.addonType,
          preventIf: this.preventIf,
          addon: this.addon
        })
      })
    },

    async audit() {
      this.loading += 1

      if (!this.object || !Object.keys(this.object).length) await this.fetch()

      this.auditedObject = await QuoteAddons.auditForAddon({
        store: this.store,
        dispatch: this.$store.dispatch,
        getters: this.$store.getters,
        norm: this.$store.state[this.store].normalized,
        fetched: this.object,
        addon: this.addon,
        refId: this.targetRefId
      })

      this.rebuildAddonFromAudited()
      this.loading -= 1
    },

    async reload() {
      return this.fetch(true)
    },

    async fetch(force = false, audit = true) {
      // this.loading = 1;
      this.fetching = 1

      try {
        let object = null

        if (this.addon.bulk) {
          // prefer bulk
          object = this.addon.bulk
        } else {
          let fetched
          if (this.addon.livePriceRef) {
            const root = c.getNormalizedRootRefId(this.$store.state[this.store].normalized)
            const rootObject = this.$store.state[this.store].normalized?.[root]
            const company = this.$store.state.session.company
            if (!company || !root || !rootObject)
              return this.$store.dispatch('alert', {
                error: true,
                message: `Quote context not found for ${this.addon.livePriceRef}, skipping addon`
              })
            const zipcode = AutoCost.getAutoCostZipcode(company, rootObject)

            fetched = await this.$store.dispatch('CostType/getDefaultLivePriceItem', {
              livePriceRef: this.addon.livePriceRef,
              company,
              zipcode
            })
          } else {
            const { object } = await this.$store.dispatch('Quote/getAddon', {
              type: this.addon.type === 'assembly' ? 'assembly' : 'cost_type',
              id: this.addon.id,
              force
            })
            fetched = object
          }

          object = fetched
        }

        if (object) {
          await this.setObject(object, audit)
          return object
        }

        this.disabled = true
        return false
      } catch (e) {
        this.disabled = true
        this.$emit('disabled')

        throw e
      } finally {
        this.loading = 0
        this.fetching = 0
      }
    }
  }
}
