<template>
  <CardListItem class="filefromid--container">
    <div class="filefromid--container-inner" v-if="object">
      <div class="filefromid--title">
        <File :object="object" :showLabel="false" style="margin: 0 !important" />

        <strong class="text-muted">
          {{ object.file_name }}
        </strong>
      </div>

      <div class="filefromid--actions">
        <SuggestionButton class="sm info" @click="preview"> Preview </SuggestionButton>

        <SuggestionButton class="sm info" @click="download"> Download </SuggestionButton>
      </div>
    </div>
    <LoadingIndicator v-else />
  </CardListItem>
</template>

<script>
import LoadingIndicator from '../ui/LoadingIndicator.vue'
import SuggestionButton from '../ui/SuggestionButton.vue'
import File from './File.vue'

export default {
  name: 'FileFromId',
  components: { File, SuggestionButton, LoadingIndicator },

  async mounted() {
    const { object } = await this.$store.dispatch('File/fetch', {
      id: this.id
    })

    this.object = object
  },

  methods: {
    preview() {
      return this.$store.dispatch('edit', {
        type: 'file',
        id: this.id
      })
    },
    download() {
      return this.$store.dispatch('File/download', {
        id: this.id,
        object: this.object
      })
    }
  },

  data() {
    return {
      object: null
    }
  },

  props: {
    id: {
      required: true
    }
  }
}
</script>

<style scoped>
.filefromid--container {
  white-space: normal;
  width: 100%;
}

.filefromid--container-inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filefromid--actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filefromid--actions > * + * {
  margin-top: 0.15em;
}

.filefromid--title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.5em 0;
}
</style>
