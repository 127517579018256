<template>
  <AddressForm
    v-model="counterparty[reference].address"
    :errors="errors"
    :required="true"
    :namespace="reference"
  />
</template>
<script>
import AddressForm from '../../../ui/forms/AddressForm.vue'

export default {
  name: 'CounterpartyOnboardingAddress',
  components: {
    AddressForm
  },
  data() {
    return {}
  },
  methods: {
    validateAddress(address) {
      let error = null
      // Validate USA zip codes
      if (address.country && address.country.toUpperCase() === 'US') {
        let first = true
        const errorMsg =
          'Invalid ZIP code. ZIP codes must either be of the format 12345 or 12345-1234'
        address.postal_code.split('-').forEach((zip) => {
          // Contains non-numbers
          if (!/^\d+$/.test(zip)) {
            error = errorMsg
          }
          // First part is not 5 digits
          if (first && zip.length !== 5) {
            error = errorMsg
          }
          // Second part (if it exists) is not 4 digits
          if (!first && zip.length !== 4) {
            error = errorMsg
          }
          first = false
        })
      }
      // Validate CA postal codes
      else if (address.country && address.country.toUpperCase() === 'CA') {
        const errorMsg =
          'Invalid postal code. Postal codes must be of the format A1A 1A1. Separated by a space, with only capital letters.'
        // Postal code doesn't match A1A 1A1
        if (!/^[A-Z]\d[A-Z][ ]?\d[A-Z]\d$/.test(address.postal_code)) {
          error = errorMsg
        }
      }
      return error
    }
  },
  props: {
    counterparty: {
      type: Object,
      default: () => {}
    },
    reference: {
      type: String,
      default: 'company'
    },
    errors: {
      type: Array
    }
  }
}
</script>
