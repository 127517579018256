<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    size="md"
    :width="800"
    ref="modal"
    :scrollable="true"
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #header>{{ $t('Upgrade details') }}</template>
    <template #body>
      <!--   Cost item name   -->
      <div class="text-base text-surface-500">{{ parentName }}</div>
      <div class="text-2xl font-medium">{{ itemName }}</div>
      <div class="font-medium text-xl">{{ qty }} {{ units }}</div>
      <div class="relative grid grid-cols-1 md:grid-cols-4 gap-6 mt-6">
        <!--   Left     -->
        <div class="flex flex-col justify-start items-start col-span-2 max-w-full">
          <!--    Image gallery    -->
          <div v-if="pictures.length" class="overflow-hidden w-full">
            <Galleria
              :images="pictures"
              :numVisible="thumbnailNumber"
              thumbnailsPosition="bottom"
              hideArrows="true"
              :pt="{
                thumbnailContainer: 'w-fit pt-3 ',
                thumbnailItem: 'overflow-hidden pr-2 w-fit',
                thumbnailItemContent: 'w-fit'
              }"
            />
          </div>
          <div
            v-else
            class="flex justify-center items-center rounded-md w-full bg-surface-200 h-[350px]"
          >
            <font-awesome-icon :icon="['fas', 'cube']" class="text-7xl text-surface-300" />
          </div>
        </div>
        <!--    Right    -->
        <div class="col-span-2">
          <div class="text-lg font-medium mb-3">{{ $t('Description') }}</div>
          <p v-if="description" class="font-light">{{ description }}</p>
          <p v-else class="font-light">{{ $t('No description available') }}.</p>
          <div
            class="mt-4"
            v-if="properties && properties.length && properties[0] && properties[0][0]"
          >
            <span class="font-medium">{{ $t('Features') }}</span>
            <PresentationProperties :object="object" />
          </div>
          <p class="text-xs py-4 text-pitch-black-500 font-light">
            {{
              $t(
                'Upgrade availability may vary according to individual project circumstances.Pictures are examples and may not exactly represent how it appears on your project.'
              )
            }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <!--     CTA     -->
      <div class="flex flex-col lg:flex-row gap-6 justify-end mt-[28px] w-full h-full">
        <Btn
          class="w-full md:w-72 p-4 flex flex-wrap items-center"
          severity="bolster"
          size="item-price"
          @click="handleSelection"
          :disabled="loading"
        >
          {{ $t('Select this') }}
          <span class="text-xs text-flame-white">
            ({{ priceAction }} ${{ $f.currency(priceTag) }})</span
          >
        </Btn>
        <Btn
          class="w-full md:w-72 p-4 h-full"
          severity="tertiary"
          :size="'item-price'"
          @click="handleClose"
        >
          <span>{{ $t('Cancel') }}</span>
        </Btn>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import AddonListMixin from '@/components/quote/addons/AddonListMixin'
import PresentationProperties from '@/components/quote/presentation/PresentationProperties.vue'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import { useMediaQuery } from '@/composables/mediaQuery'

export default {
  name: 'UpgradeDetails',
  mixins: [ObjectModalMixin, AddonListMixin],
  components: { PresentationProperties, Galleria },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const { modal } = toRefs(props)
    const { smallFormat } = useMediaQuery()
    const parentName = computed(() => modal.value.parentName)
    const itemName = computed(() => modal.value.itemName)
    const description = computed(() => modal.value.description)
    const pictures = computed(() => modal.value.urls)
    const object = computed(() => modal.value.object)
    const properties = computed(() => modal.value.properties)
    const priceTag = computed(() => modal.value.priceTag)
    const priceAction = computed(() => modal.value.priceAction)
    const addon = computed(() => modal.value.addon)
    const loading = ref(false)

    const handleClose = async () => {
      await store.dispatch('modal/closeAll')
    }

    const handleSelection = async () => {
      loading.value = true
      await store.dispatch('Quote/selectAddon', {
        addonType: addon.value.livePriceRef ? 'live_price' : addon.value.type,
        addonId: addon.value.livePriceRef ?? addon.value.id,
        refId: modal.value.refId
      })
      loading.value = false
      await handleClose()
    }

    const units = computed(() => {
      return object.value.type === 'cost_item' ? object.value.unit_of_measure_abbr : 'each'
    })

    const qty = computed(() => {
      const parent = store.state.Quote.normalized[modal.value.refId]
      return parent?.cost_item_qty_net || parent?.quote_qty_net || 0
    })

    const thumbnailNumber = computed(() => {
      if (pictures.value.length === 1) return 1
      if (smallFormat.value) return 4
      return pictures.value.length > 7 ? 7 : pictures.value.length
    })

    return {
      handleClose,
      handleSelection,
      units,
      qty,
      parentName,
      itemName,
      description,
      object,
      properties,
      priceTag,
      pictures,
      loading,
      priceAction,
      smallFormat,
      thumbnailNumber
    }
  }
}
</script>
