<template>
  <div
    v-if="canShowPipeline"
    class="pipeline--outer-container items-center basis-full w-full overflow-y-clip pb-6 px-safe-4 lg:px-8"
  >
    <PageHeader title="Pipeline" class="max-w-full">
      <template #left>
        <Btn rounded link class="text-md hidden md:inline-flex" :action="() => reload(true)">
          <font-awesome-icon icon="arrows-rotate" v-tooltip="'Reload table...'" />
        </Btn>
      </template>
      <template #right>
        <div class="flex justify-center items-center gap-4 h-fit max-h-fit">
          <!-- Search -->
          <IconField iconPosition="left" class="hidden md:inline">
            <InputIcon>
              <icon icon="search" class="text-surface-800" />
            </InputIcon>
            <InputText
              v-model="searchPhraseLocal"
              placeholder="Search your pipeline..."
              size="large"
              type="text"
            />
          </IconField>

          <owner-filter
            class="hidden xl:block"
            schema="pipeline:manager_user_ids"
            v-model="owner"
          />

          <group-filter class="hidden xl:block" schema="pipeline:location_ids" v-model="group" />

          <!-- Create new -->
          <Btn
            size="lg"
            severity="primary-black"
            v-tooltip="'Create something great...'"
            class="text-nowrap"
            @click="createNew"
          >
            <icon :icon="['fas', 'plus']" />
            <span class="hidden md:inline"> Create new </span>
          </Btn>
        </div>

        <Btn :action="toggleFilters" severity="tertiary" class="md:hidden max-w-10" size="lg">
          <font-awesome-icon icon="filter-list" size="lg" />
        </Btn>
      </template>
    </PageHeader>

    <!-- Filters -->
    <div
      v-show="showFilters"
      class="xl:hidden flex flex-row justify-end items-center gap-x-4 mb-4 w-full"
    >
      <owner-filter schema="pipeline:manager_user_ids" v-model="owner" />

      <group-filter schema="pipeline:location_ids" v-model="group" />
    </div>

    <grid
      v-if="showAs === 'pipeline'"
      class="w-full min-w-full"
      type="pipeline"
      :continuousLoading="false"
      :limit="1000"
      ref="grid"
      :permanentFilters="permanentFilters"
      :filters="localFilters"
      :doubleClickToOpen="false"
      :isMain="false"
      :showToolbar="false"
      :showFilters="false"
      :searchMethod="searchMethod"
      :searchPhrase="searchPhraseLocal"
      @loading="(v) => (loading = v)"
      @search-phrase="(v) => (searchPhraseLocal = v)"
      @fetched="addLoaded"
      @dblclick="dblclick"
      @click="singleClick"
      @tap="singleClick"
    >
      <template #customView>
        <div class="hidden md:flex flex-row flex-nowrap gap-6 overflow-x-auto">
          <a
            v-if="columnsShown.length < categorySchema.length - startColumn && cursorColumn > 0"
            @click="cursorColumn -= 1"
            class="pipeline--scroll-column pipeline--scroll-column-left"
          >
            <div class="more more-primary">
              <font-awesome-icon icon="chevron-left" />
            </div>
            <span class="text">
              {{ categorySchema[cursorColumn + startColumn - 1].title }}
            </span>
          </a>
          <div
            v-for="(cat, colIndex) in filteredCategories"
            :key="colIndex"
            :class="cat.class"
            class="w-[20%] min-w-48"
          >
            <btn-bar
              class="pipeline--column-actions block"
              :showButton="false"
              :go="false"
              :actions="cat.actions"
            >
              <template #button>
                <div
                  class="pipeline--header font-sans"
                  :id="cat.title.replace(' ', '_').toLowerCase()"
                >
                  <div class="w-full flex justify-between items-center">
                    <div>{{ cat.title }}</div>
                    <div class="flex justify-center font-light items-center mt-2">
                      <font-awesome-icon
                        class="ml-1 text-cool-gray-500 pb-2"
                        icon="fa-solid fa-ellipsis"
                      />
                    </div>
                  </div>
                  <div v-if="cat.total" class="w-full text-[13px] flex justify-between">
                    <span>Total: {{ cat.total() }}</span>
                    <span v-if="cat.value">Value: {{ cat.value() }}</span>
                  </div>
                </div>
              </template>
            </btn-bar>
            <div
              class="pipeline--category-bundle !mt-8 p-2"
              :class="cat.categories.some((item) => item.objects.length > 0) ? '' : 'empty'"
            >
              <template v-if="typeAccessor[cat.pseudoType] && typeAccessor[cat.pseudoType].length">
                <div class="pipeline--list">
                  <template
                    v-for="(item, itemIndex) in cat.categories"
                    :key="`${itemIndex}-${update}`"
                  >
                    <template v-if="item.objects.length > 0">
                      <div class="pipeline--category" :class="{ highlighted: item.highlighted }">
                        <div class="pipeline--category-header border-b border-cool-gray-500">
                          <template v-if="item.actions">
                            <btn-bar
                              class="block"
                              style="display: flex; width: 100%; justify-content: stretch"
                              :actions="item.actions"
                              :showButton="false"
                              :go="false"
                            >
                              <template #button>
                                <a
                                  tabindex
                                  class="flex justify-between items-center !text-pitch-black"
                                >
                                  {{ item.title }}
                                  <font-awesome-icon class="ml-2" icon="fa-solid fa-ellipsis" />
                                </a>
                              </template>
                            </btn-bar>
                          </template>
                          <template v-else>
                            {{ item.title }}
                          </template>
                        </div>
                      </div>
                    </template>
                    <template
                      v-for="(record, recordIndex) in item.displayObjects"
                      :key="recordIndex"
                    >
                      <div
                        class="pipeline--item"
                        :id="`${record.pipeline_object_type}-${record.pipeline_object_id}`"
                        @click="singleClick"
                        :class="{
                          highlighted: record.highlighted,
                          'category-last':
                            !item.objects[recordIndex + 1] || item.objects[recordIndex + 1].category
                        }"
                      >
                        <component
                          class="pipeline--item-preview"
                          :avatar="false"
                          :steps="false"
                          :right="false"
                          :middle="false"
                          :is="record.pipeline_object_type"
                          :object="record.oObject"
                        />
                        <div class="pipeline--item-overlay">
                          <btn
                            link
                            size="small"
                            class="whitespace-spaces flex items-start h-full basis-1/3 shrink-0"
                            @click="open(record, $event)"
                          >
                            <div
                              class="flex flex-col items-center justify-center gap-1 overlay-contents h-full w-full whitespace-break-spaces"
                            >
                              <font-awesome-icon icon="arrow-up-right" />
                              <span class="overlay-btn-text">Open</span>
                            </div>
                          </btn>
                          <btn
                            link
                            size="small"
                            class="whitespace-break-spaces flex items-center justify-center h-full basis-1/3 shrink-0"
                            @click="view(record, $event)"
                          >
                            <div
                              class="flex flex-col items-center justify-center gap-1 overlay-contents h-full w-full whitespace-break-spaces"
                            >
                              <font-awesome-icon icon="table-list" />
                              <span class="overlay-btn-text">View in list</span>
                            </div>
                          </btn>
                          <btn
                            link
                            size="small"
                            class="whitespace-break-spaces flex items-center justify-center h-full basis-1/3 shrink-0 !p-0"
                            @click="transform(record, $event)"
                          >
                            <div
                              class="flex flex-col items-center justify-center gap-1 overlay-contents whitespace-break-spaces overflow-hidden"
                            >
                              <font-awesome-icon icon="arrow-turn-down-right" />
                              <span class="overlay-btn-text whitespace-break-spaces">{{
                                record.transformTitle
                              }}</span>
                            </div>
                          </btn>
                        </div>
                      </div>
                    </template>
                    <template v-if="item.objects.length > item.displayObjects.length">
                      <div class="pipeline--category" :class="{ highlighted: item.highlighted }">
                        <div
                          class="pipeline--category-show-all category-last"
                          @click="showMoreItemObjects(item)"
                        >
                          <a>Show {{ item.objects.length - item.displayObjects.length }} more</a>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="pipeline--category pipeline--category-empty" v-if="!loading">
                  <span class="text">
                    {{ cat.empty.message }}
                  </span>
                  <div class="flex flex-col gap-2">
                    <Btn
                      v-for="action in cat.empty.actions"
                      :key="action"
                      severity="tertiary"
                      size="sm"
                      class="w-full"
                      @click="action.action"
                    >
                      <Icon :icon="action.icon" />
                      {{ action.title }}
                    </Btn>
                  </div>
                </div>
                <Skeleton v-else height="50em" width="100%" />
              </template>
            </div>
            <div class="pipeline--category-buttons">
              <btn
                link
                size="small"
                class="w-full"
                v-for="preset in cat.listPresets"
                :key="preset.title"
                @click="goToPreset(preset.page || cat.listPage, preset)"
              >
                {{ preset.title }}
              </btn>
            </div>
          </div>
          <a
            @click="cursorColumn += 1"
            class="pipeline--scroll-column pipeline-scroll-column-right"
            v-if="
              columnsShown.length < categorySchema.length - startColumn &&
              cursorColumn + columnsShown.length + startColumn < categorySchema.length
            "
          >
            <div class="more more-primary">
              <font-awesome-icon icon="chevron-right" />
            </div>
            <span class="text">
              {{ categorySchema[cursorColumn + columnsShown.length + startColumn].title }}
            </span>
          </a>
        </div>
        <div class="small-pipeline flex md:hidden flex-col gap-y-4 w-full">
          <div
            v-for="(cat, colIndex) in filteredCategories"
            :key="colIndex"
            class="p-4 pr-1 border rounded flex flex-row items-center justify-between"
            :class="cat.pseudoType"
            @click="() => goView(cat.pseudoType)"
          >
            <div class="flex flex-col">
              <div class="text-xl font-medium">{{ cat.title }}</div>
              <div class="text-sm flex flex-row items-center gap-x-12">
                <span>Total: {{ cat.total() }}</span>
                <span v-if="cat.value">Value: {{ cat.value() }}</span>
              </div>
            </div>
            <div class="text-pitch-black-300">
              <font-awesome-icon icon="chevron-right" size="xl" fixed-width />
            </div>
          </div>
        </div>
      </template>
      <template #emptyLoading>
        <h3>Just loading up your pipeline...</h3>
        <spin-container :loading="1" />
      </template>
      <template #emptySearching>
        <h3>
          Hmm, we weren't able to find anything with "{{
            grid && grid.searchPhrase ? grid.searchPhrase : 'that search criteria'
          }}"
        </h3>
        <btn class="btn btn-outline-warning" @click="searchPhraseLocal = ''"> Clear search? </btn>
      </template>
      <template #empty></template>
    </grid>
  </div>
  <div v-else-if="!hasPermissionsToSeePipeline">
    <UISection>
      <Container>
        <CardSection>
          <Warning>
            <template #title>You do not have permissions to view the pipeline.</template>
            <p>Request "client viewing" permissions from your company's administrator.</p>
          </Warning>
        </CardSection>
      </Container>
    </UISection>
  </div>
  <LoadingIndicator v-else />
</template>

<script>
import Client from '@/components/ui/Previews/Client.vue'
import Quote from '@/components/ui/Previews/Quote.vue'
import Invoice from '@/components/ui/Previews/Invoice.vue'
import UserMeta from '@/components/mixins/UserMeta'
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'
import SearchField from '../ui/SearchField.vue'
import OwnerFilter from '../ui/filters/Owner.vue'
import GroupFilter from '../ui/filters/Group.vue'
import AcceptTerms from '../legal/AcceptTerms.vue'
import Milestones from '../mixins/Milestones'
import LoadingIndicator from '../ui/LoadingIndicator.vue'
import SuggestionButton from '../ui/SuggestionButton.vue'
import UISection from '../ui/Section.vue'
import moment from 'moment'
import eventBus from '../../eventBus'
import PageHeader from '@/components/layout/page/PageHeader.vue'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import InputIcon from 'primevue/inputicon'
import { getQuotesAwaitingPaymentStatesFromApprovals } from '@/apollo-client/requests/approval'

InputText.compatConfig = { MODE: 3 }

const startOfWeek = moment().startOf('week').format('x')
const startOfMonth = moment().startOf('month').format('x')
const startOfDay = moment().startOf('day').format('x')
const days90 = moment().subtract(90, 'days').format('x')
const days60 = moment().subtract(60, 'days').format('x')
const days30 = moment().subtract(30, 'days').format('x')
const days2 = moment().subtract(2, 'days').format('x')
const currentTime = new Date().getTime()

export default {
  mixins: [BodyMixin, UserMeta, Milestones],
  name: 'PipelineBody',
  emits: ['empty', 'notEmpty'],
  inject: {
    createDongle: {
      from: 'CreateDongle'
    }
  },
  data() {
    const def = _.getStorage('defaultPipelineOwner')
    const defg = _.getStorage('defaultPipelineGroup')
    const filters = {}
    if (def) {
      filters.manager_user_ids = c.makeArray(def).join('||')
    }
    if (defg) {
      filters.location_ids = c.makeArray(defg).join('||')
    }
    return {
      update: 0,
      localFilters: filters,
      dayLabelOffset: 65,
      set: [],
      pendingPayment: {},
      activeNode: null,
      canvasWidth: 500,
      minWidth: 190,
      cursorColumn: 0,
      resizeObserver: null,
      searchPhraseLocal: this.searchPhrase,
      loading: 0,
      pipelineItemDisplayLimit: this.$store.state.session.defaultPipelineItemDisplayLimit,
      showAs: 'pipeline',
      categorySchema: [
        {
          title: 'Leads',
          icon: 'person-circle-plus',
          total: () => `${this.lead.length + this.client.length}`,
          class: 'pipeline--lead',
          transformTitle: 'Build estimate',
          pseudoType: 'lead',
          canCreate: true,
          listPage: '/leads',
          globalCount: this.$store.state.session.user.oCounts.leads,
          empty: {
            message:
              'No leads yet.  Create a new lead, or add a webform to your website to funnel leads here automatically.',
            actions: [
              {
                class: 'xs',
                title: 'Add lead',
                icon: 'house-flag',
                action: this.newLead
              },
              {
                class: 'xs',
                title: 'Lead form',
                icon: 'share-nodes',
                route: 'lead_rotations'
              }
            ]
          },
          listPresets: [
            {
              title: 'See all leads',
              description: 'All leads.',
              filters: {
                client_status: 'l'
              },
              filterText: {
                client_status: 'Lead'
              }
            }
          ],
          listButtons: [
            {
              title: 'Abandoned leads',
              action: () =>
                this.$store.dispatch('view', {
                  type: 'client',
                  filters: {
                    client_status: 'i',
                    client_time_active: 'NULL||0'
                  },
                  filterText: {
                    client_status: 'Inactive',
                    client_time_active: 'Never activated'
                  }
                })
            }
          ],
          categories: [
            {
              title: 'Fresh leads',
              filter: (o) => (o.oObject.client_time_active || o.oObject.client_time_created) > days2
            },
            {
              title: 'Stale leads',
              filter: (o) =>
                (o.oObject.client_time_active || o.oObject.client_time_created) <= days2
            }
          ]
        },
        {
          title: 'Estimates',
          icon: 'file-signature',
          total: () => `${this.pending.length}`,
          value: () => `${this.formatValueKorM(this.pendingSum)}`,
          pseudoType: 'pending',
          globalCount: this.$store.state.session.user.oCounts.quotes_pending,
          class: 'pipeline--pending',
          transformTitle: 'Mark as booked',
          canCreate: true,
          listPage: '/proposals',
          empty: {
            message: 'No pending proposals.  Build a quote to see it in this list.',
            actions: [
              {
                class: 'xs',
                title: 'New estimate',
                icon: 'file-signature',
                action: this.newPending
              }
            ]
          },
          listPresets: [
            {
              title: 'See all estimates',
              description: 'Proposals that have not yet been accepted by your client.',
              filters: {
                quote_status: 'p'
              },
              filterText: {
                quote_status: 'Pending'
              }
            }
            // {
            //   title: 'Declined proposals',
            //   description: 'Proposals that have been declined by your client.',
            //   filters: {
            //     quote_status: 'd',
            //   },
            //   filterText: {
            //     quote_status: 'Declined',
            //   },
            // },
            // {
            //   title: 'Expired proposals',
            //   description: 'Proposals that have been expired.',
            //   filters: {
            //     quote_time_expired: `>0&&<${currentTime}`,
            //     quote_status: 'p',
            //   },
            //   filterText: {
            //     quote_time_expired: 'Expired',
            //   },
            // },
          ],
          listButtons: [
            {
              title: 'Declined proposals',
              action: () =>
                this.$store.dispatch('view', {
                  type: 'quote',
                  filters: {
                    quote_status: 'd'
                  },
                  filterText: {
                    quote_status: 'Declined'
                  },
                  delay: 0
                })
            },
            {
              title: 'All proposals',
              action: () =>
                this.$store.dispatch('view', {
                  type: 'quote',
                  filters: {
                    quote_status: 'd,k,f,h,p'
                  },
                  filterText: {
                    quote_status: 'Any'
                  },
                  delay: 0
                })
            }
          ],
          categories: [
            {
              title: 'Expired recently',
              highlighted: false,
              filter: (o) =>
                o.oObject.quote_time_expired > 0 && o.oObject.quote_time_expired < currentTime
            },
            {
              title: 'Needs your approval',
              highlighted: true,
              filter: (o) =>
                o.oObject.quote_status === 'p' &&
                !o.oObject.change_order_company_has_approved &&
                ((o.oObject.change_order_creator !==
                  `user-${this.$store.state.session.user.user_id}` &&
                  this.$store.state.session.user.aUserPerms.quote.approve > 1) ||
                  (o.oObject.change_order_creator ===
                    `user-${this.$store.state.session.user.user_id}` &&
                    this.$store.state.session.user.aUserPerms.quote.approve > 0)),
              actions: [
                {
                  title: 'Approve all without viewing..',
                  icon: 'check',
                  action: this.approveAll
                }
              ]
            },
            {
              title: 'Waiting for approval',
              highlighted: true,
              filter: (o) =>
                !o.oObject.change_order_company_has_approved &&
                this.$store.state.session.user.aUserPerms.quote.approve < 2 &&
                o.oObject.quote_status === 'p'
            },
            {
              title: 'Bid requests',
              highlighted: true,
              filter: (o) =>
                o.oObject.quote_is_bid &&
                !o.oObject.quote_time_sent &&
                o.oObject.quote_status === 'p'
            },
            {
              title: 'New bids received',
              highlighted: true,
              filter: (o) => o.oObject.quote_count_bids_queued && o.oObject.quote_status === 'p'
            },
            {
              title: 'Never sent',
              highlighted: true,
              filter: (o) => !o.oObject.quote_time_sent && o.oObject.quote_status === 'p',
              actions: [
                {
                  title: 'Send all..',
                  icon: 'paper-plane',
                  action: this.sendUnsentQuotes
                }
              ]
            },
            {
              title: 'Sent but never viewed',
              highlighted: true,
              filter: (o) =>
                o.oObject.quote_time_sent &&
                !o.oObject.quote_time_seen &&
                o.oObject.quote_status === 'p',
              actions: [
                {
                  title: 'Resend all..',
                  icon: 'paper-plane',
                  action: this.sendUnseenQuotes
                }
              ]
            },
            {
              title: 'Declined recently',
              filter: (o) => o.oObject.quote_status === 'd'
            },
            {
              title: 'Client viewed today',
              filter: (o) => o.oObject.quote_time_seen >= startOfDay
            },
            {
              title: 'Client viewed this week',
              filter: (o) => o.oObject.quote_time_seen >= startOfWeek,
              actions: [
                {
                  title: 'Resend all..',
                  icon: 'paper-plane',
                  action: this.sendQuotesThisWeek
                }
              ]
            },
            {
              title: 'Followed-up this week',
              filter: (o) => o.oObject.quote_last_followup_time >= startOfWeek
            },
            {
              title: 'Needs follow-up this week',
              highlighted: true,
              actions: [
                {
                  title: 'Send follow-up email..',
                  icon: 'paper-plane',
                  action: this.sendQuotesOlderThanWeek
                },
                {
                  title: 'Set all to declined..',
                  icon: 'user-slash',
                  action: this.declineQuotesOlderThanWeek
                }
              ]
            }
          ]
        },
        {
          title: 'Booked projects',
          icon: 'house-building',
          total: () => `${this.booked.length}`,
          value: () => `${this.formatValueKorM(this.bookedSum)}`,
          pseudoType: 'booked',
          globalCount: this.$store.state.session.user.oCounts.quotes_booked,
          class: 'pipeline--booked',
          listPage: '/projects',
          transformTitle: 'Mark as in-progress',
          empty: {
            message: "No booked projects. Mark a pending quote as 'booked' to see it in this list.",
            actions: []
          },
          listPresets: [
            {
              page: '/projects',
              title: 'See all projects',
              description: 'Projects that have been finished and closed.',
              filters: {
                quote_status: 'b'
              },
              filterText: {
                quote_status: 'Booked'
              }
            }
            // {
            //   page: '/quotes',
            //   title: 'Completed projects',
            //   description: 'Projects that have been finished and closed.',
            //   filters: {
            //     quote_status: 'g',
            //   },
            //   filterText: {
            //     quote_status: 'Closed',
            //   },
            // },
          ],
          listButtons: [
            {
              title: 'Completed projects',
              action: () =>
                this.$store.dispatch('view', {
                  type: 'quote',
                  filters: {
                    quote_status: 'g'
                  },
                  filterText: {
                    quote_status: 'Closed'
                  },
                  delay: 0
                })
            }
          ],
          categories: [
            {
              title: 'Needs your approval',
              highlighted: true,
              filter: (o) =>
                !o.oObject.change_order_company_has_approved &&
                ((o.oObject.change_order_creator !==
                  `user-${this.$store.state.session.user.user_id}` &&
                  this.$store.state.session.user.aUserPerms.quote.approve > 1) ||
                  (o.oObject.change_order_creator ===
                    `user-${this.$store.state.session.user.user_id}` &&
                    this.$store.state.session.user.aUserPerms.quote.approve > 0)),
              actions: [
                {
                  title: 'Approve all changes without viewing..',
                  icon: 'check',
                  action: this.approveAllBooked
                }
              ]
            },
            {
              title: 'Changes waiting for approval',
              highlighted: true,
              filter: (o) =>
                !o.oObject.change_order_company_has_approved &&
                this.$store.state.session.user.aUserPerms.quote.approve < 2
            },
            {
              title: 'New bids need review',
              highlighted: true,
              filter: (o) => o.oObject.quote_count_bids_queued > 0
            },
            {
              title: 'Today',
              filter: (o) => o.oObject.quote_time_booked >= startOfDay
            },
            {
              title: 'This week',
              filter: (o) => o.oObject.quote_time_booked >= startOfWeek
            },
            {
              title: 'This month',
              filter: (o) => o.oObject.quote_time_booked >= startOfMonth
            },
            {
              title: 'Older than a month'
            }
          ]
        },
        {
          title: 'In-progress',
          icon: 'house-building',
          total: () => `${this.project.length}`,
          value: () => `${this.formatValueKorM(this.projectSum)}`,
          pseudoType: 'project',
          listPage: '/projects',
          globalCount: this.$store.state.session.user.oCounts.quotes_in_progress,
          class: 'pipeline--project',
          transformTitle: 'Invoice project',
          empty: {
            message:
              "No in-progress projects. Mark a booked project as 'in progress' to see it in this list.",
            actions: []
          },
          listPresets: [
            {
              page: '/projects',
              title: 'See all projects',
              description: 'All projects that have been marked as in-progress.',
              filters: {
                quote_status: 'f'
              },
              filterText: {
                quote_status: 'In Progress'
              }
            }
            // {
            //   page: '/quotes',
            //   title: 'Completed projects',
            //   description: 'Projects that have been finished and closed.',
            //   filters: {
            //     quote_status: 'g',
            //   },
            //   filterText: {
            //     quote_status: 'Closed',
            //   },
            // },
          ],
          categories: [
            {
              title: 'Needs your approval',
              highlighted: true,
              filter: (o) =>
                !o.oObject.change_order_company_has_approved &&
                ((o.oObject.change_order_creator !==
                  `user-${this.$store.state.session.user.user_id}` &&
                  this.$store.state.session.user.aUserPerms.quote.approve > 1) ||
                  (o.oObject.change_order_creator ===
                    `user-${this.$store.state.session.user.user_id}` &&
                    this.$store.state.session.user.aUserPerms.quote.approve > 0)),
              actions: [
                {
                  title: 'Approve all changes without viewing..',
                  icon: 'check',
                  action: this.approveAllBooked
                }
              ]
            },
            {
              title: 'Changes waiting for approval',
              highlighted: true,
              filter: (o) =>
                !o.oObject.change_order_company_has_approved &&
                this.$store.state.session.user.aUserPerms.quote.approve < 2
            },
            {
              title: 'New bids need review',
              highlighted: true,
              filter: (o) => o.oObject.quote_count_bids_queued > 0
            },
            {
              title: 'Fully invoiced',
              filter: (o) =>
                o.oObject.quote_invoiced_percentage > 1 ||
                _.eq(o.oObject.quote_invoiced_percentage, 1, 2),
              transformTitle: 'Close project',
              actions: [
                {
                  title: 'Close all fully invoiced projects',
                  icon: 'check',
                  action: this.closeFullyInvoiced
                }
              ]
            },
            {
              title: 'Partially invoiced',
              filter: (o) =>
                o.oObject.quote_uninvoiced_net !== 0 &&
                !_.eq(o.oObject.quote_uninvoiced_net, o.oObject.quote_price_net)
            },
            {
              title: 'Not invoiced',
              filter: (o) => _.eq(o.oObject.quote_invoiced_percentage, 0)
            }
          ]
        },
        ...(this.$store.state.session.company.aoModules.itemized_payments &&
        this.$store.state.session.company.aoModules.itemized_payments === '1'
          ? [
              {
                title: 'Payments',
                icon: 'house-building',
                total: () => `${this.awaitingPayment.length}`,
                value: () => `${this.formatValueKorM(this.awaitingPaymentSum)}`,
                pseudoType: 'awaitingPayment',
                listPage: '/projects',
                globalCount: this.$store.state.session.user.oCounts.quotes_in_progress,
                class: 'pipeline--awaitingPayment',
                transformTitle: 'Project payments',
                empty: {
                  message: 'No projects with outstanding payments.',
                  actions: []
                },
                listPresets: [
                  {
                    page: '/projects',
                    title: 'See all projects',
                    description: 'All projects that have been marked as in-progress.',
                    filters: {
                      quote_status: 'f'
                    },
                    filterText: {
                      quote_status: 'In Progress'
                    }
                  }
                ],
                categories: [
                  {
                    title: 'Unpaid 30+ days',
                    highlighted: true,
                    filter: (o) =>
                      this.pendingPayment[o.oObject.quote_id].state === 'GREATER_THAN_30_DAYS_AGO'
                  },
                  {
                    title: 'Unpaid 14+ days',
                    filter: (o) =>
                      this.pendingPayment[o.oObject.quote_id].state === 'BETWEEN_14_AND_30_DAYS_AGO'
                  },
                  {
                    title: 'Unpaid 7+ days',
                    filter: (o) =>
                      this.pendingPayment[o.oObject.quote_id].state === 'BETWEEN_7_AND_14_DAYS_AGO'
                  },
                  {
                    title: 'Payable now',
                    filter: (o) =>
                      this.pendingPayment[o.oObject.quote_id].state === 'BETWEEN_0_AND_7_DAYS_AGO'
                  }
                ]
              }
            ]
          : []),
        ...(this.$store.state.session.company.aoModules.invoice &&
        this.$store.state.session.company.aoModules.invoice === '1'
          ? [
              {
                title: 'Invoices',
                icon: 'file-invoice-dollar',
                total: () => `${this.invoice.length}`,
                value: () => `${this.formatValueKorM(this.invoiceSum)}`,
                pseudoType: 'invoice',
                globalCount: this.$store.state.session.user.oCounts.invoices_outstanding,
                class: 'pipeline--invoice',
                transformTitle: 'Mark paid',
                listPage: '/invoices',
                canCreate: true,
                empty: {
                  message: 'No unpaid invoices.  Create a new invoice to see it in this list.',
                  actions: [
                    {
                      class: 'xs',
                      title: 'Create invoice',
                      icon: 'file-invoice-dollar',
                      action: this.newInvoice
                    }
                  ]
                },
                listPresets: [
                  {
                    title: 'See all invoices',
                    description: 'Invoices that have been paid by the client, or marked paid.',
                    filters: {
                      invoice_status: 'o||$'
                    },
                    filterText: {
                      invoice_status: 'Outstanding'
                    }
                  }
                  // {
                  //   title: 'Processing invoices',
                  //   description: 'Invoices that have a payment that is processing.',
                  //   filters: {
                  //     invoice_status: 'p||pr',
                  //   },
                  //   filterText: {
                  //     invoice_status: 'Pending',
                  //   },
                  // },
                  // {
                  //   title: 'Paid invoices',
                  //   description: 'Invoices that have been paid by the client, or marked paid.',
                  //   filters: {
                  //     invoice_status: 'e',
                  //   },
                  //   filterText: {
                  //     invoice_status: 'Paid',
                  //   },
                  // },
                ],
                listButtons: [
                  {
                    title: 'Paid invoices',
                    action: () =>
                      this.$store.dispatch('view', {
                        type: 'invoice',
                        filters: {
                          invoice_status: 'e'
                        },
                        filterText: {
                          invoice_status: 'Paid'
                        },
                        delay: 0
                      })
                  },
                  {
                    title: 'Processing invoices',
                    action: () =>
                      this.$store.dispatch('view', {
                        type: 'invoice',
                        filters: {
                          invoice_status: 'p||pr'
                        },
                        filterText: {
                          invoice_status: 'Pending'
                        },
                        delay: 0
                      })
                  }
                ],
                categories: [
                  {
                    title: 'Never sent',
                    highlighted: true,
                    filter: (o) => !o.oObject.invoice_time_sent,
                    actions: [
                      {
                        title: 'Send all..',
                        icon: 'paper-plane',
                        action: this.sendUnsentInvoices
                      }
                    ]
                  },
                  {
                    title: '90+ days past-due',
                    highlighted: true,
                    filter: (o) => o.oObject.invoice_time_due <= days90,
                    actions: [
                      {
                        title: 'Send follow-up email..',
                        icon: 'paper-plane',
                        action: this.sendInvoicesNinetyDays
                      }
                    ]
                  },
                  {
                    title: '60+ days past-due',
                    highlighted: true,
                    filter: (o) => o.oObject.invoice_time_due <= days60,
                    actions: [
                      {
                        title: 'Send follow-up email..',
                        icon: 'paper-plane',
                        action: this.sendInvoicesSixtyDays
                      }
                    ]
                  },
                  {
                    title: '30+ days past-due',
                    highlighted: true,
                    filter: (o) => o.oObject.invoice_time_due <= days30,
                    actions: [
                      {
                        title: 'Send follow-up email..',
                        icon: 'paper-plane',
                        action: this.sendInvoicesThirtyDays
                      }
                    ]
                  },
                  {
                    title: 'Past-due',
                    highlighted: true,
                    filter: (o) => o.oObject.invoice_time_due <= startOfDay,
                    actions: [
                      {
                        title: 'Send follow-up email..',
                        icon: 'paper-plane',
                        action: this.sendInvoicesPastDue
                      }
                    ]
                  },
                  {
                    title: 'Not yet due'
                  }
                ]
              }
            ]
          : [])
      ],
      filtersShown: false
    }
  },
  watch: {
    searchPhrase(v) {
      this.searchPhraseLocal = v
    },
    cursorColumn() {
      this.resize()
    },
    project(newVal) {
      if (newVal.length > 0) {
        eventBus.$emit('projects-awaiting-payment')
      }
    },
    set: {
      handler: 'updatePendingPayments',
      immediate: true,
      deep: true
    }
  },
  computed: {
    searchMethod() {
      return 'search' // this.searchPhraseLocal ? 'search' : 'filter';
    },

    hasPermissionsToSeePipeline() {
      if (
        !this.$store.state.session ||
        !this.$store.state.session.user ||
        !this.$store.state.session.user.aUserPerms
      )
        return false
      const perms = this.$store.state.session.user.aUserPerms
      return perms.client.read > 0
    },
    canShowPipeline() {
      return this.$store.state.session.scope.company && this.hasPermissionsToSeePipeline
    },
    group: {
      get() {
        return c.makeArray(
          (this.localFilters &&
            this.localFilters.location_ids &&
            this.localFilters.location_ids.split('||')) ||
            null
        )
      },
      set(v) {
        _.setStorage('defaultPipelineGroup', v)
        if (v === null) {
          const { location_ids: rest } = this.localFilters
          this.localFilters = rest
        } else {
          this.localFilters = {
            location_ids: c.makeArray(v).join('||')
          }
        }
      }
    },
    owner: {
      get() {
        return c.makeArray(
          (this.localFilters &&
            this.localFilters.manager_user_ids &&
            this.localFilters.manager_user_ids.split('||')) ||
            null
        )
      },
      set(v) {
        _.setStorage('defaultPipelineOwner', v)
        if (v === null) {
          const { manager_user_ids: rest } = this.localFilters
          this.localFilters = rest
        } else {
          this.localFilters = {
            manager_user_ids: c.makeArray(v).join('||')
          }
        }
      }
    },
    typeAccessor() {
      return {
        lead: this.lead,
        pending: this.pending,
        client: this.client,
        booked: this.booked,
        project: this.project,
        awaitingPayment: this.awaitingPayment,
        invoice: this.invoice
      }
    },
    categories() {
      return this.categorySchema.map((cat) => {
        const filters = _.map(cat.categories, 'filter').filter((f) => f)
        let lastCat = cat.categories.length - 1
        lastCat = lastCat === -1 ? 0 : lastCat

        const subCategoryObjects = []
        // Objects are fit into the first category in order of categories as listed in schema
        this[cat.pseudoType].forEach((object) => {
          // See if it fits in one of the filtered subcategories
          const fits = filters.some((f, i) => {
            const b = f(object)
            if (b) {
              subCategoryObjects[i] = [
                ...(subCategoryObjects[i] || []),
                {
                  ...object,
                  highlighted: cat.categories[i].highlighted || false,
                  transformTitle: cat.categories[i].transformTitle || cat.transformTitle
                }
              ]
            } else {
              subCategoryObjects[i] = [...(subCategoryObjects[i] || [])]
            }
            return b
          })

          // If it doesn't fit, stick it in the last category always
          if (!fits) {
            // If didn't fit in other category, stick it in the last category
            subCategoryObjects[lastCat] = [
              ...(subCategoryObjects[lastCat] || []),
              {
                ...object,
                highlighted:
                  (cat.categories[lastCat] && cat.categories[lastCat].highlighted) || false,
                transformTitle: cat.categories[lastCat].transformTitle || cat.transformTitle
              }
            ]
          }
        })

        const categories = cat.categories.map((subCat, i) => ({
          ...subCat,
          objects: subCategoryObjects[i] || [],
          displayObjects: subCategoryObjects[i]
            ? subCategoryObjects[i].slice(0, this.pipelineItemDisplayLimit)
            : []
        }))

        const objects = subCategoryObjects
          .map((subCatObjects, i) =>
            subCatObjects.length
              ? [
                  {
                    ...cat.categories[i],
                    category: true,
                    pipeline_object_id: `category-${cat.title}-${cat.categories[i].title}`
                  },
                  ...subCatObjects
                ]
              : []
          )
          .reduce((a, b) => a.concat(b), [])

        const actions = [
          {
            title: 'Clean up..',
            icon: ['fas', 'broom-wide'],
            action: () => this.cleanup(cat.pseudoType)
          },
          {
            title: 'See in list..',
            icon: 'list',
            action: () => this.goView(cat.pseudoType)
          },
          ...(cat.canCreate
            ? [
                {
                  title: 'Create new',
                  icon: 'plus',
                  action: () => this.create(cat.pseudoType)
                }
              ]
            : [])
        ]

        return {
          ...cat,
          actions,
          categories,
          objects
        }
      })
    },
    visibleCategories() {
      return this.categories.filter((_, colIndex) => this.columnsShown.indexOf(colIndex) > -1)
    },
    lead() {
      return this.set.filter((o) => o.pipeline_object_pseudo_type === 'lead')
    },
    client() {
      return this.set.filter((o) => o.pipeline_object_pseudo_type === 'client')
    },
    pending() {
      return this.set.filter((o) => o.pipeline_object_pseudo_type === 'pending')
    },
    booked() {
      return this.set.filter((o) => o.pipeline_object_pseudo_type === 'booked')
    },
    project() {
      return this.set.filter((o) => o.pipeline_object_pseudo_type === 'project')
    },
    awaitingPayment() {
      if (_.isEmpty(this.pendingPayment)) {
        return []
      }

      return this.set
        .filter((o) => o.pipeline_object_type === 'quote' && o.quote_id in this.pendingPayment)
        .sort((a, b) => {
          const oldestA = this.pendingPayment[a.quote_id].oldestUnpaidItem
          const oldestB = this.pendingPayment[b.quote_id].oldestUnpaidItem
          return oldestA - oldestB
        })
    },
    invoice() {
      return this.set.filter((o) => o.pipeline_object_pseudo_type === 'invoice')
    },

    pendingSum() {
      return this.pending.reduce((acc, o) => acc + o.oObject.quote_price_net, 0)
    },
    bookedSum() {
      return this.booked.reduce((acc, o) => acc + o.oObject.quote_price_net, 0)
    },
    projectSum() {
      return this.project.reduce((acc, o) => acc + o.oObject.quote_price_net, 0)
    },
    awaitingPaymentSum() {
      return this.awaitingPayment.reduce((acc, o) => acc + o.oObject.quote_price_net, 0)
    },
    invoiceSum() {
      return this.invoice.reduce((acc, o) => acc + o.oObject.invoice_gross, 0)
    },

    permanentFilters() {
      return {
        ...this.filters
      }
    },
    columnsSpace() {
      // return Math.floor((this.canvasWidth - 60) / this.minWidth);
      return this.categories.length
    },
    columnsShown() {
      const columnsShown = []
      if (this.columnsSpace > -1) {
        ;[
          ...Array(Math.min(this.columnsSpace, this.categories.length - this.startColumn)).keys()
        ].forEach((k, i) => {
          const adder = i + this.startColumn
          if (this.cursorColumn + adder <= this.categories.length - 1) {
            columnsShown.push(this.cursorColumn + adder)
          }
        })
      }
      return columnsShown
    },
    mappedSet() {
      return this.set.map((o, i) => ({
        ...o,
        overallIndex: i
      }))
    },
    gridComponent() {
      return this.$refs.grid
    },

    showAvatars() {
      return /xl|xs|sm/.test(this.$store.state.session.deviceSize)
    },
    filteredCategories() {
      return this.categories.filter((_, index) => this.columnsShown.indexOf(index) > -1)
    },
    showFilters() {
      return this.filtersShown
    }
  },
  methods: {
    async createNew() {
      if (this.filters.client_id) {
        this.$store.dispatch('create', {
          type: 'quote',
          embue: { client_id: this.filters.client_id },
          go: false
        })
      } else {
        this.createDongle.open()
      }
    },

    async updatePendingPayments() {
      const quoteIds = this.set
        .filter((o) => o.pipeline_object_type === 'quote')
        .map((quote) => quote.quote_id)
        .sort((a, b) => Number(a) - Number(b))

      if (quoteIds.length === 0) return

      const store = this.$store
      const quotesAwaitingPayment = await getQuotesAwaitingPaymentStatesFromApprovals({
        quoteIds,
        userId: store.state.session.company.company_id
      })
      this.pendingPayment = quotesAwaitingPayment.reduce((map, item) => {
        map[item.quoteId] = {
          state: item.state,
          oldestUnpaidItem: item.oldestUnpaidItem
        }
        return map
      }, {})
    },
    formatCurrencySymbol(val, dec = 2, code = null) {
      const store = this.$store
      if (!store || !store.state.session.user || !store.state.session.company) {
        return c.format(val, 'currency')
      }

      const currency =
        code ||
        (store.state.session.company && store.state.session.company.currency_iso) ||
        (store.state.session.user && store.state.session.user.currency_iso) ||
        'USD'
      return c.toNum(val).toLocaleString(undefined, {
        style: 'currency',
        currency,
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
        currencyDisplay: 'symbol'
      })
    },
    formatValueKorM(value) {
      if (value === 0) {
        return value
      }
      const thousand = Math.floor(value / 1000)
      if (thousand >= 1000) {
        const million = Math.floor(thousand / 1000)
        return `${million}M`
      }
      return `${thousand}K`
    },
    showMoreItemObjects(item) {
      const newItems = item.objects.slice(this.pipelineItemDisplayLimit, item.objects.length)
      item.displayObjects.splice(item.displayObjects.length, 0, ...newItems)
      this.update++
      this.$forceUpdate()
    },
    addLoading() {
      if (this.gridComponent) this.gridComponent.addLoading()
    },
    removeLoading() {
      if (this.gridComponent) this.gridComponent.removeLoading()
    },
    endLoading() {
      if (this.gridComponent) this.gridComponent.endLoading()
    },
    reloadGrid() {
      if (this.gridComponent) this.gridComponent.reload()
    },
    /**
     * Event handler for when grid loads data
     * @param set
     */
    async addLoaded(set) {
      if (!set.length) this.$emit('empty')
      else this.$emit('notEmpty')

      this.set = set

      if (!set.length && this.searchPhraseLocal) {
        // If they were trying to search for something and it didn't come up
        // then we should do a global search
        await this.$store.dispatch('search/search', {
          searchPhrase: this.searchPhraseLocal,
          open: true
        })
        await c.throttle(() => {}, { delay: 350 })
        eventBus.$emit('searchPhrase', this.searchPhraseLocal)
      }
    },
    goToPreset(page, preset) {
      this.$store.dispatch('view', {
        page,
        filters: preset.filters,
        filterText: preset.filterText,
        delay: 0
      })
    },
    async reload() {
      this.addLoading()
      // await c.ajax(
      // `/search/indexCompanyType/${this.$store.state.session.company.company_id}/pipeline`);
      try {
        await c.throttle(() => {})
        await this.reloadGrid()
      } finally {
        this.removeLoading()
      }

      return this
    },
    newPending() {
      this.$store.dispatch('modal/open', {
        modal: 'QuoteNew',
        embue: {
          ...this.filters
        }
      })
    },
    newLead() {
      this.$store.dispatch('modal/open', {
        modal: 'ClientNew',
        embue: {
          ...this.filters,
          client_status: 'l'
        },
        go: false
      })
    },
    newClient() {
      this.$store.dispatch('modal/open', {
        modal: 'ClientNew',
        embue: {
          ...this.filters,
          client_status: 'a'
        },
        go: false
      })
    },
    newInvoice() {
      this.$store.dispatch('to', '/invoice/create')
    },
    resize() {
      this.canvasWidth =
        $(this.$el) && $(this.$el).parent().length
          ? $(this.$el).parent()[0].getBoundingClientRect().width
          : 1000
    },
    create(pseudoType) {
      switch (pseudoType) {
        case 'booked':
        case 'pending':
        case 'project':
          this.newPending()
          break
        case 'lead':
          this.newLead()
          break
        case 'client':
          this.newClient()
          break
        case 'invoice':
          this.newInvoice()
          break
        default:
          this.newLead()
          break
      }
    },
    cleanup() {
      this.$store.dispatch('modal/confirm', {
        message: `
              Archive stale leads, long waiting clients and long pending quotes.
            `,
        subMessage: `
             <br/>Choose a cut-off in days, and any leads, clients or quotes that have been waiting
              longer than that, will be archived.
            You can always recover archived objects later.
              <br/><br/>
              Cleanup can take a few minutes..
             `,
        actions: {
          short: {
            class: 'btn btn-primary',
            title: '30+ days',
            action: () => this.getCleanupFunction(30)()
          },
          medium: {
            class: 'btn btn-primary',
            title: '60+ days',
            action: () => this.getCleanupFunction(60)()
          },
          long: {
            class: 'btn btn-primary',
            title: '90+ days',
            action: () => this.getCleanupFunction(90)()
          },
          confirm: {
            visible: () => false
          }
        }
      })
    },
    async closeFullyInvoiced() {
      this.addLoading()
      const objects = this.getCategoryObjects(4, 3)
      await this.$store.dispatch('Quote/markMultiple', {
        markAs: 'Closed',
        grid: this.$refs.grid,
        selected: objects,
        go: false
      })
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async approveAll() {
      this.addLoading()
      const objects = this.getCategoryObjects(2, 1)
      await this.$store.dispatch('ChangeOrder/markMultiple', {
        markAs: 'approvedByCompany',
        selected: objects.map((co) => ({ ...co, type: 'change_order' })),
        go: false,
        alert: true
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    getCategoryObjects(categoryIndex, subcategoryIndex) {
      return this.categories[categoryIndex].categories[subcategoryIndex].objects.map(
        (o) => o.oObject
      )
    },
    async sendUnseenQuotes() {
      this.addLoading()
      const objects = this.getCategoryObjects(2, 6)
      await this.$store.dispatch('Quote/send', {
        selected: objects,
        go: false
      })
      this.reloadGrid()
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendUnsentQuotes() {
      this.addLoading()
      const objects = this.getCategoryObjects(2, 5)
      await this.$store.dispatch('Quote/send', {
        selected: objects,
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendQuotesOlderThanWeek() {
      this.addLoading()
      const objects = this.getCategoryObjects(2, 11)
      eventBus.$emit('closeAllDrops')
      await this.$store.dispatch('Quote/send', {
        selected: objects,
        templateType: 22,
        go: false
      })
      this.reloadGrid()
      this.removeLoading()
    },
    async sendQuotesThisWeek() {
      this.addLoading()
      const objects = this.getCategoryObjects(2, 9)
      await this.$store.dispatch('Quote/send', {
        selected: objects,
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async declineQuotesOlderThanWeek() {
      this.addLoading()
      const objects = this.getCategoryObjects(2, 11)
      await this.$store.dispatch('Quote/markMultiple', {
        markAs: c.format('d', 'status'),
        selected: objects,
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },

    async approveAllBooked() {
      this.addLoading()
      const objects = this.getCategoryObjects(3, 0)
      await this.$store.dispatch('ChangeOrder/markMultiple', {
        markAs: 'approvedByCompany',
        selected: objects.map((co) => ({ ...co, type: 'change_order' })),
        go: false,
        alert: true
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },

    async approveAllInProgress() {
      this.addLoading()
      const objects = this.getCategoryObjects(4, 0)
      await this.$store.dispatch('ChangeOrder/markMultiple', {
        markAs: 'approvedByCompany',
        selected: objects.map((co) => ({ ...co, type: 'change_order' })),
        go: false,
        alert: true
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },

    async sendUnsentInvoices() {
      this.addLoading()
      const objects = this.getCategoryObjects(5, 0)
      await this.$store.dispatch('Invoice/send', {
        selected: objects,
        message_subject: 'Your invoice',
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async aaaa() {
      const objects = this.getCategoryObjects(5, 1)
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('Invoice/send', {
        selected: objects.slice(0, 10),
        message_subject: 'Invoice 90+ days past due..',
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
    },
    async sendInvoicesNinetyDays() {
      this.addLoading()
      const objects = this.getCategoryObjects(5, 1)
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('Invoice/send', {
        selected: objects.slice(0, 10),
        message_subject: 'Invoice 90+ days past due..',
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendInvoicesSixtyDays() {
      this.addLoading()
      const objects = this.getCategoryObjects(5, 2)
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('Invoice/send', {
        selected: objects.slice(0, 10),
        message_subject: 'Invoice 60+ days past due..',
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendInvoicesThirtyDays() {
      this.addLoading()
      const objects = this.getCategoryObjects(5, 3)
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('Invoice/send', {
        selected: objects.slice(0, 10),
        message_subject: 'Invoice 30+ days past due..',
        grid: this.gridComponent,
        go: false
      })
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendInvoicesPastDue() {
      this.addLoading()
      const objects = this.getCategoryObjects(5, 4)
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('Invoice/send', {
        selected: objects.slice(0, 10),
        message_subject: 'Invoice past due..',
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendUncontactedLeads() {
      this.addLoading()
      const objects = this.getCategoryObjects(0, 0).map((o) => ({
        message_to: o.user_email,
        message_subject: 'Thank you for your request..'
      }))
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('message/composeMultiple', {
        templateFilters: {
          template_type_id: 13
        },
        selected: objects.slice(0, 10),
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    async sendUncontactedLeadsToday() {
      this.addLoading()
      const objects = this.getCategoryObjects(0, 1).map((o) => ({
        message_to: o.user_email,
        message_subject: 'Thank you for your request..',
        go: false
      }))
      if (objects.length > 10)
        this.$store.dispatch('alert', { message: 'Can only do 10 at a time max..' })
      await this.$store.dispatch('message/composeMultiple', {
        templateFilters: {
          template_type_id: 13
        },
        selected: objects.slice(0, 10),
        go: false
      })
      this.reloadGrid()
      eventBus.$emit('closeAllDrops')
      this.removeLoading()
    },
    open(object, event) {
      this.$store.dispatch('edit', {
        type: object.pipeline_object_type,
        id: object.pipeline_object_id,
        button: event.button,
        grid: this.gridComponent
      })
    },
    view(object) {
      this.goView(object.pipeline_object_pseudo_type)
    },

    /**
     * Get a function to clean up old/stale items based on provided
     * cut-off in days.
     * @param days
     * @returns {function(): Promise<any>}
     */
    getCleanupFunction(days = 30) {
      return async () => {
        this.addLoading()

        const cutoff = moment().subtract(days, 'days').format('x')

        const allLeads = [
          ...this.getCategoryObjects(0, 0),
          ...this.getCategoryObjects(0, 1),
          ...this.getCategoryObjects(0, 2)
        ]

        const allProspects = [
          ...this.getCategoryObjects(1, 0),
          ...this.getCategoryObjects(1, 1),
          ...this.getCategoryObjects(1, 2)
        ]

        const allProposals = [
          ...this.getCategoryObjects(2, 0),
          ...this.getCategoryObjects(2, 1),
          ...this.getCategoryObjects(2, 2),
          ...this.getCategoryObjects(2, 3),
          ...this.getCategoryObjects(2, 4),
          ...this.getCategoryObjects(2, 5),
          ...this.getCategoryObjects(2, 6),
          ...this.getCategoryObjects(2, 7),
          ...this.getCategoryObjects(2, 8),
          ...this.getCategoryObjects(2, 9),
          ...this.getCategoryObjects(2, 10),
          ...this.getCategoryObjects(2, 11)
        ]

        // Group by native entities
        let clients = []
        let quotes = []

        // Get leads without contact information
        clients = [
          ...clients,
          ...allLeads.filter(
            (lead) =>
              !lead.user_phone && !lead.user_phone_alt && !lead.user_email && !lead.user_email_alt
          )
        ]

        // Collect leads that are contacted but old
        clients = [...clients, ...allLeads.filter((lead) => lead.last_activity_time < cutoff)]

        // Collect prospects that are old
        clients = [
          ...clients,
          ...allProspects.filter((prospect) => prospect.client_time_active < cutoff)
        ]

        // Collect old proposals
        quotes = allProposals.filter((proposal) => proposal.quote_time_created < cutoff)

        // Reduce both to type and id for minimal data transfer
        clients = clients.map((client) => ({
          type: 'client',
          client_id: client.client_id
        }))
        quotes = quotes.map((quote) => ({
          type: 'quote',
          quote_id: quote.quote_id
        }))

        // Mark multiple as inactive
        if (clients.length) {
          await this.$store.dispatch('Client/markMultiple', {
            selected: clients,
            markAs: c.format('i', 'status'),
            go: false
          })
        }

        if (quotes.length) {
          await this.$store.dispatch('Quote/markMultiple', {
            selected: quotes,
            markAs: c.format('d', 'status'),
            go: false
          })
        }

        await this.reloadGrid()
        this.removeLoading()

        return true
      }
    },
    shouldDoGlobalSavedReload(payload = null) {
      const object = payload && payload.object ? c.makeArray(payload.object)[0] : false
      if (object && object.type && /quote|invoice|project|client|lead|receipt/.test(object.type)) {
        this.reload()
      }
    },
    async transform(object, event) {
      const button = event.button
      const obj = _.imm(object.oObject)

      c.addLoadingAll(button)

      const type = object.pipeline_object_pseudo_type

      if (type === 'lead') {
        try {
          await this.$store.dispatch('create', {
            type: 'quote',
            embue: {
              client_id: obj.client_id
            },
            go: true
          })
        } catch (e) {
          return this.$store.dispatch('alert', {
            message:
              e.userMessage || 'Make sure client has all required information, including an email.',
            error: true
          })
        }
      } else if (type === 'pending') {
        await this.$store.dispatch('Quote/markMultiple', {
          markAs: 'Booked',
          selected: [
            {
              type: 'quote',
              quote_id: obj.quote_id
            }
          ],
          alert: true,
          go: false
        })
      } else if (type === 'booked') {
        await this.$store.dispatch('Quote/markMultiple', {
          markAs: 'InProgress',
          selected: [
            {
              type: 'quote',
              quote_id: obj.quote_id
            }
          ],
          alert: true,
          go: false
        })
      } else if (type === 'project') {
        if (obj.quote_invoiced_percentage > 1 || _.eq(obj.quote_invoiced_percentage, 1)) {
          await this.$store.dispatch('Quote/markMultiple', {
            markAs: 'Closed',
            selected: [
              {
                type: 'quote',
                quote_id: obj.quote_id
              }
            ],
            alert: true,
            go: false
          })
        } else {
          await this.$store.dispatch('create', {
            type: 'invoice',
            go: false,
            embue: {
              quote_id: obj.quote_id
            }
          })
        }
      } else if (type === 'invoice') {
        await this.$store.dispatch('Invoice/markMultiple', {
          markAs: 'Paid',
          selected: [
            {
              type: 'invoice',
              invoice_id: obj.invoice_id
            }
          ],
          alert: true,
          go: false
        })
      }

      c.endLoadingAll(button)

      setTimeout(() => {
        this.reload()
      }, 2000)

      return this
    },
    goView(type) {
      switch (type) {
        case 'lead':
          this.$store.dispatch('view', {
            type: 'lead',
            filters: {
              ...this.filters
            },
            filterText: {
              ...this.filters
            },
            delay: 0
          })
          break
        case 'client':
          this.$store.dispatch('view', {
            type: 'client',
            filters: {
              ...this.filters,
              client_status: 'a',
              client_count_quotes: '<1'
            },
            filterText: {
              ...this.filters,
              client_status: 'Active',
              client_count_quotes: 'None'
            },
            delay: 0
          })
          break
        case 'pending':
          this.$store.dispatch('view', {
            type: 'quote',
            filters: {
              ...this.filters,
              quote_status: 'p'
            },
            filterText: {
              ...this.filters,
              quote_status: 'Pending'
            },
            delay: 0
          })
          break
        case 'booked':
          this.$store.dispatch('view', {
            type: 'project',
            filters: {
              ...this.filters,
              quote_status: 'k',
              quote_invoiced_percentage: '<1'
            },
            filterText: {
              ...this.filters,
              quote_status: 'Booked',
              quote_invoiced_percentage: 'less than 100%'
            },
            delay: 0
          })
          break
        case 'project':
        case 'awaitingPayment':
          this.$store.dispatch('view', {
            type: 'project',
            filters: {
              ...this.filters,
              quote_status: 'f',
              quote_invoiced_percentage: '<1'
            },
            filterText: {
              ...this.filters,
              quote_status: 'In Progress',
              quote_invoiced_percentage: 'less than 100%'
            },
            delay: 0
          })
          break
        case 'invoice':
          this.$store.dispatch('view', {
            type: 'invoice',
            filters: {
              ...this.filters,
              invoice_status: 'o||$'
            },
            filterText: {
              ...this.filters,
              invoice_status: 'Outstanding'
            },
            delay: 0
          })
          break
        default:
          break
      }
    },
    singleClick(element) {
      const all = Array.from(document.getElementsByClassName('pipeline--item-overlay'))
      all.forEach((node) => node.classList.remove('show-overlay'))
      const el = element.target.getElementsByClassName('pipeline--item-overlay')
      if (el) {
        el[0].className += ' show-overlay'
      }
    },
    dblclick() {},
    toggleFilters() {
      this.filtersShown = !this.filtersShown
    }
  },
  components: {
    IconField,
    PageHeader,
    InputText,
    InputIcon,
    SuggestionButton,
    LoadingIndicator,
    AcceptTerms,
    SearchField,
    Grid,
    quote: Quote,
    client: Client,
    invoice: Invoice,
    OwnerFilter,
    GroupFilter,
    UISection
  },
  props: {
    searchPhrase: {
      default: ''
    },
    filters: {
      default: () => ({})
    },
    startColumn: {
      default: 0
    },
    endColumn: {
      default: 5
    },
    showToolbar: {
      default: true
    }
  },
  mounted() {
    this.$store.dispatch('triggerEvent', 'UserLogin')

    this.$nextTick(() => {
      eventBus.$on('saved', this.shouldDoGlobalSavedReload)
    })

    this.reload()

    c.throttle(async () => {
      if (this.needsAcceptance && !this.$store.getters.activatePaywall) {
        const val = await this.$refs.termsAcceptance.open()

        if (!val && Date.now() >= 1598832000000) {
          this.$store.dispatch('logout')
        } else if (!val) {
          this.$store.dispatch('alert', {
            message:
              'Please accept our updated TOS before 31 Aug 2020 so we can keep bringing you new features!'
          })
        } else {
          c.throttle(() => this.$store.dispatch('getBaseValues', { cloak: false }), {
            delay: 10000
          })
        }
      }
    })
  },
  beforeUnmount() {
    eventBus.$off('saved', this.shouldDoGlobalSavedReload)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$opacity: 0.1;

$highlightColor: rgb(31 146 252);

$itemHeight: 4em;
$borderRadius: 4px;
$columnPadding: 0.25em;

$headerHeight: 3em;

%categoryHeader {
  height: $headerHeight;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $flame-white;
  padding: 8px;
  font-size: 0.9em;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  border-top-right-radius: $borderRadius;
  border-top-left-radius: $borderRadius;
}

.pipeline--outer-container {
  > .grid--container {
    height: calc(100% - #{$headerHeight});
  }

  .pipeline--container {
    display: flex;
    justify-content: flex-start;
    width: unset;
    max-width: unset;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-touch-scrolling: touch;
    padding: 0;
    margin: 0;
    height: 100%;
    max-height: 100%;

    // @extend %scrollbarDark;

    /* Track */
    &::-webkit-scrollbar-track {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &::-webkit-scrollbar-thumb {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .pipeline--list {
    margin-top: 1px;
  }

  .pipeline--scroll-column {
    min-width: unset !important;
    position: relative;
    top: 0.8em;
    flex: 0 0 auto;
    font-size: 1em;
    max-width: 3em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .pipeline--column {
    max-width: 20em;
    min-width: 15em;
    //width: 17em;
    //margin: 0 0.5em !important;
    overflow: auto;
    max-height: 100%;
  }

  .pipeline--column-actions {
    width: 100%;
    cursor: pointer;
    .caret-down {
      display: inline-block;
      font-size: 1.5em;
      transition: transform 0.2s ease;
    }

    &:hover {
      .caret-down {
        transform: translateY(0.1em);
      }
    }
  }

  .pipeline--header {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 12px;
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    height: 4em;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;

    color: $flame-white;
    background: $cool-gray-400;
  }

  .pipeline--category-bundle {
    text-align: center;
    border-radius: 3px;
  }

  .pipeline--item + .pipeline--item {
    border-top: solid 1px $cool-gray-200;
  }

  .pipeline--category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: transparent !important;
    position: sticky;

    top: 0;
    z-index: 1;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .pipeline--category-empty {
    display: flex;
    padding: 2em 3em;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.1;

    background: transparent;
    background: $cool-gray-200;
    color: $cool-gray-600;
    border-radius: $borderRadius;
    margin-top: 30px;
    font-weight: normal;

    .text {
      font-size: 0.9em;
      margin-bottom: 1em;
    }
  }

  .pipeline--category-header,
  .pipeline--category-show-all {
    @extend %categoryHeader;
    cursor: pointer;
  }

  .pipeline--category-show-all {
    @extend %categoryHeader;
    cursor: pointer;
    margin-top: 0;
    border: none !important;
  }

  .pipeline--item {
    position: relative;
    overflow: hidden;
    padding: 0 0.5em;
    height: $itemHeight;
    border-top: none;
    border-collapse: collapse;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $flame-white;

    &:hover {
      .pipeline--item-overlay {
        transform: translateY(0);
      }
    }
  }

  .pipeline--item-preview {
    font-size: 0.8em;
    width: 100%;
    a,
    span,
    small,
    .text-primary {
      color: $cool-gray-600 !important;
    }

    .number {
      margin-left: auto;
    }

    .text-success {
      color: $cool-gray-600 !important;
    }

    .info {
      width: 100%;
    }
    .primary {
      color: $cool-gray-800 !important;
    }

    .secondary {
      display: flex;
      justify-content: space-between;
      align-items: space-between;
      width: 100%;
      color: $cool-gray-600 !important;
      > div {
        width: 100%;
      }
    }

    .button--container {
      display: none !important;
    }
  }

  .overlay-contents {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    line-height: 1 !important;
    color: $cool-gray-800 !important;
    whitespace: normal !important;
    overflow: visible !important;
    flex-wrap: wrap !important;
  }

  .pipeline--item-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    transition: transform 0.4s ease-out;

    background: $cool-gray-100 !important;
    color: $cool-gray-800;
    border: solid 1px $cool-gray-100;

    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    z-index: 1;

    .overlay-btn {
      flex: 0 0 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.7em;

      height: $itemHeight + 1em;
      width: $itemHeight + 1em;
      max-height: $itemHeight + 1em;
      max-width: $itemHeight + 1em;
      overflow: visible;
      border: 0;

      .text {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
      }

      &:hover {
        cursor: pointer;
        .overlay-contents {
          color: var(--secondary) !important;
          border-radius: 50%;
        }
      }
      .glyphicon,
      .fa,
      .icon {
        font-size: 1.1em !important;
        margin-bottom: 0.7em !important;
      }
    }
  }

  .pipeline--category-buttons {
    margin-top: 1em;
  }

  .pipeline--category-button {
    height: $headerHeight * 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1em 0.5em;
    font-size: 0.8em;
    line-height: 1;
    margin-top: 1.5em;
    text-align: center;
    border-width: 1px;
    border-radius: $borderRadius;
    .fa,
    .glyphicon {
      margin-left: 0.25em;
      transition: transform 0.1s ease;
      top: 1px;
    }

    &:hover {
      cursor: pointer;
      .fa,
      .glyphicon {
        transform: translateX(0.25em);
      }
    }
  }

  .category-last {
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }

  @each $color, $value in $stages-l {
    .pipeline--#{$color} {
      $contrast: $cool-gray-800;
      $background: $flame-white;
      $backgroundItem: $flame-white;
      .pipeline--header {
        background: transparent;
        color: $contrast;
      }
      .pipeline--category.highlighted .pipeline--category-header,
      .pipeline--category.highlighted .pipeline--category-show-all,
      .pipeline--item-show-all.highlighted {
        color: $contrast;
        a {
          color: $contrast !important;
        }
      }

      .pipeline--category-buttons {
        margin-top: 1em;
        .pipeline--category-button {
          margin: 1em 0;
        }
      }

      .pipeline--item.highlighted {
        background: $backgroundItem;

        .pipeline--item-preview {
          color: $contrast !important;

          a,
          span,
          small,
          .text-primary,
          .primary,
          .secondary,
          .info {
            color: $contrast !important;
          }
          .primary {
            color: $contrast !important;
          }

          .text-success {
            color: $contrast !important;
          }
        }
      }
      .pipeline--category-bundle {
        background-color: $cool-gray-100;
        .pipeline--category-empty {
          background-color: $cool-gray-100 !important;
          min-height: 400px;
          .text {
            color: rgba($contrast, 0.5) !important;
          }
        }
      }
    }
  }

  @each $color, $value in $stages {
    .pipeline--#{$color} {
      .pipeline--header {
        border-bottom: solid 3px $value;
      }
      .pipeline--category.highlighted .pipeline--category-header,
      .pipeline--category.highlighted .pipeline--category-show-all,
      .pipeline--item-show-all.highlighted {
        background: $flame-white;
        border-bottom: 1px solid $cool-gray-500;
      }
      .pipeline--category-bundle:not(.empty) {
        background-color: rgba($value, 0.2);
      }
    }
  }
}

.small-pipeline {
  @each $color, $value in $stages {
    .#{$color} {
      border-left: 4px solid $value;
    }
  }
}
</style>
