<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useSidePanel } from '@/stores/sidepanel'
import BaseView from '@/views/BaseView.vue'
import FeatureNotifier from '@/components/engagement/FeatureNotifier.vue'
import AppHeader from '@/components/headers/AppHeader.vue'
import AppMenu from '@/components/menus/AppMenu.vue'

const route = useRoute()
const store = useStore()
const sidePanelStore = useSidePanel()

const shouldLoadBeamer = computed(() => {
  if (!window.Beamer?.started && store.state.session.isLoggedIn) {
    // Get the current company and check the user is an admin
    const scopedCompany = store.state.session.scope?.company
    const userCompanies = store.state.session.user?.aoCompanies
    if (scopedCompany) {
      for (const company in userCompanies) {
        if (
          userCompanies[company].company_id === scopedCompany &&
          userCompanies[company].user_is_admin == 1
        ) {
          return true
        }
      }
    }
  }

  return false
})

const showHeader = computed(() => {
  return route.meta.header || store.state.session.isLoggedIn
})

const bannerIsOpen = computed(() => {
  return store.state.session.scope.company && store.state.banner.settings.open
})

function dismissBanner() {
  store.dispatch('toggleBanner')
}
</script>

<template>
  <BaseView>
    <template #before>
      <FeatureNotifier v-if="shouldLoadBeamer" display="popup" />
      <AppHeader v-if="showHeader" />
      <warning v-if="bannerIsOpen" :closable="dismissBanner" class="w-full m-0">
        <span v-html="store.state.banner.settings.message" />
      </warning>
    </template>
    <template #after>
      <AppMenu v-show="sidePanelStore.shouldShowSidePanel" />
    </template>
  </BaseView>
</template>

<style scoped lang="scss"></style>
