<template>
  <div class="flex flex-col gap-4">
    <div v-if="!emailSkipped" class="flex flex-col gap-4">
      <h4>Start with an email</h4>
      <card-list>
        <card-list-field>
          <span>
            Email
            <small
              >Emails are required to send estimates <br />
              (but you can always add one later!)</small
            >
          </span>

          <field
            schema="client:user_email"
            :emit-delay="0"
            :validate="validationSchema.user_email"
            v-model="email"
            @submit="emailNextHandler"
          />
        </card-list-field>
      </card-list>
      <div v-if="!continued" class="flex justify-end">
        <btn-group>
          <Btn size="lg" severity="secondary" ref="btn" @click="skipEmailEntry"> Skip </Btn>
          <Btn
            size="lg"
            severity="primary-black"
            ref="btn"
            :loading="searchLoading"
            :disabled="!emailValid"
            @click="emailNextHandler"
          >
            Next
          </Btn>
        </btn-group>
      </div>
    </div>
    <div v-if="displaySearchResults">
      <div v-if="searchLoading && !choiceOptions.length" class="flex flex-col gap-2">
        <div v-for="i in 3" :key="i" class="flex flex-row items-center gap-2 p-4">
          <Skeleton shape="circle" size="4rem" />
          <div class="flex flex-col gap-2 grow">
            <Skeleton shape="rectangle" width="5rem" />
            <Skeleton shape="rectangle" width="12rem" height="2rem" />
            <Skeleton shape="rectangle" width="4rem" />
          </div>
          <Skeleton shape="rectangle" width="12rem" height="2rem" />
        </div>
      </div>
      <div v-else-if="choiceOptions.length && !searchLoading">
        <div class="grid grid-nogutter">
          <div v-for="(user, index) in choiceOptions" :key="`${user.name}`" class="col-12">
            <div
              class="flex flex-row items-center justify-start p-4 gap-3"
              :class="{
                'border-top-1 surface-border': index !== 0,
                hidden: chosenOption && chosenOption.id !== user.id
              }"
            >
              <div class="md:w-10rem relative">
                <PersonAvatar
                  :id="user.id"
                  :name="user.name"
                  :type="user.type"
                  size="xlarge"
                  :onClick="
                    () =>
                      chooseClient(
                        ...[
                          user.type === 'user' && user.object,
                          user.type === 'company' && user.object,
                          user.type === 'client' && user.object
                        ]
                      )
                  "
                  :shape="user.clientType === 'user' ? 'circle' : 'square'"
                />
              </div>
              <div
                class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4"
              >
                <div
                  class="flex flex-row md:flex-column justify-content-between align-items-start gap-2"
                >
                  <div>
                    <span class="font-medium text-blue-print text-xs"
                      >Existing {{ user.type }}</span
                    >
                    <div class="text-lg font-medium text-900">{{ user.name }}</div>
                    <div class="text-md font-medium text-surface-500">
                      {{ user?.object?.user_email }}
                    </div>
                    <div class="text-sm text-surface-400">
                      {{ user.rating || 'No rating yet' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-column md:align-items-middle gap-5">
                <Button
                  :class="
                    chosenOption && chosenOption.id === user.id
                      ? 'border-secondary !bg-secondary text-surface-100 hover:bg-secondary-300'
                      : ''
                  "
                  class="gap-2 p-2"
                  outlined
                  @click="
                    chooseClient(
                      ...[
                        user.type === 'user' && user.object,
                        user.type === 'company' && user.object,
                        user.type === 'client' && user.object
                      ]
                    )
                  "
                >
                  <font-awesome-icon
                    icon="circle"
                    v-if="!chosenOption || chosenOption.id !== user.id"
                  />
                  <font-awesome-icon icon="circle-check" v-else />
                  {{
                    !chosenOption || chosenOption.id !== user.id
                      ? 'Choose this account'
                      : 'Adding this account'
                  }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayClientDetails" class="flex flex-col">
      <template v-if="!chosen">
        <card-section>
          <template #label>
            <span> Contact </span>
          </template>
          <card-list>
            <card-list-field>
              <span>First name</span>
              <field
                :validate="validationSchema.user_fname"
                v-model="user_fname"
                schema="client:user_fname"
                ref="userFirstName"
                @field-mounted="firstNameMounted"
              />
            </card-list-field>
            <card-list-field>
              <span>Last name</span>
              <field
                :validate="validationSchema.user_lname"
                v-model="user_lname"
                schema="client:user_lname"
              />
            </card-list-field>
            <card-list-field>
              <span>Phone number</span>
              <div>
                <btn
                  size="sm"
                  severity="tertiary"
                  class="mr-2"
                  @click="primaryExtension = true"
                  v-if="primaryExtension !== true && !user_phone_ext"
                >
                  <font-awesome-icon icon="plus" />
                </btn>
              </div>
              <field
                :validate="{ required: false }"
                v-model="user_phone"
                schema="client:user_phone"
                format="phone"
              ></field>
            </card-list-field>
            <card-list-field v-if="primaryExtension === true || user_phone_ext">
              <span>Extension</span>
              <field
                v-model="user_phone_ext"
                schema="client:user_phone_ext"
                class="max-w-20"
              ></field>
            </card-list-field>
          </card-list>
        </card-section>

        <!-- commercial /res -->
        <card-section>
          <template #label>
            <span>Contact type</span>
          </template>
          <selection-toggle
            class="ml-1"
            :options="commercialOptions"
            v-model="clientIsCommercial"
          />
          <card-list v-show="clientIsCommercial">
            <card-list-field>
              <span> Company name </span>
              <field
                schema="company:company_name"
                :validate="validationSchema.company_name"
                v-model="company_name"
              />
            </card-list-field>
            <card-list-field>
              <span> Company type </span>
              <choose
                multiple
                :validate="{ required: false }"
                schema="company:trade_type_ids"
                v-model="trade_type_ids"
              />
            </card-list-field>
          </card-list>
        </card-section>

        <card-section>
          <template #label>
            <span>Address</span>
          </template>

          <card-list>
            <card-list-field>
              <span>Address</span>
              <field :validate="{ required: false }" v-model="user_address" />
            </card-list-field>
            <card-list-field>
              <span></span>
              <field v-model="user_suite" />
            </card-list-field>
            <card-list-field>
              <span>City</span>
              <field :validate="{ required: false }" v-model="user_city" />
            </card-list-field>
            <card-list-field>
              <span>State/province</span>
              <choose
                :return-array="false"
                :allowDeselect="false"
                :default="$store.state.session.company.province_id"
                schema="client:province_id"
                :filters="countryFilters"
                v-model="province_id"
              />
            </card-list-field>
            <card-list-field>
              <span>Zip/postal Code</span>
              <field v-model="user_postal" size="10" class="w-8 basis-1/3 !max-w-80" />
            </card-list-field>
          </card-list>
        </card-section>
      </template>

      <card-section>
        <card-list>
          <card-list-field>
            <span>
              Owner
              <small>
                Choose the user that will be primarily responsible for managing this relationship.
                This contact and all quotes and invoices for them will show up in their lists. You
                can reassign this contact to someone else later.
              </small>
            </span>
            <choose
              :return-array="false"
              schema="client:client_owner"
              v-model="client_owner"
              :filters="{
                company_ids: `INSET${$store.state.session.company.company_id}`
              }"
              :allowDeselect="false"
              :default="$store.state.session.user.user_id"
            />
          </card-list-field>
        </card-list>
      </card-section>
      <card-section>
        <card-list>
          <card-list-field>
            <span>
              Notes
              <small>
                Provide notes about this contact, including what they are looking for, or any
                requests they have.
              </small>
            </span>

            <field type="textarea" v-model="activity_desc"></field>
          </card-list-field>
        </card-list>
      </card-section>

      <CardSection>
        <PrimePanel toggleable header="Advanced" collapsed>
          <card-list>
            <card-list-field>
              <span>Localization</span>
              <choose
                :return-array="false"
                :default="$store.state.session.user.localization_id"
                schema="user:localization_id"
                v-model="localization_id"
              />
            </card-list-field>
            <card-list-field>
              <span>
                Lead source
                <small> Where did this client/lead come from? (optional) </small>
                <small>
                  If you received this client/lead from a particular marketing campaign or even by
                  referral, you can track what you receive from each type of advertising so that you
                  can determine your ROI.
                </small>
              </span>

              <choose
                :validation="{ required: false }"
                :return-array="false"
                schema="client:lead_source_id"
                v-model="lead_source_id"
              />
            </card-list-field>
          </card-list>
        </PrimePanel>
      </CardSection>

      <Btn :action="saveClient" size="lg" severity="primary-black" class="w-full mt-4">
        {{ getReviewer ? 'Save client and add as reviewer' : 'Save' }}
      </Btn>
    </div>
  </div>
</template>

<script>
import BodyMixin from '@/components/mixins/Body'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import Btn from '@/components/ui/Btn.vue'
import Field from '@/components/ui/Field.vue'

export default {
  mixins: [ObjectManipulator('client', true), BodyMixin],
  emits: ['addReviewer'],
  data() {
    return {
      email: null,
      emailSkipped: false,
      displayClientDetails: false,
      displaySearchResults: false,
      continued: false,
      clientIsCommercial: false,
      primaryExtension: false,
      appUsers: [],
      clients: [],
      searchLoading: 0,
      chosen: false,
      chosenUser: null,
      chosenCompany: null,
      countryFilters: {
        country_id: this.$store.state.session.company.country_id
      },
      commercialOptions: [
        {
          text: 'Residential',
          value: false
        },
        {
          text: 'Commercial',
          value: true
        }
      ]
    }
  },
  watch: {
    email(val) {
      this.continued = false
      this.displaySearchResults = false
      this.clients = []
      this.appUsers = []
      this.chosenUser = null
      this.chosen = false
      if (this.emailValid) {
        this.user_email = val
      }
    }
  },
  computed: {
    emailValid() {
      if (this.email) {
        return /^\S+@\S+\.\S+$/.test(this.email)
      }

      return false
    },
    chosenOption() {
      return this.choiceOptions.find(
        (co) =>
          (co.type === 'client' && co.id === this.client_id) ||
          (co.type === 'company' && co.id === this.client_company_id) ||
          (co.type === 'user' && co.id === this.client_user_id)
      )
    },
    choiceOptions() {
      const options = []

      if (this.clients.length) {
        const cl = this.clients[0]
        if (cl.client_company_id) {
          options.push({
            name: cl.client_name,
            id: cl.client_id,
            rating: cl.client_user_rating,
            type: 'client',
            clientType: 'company',
            object: this.clients[0]
          })
        } else {
          options.push({
            name: cl.client_name,
            id: cl.client_id,
            rating: cl.client_user_rating,
            type: 'client',
            clientType: 'user',
            object: this.clients[0]
          })
        }

        return options
      }

      const companies = this.companies.map((u) => ({
        name: `${u.company_name}`,
        id: u.company_id,
        rating: u.company_rating,
        type: 'company',
        clientType: 'company',
        userId: u.user_id,
        object: u
      }))

      const users = this.appUsers.map((u) => ({
        name: `${u.user_fname} ${u.user_lname}`,
        id: u.user_id,
        rating: u.user_rating,
        type: 'user',
        clientType: 'user',
        object: u
      }))

      return [...users, ...companies]
    },
    /**
     * Provide optional validation schema that checks
     * for correct formatting and that all required
     * values have been provided. If null it will sue
     * the (src/api/schema/{type}.js).fields validation
     * values to check against.
     */
    validationSchema() {
      const common = {
        user_fname: {
          required: true
        },
        user_email: {
          type: 'email'
        }
      }

      if (this.client_status === 'l') {
        return {
          ...common,
          user_fname: {
            required: true
          }
        }
      } else if (this.client_user_id || this.client_company_id) {
        return {}
      }

      if (this.clientIsCommercial && this.client_status !== 'l') {
        common.company_name = {
          required: true
        }
      }

      return {
        ...common,
        user_lname: {
          required: true
        }
      }
    },
    companies() {
      if (!this.appUsers.length || this.getReviewer) {
        return []
      }

      return this.appUsers.reduce(
        (acc, u) => [
          ...acc,
          ...u.aoCompanies.filter(
            (co) => co.company_id && !acc.find((coo) => coo.company_id === co.company_id)
          )
        ],
        []
      )
    },
    address() {
      if (this.user_address && this.user_city) {
        return `${this.user_address ? this.user_address : ''} ${
          this.user_city ? this.user_city : ''
        } ${this.user_prov ? this.user_prov : ''} ${this.user_postal ? this.user_postal : ''} `
      }
      return ''
    }
  },
  methods: {
    skipEmailEntry() {
      this.emailSkipped = true
      this.email = null
      this.user_email = null
      this.displayClientDetails = true
    },
    async emailNextHandler() {
      this.displayClientDetails = false
      this.continued = true
      this.displaySearchResults = true
      await this.checkIfExists()
    },
    async firstNameMounted() {
      await this.$refs.userFirstName.focus()
    },
    async saveClient() {
      // Set the Client status based on whether they have an email or not
      this.client_status = this.user_email ? 'a' : 'l'
      if (!this.getReviewer) {
        await this.saveAndClose()
      } else {
        const { payload: savedClient } = await this.save()

        let userProv
        if (savedClient.province_id) {
          const { set } = await this.$store.dispatch('Province/find', {
            by: {
              province_id: savedClient.province_id
            }
          })
          userProv = set[0].province_name
        }

        const reviewerObject = {
          client_id: savedClient.client_id,
          type: 'client',
          user_fname: savedClient.user_fname,
          user_lname: savedClient.user_lname,
          user_phone: savedClient.user_phone,
          user_email: savedClient.user_email,
          user_suite: savedClient.user_suite,
          user_address: savedClient.user_address,
          user_city: savedClient.user_city,
          user_prov: userProv,
          user_postal: savedClient.user_postal,
          country_abbr: savedClient.country_abbr
        }
        this.$emit('addReviewer', reviewerObject)
      }
    },
    async chooseClient(user = null, company = null, client = null) {
      if (client) {
        this.chosenUser = client
        this.fillUpstreamChanges(
          {
            ...client,
            user_fname: client.client_fname,
            user_lname: client.client_lname
          },
          true
        )
        this.chosen = true
        if (this.getReviewer) {
          const reviewerObject = {
            client_id: client.client_id,
            type: 'client'
          }

          if (client.client_user_id) {
            const { object: us } = await this.$store.dispatch('Client/fetch', {
              id: client.client_id
            })

            if (us) {
              reviewerObject.user_fname = us.user_fname
              reviewerObject.user_lname = us.user_lname
              reviewerObject.user_phone = us.user_phone
              reviewerObject.user_email = us.user_email
              reviewerObject.user_suite = us.user_suite
              reviewerObject.user_address = us.user_address
              reviewerObject.user_city = us.user_city
              reviewerObject.user_prov = us.user_prov
              reviewerObject.user_postal = us.user_postal
            }
          }

          this.$emit('addReviewer', reviewerObject)
        }
      } else {
        this.client_status = 'a'
        this.client_company_id = company ? company.company_id : null
        this.client_user_id = user ? user.user_id : this.client_user_id || this.appUsers[0].user_id
        this.chosen = true
      }

      this.displayClientDetails = true
    },
    async checkIfExists() {
      this.searchLoading = 1
      this.appUsers = []
      this.clients = []

      this.clients = await this.findUsingEmail('client')

      if (this.clients.length) {
        this.searchLoading = 0
        return
      }

      this.appUsers = await this.findUsingEmail('user')

      if (this.appUsers.length) {
        this.searchLoading = 0
        return
      }
      this.searchLoading = 0
      this.displayClientDetails = true
    },
    async findUsingEmail(entityType = 'user') {
      if (!this.user_email || !this.emailValid) return []

      const titleType = c.titleCase(entityType)
      const { set } = await this.$store.dispatch(`${titleType}/find`, {
        by: {
          user_email: this.user_email
        }
      })
      return set
    }
  },
  components: {
    Btn,
    Field
  },
  props: {
    getReviewer: {
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
