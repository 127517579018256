<template>
  <!-- Root container -->
  <div class="flex flex-col w-full h-full overflow-hidden">
    <!-- Traverse body -->
    <Splitter
      class="border-t h-full max-h-full overflow-y-clip !bg-transparent overflow-hidden"
      :gutterSize="sideOpenSetting ? 4 : 0"
    >
      <!-- Traverse tree -->
      <SplitterPanel
        v-show="sideOpenSetting"
        :size="30"
        class="scrollbar-hide w-full md:min-w-96 md:max-w-[50%] pt-4 h-full overflow-y-auto pb-20"
      >
        <div class="flex flex-col w-full gap-6 justify-start items-start pl-4">
          <div class="flex justify-stretch items-center gap-1 pr-4 w-full">
            <Btn
              tooltip="Toggle sidebar"
              class="grow-0 shrink-0 !size-10"
              size="lg"
              link
              @click="sideOpenSetting = !sideOpenSetting"
            >
              <Icon icon="sidebar" size="lg" />
            </Btn>

            <IconField iconPosition="left" class="!grow">
              <InputIcon>
                <icon icon="search" class="text-surface-800" />
              </InputIcon>
              <InputText
                v-model="searchPhrase"
                placeholder="Search your catalog..."
                size="large"
                type="text"
              />
            </IconField>
            <Btn
              v-if="!modal"
              link
              size="lg"
              :action="create"
              tooltip="Create a new item, assembly or category"
              class="grow-0 shrink-0 !size-10"
            >
              <font-awesome-icon icon="fas fa-plus" />
            </Btn>
          </div>

          <div class="flex flex-col w-full gap-2">
            <span class="text-lg font-medium flex gap-2 items-center"
              ><font-awesome-icon icon="box-open-full" size="sm" /> Catalogs</span
            >
            <TraverseTree
              @input="treeInputHandler"
              :value="parent"
              refs="tree"
              ref="tree"
              :searchPhrase="searchPhrase"
              :modal="modal"
              :company="company || null"
              :hideGlobalPublic="publicGlobalHidden"
              :isFranchisor="isFranchisor"
            />
          </div>

          <div class="flex flex-col w-full gap-2 select-none">
            <span class="text-lg font-medium flex gap-2 items-center"
              ><font-awesome-icon icon="filter" size="sm" /> Filters</span
            >
            <TraverseAutoCostFilters
              v-if="inAutoCostCategory && (autocostVendors || inGlobalScope)"
              v-model="autocostVendors"
              :defaultDistance="autocostDistance"
              @distance-input="(input) => (autocostDistance = input)"
              @applyFilters="$refs.list.reload()"
            />
            <TraverseRefinementFilters
              v-else-if="!inAutoCostCategory"
              v-model="aggregationResults"
              :aggregations-schema="aggregations"
            />
          </div>
        </div>
      </SplitterPanel>

      <!-- Traverse gallery -->
      <SplitterPanel :size="70" v-show="(!sideOpenSetting && smallFormat) || !smallFormat">
        <Splitter :gutter-size="0">
          <SplitterPanel
            v-show="!sideOpenSetting"
            :size="5"
            class="scrollbar-hide mt-2 ml-2 overflow-y-auto pb-20 min-w-[60px]"
          >
            <Btn
              link
              size="xl"
              @click="sideOpenSetting = true"
              tooltip="Show sidebar"
              class="grow-0 shrink-0 !size-10"
            >
              <Icon icon="sidebar" size="md" />
            </Btn>
          </SplitterPanel>

          <SplitterPanel size="100" class="overflow-y-auto pb-20">
            <div class="pb-[env(safe-area-inset-bottom, 0)]">
              <div
                class="flex flex-col items-center justify-center p-4"
                v-if="!loading && searchPhrase && !inAutoCostCategory"
              >
                <SuggestionButton
                  v-if="
                    !loading && (searchPhrase || parent) && Object.keys(refinementFilters).length
                  "
                  class="danger my-1"
                  @click="clearRefinementFilters()"
                >
                  Your filters are restricting the results of your search. Clear filters?
                </SuggestionButton>

                <SuggestionButton
                  class="danger my-1"
                  @click="parent = null"
                  v-else-if="
                    !loading &&
                    searchPhrase &&
                    parent &&
                    parent !== 'NULL' &&
                    parent !== '0||NULL' &&
                    parent !== 'NULL||0'
                  "
                >
                  You are searching inside of&nbsp;<name
                    :id="parent"
                    type="cost_type"
                    get-individual
                    default="a sub-category"
                  />. Search everywhere instead?
                </SuggestionButton>
              </div>

              <!-- Item list -->
              <TraverseList
                ref="list"
                @loading="loadingHandler"
                @select="selectHandler"
                @aggregations="aggregationsHandler"
                @dropped="reset"
                @setParent="(newParent) => (parent = newParent)"
                :aggregations="Object.keys(aggregations)"
                :searchPhrase="searchPhrase"
                :selected="selected"
                :filters="listFilters"
                :autocost-filters="autocostFilters"
                :autocostVendors="autocostVendors"
                :limit="40"
                :modal="modal"
              />

              <!-- Loading spinner -->
              <div v-if="loading" class="flex w-full mt-64 justify-center items-center">
                <Spinner :loading="true" size="4em" />
              </div>
            </div>
          </SplitterPanel>
        </Splitter>
      </SplitterPanel>
    </Splitter>
  </div>
</template>

<script>
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import eventBus from '@/eventBus'
import UserMeta from '../../mixins/UserMeta'
import TraverseTree from './TraverseTree.vue'
import TraverseList from './TraverseList.vue'
import TraverseRefinementFilters from './TraverseRefinementFilters.vue'
import TraverseAutoCostFilters from './TraverseAutoCostFilters.vue'
import SuggestionButton from '../SuggestionButton.vue'
import AutoCost from '../../../../imports/api/AutoCost'

import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import { useMediaQuery } from '@/composables/mediaQuery'
import { SET_TRAVERSE_START_AT } from '@/store/mutation-types'

const getBucketsFromAgg = (agg) => {
  const isArray = Array.isArray(agg.buckets)
  return [isArray ? agg.buckets : Object.values(agg.buckets), Object.keys(agg.buckets), isArray]
}

export default {
  mixins: [UserMeta],
  components: {
    SuggestionButton,
    TraverseRefinementFilters,
    TraverseList,
    TraverseTree,
    TraverseAutoCostFilters,
    Splitter,
    SplitterPanel,
    InputIcon,
    IconField,
    InputText
  },
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  beforeUnmount() {
    this.close()
  },

  computed: {
    sideOpen() {
      return !this.smallFormat || this.sideOpenSetting
    },
    isAmerican() {
      const session = this.$store.state.session
      return session.company && session.company.country_id === 2
    },
    isSuper() {
      const session = this.$store.state.session
      return session.authorizedUser && session.authorizedUser.user_is_super_user
    },
    inGlobalScope() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.user.company_id
      )
    },
    inAutoCostCategory() {
      return AutoCost.isAutoCostCategory(this.parent)
    },
    isFranchisor() {
      return !!this.$store.state.session.scope.franchisor
    },
    quoteObject() {
      const state = this.$store.state
      if (!state || !state.Quote || !state.Quote.normalized) return {}
      const filter = Object.values(state.Quote.normalized).filter((obj) => obj.type === 'quote')
      return filter[0]
    },
    listFilters() {
      return {
        ...this.filters,
        ...this.refinementFilters,
        ...(this.parent ? { parent_cost_type_id: this.parent } : {}),
        ...(this.company ? { company_id: this.company } : {}),
        ...(this.publicHidden ? { company_id: this.$store.state.session.company.company_id } : {}),
        cost_type_is_parent: 'NULL||0'
      }
    },
    autocostFilters() {
      const vendorFilter = Object.values(this.autocostVendors)
        .filter((vendor) => vendor.search)
        .map((vendor) => vendor.vendorId)
      return {
        vendor: vendorFilter,
        distance: this.autocostDistance
      }
    },
    hasRefinementFilters() {
      const results = _.imm(this.aggregationResults)

      let has = false
      Object.keys(results).forEach((key) => {
        const [buckets] = getBucketsFromAgg(results[key])

        if (buckets.some((bucket) => bucket.filter)) {
          has = true
        }
      })

      return has
    },

    refinementFilters() {
      const results = _.imm(this.aggregationResults)
      const filters = {}

      const addFilters = (add, filter, joiner) => `${filter ? `${filter}${joiner}` : filter}${add}`

      Object.keys(results).forEach((key) => {
        const agg = results[key]
        const buckets = Array.isArray(agg.buckets) ? agg.buckets : Object.values(agg.buckets)

        buckets.forEach((bucket) => {
          if (!bucket.filter || !bucket) return

          if (bucket.from || bucket.to) {
            let filterValue = ''

            if (bucket.from) filterValue = `>=${bucket.from}`
            if (bucket.from && bucket.to) filterValue = `${filterValue}&&`
            if (bucket.to) filterValue = `${filterValue}<=${bucket.to}`

            filters[key] = addFilters(
              filterValue,
              filters[key] || '',
              this.aggregations[key].joiner
            )
            return
          }

          // With bucket.key
          if (bucket && bucket.key) {
            filters[key] = addFilters(bucket.key, filters[key] || '', this.aggregations[key].joiner)
          }
        })
      })

      return filters
    }
  },

  data() {
    return {
      autocostVendors: {},
      autocostDistance: AutoCost.getAutoCostCountries(this.$store.state.session.company).includes(2)
        ? '100mi'
        : '100km',
      sideOpenSetting: true,
      loading: 0,
      openPromise: null,
      publicHidden: 0,
      publicGlobalHidden: 1,
      openResolve: () => {},
      selected: [],
      parent: null,
      company: this.startingCompany,
      searchPhrase: '',
      aggregationResults: {},
      aggregations: {}
    }
  },

  beforeMount() {
    this.aggregations = {
      ...(this.$store.state.session.scope.company
        ? {
            company_id: {
              title: 'Source',
              joiner: '||',
              includeUnknown: true,
              key: (bucket) => {
                if (bucket && bucket.key === 'NULL') {
                  return 'Bolster items'
                }

                return 'Your items'
              }
            }
          }
        : {}),
      type: {
        title: 'Format',
        joiner: '||',
        includeUnknown: false,
        key: (bucket) => {
          if (bucket && String(bucket.key) === 'cost_type') {
            return 'Item'
          }

          return 'Assembly'
        }
      },
      stage_name: {
        includeUnknown: 'None/multiple',
        title: 'Item/trade type',
        joiner: '||'
      },
      // trade_type_name: {},
      item_type_manufacturer_name: {
        includeUnknown: 'None',
        title: 'Manufacturer',
        joiner: '||'
      },
      item_type_measure_type: {
        includeUnknown: false,
        title: 'Measure type',
        joiner: '||'
      },
      item_type_unit_of_measure_abbr: {
        includeUnknown: false,
        title: 'Quantity unit type',
        joiner: '||'
      },
      item_type_has_labor: {
        title: 'Includes labor',
        joiner: '||',
        group: 'costtypes',
        key: (bucket) => {
          if (bucket && String(bucket.key) === '1') {
            return 'Includes labor'
          }

          return 'Labor not included'
        }
      },
      item_type_has_materials: {
        title: 'Includes materials',
        joiner: '||',
        group: 'costtypes',
        key: (bucket) => {
          if (bucket && String(bucket.key) === '1') {
            return 'Includes materials'
          }

          return 'Materials not included'
        }
      },
      cost_type_is_addon_group: {
        title: 'Includes option groups',
        joiner: '||',
        group: 'costtypes',
        key: (bucket) => {
          if (bucket && String(bucket.key) === '1') {
            return 'Includes option groups'
          }

          return 'Option groups not included'
        }
      },
      item_type_price_net: {
        title: 'Price (approx)',
        joiner: '||'
      },
      item_type_profit_net: {
        title: 'Profit (approx)',
        joiner: '||'
      },
      item_type_cost_net: {
        title: 'Cost (approx)',
        joiner: '||'
      },
      item_type_rating: {
        title: 'Rating',
        joiner: '||'
      },
      item_type_count_reviews: {
        title: 'Number of reviews',
        joiner: '||'
      }
    }
  },

  watch: {
    parent(id) {
      this.$store.commit(SET_TRAVERSE_START_AT, { startAt: id })
    },
    company(id) {
      if (this.publicHidden && !id) {
        this.company = this.$store.state.session.company.company_id
        return
      }
      c.throttle(() => this.saveDefaultSettings())
    }
  },

  async created() {
    this.setParent()
    eventBus.$on('traverse-reload', () => {
      c.throttle(
        () => {
          this.$refs.list?.reload()
        },
        { delay: 400 }
      )
    })
  },

  async mounted() {
    this.sideOpenSetting = !this.smallFormat
    // eslint-disable-next-line
    this.openPromise = new Promise(async (resolve) => {
      this.openResolve = resolve
      this.publicHidden = (await this.$store.dispatch('Keyvalue/get', 'HidePublicItems')) || 0
      this.publicGlobalHidden = !(await this.$store.dispatch(
        'Keyvalue/get',
        'EnableCostcertifiedLibrary'
      ))
      this.company = this.publicHidden ? this.$store.state.session.company.company_id : this.company
    })
    await this.fetchAutoCostVendors()
    return this.openPromise
  },

  methods: {
    async create() {
      const chosenValue = await this.$store.dispatch('modal/asyncConfirm', {
        choices: [
          {
            title: 'New item',
            icon: 'cube',
            desc: 'Create a regular item, that has some sort of cost and price associated to it. You can add items to your estimates or assemblies.',
            value: 'item'
          },
          {
            title: 'New assembly',
            icon: 'cubes',
            desc: 'Create an assembly which is just a templated group of items and other assemblies. You can re-use your assemblies inside of other assemblies, or just add them to new estimates.',
            value: 'assembly'
          },
          {
            title: 'New category',
            icon: 'folder-open',
            desc: 'Create a new folder inside your catalog, so you can organize your items and assemblies in the way that makes most sense for you.',
            value: 'category'
          },
          ...(this.$store.state.session?.authorizedUser?.user_is_super_user
            ? [
                {
                  title: 'Company transfer',
                  icon: 'swap',
                  desc: 'Super users only: swap items between companies',
                  value: 'transfer'
                }
              ]
            : [])
        ],
        message: 'Choose what you would like to create'
      })

      if (!chosenValue) return

      switch (chosenValue) {
        case 'item':
          await this.newItem()
          break
        case 'assembly':
          await this.newAssembly()
          break
        case 'category':
          break
        case 'transfer':
          this.$store.dispatch('to', '/copyitems')
          break
      }
    },

    async newCategory() {
      const category = this.$refs.tree.value
      await this.$store.dispatch('create', {
        type: 'cost_type',
        embue: {
          parent_cost_type_id: category,
          cost_type_is_parent: 1
        },
        go: false
      })
      await this.$nextTick()
      this.$refs.list.reload()
    },

    async newItem() {
      const category = this.$refs.tree.value
      await this.$store.dispatch('create', {
        type: 'cost_type',
        embue: {
          parent_cost_type_id: category
        },
        go: false
      })
      await this.$nextTick()
      this.$refs.list.reload()
    },

    async newAssembly() {
      const category = this.$refs.tree.value
      await this.$store.dispatch('create', {
        type: 'assembly',
        embue: {
          parent_cost_type_id: category
        },
        go: false
      })
      await this.$nextTick()
      this.$refs.list.reload()
    },
    async fetchAutoCostVendors() {
      const response = await this.$store.dispatch('ajax', {
        path: 'live_price/getAutoCostVendors',
        data: {}
      })
      this.autocostVendors = response.payload
    },
    goToFeatureManagement() {
      const session = this.$store.state.session
      if (session.user.user_is_admin) {
        this.$store.dispatch('to', 'features')
        if (this.modal) this.modal.close()
      } else {
        this.$store.dispatch('alert', { message: 'Only Admin can manage features.' })
      }
    },
    async saveDefaultSettings() {
      this.setMetaItem(this.metaConstants.TraverseDefaultSettings, { company: this.company })
    },
    setParent() {
      this.parent = this.startingParent || this.$store.state.general.startAt || null
    },
    async done() {
      this.commitSelection()
      if (this.modal) this.modal.close()
    },
    commitSelection() {
      const selected = _.imm(this.selected)
      this.openResolve(selected)
      this.$store.dispatch('selectAndCloseItemSelector', selected)
    },
    close() {
      this.reset()
      this.$store.dispatch('selectAndCloseItemSelector', [])
    },
    async toggle() {
      if (this.openPromise) return this.close()
      return this.open()
    },
    reset() {
      this.openPromise = null
      this.openResolve = () => {}
      this.selected = []
    },

    async selectHandler(object) {
      if (object.cost_type_is_parent) {
        this.parent = object.cost_type_id
        return
      }

      const newSelected = _.imm(this.selected)
      const found = newSelected.find(
        (s) =>
          (object.type === 'cost_type' && String(s.cost_type_id) === String(object.cost_type_id)) ||
          (object.type === 'assembly' && String(s.assembly_id) === String(object.assembly_id)) ||
          (object.type === 'autocost' && String(s.autocost_id) === String(object.autocost_id))
      )
      if (found) {
        newSelected.splice(newSelected.indexOf(found), 1)
        this.selected = newSelected
        return
      }

      this.selected = [...this.selected, object]
    },

    treeInputHandler(parent, company) {
      this.parent = null
      this.company = null
      this.parent = parent
      this.company = company
      this.reset()
    },

    loadingHandler(loading) {
      this.loading = loading
    },

    clearRefinementFilters() {
      const newAggs = _.imm(this.aggregationResults)

      Object.keys(newAggs).forEach((key) => {
        Object.keys(newAggs[key].buckets).forEach((bucketKey) => {
          newAggs[key].buckets[bucketKey].filter = false
        })
      })

      this.aggregationResults = newAggs
    },

    aggregationsHandler(aggs) {
      const aggregations = _.imm(aggs)
      if (!this.hasRefinementFilters) {
        this.aggregationResults = aggregations
        return
      }

      const current = _.imm(this.aggregationResults)
      const findBucketIndex = (buckets, bucket) => {
        if (!bucket) return -1
        const found = buckets.find((b) => b && b.key === bucket.key)
        return buckets.indexOf(found)
      }

      Object.keys(current).forEach((key) => {
        const [currentBuckets, keys, isArray] = getBucketsFromAgg(current[key])

        currentBuckets.forEach((bucket, index) => {
          if (!bucket.filter) return

          const bucketKey = !isArray
            ? keys[index]
            : findBucketIndex(aggregations[key].buckets, bucket)

          if ((typeof bucketKey === 'string' && bucketKey) || bucketKey > -1) {
            aggregations[key].buckets[bucketKey].filter = true
          }
        })
      })

      this.aggregationResults = aggregations
    }
  },

  props: {
    modal: {
      required: false,
      default: null
    },
    startingParent: {
      required: false,
      default: null
    },
    startingCompany: {
      required: false,
      default: null
    },
    filters: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    /**
     * When creating a new assembly or object,
     * embue these values into it.
     */
    embue: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped></style>
