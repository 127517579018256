<template>
  <div
    class="alert--container"
    @click="hide"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @mousemove="onDrag"
    @touchmove="onDrag"
    @mouseup="stopDrag"
    @touchend="stopDrag"
    @mouseout="stopDrag"
    :class="{ error: alert.error, muted: alert.muted, warning: alert.warning }"
    :style="style"
  >
    <div class="alert--content">
      <checkmark class="alert--spinner alert--checkmark" v-if="alert.success" :size="30" />
      <spinner class="light alert--spinner" v-if="alert.loading" :loading="1" />
      <div class="alert--message" :class="msg.length < 50 ? 'superstrong' : ''">
        <span class="m-0" v-text="msg" style="white-space: pre-wrap; max-width: 100%"></span>
      </div>
      <ul class="alert--warning" v-if="alert.warnings.length">
        <li v-for="(warning, index) in alert.warnings" :key="index">
          {{ warning }}
        </li>
      </ul>

      <SuggestionButton v-if="!alert.timeout" @click="hide()">Dismiss</SuggestionButton>
    </div>
  </div>
</template>

<script>
import SuggestionButton from './SuggestionButton.vue'

export default {
  name: 'Alert',
  components: { SuggestionButton },
  props: ['alert'],
  computed: {
    msg() {
      return c.removeHtml(this.alert.text || this.alert.message).replace(/ {2,}/gi, ' ')
    },
    opacity() {
      return this.dragging && this.offset && this.offset.x
        ? 1 - (this.offset.x / this.destroyAt) * 0.5
        : 1
    },
    style() {
      if (this.dragging && this.offset && this.offset.x) {
        return `position: relative; left:${this.offset.x}px; transition: none; opacity:${this.opacity};`
      }
      return `position: relative; left:0px; transition: all 1s; opacity:${this.opacity};`
    }
  },
  data() {
    return {
      dragging: false,
      offset: {},
      startingPosition: {},
      startingClick: {},
      destroyAt: 600,
      destroying: false,
      destroyTranslate: 0
    }
  },
  methods: {
    startDrag(e) {
      const rect = this.$el.getBoundingClientRect()
      this.destroyAt = rect.width / 2
      this.startingPosition = { x: rect.left }
      this.startingClick = { x: e.clientX }
      this.offset = { x: 0 }
      this.dragging = true
    },
    onDrag(e) {
      if (this.dragging) {
        this.offset = {
          x: e.clientX - this.startingClick.x < 0 ? 0 : e.clientX - this.startingClick.x
        }
        if (this.destroyAt && this.offset.x >= this.destroyAt) {
          this.destroying = true
          this.hide()
        }
      }
    },
    stopDrag() {
      if (this.dragging) {
        this.dragging = false
      }
    },
    hide() {
      this.dragging = false
      this.$store.dispatch('removeAlert', { alert: this.alert })
    }
  },
  mounted() {
    if (this.alert.timeout) {
      setTimeout(() => {
        this.hide()
      }, this.alert.timeout)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
$margin: 2em;
$padding: 2em 2em;
$borderRadius: 10px;

.alert--container {
  width: auto;
  max-width: 400px;
  border-radius: $borderRadius;
  background: rgba($pitch-black, 0.75);
  min-height: 3em;
  padding: $padding;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: all;

  .alert--checkmark {
    zoom: 0.5;
  }
  .alert--spinner {
    margin-right: 1em;
  }

  .alert--content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .alert--message {
    color: $flame-white;
    font-weight: normal;
    font-size: 0.9em;
  }

  .alert--warning {
  }

  &.muted {
    background: rgba($pitch-black, 0.7);
    font-size: 0.7em;
  }

  &.error {
    background: rgba($deep-red-500, 0.7);
  }

  &.warning {
    background: rgba($orange-500, 0.7);

    .alert--message {
      color: $pitch-black;
    }
  }
}
</style>
