// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers'

const defaultFormFields = [
  'user_fname',
  'user_lname',
  'user_email',
  'user_phone',
  'activity_desc',
  'project_type',
  'project_budget',
  'project_start'
]

const formFieldsData = {
  user_fname: {
    label: 'First Name',
    type: 'text',
    desc: ''
  },
  user_lname: {
    label: 'Last Name',
    type: 'text',
    desc: ''
  },
  user_email: {
    label: 'Email',
    type: 'text',
    desc: ''
  },
  user_phone: {
    label: 'Phone',
    type: 'text',
    desc: ''
  },
  user_suite: {
    label: 'Suite',
    type: 'text',
    desc: ''
  },
  user_address: {
    label: 'Address',
    type: 'text',
    desc: ''
  },
  user_city: {
    label: 'City',
    type: 'text',
    desc: ''
  },
  user_postal: {
    label: 'Zip Code / Postal Code',
    type: 'text',
    desc: ''
  },
  activity_desc: {
    label: 'Project Details',
    type: 'textarea',
    desc: 'Tell us about your project.'
  },
  project_type: {
    label: 'Project Type',
    type: 'textarea',
    desc: 'Tell us the type of project you need (ex. basement renovation).'
  },
  project_budget: {
    label: 'Project Budget',
    type: 'textarea',
    desc: 'Tell us your budget for the project.'
  },
  project_start: {
    label: 'Project Timeline',
    type: 'textarea',
    desc: 'Tell us when you want the project to start and any other important dates.'
  }
}

export default {
  type: 'lead_rotation',

  actions: {
    changeStatus: {
      visible: () => false
    }
  },

  skipAudit: true,

  fields: {
    lead_rotation_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewLeadRotation'
    },
    lead_rotation_name: {
      type: 'string',
      filter: true,
      save: true,
      required: true
    },
    lead_rotation_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_rotation_user_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'user',
      normalize: false
    },
    lead_rotation_notify_each_user_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'user',
      normalize: false
    },
    lead_rotation_url: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      reload: true
    },
    lead_rotation_form_url: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      reload: true
    },
    lead_rotation_post_url: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      reload: true
    },
    lead_rotation_success_url: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    aoUserNames: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'user',
      save: false,
      trackChanges: false
    },
    asFormFields: {
      type: 'array',
      default: () => _.imm(defaultFormFields),
      save: true,
      saveChanges: true,
      trackChanges: true,
      deep: true
    },
    aoFields: {
      type: 'array',
      default: () => [],
      save: true,
      saveChanges: true,
      trackChanges: true,
      deep: true
    },
    oForm: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'form'
    },
    aoFieldOptions: {
      type: 'array',
      default: () => [],
      save: true,
      saveChanges: true,
      trackChanges: true,
      deep: true
    },
    form_id: {
      type: 'int',
      save: true,
      saveChanges: true,
      trackChanges: true,
      mapTo: 'form'
    },
    lead_source_id: {
      type: 'int',
      save: true,
      saveChanges: true,
      trackChanges: true,
      mapTo: 'lead_source'
    }
  },

  generateVueActions() {
    return {
      goToCreatePage({ dispatch }) {
        dispatch('to', 'create/lead_rotation', { root: true })
        dispatch('modal/closeAll', { confirm: true }, { root: true })
      },
      async create({ dispatch, rootState }, payload) {
        const company = rootState.session.company
        const user = rootState.session.user
        const object = {
          lead_rotation_name: `${company.company_name} Lead Form`,
          lead_rotation_user_ids: [user.user_id],
          asFormFields: defaultFormFields
        }

        const resp = await dispatch('ajax', {
          path: `lead_rotation/save`,
          data: { object }
        })

        if (!resp || !resp.payload || !resp.payload.lead_rotation_id) {
          return dispatch(
            'alert',
            { message: 'Could not create your form, please try again.', error: true },
            { root: true }
          )
        }

        return dispatch('edit', {
          ...payload,
          type: 'lead_rotation',
          id: resp.payload.lead_rotation_id
        })
      },
      getFormFieldsData() {
        return formFieldsData
      },
      getDefaultFormFields() {
        return defaultFormFields
      }
    }
  }
}
