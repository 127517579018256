<script setup>
import TieredMenu from 'primevue/tieredmenu'
import Saving from '@/components/headers/Saving.js'
import { computed, ref, watchEffect, onMounted } from 'vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { useStore } from 'vuex'
import UnapprovedChanges from '@/components/Sheets/quote/estimating/UnapprovedChanges'

const $store = useStore()
const refChanges = ref(null)
const { refId, changes, isDirty, type, storeName, isInPresentation } =
  RouteEntityContext.useRouteEntityContext({
    trackChanges: true
  })
const { save /* resetChanges */ } = Saving.useSaving({
  refId,
  changes,
  isDirty,
  type,
  storeName,
  refChanges
})
const {
  quote_id,
  change_order_client_has_approved,
  change_order_company_has_approved,
  change_order_id,
  change_order_time_sent,
  quote_state
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})
const { unapprovedItems } = UnapprovedChanges.useUnapprovedChanges({
  refId,
  store: 'Quote'
})

const label = ref('Save')
const severity = ref('bolster')
const disabled = ref(false)
const action = ref(() => {})

const canApproveForCompany = computed(() => $store.state.session.user.aUserPerms.quote.approve)
const needsManagerApproval = computed(() => !change_order_company_has_approved.value)
const needsClientApproval = computed(() => !change_order_client_has_approved.value)
const isRequestForEstimate = computed(() => quote_state.value === 'lead')
const unsentChanges = computed(() => !change_order_time_sent.value)

const scheduleNotifications = computed(
  () =>
    $store.state.eventNotification &&
    $store.state.eventNotification.notifications &&
    $store.state.eventNotification.notifications.schedule
)

const handleSend = async () => {
  await $store.dispatch('modal/open', {
    modal: 'QuoteSend',
    objects: [{ quote_id: quote_id.value }]
  })
}

const approveChanges = async () => {
  await $store.dispatch('ChangeOrder/markApprovedByCompany', {
    id: change_order_id.value
  })
}

const mutedclass = '!text-surface-400'
const activeclass = '!text-level-yellow'
const items = computed(() => {
  const formattedItems = [
    {
      label: 'No changes to save.',
      icon: 'fa fa-house-user',
      class: mutedclass
    }
  ]

  if (isDirty.value) {
    formattedItems[0] = {
      label: 'Save changes',
      icon: 'fa fa-house-user',
      class: activeclass,
      command: save
    }
  }

  if (
    needsClientApproval.value &&
    unsentChanges.value &&
    unapprovedItems.value.length &&
    !isRequestForEstimate.value
  ) {
    formattedItems[1] = {
      label: 'Review and send',
      icon: 'fa fa-house-user',
      class: activeclass,
      command: handleSend
    }
  } else if (
    needsClientApproval.value &&
    !unsentChanges.value &&
    unapprovedItems.value.length &&
    !isRequestForEstimate.value
  ) {
    formattedItems[1] = {
      label: 'Send again',
      icon: 'fa fa-house-user',
      class: activeclass,
      command: handleSend
    }
  }

  if (canApproveForCompany.value && needsManagerApproval.value) {
    formattedItems.splice(1, 0, {
      label: 'Approve as manager',
      icon: 'fa fa-house-user',
      class: activeclass,
      command: approveChanges
    })
  } else if (needsManagerApproval.value) {
    formattedItems.splice(1, 0, {
      label: 'Requires manager approval',
      icon: 'fa fa-house-user',
      class: activeclass,
      command: () => {}
    })
  }

  if (scheduleNotifications.value && scheduleNotifications.value.length > 0) {
    formattedItems.splice(1, 0, {
      label: 'Send schedule notifications',
      icon: 'fa fa-users',
      class: activeclass,
      command: () => {
        $store.dispatch('EventNotification/sendByType', {
          type: 'schedule',
          quoteId: quote_id.value
        })
      }
    })
  }

  return formattedItems
})

const setSteps = () => {
  const found = items.value.find((item) => item.class === activeclass)

  label.value = 'All up to date'
  severity.value = 'secondary'
  disabled.value = true
  action.value = () => {}

  if (found) {
    label.value = found.label
    severity.value = 'bolster'
    disabled.value = false
    action.value = found.command
  }
}

const refToggleButton = ref(null)
const toggle = () => {
  menu.value.toggle({ currentTarget: refToggleButton.value.$el })
}
const menu = ref()

watchEffect(() => {
  setSteps()
})

onMounted(() => {
  $store.dispatch('EventNotification/checkForNotifications', {
    type: 'schedule',
    quoteId: quote_id.value
  })
})
</script>

<template>
  <div class="flex">
    <Btn
      class="rounded-r-none hidden lg:inline-flex"
      :class="{ '!inline-flex': isInPresentation }"
      :action="action"
      :disabled="disabled"
      :severity="severity"
      :size="isInPresentation ? 'md' : 'lg'"
      hotkey="cmd-s"
      >{{ label }}
    </Btn>
    <Btn
      v-if="!isInPresentation"
      ref="refToggleButton"
      class="lg:rounded-l-none"
      @click="toggle"
      :disabled="disabled"
      :severity="severity"
      size="lg"
    >
      <span class="lg:hidden">
        <font-awesome-icon icon="ellipsis" />
      </span>
      <span class="hidden lg:inline-block">
        <font-awesome-icon icon="chevron-down" />
      </span>
    </Btn>

    <TieredMenu ref="menu" id="overlay_tmenu" :model="items" popup />
  </div>
</template>

<style scoped lang="scss"></style>
