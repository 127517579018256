import { SET_COUNTERPARTY_DATA } from '@/store/mutation-types'

const state = () => ({
  counterpartyData: {}
})

const mutations = {
  SET_COUNTERPARTY_DATA(state, { companyId, data }) {
    state.counterpartyData[companyId] = data
  }
}

const actions = {
  setCounterpartyData({ commit }, payload) {
    commit(SET_COUNTERPARTY_DATA, payload)
  }
}

const getters = {
  getCounterpartyDataByCompanyId: (state) => (companyId) => {
    return state.counterpartyData[companyId] || null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
