import { getContext } from '@/SuggestionEngine/Context.js'
import { store } from '../store'

export const fields = {}
export const register = (SuggestionExport) => {
  const required = ['getSuggestion', 'fieldName']

  if (required.some((f) => !(f in SuggestionExport))) {
    throw new Error('Missing required fields in SuggestionExport object')
  }

  fields[SuggestionExport.fieldName] = SuggestionExport.getSuggestion
}

export const buildEntitySuggester = (providedRegister) => {
  return async (context) => {
    const { object, fields, override = true } = context
    let cumulative = object

    const suggestors = providedRegister.fields

    const results = {}
    const bestResult = {}
    await _.waterfall(
      fields.map((field) => async () => {
        if (!override && (object[field] ?? true) !== true) {
          results[field] = {
            error: {
              message: `Field already set, with override turned off: ${field}`
            }
          }
        } else if (!(field in suggestors)) {
          results[field] = {
            error: {
              message: `No suggestor found for field ${field}`
            }
          }
        } else {
          cumulative = {
            ...cumulative,
            ...bestResult
          }
          const result = await suggestors[field]({
            $store: store,
            ...context,
            object: cumulative,
            results,
            bestResult
          })
          results[field] = result
          bestResult[field] = result.best
        }
      })
    )

    return {
      bestResult,
      results
    }
  }
}

export default {
  buildEntitySuggester,
  register,
  fields,
  output: (registry) => ({
    fields,
    suggest: buildEntitySuggester(registry)
  })
}
