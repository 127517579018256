<template>
  <div
    class="bg-flame-white text-base font-normal md:font-light"
    :class="summaryClasses"
    ref="quoteSummary"
  >
    <div class="flex flex-col justify-start" v-if="countOptions > 0">
      <div class="w-full flex flex-col gap-1">
        <div class="w-full flex justify-between">
          <span>{{ l('Items included') }}</span>
          <span>{{ items.length }}</span>
        </div>
        <div class="w-full flex justify-between" v-if="exclusions.length">
          <span>{{ l('Items excluded') }}</span>
          <span>{{ exclusions.length }}</span>
        </div>
        <div class="w-full flex justify-between">
          <span>{{ l('Optional items') }}</span>
          <span>{{ countOptions }}</span>
        </div>
      </div>
      <hr class="my-3" />
      <div>
        <div
          class="w-full flex justify-between"
          v-if="quote_profit_net >= 0.01 && mergedSettings.showBundledProfit"
        >
          <span>{{
            l(
              typeof mergedSettings.showBundledProfit === 'string'
                ? mergedSettings.showBundledProfit
                : 'Management fee'
            )
          }}</span>
          <span class=""
            >$
            {{
              $f.currency(quote_price_net - quote_total_cost_net + (quote_discount_net || 0))
            }}</span
          >
        </div>
        <div class="w-full flex flex-col gap-1">
          <div class="w-full flex justify-between" v-if="quote_discount_net > 0.0">
            <span>{{ l('Discount') }}</span>
            <span class="discount">$ {{ $f.currency(quote_discount_net) }}</span>
          </div>
          <div class="w-full flex justify-between" v-if="quote_price_tax > 0">
            <span>{{ l('Subtotal') }}</span>
            <span>$ {{ $f.currency(quote_price_net) }}</span>
          </div>
          <template v-if="presentationSettings.showItemSpecificTax">
            <div v-for="tax in taxes" :key="tax.key">
              <div class="w-full flex justify-between">
                <span>{{ tax.name }}</span>
                <span style="white-space: nowrap">$ {{ $f.currency(tax.sum) }}</span>
              </div>
              <span
                class="text-sm md:text-xs text-surface-600 md:text-surface-500"
                v-if="languageSelected.includes('en_')"
              >
                Tax on {{ friendlyNameTaxOn(tax.on) }} derived from
                {{ friendlyNameTaxType(tax.type) }}
              </span>
            </div>
          </template>
          <template v-else>
            <div class="w-full flex justify-between">
              <span>{{ l('Tax') }}</span>
              <span>$ {{ $f.currency(quote_tax) }}</span>
            </div>
          </template>
          <hr class="my-3" />
          <div class="w-full flex justify-between font-medium mr-6">
            <span>{{ l('Project total') }}</span>
            <span>$ {{ $f.$(quote_price_gross, 2, currency_iso) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import TranslationMixin from './languages/TranslationMixin'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields'
import { useMediaQuery } from '@/composables/mediaQuery'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'PriceSummary',
  mixins: [TranslationMixin],
  props: {
    changeOrderNotApproved: Boolean,
    isInQuoteEditor: Boolean,
    countOptions: Number,
    items: Array,
    exclusions: Array,
    presentationSettings: Object,
    mergedSettings: Object,
    quoteId: Number,
    normRefId: String
  },
  emits: ['close-summary'],
  setup(props, { emit }) {
    const store = useStore()
    const { refId, storeName } = RouteEntityContext.useRouteEntityContext({ trackChanges: false })

    const quoteRefId = computed(() => refId.value || props.normRefId)

    const { smallFormat } = useMediaQuery()

    const {
      quote_profit_net,
      quote_total_cost_net,
      quote_discount_net,
      quote_price_tax,
      quote_price_net,
      quote_tax,
      quote_price_gross,
      currency_iso
    } = EntityComputedFields.useEntityComputedFields({
      refId: quoteRefId,
      type: 'Quote',
      store: storeName
    })

    const taxes = computed(() => [
      { key: 'tax1', name: 'GST', sum: quote_tax.value * 0.05, on: 'all', type: 'general' }
    ])

    const emitCloseSummary = () => {
      emit('close-summary')
    }

    const friendlyNameTaxOn = (taxOn) => {
      if (taxOn === 'all') return 'prices'
      if (taxOn === 'cost') return 'costs'
      if (taxOn === 'profit') return 'profits'

      return taxOn
    }

    const friendlyNameTaxType = (taxType) => {
      if (taxType === 'ihlt') return 'labor'
      if (taxType === 'mt') return 'materials'
      if (taxType === 'slt') return 'subcontracting'

      return 'general'
    }

    const priceSummaryClasses = computed(() => {
      if (smallFormat.value) {
        return '!right-0 max-w-screen w-screen inset-x-0 bottom-0'
      }
      if (props.changeOrderNotApproved && !props.isInQuoteEditor) {
        return 'right-64 max-w-[450px] bottom-[90px]'
      }
      return 'right-4 max-w-[450px] bottom-[90px]'
    })

    const zIndex = computed(() => {
      return store?.state?.modal?.topZIndex + 1000
    })

    const containerClasses = computed(() => {
      return smallFormat.value
        ? 'p-3'
        : 'fixed p-4 rounded shadow-md border border-surface-100 max-w-[450px] bottom-[90px] z-50'
    })

    const positioningClasses = computed(() => {
      return props.changeOrderNotApproved && !props.isInQuoteEditor ? 'right-64' : 'right-4'
    })

    const summaryClasses = computed(() => {
      return [containerClasses.value, positioningClasses.value]
    })

    return {
      smallFormat,
      quote_profit_net,
      quote_total_cost_net,
      quote_discount_net,
      quote_price_tax,
      quote_price_net,
      quote_tax,
      quote_price_gross,
      currency_iso,
      taxes,
      emitCloseSummary,
      friendlyNameTaxOn,
      friendlyNameTaxType,
      priceSummaryClasses,
      zIndex,
      summaryClasses
    }
  }
})
</script>
