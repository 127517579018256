<script setup>
import { defineProps, onBeforeMount, ref, defineOptions, computed } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'
import CurrencyFilter from '@/components/mixins/CurrencyFilter.js'
import { useStore } from 'vuex'
import CostItemVariationSelector from '@/components/quote/item/CostItemVariationSelector.vue'
import TransitionExpand from '@/components/transitions/TransitionExpand.vue'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'assembly' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  audience: { type: String, default: null }
})

defineOptions({
  mixins: [CurrencyFilter]
})
// const $store = useStore()

const refId = ref(props.refId || props.reference)
const type = ref(props.type || 'assembly')
const store = ref(props.store || props.storeName)
const $store = useStore()

const { norm, selected, setFields } = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store
})

const refsToSelect = computed(() => {
  if (norm.value[refId.value]?.parentRefId) {
    return NormalizeUtilities.sortNatural(norm.value)
  }
  return NormalizeUtilities.sortNatural(
    NormalizeUtilities.extractDescendants(norm.value, [refId.value], true)
  )
})
const refsWithSelections = computed(() =>
  refsToSelect.value.filter(
    (ref) =>
      hasSelection(norm.value[ref]) &&
      (!props.audience || getAudience(norm.value[ref]) === props.audience)
  )
)
const refsByParent = computed(() =>
  refsWithSelections.value.reduce(
    (acc, ref) => ({
      ...acc,
      [norm.value[ref].parentRefId]: [...(acc[norm.value[ref].parentRefId] ?? []), ref]
    }),
    {}
  )
)
onBeforeMount(async () => {
  await selected
  await $store.dispatch(`${store.value}/recalcAddons`, {
    refIds: refsWithSelections.value,
    loading: false
  })
})

const hasSelection = (item) =>
  item.aoAddons.length ||
  item.cost_type_is_variation_parent ||
  item.cost_item_is_optional ||
  item.assembly_is_optional
const getAudience = (item) => item.oMeta?.selectionAudience || 'client'

const toggleLock = (ref) => {
  setFields(
    {
      oMeta: {
        ...(norm.value[ref].oMeta || {}),
        lockOptions: norm.value[ref].oMeta?.lockOptions ? 0 : 1
      }
    },
    ref
  )
}
const toggleOptional = async (ref) => {
  const field = `${norm.value[ref].type}_is_optional`
  setFields(
    {
      [field]: norm.value[ref][field] ? 0 : 1
    },
    ref
  )
}
const setIsIncluded = async (ref, is = 0) => {
  const field = `${norm.value[ref].type}_is_included`
  setFields(
    {
      [field]: is
    },
    ref
  )
}
const editing = ref(null)
const swap = (ref) => {
  if (norm.value[ref].aoAddons?.length) {
    // swap for addon
  } else {
    // expand for selections
    editing.value = ref === editing.value ? null : ref
  }
}

const selectAddon = (ref, index) => {
  const addon = norm.value?.[ref]?.aoAddons[index]
  if (addon) {
    return $store.dispatch(`${store.value}/selectAddon`, {
      addonId: addon.id ?? addon.livePriceRef,
      addonType: addon.livePriceRef ? 'live_price' : addon.type,
      refId: ref
    })
  }
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <slot name="empty" v-if="!Object.keys(refsByParent).length">
      <p class="text-xl font-medium rounded px-4 py-4 bg-surface-200/50">
        No {{ audience === 'company' ? 'internal' : 'client' }} selections
      </p>
    </slot>
    <div
      v-for="(refs, parentRef) in refsByParent"
      :key="parentRef"
      class="flex flex-col overflow-auto"
    >
      <div
        class="relative min-w-[50rem] flex flex-nowrap overflow-hidden font-normal px-2 py-0.5 rounded-t-sm bg-pitch-black text-white text-lg select-none cursor-pointer"
      >
        <div class="mr-2">
          {{ norm[parentRef].asAssemblyPath[0] || 'Root' }}
        </div>
        <div
          v-for="segment in norm[parentRef].asAssemblyPath.slice(1)"
          :key="segment"
          class="flex justify-start items-center gap-2"
        >
          <font-awesome-icon icon="arrow-right" />
          <p class="leading-none">{{ segment }}</p>
        </div>
      </div>

      <div
        class="grid min-w-[50rem] grid-cols-11 border-b bg-surface-100 gap-2 px-4 py-2 items-center w-full overflow-auto"
      >
        <p class="col-span-3 text-xs">Option group name</p>
        <p class="col-span-2 text-xs">Lock selection</p>
        <p class="col-span-2 text-xs">Optional</p>
        <p class="col-span-3 text-xs">Selections</p>
        <p class="text-xs">Price</p>
      </div>
      <div class="flex flex-col divide-y divide-surface-300 min-w-[50rem] w-full">
        <div
          v-for="ref in refs"
          :key="ref"
          class="flex flex-col w-full overflow-hidden"
          :class="[
            '',
            {
              'bg-surface-200/50 rounded-md !border-t-transparent': ref === editing
            }
          ]"
        >
          <div class="grid grid-cols-11 gap-2 p-2 items-center w-full overflow-hidden">
            <p class="col-span-3 text-sm text-ellipsis line-clamp-1">
              {{
                norm[ref]?.oMeta?.optionGroupName ||
                norm[ref]?.variation_parent_cost_type_name ||
                norm[ref]?.cost_type_name ||
                norm[ref]?.assembly_name
              }}
            </p>
            <div class="col-span-2">
              <Btn
                link
                rounded
                size="xl"
                :class="{ '!text-gray-400': !norm[ref]?.oMeta?.lockOptions }"
                @click="toggleLock(ref)"
                v-tooltip="'Lock selection so the client cannot change it'"
              >
                <font-awesome-icon
                  :icon="norm[ref]?.oMeta?.lockOptions ? ['far', 'lock'] : ['far', 'lock-open']"
                />
              </Btn>
            </div>
            <div class="col-span-2 flex items-center">
              <Btn
                link
                rounded
                size="xl"
                @click="toggleOptional(ref)"
                :class="{
                  '!text-gray-400': !(
                    norm[ref]?.assembly_is_optional || norm[ref]?.cost_item_is_optional
                  )
                }"
                v-tooltip="
                  norm[ref]?.assembly_is_optional || norm[ref]?.cost_item_is_optional
                    ? 'Item is optional'
                    : 'Item is not optional'
                "
              >
                <font-awesome-icon
                  :icon="
                    norm[ref]?.assembly_is_optional || norm[ref]?.cost_item_is_optional
                      ? ['far', 'toggle-on']
                      : ['far', 'toggle-off']
                  "
                />
              </Btn>
              <div
                class="ml"
                v-if="norm[ref]?.cost_item_is_optional || norm[ref]?.assembly_is_optional"
              >
                <Choose
                  schema="cost_item:cost_item_is_included"
                  :value="norm[ref]?.cost_item_is_included || norm[ref]?.assembly_is_included || 0"
                  @input="(val) => setIsIncluded(ref, val)"
                  :return-array="false"
                  :default="1"
                  size="sm"
                  :static-set="[
                    {
                      text: 'Included by default',
                      value: 1
                    },
                    {
                      text: 'Excluded by default',
                      value: 0
                    }
                  ]"
                  :allow-deselect="false"
                >
                  <template #default="{ text }">
                    <p class="hover:bg-surface-200 cursor-pointer p-1 text-xs">
                      {{ text }}
                    </p>
                  </template>
                </Choose>
              </div>
            </div>
            <div class="col-span-3">
              <!-- selection for addons, show name -->
              <Choose
                class="w-2/3 min-w-40"
                v-if="norm[ref]?.aoAddons?.length"
                :static-set="
                  norm[ref]?.aoAddons.map((addon, index) => ({
                    value: index,
                    text: `${addon.name} - ${$$(addon.price)}`,
                    html: `<div class='flex justify-between items-center w-full gap-4'><div class='text-md leading-tight'>${addon.name}</div> <div class='font-medium'>${$$(addon.price)}</div></div>`
                  }))
                "
                @input="(index) => selectAddon(ref, index)"
                :return-array="false"
                :default="null"
              >
                <div
                  class="hover:bg-surface-200 cursor-pointer line-clamp-1 border border-pitch-black rounded-sm px-2 py-1 leading-tight text-sm flex justify-between text-left items-center gap-2 w-full"
                >
                  <p class="line-clamp-1">
                    {{ norm[ref].cost_type_name || norm[ref].assembly_name }}
                  </p>
                  <font-awesome-icon icon="chevron-down" />
                </div>
              </Choose>
              <div
                v-else-if="norm[ref]?.cost_type_is_variation_parent"
                @click="swap(ref)"
                class="hover:bg-surface-200 w-2/3 min-w-40 cursor-pointer border border-pitch-black rounded-sm px-2 py-1 text-sm"
                :class="{ '!bg-black text-flame-white': editing === ref }"
              >
                <div class="flex justify-between items-center gap-2">
                  <p class="line-clamp-1">
                    {{
                      norm[ref].oMeta?.optionGroupName ||
                      norm[ref].cost_type_name ||
                      norm[ref].assembly_name
                    }}
                  </p>
                  <font-awesome-icon icon="chevron-down" />
                </div>
              </div>
            </div>
            <p class="text-md">
              {{ $$(norm[ref]?.quote_subtotal_net || norm[ref]?.cost_item_price_net) }}
            </p>
          </div>

          <TransitionExpand>
            <div v-if="editing === ref" class="border-t border-surface-800 py-4">
              <CostItemVariationSelector
                :object="norm[ref]"
                :store="store"
                :refId="ref"
                :show-highlights="false"
                :reference="ref"
                :editable="true"
                :scroll="false"
                :focusOnMount="false"
                :key="`var-var-${ref}`"
                :upgrading-allowed="true"
                :commitInterval="100"
              />
            </div>
          </TransitionExpand>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
