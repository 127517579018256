<template>
  <container class="permission-list--container" v-if="selected">
    <card-section v-if="permissionType === 'user'">
      <template #title>Administrator</template>
      <card>
        <template #title
          >User is currently {{ user_is_admin ? '' : 'not an' }} administrator.</template
        >
        <template #icon>
          <font-awesome-icon icon="keys" />
        </template>
        <p
          v-if="
            $store.state.session.user.user_is_admin &&
            String($store.state.session.user.user_id) !== String(user_id)
          "
        >
          <btn class="btn square warning lg" :action="toggleAdmin">
            {{
              user_is_admin ? 'Remove administrator privileges' : 'Grant administrator privileges'
            }}
          </btn>
        </p>
        <p v-else>
          Only other administrators can modify administrator settings, and no one can change their
          own administrator settings.
        </p>
      </card>
    </card-section>

    <card-section v-if="permissionType === 'user'">
      <template #title>Roles</template>

      <card>
        <template #icon>
          <font-awesome-icon icon="briefcase" />
        </template>
        <template #title>Roles</template>
        <p>
          Roles allow you to easily assign preset permissions to a user based on their role in the
          company. You can change the permissions assigned to each role, and even create new roles
          by going to your roles management page. Add a role to this user, and see how it changes
          their permissions.
        </p>
      </card>

      <card-list v-if="userRoles.length">
        <card-list-item
          v-for="role in userRoles"
          style="flex-direction: row"
          :key="role.user_role_id"
          class="flex items-center justify-start"
        >
          <btn class="more danger lg" @click="remove(role.user_role_id)">
            <font-awesome-icon icon="minus" />
          </btn>

          <strong class="ml-4">
            {{ role.role_name }}
          </strong>
        </card-list-item>
      </card-list>

      <choose
        class="mt-3"
        @input="(id) => change(id)"
        :filters="roleFilters"
        :value="[]"
        :return-array="false"
        allowDeselect="true"
        schema="role:role_id"
      >
        <btn class="btn round muted" :stop="false" :prevent="false">
          <template #icon>
            <font-awesome-icon icon="plus" />
          </template>
          Add role to user
        </btn>
      </choose>
    </card-section>

    <card-section>
      <template #title> Permissions </template>
      <IconField iconPosition="left">
        <InputIcon>
          <icon icon="search" class="text-surface-800" />
        </InputIcon>
        <InputText
          v-model="searchPhrase"
          placeholder="Search for permissions..."
          size="large"
          type="text"
        />
      </IconField>
      <template v-for="(permissionGroup, index) in groupedPermissions" :key="index">
        <div class="!m-0">
          <div
            class="flex flex-row justify-between gap-20 py-2 px-4 !m-0 !mt-8 bg-cement-800 text-flame-white font-bold rounded-t"
            v-if="!permissionGroup.type.includes('_')"
          >
            {{ $f.capitalize(permissionGroup.type) }}
            <div class="flex justify-start *:mr-16 *:w-36" v-if="permissionType === 'user'">
              <div>From roles</div>
              <div>Individually</div>
              <div class="!mr-0">Result</div>
            </div>
            <div class="flex justify-start *:mr-4 *:w-16" v-else>
              <div>Self</div>
              <div>Others</div>
            </div>
          </div>

          <component
            class="!m-0 !bg-flame-white !rounded-none border-t border-surface-200/50"
            v-for="(permission, index2) in permissionGroup.data"
            :is="permissionType === 'user' ? 'user-permission' : 'role-permission'"
            :key="permission.permission_id"
            :permission="permission"
            :index="index2"
            @saved="() => $emit('saved')"
            @changed="(permission) => $emit('changed', permission)"
            :user="user"
          />
        </div>
      </template>
    </card-section>
  </container>
</template>

<script>
import SearchField from '@/components/ui/SearchField.vue'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import UserPermission from '../Permissions/UserPermission.vue'
import RolePermission from '../Permissions/RolePermission.vue'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'

export default {
  name: '',
  mixins: [ObjectManipulator('user', true, 'User')],
  emits: ['saved', 'changed', 'saving'],
  data() {
    return {
      localUserRoles: null,
      addingRole: false,
      searchPhrase: ''
    }
  },
  computed: {
    user() {
      return this.cast()
    },
    newRoleId: {
      set(id) {
        this.localUserRoles.push(
          this.aoRolePermissions.filter((permission) => permission.permission_id === id)[0]
        )
        this.addingRole = false
      },
      get() {
        return []
      }
    },
    groupedPermissions() {
      const grouped = this.groupBy(this.forcePermissions || this.permissions, 'permission_object')
      const parsedPhrase = new RegExp(this.searchPhrase.replace(' ', '(.*)'))
      return c.empty(this.searchPhrase)
        ? grouped
        : grouped.filter((g) => parsedPhrase.test(JSON.stringify(g)))
    },
    roleFilters() {
      return {
        role_id: this.userRoles.map((ur) => `!${ur.role_id}`).join('&&')
      }
    }
  },
  methods: {
    remove(id) {
      this.$store
        .dispatch('ajax', { path: `user_role/delete/${id}` })
        .then(() => {
          this.$emit('saved')
        })
        .catch(({ message }) => this.$store.dispatch('alert', { message, error: true }))
    },
    change(id) {
      this.$emit('saving')
      const role = {
        role_id: id,
        user_id: this.user_id
      }
      this.$store
        .dispatch('ajax', { path: 'user_role/save', data: { object: role } })
        .then(() => {
          this.$emit('saved')
        })
        .catch(({ message }) => this.$store.dispatch('alert', { message, error: true }))
      this.addingRole = false
    },
    async toggleAdmin() {
      const company = this.aoCompanies.find(
        (co) => String(co.company_id) === String(this.$store.state.session.scope.company)
      )

      const newAdmin = company.user_is_admin ? 0 : 1
      const method = !newAdmin ? 'removeAdmin' : 'grantAdmin'

      try {
        await this.$store.dispatch(`User/${method}`, {
          id: this.user_id,
          company: company.company_id
        })

        this.user_is_admin = newAdmin

        this.$emit('saved')

        return true
      } catch (e) {
        this.$store.dispatch('alert', { message: e.userMessage, error: true })
      }

      return false
    },
    addNewRole() {
      this.addingRole = true
    },
    groupBy(arr, key) {
      const newArr = []
      const types = {}
      arr.forEach((object) => {
        if (newArr.filter((o) => o.type === object[key]).length === 0) {
          types[object[key]] = { type: object[key], data: [] }
          newArr.push(types[object[key]])
        }
        types[object[key]].data.push(object)
      })
      return newArr
    }
  },
  components: {
    UserPermission,
    RolePermission,
    SearchField,
    InputIcon,
    IconField,
    InputText
  },
  props: {
    deselectOnDestroy: {
      default: false
    },
    permissionType: {
      required: true
    },
    role: {
      required: false
    },
    forcePermissions: {
      default: false
    }
  },
  created() {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.permission-list--container {
  .permission-list--card {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    > div {
      width: 25%;
      max-width: 25%;
      flex: 0 0 25%;
      white-space: pre-wrap;
      word-wrap: break-word;
      line-height: 1.1;
      text-align: center;

      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}
</style>
