=
<template>
  <section class="p-2">
    <TabView :pt="{ navContainer: '!mb-5' }" :ptOptions="{ mergeProps: true }">
      <TabPanel header="Information">
        <div class="flex mb-6 items-center">
          <h4 class="mr-2 !font-bold">Progress:</h4>
          <span>{{ selected.completed_count }} / {{ selected.total_count }} completed</span>
        </div>
        <field-container label="Stage name">
          <Field :pt="{ root: '!w-full' }" v-model="selected.text" :disabled="true" />
        </field-container>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
          <field-container label="Set start date">
            <date-picker
              :picker-options="{
                shortcuts: false
              }"
              @date-select="(date) => onChangeDate(selected, date, 'start_date')"
              v-model="selected.start_date"
              ref="datepicker"
            />
            <template #details>
              <p class="mt-1">
                <span class="flex items-center">
                  <p>
                    EST. Workdays:
                    <span class="text-sm text-cool-gray-600">
                      {{ workdays || 0 }} workday{{ workdays > 1 ? 's' : '' }} ({{
                        selected.item_total_hours || 0
                      }}
                      / {{ hoursPerDayTotal }} = {{ workdays }} workday{{
                        workdays > 1 ? 's' : ''
                      }})
                    </span>
                  </p>
                </span>
              </p>
            </template>
          </field-container>
          <field-container label="Set end date">
            <date-picker
              :picker-options="{
                shortcuts: false
              }"
              @date-select="(date) => onChangeDate(selected, date, 'end_date')"
              v-model="selected.end_date"
              ref="datepicker"
            />
          </field-container>
        </div>
        <section class="w-full stage-tab">
          <h4 class="mb-3 mt-4 !font-bold">
            Cost item list
            <span v-if="actionItemList.length > 0">({{ actionItemList.length }})</span>
          </h4>
          <ItemList :update="updateStage" :list="actionItemList" />
        </section>
      </TabPanel>
      <TabPanel header="Task list">
        <ItemList :update="updateStage" :list="taskList" />
      </TabPanel>
      <TabPanel header="Materials">
        <h4 class="mb-3 mt-4 !font-bold">
          Material list
          <span v-if="actionItemList.length > 0">({{ actionItemList.length }})</span>
        </h4>
        <MaterialList @update="updateStage" :list="selected.material_list || []" />
      </TabPanel>
    </TabView>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import MaterialList from '../materials/MaterialList.vue'
import ItemList from '../items/ItemList.vue'
import eventBus from '../../../eventBus'
import useSchedule from '@/components/schedule/Schedule'
import useTask from '@/components/schedule/Task'
import DatePicker from '@/components/ui/DatePicker.vue'
import FieldContainer from '@/components/ui/fields/FieldContainer.vue'

const { selected, hoursPerDayTotal } = useSchedule()
const { onChangeDate } = useTask()

const actionItemList = computed(() => {
  return (
    selected.value && selected.value.children && selected.value.children.filter((c) => !c.is_task)
  )
})

const taskList = computed(() => {
  return (
    selected.value && selected.value.children && selected.value.children.filter((c) => c.is_task)
  )
})

const updateStage = (updates) => {
  selected.value = { ...selected.value, ...updates }
  eventBus.$emit('refresh-gantt-items', {
    items: [
      {
        ...updates,
        id: selected.value.id
      }
    ]
  })
}
</script>
<style lang="scss" rel="stylesheet/scss">
.stage-modal .info-section {
  @apply border border-cool-gray-300 p-2 rounded;
}
</style>
