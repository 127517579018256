<template>
  <div class="flex flex-col gap-4">
    <VerifyQuoteItems
      :reference="refId"
      :store="storeName"
      :type="type"
      v-model="subComponentInterface"
    />

    <div
      class="border rounded-sm border-surface-200 min-h-80 flex flex-col bg-flame-white px-5 pt-3 pb-5"
    >
      <QuoteMultiClients :client="oClient" :oReviewers="oReviewers" :quoteId="quote_id" />

      <div class="flex flex-col items-start min-h-64 h-full w-full gap-3">
        <StringField
          placeholder="Write a message"
          v-model="message"
          class="!m-0 grow !h-fit !w-full !whitespace-pre-line !py-3 !px-3 !leading-1 !bg-transparent !border !border-surface-200 !border-dashed z-0"
        />

        <div class="flex items-end justify-between z-100 w-full p-1 grow-0">
          <div class="flex justify-start items-stretch flex-wrap gap-2">
            <div
              ref="latest"
              class="bg-blue-print/20 text-blue-print-700 rounded-sm px-2 py-0.5 flex flex-col justify-center items-start gap-1 min-w-80 min-h-16"
            >
              <div class="flex justify-between items-center gap-1 font-medium w-full">
                <span> <font-awesome-icon icon="file-signature" /> Latest version </span>
                <div class="text-xs">
                  {{
                    !change_order_client_has_approved
                      ? `${unapprovedItems.length} items need approval`
                      : 'Scope already approved'
                  }}
                </div>
              </div>
              <div class="text-xs flex justify-between items-center w-full">
                <div>
                  {{ $$(quote_price_gross) }}
                </div>
                <div>
                  <Choose
                    class="mx-3 xs"
                    :return-array="false"
                    v-model="quoteDaysExpired"
                    :staticSet="quoteDaysExpOptions"
                    btnClass="!border-flame-white !text-flame-white rounded-md"
                    :default="quoteDaysExpOptions[0]"
                  >
                    <Btn
                      severity="info"
                      class="bg-transparent hover:bg-transparent !text-blue-print"
                      size="xs"
                    >
                      <font-awesome-icon icon="clock-rotate-left" />
                      <span v-if="!quoteDaysExpired || quoteDaysExpired === 'null'">
                        No expiry
                      </span>
                      <span v-else> {{ quoteDaysExpired }} days </span>
                    </Btn>
                  </Choose>
                </div>
              </div>
            </div>

            <FileList
              class="left"
              :startingFolder="`quote-${quote_id}`"
              :idList="true"
              v-model="fileIds"
              style="font-size: 0.6em"
            />
          </div>
          <div class="z-100">
            <Btn severity="bolster" size="lg" :action="sendQuote"> Send </Btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineOptions, nextTick, defineExpose } from 'vue'
import VerifyQuoteItems from '../quote/VerifyQuoteItems.vue'
import QuoteMultiClients from '../quote/settings/QuoteMultiClients.vue'
import eventBus from '../../eventBus'
import StringField from '@/components/ui/Calculator/StringField.vue'
import CurrencyFilter from '@/components/mixins/CurrencyFilter.js'
import UnapprovedChanges from '@/components/Sheets/quote/estimating/UnapprovedChanges.js'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { useRouter } from 'vue-router'

const $store = useStore()

const props = defineProps({
  refId: {
    required: true
  },
  beforeSend: {
    type: Function,
    default: async () => true
  },
  deselectOnDestroy: {
    type: Boolean,
    default: false
  },
  saveBefore: {
    type: Boolean,
    default: false
  },
  forceFull: {
    type: Boolean,
    default: true
  }
})

const refId = computed(() => props.refId)
const router = useRouter()

const {
  change_order_time_sent,
  client: oClient,
  quote_time_expired,
  quote_is_change_order,
  file_ids,
  change_order_client_has_approved,
  quote_price_gross,
  presentationSettings: oPresentationSettings,
  quote_id,
  oReviewers
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type: 'quote',
  store: 'Quote'
})

const { unapprovedItems } = UnapprovedChanges.useUnapprovedChanges({
  refId: refId.value,
  store: 'Quote'
})

defineOptions({
  mixins: [CurrencyFilter]
})

const loading = ref(0)
const changeMessage = ref(0)
const message = ref('')
const floorplanFileIds = ref([])
const sms = ref(true)
const email = ref(true)
const quoteDaysExpiredLocal = ref(null)

const quoteDaysExpOptions = [
  { value: 'null', text: 'No' },
  { value: '7', text: '7 days' },
  { value: '15', text: '15 days' },
  { value: '30', text: '30 days' },
  { value: '45', text: '45 days' },
  { value: '60', text: '60 days' }
]

const showMessage = computed(() => !change_order_time_sent.value || changeMessage.value)

const quoteDaysExpired = computed({
  get() {
    if (quoteDaysExpiredLocal.value) {
      return quoteDaysExpiredLocal.value
    }
    if (quote_time_expired.value === null) {
      return 'null'
    }
    const seconds = new Date().getTime()
    const quoteDaysExpiredValue = Math.ceil(
      (quote_time_expired.value - seconds) / (24 * 60 * 60 * 1000)
    )
    let nearestVal = null
    quoteDaysExpOptions.forEach((days) => {
      if (quoteDaysExpiredValue <= days.value && !nearestVal) {
        nearestVal = days.value
      }
    })
    return String(nearestVal)
  },
  set(val) {
    quoteDaysExpiredLocal.value = val
  }
})

const quoteTimeExpiredOn = computed(() => {
  if (quoteDaysExpired.value < 1) return null
  const seconds = Math.round(new Date().getTime() / 1000)
  return seconds + quoteDaysExpired.value * 24 * 60 * 60
})

watch(
  () => showMessage.value,
  (newVal) => {
    if (newVal && oPresentationSettings.defaultChangeOrderMessage) {
      setDefaultMessage()
    }
  },
  { immediate: true }
)

watch(
  () => sms.value,
  async (newVal) => {
    if (
      newVal &&
      this.selected &&
      oClient.value.client_id &&
      !oClient.value.user_has_phone &&
      !oClient.value.user_phone
    ) {
      sms.value = 0
      const phone = await $store.dispatch('prompt', {
        message: "This client doesn't have a phone number on record, what is their number?",
        format: 'phone'
      })
      if (!phone) return
      await $store.dispatch('Client/partialUpdate', {
        selected: [
          {
            client_id: oClient.value.client_id,
            type: 'client',
            user_phone: phone
          }
        ]
      })
      sms.value = 1
      oClient.value = {
        ...oClient.value,
        user_phone: phone,
        user_has_phone: 1
      }
      $store.dispatch('alert', {
        message: 'Phone added to your user. You can now send via SMS.'
      })
    }
  }
)

watch(
  () => email.value,
  async (newVal) => {
    if (
      newVal &&
      this.selected &&
      oClient.value.client_id &&
      !oClient.value.user_has_email &&
      !oClient.value.user_email
    ) {
      email.value = 0
      const emailValue = await $store.dispatch('prompt', {
        message: "This client doesn't have an email on record, what is their email?",
        format: 'email'
      })
      if (!emailValue) return
      await $store.dispatch('Client/partialUpdate', {
        selected: [
          {
            client_id: oClient.value.client_id,
            type: 'client',
            user_email: emailValue
          }
        ]
      })
      email.value = 1
      oClient.value = {
        ...oClient.value,
        user_email: emailValue,
        user_has_email: 1
      }
      $store.dispatch('alert', {
        message: 'Email added to your user. You can now send via Email.'
      })
    }
  }
)

const getEmail = async () => {
  const emailValue = await $store.dispatch('prompt', {
    message: "This client doesn't have an email on record, what is their email?",
    format: 'email'
  })
  if (!emailValue) return false

  const { set: users } = await $store.dispatch('User/find', {
    by: {
      user_email: emailValue
    }
  })
  if (users.length) {
    const { set: clients } = await $store.dispatch('Client/find', {
      by: {
        client_user_id: users[0].user_id
      }
    })
    if (clients.length) {
      await $store.dispatch('modal/confirm', {
        message: 'A client already exists with these details. Use this client instead?',
        actions: {
          confirm: {
            title: 'Continue',
            action: async () => {
              await $store.dispatch('Quote/partialUpdate', {
                selected: [
                  {
                    type: 'quote',
                    quote_id: props.refId,
                    client_id: clients[0].client_id
                  }
                ]
              })
            }
          },
          cancel: {
            title: 'Cancel'
          }
        }
      })
      oClient.value = {
        ...oClient.value,
        ...clients[0],
        ...users[0]
      }
    }
  } else {
    await $store.dispatch('Client/partialUpdate', {
      selected: [
        {
          client_id: oClient.value.client_id,
          type: 'client',
          user_email: emailValue
        }
      ]
    })
    oClient.value = {
      ...oClient.value,
      user_email: emailValue,
      user_has_email: 1
    }
  }
  return emailValue
}

const setDefaultMessage = () => {
  if (!oPresentationSettings.defaultChangeOrderMessage) return
  message.value = (oPresentationSettings.defaultChangeOrderMessage || '')
    .replace('{quote_type}', quote_is_change_order.value ? 'change order' : 'proposal')
    .replace('{client_name}', oClient.value.client_name)
    .replace('{quote_name}', this.quote_name)
    .replace('{quote_address}', this.quote_address)
}

const inPersonApproval = async () => {
  const confirmed = await $store.dispatch('modal/asyncConfirm', {
    message:
      'This process is designed to be completed by the client and they will be notified by email. Have you passed your device to the client?'
  })
  if (confirmed) {
    await $store.dispatch('modal/closeAll')
    await router.push(`/pub/presentation/${quote_id.value}`)
  } else {
    await $store.dispatch('modal/alert', {
      message: 'In-person approvals can only be completed by the client.'
    })
  }
}

defineExpose({ inPersonApproval })

const sendQuote = async (payload = {}, saveMessageAndFiles = true) => {
  let emailValue = null
  if (!oClient.value.user_email) {
    emailValue = await getEmail()
    if (!emailValue) return false
  }
  await props.beforeSend()
  loading.value = 1
  await nextTick()
  await c.throttle(
    async () => {
      const updatePayload = saveMessageAndFiles
        ? {
            change_order_message: message.value,
            ...(file_ids.value && file_ids.value.length ? { file_ids: file_ids.value } : {}),
            ...(floorplanFileIds.value && floorplanFileIds.value.length
              ? { plan_file_ids: floorplanFileIds.value }
              : {})
          }
        : {}
      updatePayload.quote_time_expired = quoteTimeExpiredOn.value
      await $store.dispatch('Quote/sendAndUpdate', {
        ...payload,
        refId: props.refId,
        update: updatePayload,
        sms: true,
        email: true,
        ...(emailValue ? { forceEmail: emailValue } : {})
      })
      await nextTick()
      eventBus.$emit('sent-quote')
      await $store.dispatch('modal/closeAll')
      await c.throttle(
        () => {
          // this.$refs.changeOrderListSending.fetch()
          // this.$refs.changeOrderListPrevious.fetch()
          this.$emit('reload')
          this.$emit('saved')
          loading.value = 0
        },
        { delay: 2000 }
      )
    },
    { delay: 300 }
  )
  return payload
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-send--message {
  width: 250px;
  max-width: 250px;
  border-radius: 0.25em;
  border: 1px solid transparent;
  padding: 1em;
  margin: 0.5em 0;
  font-size: 0.9em;
  position: relative;
  // background: $blue-print-700;
  // color: $flame-white !important;

  .glyphicon {
    position: absolute;
    top: 0.1em;
    right: 0.2em;
    // color: $flame-white !important;
  }

  strong {
    white-space: pre-wrap;
  }
}
</style>
