<template>
  <div class="selection-color--container input-container">
    <Swatch
      class="single"
      :show-vendor="true"
      @select="openSelector()"
      :color="value && Object.keys(value).length ? value : emptyColor"
    >
      <SuggestionButton
        class="danger mt-1"
        v-if="value && Object.keys(value).length && editable && allowDeselect"
        @click="selectColor({})"
      >
        <font-awesome-icon icon="remove" />
        Clear default color
      </SuggestionButton>
    </Swatch>

    <MiniModal
      v-if="showModal"
      ref="selector"
      :full="true"
      :fixed="true"
      :scrollable="false"
      size="full"
    >
      <div class="selection-color--selector">
        <div class="selection-color--families">
          <ScrollContainer>
            <img :src="logoImport" style="max-width: 100%; max-height: 3em" class="p-1" />
            <div class="small-group-container">
              <div
                v-for="(group, gIndex) in filteredGroups"
                :key="gIndex"
                class="small-group"
                :class="{
                  selected: gIndex === selectedGroup
                }"
                @click="goToGroup(group.colors[0].number)"
              >
                <div
                  v-for="(color, index) in group.colors"
                  class="small-color"
                  :key="index"
                  :ref="`color-${color.number}-sm`"
                  :class="{
                    selected: value && color.number == value.number
                  }"
                >
                  <div
                    class="small-color-bg"
                    :style="{
                      backgroundColor: `#${color.hex}`
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </ScrollContainer>
        </div>

        <div class="selection-color--list">
          <ScrollContainer ref="list">
            <div class="selection-color--toolbar">
              <SearchField v-model="searchPhrase" />
            </div>

            <div
              class="swatch-group"
              :ref="`group-${group.colors[0].number}`"
              :key="index"
              v-for="(group, index) in filteredGroups"
            >
              <Swatch
                :class="{
                  selected: value && color.number == value.number
                }"
                v-for="color in group.colors"
                :ref="`color-${color.number}`"
                @select="selectColor(color)"
                :color="color"
                :key="color.number"
              />
            </div>
          </ScrollContainer>
        </div>
      </div>
    </MiniModal>
  </div>
</template>

<script>
import benjaminmooreLogo from '@/assets/BenjaminMoore-logo.png'
import Swatch from './Swatch.vue'
import SuggestionButton from '../SuggestionButton.vue'
import benjaminmoore from './benjaminmoore'

export default {
  name: 'SelectionColor',
  components: { SuggestionButton, Swatch },
  props: {
    allowDeselect: {
      default: true
    },
    editable: {
      default: true
    },
    value: {
      required: true
    },
    placeholder: {
      default: 'Choose a color..'
    },
    vendor: {
      default: () => ({
        groupCount: 7,
        colorGroups: null
      })
    },
    logo: {
      default: ''
    }
  },
  emits: ['input'],
  data() {
    return {
      showModal: false,
      selected: null,
      searchPhrase: '',
      emptyColor: {
        vendor: '',
        vendorId: '',
        number: '',
        data: {},
        family: '',
        contrastColor: 'light',
        int: 1,
        ext: 1,
        sku: '',
        url: '',
        keywords: '',
        name: this.placeholder,
        hex: 'd5dadf'
      }
    }
  },

  computed: {
    logoImport() {
      if (this.logo) {
        return this.logo
      }

      return benjaminmooreLogo
    },
    filteredGroups() {
      if (!this.searchPhrase) return this.vendor.colorGroups || benjaminmoore.colorGroups

      return (this.vendor.colorGroups || benjaminmoore.colorGroups).filter((g) =>
        g.keywords.includes(this.searchPhrase.toLowerCase())
      )
    },
    selectedGroup() {
      if (!this.value.number) return null
      return Math.floor((this.value.number - 1) / 7)
    }
  },

  methods: {
    goToGroup(number) {
      c.scrollTo(this.$refs[`group-${number}`][0])
    },

    async openSelector() {
      if (!this.editable) return

      // optimizes rendering performance
      this.showModal = true
      await this.$nextTick()

      this.$refs.selector.open()

      await c.throttle(() => {}, {})
      if (this.value && this.value.number) {
        c.scrollTo(this.$refs[`color-${this.value.number}`][0].$el)
        c.scrollTo(this.$refs[`color-${this.value.number}-sm`][0])
      }
    },

    async closeSelector() {
      this.$refs.selector.close()
      await this.$nextTick()
      this.showModal = false
    },

    selectColor(color) {
      this.selected = color
      this.$emit('input', this.selected)
      this.closeSelector()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.selection-color--container {
  .selection-color--swatch {
    width: 4em;
    height: 4em;
    border-radius: 0.25em;
  }
}

.selection-color--selector {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  max-height: 100%;
  height: 100%;
  .selection-color--families {
    flex: 0 10em;
    min-width: 10em;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .selection-color--list {
    flex: 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    padding: 0 1em;
    min-width: 0;
  }

  .selection-color--families {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .family {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

      min-height: 2em;

      width: 100%;
      padding: 0.5em 0.5em;

      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        background: $cool-gray-300;
        color: $cool-gray-700;
        border-radius: 0.25em;
        padding: 0.5em 1em;
        overflow: hidden;
        min-height: 2em;
        width: 100%;
        font-weight: 500;
        font-size: 0.9em;
      }

      .family-examples {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        height: 100%;

        min-width: 3em;
        width: 3em;
        height: 100%;
        .family-example {
          min-width: 1em;
          width: 1em;
          height: 100%;
        }
      }
    }
  }

  .swatch-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    flex-wrap: nowrap;

    padding: 1em 0;
    width: 100%;
    min-width: 0;
    overflow-x: scroll;
    // @extend %scrollbar;
    overflow-y: hidden !important;

    > :first-child {
      .swatch--bg {
        border-top-left-radius: 0.5em;
      }
      .swatch--name {
        border-bottom-left-radius: 0.5em;
      }
    }
    > :last-child {
      .swatch--bg {
        border-top-right-radius: 0.5em;
      }
      .swatch--name {
        border-bottom-right-radius: 0.5em;
      }
    }
  }
}

.small-group-container {
  padding: 1em 1em;
}
.small-group {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .small-color {
    .small-color-bg {
      height: 1em;
      width: 1em;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.muted {
      opacity: 0.3;
    }
  }

  &.selected {
    border-top: 2px solid $blue-print-700;
    border-bottom: 2px solid $blue-print-700;
  }
}

.selection-color--toolbar {
  position: sticky;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 20px 20px -20px rgba(115, 120, 123, 0.4);
  height: 5em;
  background: $cool-gray-200;
  top: 0;
}
</style>
