<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    size="sm"
    :width="800"
    ref="modal"
    :scrollable="true"
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #body>
      <div class="flex flex-col justify-center items-center min-h-96 text-center px-20 pb-8 gap-8">
        <h2 class="font-sans text-4xl !font-medium">Welcome to your interactive estimate</h2>
        <CostItemFlag type="Selections" />
        <p>
          In this proposal, your contractor or builder has specified some items which require a
          selection to be made by you before your project can start.
        </p>
        <CostItemFlag type="Optional" />
        <p>
          There are also items marked 'Optional', which you can choose to add to your project if you
          wish.
        </p>
        <p>Thanks, and enjoy your experience using Bolster's Interactive Estimates!</p>
        <Btn class="w-52" severity="tertiary" @click="handleDismiss">
          <span class="p-2">Dismiss</span>
        </Btn>
      </div>
    </template>
  </modal>
</template>

<script>
import { useStore } from 'vuex'
import CostItemFlag from '@/components/quote/presentation/CostItemFlag.vue'
import Btn from '@/components/ui/Btn.vue'
export default {
  name: 'TwoFactorAuth',
  mixins: [],
  components: { Btn, CostItemFlag },
  props: {
    type: {
      default: 'quote'
    },
    modal: {
      type: Object,
      default: null
    }
  },
  setup() {
    const store = useStore()
    const handleDismiss = async () => {
      await store.dispatch('modal/closeAll')
    }
    return {
      handleDismiss
    }
  }
}
</script>
