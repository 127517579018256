import Router from '../../../router'
import * as types from '../../mutation-types'
import _ from '../../../../imports/api/Helpers'
import Perf from '../../../../imports/api/Perf'
import HelpTopics from '../../../HelpTopics'
import UserError from '../../../../imports/api/UserError'
import Ajax from '../../../../imports/api/Ajax'
import { Capacitor } from '@capacitor/core'
import { Filesystem } from '@capacitor/filesystem'
import { fetchAuthSession } from 'aws-amplify/auth'

// initial state
const initialState = {
  /**
   * When editing an object, the holds array shows the items that are currently being
   * edited, and shouldn't be edited elsewhere.
   */
  holds: [],

  /**
   * Maximum amount of API ajax request that can occur at one time before
   * waiting for pending requests to return
   */
  maxRequests: 20,

  /**
   * Number of current pending requests
   */
  currentRequests: 0,

  /**
   * The request queue
   */
  requestQueue: [],

  /**
   * Items selected
   */
  itemSelectorResolve: false,
  itemSelectorMod: {},
  startAt: null,

  /**
   * PayWall
   */
  showPaywall: false,
  savedRequestPayload: null
}

let tempMeta = {}
let savedMeta = {}

// getters
const getters = {
  savedRequestPayload: (state) => state.savedRequestPayload,
  showPaywall: (state) => state.showPaywall,
  uomById: (state, gets, rootState) => {
    const all = rootState.UnitOfMeasure.all
    const arrayTypes = Object.keys(all).map((n) => all[n][Object.keys(all[n])[0]])
    return arrayTypes.reduce(
      (acc, type) => ({
        ...acc,
        [String(type.unit_of_measure_id)]: type
      }),
      {}
    )
  },
  dimensionsByAbbr: (state, gets, rootState) => {
    const all = rootState.Dimensions.all
    const arrayTypes = Object.keys(all).map((n) => all[n][Object.keys(all[n])[0]])
    return arrayTypes.reduce(
      (acc, type) => ({
        ...acc,
        [type.dimension_id]: type
      }),
      {}
    )
  },
  originalMeta: (state, gets, rootState) => {
    const rawMeta = _.imm((rootState.session.user && rootState.session.user.oMeta) || {})
    const newMeta = {}

    Object.keys(rawMeta).forEach((key) => {
      if (/^-json-/.test(key)) {
        newMeta[key.replace('-json-', '')] = JSON.parse(rawMeta[key])
      } else {
        newMeta[key] = rawMeta[key]
      }
    })

    return newMeta
  },
  userMeta: (state, gets) => ({
    ...gets.originalMeta,
    ...savedMeta,
    ...tempMeta
  })
}

let traversePromise = null

// actions
const actions = {
  togglePaywall({ commit }, toggle) {
    commit({
      type: types.TOGGLE_PAYWALL,
      toggle
    })
  },
  savePayloadRequest({ commit }, payload) {
    commit({
      type: types.SAVE_PAYLOAD_REQUEST,
      payload
    })
  },
  triggerEvent({ dispatch }, name) {
    return dispatch('ajax', {
      path: `event/triggerEventByName/${name}`
    })
  },
  selectAndCloseItemSelector({ state, commit }, selected) {
    if (state.itemSelectorResolve) {
      state.itemSelectorResolve(selected)

      commit({
        type: types.TRAVERSE_ALTER,
        resolve: false
      })
    }

    if (traversePromise) {
      traversePromise = null
    }
  },
  async openItemSelector({ commit, rootGetters: gets }, payload = {}) {
    const { mod = gets.defaultMod, startAt = null } = payload

    if (traversePromise) {
      return traversePromise
    }

    let resolve
    traversePromise = new Promise((r) => {
      resolve = r
    })
    commit({
      type: types.TRAVERSE_ALTER,
      resolve,
      mod,
      startAt
    })

    return traversePromise
  },
  copyToClipboard({ dispatch }, text) {
    navigator.clipboard.writeText(text)
    return dispatch('alert', {
      message: 'Copied to clipboard!'
    })
  },
  /**
   * Report error
   * @param state
   * @param details
   * @returns {Promise<void>}
   */
  reportError({ dispatch }, { details = {} }) {
    return dispatch('ajax', {
      path: '/reportError',
      data: details
    })
  },

  /**
   * Returns a unique ID (string) that will never again be created.
   * @param rootState
   * @returns {Promise<String>}
   */
  async globalUniqueId({ rootState }) {
    const time = new Date().valueOf()
    const rand = (Math.random() * 10000).toFixed(0)
    const id = rootState.session.user.user_id
    const sessionUnique = _.uniqueId()
    return Number(`${time}${rand}${id}${sessionUnique}`).toString(32)
  },

  downloadFile(
    state,
    {
      content,
      fileName = `CostCertified-${new Date().valueOf()}.txt`,
      fileType = 'text/plain',
      charset = 'utf-8'
    }
  ) {
    const el = document.createElement('a')
    el.setAttribute('href', `data:${fileType};charset=${charset},${encodeURIComponent(content)}`)
    el.setAttribute('download', fileName)
    el.style.display = 'none'
    document.body.appendChild(el)
    el.click()
    document.body.removeChild(el)
  },
  /**
   * Open map with directions as provided
   * @param state
   * @param address
   * @param city
   * @param prov
   *
   * @returns Promise<bool>
   */
  openMap({ dispatch }, { address = null, city = null, prov = null }) {
    if (!address || !city) {
      throw new UserError({
        userMessage: 'No address available.'
      })
    }

    const fullAddress =
      `${encodeURIComponent(address)}, ${encodeURIComponent(city)}${prov ? `, ${encodeURIComponent(prov || '')}` : ''}`
        .replace(' ', '+')
        .replace(',', '%2C')

    dispatch(
      'openLink',
      {
        url: `https://www.google.com/maps/dir/?api=1&destination=${fullAddress}`,
        newWindow: true
      },
      { root: true }
    )

    return true
  },

  helpTopic({ dispatch }, topic) {
    return dispatch('openLink', { url: HelpTopics[topic].url })
  },

  /**
   * Creates a link to open in a new window, for example to download a file,
   * or open a map.
   * @param string url                                url of link to open
   * @param bool newWindow default true               whether to open in new window
   * @param string|null downloadName default null     if it is a download, name the download
   * @returns void
   */
  openLink(state, { url, newWindow = true, downloadName = null }) {
    const a = document.createElement('a')
    a.href = `${/^(tel|mailto|https?:\/\/)/.test(url) ? '' : 'http://'}${url}`

    if (newWindow) {
      a.setAttribute('target', '_blank')
    }

    if (downloadName) {
      a.download = downloadName
    }

    a.click()

    _.throttle(() => a.parentNode.removeChild(a))
  },

  /**
   * Set user meta values so you can set small settings. For example a warning window might
   * ask if you ever want to see it again, you could check of 'never show this again',
   * provide a unique key value pair to set meta like {'neverShowMyWarningWindowAgain': 1}
   * and then you can access that through VUEX data in:
   *
   * state.session.user.oMeta.neverShowMyWarningWindowAgain
   *
   * to know if that was ever turned off or you can dispatch 'getMeta':
   *
   * const show = await dispatch('getMeta', 'neverShowMyWarningWindowAgain');
   *
   * @param dispatch
   * @param payload
   * @returns {Promise<void>}
   */
  async setMeta({ dispatch }, metaValues = {}) {
    tempMeta = _.imm({
      ...tempMeta,
      ...metaValues
    })

    _.throttle(() => dispatch('sendMeta'))

    return tempMeta
  },

  /**
   * Will send meta values saved in tempMeta, and clear that out,
   * transferring to savedMeta, so to track which meta values are
   * in the process of saving, and which have not been sent yet.
   *
   * @param dispatch
   * @returns {Promise<void>}
   */
  async sendMeta({ dispatch, getters: gets }) {
    // because we are sending,
    // we remove tempMeta values,
    // which then get put into saved meta
    // so that they are there between now,
    // and when we re-fetch base values
    const metaToSend = tempMeta
    savedMeta = {
      ...savedMeta,
      ...metaToSend
    }
    tempMeta = {}
    const data = {}
    const original = _.imm(gets.originalMeta)

    Object.keys(metaToSend).forEach((key) => {
      const value = metaToSend[key]

      if (key in original && _.jsonEquals(value, original[key])) {
        return
      }

      const scalarValue = _.isScalar(value)
      const scalarKey = _.isScalar(key)
      const saveValue = scalarValue ? value : JSON.stringify(value)
      const saveKey = `${scalarValue ? '' : '-json-'}${scalarKey ? key : JSON.stringify(key)}`

      // Now check that it isn't already the same
      data[saveKey] = saveValue
    })

    // only save if there are material changes
    if (Object.keys(data).length) {
      await dispatch('ajax', {
        path: 'meta/set',
        // Make sure object is string key/value pairs
        data
      })

      await dispatch('getBaseValues', { cloak: false })
    }

    return metaToSend
  },

  /**
   * Get a meta value that is set above, with key provided by name
   * @param rootState
   * @param string key    the original key (not including -json- prefixes).
   * @returns {Promise<*|null>}
   */
  async getMeta({ getters: gets }, key) {
    return gets.userMeta[key] || null
  },

  /**
   * Download from url provided, opens in new window so not to disrupt current window/session
   * @param payload
   *    @param string url         relative path of download (correlating to RESTful API call)
   *    @param string fileName    file name for downloaded file
   * @returns {Promise<void>}
   */
  async downloadFromUrl(store, payload = {}) {
    const { url, fileName } = payload
    const fullPath = _.link(url, {}, true, null, import.meta.env.VITE_BASE_API_URL)

    if (Capacitor.isNativePlatform()) {
      Filesystem.downloadFile({
        url: fullPath,
        path: fileName,
        directory: 'DATA'
      })
    } else {
      const a = document.createElement('a')
      a.href = fullPath
      a.download = fileName
      a.click()
    }

    return false
    // return payload;
  },

  /**
   * Remove all cache saved on window/session
   * @param dispatch
   * @param type
   */
  clearCache({ dispatch }, { type = [] }) {
    c.makeArray(type).forEach((t) => {
      c.clearCacheOfType(t)
      dispatch(`${c.titleCase(t)}/clearCache`)
    })
  },

  /**
   * Hide all tooltips
   */
  hideAllTooltips() {
    $('div.tooltip').remove()
  },

  /**
   * Can be used to create then resolves to
   * the saved object
   * ie:
   *
   * dispatch('create', {
   *    type: assembly,
   *    embue: {
   *      assembly_name: 'my new assembly',
   *   },
   * }).then((object) => object);
   *
   *
   * // => {
   *    assembly_name: 'my new assembly',
   *    assembly_id: 123,
   *    ...other assembly stuff,
   * }
   *
   * After the modal closes/saves it is resolved.
   * If the modal is clsoed without saving it is rejected
   *
   *
   * NB: FOr the object to be created it needs to be in
   * c.creatableObjects array by type
   *
   * @param commit
   * @param dispatch
   * @param payload
   * @returns {Promise} { ...payload, object: { Object just saved/created } }
   */
  create({ dispatch }, payload) {
    const { type, embue = {}, go = true, grid = null, close = false } = payload
    if (!(type in c.creatableObjects)) {
      throw new UserError({
        userMessage: 'You cannot create an object of that type.'
      })
    }

    let resolved = false
    return new Promise((resolve) => {
      // Create resolver
      const doResolve = (object = null, modal = null) => {
        if (!resolved) {
          resolved = true
          resolve(object)
        }

        if (modal && close) {
          modal.close()
        }

        if (go && object) {
          dispatch('go', {
            object
          })
        }

        if (!go && grid) {
          grid.reload()
        }
      }

      // Open modal
      dispatch('modal/open', {
        modal: {
          name: c.creatableObjects[type],
          closed() {
            doResolve()
          },
          saved({ object }, modal) {
            doResolve(c.makeArray(object)[0], modal)
          }
        },
        embue,
        go: false
      })
    })
  },

  /**
   * Request some value in a pop-up that opens up for client
   * @param dispatch
   * @param payload
   *    @param string message
   *    @param string inputType       default 'text', 'textarea'
   *    @param string defaultValue    default ''
   *    @param bool required          default true
   * @returns {Promise<object>}
   */
  prompt({ dispatch }, payload) {
    return dispatch('modal/prompt', payload)
  },

  /**
   * Sends object given by type param, if there is a send method in schema/TypeProvided.js
   * @param dispatch
   * @param payload
   * @returns {Promise<boolean>}
   */
  async send({ dispatch }, payload) {
    const {
      type,
      open = () => {},
      opened = () => {},
      close = () => {},
      save = () => {},
      embue = {}
    } = payload

    const titleCase = c.titleCase(type)
    const { object } = await dispatch(`${titleCase}/resolveObject`, { ...payload, type })

    const name = `${titleCase}Send`

    await new Promise((resolve) => {
      dispatch('modal/open', {
        modal: {
          name,
          closed: () => resolve(),
          open,
          opened,
          close,
          save
        },
        objects: [object],
        embue
      })
    })

    return true
  },

  /**
   * Shortcut to edit any kind of object
   * @param commit
   * @param dispatch
   * @param localState
   * @param payload
   *    @param string type            entity type to edit
   *    @param string id              id of entity to edit
   *    @param object|null embue      key-value pairs to pre-edit on item. add-ins
   *    @param GridComponent grid     grid component to relaod after editing
   *    @param bool alert             whether to alert after eiditng done, false for silent
   *    @param bool go                whether to go to show item in list after editing done
   * @returns {*}
   */
  async edit({ commit, dispatch, state: localState, rootGetters }, payload) {
    const {
      type,
      id,
      embue = {},
      modal = {},
      go = false,
      confirmBeforeGo = false,

      // Get other modal related values
      open = () => true,
      opened = () => true,
      close = () => true,
      closed = () => true,
      save = () => true,
      saved = () => true
    } = payload

    if (!(type in c.editableObjects)) {
      throw new UserError({
        userMessage: 'That object type cannot be edited.',
        hintMessage: "Attempted to edit an entity that shouldn't be editable."
      })
    }

    const isSuper = rootGetters.inSuper

    // Objects that are edited by a page, rather than modal
    const pageObjects = {
      quote: (objId) => {
        return `/quote/${objId}`
      },
      change_order: async (objId) => {
        const { object } = await dispatch(
          'ChangeOrder/resolveObject',
          {
            id: objId
          },
          { root: true }
        )

        return `/quote/${object.quote_id}?tab=Changes`
      },
      client: async (objId) => {
        return `/client/${objId}`
      },
      vendor: async (objId) => {
        return `/vendor/${objId}`
      },
      user: async (objId) => {
        return `/${isSuper ? 'super/' : ''}user/${objId}`
      },
      company: async (objId) => {
        return `/${isSuper ? 'super/' : ''}company/${objId}`
      },
      invoice: async (objId) => {
        return `/invoice/${objId}`
      },
      form: async (objId) => {
        return `/form/${objId}`
      },
      lead_rotation: async (objId) => {
        return `/lead_rotation/${objId}`
      }
    }

    let resolved = 0
    const holdKey = `${type}:${id}`

    const releaseHold = () =>
      commit({
        type: types.REMOVE_EDIT_HOLD,
        holdKey
      })

    // Release hold after 5 seconds regardless
    setTimeout(() => releaseHold(), 5000)

    // Build a resolver
    let resolveThis = () => {}
    let object = null
    const promise = new Promise((resolve) => {
      resolveThis = (resolvePayload = {}) => {
        resolved = 1
        resolve(resolvePayload)

        releaseHold()

        if (go) {
          dispatch('go', {
            object
          })
        }
      }
    })

    c.throttle(
      async () => {
        // Editing that object has already been triggered within the throttle period
        if (localState.holds.includes(holdKey)) {
          return false
        }

        commit({
          type: types.ADD_EDIT_HOLD,
          holdKey
        })

        // If it is a page editable object, go to the page
        if (type in pageObjects) {
          if (
            confirmBeforeGo &&
            !(await dispatch('modal/asyncConfirm', {
              message: 'Would you like to leave?'
            }))
          ) {
            return {}
          }

          if (Router.currentRoute.value.path.includes(`${type}/`)) {
            await dispatch('to', '/pipeline')
            await _.throttle(() => {}, { delay: 1000 })
          }

          await dispatch('to', await pageObjects[type](id))
          await dispatch('modal/closeAll', { confirm: true })

          // Resolve immediately
          resolveThis()
          return {}
        }

        // Get object in question:
        const { object: resolvedObject } = await dispatch(
          `${c.titleCase(type)}/resolveObject`,
          payload
        )
        object = resolvedObject
        // Get modal name:
        const name =
          typeof c.editableObjects[type] === 'function'
            ? c.editableObjects[type](object)
            : c.editableObjects[type]

        // Opean modal, with resolve happening on save OR on close
        // if save doesn't happen
        dispatch('modal/open', {
          modal: {
            ...modal,
            open,
            opened,
            close,
            save,
            name,
            go: false,
            async saved(savedPayload) {
              await saved.call(this, savedPayload)

              resolveThis(savedPayload)
            },
            async closed() {
              await closed.call(this)

              setTimeout(() => {
                if (!resolved) resolveThis({ object })
              }, 500)
            }
          },
          objects: [object],
          embue
        })

        return true
      },
      { delay: 100, key: holdKey }
    )

    return promise // resolved when modal closes or saves
  },

  /**
   * Open an object in a modal of its own to view directly.  Will not show in list but
   * in its own modal or page whatever is dictated by its type provided below.
   * @param commit
   * @param dispatch
   * @param payload
   *    @param string type
   *    @param string id
   *    @param bool|null closeModals
   * @returns {Promise<any>}
   */
  open({ dispatch }, payload) {
    const { type, id, closeModals = true } = payload
    return new Promise((resolve) => {
      switch (type) {
        case 'conversation': {
          if (closeModals) dispatch('modal/closeAll')
          const escaped = encodeURIComponent(id).replace(/\./g, '_PERIOD_')
          dispatch.to(`/conversation/${escaped}`).then(() => resolve(payload))
          break
        }
        case 'lead':
        case 'client':
          dispatch('modal/open', {
            modal: 'Client',
            objects: [
              {
                client_id: id
              }
            ]
          }).then(() => resolve(payload))
          break
        case 'rating':
          dispatch('modal/open', {
            modal: 'rating',
            objects: [
              {
                ratingId: id
              }
            ]
          }).then(() => resolve(payload))
          break
        case 'project':
        case 'quote':
          dispatch('modal/open', {
            modal: 'Quote',
            objects: [
              {
                quote_id: id
              }
            ]
          }).then(() => resolve(payload))
          break
        case 'receipt':
        case 'invoice':
          dispatch('modal/open', {
            modal: 'Invoice',
            objects: [
              {
                invoice_id: id
              }
            ]
          }).then(() => resolve(payload))
          break
        case 'notification':
        case 'activity':
          dispatch('modal/open', {
            modal: 'Activity',
            objects: [
              {
                activity_id: id
              }
            ]
          }).then(() => resolve(payload))
          break
        case 'assembly':
        case 'line_item':
        case 'cost_type':
        case 'cost_item':
          if (closeModals) dispatch('modal/closeAll')
          Router.push(`/items/${id}`, () => resolve(payload))
          break
        default:
          dispatch('modal/open', {
            modal: c.titleCase(type),
            objects: [
              {
                [`${type}_id`]: id
              }
            ]
          }).then(() => resolve(payload))
          break
      }
    })
  },

  /**
   * When there is a scoping nickname in the route ie: '/123-CompanyName/.../...',
   * then we should change to a scope of {company:123, user:...}, for the API server calls
   * as well as what we view here on the front-end.  Then if we go back out of
   *  the company scope to, for example '/notifications' which are only on the user scope,
   *  we must then back-out remove that company from our current scope.
   *
   *  This should be attached to a beforeEach or similar call on the router, to check every time
   *  the route chagnes, or from a component can be called like:
   *  this.$store.dispatch('setScopeByScopeRoute', this.$route);
   *
   * @param dispatch
   * @param rootState
   * @param {object} route    route object provided by VueRouter() class
   * @returns {*}
   */
  async setScopeByScopeRoute({ dispatch, rootState }, route) {
    const scopeRoute = route.params.scopeRoute || null
    const { scope } = await dispatch('getScope')

    const routeScope = await dispatch('getScopeFromRoute', scopeRoute)
    const scopesAvailable = {
      ...routeScope,
      user: scope.user || rootState.session.user.user_id
    }

    let reducedScope = {}
    if (route.meta.scopesAllowed) {
      Object.keys(scopesAvailable).forEach((scopeType) => {
        if (route.meta.scopesAllowed.includes(scopeType)) {
          reducedScope[scopeType] = scopesAvailable[scopeType]
        }
      })
    } else {
      reducedScope = scopesAvailable
    }

    return dispatch('setScope', {
      scope: reducedScope
    })
  },

  /**
   * Based on the scopeRoute provided, get the scope value.
   *
   * @param rootState
   * @param {string|null} scopeRoute  the scope nickname/moniker ie: for '/123-CompanyName/clients
   *                                  the scopeRoute portion would be '123-CompanyName' so that is
   *                                  what to provide here for ScopeRoute. That will then return
   *                                  { company: 123 } as the scope add-in for that scopeRoute
   *                                  nickname.
   * @returns {Promise<any>}
   */
  getScopeFromRoute({ rootState }, scopeRoute = null) {
    const currentScope = rootState.session.scope || {}
    if (
      scopeRoute &&
      scopeRoute in rootState.session.scopesByRouteName &&
      rootState.session.scopesByRouteName[scopeRoute]
    ) {
      return rootState.session.scopesByRouteName[scopeRoute] || currentScope
    }

    return currentScope
  },

  /**
   * The reverse of getScopeFromRoute.  Based on the current scope,
   * get the scopeRoute nickname/moniker to prefix onto the route.
   *
   * @param dispatch
   * @param rootState
   * @param payload
   *
   * @returns {Promise<string>}
   */
  async getScopeRouteFromScope({ dispatch, rootState }, payload = {}) {
    const { scope } = await dispatch('getScope', payload)
    const rest = _.omit(scope, 'user')
    const stringScopeName = JSON.stringify(Object.keys(rest).sort())
    const stringScopeValues = JSON.stringify(Object.values(rest).sort())
    const scopeRoutes = Object.keys(rootState.session.scopesByRouteName)

    return scopeRoutes.find(
      (route) =>
        _.jsonEquals(
          stringScopeName,
          Object.keys(rootState.session.scopesByRouteName[route]).sort()
        ) &&
        _.jsonEquals(
          stringScopeValues,
          Object.values(rootState.session.scopesByRouteName[route]).sort()
        )
    )
  },

  /**
   * Same as route, with no payload but directly provide the path instead.
   *
   * @param dispatch
   * @param rootState
   * @param {string} path
   *
   * @returns {*}
   */
  to({ dispatch }, path) {
    return dispatch('route', { path })
  },

  /**
   * Use the path param inside of payload to 'guess' at the best path
   * given the current scope.  If there is a page in the current scope with
   * path provided, go to the scoped page. If not, go out of the scope, and to the
   * page with root route provided.
   *
   * @param dispatch
   *    @param string path
   * @param rootState
   * @param payload
   * @returns {Promise<string>}
   */
  async guessPath({ dispatch }, payload) {
    const { path } = payload
    const rel = path.replace(/^\//, '')

    let route = Router.resolve(`/scoperoute/${rel}`) || false
    let scopedRoute = !!route?.params?.scopeRoute

    try {
      const scopeRoute = scopedRoute ? await dispatch('getScopeRouteFromScope') : false
      return scopeRoute ? `/${scopeRoute}/${rel}` : `/${rel}`
    } catch (e) {
      return `/${rel}`
    }
  },

  /**
   * Determines a route based on path, whether it is scoped, and the relevant scope
   * to use.
   *
   * @param dispatch
   * @param rootState
   * @param payload
   *
   * @returns {Promise<{route: RouteLocation & {href: string}, scoped: boolean, scope: Promise<string>}>}
   */
  async determineRoute({ dispatch }, payload) {
    const { path } = payload
    const rel = path.replace(/^\//, '')
    let route = Router.resolve(`/scoperoute/${rel}`)
    const scoped = !!route?.params?.scopeRoute
    const scope = await dispatch('getScopeRouteFromScope')

    if (route.name === 'NotFound' || scope === undefined) {
      route = Router.resolve(`/${rel}`)
    }

    return {
      route,
      scoped,
      scope
    }
  },

  /**
   * Like Router.push() but with guessPath() included so that we can go
   * to scoped paths (/companyName/clients etc)
   *
   * Calling dispatch('route', { path: 'clients' })
   * WHILE on '/123-CompanyName/pipeline'
   * will go to '/123-CompanyName/clients' page. NOT '/clients' page.
   *
   * @param dispatch
   * @param rootState
   * @param payload
   * @returns {Promise<*>}
   */
  async route({ dispatch }, payload) {
    const { path, query = {}, params = {} } = payload
    const determinedRoute = await dispatch('determineRoute', { path })
    // Already there?
    const currentRouteName = Router.currentRoute.value.name
    if (
      determinedRoute.route.name === currentRouteName &&
      !Object.keys(query).length &&
      !Object.keys(params).length
    )
      return true

    const allParams =
      determinedRoute.scoped && determinedRoute.scope
        ? { ...determinedRoute.route.params, scopeRoute: determinedRoute.scope }
        : determinedRoute.route.params
    return Router.push({ name: determinedRoute.route.name, query, params: allParams })
  },

  /**
   * After creating, editing or double-clicking on an object of some kind
   * this is a single method that will take you to the best way of viewing that
   * item in context, for example in a list.
   *
   * @param commit
   * @param dispatch
   * @param payload
   *    @param object object      single object to view
   *    @param array set          multiple objects to view
   *    @param bool closeModals   whether to close modals before changing page
   * @returns {Promise<*>}
   */
  async go({ dispatch }, payload) {
    const { object: initObject = false, set: initSet = false, closeModals = false } = payload
    const set = initSet || [initObject]
    const object = set[0]
    const type = object.type
    const ids = set.map((o) => o[`${type}_id`])
    const id = ids[0] // for single possibility only

    if (closeModals) {
      dispatch('modal/closeAll')
    }

    switch (type) {
      case 'conversation': {
        Router.push('/hub')
        break
      }

      case 'message': {
        const { object: resolvedObject } = await dispatch('Message/resolveObject', payload)
        const escaped = encodeURIComponent(resolvedObject.conversation_id).replace(
          /\./g,
          '_PERIOD_'
        )
        await dispatch.to(`/conversation/${escaped}`)
        break
      }

      case 'notification':
      case 'activity':
        await dispatch('modal/open', {
          modal: 'Activity',
          objects: [
            {
              activity_id: id
            }
          ]
        })
        break

      case 'file':
        dispatch('modal/open', {
          objects: [
            {
              ...payload.object
            }
          ],
          modal: {
            name: 'file'
          }
        })
        break

      case 'assembly':
      case 'line_item':
      case 'cost_type':
      case 'cost_item':
        await dispatch('to', '/items')
        break

      case 'client':
        if (
          object.client_status &&
          (object.client_status === 'l' || object.client_count_quotes === 0)
        ) {
          dispatch('view', {
            type: 'lead',
            filterText: {
              client_id: `${ids.length} leads`
            },
            closeModals
          })
        } else {
          dispatch('view', {
            type: 'client',
            filterText: {
              client_id: `${ids.length} clients`
            },
            closeModals
          })
        }
        break

      case 'quote':
        dispatch('view', {
          type: 'quote',
          filterText: {
            quote_id: `${ids.length} quotes`
          },
          closeModals
        })
        break

      default:
        dispatch('view', {
          type,
          filterText: {
            [`${type}_id`]: `${ids.length} ${type}s`
          },
          closeModals
        })
        break
    }

    return payload
  },

  /**
   * View an item in list based on type and id
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  async viewInList({ dispatch }, payload) {
    const { type, id } = payload

    return dispatch('view', {
      filterText: {
        [`${type}_id`]: '1 showing'
      },
      filters: {
        [`${type}_id`]: id
      },
      ...payload
    })
  },

  /**
   * View a list based on filters and other specifics provided
   * @param dispatch
   * @param payload
   *    @param string type
   *    @param object filters
   *    @param object filterText
   *    @param array order
   *    @param string searchPhrase
   *    @param bool closeModals
   * @returns {Promise<*>}
   */
  async view({ dispatch }, payload) {
    const {
      type = '',
      page = null,
      filters = {},
      filterText = {},
      order = [],
      searchPhrase = '',
      closeModals = true,
      // Sometimes need a delay for search server to fully
      // index and display
      delay = 1500
    } = payload

    let path = page
    if (!path) {
      const plural = `${type}s`
      path = `${plural}`
    }

    const determinedRoute = await dispatch('determineRoute', { path })
    const query = c.encodeGridQuery(
      Router.resolve({ name: determinedRoute.route.name }).path,
      filters,
      filterText,
      searchPhrase,
      order
    )

    if (closeModals) dispatch('modal/closeAll')
    await c.throttle(
      () => {
        dispatch('route', {
          path,
          query,
          params: payload
        })
      },
      {
        delay
      }
    )

    return payload
  },

  /**
   * FIFO request queue for ajax calls, so that only a certain amount
   * of request are called concurently without first returning something
   * so as not to overwhelm the API server.
   *
   * Will be based on the maximum number of requests allowed by maxRequests above.
   *
   * @param state
   * @param dispatch
   * @param commit
   * @param object|null payload
   * @returns {Promise<any>}
   */
  queueRequest() {
    return true
    // return new Promise((resolve) => {
    //   if (state.requestQueue.length <= state.maxRequests) {
    //     commit({
    //       type: types.ADD_REQUEST_QUEUE,
    //       promise: () => () => {}
    //     })
    //     resolve()
    //   } else {
    //     commit({
    //       type: types.ADD_REQUEST_QUEUE,
    //       promise: () => resolve
    //     })
    //   }
    // })
  },

  /**
   * Trigger the paywall modal
   * @param state
   * @param dispatch
   * @param commit
   * @param object|null payload
   * @returns {Promise<any>}
   */
  triggerPayWall({ commit }, payload = {}) {
    commit({
      type: types.SHIFT_REQUEST_QUEUE
    })
    return Promise.resolve(payload)
  },

  /**
   * Mark a queued request done so the next one can fire.
   * @param state
   * @param dispatch
   * @param commit
   * @param object|null payload
   * @returns {Promise<any>}
   */
  doneRequest({ state, commit }, payload = {}) {
    if (state.requestQueue.length) {
      state.requestQueue[0]()()
      commit({
        type: types.SHIFT_REQUEST_QUEUE
      })
    }
    return payload
  },

  /**
   * Get a link with a small token attached so it has the permissions required.
   * @param state
   * @param dispatch
   * @param rootState
   * @param payload
   *    @param string path          relative link path
   *    @param object scope         defaults to current scope
   *    @param object query
   *    @param bool setToken        whether to attach token or not
   * @returns {*}
   */
  link({ dispatch }, payload = {}) {
    const {
      path,
      scope = null,
      query = {},
      setToken = true,
      host = import.meta.env.VITE_BASE_FILE_URL
    } = payload

    return dispatch('getScope').then(({ scope: currentScope }) =>
      Promise.resolve({
        ...payload,
        link: c.link(path, query, setToken, scope || currentScope, host)
      })
    )
  },

  /**
   * Single point of contact with the API server
   * @param state
   * @param dispatch
   * @param rootState
   * @param payload
   * @returns {Promise<any>}
   */
  async ajax({ dispatch, rootState, getters: gets }, payload = {}) {
    const {
      path = '',
      authorization = [],
      scope = null,
      data: explicitData,
      token = null,
      setToken = false,
      useAuthScope = null,
      progress = () => {},
      host = import.meta.env.VITE_BASE_API_URL,
      externalRequest = host !== import.meta.env.VITE_BASE_API_URL &&
        host !== import.meta.env.VITE_WORKER_BASE_URL
    } = payload

    Perf.bench('ajax', path)

    const session = await fetchAuthSession()

    const longToken = token || _.getStorage('token') || null
    const shortToken = _.getStorage('shortToken') || null
    const accessToken =
      session.tokens?.accessToken.toString() || _.getStorage('accessToken') || null
    const idToken = session.tokens?.idToken.toString() || _.getStorage('idToken') || null
    const refreshToken = _.getStorage('refreshToken') || null

    // Set authorization
    let auth = {}

    if (!externalRequest) {
      auth = {
        token: longToken,
        shortToken,
        accessToken,
        idToken,
        refreshToken
      }
    }

    let ignoreScope = useAuthScope || false
    if (authorization && authorization.length === 2) {
      auth = {
        email: authorization[0],
        password: authorization[1]
      }
      ignoreScope = true
    }

    const { scope: currentScope } = await dispatch('getScope')
    const requestedScope =
      ignoreScope && !Object.keys(currentScope).length ? null : scope || currentScope

    const deformattedData =
      host === import.meta.env.VITE_BASE_API_URL ? c.formatForPHP(explicitData) : explicitData

    await dispatch('queueRequest')
    // If ignore scope is true, and explicit requested scope is null, and current scope is public,
    // ignore the public scope, and upgrade to whatever scope is included in authorization.

    let recd = {}
    try {
      recd = await Ajax.ajax({
        host,
        path,
        data: deformattedData,
        version: rootState.session.version,
        authorization: auth,
        scope: requestedScope,
        shouldCompress: !externalRequest && import.meta.env.VITE_ENCODE === 'true',
        progress
      })
    } catch (e) {
      if (e.hintMessage && (import.meta.env.DEV || import.meta.env.MODE === 'staging')) {
        console.log('[AJAXERR]', e.hintMessage, recd)
      }

      if (e.hintMessage?.includes('accessToken')) {
        _.removeStorageItem('accessToken')
        _.removeStorageItem('idToken')
      }
      throw e
    }

    if (recd.paywall) {
      await dispatch('savePayloadRequest', recd.requestPayload)
      await dispatch('togglePaywall', true)
      return {
        ...recd,
        payload
      }
    }

    if (rootState.session.version < recd.latestClientVersion) {
      _.throttle(async () => {
        const ignored =
          c.getCacheItem('versionReloadCheck', null, rootState.session) ||
          (rootState.session &&
            rootState.session.user &&
            rootState.session.user.user_terms_time_accepted)
        const isGuest = gets.isGuestUser
        if (isGuest) return
        let reload = false
        if (
          !ignored &&
          (await dispatch('modal/asyncConfirm', {
            message: 'There is an update of Bolster available, refresh to load it now.',
            subMessage: 'It is recommended that you load the update now, to avoid possible errors.'
          }))
        ) {
          reload = true
        }
        // Turn it off, for 12 hours
        c.setCacheItem(
          'versionReloadCheck',
          1,
          null,
          rootState.session,
          Date.now() + 8 * 60 * 60 * 1000
        )

        if (reload) window.location.reload()
      })
    }

    dispatch('doneRequest')

    Perf.bench('ajax, before formatForJS', path)

    const object = c.formatForJS(recd.payload)
    const set = _.makeArray(object) // so that it is formatted for js

    if (setToken) {
      await dispatch('setToken', recd)
      await dispatch('setShortToken', recd)
      await dispatch('setAccessToken', recd)
      await dispatch('setIdToken', recd)
      await dispatch('setRefreshToken', recd)
    }

    Perf.bench('ajax, after formatForJS', path, true)

    return {
      ...recd,
      payload: object,
      object,
      set
    }
  }
}

// mutations
const mutations = {
  [types.TOGGLE_PAYWALL](state, { toggle = null }) {
    state.showPaywall = toggle === 'null' ? !state.showPaywall : toggle
  },
  [types.SAVE_PAYLOAD_REQUEST](state, { payload }) {
    state.savedRequestPayload = payload
  },
  [types.SET_TRAVERSE_START_AT](state, { startAt = null }) {
    state.startAt = startAt
  },
  [types.TRAVERSE_ALTER](state, { resolve: r = null, mod = null, startAt = null }) {
    const itemSelectorResolve = r !== null ? r : state.itemSelectorResolve

    state.itemSelectorResolve = itemSelectorResolve
    if (startAt) state.startAt = startAt
    if (mod) state.itemSelectorMod = mod
  },
  [types.ADD_REQUEST_QUEUE](state, { promise }) {
    state.requestQueue = [promise, ...state.requestQueue]
  },
  [types.SHIFT_REQUEST_QUEUE](state) {
    const arr = state.requestQueue
    const fn = arr.shift()
    fn()
    state.requestQueue = arr
  },
  [types.ADD_EDIT_HOLD](localstate, { holdKey }) {
    const index = localstate.holds.indexOf(holdKey)
    if (index === -1) {
      localstate.holds.push(holdKey)
    }
  },
  [types.REMOVE_EDIT_HOLD](localstate, { holdKey }) {
    const index = localstate.holds.indexOf(holdKey)
    if (index > -1) {
      localstate.holds.splice(index, 1)
    }
  }
}

export default {
  state: initialState,
  getters,
  actions,
  mutations
}
