<template>
  <div class="relative">
    <Field
      v-model="localValue"
      :value="localValue"
      type="text"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
      @input="onChange"
      :emit-delay="100"
      class="!w-full h-10"
    />

    <Loader :loading="loading" />

    <div
      v-if="items"
      class="flex flex-col absolute top-9 left-0 overflow-y-auto max-h-[300px] rounded-b divide-y shadow-md w-full z-[10000] border-2 border-primary"
    >
      <a
        v-for="(item, k) in items"
        :key="k"
        @click="() => onSelect(item)"
        class="p-2 cursor-pointer bg-surface-100 hover:bg-surface-200 transition"
      >
        <span v-if="item[attribute]">
          {{ item[attribute] }}
        </span>
      </a>
      <p class="p-2 bg-surface-100" v-if="items && items.length === 0">No results</p>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from '../LoadingIndicator.vue'
import FieldSection from '../fields/FieldSection.vue'

export default {
  name: 'Selector',
  props: {
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Start Typing...'
    },
    label: {
      type: String,
      default: 'text'
    },
    attribute: {
      type: String,
      default: 'text'
    },
    search: {
      type: Function,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'focus', 'blur', 'change', 'select'],
  data() {
    return {
      localValue: '',
      items: null,
      loading: 0
    }
  },
  watch: {
    localValue() {
      this.$emit('input', this.localValue)
    },
    value() {
      if (this.value !== this.localValue) this.localValue = this.value
    }
  },
  created() {
    this.localValue = this.value
  },
  mounted() {
    document.getElementById('app').addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.getElementById('app').removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.close()
      }
    },
    clear() {
      this.close()
    },
    close() {
      this.items = null
      this.loading = 0
    },
    onFocus() {
      this.$emit('focus', this.localValue)
    },
    onBlur() {
      this.$emit('blur', this.localValue)
    },
    onChange() {
      this.$emit('change', this.localValue)
      this.onSearch()
    },
    async onSearch() {
      this.loading = 1
      await c.throttle(
        async () => {
          if (this.localValue && this.localValue !== '') {
            const { payload } = await this.search(this.localValue)
            this.items = payload
          }
        },
        { delay: 300 }
      )
      this.loading = 0
    },
    onSelect(selected) {
      this.$emit('select', selected)
      this.close()
      setTimeout(() => {
        this.loading = 0
      })
    }
  },
  components: {
    LoadingIndicator,
    FieldSection
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.selector-field {
  position: relative;
  left: 0;
  right: 0;
  min-width: 300px;
  z-index: 99;
  // .selector-field--results {
  //   position: absolute;
  //   z-index: 999;
  //   right: 0;
  //   min-width: 95%;
  //   left: 2.5%;
  //   right: 2.5%;
  //   background-color: $flame-white;
  //   max-width: 320px;
  //   white-space: initial;
  //   max-height: 300px;
  //   overflow-y: auto;
  //   top: calc(100% - 0.6em);
  // }
  // .selector-field--item {
  //   background-color: $flame-white;
  //   margin-top: 0;
  //   padding: 0;
  //   &:hover {
  //     background-color: $cool-gray-200;
  //   }
  // }
  .selector-field--close {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    color: $cool-gray-800;
  }
  .field-error {
    input {
      background: #fde1e4 !important;
      border: 3px solid rgba(239, 60, 82, 0.4) !important;
    }
  }
}
</style>
