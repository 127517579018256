import { computed, onBeforeMount, ref, toValue, watch } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { useStore } from 'vuex'
import { v4 } from 'uuid'
import CostType from '../../../imports/api/schemas/CostType.js'
import _ from '../../../imports/api/Helpers.js'
import AutoCost from '../../../imports/api/AutoCost.js'
import CostItem from '../../../imports/api/schemas/CostItem'

export default {
  useItemVariations(payload) {
    const {
      refId: rrefId,
      store: rstore,
      type: rtype,
      object: robject /* autoCommit = false */
    } = payload

    const refId = computed(() => toValue(rrefId))
    const store = computed(() => toValue(rstore))
    const type = computed(() => toValue(rtype))
    const downstreamVariationPossibilities = ref({})
    const $store = useStore()

    const {
      norm,
      object,
      cost_type_is_variation_parent,
      oVariations,
      oMeta,
      cost_type_name,
      cost_type_desc,
      cost_item_qty_net_base,
      selected_cost_type_id,
      cost_matrix_markup_net,
      cost_item_markup_net_adjustment,
      cost_matrix_aggregate_cost_net,
      cost_item_price_net
    } = EntityComputedFields.useEntityComputedFields({
      object: robject,
      refId,
      type,
      store
    })

    const target = computed(() => object.value)

    const isVariationItem = computed({
      get: () => !!cost_type_is_variation_parent.value
    })

    const optionGroupName = computed({
      get() {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) return target.value.cost_type_name

        return (
          oMeta.value?.optionGroupName ||
          target.value.variation_parent_cost_type_name ||
          `${target.value.cost_type_name}`
        )
      },
      set(name) {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) cost_type_name.value = name
        else {
          oMeta.value = {
            ...oMeta.value,
            optionGroupName: name
          }
        }
      }
    })

    const optionGroupDesc = computed({
      get() {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) return target.value.cost_type_desc

        return oMeta.value?.optionGroupDesc
      },
      set(name) {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) cost_type_desc.value = name
        else {
          oMeta.value = {
            ...oMeta.value,
            optionGroupDesc: name
          }
        }
      }
    })

    const selectionAudience = computed({
      get() {
        return oMeta.value?.selectionAudience ?? 'client'
      },
      set(name) {
        const selectionAudience = name === 'company' ? 'company' : 'client'
        oMeta.value = {
          ...oMeta.value,
          selectionAudience
        }
      }
    })

    const mockItems = [
      {
        name: 'Material',
        imgs: [],
        desc: '',
        rate: 0,
        tags: [],

        backgroundSize: 'cover',
        imageShape: 'circle', // square, circle, fit
        locked: 0,
        hidden: 0,
        traits: [
          {
            name: 'Granite',
            backgroundSize: 'cover'
          },
          {
            name: 'Cement',
            backgroundSize: 'cover'
          },
          {
            name: 'Oak',
            backgroundSize: 'cover'
          }
        ]
      },
      {
        name: 'Finish',
        imgs: [],
        desc: '',
        rate: 0,
        tags: [],
        imageShape: 'circle', // square, circle, fit
        locked: 0,
        hidden: 0,
        traits: [
          {
            name: 'Smooth',
            backgroundSize: 'cover'
          },
          {
            name: 'Natural',
            backgroundSize: 'cover'
          },
          {
            name: 'Leathered',
            backgroundSize: 'cover'
          }
        ]
      },
      {
        name: 'Thickness',
        imgs: [],
        desc: '',
        rate: 0,
        tags: [],
        imageShape: 'circle', // square, circle, fit
        backgroundSize: 'cover',
        locked: 0,
        hidden: 0,
        traits: [
          {
            name: '1"',
            backgroundSize: 'cover'
          },
          {
            name: '2"',
            backgroundSize: 'cover'
          },
          {
            name: '3"',
            backgroundSize: 'cover'
          }
        ]
      }
    ]

    const setupMockAddons = () => {
      variations.value.variationTypes = _.imm(mockItems)
    }

    // Allow deep mutations locally
    const variations = ref(c.imm(oVariations.value))
    // To get the UID for each cost_type_id
    const costTypeIdToUid = computed(
      () =>
        variations.value?.items.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: item.uid ?? null
          }),
          {}
        ) ?? {}
    )
    // To get the UID for each cost_type_id
    const uidToCostTypeId = computed(
      () =>
        variations.value?.items.reduce(
          (acc, item) => ({
            ...acc,
            [item.uid]: item.id ?? null
          }),
          {}
        ) ?? {}
    )
    const uidToLivePriceRef = computed(
      () =>
        variations.value?.items.reduce(
          (acc, item) => ({
            ...acc,
            [item.uid]: item.livePriceRef ?? null
          }),
          {}
        ) ?? {}
    )

    const variationItems = computed(() => {
      return variations.value.items
    })

    const variationTraits = computed(() => {
      return variations.value?.variationTypes.reduce((acc, variationType) => {
        return [...acc, ...(variationType.traits || [])]
      }, [])
    })
    const variationTraitsObject = computed(() => {
      const res = {}
      for (const vType of variations.value?.variationTypes || []) {
        res[vType.name] = (vType.traits || []).reduce((acc, vTrait) => {
          acc[vTrait.name] = vTrait
          return acc
        }, {})
      }
      return res
    })

    const variationTypes = computed(() => {
      return variations.value?.variationTypes
    })
    const variationsTypesObject = computed(() => {
      return variationTypes.value?.reduce((acc, variationType) => ({
        ...acc,
        [variationType.name]: variationType
      }))
    })

    const allVariationTypePossibilities = computed(() => {
      return CostItem.getAllVariationPossibilities(variationItems.value)
    })

    const selectedVariationTraitImages = computed(() => {
      const selectedVariations = variations.value?.selectedItem?.variations || {}
      return Object.keys(selectedVariations).reduce((acc, variationType) => {
        if (
          variationTraitsObject.value?.[variationType]?.[selectedVariations[variationType]]?.imgs
            ?.length
        ) {
          acc.push(
            c.link(
              `file/view/${variationTraitsObject.value?.[variationType][selectedVariations[variationType]].imgs[0]}`
            )
          )
        }
        return acc
      }, [])
    })

    const generatePermutations = (variationTypes) => {
      const keys = Object.keys(variationTypes || {})
      const result = []

      const generate = (current, index) => {
        const key = keys[index]
        const values = variationTypes?.[key] || []

        values.forEach((value) => {
          const newCombination = { ...current, [key]: value }

          if (index + 1 < keys.length) {
            generate(newCombination, index + 1)
          } else {
            result.push(newCombination)
          }
        })
      }

      generate({}, 0)
      return result
    }

    // Array of the different combos possible for each variation type trait value, array
    const fullPermutations = computed(() => {
      const traitOptions = variations.value?.variationTypes?.reduce(
        (acc, vtype) => ({
          ...acc,
          [vtype.name]: vtype.traits.map((trait) => trait.name)
        }),
        {}
      )

      return generatePermutations(traitOptions)
    })

    const orderedVtypes = computed(() =>
      variations.value?.variationTypes?.map((vtype) => vtype.name)
    )

    const getPermutationKey = (variationObject) =>
      orderedVtypes.value?.map((vtype) => variationObject?.[vtype] ?? 'None').join('|')

    // Map saved items based on the permutation key
    const itemsByPermutationKey = computed(() => {
      return (variations.value?.items ?? []).reduce((acc, item, index) => {
        return {
          ...acc,
          [getPermutationKey(item.variations)]: { ...item, index }
        }
      }, {})
    })
    const itemsByUid = computed(() => {
      return (variations.value?.items ?? []).reduce((acc, item, index) => {
        return {
          ...acc,
          [item.uid]: { ...item, index }
        }
      }, {})
    })

    const missingPermutations = computed(() =>
      fullPermutations.value.filter(
        (permutation) => !(getPermutationKey(permutation) in itemsByPermutationKey.value)
      )
    )

    const parentVariationRateNet = computed(() => {
      const markupAdjusted =
        cost_matrix_markup_net.value + (cost_item_markup_net_adjustment.value || 0)
      return c.round(cost_matrix_aggregate_cost_net.value * markupAdjusted, 4)
    })

    const parentPriceAdjustment = computed(() => {
      const selectionPrice = c.round(
        variations.value?.selectedItem?.rate || parentVariationRateNet.value,
        4
      )
      return parentVariationRateNet.value - selectionPrice
    })

    const priceDeltas = computed(() => {
      const all = _.imm(allVariationTypePossibilities.value)
      const currentRate = c.toNum(cost_item_price_net.value)
      const currentQty = c.toNum(cost_item_qty_net_base.value)

      return Object.keys(all)
        .filter(
          (vtype) =>
            !variationsTypesObject.value[vtype]?.locked &&
            !variationsTypesObject.value[vtype]?.hidden
        )
        .reduce(
          (acc, vtype) => ({
            ...acc,
            [vtype]: all[vtype].reduce((acc2, variation) => {
              const best = CostItem.findBestVariation(object.value, vtype, variation)
              const foundItem = best || null
              const fullItem = fullItems.value?.[foundItem?.uid]

              let delta = null
              if (
                fullItem &&
                (!selected_cost_type_id.value || foundItem?.id !== selected_cost_type_id.value)
              ) {
                const qtyPer = _.n(
                  fullItem.cost_type_materials_purchase_qty_per_unit === null
                    ? 1
                    : fullItem.cost_type_materials_purchase_qty_per_unit,
                  1
                )
                const withWastage =
                  currentQty * (1 + _.n(fullItem.cost_type_material_waste_factor_net, 0))
                const roundUp =
                  qtyPer !== 1 ||
                  String(fullItem.purchase_unit_of_measure_id) !==
                    String(fullItem.unit_of_measure_id)
                const baseQty = _.eq(currentQty, 0) ? 0 : withWastage / qtyPer
                const adjustedQty = roundUp ? Math.ceil(baseQty) * qtyPer : baseQty

                delta = c.round(
                  adjustedQty * c.toNum(fullItem.cost_matrix_rate_net) -
                    currentRate +
                    parentPriceAdjustment.value,
                  2
                )
                if (c.eq(delta, 0, 2)) delta = 0
              }

              return {
                ...acc2,
                [variation]: [delta, foundItem?.rate || null]
              }
            }, {})
          }),
          {}
        )
    })

    const getItemFromPermutationArray = (permutationArray) => {
      return {
        ...CostType.defaultVariationItem,
        uid: v4(),
        id: null, // not saved
        name: `${optionGroupName.value || target.value.cost_type_name || 'Option'} - ${Object.values(permutationArray).join(', ')}`, // not saved
        desc: '',
        rate: 0,
        cost: 0,
        variations: permutationArray
      }
    }

    const getDownstreamPossibleVariations = async (
      lockedSelections = {},
      chosenSelections = {}
    ) => {
      downstreamVariationPossibilities.value = await $store.dispatch(
        'CostItem/findPossibleVariations',
        {
          lockedSelections,
          chosenSelections,
          store: store.value,
          object: object.value
        }
      )

      return downstreamVariationPossibilities.value
    }

    const addVariationItems = (items) => {
      variations.value = {
        ...variations.value,
        items: [...(variations.value?.items ?? []), ...items]
      }
    }

    const loadMissingPermutationItems = (count) => {
      let perms = missingPermutations.value.slice(0, count)

      const missing = count - perms.length

      // add blanks if no missing permutations are found
      perms = [...perms, ...Array(missing).fill({})]
      const newItems = perms.map((perm) => getItemFromPermutationArray(perm))

      addVariationItems(newItems)

      return {
        newItems,
        allItems: variations.value.items ?? []
      }
    }

    let fullItems = ref({})
    const getSavedItem = async (id) => {
      const uid = costTypeIdToUid.value[id]
      if (!(uid in fullItems.value)) {
        // fetch
        const { object } = await $store.dispatch('CostType/fetch', {
          id
        })

        fullItems.value[uid] = object
      }

      return fullItems.value[uid] ?? {}
    }

    // takes costtypeids as ids
    // returns object with cost type id as key, and the full item as value
    const getSavedItems = async (ids) => {
      const unfetched = ids.filter((id) => !(costTypeIdToUid.value[id] in fullItems.value))
      if (unfetched.length) {
        // fetch
        const { set } = await $store.dispatch('CostType/filter', {
          filters: {
            cost_type_id: unfetched.join('||')
          }
        })

        fullItems.value = {
          ...fullItems.value,
          ...set.reduce(
            (acc, item) => ({
              ...acc,
              [costTypeIdToUid.value[item.cost_type_id]]: item
            }),
            {}
          )
        }
      }

      return ids.reduce(
        (acc, id) => ({
          ...acc,
          [id]: fullItems.value[costTypeIdToUid.value[id]] ?? {}
        }),
        {}
      )
    }

    const getLivePriceItems = async (livePriceUids) => {
      const unfetched = livePriceUids.filter((livePriceUid) => !(livePriceUid in fullItems.value))

      const normalized = $store.state.Quote.normalized
      const rootRefId = c.getNormalizedRootRefId(normalized)
      const quote = normalized[rootRefId]
      const company = $store.state.session.company
      const zipcode = AutoCost.getAutoCostZipcode(company, quote)

      if (unfetched.length) {
        const livePriceItems = await Promise.all(
          unfetched.map(async (livePriceUid) => {
            const res = await $store.dispatch('CostType/getDefaultLivePriceItem', {
              livePriceRef: uidToLivePriceRef.value[livePriceUid],
              company,
              zipcode
            })
            return {
              ...res,
              uid: livePriceUid,
              livePriceRef: res.live_price_reference
            }
          })
        )

        // apply modification factors
        const mod =
          target.value.mod_labor_net && target.value.mod_materials_net
            ? {
                mod_labor_net: target.value.mod_labor_net,
                mod_materials_net: target.value.mod_materials_net,
                mod_id: target.value.mod_id || null
              }
            : await $store.dispatch('Quote/getQuoteMod', {
                refId: target.value.refId
              })

        fullItems.value = {
          ...fullItems.value,
          ...livePriceItems.reduce(
            (acc, item) => ({
              ...acc,
              [item.uid]: CostType.applyMod(item, mod, item.cost_matrix_markup_net).item
            }),
            {}
          )
        }
      }
    }

    let defaultCostType = {}
    let overrides = {}
    onBeforeMount(async () => {
      // overrides = await dispatch('CostType/globalSaveCheck', !!$store.state.session.user.user_is_super_user)
      ;({ object: defaultCostType } = await $store.dispatch('CostType/buildDefaultObject'))
    })

    const fieldMapping = {
      uid: 'uid',
      cost_type_id: 'id',
      type: 'type',
      labor_type_id: 'labor_type_id',
      cost_type_name: 'name',
      cost_type_desc: 'desc',
      cost_matrix_rate_net: 'rate',
      cost_matrix_aggregate_cost_net: 'cost',
      file_ids: 'file_ids',
      live_price_reference: 'live_price_reference',
      livePriceRef: 'livePriceRef',
      cost_type_sku: 'sku'
    }

    const fieldUnmapping = Object.keys(fieldMapping).reduce(
      (acc, ctfield) => ({
        ...acc,
        [fieldMapping[ctfield]]: ctfield
      }),
      {}
    )

    const mapVariationItemToCostType = (vi) => {
      const parent = target.value

      return Object.keys(vi).reduce(
        (acc, vfield) => ({
          ...acc,
          [fieldUnmapping[vfield]]: vi[vfield] ?? parent[fieldUnmapping[vfield]]
        }),
        {}
      )
    }

    const convertItemToCostType = (vi) => {
      const found = fullItems.value[vi.uid] ?? {}
      const parent = target.value
      const ct = {
        ...defaultCostType,
        ...mapVariationItemToCostType(vi),

        // Addons should be empty, if found has some, let them be,
        // but they won't get added in when chosen as a selection
        aoAddons: [],

        // If this is a saved version, let it retain its equations etc,
        // these aren't loaded in when this variation is selected so it doesn't
        // matter anyway, since the equation and related values are taken
        // from the variation parent
        // If FOUND has any of these, they will get overridden when it is mixed in below
        cost_type_qty_equation: parent.cost_type_qty_equation,
        parent_cost_type_id: parent.parent_cost_type_id,
        asDimensionsUsed: parent.asDimensionsUsed,
        asRequiredDimensions: parent.asRequiredDimensions,

        // All of the following fields CAN be distinct between different
        // selections/variations. So, use th saved version if found, and if
        // not, then defaul to the parent's value for each of these.
        // If these are NOT set in the saved version, they should draw form the parent.
        stage_id: parent.stage_id,
        stage_name: parent.stage_name,
        cost_type_is_indexed: 0,

        labor_type_id: vi.labor_type_id || parent.labor_type_id,
        labor_type_name: parent.labor_type_name,
        labor_type_rate_net: parent.labor_type_rate_net,

        labor_type_is_indexed: parent.labor_type_is_indexed,
        trade_type_id: parent.trade_type_id,
        trade_type_name: parent.trade_type_name,
        vendor_id: parent.vendor_id,
        vendor_name: parent.vendor_name,

        // If it is found already, use the existing ID
        cost_type_id: vi.id,

        // These are fields that gets staged for the user, and the user
        // can edit. So save these as received from the variation item
        cost_type_name: vi.name,
        cost_type_desc: vi.desc,
        file_ids: vi.file_ids,
        oTag: vi.tag,
        cost_type_is_variation_parent: 0,
        cost_type_is_parent: 0,
        unit_of_measure_id: parent.unit_of_measure_id,
        unit_of_measure_abbr: parent.unit_of_measure_abbr,
        unit_of_measure_name: parent.unit_of_measure_name,
        cost_type_sku: vi.sku,
        live_price_reference: vi.live_price_reference,

        cost_type_has_labor: parent.cost_type_has_labor,
        cost_type_has_materials: parent.cost_type_has_materials,

        ...(vi.live_price_reference ? { cost_type_has_labor: 1, cost_type_has_materials: 1 } : {}),

        ...(AutoCost.isAutoCostLaborTypeId(vi.labor_type_id)
          ? {
              cost_matrix_labor_cost_net: 0,
              cost_matrix_aggregate_cost_net: vi.cost
            }
          : {}),

        // cost_matrix_markup_net: defaultMarkup,
        cost_matrix_markup_net: c.divide(vi.rate, vi.cost) || $store.getters.defaultMarkup,

        ...found,
        ...overrides.overrides,

        type: 'cost_type',
        uid: vi.uid,

        variations: vi.variations ?? {}
      }

      return ct
    }

    const convertCostTypeToVariationItem = (costType) => {
      // since it could be apartial change, find the overlap and only set those
      const ctfields = _.intersection(Object.keys(costType), Object.keys(fieldMapping))
      return ctfields.reduce(
        (acc, ctfield) => ({
          ...acc,
          [fieldMapping[ctfield]]: costType[ctfield]
        }),
        {}
      )
    }

    const generateNewCostTypeForUid = (uid) => {
      return convertItemToCostType(itemsByUid.value[uid])
    }

    // Generate a list of full costtype objects for the UIDS provied. NOT abbreviated variations.items !
    // will fetch saved itmes for ones that were saved, or generate blank items for those that haven't been saved yet
    // this is designed to happen JIT just in time for editing, so that we don't load thousands of blanks for no reason yet
    const getFullItems = async (uids) => {
      let toFetch = []
      for (const uid of uids) {
        if (uid in fullItems.value) continue // alreday have it

        const saved = uidToCostTypeId.value[uid]
        const savedLivePrice = uidToLivePriceRef.value[uid]
        if (saved || savedLivePrice) toFetch.push(uid)
        else {
          // generate now
          fullItems.value[uid] = generateNewCostTypeForUid(uid)
        }
      }

      const idsToFetch = toFetch.reduce(
        (acc, uid) => (uidToCostTypeId.value[uid] ? [...acc, uidToCostTypeId.value[uid]] : acc),
        []
      )
      const livePriceRefsToFetch = toFetch.reduce(
        (acc, uid) => (uidToLivePriceRef.value[uid] ? [...acc, uid] : acc),
        []
      )

      await getSavedItems(idsToFetch)
      await getLivePriceItems(livePriceRefsToFetch)

      return fullItems.value
    }

    const convertFromCostTypeToVariation = (item) => {
      return {
        id: item.cost_type_id,
        name: item.cost_type_name,
        desc: item.cost_type_desc,
        rate: item.cost_matrix_rate_net,
        cost: item.cost_matrix_aggregate_cost_net,
        markup: item.cost_matrix_markup_net || $store.getters.defaultMarkup
      }
    }
    const convertFromVariationToCostType = (item) => {
      return {
        cost_type_id: item.id,
        cost_type_name: item.name,
        cost_type_desc: item.desc,
        cost_matrix_rate_net: item.rate,
        cost_matrix_aggregate_cost_net: item.cost,
        cost_matrix_markup_net: _.divide(item.rate, item.cost)
      }
    }

    const variationItemChanges = ref({})

    const commit = () => {
      const vars = variations.value
      // Make sure only saved items are committed
      vars.items = vars.items?.filter((item) => item.id || item.livePriceRef) ?? []
      oVariations.value = vars
    }

    const addVariationType = () => {
      variations.value.variationTypes.push({
        name: 'New variation',
        backgroundSize: 'cover',
        imageShape: 'square', // square, circle, fit
        locked: 0,
        hidden: 0,
        traits: [
          {
            name: 'New variation',
            imgs: [],
            desc: '',
            rate: 0,
            tags: []
          }
        ]
      })
    }

    const addTrait = (vtypeIndex, embue = {}) => {
      variations.value.variationTypes[vtypeIndex].traits.push({
        ...CostType.defaultSelectionTraits,
        name: 'New variation',
        imgs: [],
        desc: '',
        rate: 0,
        tags: [],
        ...embue
      })
    }

    const removeTrait = (vtypeIndex, traitIndex) => {
      variations.value?.variationTypes[vtypeIndex].traits.splice(traitIndex, 1)
    }

    const removeVariationType = (vtypeIndex) => {
      variations.value?.variationTypes.splice(vtypeIndex, 1)
    }

    const setItemVariation = (uid, vtype, traitName) => {
      variations.value.items[itemsByUid.value[uid].index].variations[vtype] = traitName
    }

    const setItemField = (uid, field, value) => {
      variations.value.items[itemsByUid.value[uid].index][field] = value
    }

    const setItem = (uid, item) => {
      const newItem = {}
      for (const field in item) {
        if (!(field in CostType.defaultVariationItem) || field === 'variations') continue
        newItem[field] = item[field]
      }

      variations.value.items[itemsByUid.value[uid].index] = {
        ...(variations.value.items?.[itemsByUid.value[uid].index] ?? {}),
        ...newItem
      }
    }

    const selectItem = (id) => {
      return $store.dispatch('CostItem/embueVariation', {
        variation: id,
        store: store.value,
        refId: refId.value
      })
    }

    const selectVariation = (type, value) => {
      return $store.dispatch('CostItem/selectVariation', {
        variationType: type,
        variationValue: value,
        store: store.value,
        refId: refId.value
      })
    }

    const lockedSelections = computed(() => {
      const lockedVtypes = (variations.value?.variationTypes || []).filter((vtype) => vtype.locked)

      return lockedVtypes.reduce(
        (acc, vtype) => ({
          ...acc,
          [vtype]: variations.value?.selectedItem?.variations[vtype]
        }),
        {}
      )
    })

    const unlockedVariations = computed(() => {
      let possibleVariations = {}

      const addPossibilities = (variations) => {
        possibleVariations = {
          ...possibleVariations,
          ...Object.keys(variations).reduce(
            (acc, vtype) => ({
              ...acc,
              [vtype]: _.uniq([
                ...Object.keys(possibleVariations[vtype] || {}),
                ...(acc[vtype] || []),
                variations[vtype]
              ]).reduce((acc2, variation) => {
                acc2[variation] = true
                return acc2
              }, {})
            }),
            {}
          )
        }
      }

      variations.value?.items?.forEach((item) => {
        if (
          Object.keys(item.variations).every(
            (vtype) =>
              !(vtype in lockedSelections.value) ||
              item.variations[vtype] === lockedSelections.value[vtype]
          )
        ) {
          addPossibilities(item.variations)
        }
      })

      return possibleVariations
    })

    const unavailableCombos = computed(() => {
      const all = _.imm(allVariationTypePossibilities.value)
      const dst = _.imm(downstreamVariationPossibilities.value)

      return Object.keys(all).reduce(
        (acc, vtype) => ({
          ...acc,
          [vtype]: _.difference(all[vtype], dst[vtype]).reduce((acc2, variation) => {
            acc2[variation] = true
            return acc2
          }, {})
        }),
        {}
      )
    })

    const usedVariationTypeTraits = computed(() => {
      const used = {}

      // collect all posibilities
      for (const vtype in variations.value.variationTypes) {
        used[vtype.name] = variations.value.variationTypes?.traits?.reduce(
          (acc, trait) => ({ ...acc, [trait.name]: false }),
          {}
        )
      }

      for (const item of variations.value?.items ?? []) {
        for (const vtype of Object.keys(item.variations ?? {})) {
          used[vtype] ??= {}
          used[vtype][item.variations[vtype]] = true
        }

        if ([...Object.values(used)].every((val) => !!val)) break // stop if all found
      }

      return used
    })

    watch(oVariations, (val) => {
      variations.value = c.imm(val)
      return getDownstreamPossibleVariations(
        lockedSelections.value,
        variations.value?.selectedItem?.variations
      )
    })

    return {
      norm,
      object,
      isVariationItem,
      variations,
      target,
      optionGroupName,
      optionGroupDesc,
      orderedVtypes,
      priceDeltas,

      addTrait,
      addVariationType,
      removeTrait,
      removeVariationType,

      getSavedItem,
      getSavedItems,
      getLivePriceItems,

      convertFromCostTypeToVariation,
      convertFromVariationToCostType,
      commit,
      setupMockAddons,
      loadMissingPermutationItems,
      fullItems,
      getFullItems,
      mapVariationItemToCostType,
      convertItemToCostType,
      variationItemChanges,
      setItemVariation,
      setItemField,
      setItem,
      selectItem,
      selectVariation,
      convertCostTypeToVariationItem,
      getPermutationKey,
      getItemFromPermutationArray,
      addVariationItems,
      getDownstreamPossibleVariations,
      allVariationTypePossibilities,
      downstreamVariationPossibilities,
      variationItems,
      variationTypes,
      variationsTypesObject,
      variationTraits,
      variationTraitsObject,
      selectedVariationTraitImages,
      costTypeIdToUid,
      itemsByUid,
      unlockedVariations,
      lockedSelections,
      unavailableCombos,
      usedVariationTypeTraits,
      selectionAudience
    }
  }
}
