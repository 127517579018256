import { computed, onMounted, onBeforeUnmount, ref, defineOptions } from 'vue'
import { useStore } from 'vuex'
import eventBus from '@/eventBus'
import { useRouter } from 'vue-router'
import ChangeTracking from '@/components/mixins/ChangeTracking'
import useUserMeta from '@/components/composables/UserMeta.js'

export default {
  useOnboardingMilestones() {
    const { setMetaItem, getMetaItem } = useUserMeta()

    const currentPoints = computed(() => getMetaItem('onboardingMilestones')?.points ?? 0)
    const milestonesList = computed(() => getMetaItem('onboardingMilestones')?.list ?? [])

    const isMilestoneComplete = (name) => !!milestonesList.value.find((m) => m.name === name)

    const completeMilestone = (milestone) => {
      const { points = 0, name } = milestone

      // emit regardless if already done for a celebration
      eventBus.$emit('milestoneComplete', milestone)

      if (isMilestoneComplete(name)) return

      const milestones = getMetaItem('onboardingMilestones') ?? {}

      milestones.list = milestones.list ?? []
      milestones.list.push(milestone)
      milestones.points += points ?? 0
      milestones.latestAchieved = name

      setMetaItem('onboardingMilestones', {
        ...milestones
      })
    }

    return {
      milestonesList,
      isMilestoneComplete,
      completeMilestone,
      currentPoints
    }
  }
}
