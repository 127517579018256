<template>
  <div
    class="card-list card grid grid-flow-row auto-rows-max rounded-sm border border-surface-300"
    @click.stop="() => $emit('click')"
    :class="[
      'card-list card container-element',
      { touch: deviceStore.isTouch },
      flat ? 'flat-card-list' : '',
      { 'card-list-border': border }
    ]"
  >
    <ul :class="`list-group ${flush ? 'list-group-flush' : ''}`">
      <!-- must be CardListItem or CardListField component to work properly -->
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { useDeviceStore } from '@/stores/device'

/**
 * Modifier classes:
 * -borderless: no border
 * -flush: fits inside another card-list-item
 * -outlined: stronger border
 * -highlight
 *  -primary
 *  -secondary
 *  -dark
 *  -light
 *  -info
 *  -success
 *  -danger
 *  -warning
 */
export default {
  name: 'CardList',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    flush: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
}
</script>

<style lang="scss" rel="stylesheet/scss">
.card-list.card {
  overflow: hidden;
  background: transparent;
  // box-shadow: $shadow;
  height: auto !important;
  padding: 0 !important;
  max-width: 100%;
  width: 100%;

  &.card-list-border {
    border: 1px solid $primary-200;
  }

  &.flat-card-list {
    box-shadow: none;
    overflow: visible;
    > .list-group-item {
      border: solid 1px $cool-gray-200;
    }
    .list-group-item.card-list-item,
    .list-group-item.card-list-field {
      contain: initial;
      overflow: visible;
    }
  }

  > .list-group {
    height: 100%;
    > .card-list-item:not(.card-list-field) {
      flex: 1 1 auto;
    }

    > .list-group-item {
      .field-section-item {
        padding: 0 !important;
      }
    }
  }

  &.borderless {
    border: none;
    background: transparent;
  }

  &.flush {
    border-radius: 0 !important;
    width: auto;

    > ul {
      border-radius: 0 !important;
      > .list-group-item {
        border-radius: 0 !important;
      }
    }
  }

  &.outlined {
    border-color: $cool-gray-500;
  }

  &.highlight {
    border-left: 3px solid rgba($purple-500, 0.8);
    &.primary {
      border-left: 3px solid rgba($primary, 0.8);
    }
    &.dark {
      border-left: 3px solid rgba($cool-gray-600, 0.8);
    }
    &.light {
      border-left: 3px solid rgba($flame-white, 0.8);
    }
    &.danger {
      border-left: 3px solid rgba($deep-red-800, 0.8);
    }
    &.warning {
      border-left: 3px solid rgba($level-yellow, 0.8);
    }
    &.info {
      border-left: 3px solid rgba($blue-print-700, 0.8);
    }
    &.success {
      border-left: 3px solid rgba($matcha-500, 0.8);
    }
    &.secondary {
      border-left: 3px solid rgba(var(--secondary), 0.8);
    }
  }

  &.discreet,
  &.inline {
    border: none !important;
    box-shadow: none !important;
  }

  &.sm {
    font-size: 0.8em;
  }
  &.xs {
    font-size: 0.6em;
  }

  &.square {
    border-radius: 0 !important;
    > ul.list-group {
      border-radius: 0 !important;
      > .list-group-item {
        border-radius: 0 !important;
      }
    }
  }

  $transitionTime: 0.2s;
  $defaultHeight: 3em;

  &:not(.touch) {
    transition: all $transitionTime ease;

    &.button {
      $perspective: $defaultHeight * 10;

      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;

      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;

      transform-origin: center;

      perspective: $perspective;
      -webkit-perspective: $perspective;
      -moz-perspective: $perspective;
      -o-perspective: $perspective;
      -ms-perspective: $perspective;
      &:hover,
      &:active,
      &.active {
        transform: perspective($perspective) translate3d(0, 0, (-1 * $defaultHeight) * calc(2 / 3));
        -moz-transform: none;
        -ms-transform: none;
      }
    }
  }
}

.card:not(.card-list) {
  > .card-list.flush {
    border: none;
    border-radius: 0 !important;
    > ul.list-group.list-group-flush {
      background: transparent;
      border-radius: 0 !important;
      > li {
        border-radius: 0 !important;
      }
    }
  }
}

#app.small-format {
  .container-element,
  .container-element > .container-element,
  .container-element > .container-element > .container-element,
  .container-element > .container-element > .container-element > .container-element {
    > .card.card-list {
      border-radius: 0 !important;

      > .list-group {
        border-radius: 0;

        > .card-list-item {
          border-radius: 0;
        }
      }
    }
  }

  .cc-container,
  .scroll-container--container,
  .scroll-container--container > .scroll-container--inner,
  .see-more-container,
  .see-more-container > span,
  .see-more-content,
  .panel-container {
    > .card.card-list,
    > .card-section > .card.card-list,
    > .card-section > .card-section > .card.card-list,
    > .card-section > .card-section > .card-section > .card.card-list {
      border-radius: 0;
      > .list-group {
        border-radius: 0;
        > .card-list-item {
          border-radius: 0;
        }

        > :last-child,
        > :first-child {
          &.card-list-item,
          &.card-list--button {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }

        > .card-list--button {
          border-radius: 0 !important;
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;

          &:last-child,
          &:first-child {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
      }

      > .card-list--button {
        border-radius: 0 !important;

        &:last-child,
        &:first-child {
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }
}
</style>
