<template>
  <container v-if="selected">
    <card-section>
      <template #label>
        <btn-bar
          class="float-right"
          v-if="lead_source_id"
          :collapse="true"
          :actions="actions"
          :selected="[this]"
        />

        {{ !lead_source_id ? 'New lead source' : 'Lead source' }}
      </template>

      <card-list>
        <card-list-field v-if="lead_source_id">
          <span>Lead source id</span>

          <span class="text-info" style="user-select: all">
            {{ lead_source_id }}
          </span>
        </card-list-field>

        <card-list-field>
          <span>
            Lead source name
            <small class="text-info">
              A descriptive name for this lead, for example a marketing campaign, landing page, or
              social media.
            </small>
          </span>

          <field
            v-model="lead_source_name"
            schema="lead_source:lead_source_name"
            :validate="{ required: true }"
          />
        </card-list-field>

        <card-list-field>
          <span> Lead source description </span>

          <field v-model="lead_source_desc" type="textarea" schema="lead_source:lead_source_desc" />
        </card-list-field>
      </card-list>
    </card-section>

    <CardSection class="mb-0">
      <Btn class="lg info round block" :action="saveAndClose" :disabled="!isDirty"> Save </Btn>
    </CardSection>
  </container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'

export default {
  name: 'LeadSource',
  mixins: [
    ObjectManipulator(
      'lead_source' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */
    ),
    BodyMixin /* AutoSave */
  ],
  data() {
    return {}
  },
  computed: {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
