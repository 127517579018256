<template>
  <span v-if="!hideOnZero || count" @click.stop.prevent="getCount">
    <slot name="before"></slot>
    <slot></slot>
    {{ count }}
    <slot name="after"></slot>
  </span>
</template>

<script>
export default {
  name: 'Count',
  emits: ['count'],

  data() {
    return {
      count: 0
    }
  },

  mounted() {
    this.getCount()
  },

  methods: {
    getCacheKey() {
      return `countComponentCache-${this.type}-${JSON.stringify(this.filters)}-${this.searchPhrase}`
    },

    async getCount() {
      const cacheKey = this.getCacheKey()
      let cachedCount = c.getCacheItem(cacheKey, this.type)

      if (cachedCount === null) {
        const searchPhrase = this.searchPhrase || null
        const { payload } = await this.$store.dispatch(`${this.storeName}/ajax`, {
          path: `${this.type}/count${searchPhrase ? `/${searchPhrase}` : ''}`,
          data: {
            filters: {
              ...this.filterPresets,
              ...this.filters
            }
          }
        })

        c.setCacheItem(cacheKey, payload.count, this.type)
        cachedCount = payload.count
      }

      this.count = cachedCount
      this.$emit('count', cachedCount)

      return cachedCount
    }
  },

  computed: {
    storeName() {
      return _.titleCase(this.type)
    }
  },

  props: {
    /**
     * Searchphrase to count matches
     */
    searchPhrase: {
      type: String,
      default: ''
    },

    /**
     * Filters to count matches
     */
    filters: {
      type: Object,
      default: () => ({})
    },

    /**
     * Filter presets to count matches
     */
    filterPresets: {
      type: Object,
      default: () => ({})
    },

    /**
     * Entity type
     */
    type: {
      type: String,
      required: true
    },

    /**
     * Will hide this alltogether if it is 0
     */
    hideOnZero: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped></style>
