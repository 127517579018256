<template>
  <div
    class="absolute bg-surface-600 flex flex-col top-1 rounded -right-10 gap-0.5 overflow-hidden cursor-pointer"
    :class="{ hidden: !isInQuoteEditor }"
  >
    <div
      @click="toggleVisibility('isVisible')"
      class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
      :class="{ 'opacity-50': !showItem }"
      v-tooltip="visibleTooltip"
    >
      <font-awesome-icon :icon="['fas', 'eye']" />
    </div>

    <div
      @click="toggleVisibility('price')"
      class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
      :class="{ 'opacity-50': !showPrice }"
      v-tooltip="priceTooltip"
      v-if="isNotInteractiveItem"
    >
      <font-awesome-icon :icon="['fas', 'dollar-sign']" />
    </div>

    <div
      @click="toggleVisibility('qty')"
      class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
      :class="{ 'opacity-50': !showQty }"
      v-tooltip="quantityTooltip"
      v-if="isNotInteractiveItem"
    >
      <font-awesome-icon :icon="['fas', 'ruler-triangle']" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import eventBus from '@/eventBus'

const props = defineProps({
  isInQuoteEditor: {
    type: Boolean,
    required: true
  },
  object: {
    type: Object,
    required: true
  }
})

const visibility = computed(
  () =>
    (props.object.oViewOptions && props.object.oViewOptions.pres) || {
      price: 1,
      qty: 1,
      isVisible: 1
    }
)
const showItem = computed(() => visibility.value.isVisible)
const showPrice = computed(() => visibility.value.price)
const showQty = computed(() => visibility.value.qty)

const visibleTooltip = computed(() => (showItem.value ? 'Hide item' : 'Show item'))
const priceTooltip = computed(() => (showPrice.value ? 'Hide price' : 'Show price'))
const quantityTooltip = computed(() => (showQty.value ? 'Hide quantity' : 'Show quantity'))
const isNotInteractiveItem = computed(() => {
  const itemType = props.object.oMeta?.itemType
  const metaTypes = ['gallery', 'text']
  return !metaTypes.includes(itemType)
})

const setField = (fieldName, value, explicit = false, skipAudit = false) => {
  eventBus.$emit('PresentationModifyItem', {
    refId: props.object.refId,
    changes: {
      [fieldName]: value
    },
    skipAudit,
    explicit
  })
}

const toggleVisibility = (key) => {
  const newValue = visibility.value[key] ? 0 : 1
  setVisibilityOption(key, newValue)
}

const setVisibilityOption = (key, value) => {
  const pres = {
    ...visibility.value,
    [key]: value
  }
  const payload = {
    ...props.object.oViewOptions,
    pres
  }
  setField('oViewOptions', payload, true, true)
}
</script>
