import ExternalChanges from './ExternalChangesMixin'
import eventBus from '../../eventBus'

export default {
  mixins: [ExternalChanges],

  props: {
    autoSaveEnabled: {
      default: true
    },
    deselectOnDestroy: {
      default: false
    },
    autoSaveDelay: {
      default: 600000 // 10 mins
    }
  },

  inject: {
    inModal: {
      default: false
    },

    go: {
      default: true
    }
  },
  emits: ['saved', 'autoSaving', 'autoSaved'],

  data() {
    return {
      autoSaveEnabledLocal:
        this.autoSaveEnabled &&
        this.$store.state.session.authorizedUser &&
        !this.$store.state.session.authorizedUser.user_is_super_user,
      autoSaveSecondsLeft: 0,
      autoSaving: 0,
      autoSaveInterval: null,
      autoSaveAlert: null,
      autoSaveDelayLocal: this.autoSaveDelay,
      addingItem: false
    }
  },

  beforeUnmount() {
    if (this.autoSaveEnabledLocal && !this.deselectOnDestroy) {
      this.checkIfDirty()
      if (this.isDirty) {
        this.blockingAutoSave()
        this.$emit('saved')
        this.$emit('saved')
        eventBus.$emit('saved')
        eventBus.$emit(`saved-${this.type}-${this.refId}`)
      }
    }

    eventBus.$off(`${this.uid}-selected`)
    window.onbeforeunload = () => {}
    this.disableAutoSave()
  },

  watch: {
    autoSaveDelayLocal(delay, old) {
      if (delay !== old) {
        this.initializeAutoSave()
      }
    },
    autoSaveEnabledLocal(now, before) {
      if (now === before) {
        return
      }

      if (now) {
        this.enableAutoSave()
      } else {
        this.disableAutoSave()
      }
    }
    // autoSave(value) {
    //   this.enableAutoSave = value;
    // },
    // enableAutoSave(value) {
    //   if (value) {
    //     this.startAutoSave();
    //   } else {
    //     clearInterval(this.autoSaveInterval);
    //   }
    // },
  },
  methods: {
    async endAutoSave() {
      this.autoSaving = 0

      if (this.autoSaveAlert) {
        this.$store.dispatch('removeAlert', {
          alert: this.autoSaveAlert
        })
      }

      this.$emit(`saved-${this.type}-${this.refId}`)
      eventBus.$emit(`saved-${this.type}-${this.refId}`)
    },

    async startAutoSave() {
      this.autoSaving = 1

      // this.autoSaveAlert = await this.$store.dispatch('alert', {
      //   message: 'Saving..',
      //   loading: 1,
      //   timeout: 0,
      //   muted: 1,
      // });
    },

    /**
     * Autosave
     */
    getAutoSaveData(refIdsToSave = []) {
      let refIds = refIdsToSave
      let changes = {}
      let explicitChanges = {}
      let removed = []
      const norm = _.imm(this.$store.state[this.storeName].normalized)

      if (!refIds.length) {
        changes = this.changeManagerLocal.getChanges(false, true, true)
        explicitChanges = this.changeManagerLocal.getExplicitChanges(false, true)

        removed = Object.keys(changes).filter((ref) => changes[ref].changed === 'removed')

        refIds = Object.keys(changes)
      }

      // After we have refIds, get all relevant parents
      if (refIds.length) {
        // get all parent refs too
        refIds.forEach((childRef) => {
          let child = childRef
          do {
            if (!refIds.includes(child)) {
              refIds.push(child)
            }

            child =
              (norm[child] && norm[child].parentRefId) ||
              (changes[child] && changes[child].parentRefId) ||
              null
          } while (child)
        })
      }

      if (this.global) {
        norm[this.refId].company_id = 0
      }

      const normalized = refIds.reduce(
        (acc, ref) => ({
          ...acc,
          [ref]: norm[ref]
        }),
        {}
      )

      return {
        normalized,
        changes: [changes],
        explicitChanges: [explicitChanges],
        removed
      }
    },

    blockingAutoSave(forceSaveRefIds = []) {
      const refId = this.refId
      const quoteId = this.getField('quote_id')
      const data = this.getAutoSaveData(forceSaveRefIds)

      if (!forceSaveRefIds.length) {
        this.$store.dispatch('Quote/resetChanges', { refId })
      }

      this.$emit('autoSaving')

      return this.$store.dispatch('ajax', {
        path: `quote/saveNormalized/${quoteId}`,
        data,
        queue: false
      })
    },

    async throttleAutoSave() {
      if (!this.autoSaveEnabledLocal) return false
      await c.throttle(() => this.autoSave(), {
        key: this.refId,
        delay: 200
      })

      return this
    },

    async autoSave(forceSaveRefIds = []) {
      if (this.autoSaving || !this.isDirty || this.addingItem) {
        return this
      }

      this.autoSaving = 1
      const backup = this.changeManagerLocal.getState()
      this.startAutoSave()

      try {
        eventBus.$emit('saving-quote')
        eventBus.$emit('saving')
        await this.$store.dispatch(`${this.storeName}/auditDependencies`, {
          immediate: true,
          refId: this.refId,
          queue: false
        })
        try {
          await this.integrateExternalChanges()
        } catch (e) {
          // ignore and continue
        }
        await this.beforeSave()
        const blocking = await this.blockingAutoSave(forceSaveRefIds)

        await this.afterSave()

        if (!blocking) {
          return this
        }

        const { object } = blocking

        await this.$store.dispatch('Quote/selectiveReload', {
          refId: this.refId,
          normalized: object,
          queue: false
        })

        await this.$store.dispatch('alert', {
          message: 'Changes saved.',
          timeout: 10000
        })

        this.autoSaveDelayLocal = this.autoSaveDelay
        this.enableAutoSave()
        this.$emit('autoSaved')
        eventBus.$emit('saved-quote')
        eventBus.$emit('saved')
        this.setField('change_order_time_last_modified', Date.now() + 10)
      } catch (e) {
        eventBus.$emit('error-quote')
        // prevent too many ajax calls
        this.autoSaveDelayLocal = this.autoSaveDelay
        // this.autoSaveDelayLocal + this.autoSaveDelay;

        this.endAutoSave()

        // Add back changes from before, as well as ones that are there right now.
        const newFieldChanges = this.changeManagerLocal.getExplicitFieldChanges() || []
        const combinedFieldChanges = [
          ...Object.keys(backup.state.explicitFieldChanges),
          ...Object.keys(newFieldChanges)
        ].reduce(
          (acc, ref) => ({
            ...acc,
            [ref]: {
              ...(acc[ref] || {}),
              ...(backup.state.explicitFieldChanges[ref] || {}),
              ...(newFieldChanges[ref] || {})
            }
          }),
          {}
        )

        this.changeManagerLocal.setState({
          ...backup,
          state: {
            ...backup.state,
            explicitFieldChanges: combinedFieldChanges,
            currentNormalized: this.$store.state[this.storeName].normalized
          }
        })

        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage || 'There was an error saving.'
        })

        throw e
      } finally {
        this.endAutoSave()
      }

      return this
    },

    initializeAutoSave() {
      if (!this.autoSaveEnabledLocal) {
        return
      }

      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval)
      }

      this.autoSaveInterval = setInterval(() => {
        if (this.isDirty && !this.autoSaving) {
          this.throttleAutoSave()
        }
      }, this.autoSaveDelayLocal)

      this.autoSaving = 0
    },

    disableAutoSave() {
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval)
      }

      this.autoSaveEnabledLocal = false
    },

    enableAutoSave() {
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval)
      }

      this.autoSaveEnabledLocal = this.autoSaveEnabled

      if (this && this.refId === this.rootRefId && this.changeManagerLocal) {
        this.initializeAutoSave()
      }
    }
  },
  mounted() {
    window.onbeforeunload = () => {
      if (this.autoSaveEnabledLocal) {
        this.checkIfDirty()

        if (this.isDirty) {
          const ask = async () => {
            if (
              await this.$store.dispatch('modal/asyncConfirm', {
                message: 'Save your changes?'
              })
            ) {
              await this.autoSave()
            }
          }
          ask()
          // this.blockingAutoSave();
          return 'You have unsaved changes, are you sure you want to leave?'
        }
      }

      return undefined
    }

    eventBus.$on(`${this.uid}-selected`, async () => {
      await this.$nextTick()

      this.changeManagerLocal = this.changeManager

      if (this.refId === this.rootRefId && this.autoSaveEnabledLocal) {
        this.enableAutoSave()

        if (this.deselectOnDestroy) {
          throw new Error('This component cannot be auto saved if deselectOnDestroy is enabled.')
        }
      }

      return true
    })
  }
}
