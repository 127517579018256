import { ref, onBeforeUnmount, onMounted, getCurrentInstance, watch, computed, nextTick } from 'vue'

export default {
  useRowOptions(args) {
    const {
      getRowId,
      totalGutterWidth,
      rowHeadingWidth,
      getColWidth,
      getCellBoundingRect,
      refGripClick,
      disableRowResizeHover,
      highlightCollapseGroup,
      collapseGroupGutterPositionsVisible,
      hideCollapseGroupHighlight,
      isCollapseGroupParent,
      moveRowTo,
      addRow,
      getRowFromId,
      cellPositionsVisible,
      rowPositionsVisible,
      getRowParent,
      rowsMap,
      moveCell,
      focusCell,
      multiSelect,
      canvasCursorType,
      commitValue,
      cellFocused
    } = args

    const $this = getCurrentInstance().proxy

    const showSelectBox = ref(null)
    const viewingSelectBoxOptions = ref(null)
    const selectedRows = ref([])

    watch(selectedRows, (selected = []) => {
      const rowSelectedData = selected.reduce(
        (acc, id) => ({
          ...acc,
          [getRowFromId(id)]: {
            id
          }
        }),
        {}
      )

      $this.$emit('rowsSelected', {
        rows: rowSelectedData,
        ids: selected
      })
    })

    const autoSelected = ref(false)
    watch(multiSelect, (ms) => {
      if (!ms && autoSelected.value) {
        selectedRows.value = []
        autoSelected.value = false
      }

      const [[startCol, startRow], [, endRow]] = ms || [
        [null, null],
        [null, null]
      ]

      if (startCol === null || startRow === null || endRow === null) return

      // If multiple cells are selected, clear all of them
      if (startCol === 0 && endRow - startRow >= 1) {
        const rowsSelected = []
        for (let i = startRow; i <= endRow; i++) {
          rowsSelected.push(getRowId(i))
        }
        autoSelected.value = true
        selectedRows.value = rowsSelected
      }
    })

    const handleSelectBoxHover = (eventData) => {
      const { x } = eventData

      const { sheetIndex } = eventData.element

      const { row } = eventData.element.position

      if (row <= -1 || row === null) {
        showSelectBox.value = null
        return
      }

      const xleft = totalGutterWidth.value
      const xright = totalGutterWidth.value + rowHeadingWidth.value + getColWidth(0, sheetIndex)

      if (x >= xleft && x <= xright) {
        showSelectBox.value = row
      } else {
        showSelectBox.value = null
      }
    }

    const handleSelectBoxMouseout = () => {
      showSelectBox.value = null
    }

    const selectBoxStyles = computed(() => {
      const indexes = selectedRows.value.map((id) => getRowFromId(id))
      if (!indexes.length) {
        return {}
      }

      const styles = {}
      for (let i = 0; i < indexes.length; i += 1) {
        const row = String(indexes[i])

        if (!(row in cellPositionsVisible.value)) {
          styles[selectedRows.value[i]] = {
            display: 'none',
            opacity: 0,
            position: 'absolute',
            top: '-1000px',
            left: '-1000px'
          }
          continue
        }

        const cellPos = Object.values(cellPositionsVisible.value[row])[0]
        const { y: rtop, x: rleft, height } = cellPos

        const top = rtop + (height / 2 - 12)

        const left = rleft - 32

        styles[selectedRows.value[i]] = {
          position: 'absolute',
          top: `${top}px`,
          left: `${left}px`,
          zIndex: '100'
        }
      }

      return styles
    })

    const selectBoxStyle = computed(() => {
      const toShowFor = viewingSelectBoxOptions.value ?? showSelectBox.value
      if (toShowFor === null || gripMouseDown.value || !(toShowFor in rowPositionsVisible.value)) {
        return {
          display: 'none'
        }
      }

      const cellPos = rowPositionsVisible.value[toShowFor]
      const { y: top } = cellPos

      const left = totalGutterWidth.value + rowHeadingWidth.value - 74
      return {
        position: 'absolute',
        top: `${top - 2}px`,
        left: `${left}px`,
        zIndex: '100'
      }
    })

    const grabStyle = ref({
      display: 'none',
      opacity: 0
    })
    const gripRow = ref(null)
    const showGripOptions = () => {
      const { left, top, height } = getCellBoundingRect(0, viewingSelectBoxOptions.value)

      grabStyle.value = {
        position: 'absolute',
        top: `${top + height}px`,
        left: `${left - 60}px`,
        opacity: 1
      }

      gripRow.value = viewingSelectBoxOptions.value
    }

    const hideGripOptions = () => {
      viewingSelectBoxOptions.value = null
      grabStyle.value = {
        display: 'none',
        opacity: 0
      }
    }

    const handleAddClick = async (from = showSelectBox.value) => {
      const rowPositionFrom = from ?? null
      addRow(rowPositionFrom)
      await nextTick()

      const rowFocus = (rowPositionFrom ?? rowsMap.value.length - 2) + 1
      moveCell(0, rowFocus)
      await nextTick()
      await nextTick()
      focusCell()
    }

    const handleGripClick = () => {
      viewingSelectBoxOptions.value = showSelectBox.value
      showGripOptions()
    }

    const gripMouseDown = ref(null)

    const movingRow = ref(null)
    const handleGripMousedown = () => {
      if (cellFocused.value) {
        commitValue()
        cellFocused.value = false
      }
      movingRow.value = showSelectBox.value
      gripMouseDown.value = true
      canvasCursorType.value = 'grabbing'
      refGripClick.value.style.cursor = 'grabbing'
      disableRowResizeHover.value = true
    }

    const moveRow = ref(null)

    const handleGripMouseup = () => {
      if (!gripMouseDown.value) return

      gripMouseDown.value = false
      firstCellCoords.value = {}
      canvasCursorType.value = 'cell'
      refGripClick.value.style.cursor = 'grab'
      disableRowResizeHover.value = false

      if (moveRow.value === null || movingRow.value === null) {
        // Act as a click
        moveRow.value = null
        movingRow.value = null
        handleGripClick()
        return
      }

      moveRowTo(movingRow.value, moveRow.value, getRowFromId(movingParentId.value))
      moveRow.value = null
      movingRow.value = null
      movingParentId.value = null
      hideCollapseGroupHighlight()
      viewingSelectBoxOptions.value = null
    }

    const firstCellCoords = ref({})

    const insertIndicatorStyle = computed(() => {
      if (!gripMouseDown.value) {
        return {
          display: 'none'
        }
      }

      const { top, left, width } = firstCellCoords.value

      return {
        display: 'block',
        height: '8px',
        width: `${width + 8}px`,
        position: 'absolute',
        top: `${top - 4}px`,
        left: `${left - 8}px`
      }
    })

    const movingParentId = ref(null)
    const handleGripMousemove = (eventData) => {
      if (!gripMouseDown.value) return

      const { y } = eventData
      const { row } = eventData.element.position

      const rect = getCellBoundingRect(0, row)

      const yPercentage = rect.height === 0 ? 0 : (y - rect.y) / rect.height

      const yDelta = yPercentage > 0.5 ? 1 : 0

      moveRow.value = row + yDelta

      if (yDelta) {
        rect.y += rect.height
        rect.top = rect.y
      }

      firstCellCoords.value = rect

      // Figure out the parent
      const isParent = isCollapseGroupParent(row)
      const isLastRow = row >= rowsMap.value.length - 1

      let parentId = getRowParent(row)
      if (isParent && yDelta) {
        // itself
        parentId = getRowId(row)
      } else if (!isParent && isLastRow && yPercentage >= 0.75) {
        // get grandparent
        parentId = getRowParent(getRowFromId(getRowParent(row)))
      } else if (!isParent && yPercentage >= 0.75) {
        // next row's parent
        parentId = getRowParent(row + 1)
      }
      movingParentId.value = parentId

      const parentRow = getRowFromId(parentId)
      const cgpi = collapseGroupGutterPositionsVisible.value.findIndex((cgp) => cgp.id === parentId)
      if (isCollapseGroupParent(parentRow) && cgpi > -1) {
        highlightCollapseGroup({
          ...collapseGroupGutterPositionsVisible.value[cgpi],
          row: moveRow.value
        })
      }
    }

    const handleGripMouseout = () => {
      // unhighlight assembly
      hideCollapseGroupHighlight()
    }

    const handleGripMouseover = () => {
      // check current row
      const row = showSelectBox.value

      const id = getRowId(row)
      const cgpi = collapseGroupGutterPositionsVisible.value.findIndex((cgp) => cgp.id === id)
      if (isCollapseGroupParent(row) && cgpi > -1) {
        highlightCollapseGroup({
          ...collapseGroupGutterPositionsVisible.value[cgpi],
          row
        })
      }
      // if it is an assembly / collapse group parent, hightlight the assembly
      // if not do nothing
    }

    onMounted(() => {
      $this.$on('mouseoverRowHeading', handleSelectBoxHover)
      $this.$on('mouseoutRowHeading', handleSelectBoxMouseout)
      $this.$on('mouseoverCell', handleSelectBoxHover)
      $this.$on('mouseoutCell', handleSelectBoxMouseout)
      $this.$on('clickCanvas', hideGripOptions)
      $this.$on('mousemoveCanvas', handleGripMousemove)
      window.addEventListener('mouseup', handleGripMouseup)
    })

    onBeforeUnmount(() => {
      $this.$off('mouseoverRowHeading', handleSelectBoxHover)
      $this.$off('mouseoutRowHeading', handleSelectBoxMouseout)
      $this.$off('mouseoverCell', handleSelectBoxHover)
      $this.$off('mouseoutCell', handleSelectBoxMouseout)
      $this.$on('mousemoveCanvas', handleGripMousemove)
      window.removeEventListener('mouseup', handleGripMouseup)
    })

    return {
      handleGripClick,
      handleAddClick,
      handleGripMouseup,
      handleGripMousedown,
      selectBoxStyle,
      selectBoxStyles,
      showSelectBox,
      selectedRows,
      insertIndicatorStyle,

      handleGripMouseout,
      handleGripMouseover,

      grabStyle,
      gripRow,
      hideGripOptions
    }
  }
}
