<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    size="md"
    :width="800"
    ref="modal"
    :scrollable="true"
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #header>Authenticate to continue</template>
    <template #body>
      <div class="flex justify-center items-center min-h-96">
        <transition name="fade" mode="out-in">
          <component
            :is="steps[currentStepIndex].component"
            :key="currentStepIndex"
            :clientEmail="oClient.user_email"
            :clientPhone="oClient.user_phone"
            :isClaimedAccount="!oClient.user_is_using_temp_pass"
            :tokenMessage="tokenMessage"
            @selectedMethod="handleSelectedMethod"
            @valid="handleToken"
            :valid="validToken"
            @codeChanged="handleCodeChanged"
            :resendCode="resendCode"
          />
        </transition>
      </div>
    </template>
    <template #footer>
      <div class="flex gap-6 justify-end">
        <Btn
          class="w-52 py-2"
          severity="tertiary"
          :disabled="isResendDisabled"
          @click="dispatchAction(steps[currentStepIndex].secondaryAction)"
        >
          <span v-if="resendTimeout > 0">Resend code ({{ resendTimeout }}s)</span>
          <span v-else>{{ steps[currentStepIndex].secondaryAction }}</span>
        </Btn>
        <Btn
          class="w-52 py-2"
          severity="bolster"
          @click="dispatchAction(steps[currentStepIndex].primaryAction)"
          :disabled="isDisabled"
        >
          {{ steps[currentStepIndex].primaryAction }}
        </Btn>
      </div>
    </template>
  </modal>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import QuoteSend from '../bodies/QuoteSend.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import SendAuthCode from '@/components/bodies/SendAuthCode.vue'
import InputAuthCode from '@/components/bodies/InputAuthCode.vue'
import TwoFactorAuthField from '@/components/ui/fields/TwoFactorAuthField.vue'
import eventBus from '@/eventBus'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields'

export default {
  name: 'TwoFactorAuth',
  mixins: [ObjectModalMixin],
  components: { QuoteSend, SendAuthCode, InputAuthCode, TwoFactorAuthField },
  props: {
    type: {
      default: 'quote'
    },
    modal: {
      type: Object,
      default: null
    }
  },
  setup() {
    const store = useStore()

    const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
      trackChanges: false
    })

    const { oClient, quote_id: quoteId } = EntityComputedFields.useEntityComputedFields({
      refId,
      type,
      store: storeName
    })

    // Reactive state
    const currentStepIndex = ref(0)
    const resendTimeout = ref(0)
    const tokenTimestamp = ref('')
    const validToken = ref(null)
    const tokenMessage = ref('')
    const selectedMethod = ref('')
    const verificationCode = ref('')
    const resendCode = ref(false)

    // Steps definition
    const steps = [
      {
        component: 'SendAuthCode',
        primaryAction: 'Send',
        secondaryAction: 'Cancel'
      },
      {
        component: 'InputAuthCode',
        primaryAction: 'Submit',
        secondaryAction: 'Resend code'
      }
    ]

    // Computed property for disabling the primary button
    const isDisabled = computed(() => {
      if (currentStepIndex.value === 0) return !selectedMethod.value
      return verificationCode.value.length !== 6
    })

    // Computed property for disabling the resend button
    const isResendDisabled = computed(() => {
      return currentStepIndex.value === 1 && resendTimeout.value > 0
    })

    // Handlers for actions
    const actionHandlers = {
      Send: async (quoteId) => {
        resendCode.value = true
        startResendTimeout()
        if (currentStepIndex.value < steps.length - 1) {
          currentStepIndex.value++
        }
        await store.dispatch('Quote/sendApprovalToken', {
          method: selectedMethod.value,
          quoteId
        })
        resendCode.value = false
      },
      Submit: async (quoteId) => {
        const { valid, message } = await store.dispatch('Quote/validateApprovalToken', {
          token: verificationCode.value,
          quoteId
        })
        handleToken(valid, message)
        if (valid) {
          eventBus.$emit('termsSaved')
          await dispatchAction('Cancel')
        }
      },
      'Resend code': async (quoteId) => {
        resendCode.value = true
        await store.dispatch('Quote/sendApprovalToken', {
          method: selectedMethod.value,
          quoteId
        })
        startResendTimeout()
        resendCode.value = false
      },
      Cancel: async () => {
        await store.dispatch('modal/closeAll')
      }
    }

    const dispatchAction = async (actionButtonName) => {
      const handler = actionHandlers[actionButtonName]
      if (handler) await handler(quoteId.value)
    }

    // Utility functions
    const startResendTimeout = () => {
      resendTimeout.value = 59
      const interval = setInterval(() => {
        resendTimeout.value--
        if (resendTimeout.value === 0) {
          clearInterval(interval)
        }
      }, 1000)
    }

    const handleToken = (valid, message) => {
      validToken.value = valid
      tokenMessage.value = message
    }

    const handleCodeChanged = (newCode) => {
      verificationCode.value = newCode
    }

    const updateClientPhone = async () => {
      const phoneValue = await store.dispatch('prompt', {
        message: "This client doesn't have a phone number on record, what is their number?",
        format: 'phone'
      })
      if (!phoneValue) return false
      await store.dispatch('Client/partialUpdate', {
        selected: [
          {
            client_id: oClient.client_id,
            type: 'client',
            user_phone: phoneValue,
            user_has_phone: 1
          }
        ]
      })
      oClient.user_phone = phoneValue
      oClient.user_has_phone = 1
      await store.dispatch('alert', {
        message: 'Phone added to your user. You can now send via SMS.'
      })
      return phoneValue
    }

    const handleSelectedMethod = async (method) => {
      const canUpdatePhone = oClient.user_is_using_temp_pass === 0 && !oClient.user_phone
      if (canUpdatePhone && method === 'sms') {
        await updateClientPhone()
      }
      selectedMethod.value = method
    }

    return {
      steps,
      oClient,
      validToken,
      isDisabled,
      resendCode,
      tokenMessage,
      resendTimeout,
      selectedMethod,
      tokenTimestamp,
      isResendDisabled,
      currentStepIndex,
      dispatchAction,
      handleToken,
      handleCodeChanged,
      handleSelectedMethod
    }
  }
}
</script>
