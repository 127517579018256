import { toRefs, reactive, computed, ref } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import useGantts from '@/components/ui/gantt/Gantts'
import { workdays as asWorkdays } from '@/components/schedule/fields/defaultWorkdays'
import Status from '@/../imports/api/Statuses'
import { workdays } from '@/components/schedule/fields/defaultWorkdays'

export const iconLookup = {
  project: 'bars-progress',
  quote: 'bars-progress',
  task: 'list-check',
  subtask: 'square-check',
  list: 'money-check-pen',
  user: 'user-helmet-safety',
  vendor: 'truck-field'
}

export const initialState = {
  emit: null,
  props: {},
  data: {
    data: [],
    links: [],
    resources: []
  },
  columns: [],
  settings: {
    asWorkdays,
    oWorkingHours: {
      start: 8,
      end: 16
    }
  },
  filters: {},
  orderLists: {},
  todayMarker: null,
  query: '',
  showSearch: false,
  interval: 'week',
  downloadFormat: null,
  displayGrid: true,
  lazyLoad: false,
  expanded: false,
  antecedent: null,
  defaultWorkDays: workdays,
  intervalSpan: 6,
  downloadFormats: [
    {
      value: 'pdf',
      text: 'Download as PDF'
    },
    {
      value: 'png',
      text: 'Download as PNG'
    }
  ],
  intervals: [
    {
      value: 'day',
      text: 'Day'
    },
    {
      value: 'week',
      text: 'Week'
    },
    {
      value: 'month',
      text: 'Month'
    }
  ],
  // This is used for switching calendar interval
  levels: [
    {
      name: 'day',
      scale_height: 50,
      min_column_width: 300,
      scales: [
        {
          unit: 'day',
          step: 1,
          format: '%d %M'
        }
      ]
    },
    {
      name: 'week',
      scale_height: 50,
      min_column_width: 80,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: (date) => {
            const day = gantt.value.date.date_to_str('%d')
            const month = gantt.value.date.date_to_str('%M')
            const endDate = gantt.value.date.add(date, 6, 'day')
            const startMo = month(date)
            const endMo = month(endDate)
            return `WEEK: ${startMo === endMo ? startMo : ''} ${startMo !== endMo ? startMo : ''} ${day(date)} - ${startMo !== endMo ? endMo : ''} ${day(endDate)}`
          }
        },
        {
          unit: 'day',
          step: 1,
          format: (date) => {
            const day = moment(date).format('DD')
            const dayOfWeek = moment(date).format('dd')
            return `<span class="flex justify-center text-sm gap-1"><span>${day}</span> - <span>${dayOfWeek}</span></span>`
          }
        }
      ]
    },
    {
      name: 'month',
      scale_height: 50,
      min_column_width: 50,
      scales: [
        {
          unit: 'month',
          format: '%F, %Y'
        },
        {
          unit: 'day',
          step: 1,
          format: '%d'
        }
      ]
    },
    {
      name: 'quarter',
      height: 50,
      min_column_width: 150,
      scales: [
        {
          unit: 'quarter',
          step: 1,
          format: (date) => {
            const dateToStr = gantt.value.date.date_to_str('%M')
            const endDate = gantt.value.date.add(gantt.value.date.add(date, 3, 'month'), -1, 'day')
            return `${dateToStr(date)} - ${dateToStr(endDate)}`
          }
        },
        {
          unit: 'month',
          step: 1,
          format: '%M'
        }
      ]
    },
    {
      name: 'year',
      scale_height: 50,
      min_column_width: 350,
      scales: [
        {
          unit: 'year',
          step: 1,
          format: '%Y'
        }
      ]
    }
  ],
  before: null,
  selected: [],
  groups: [
    {
      value: '',
      text: 'Stages'
    },
    {
      value: 'priority',
      text: 'Priority'
    },
    {
      value: 'assignee_ids',
      text: 'Assignee'
    }
  ],
  groupBy: '',
  sortBy: 'start_date',
  sortDir: false,
  dragging: false,
  version: null,
  minDate: null,
  maxDate: null,
  vueInstances: {}
}

// global state
let context = reactive({
  ...initialState
})

let gantt = ref(null)

export default () => {
  // composables
  const store = useStore()
  const { storeGantt, getGantt, clearGantt } = useGantts()

  // refs
  const originalParentId = ref()

  // computed
  const filterCount = computed(
    () =>
      context.filters &&
      Object.keys(context.filters).filter(
        (k) =>
          (context.filters[k] !== null && context.filters[k].constructor !== Array) ||
          (context.filters[k] &&
            context.filters[k].constructor === Array &&
            context.filters[k].length !== 0)
      ).length
  )

  // methods

  /**
   * When items need to be refreshed
   * @param {Object} params
   */
  const onRefreshItems = ({ items }) => {
    items.forEach((i) => {
      const { id } = i
      const task = gantt.value.getTask(id)
      // if we can't find the item create instead of update
      if (!task) {
        gantt.value.addTask(i, i.parent || 0, 1)
        return
      }
      if (i.start_date) task.start_date = i.start_date
      if (i.end_date) task.end_date = i.end_date
      if ('duration' in i) task.duration = i.duration
      if ('text' in i) task.text = i.text
      if (i.parent) task.parent = i.parent
      if (i.stage_id) task.stage_id = i.stage_id
      if (i.assignees) task.assignees = i.assignees
      if (i.assignee_ids) task.assignee_ids = i.assignee_ids
      if (i.item_status) task.item_status = i.item_status
      if (i.completed_count) task.completed_count = i.completed_count
      if (i.remaining_time) task.remaining_time = i.remaining_time
      if (i.progress) task.progress = i.progress
      if (i.stage_name) task.stage_name = i.stage_name
      if (i.list) task.list = i.list
      gantt.value.updateTask(task.id)
    })
  }

  /**
   * When grid needs to be toggled
   */
  const onHideGrid = () => {
    if (context.displayGrid) {
      gantt.value.config.grid_width = 0
    } else {
      gantt.value.config.grid_width = 400
    }
    context.displayGrid = !context.displayGrid
    gantt.value.render()
  }

  /**
   * Clear the stored filters
   */
  const clearFilters = () => {
    Object.keys(context.filters).forEach((k) => {
      if (context.filters[k].constructor === Array) {
        context.filters[k] = []
        return
      }
      context.filters[k] = null
    })
    context.emit('clear-filters', context.filters)
  }

  /**
   * Clear the selected items
   */
  const clearSelected = () => {
    context.selected = []
  }

  /**
   * Handle the selection of workdays
   * @param {Array} days
   */
  const handleWorkdays = () => {
    const { asWorkdays, oWorkingHours } = context.settings
    const formatted = asWorkdays.map((i) => parseInt(i, 10))
    const difference = [0, 1, 2, 3, 4, 5, 6].filter((x) => !formatted.includes(x))
    const { start, end } = oWorkingHours
    // set non working days
    difference.forEach((day) => {
      gantt.value.setWorkTime({ day, hours: false })
    })
    // set work time
    formatted.forEach((day) => {
      gantt.value.setWorkTime({ day, hours: [`${start}:00-${end}:00`] })
    })
    gantt.value.render()
  }

  /**
   * Expand all bars
   */
  const expandAll = () => {
    context.expanded = !context.expanded
    context.data.data.forEach(({ id }) => {
      if (context.expanded) {
        gantt.value.open(id)
        return
      }
      gantt.value.close(id)
    })
  }

  /**
   * Reload the gantt
   */
  const reload = () => {
    context.expanded = false
    context.emit('reload')
  }

  /**
   * When selecting download by format
   * @param {*} format
   */
  const onDownload = (format) => {
    // Initialize options variables
    const formatType = format || context.downloadFormat
    const companyName = store.state.session.company.company_name
    const fileName = `${companyName.replace(' ', '_').toLowerCase()}_schedule.${formatType}`
    const { start_date: startDate, end_date: endDate } = gantt.value.getSubtaskDates()
    // Options object passed to the gantt export functions
    // See: https://docs.dhtmlx.com/gantt/desktop__export.html
    const options = {
      name: fileName,
      start: moment(startDate).format('DD-MM-YYYY'),
      end: moment(endDate).add(1, 'day').format('DD-MM-YYYY'),
      skin: 'material',
      raw: true
    }
    // Export to specified file type
    if (formatType === 'pdf') gantt.value.exportToPDF(options)
    if (formatType === 'png') gantt.value.exportToPNG(options)
    context.downloadFormat = null
  }

  /**
   * When changing the desired interval
   * @param {String} selectedInterval
   * @returns
   */
  const onIntervalChange = (selectedInterval) => {
    if (selectedInterval === 'today') {
      goToToday()
      return
    }
    zoomTo(selectedInterval || context.interval)
  }

  /**
   * When sorting by a key
   * @param {String} key
   */
  const onSortBy = (key) => {
    context.sortBy = key
    context.sortDir = !context.sortDir
    gantt.value.sort(key, context.sortDir)
  }

  /**
   * When grouping based on a group by
   * @param {String} groupBy
   * @returns
   */
  const onGroupBy = (groupBy) => {
    const toGroupBy = groupBy || context.groupBy
    if (toGroupBy) {
      gantt.value.groupBy({
        groups: gantt.value.serverList(toGroupBy),
        relation_property: toGroupBy,
        group_id: 'key',
        group_text: 'label'
      })
      clearSelected()
      return
    }
    context.groupBy = ''
    gantt.value.groupBy(false)
    clearSelected()
  }

  const isSelectedItem = (id) => {
    return context.selected.indexOf(id) !== -1
  }

  /**
   * Add or remove from the selected
   * @param {*} val
   * @param {String} id
   * @returns
   */
  const updateSelected = (val, id) => {
    if (val) {
      context.selected = [...context.selected, id]
      return
    }
    const index = context.selected.indexOf(id)
    context.selected.splice(index, 1)
  }

  /**
   * When zooming in
   * @returns
   */
  const zoomIn = () => gantt.value.ext.zoom.zoomIn()

  /**
   * When zooming out
   * @returns
   */
  const zoomOut = () => gantt.value.ext.zoom.zoomOut()

  /**
   * When zooming to a specific zoom level
   * @returns
   */
  const zoomTo = (level) => gantt.value.ext.zoom.setLevel(level)

  /**
   * Go to today's date on the gantt
   * @param {Date} todayMarker
   * @returns
   */
  const goToToday = (todayMarker) => {
    gantt.value.showDate(todayMarker || context.todayMarker)
  }

  const addMarker = (date, text = 'Today') => {
    gantt.value.addMarker({
      start_date: date,
      text
    })
    return date
  }

  /**
   * Add order lists to gantt store
   * @param {Object} orderLists
   * @returns
   */
  const addOrderLists = (orderLists) => {
    const lists = orderLists || context.orderLists
    if (!lists) return
    Object.keys(lists).forEach((k) => {
      gantt.value.serverList(k, lists[k])
    })
  }

  /**
   * Get the date when dragging the item to help show user the dates
   * @param {Date} date
   * @param {Object} item
   * @returns
   */
  const getDateOnDrag = (date, item) => {
    const state = gantt.value.getState()
    const modes = gantt.value.config.drag_mode
    if (state.drag_id !== item.id) {
      return ''
    }
    if (
      state.drag_mode === modes.move ||
      (state.drag_mode === modes.resize && !state.drag_from_start)
    ) {
      return moment(gantt.value.roundDate(date)).format('MMM DD')
    }
    return ''
  }

  /**
   * Initialize the tooltips
   */
  const initTooltips = () => {
    // remove default tooltips
    gantt.value.ext.tooltips.detach(`[${gantt.value.config.task_attribute}]:not(.gantt_task_row)`)
    gantt.value.ext.tooltips.tooltipFor({
      selector: '.gantt_task_line',
      html: (event) => {
        if (!event.target.parentNode) return ''
        let taskId = event.target.parentNode.attributes.task_id
        if (!taskId) taskId = event.target.parentNode.parentNode.attributes.task_id
        if (!taskId) taskId = event.target.parentNode.parentNode.parentNode.attributes.task_id
        if (!taskId) return ''
        const id = taskId.nodeValue
        const item = gantt.value.getTask(id)
        const { start_date: start, end_date: end, type } = item
        const formattedStart = gantt.value.templates.tooltip_date_format(start)
        const formattedEnd = gantt.value.templates.tooltip_date_format(end)
        if (context.props.onTooltipRender) {
          return context.props.onTooltipRender(formattedStart, formattedEnd, item, iconLookup[type])
        }
        return item.text
      }
    })
  }

  /**
   * Get the task notice to fit when its being dragged
   * @param {*} task
   * @returns
   */
  const getTaskFitValue = (task) => {
    const { start_date: startDate, end_date: endDate, text } = task
    const taskStartPos = gantt.value.posFromDate(startDate)
    const taskEndPos = gantt.value.posFromDate(endDate)

    const width = taskEndPos - taskStartPos
    const textWidth = (text || '').length * gantt.value.config.font_width_ratio
    // if the width is less than the text width attach title to the side
    if (width < textWidth) {
      const ganttLastDate = gantt.value.getState().max_date
      const ganttEndPos = gantt.value.posFromDate(ganttLastDate)
      if (ganttEndPos - taskEndPos < textWidth) return 'left'
      return 'right'
    }
    // default to centering the text
    return 'center'
  }

  /**
   * Set all the templates replacing default templates
   */
  const setTemplates = () => {
    // template for expanding element
    gantt.value.templates.grid_open = ({ $open }) =>
      `<div class="gantt-expand mr-2"><i class='far ${$open ? 'gantt_close fa-chevron-down' : 'gantt_open fa-chevron-right'}'></i></div>`
    // template for parent list items (projects, stages)
    gantt.value.templates.grid_folder = () => ''
    // `<div class="gantt-indicator gantt-indicator--${type} ml-0 my-auto mr-2"><i class="far fa-${iconLookup[type]}"></i></div>`
    // template for items with no children (cost items)
    gantt.value.templates.grid_file = () => ''
    // this template is to remove extra spacing in resources section
    gantt.value.templates.grid_blank = ({ type }) =>
      type !== 'work' ? '<div class="gantt_tree_icon gantt_blank"></div>' : ''
    // custom classes for the dependency arrows
    gantt.value.templates.link_class = (link) => {
      const { type } = link
      const types = gantt.value.config.links
      switch (type.toString()) {
        case types.finish_to_start:
          return 'finish_to_start'
        case types.start_to_start:
          return 'start_to_start'
        case types.finish_to_finish:
          return 'finish_to_finish'
        case types.start_to_finish:
          return 'start_to_finish'
        default:
          return ''
      }
    }
    // The task bar template text
    gantt.value.templates.task_text = (start, end, task) => {
      if (context.props.onBarRender) return context.props.onBarRender(start, end, task)
      if (getTaskFitValue(task) === 'center') return task.text
      return ''
    }
    // add new gantt entity classes
    gantt.value.templates.task_class = (start, end, task) => {
      if (task.type === gantt.value.config.types.subtask) {
        return task.is_task ? 'subtask task-item' : 'subtask'
      }
      if (task.type === gantt.value.config.types.quote) {
        return `quote quote-status-${(Status.statuses[task.status] || 'in Progress').replace(/\s+/g, '-').toLowerCase()}`
      }
      if (task.type === gantt.value.config.types.list) {
        return 'list'
      }
      return ''
    }
    // add the date to the left of bar when dragging
    gantt.value.templates.leftside_text = (start, end, item) => {
      return getDateOnDrag(start, item)
    }
    // add the date to the right side of the bar when dragging
    gantt.value.templates.rightside_text = (start, end, item) => {
      return getDateOnDrag(end, item)
    }
    // highlight the non working days
    gantt.value.templates.timeline_cell_class = (item, date) => {
      if (!gantt.value.isWorkTime(date, 'day')) return 'week_end'
      return ''
    }
  }

  /**
   * Update the timeline and reload data
   * @param {String} dir
   */
  const updateTimeline = (dir = 'start-end') => {
    if (dir === 'start' || dir === 'start-end') {
      const start = moment(context.minDate)
        .subtract(context.intervalSpan, 'months')
        .format('YYYY-MM-DD hh:mm')
      context.emit('reload-data', {
        startDate: moment(start).format('X'),
        endDate: moment(context.minDate).format('X')
      })
      context.minDate = start
    }
    if (dir === 'end' || dir === 'start-end') {
      const end = moment(context.maxDate)
        .add(context.intervalSpan, 'months')
        .format('YYYY-MM-DD hh:mm')
      context.emit('reload-data', {
        startDate: moment(context.maxDate).format('X'),
        endDate: moment(end).format('X')
      })
      context.maxDate = end
    }
  }

  const isSimilar = (query, text) => {
    const regex = new RegExp(query.split(' ').join('|'), 'i')
    return regex.test(text)
  }

  /**
   * Does the item match the filter criteria
   * @param {Object} item
   * @returns
   */
  const isMatch = (item) => {
    const appliedFilters = Object.keys(context.filters).reduce((acc, i) => {
      const val = context.filters[i]
      if (val !== null) acc[i] = val
      return acc
    }, {})

    const keys = Object.keys(appliedFilters)
    if (keys.length === 0) return true
    // map result of comparison so we can
    const results = keys.map((key) => {
      const filter = context.filters[key]
      if (filter.constructor === Array) {
        if (filter.length === 0) return true
        if (!item[key] || item[key].length === 0) return false
        let intersection = []
        if (item[key].constructor === Array) {
          intersection = _.intersection(
            item[key].map((v) => v.toString()),
            filter.map((v) => v.toString())
          )
        } else {
          intersection = _.intersection(
            [item[key]],
            filter.map((v) => v.toString())
          )
        }
        return intersection.length > 0
      }
      if (/^-?\d+$/.test(item[key])) {
        return parseInt(item[key], 10) === parseInt(filter, 10)
      }
      return item[key] === filter
    })
    const show = !results.includes(false)
    return show
  }

  /**
   * Setup the events for event handli
   */
  const setEvents = () => {
    gantt.value.createDataProcessor((entity, action, data, id) => {
      context.emit(`${entity}-updated`, id, action, data)
    })

    // after the gantt has rendered
    gantt.value.attachEvent('onGanttRender', () => {
      context.emit('on-ready')
    })

    // after the task bar was dragged
    gantt.value.attachEvent('onAfterTaskDrag', (id) => {
      const item = gantt.value.getTask(id)
      context.emit('task-dragged', {
        before: context.before,
        after: item
      })
    })

    gantt.value.attachEvent('onBeforeTaskDrag', (id, mode) => {
      const item = gantt.value.getTask(id)
      context.before = { ...item }
      const { reference_type: referenceType } = item
      // turned on project drag which triggers saving the stages so just return null for project
      if (referenceType === 'project' && mode === 'resize') return false
      // disable material list start and end changing
      if (referenceType === 'list') return false
      return true
    })

    // handle clicking any of the bars
    gantt.value.attachEvent('onTaskClick', (id, e) => {
      // make sure this event only has an affect if they are clicking the bar
      const target = e.target
      const parent = target.parentNode
      const grandParent = parent.parentNode
      if (
        target.className === 'gantt_task_content' ||
        parent.className === 'gantt_task_content' ||
        grandParent.className === 'gantt_task_content'
      ) {
        const item = gantt.value.getTask(id)
        // slight delay for mobile the tooltip hide was not being registered
        if (gantt.value) gantt.value.ext.tooltips.tooltip.hide()
        // hide the tooltip when clicking
        context.emit('item-click', item)
      }
      return true
    })

    gantt.value.attachEvent('onTaskRowClick', (id, row) => {
      const item = gantt.value.getTask(id)
      const { $open } = item
      const { classList } = row
      if ([...classList].includes('gantt_close')) {
        gantt.value.close(id)
        return
      }
      if ([...classList].includes('gantt_open')) {
        gantt.value.open(id)
        return
      }
      if ($open) {
        gantt.value.close(id)
        return
      }
      gantt.value.open(id)
    })

    // An event before  a task is shown, this is where the filters are applied
    gantt.value.attachEvent('onBeforeTaskDisplay', (id, item) => {
      if (context.groupBy !== '' && item.reference_type !== 'item' && !item.$virtual) return false
      return true
    })

    // Add this to disable default double click action
    gantt.value.attachEvent('onTaskDblClick', (item) => {
      context.emit('double-click', item)
      return false
    })

    gantt.value.attachEvent('onBeforeLinkDelete', (id) => {
      const link = gantt.value.getLink(id)
      if (link.custom) return false
      return true
    })

    // a dependency link has been added
    gantt.value.attachEvent('onAfterLinkAdd', (id, item) =>
      context.emit('link-added', {
        id,
        item
      })
    )

    // a dependency link has been deleted
    gantt.value.attachEvent('onAfterLinkDelete', (id, item) =>
      context.emit('link-deleted', {
        id,
        item
      })
    )

    // before adding links check if you can
    gantt.value.attachEvent('onBeforeLinkAdd', (id, link) => {
      const sourceItem = gantt.value.getTask(link.source)
      const targetItem = gantt.value.getTask(link.target)
      const { type: sourceType } = sourceItem
      const { type: targetType } = targetItem
      if (sourceType === 'list' || targetType === 'list') {
        gantt.value.alert('You cannot add dependencies between material list items')
        return false
      }
      if (sourceType === 'subtask' && targetType === 'task') {
        gantt.value.alert('You cannot add dependencies between labor items and stages')
        return false
      }
      if (sourceType === 'subtask' && targetType === 'subtask') {
        gantt.value.alert('You cannot add dependencies between labor items')
        return false
      }
      if (sourceType === 'project' || targetType === 'project') {
        gantt.value.alert('You cannot add dependencies between projects')
        return false
      }
      return true
    })

    // a dependency link has been updated
    gantt.value.attachEvent('onAfterLinkUpdate', (id, item) =>
      context.emit('link-updated', {
        id,
        item
      })
    )

    // Before  scheduling store a version of the changes
    gantt.value.attachEvent('onBeforeAutoSchedule', (itemId) => {
      const item = gantt.value.getTask(itemId)
      context.antecedent = item
      context.version = c.cloneDeep(context.data.data)
      return true
    })

    // On before task auto scheduling we want a bit more control
    gantt.value.attachEvent('onBeforeTaskAutoSchedule', (item, start, link, predecessor) => {
      const { id: itemId, reference_type: referenceType } = item
      if (!predecessor) return false
      const { reference_type: refType } = predecessor
      if (!context.antecedent) return true
      const { id } = context.antecedent
      const children = gantt.value.getChildren(id)
      if (
        refType === 'stage' &&
        (referenceType === 'item' || referenceType === 'list') &&
        children.includes(itemId)
      ) {
        return false
      }
      return true
    })

    // After auto schedule we emit the changes so we can manipulate data on our end
    gantt.value.attachEvent('onAfterAutoSchedule', (taskId, updatedTasks) => {
      if (updatedTasks.length === 0) return true
      const beforeTasks = context.version.filter((v) => updatedTasks.includes(v.id))
      // tasks after changes
      const after = updatedTasks.reduce((acc, itemId) => {
        const {
          id,
          reference_type: referenceType,
          start_date: startDate,
          end_date: endDate,
          assignees = []
        } = gantt.value.getTask(itemId)
        if (referenceType === 'item' && assignees.length > 0) {
          acc.push({
            id,
            start_date: startDate,
            end_date: endDate
          })
        }
        return acc
      }, [])
      if (after.length === 0) return true
      // tasks before changes
      const before = beforeTasks.reduce((acc, item) => {
        const {
          id,
          reference_type: referenceType,
          start_date: startDate,
          end_date: endDate,
          assignees = []
        } = item
        if (referenceType === 'item' && assignees.length > 0) {
          acc.push({
            id,
            start_date: startDate,
            end_date: endDate
          })
        }
        return acc
      }, [])
      // emit change set
      context.emit('auto-schedule-update', {
        before,
        after
      })
      context.version = null
      return true
    })

    // After the drag has been completed emit event to parent
    gantt.value.attachEvent('onRowDragEnd', (id, target) => {
      const item = gantt.value.getTask(id)
      const parent = gantt.value.getTask(target)
      context.emit('row-dragged', {
        id,
        target,
        item,
        parent
      })
      document.body.classList.remove('gantt-cursor-grabbing')
      context.dragging = false

      // If the dragged item is a task, check if its previous parent (stage) is empty
      if (item && item.reference_type === 'item' && originalParentId.value) {
        const remainingChildren = gantt.value.getChildren(originalParentId.value)
        // If the original stage has no remaining tasks, remove the stage
        if (remainingChildren.length === 0) {
          context.emit('delete-stage', originalParentId.value)
        }
      }
    })

    gantt.value.attachEvent('onRowDragStart', (id, target) => {
      const isDragHandle =
        target.classList.contains('gantt-dragg') ||
        target.parentNode.classList.contains('gantt-dragg')

      if (isDragHandle) {
        // Add the 'grabbing' class to the target or the target's parent node
        document.body.classList.add('gantt-cursor-grabbing')
        context.dragging = true
        return true // Allow the drag to continue
      }
      return false // Block the drag if it's not the correct handle
    })

    // Only allow labor items to be dragged in the row view
    gantt.value.attachEvent('onBeforeRowDragMove', (id) => {
      const item = gantt.value.getTask(id)
      if (item.reference_type === 'item') originalParentId.value = item.parent
      if (item.reference_type === 'item' || item.reference_type === 'stage') return true
      return false
    })

    // before dragging a row in the grid check to see if we want to continue events
    gantt.value.attachEvent('onBeforeRowDragEnd', (id, parent) => {
      const item = gantt.value.getTask(id)
      const parentItem = gantt.value.getTask(parent)
      // Ignore drag if the item wasn't dragged onto a stage or
      // it was dragged onto it's pre-existing parent stage
      // if (parentItem && parentItem.reference_type !== 'stage') return false;
      if (item.reference_type === 'stage' && parentItem && parentItem.reference_type === 'item')
        return false
      // if (item.parent === parent) return false;
      return true
    })

    gantt.value.attachEvent('onTaskCreated', (item) => {
      context.emit('task-created', item)
      return true
    })

    gantt.value.attachEvent('onBeforeLightbox', () => false)

    gantt.value.attachEvent('onTaskDeleted', (id) => {
      const children = gantt.value.getChildren(id)
      umountWithId(id)
      unmountWithIds(children)
      return true
    })

    // The event for detecting timeline position for lazy loading tasks
    if (context.lazyLoad) {
      gantt.value.attachEvent('onGanttScroll', () => {
        // get the gantt container
        const el = document.querySelector('.gantt_task')
        if (!el) return
        const width = el.offsetWidth
        // get the position of the timeline scroll state
        const { x } = gantt.value.getScrollState()
        const viewMinDate = gantt.value.dateFromPos(x)
        const viewMaxDate = gantt.value.dateFromPos(x + width)

        // add a buffer on trigger and data loading
        const maxTime = moment(context.maxDate).subtract(15, 'days').format('x')
        const minTime = moment(context.minDate).add(15, 'days').format('x')

        if (viewMaxDate && viewMaxDate.getTime() >= maxTime) updateTimeline('end')
        if (viewMinDate && viewMinDate.getTime() < minTime) updateTimeline('start')
      })
    }
  }

  const onClickAndDrag = (
    startPoint,
    endPoint,
    startDate,
    endDate,
    tasksBetweenDates,
    tasksInRow
  ) => {
    if (tasksInRow.length === 0) return
    const task = tasksInRow[0]
    context.emit('click-and-drag', {
      startDate,
      endDate,
      task
    })
  }

  const searchTasks = () => {
    const filteredTasks = new Set()

    const addParentTask = (task) => {
      const parentId = task.parent
      if (parentId) {
        const parentTask = context.data.data.find((i) => i.id === parentId)
        if (parentTask) filteredTasks.add(parentTask) // Add the direct parent task
      }
    }

    context.data.data.forEach((item) => {
      if (isSimilar(context.query, item.text)) {
        addParentTask(item)
        filteredTasks.add(item)
      }
    })

    return Array.from(filteredTasks)
  }

  const filterTasks = () => {
    const filteredTasks = new Set() // Using a Set to avoid duplicates

    const addParentTask = (task) => {
      const parentId = task.parent
      if (parentId) {
        const parentTask = context.data.data.find((i) => i.id === parentId)
        if (parentTask) filteredTasks.add(parentTask) // Add the direct parent task
      }
    }

    context.data.data.forEach((item) => {
      if (isMatch(item)) {
        addParentTask(item) // Ensure parent tasks are added
        filteredTasks.add(item) // Add the matching task
      }
    })

    return Array.from(filteredTasks) // Convert Set to Array to return
  }

  const hasFilters = () => {
    return Object.values(context.filters).some((value) => Array.isArray(value) && value.length > 0)
  }

  const removeAllTasks = () => {
    gantt.value.clearAll()
  }

  const renderFilteredTasks = (filteredTasks) => {
    removeAllTasks() // Clear current tasks
    gantt.value.parse({ data: filteredTasks, links: context.data.links }) // Add only filtered tasks
  }

  const resetGanttView = (originalTasks) => {
    gantt.value.parse(originalTasks) // Add all tasks back
  }

  const setData = (data) => (context.data = data)

  const setFilters = (filters) => (context.filters = filters)

  const setOrderLists = (orderLists) => (context.orderLists = orderLists)

  const setColumns = (columns) => (context.columns = columns)

  const setProps = (props) => (context.props = props)

  const setEmit = (emit) => (context.emit = emit)

  const getStoredGantt = () => getGantt(context.props.refId)

  const restoreOrCreateInstance = () => {
    const stored = getStoredGantt()
    // no stored version create new
    if (!stored) {
      setGanttInstance()
      storeGanttInstance()
      return
    }
    // restore previous version
    restoreGantt(stored)
  }

  const setGanttInstance = (params = {}) => {
    gantt.value = window.Gantt.getGanttInstance(params)
  }

  const restoreGantt = (stored) => {
    // setup composable context
    const { columns, data, orderLists, filters } = stored
    // data includes tasks, links and resources
    context.data = data
    context.columns = columns
    context.orderLists = orderLists
    context.filters = filters
    setGanttInstance()
  }

  const resetGanttContext = () => {
    // unmount all instances
    unmountVueInstances()
    // reset context
    context = reactive({
      ...initialState,
      props: {},
      data: {
        data: [],
        links: [],
        resources: []
      },
      columns: [],
      filters: {},
      orderLists: {},
      selected: [],
      vueInstances: {}
    })
    gantt.value = null
  }

  const resetGantt = () => {
    // store the gantt
    storeGanttInstance()
    destroyGantt()
  }

  const destroyGantt = () => {
    // destroys dataProcessor
    gantt.value?.destructor()
    // umount instances and reset context state
    resetGanttContext()
  }

  const storeGanttInstance = () => storeGantt(context.props.refId, context)

  const clearGanttInstance = (key) => clearGantt(key)

  const setVueInstance = (key, instance) => {
    context.vueInstances[key] = instance
  }

  const getVueInstance = (key) => context.vueInstances[key]

  const reuseVueInstance = (element, key, props = {}) => {
    const instance = getVueInstance(key)
    instance._props = reactive(props)
    element.innerHTML = ''
    element.appendChild(instance._container)
  }

  const mountVueInstance = (key, props = {}, element, createInstance) => {
    // Check if instance already exists and if so reuse it
    const existingInstance = getVueInstance(key)
    if (existingInstance) {
      reuseVueInstance(element, key, props)
      return
    }
    // create instance
    const instance = createInstance()
    // Mount the Vue instance to the provided DOM element
    instance.mount(element)
    // Store the instance in the WeakMap for future reference
    setVueInstance(key, instance)
  }

  const unmountVueInstances = () => {
    Object.keys(context.vueInstances).forEach((key) => unmountVueInstance(key))
  }

  const unmountVueInstance = (key) => {
    if (!context.vueInstances[key]) return
    context.vueInstances[key].unmount()
    delete context.vueInstances[key]
  }

  const unmountWithIds = (ids) => {
    ids.forEach((id) => {
      umountWithId(id)
    })
  }

  const umountWithId = (id) => {
    Object.keys(context.vueInstances).forEach((key) => {
      if (key.includes(id)) {
        unmountVueInstance(key)
      }
    })
  }

  // Return
  return {
    ...toRefs(context),
    gantt,
    filterCount,
    // initiate
    initTooltips,
    // handling events
    onRefreshItems,
    onHideGrid,
    onDownload,
    onIntervalChange,
    onSortBy,
    onGroupBy,
    onClickAndDrag,
    expandAll,
    reload,
    // clearing
    clearFilters,
    clearSelected,
    // updating
    updateSelected,
    // go to
    goToToday,
    zoomOut,
    zoomIn,
    // setters
    setData,
    setFilters,
    setOrderLists,
    setColumns,
    setTemplates,
    setEvents,
    setProps,
    setEmit,
    // other
    addMarker,
    addOrderLists,
    handleWorkdays,
    isSelectedItem,
    resetGantt,
    destroyGantt,
    restoreGantt,
    setGanttInstance,
    restoreOrCreateInstance,
    storeGanttInstance,
    clearGanttInstance,
    // methods for custom components handling
    setVueInstance,
    getVueInstance,
    reuseVueInstance,
    mountVueInstance,
    unmountVueInstances,
    unmountVueInstance,
    umountWithId,

    // searching
    searchTasks,
    removeAllTasks,
    renderFilteredTasks,
    resetGanttView,

    // filtering
    filterTasks,
    hasFilters
  }
}
