<template>
  <Container>
    <card-section>
      <template #title>Estimate settings</template>
      <card-list>
        <card-list-field>
          <span> Allow client to make modifications </span>
          <toggle v-model="allowModifications" />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <template #title>Item and assembly settings</template>
      <card-list>
        <card-list-field>
          <span
            >Override assembly-level presentation settings
            <small>
              When enabled, the settings in this presentation will override all localized itemized
              pricing settings that are set on an assembly-by-assembly basis.
            </small>

            <small>
              When this is disabled, assembly-level itemized pricing
              <strong>and</strong> presentation-level itemized pricing must <strong>both</strong> be
              enabled in order for it to show.
            </small>

            <small>
              This is useful for example, if you are creating a master document that you can
              download for your internal use where you want to show the quantities, costs and prices
              even in assemblies where itemized pricing is turned off for the client's view.
            </small>

            <small> Default is off. </small>
          </span>

          <toggle v-model="oPresentationSettings.forceTemplateSettings" />
        </card-list-field>
        <card-list-field>
          <span
            >Show quantities
            <SuggestionButton class="info xs"> Recommended </SuggestionButton>
          </span>

          <toggle v-model="oPresentationSettings.showQuantities" />
        </card-list-field>

        <card-list-field>
          <span> Show assembly dimensions </span>

          <toggle v-model="oPresentationSettings.showAssemblyDimensions" />
        </card-list-field>

        <card-list-field>
          <span
            >Show itemized pricing
            <SuggestionButton class="info xs"> Recommended </SuggestionButton>
            <small
              >If disabled, then only the total price of the proposal will be shown. Can be
              overridden on an assembly-by-assembly basis.</small
            >
          </span>

          <toggle v-model="oPresentationSettings.showItemizedPrices" />
        </card-list-field>
        <template v-if="oPresentationSettings.showItemizedPrices">
          <card-list-field>
            <span
              >Show item prices

              <SuggestionButton class="info xs"> Recommended </SuggestionButton>
            </span>

            <toggle v-model="oPresentationSettings.showCostItemPrices" />
          </card-list-field>
          <card-list-field>
            <span
              >Show assembly prices

              <SuggestionButton class="info xs"> Recommended </SuggestionButton>
            </span>

            <toggle v-model="oPresentationSettings.showAssemblyPrices" />
          </card-list-field>
        </template>
        <card-list-field>
          <span
            >Show costs
            <SuggestionButton class="danger xs"> Not recommended </SuggestionButton>
            <small class="text-danger"
              >If enabled, this will reveal your costs to your client!</small
            >
          </span>

          <toggle v-model="oPresentationSettings.showCosts" />
        </card-list-field>

        <card-list-field>
          <span
            >Show profit as management fee
            <small>
              If you enable this setting, each individual item and assembly will show the cost as if
              it were the price (as long as itemized pricing is turned on), and the combined profit
              for all your items will appear as its own line item above the subtotal in the quote
              presentation. By default it is named "Management fee", however you can change that to
              whatever you like once this option is enabled.
            </small>
            <small class="text-danger"
              >If enabled, this will reveal your costs to your client!</small
            >
          </span>

          <toggle
            :value="oPresentationSettings.showBundledProfit ? 1 : 0"
            @input="(v) => (oPresentationSettings.showBundledProfit = v)"
          />
          <field
            placeholder="Management fee"
            v-if="oPresentationSettings.showBundledProfit"
            :value="
              !oPresentationSettings.showBundledProfit ||
              typeof oPresentationSettings.showBundledProfit === 'number' ||
              /^0-9$/.test(oPresentationSettings.showBundledProfit)
                ? ''
                : oPresentationSettings.showBundledProfit
            "
            @input="
              (v) => {
                if (v === '') {
                  oPresentationSettings.showBundledProfit = 1
                } else {
                  oPresentationSettings.showBundledProfit = v
                }
              }
            "
          />
        </card-list-field>
      </card-list>
    </card-section>

    <CardSection>
      <template #title> Printable document view settings </template>

      <CardList>
        <card-list-field>
          <span
            >Show production notes
            <SuggestionButton class="danger xs"> Not recommended </SuggestionButton>
            <small class="text-danger"
              >If enabled, this will reveal your production notes to your client with this
              presentation!</small
            >
          </span>

          <toggle v-model="oPresentationSettings.showProductionNotes" />
        </card-list-field>

        <card-list-field>
          <span
            >Show terms and conditions
            <small
              >Show your terms and conditions in the print version of the proposal document.</small
            >
          </span>

          <toggle v-model="oPresentationSettings.showTermsAndConditions" />
        </card-list-field>

        <card-list-field>
          <span
            >Show approvals summary
            <small
              >If enabled, this will show a summary of change-orders/versions in the printable
              version of this presentation.</small
            >
          </span>

          <toggle v-model="oPresentationSettings.showApprovals" />
        </card-list-field>

        <card-list-field>
          <span
            >Show project totals
            <small
              >If enabled, this will show the project price, tax and discount totals at the bottom
              of the printable version of this presentation.</small
            >
          </span>

          <toggle v-model="oPresentationSettings.showProjectTotals" />
        </card-list-field>

        <card-list-field>
          <span>Show images in print view </span>

          <toggle v-model="oPresentationSettings.showDocumentPictures" />
        </card-list-field>

        <card-list-field>
          <span>Show descriptions in print view </span>

          <toggle v-model="oPresentationSettings.showDocumentDescriptions" />
        </card-list-field>

        <card-list-field>
          <span>Print header background </span>
          <color-picker
            class="field-component pr-0"
            v-if="oPresentationSettings.documentHeadingBg !== null"
            style="font-size: 3em; padding-right: 0 !important"
            title="Main background color"
            v-model="oPresentationSettings.documentHeadingBg"
          />
          <div v-else>
            <SuggestionButton
              class="info"
              @click="oPresentationSettings.documentHeadingBg = '#FFFFFF'"
            >
              Using default color
            </SuggestionButton>
          </div>
        </card-list-field>

        <card-list-field>
          <span>Assembly heading color </span>
          <color-picker
            class="field-component pr-0"
            v-if="oPresentationSettings.assemblyHeadingColor !== null"
            style="font-size: 3em; padding-right: 0 !important"
            title="Main background color"
            v-model="oPresentationSettings.assemblyHeadingColor"
          />
          <div v-else>
            <SuggestionButton
              class="info"
              @click="oPresentationSettings.assemblyHeadingColor = '#888888'"
            >
              Using default color
            </SuggestionButton>
          </div>
        </card-list-field>
      </CardList>
    </CardSection>

    <card-section v-if="showoPresentationSettings">
      <template #title>Presentation settings</template>
      <template #subtitle
        >These settings affect any proposal that uses this presentation template.</template
      >

      <card-list>
        <card-list-field>
          <span
            >Presentation-specific terms & conditions
            <small class="text-muted">
              Set terms and conditions here that apply ONLY to proposals that use this presentation.
              Your client will need to accept these terms in order to accept your
              quote/estimate/proposal.
              <br /><br />
              <to-route class="text-info" to="estimating_defaults"
                >You can also add terms and conditions that apply to all proposals in your
                "Estimating & presentation settings" page.</to-route
              >
              <br /><br />
              <span class="text-info"
                >Separate each term with a new line. Add sections/headings with square
                brackets:</span
              >

              <br /><br />
              <pre class="code">
                    [My heading]
                    My term one
                    My term two

                    [My other heading]
                    Other terms.
                  </pre
              >
            </small>
          </span>
          <field v-model="oPresentationSettings.termsAndConditions" type="textarea" />
        </card-list-field>

        <card-list-field>
          <span
            >Files to always attach
            <small class="text-muted">
              These files will always be included in the presentation view to your client with any
              project that uses this presentation. For example you could include a standard contract
              you use, or generic information that applies to every job, a catalog, brochure or
              referral form etc.
            </small>
          </span>
          <FileList v-model="oPresentationSettings.alwaysIncludedFileIds" :id-list="true" />
        </card-list-field>

        <card-list-field>
          <span
            >Require floor plans
            <small class="text-muted"> Prevent sending a quote without floor plans </small>
          </span>
          <toggle v-model="oPresentationSettings.requirePlans" />
        </card-list-field>

        <card-list-field>
          <span
            >Show large profile picture of sales person
            <small class="text-muted">
              If turned on, a larger format picture of the sales person (whoever is owner of the
              client), will appear on the welcome message when the client receives their proposal,
              and under the heading where it has 'Presenter' if that is turned on.
            </small>
          </span>
          <toggle v-model="oPresentationSettings.showLargeProfilePic" />
        </card-list-field>

        <card-list-field>
          <span
            >Presentation-specific booking message
            <small class="text-muted">
              This is the message your client will see after they have booked your project. If no
              message is provided, they will see a generic thank you message.
            </small>
          </span>
          <field v-model="oPresentationSettings.bookingMessage" type="textarea" />
        </card-list-field>

        <card-list-field>
          <span
            >Cover letter
            <small class="text-muted">
              This is a letter that, if set, will pop up at the moment the user opens the quote and
              it is designed to introduce your company to the client.
            </small>
          </span>
          <field v-model="oPresentationSettings.coverLetter" type="textarea" />
        </card-list-field>

        <card-list-field>
          <span
            >Default send message
            <small class="text-muted">
              This creates a default for the personalized message you can write when you send a
              proposal or change-order.
            </small>
            <small class="text-muted" style="white-space: pre-line">
              Use the following variables: • {quote_type} (proposal or change order) • {client_name}
              (full name) • {quote_name} • {quote_address} (street address)
            </small>
          </span>
          <field v-model="oPresentationSettings.defaultChangeOrderMessage" type="textarea" />
        </card-list-field>

        <card-list-field>
          <span>
            Credentials/attributes/awards/accomplishments
            <small class="text-info">
              List your credientials (tax number, licence numbers, insurance number, etc)
            </small>
          </span>

          <div>
            <MetaList v-model="oPresentationSettings.props" />
          </div>
        </card-list-field>

        <card-list-field>
          <span>
            Badges
            <small class="text-info"> List your badges or certifications (in picture form) </small>
          </span>

          <FileList v-model="oPresentationSettings.badges" :id-list="true" />
        </card-list-field>

        <card-list-field>
          <span>Show next/prev slide previews</span>
          <toggle v-model="oPresentationSettings.showSlidePreviews" />
        </card-list-field>

        <card-list-field>
          <span>Show heading</span>
          <toggle v-model="oPresentationSettings.screens[0].showHeading" />
        </card-list-field>

        <card-list-field>
          <span>Show presenter/sales manager</span>
          <toggle v-model="oPresentationSettings.screens[0].showPresenter" />
        </card-list-field>

        <card-list-field>
          <span>Show contact buttons</span>
          <toggle v-model="oPresentationSettings.screens[0].showContact" />
        </card-list-field>

        <card-list-field>
          <span>Show logo</span>
          <toggle v-model="oPresentationSettings.screens[0].showLogo" />
        </card-list-field>

        <card-list-field>
          <span>Show project details</span>
          <toggle v-model="oPresentationSettings.screens[0].showProjectDetails" />
        </card-list-field>

        <card-list-field>
          <span>Show item specific taxes</span>
          <toggle v-model="oPresentationSettings.showItemSpecificTax" />
        </card-list-field>

        <card-list-field>
          <span
            >Buying wizard
            <SuggestionButton class="info xs"> New feature </SuggestionButton>
          </span>
          <toggle v-model="oPresentationSettings.showConfigurator" />
        </card-list-field>
      </card-list>
    </card-section>
    <div
      class="fixed flex gap-3 justify-between bottom-0 left-0 right-0 w-full p-6 bg-white"
      v-if="templateFull"
    >
      <choose
        :key="`settings${presentationTemplateId}`"
        data-label="choose_presentation"
        :return-array="false"
        schema="quote:presentation_template_id"
        :filters="{ template_type_id: 101 }"
        :allowCreate="false"
        v-model="presentationTemplateId"
        :multiple="false"
        :allowDeselect="false"
      >
        <template #default="{ text }">
          <Btn
            size="lg"
            severity="tertiary"
            :stop="false"
            :prevent="false"
            :loading="loading || bodyLoading"
          >
            <template #icon>
              <font-awesome-icon icon="keynote" fixed-width />
            </template>
            {{ text || 'Default' }}
            <span class="caret-down"></span>
          </Btn>
        </template>
      </choose>
    </div>
  </Container>
</template>

<script>
import MetaList from '../../ui/MetaList.vue'
import SuggestionButton from '../../ui/SuggestionButton.vue'
import ColorPicker from '@/components/ui/ColorPicker.vue'
import PresentationMixin from '@/components/quote/presentation/PresentationMixin'
import ObjectDistinct from '@/components/mixins/ObjectDistinct'

export default {
  name: 'PresentationSettings',
  emits: ['input'],
  data() {
    return {}
  },

  watch: {
    oPresentationSettings: {
      deep: true,
      handler(v) {
        this.$emit('input', v)
      }
    }
  },
  computed: {
    allowModifications: {
      get() {
        return this.quote_is_upgrading_allowed ? 1 : 0
      },
      set(v) {
        const val = v ? 1 : 0
        const action = ['disabled', 'enabled']
        this.quote_is_upgrading_allowed = val
        this.saveFields(['quote_is_upgrading_allowed'])

        this.$store.dispatch('alert', {
          error: false,
          message: `Client edits ${action[val]}`
        })
      }
    }
  },
  components: {
    SuggestionButton,
    MetaList,
    ColorPicker
  },
  mixins: [ObjectDistinct('quote'), PresentationMixin]
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
