<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 h-full">
    <div v-if="!loading" class="bg-white h-full p-6">
      <TabView>
        <TabPanel header="Custom form editor">
          <CardSection>
            <CardList>
              <CardListField>
                <span>Lead form name</span>
                <Field
                  type="text"
                  schema="lead_rotation:lead_rotation_name"
                  v-model="lead_rotation_name"
                />
              </CardListField>
            </CardList>
          </CardSection>

          <EntitySelect v-if="selectBlank || form_id" :id="form_id" type="form">
            <template #default="{ refId }">
              <FormBuilder
                :refId="refId"
                v-if="refId"
                :formName="lead_rotation_name"
                :hidePreview="true"
                :hideName="true"
                @changes="onFormChanges"
              />
            </template>
          </EntitySelect>
          <CardSection v-if="asFormFields">
            <template #subLabel>
              <div class="flex flex-col">
                <div>Select fields to include in your form.</div>
              </div>
            </template>

            <card-list>
              <card-list-field
                v-for="(field, index) in Object.keys(defaultFormFields)"
                :key="index"
              >
                <div>
                  <span>{{ defaultFormFields[field].label }}</span>
                  <small>{{ defaultFormFields[field].desc }}</small>
                </div>

                <Toggle
                  :value="asFormFields.includes(field)"
                  @input="change(field)"
                  :onValue="true"
                  :offValue="false"
                  onText=""
                  offText=""
                />
              </card-list-field>
            </card-list>
            <div>
              <font-awesome-icon icon="warning" style="color: #facf58; font-size: 20px" />
              Fields will be ordered based on the order you toggle them.
            </div>
          </CardSection>
        </TabPanel>
        <TabPanel header="Settings">
          <card-section>
            <template #label>
              <span></span>
            </template>
            <template #subLabel>
              <span>Name your form, set your lead rotation and fill out the form details.</span>
            </template>
            <card-list>
              <card-list-field>
                <span
                  >Lead rotation name
                  <br />
                  <small>Name this anything you want, for your reference.</small>
                </span>
                <field schema="lead_rotation:lead_rotation_name" v-model="lead_rotation_name" />
              </card-list-field>

              <card-list-field>
                <div>
                  <div class="flex flex-row items-center">
                    <div>Lead rotation</div>
                    <help class="ml-3">
                      <template #title>Lead rotation users</template>
                      <p>
                        A lead rotation is a group of company users that will be assigned the leads
                        from this form in rotation. Lead rotations are specific to each form.
                      </p>
                    </help>
                  </div>
                  <small>
                    Choose the users that leads will go to. If none are assigned they will go to
                    you.
                  </small>
                </div>
                <choose
                  :allowDeselect="true"
                  :multiple="true"
                  v-model="lead_rotation_user_ids"
                  :filters="{ company_ids: `INSET${$store.state.session.company.company_id}` }"
                  schema="lead_rotation:lead_rotation_user_ids"
                />
              </card-list-field>

              <card-list-field>
                <span>
                  Who else should be notified?
                  <br />
                  <small>
                    Choose the users who should be notified about every single lead (optional).
                  </small>
                </span>

                <choose
                  :allowDeselect="true"
                  :multiple="true"
                  v-model="lead_rotation_notify_each_user_ids"
                  :filters="{
                    company_ids: `INSET${$store.state.session.company.company_id}`
                  }"
                  schema="lead_rotation:lead_rotation_notify_each_user_ids"
                />
              </card-list-field>
              <card-list-field>
                <span
                  >Success URL (Optional)
                  <br />
                  <small>After form submission the lead will be re-directed to this link. </small>
                </span>

                <field
                  schema="lead_rotation:lead_rotation_success_url"
                  v-model="lead_rotation_success_url"
                  placeholder="http://www.yourwebsite.com/thank-you"
                />
              </card-list-field>
              <card-list-field>
                <span>
                  Lead source
                  <small>Choose a built-in lead source for this form.</small>
                </span>

                <choose
                  :return-array="false"
                  :validation="{ required: false }"
                  :allowDeselect="true"
                  schema="client:lead_source_id"
                  v-model="lead_source_id"
                />
              </card-list-field>
            </card-list>
          </card-section>
        </TabPanel>
        <TabPanel header="Integrations">
          <Accordion :multiple="true" :active-index="[]">
            <AccordionTab header="Get form HTML">
              <card-section>
                <template #label><span></span></template>
                <template #subLabel>
                  <span
                    >Paste this html onto your website and style it however you like. Always ensure
                    the id="" and name="" attributes stay the same so that the values end up in the
                    right place. You can add as many custom fields as you like, and all that data
                    will be displayed on your lead.</span
                  >
                </template>
                <card>
                  <Btn
                    class="info round sm"
                    text="Copy html"
                    :icon="['fas', 'copy']"
                    v-tooltip="'Copy html'"
                    @click="() => $store.dispatch('copyToClipboard', generatedHtml)"
                  />
                  <div class="generated">{{ generatedHtml }}</div>
                </card>
              </card-section>
            </AccordionTab>

            <AccordionTab header="Get POST endpoint">
              <CardSection>
                <template #label><span></span></template>
                <template #subLabel>
                  <span
                    >Use this endpoint to connect Zapier, Integromat or your own API to Bolster.
                    This is a different URL because it does not do any bot/spam filtering. You
                    should do that before sending leads to this endpoint so that you don't end up
                    receiving spam.</span
                  >
                </template>
                <Card>
                  <Btn
                    class="info round sm"
                    text="Copy endpoint"
                    :icon="['fas', 'copy']"
                    v-tooltip="'Copy endpoint'"
                    @click="() => $store.dispatch('copyToClipboard', lead_rotation_post_url)"
                  />
                  <pre class="generated">{{ lead_rotation_post_url }}</pre>
                </Card>
              </CardSection>
            </AccordionTab>
          </Accordion>
        </TabPanel>
      </TabView>
    </div>
    <div class="p-6 h-full bg-cool-gray-100">
      <h3 class="!mb-2 text-center w-full !font-medium">Form preview</h3>
      <div class="px-12 pt-4 bg-white rounded h-full">
        <h1 class="text-center my-8">{{ lead_rotation_name }}</h1>
        <LeadFormContent
          :aoFields="aoFields"
          :aoFieldOptions="aoFieldOptions"
          :formFields="formFields"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import EntityComputedFields from '../composables/EntityFields/EntityComputedFields'
import Field from '../ui/Field.vue'
import Toggle from '../ui/Toggle.vue'
import LeadFormContent from '@/components/leads/LeadFormContent.vue'
import FormBuilder from '@/components/forms/FormBuilder.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

const storeName = 'LeadRotation'
const type = 'lead_rotation'
const defaultFormFields = ref({})

const { refId, selectBlank } = RouteEntityContext.useRouteEntityContext()

const computedFields = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})

const {
  loading,
  aoFields,
  aoFieldOptions,
  asFormFields,
  lead_rotation_url,
  lead_rotation_id,
  lead_rotation_name,
  lead_rotation_notify_each_user_ids,
  lead_rotation_success_url,
  lead_rotation_user_ids,
  lead_rotation_post_url,
  lead_source_id,
  form_id
} = computedFields

const $store = useStore()

const generatedHtml = computed(() => {
  if (!asFormFields.value) return
  let tempHtml = ''
  tempHtml = `${tempHtml}<form`
  tempHtml = `${tempHtml}\naction='${lead_rotation_url.value}' method='post' target='_blank'>`
  tempHtml = `${tempHtml}\n\t<input type='hidden' name='lead_rotation_id' id='lead_rotation_id' value="${lead_rotation_id.value}" />`

  tempHtml = lead_source_id.value
    ? `${tempHtml}\n\t<input type='hidden' name='lead_source_id' id='lead_source_id' value="${c.makeArray(lead_source_id.value)[0]}" />`
    : tempHtml

  asFormFields.value.forEach((field) => {
    if (!defaultFormFields.value[field]) return
    tempHtml = `${tempHtml}\n\t<label>`
    tempHtml = `${tempHtml}\n\t\t${defaultFormFields.value[field].label}`
    tempHtml = `${tempHtml}\n\t\t<input type='text' name="${field}" placeholder='${defaultFormFields.value[field].label}' />`
    tempHtml = `${tempHtml}\n\t</label>`
    tempHtml = `${tempHtml}\n\t`
  })
  // add custom fields
  if (aoFields.value && aoFields.value.length > 0) {
    aoFields.value.forEach((field) => {
      if (field.custom_field_type === 'textfield') {
        tempHtml = `${tempHtml}\n\t<label>`
        tempHtml = `${tempHtml}\n\t\t${field.custom_field_name}`
        tempHtml = `${tempHtml}\n\t\t<input type='text' name="${formatString(field.custom_field_name)}" placeholder='${field.custom_field_name}' />`
        tempHtml = `${tempHtml}\n\t</label>`
        tempHtml = `${tempHtml}\n\t`
      }
      if (field.custom_field_type === 'textarea') {
        tempHtml = `${tempHtml}\n\t<label>`
        tempHtml = `${tempHtml}\n\t\t${field.custom_field_name}`
        tempHtml = `${tempHtml}\n\t\t<textarea name="${formatString(field.custom_field_name)}" placeholder='${field.custom_field_name}'></textarea>`
        tempHtml = `${tempHtml}\n\t</label>`
        tempHtml = `${tempHtml}\n\t`
      }
      if (field.custom_field_type === 'radio') {
        tempHtml = `${tempHtml}\n\t<label>`
        tempHtml = `${tempHtml}\n\t\t${field.custom_field_name}`
        tempHtml = `${tempHtml}\n\t</label>`
        getOptions(field.custom_field_id || field.uid).forEach((option) => {
          tempHtml = `${tempHtml}\n\t<input type='radio' name="${formatString(field.custom_field_name)}" value="${option.custom_field_option_value}" /> ${option.custom_field_option_label}`
        })
        tempHtml = `${tempHtml}\n\t`
      }
      if (field.custom_field_type === 'checkbox') {
        tempHtml = `${tempHtml}\n\t<label>`
        tempHtml = `${tempHtml}\n\t\t${field.custom_field_name}`
        tempHtml = `${tempHtml}\n\t</label>`
        getOptions(field.custom_field_id || field.uid).forEach((option) => {
          tempHtml = `${tempHtml}\n\t<input type='checkbox' name="${formatString(field.custom_field_name)}" value="${option.custom_field_option_value}" /> ${option.custom_field_option_label}`
        })
        tempHtml = `${tempHtml}\n\t`
      }
      if (field.custom_field_type === 'select') {
        tempHtml = `${tempHtml}\n\t<label>`
        tempHtml = `${tempHtml}\n\t\t${field.custom_field_name}`
        tempHtml = `${tempHtml}\n\t\t<select name="${formatString(field.custom_field_name)}">`
        getOptions(field.custom_field_id || field.uid).forEach((option) => {
          tempHtml = `${tempHtml}\n\t\t\t<option value="${option.custom_field_option_value}">${option.custom_field_option_label}</option>`
        })
        tempHtml = `${tempHtml}\n\t\t</select>`
        tempHtml = `${tempHtml}\n\t</label>`
        tempHtml = `${tempHtml}\n\t`
      }
    })
  }
  tempHtml = `${tempHtml}\n<button type='submit'>Submit</button>`
  tempHtml = `${tempHtml}\n</form>`
  return tempHtml
})

const formFields = computed(() => {
  if (!asFormFields.value) return []
  return asFormFields.value.reduce((acc, key) => {
    acc.push({
      key,
      ...defaultFormFields.value[key]
    })
    return acc
  }, [])
})

const onFormChanges = (changes) => {
  Object.keys(changes).forEach((field) => {
    const value = changes[field]
    // Check if the value is an array
    if (Array.isArray(value)) {
      // Create a deep copy of the array to trigger reactivity
      computedFields[field].value = _.cloneDeep(value)
    } else {
      // Directly set the value if it's not an array
      computedFields[field].value = value
    }
  })
}

const getOptions = (customFieldId) => {
  return aoFieldOptions.value.reduce((acc, option) => {
    if (option && customFieldId in option) {
      acc = option[customFieldId]
      return acc
    }
    // return option && Object.values(option).custom_field_id === customFieldId
    return acc
  }, [])
}

const formatString = (input) => {
  if (!input) return ''
  return input
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters (keeping letters, numbers, and spaces)
    .replace(/\s+/g, '_') // Replace spaces with underscores
}

const change = (field) => {
  const temp = _.imm(asFormFields.value)
  const indexOf = asFormFields.value.indexOf(field)
  if (indexOf === -1) {
    temp.push(field)
  } else {
    temp.splice(indexOf, 1)
  }
  asFormFields.value = temp
}

const setDefaultFormFields = async () => {
  defaultFormFields.value = await $store.dispatch(`${storeName}/getFormFieldsData`)
}

onMounted(() => {
  setDefaultFormFields()
})
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.toggle-column {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.generated {
  font-family: monospace;
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: $cool-gray-700;
  color: $flame-white;
  padding: 3em;
  border-radius: 10px;
  max-width: 100%;
}
input {
  width: 100%;
}

.lead-form-name {
  background-color: $flame-white;
  padding: 0px;
  height: 2em;
  font-weight: 500;
}

.lead-form-card {
  .card-body {
    padding: 0px !important;
  }
}

.step-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
