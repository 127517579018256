<template>
  <div v-if="$store.getters.inSuper">
    <div v-if="activeTab.includes('settings')">
      <company-edit v-if="refId" :refId="refId" />
    </div>

    <div v-if="activeTab.includes('users')">
      <grid
        type="user"
        title="Users"
        :filters="{ company_ids: `INSET${company_id}`, company_id }"
        :isMain="isMain"
      />
    </div>

    <div v-if="activeTab.includes('activities')">
      <ActivityChannelAdmin
        v-if="company_id"
        channelType="COMPANY"
        :channelTypeId="company_id.toString()"
        :company="object"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import ActivityChannelAdmin from '@/components/Activities/ActivityChannelAdmin.vue'
import CompanyEdit from './CompanyEdit.vue'
import Grid from '@/components/ui/Grid/Grid.vue'
import RouteEntityContext from '../composables/RouteEntityContext'
import EntityComputedFields from '../composables/EntityFields/EntityComputedFields'

const $route = useRoute()
const $store = useStore()

const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const object = EntityComputedFields.useEntityComputedFields({
  refId: refId,
  store: storeName,
  type: type
})

const { company_id } = object

const activeTab = computed(() => {
  const rtq = $route.query && $route.query.tab
  if (rtq) return c.makeArray(rtq)
  return ['settings']
})
</script>

<style lang="scss" rel="stylesheet/scss">
.mobile-sidepanel-header {
  width: auto;
  height: 3em;
  background-color: $cool-gray-200;
}
</style>
