<template>
  <spin-container :loading="bodyLoading">
    <btn-bar
      class="float-right"
      v-if="labor_type_id && !isAutoCost"
      :collapse="true"
      :actions="actions"
      :selected="[this]"
    />
    <card-section>
      <template v-if="!isAutoCost" #label> Labor Rates </template>
      <template v-else #label>
        <SvgIcon svg="autocost" class="auto-cost-icon" />
        AutoCost Labor Rate
      </template>
      <card-list>
        <card-list-field>
          <span>Labor rate name</span>

          <field
            schema="labor_type:labor_type_name"
            v-model="labor_type_name"
            placeholder="Labor type name"
            :disabled="isAutoCost"
          />
        </card-list-field>

        <card-list-field>
          <span
            >Hourly labor rate
            <small class="text-info">
              Should include all burden costs like:
              <ul>
                <li>Payroll pension plan, benefits, employment insurance.</li>
                <li>Workers compensation or other safety insurance.</li>
                <li>
                  Gross hourly pay, including income and other taxes, including what is paid by the
                  company.
                </li>
              </ul>
            </small>
          </span>

          <field
            type="calculator"
            schema="labor_type:labor_type_rate_net"
            v-model="labor_type_rate_net"
            placeholder="Rate"
            :disabled="isAutoCost"
          />
        </card-list-field>
      </card-list>

      <CardList>
        <card-list-field v-if="!isAutoCost">
          <span
            >Crew types
            <small class="text-info">
              Which type of workers should this rate apply to? You can choose multiple.
            </small>
          </span>

          <Choose schema="labor_type:trade_type_ids" v-model="trade_type_ids" :multiple="true" />
        </card-list-field>
      </CardList>
    </card-section>

    <CardSection v-if="labor_type_id && !isAutoCost" class="mb-0">
      <template #title>
        <font-awesome-icon icon="arrows-rotate" class="mr-1" />
        Where this labor type is used
      </template>

      <List
        type="cost_type"
        :showOptions="false"
        :permanent-filters="{
          type: 'cost_type',
          labor_type_id: labor_type_id
        }"
      >
        <template #header>Items</template>
      </List>
    </CardSection>
  </spin-container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import AutoCost from '../../../imports/api/AutoCost.js'

export default {
  mixins: [ObjectManipulator('labor_type', true, 'LaborType'), BodyMixin /* , AutoSave */],
  data() {
    return {}
  },
  watch: {},
  computed: {
    isAutoCost() {
      return this.labor_type_id && AutoCost.isAutoCostLaborTypeId(this.labor_type_id)
    }
  },
  methods: {},
  components: {
    /* ChangeAudit, */
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.auto-cost-icon {
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 0.25em;
}
</style>
