<template>
  <div
    class="card-list-item list-group-item card-list-item-field bg-transparent py-4 px-6 border-b hover:bg-surface-200/20 border-dotted border-surface-300 last:border-b-0"
    @dblclick.prevent="dblclickHandler"
    :class="{
      'show-invalid': hasBlurred && !validationCode && validationCode !== null,
      invalid: !validationCode && validationCode !== null,
      focused: focusIn,
      muted: muted,
      'floating-label': floatingLabel
    }"
  >
    <div class="card-list-field-above" v-if="$slots.above">
      <slot name="above"></slot>
    </div>
    <div
      :data-label="label"
      :id="uid"
      class="card-list-field"
      :class="{
        'floating-label': floatingLabel,
        'mobile-stacked-field': mobileStack
      }"
    >
      <slot></slot>
    </div>
    <fade>
      <div
        v-if="(required || requiredFormat) && validationCode === true && hasFocused"
        class="card-list-field-validation-success"
      >
        <font-awesome-icon icon="check" />
      </div>
    </fade>
    <fade>
      <div
        v-if="required === true && !hasFocused && valueEmpty"
        class="card-list-field-validation-required"
      >
        <font-awesome-icon icon="asterisk" />
      </div>
    </fade>
    <div
      v-if="hasBlurred && validationCode === false && validationMessage && validationMessage !== ''"
      class="card-list-field-validation"
    >
      {{ validationMessage }}
    </div>
    <div class="card-list-field-below" v-if="$slots.below">
      <slot name="below"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardListField',
  props: {
    muted: Boolean,
    mobileStack: {
      type: Boolean,
      default: false
    },
    floatingLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uid: _.uniqueId(),
      validationMessage: '',
      validationCode: null, // null - no validation, false - invalid, true - valid
      required: false,
      requiredFormat: false,
      focusIn: false,
      hasFocused: false,
      hasBlurred: false,
      valueEmpty: true,
      label: ''
    }
  },
  methods: {
    dblclickHandler() {
      const field = this.getField()
      if (!field) return
      if (field.type === 'calculator') {
        field.openCalculator()
      } else {
        field.focus()
        field.selectAll()
      }
    },

    listenToChild(component) {
      // Set id for a hook for entering
      const $first = $(`#${this.uid}>:first-child`)
      const fieldId =
        c.idFromText($first.clone().children().remove().end().text()) ||
        ($first.find('.help--text-button').length &&
          c.idFromText($first.find('.help--text-button').text())) ||
        c.idFromText($first[0].innerText)

      this.label = fieldId

      if (!component) return

      $(component.$el).attr('id', fieldId)

      // get required
      this.required = component.required || false
      this.requiredFormat = component.requiredFormat || component.format
      this.validationCode = component.valid || null
      this.validationMessage = component.required ? 'This is a required field.' : ''
      this.valueEmpty = !component.notEmpty
      const componentIs = component.componentIs

      component.$on('validation', (payload) => {
        if (!Object.keys(payload).length) return
        this.valueEmpty = payload.empty
        this.required = payload.required
        this.requiredFormat = payload.requiredFormat || payload.format
        this.validationCode = payload.valid
        this.validationMessage = payload.message
      })

      component.$on('focus', () => {
        this.hasFocused = true
        this.focusIn = true
      })

      component.$on('blur', () => {
        this.blur()
      })

      this.valueEmpty = c.empty(component.value)
      component.$on('change', (v) => {
        this.valueEmpty = c.empty(v)
      })

      if (componentIs !== 'textarea') {
        component.$on('up', () => {
          this.prev()
        })
        component.$on('down', () => {
          this.next()
        })
        component.$on('enter', () => {
          this.next()
        })
        component.$on('tab', () => {
          // this.next();
        })
      }
    },

    next() {
      const nextField = this.getNextCardListField()
      if (nextField) nextField.focus()
    },

    prev() {
      const prevField = this.getPrevCardListField()
      if (prevField) prevField.focus()
    },

    getField() {
      const slots = this.$slots.default()

      let component = null
      slots.forEach((slot) => {
        const ci = slot.componentInstance || null

        if (ci && ('fieldSchema' in ci || typeof ci.focus === 'function')) {
          component = ci
        }
      })

      return component
    },

    getPrevCardListField() {
      let prev = $(this.$el).prev('.card-list-item-field')

      if (!prev.length) {
        prev = $(this.$el).parent().parent().prev('.card-list.card').find('.card-list-item-field')
      }

      if (prev[0]) {
        prev = prev[0].__vue__
        return prev
      }

      return null
    },

    getNextCardListField() {
      let next = $(this.$el).next('.card-list-item-field')

      if (!next.length) {
        next = $(this.$el).parent().parent().next('.card-list.card').find('.card-list-item-field')
      }

      if (next[0]) {
        next = next[0].__vue__
        return next
      }

      return null
    },

    blur() {
      this.focusIn = false
      this.hasBlurred = true
    },

    getGenericField() {
      const inputa = $(`#${this.uid}>:last-child`).find(':input')
      const inputb = $(`#${this.uid}`).find('input,textarea,[contenteditable],:input').last()
      let input = null

      if (inputa.length) {
        input = inputa
      } else if (inputb.length) {
        input = inputb
      }

      return input
    },

    async selectField(field) {
      let el = field
      if (field && 'fieldSchema' in field) {
        field.selectAll()
        el = field.$el
      } else if (field && field.selectAll) {
        field.selectAll()
      }

      const $el = $(el)
      if ($el && $el.length) {
        $el.addClass('input-highlighted')

        await c.throttle(() => {}, { delay: 750 })

        $el.removeClass('input-highlighted')
      }
    },

    focusField(field) {
      if (field && 'fieldSchema' in field) {
        field.focus()
      } else if (field && field.focus) {
        field.focus()
      }
    },

    blurField(field) {
      if (field && 'fieldSchema' in field) {
        field.emitImmediately()
        field.blur()
      } else if (field && field.blur) {
        field.blur()
      }
    },

    fieldIsFocused(field) {
      const rawField = field && field.$refs && field.$refs.input ? field.$refs.input : field[0]

      if (rawField) {
        return rawField === document.activeElement
      }

      return null
    },

    dblclick() {
      const field = this.getField() || this.getGenericField()

      if (field) {
        setTimeout(() => {
          this.selectField(field)
        }, 300)
      }

      return true
    },

    focus() {
      const field = this.getField() || this.getGenericField()

      const focused = field && this.fieldIsFocused(field)

      // this.blurField(field);
      if (field && !focused) {
        setTimeout(() => {
          this.focusField(field)
          this.selectField(field)
        }, 100)
      }

      this.$nextTick(() => {
        const lastSlot = this.$slots.default()[this.$slots.default().length - 1].componentInstance
        if (
          lastSlot &&
          lastSlot.$options &&
          lastSlot.$options._componentTag &&
          lastSlot.$options._componentTag === 'choose'
        ) {
          setTimeout(() => {
            lastSlot.open()
          }, 100)
        }
      })

      return true
    }
  },
  mounted() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
%labelSubText {
  font-size: 0.8rem !important;
  font-weight: normal !important;
  color: $surface-500 !important;
  line-height: 1.25 !important;
  display: inline-block !important;
  word-wrap: break-word;

  ul,
  ol {
    //padding-left: 1.5em;
  }
}

%labelContainer {
  //padding-right: 0 !important;

  flex: 0 auto !important;
  min-width: 30%;
  max-width: 60%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-spaces;
  word-wrap: break-spaces;
  white-space: normal;
  text-align: left;
  justify-self: flex-start;
  margin-right: auto;

  /*row-gap: 0.5rem;*/
  > :not(.help--container) {
    margin-top: 0.5rem !important;
  }
}

%suffix {
  margin-left: 0.25em;
  flex: 0 0 auto !important;
  white-space: nowrap;
  align-self: stretch;
}

.card-list-item-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 4.5em;

  .card-list-field-below {
    width: 100%;
    text-align: center;
  }
  .card-list-field-above {
    width: 100%;
    text-align: center;
  }

  &.show-invalid {
    background: $deep-red-200 !important;
  }
  .card-list-field-validation {
    font-weight: 500;
    font-size: 0.8em;
    color: $deep-red-800;
    text-align: right;
  }
  .card-list-field-validation-required {
    color: rgba($deep-red-800, 0.7);
    font-size: 0.7em;
    position: absolute;
    top: 0.1em;
    right: 0.25em;
  }
  .card-list-field-validation-success {
    color: $blue-print-700;
    font-size: 0.8em;
    position: absolute;
    top: 0.1em;
    right: 0.25em;
  }
  .card-list-field {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 3em;
    cursor: pointer;

    > * {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 1 auto;
      flex-direction: row;

      // Label
      &:first-child {
        @extend %labelContainer;

        > :not(:first-child),
        > span.text-muted,
        > small,
        > small.text-muted,
        > .text-info {
          @extend %labelSubText;
        }
        .suggestion-button {
          font-weight: 500 !important;
        }
      }
      &:last-child:not(.input-container):not(.flush) {
        @extend %suffix;
      }
    }

    > :last-child:not(.checkbox--container) {
      align-self: stretch;
      // height: auto !important;
      font-weight: 500;
    }
    > :not(:last-child) {
      &:not(:first-child):not(.flush) {
        flex: 0 0 auto;
      }
    }
  }

  &.full-label {
    .card-list-field {
      > :first-child {
        white-space: nowrap !important;
        word-wrap: break-word;
        flex: 2 0 auto !important;
        > :not(:first-child),
        > span.text-muted,
        > small,
        > small.text-muted,
        > .text-info {
          word-wrap: break-word;
          white-space: normal !important;
        }
      }

      > .input-container {
        flex: 1 3 30% !important;
      }
    }
  }

  .floating-label {
    &.card-list-field {
      display: flex;
      flex-direction: column;
    }

    &.card-list-field > *:first-child {
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 125%;
      color: $flame-white;
    }

    &.card-list-field > .input-container {
      flex-basis: unset;
      height: 52px !important;
      background: $flame-white !important;

      &:focus {
        outline-color: $cool-gray-500;
      }

      &.no-format {
        flex: 1 1 52px !important;
      }
    }
  }
}

.card-list-item-field .mobile-stacked-field {
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    > span,
    > div {
      width: 100% !important;
      max-width: 100% !important;
      justify-content: center !important;
      flex-basis: 4em !important;
    }
  }
}
</style>
