<script setup>
import { watch, ref, computed, onMounted, nextTick } from 'vue'

const props = defineProps({
  value: {
    default: ''
  },
  placeholder: {
    default: 'Enter a value'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  tooltip: {
    default: false
  },
  classes: {
    type: String
  }
})

const emit = defineEmits([
  'input',
  'blur',
  'focusout',
  'focusin',
  'keydown',
  'keyup',
  'mouseup',
  'dblclick',
  'click'
])

const propValue = computed(() => props.value)
const rawValue = ref(props.value)
const focused = ref(false)
const editableDiv = ref(null)

const isNumeric = false

const sameAsOriginal = (value, original = props.value) => {
  if (!isNumeric.value && `${value}` === `${original}`) return true

  if (isNumeric.value && c.eq(value, original)) return true

  return false
}

const doEmit = () => {
  if (sameAsOriginal(rawValue.value)) return

  emit('input', rawValue.value)
}

const handleBlur = () => {
  rawValue.value = editableDiv.value.innerText
  doEmit()
  focused.value = false
  emit('blur', rawValue.value)
  emit('focusout')
}

const handleInput = (input) => {
  rawValue.value = input // optimistic
}

const handleClick = async () => {
  if (focused.value || props.disabled) return
  focused.value = true
  await nextTick()
  editableDiv.value.innerText = rawValue.value
  editableDiv.value.focus()
}

watch(propValue, (newVal) => {
  rawValue.value = newVal
})
watch(rawValue, (newVal) => {
  if (!focused.value && editableDiv.value) {
    editableDiv.value.innerText = newVal
  }
})

const box = ref({})
const refPlaceholder = ref(null)
onMounted(() => {
  box.value = refPlaceholder.value?.getBoundingClientRect() ?? {}
})

const boxStyle = computed(() => {
  const editable = editableDiv.value?.getBoundingClientRect()
  if (!editable) return {}
  return {
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    minHeight: 'calc(100% + 4px)'
  }
})
</script>

<template>
  <div
    @click.capture="handleClick"
    :class="[
      'string-field-reset',
      'relative',
      'outline-none',
      'transition-all',
      'border-2',
      'rounded-sm',
      'leading-tight',
      'line-clamp-4',
      '!outline-none',
      'min-w-12',
      'min-h-6',
      {
        'text-surface-400 border-surface-300 border-dashed': !rawValue && !focused,
        'border-transparent': rawValue && !focused,
        '!border-transparent': focused,
        '!overflow-visible z-[10000]': focused,
        '!overflow-hidden': !focused,
        'hover:border-blue-print hover:border-solid cursor-text': !disabled && !focused
      },
      classes
    ]"
  >
    <div
      ref="refPlaceholder"
      v-tooltip="tooltip"
      :contenteditable="false"
      :class="[
        'py-0.25 px-0.5',
        'border-2 border-transparent',
        '!outline-none',
        {
          'opacity-0': focused,
          '!text-surface-400': !rawValue
        },
        classes
      ]"
      v-text="rawValue || placeholder"
    ></div>
    <div
      v-if="focused"
      ref="editableDiv"
      contenteditable="true"
      :style="boxStyle"
      :class="[
        'rounded-sm',
        'border-2 border-blue-print',
        'py-0.5 px-2',
        '!outline-none',
        'z-[10000]',
        'bg-white',
        'min-w-full h-fit',
        'box-border',
        'whitespace-break-spaces'
      ]"
      @focusin="(e) => $emit('focusin', e)"
      @focusout="handleBlur"
      @input="handleInput"
      @keydown="(e) => $emit('keydown', e)"
      @mouseup="(e) => $emit('mouseup', e)"
      @keyup="(e) => $emit('keyup', e)"
      @dblclick.stop.prevent="(e) => $emit('dblclick', e)"
      @click.stop.prevent="(e) => $emit('click', e)"
      autocomplete="on"
      autocorrect="on"
      autocapitalize="on"
      spellcheck="false"
      tabindex
    ></div>
  </div>
</template>

<style lang="scss" rel="stylesheet/scss">
.string-field-reset {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  outline: none;
  margin-right: -0.25rem;
  margin-left: -0.7rem;
  padding-right: 0.1rem;
  padding-left: 0.1rem;
  font-family:
    Cera Pro,
    sans-serif !important;
  text-align: left;
}
</style>
