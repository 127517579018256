export const getItemType = (item) => item?.oMeta?.itemType ?? 'item'

export const isItemType = (item, type) => getItemType(item) === type

export const getType = (item) => item?.type ?? 'cost_item'

export const isNotItem = (item) => getItemType(item) !== 'item' || getType(item) !== 'cost_item'

export default {
  getType,
  isItemType,
  getItemType,
  isNotItem
}
