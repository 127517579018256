// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

import _ from '../Helpers'
import UserError from '../UserError'
import { baseApiUrl } from '../moduleUtil.js'
import { Capacitor } from '@capacitor/core'
import { Filesystem } from '@capacitor/filesystem'

// fixme- This link function below should be accessed from VueHelpers --> ask Mike
const link = (path, query = {}, setToken = true, scope = null) => {
  const token = setToken ? _.getStorage('token') : ''
  if (!token && setToken) {
    window.dispatch('authFailed')
  }
  const url = `${baseApiUrl().replace(/\/$/, '')}${token ? `/r/${token}` : ''}/${path.replace(/^\//, '')}${scope ? `/scope/${scope}` : ''}`
  const queryString = _.serialize({
    // ...(setToken ? { t: _.getStorage('token') } : {}),
    ...query
  })
  return `${url}${queryString ? `?${queryString}` : ''}`
}

export default {
  type: 'file',

  possibleStatuses: ['p', 'v', 'h'],

  skipAudit: true,

  fields: {
    aoSigning: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      deep: true
    },
    aoAudit: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      deep: true
    },
    last_activity_time: {
      type: 'int',
      filter: false,
      format: 'datetime',
      mapTo: false
    },
    file_creator: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    file_owner: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    creator_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user'
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      normalize: false
    },
    owner_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user'
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company'
    },
    oOwner: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      normalize: false
    },
    file_id: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    file_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    file_size: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    file_status: {
      type: 'string',
      filter: false,
      format: 'status',
      mapTo: false
    },
    file_location: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    file_time_attached: {
      type: 'int',
      filter: false,
      format: 'datetime',
      mapTo: false
    },
    file_time_modified: {
      type: 'int',
      filter: false,
      format: 'datetime',
      mapTo: false
    },
    file_company_alias: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    owner_cache: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    creator_cache: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_virtual: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_message_attachment: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_completely_signed: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_partially_signed: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_virtual_object_type: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_virtual_object_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    /**
     * Either 'excel', 'pdf' or 'word'
     */
    file_virtual_object_format: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    tag_cache: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_highlight: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_thumb_location: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_thumb_is_generic: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_folder: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    parent_file_id: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_user_folder: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_signable: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_sign_request_template: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_sign_confirmation_template: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_template: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    file_is_alias: {
      type: 'int',
      default: 0,
      filter: false,
      format: false
    },
    location_ids: {
      type: 'array',
      mapTo: 'location',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true
    }
  },

  actions: {
    duplicate: {
      visible: () => false
    },
    create: {
      icon: 'cloud-arrow-up',
      class: 'info',
      text: 'Upload',
      action(store, payload) {
        const { grid = null } = payload
        return store.dispatch('File/upload', {
          tags: grid ? { ...grid.permanentFilters, ...grid.filters, ...grid.filtersLocal } : {},
          ...payload
        })
      },
      modal: false,
      selectionRequired: false,
      collapse: false,
      editing: false
    },
    edit: {
      icon: 'cloud-arrow-up',
      text: 'Edit/View',
      action: 'File/edit',
      selectionRequired: true,
      collapse: true,
      visible: (selected) => !selected[0].file_is_folder,
      editing: false
    },
    delete: {
      icon: 'trash',
      class: 'default',
      text: 'Delete',
      clearCache: true,
      action(store, payload) {
        const { objects = [] } = payload
        return store.dispatch('File/delete', {
          ...payload
        })
      },
      confirm: `Are you sure you want to delete this file?`,
      visible: (selected) => !selected[0].file_company_alias,
      selectionRequired: true,
      collapse: true,
      multiple: true
    },
    sign: {
      icon: 'pencil',
      text: 'Send for signature..',
      selectionRequired: true,
      action(store, payload) {
        const { grid = null } = payload
        return store.dispatch('modal/open', {
          modal: {
            name: 'SignersChooser',
            saved: () => {
              if (grid) grid.reload()
            }
          },
          ...payload
        })
      },
      collapse: true,
      visible: (selected) => selected[0].file_is_template,
      editing: false,
      multiple: true
    },
    go: {
      icon: 'external-link-square',
      text: 'Open Folder',
      modal: 'Files',
      selectionRequired: true,
      collapse: true,
      visible: (selected) => selected[0].file_is_folder
    },
    createFolder: {
      icon: 'folder-open',
      class: 'info',
      text: 'New Folder',
      action(store, payload) {
        const { grid = null } = payload
        return store.dispatch('File/createFolder', {
          tags: grid ? { ...grid.permanentFilters, ...grid.filters, ...grid.filtersLocal } : {},
          ...payload
        })
      },
      selectionRequired: false,
      collapse: false,
      editing: false,
      reload: false
    },
    share: {
      icon: 'share',
      text: 'Share',
      selectionRequired: true,
      collapse: true,
      multiple: true,
      // All selected, must be files and not folders
      visible: (selected) => selected.filter((f) => !f.file_is_folder).length === selected.length,
      options: [
        {
          icon: 'paper-plane-top',
          text: 'Email',
          action: 'File/shareEmail',
          selectionRequired: true,
          collapse: true,
          multiple: true
        },
        {
          icon: ['fas', 'comment'],
          text: 'Message',
          action: 'File/shareMessage',
          selectionRequired: true,
          collapse: true,
          multiple: true
        },
        {
          icon: 'external-link',
          text: 'Copy shareable link',
          action: 'File/shareLink',
          selectionRequired: true,
          collapse: true,
          multiple: true
        }
      ]
    },
    changeStatus: {
      visible: () => false
    },
    downloadMulti: {
      icon: 'cloud-arrow-down',
      text: 'Download',
      action(store, payload) {
        return store.dispatch('File/download', payload)
      },
      selectionRequired: true,
      collapse: true,
      multiple: true,
      // All selected, must be files and not folders
      visible: (selected) => selected.filter((f) => !f.file_is_folder).length === selected.length
    }
  },

  generateVueActions() {
    return {
      async getFileType({ dispatch }, { id }) {
        const { message } = await dispatch('ajax', {
          path: `file/getFileType/${id}`
        })
        return message
      },

      async sendUpload({ dispatch, rootState }, payload = {}) {
        const {
          data,
          file = {},
          tags = {},
          progress = () => {},
          alert = true,
          go = false,
          signer = null,
          scope = null
        } = payload

        const { link } = await dispatch('link', {
          path: '/file/upload',
          setToken: false,
          host: baseApiUrl(),
          scope: scope || rootState.session.scope
        })

        const fd = new FormData()

        Object.keys(tags)
          .filter((k) => tags[k])
          .forEach((k) => fd.append(`data[${k}]`, tags[k]))

        Object.keys(file)
          .filter((k) => file[k])
          .forEach((k) =>
            fd.append(
              `data[${k}]`,
              Array.isArray(file[k]) || typeof file[k] === 'object'
                ? JSON.stringify(file[k])
                : file[k]
            )
          )

        fd.append('file', data)

        const requestedScope = scope || rootState.session.scope
        fd.append('requestedScope', JSON.stringify(requestedScope))

        if (file.file_name) {
          fd.append('name', file.file_name || null)
          fd.append('data[file_name]', file.file_name || null)
        }

        if (file.file_type) {
          fd.append('type', file.file_type || null)
          fd.append('data[file_type]', file.file_type || null)
        }

        if (file.file_size) {
          fd.append('size', file.file_size || null)
          fd.append('data[file_size]', file.file_size || null)
        }

        if (JSON.stringify(signer)) {
          fd.append('data[signer]', JSON.stringify(signer))
        }

        // Now we add parent_file_id
        if (file.parent_file_id || tags.parent_file_id) {
          fd.append('data[parent_file_id]', file.parent_file_id || tags.parent_file_id)
        }

        const sendHeaders = new Headers({
          Authorization: `Bearer ${rootState.session.token}`
        })
        const fetchResult = await fetch(link, {
          method: 'POST',
          headers: sendHeaders,
          body: fd
        })
        const response = await fetchResult.json()

        if (!fetchResult.ok) {
          dispatch(
            'alert',
            {
              text:
                response.payload.userMessage ||
                'There was an error uploading your file. Try again.',
              error: true
            },
            { root: true }
          )

          throw new UserError({
            userMessage: response.payload.userMessage,
            message: response.payload.message
          })
        }

        if (go && !response.error) {
          dispatch(
            'go',
            {
              type: file,
              id: response.object.file_id
            },
            { root: true }
          )
        }

        if (alert) {
          dispatch(
            'alert',
            {
              text: response.userMessage || 'Successfully uploaded your file..'
            },
            { root: true }
          )
        }

        return {
          ...response,
          object: response.payload
        }
      },
      upload({ dispatch }, payload = {}) {
        const { grid = false } = payload

        if (payload.file && payload.data) return dispatch('sendUpload', payload)

        if (grid) {
          grid.$emit('upload')
        } else {
          console.warn(
            'the File/upload action requires a grid of File upload component given in payload.'
          )
        }

        return Promise.resolve()
      },

      /**
       * Download a file
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async download({ dispatch, rootState }, payload = {}) {
        const {
          scope = null // defaults to current scope
        } = payload

        const { object: file } = await dispatch('resolveObject', payload)

        const { link } = await dispatch('link', { path: `file/download/${file.file_id}`, scope })

        if (Capacitor.isNativePlatform()) {
          await Filesystem.downloadFile({
            url: link,
            directory: 'DATA',
            path: file.file_name
          })

          return payload
        }

        return dispatch(
          'openLink',
          {
            url: link,
            newWindow: false,
            downloadName: file.file_name
          },
          { root: true }
        )
      },

      async createFolder(
        { dispatch },
        { selected = [], button = null, alert = false, grid = false, tags = {}, scope = null }
      ) {
        const parentFolder = tags.parent_file_id ? tags.parent_file_id : 'NULL'

        const dataObject = {
          ...tags,
          parent_file_id: parentFolder,
          file_name: 'New Folder',
          ...(selected.length ? selected[0] : {}),
          file_is_folder: 1,
          file_type: 'folder'
        }

        if (scope && scope.user) {
          dataObject.user_id = scope.user
        }
        if (scope && scope.company) {
          dataObject.company_id = scope.company
        }

        const { object } = await dispatch('ajax', {
          path: 'file/create',
          data: {
            tags,
            object: dataObject
          },
          scope,
          button
        })

        if (grid) {
          grid.prepend(object)

          const newFileInSet = grid.set.filter((f) => String(f.file_id) === String(object.file_id))

          if (newFileInSet.length) {
            grid.selectSingleRow(newFileInSet[0])
          }
        }

        dispatch('clearCache')

        return {
          object
        }
      },
      shareMessage({ dispatch }, payload) {
        // const { selected = [], button = null, alert = true } = payload;
        return new Promise((resolve) => {
          dispatch('alert', { text: 'Shareable links, coming soon!', error: true }, { root: true })
          resolve(payload)
        })
      },
      shareEmail({ dispatch }, payload) {
        // const { selected = [], button = null, alert = true } = payload;
        return new Promise((resolve) => {
          dispatch(
            'alert',
            { text: 'Share files by email, coming soon!', error: true },
            { root: true }
          )
          resolve(payload)
        })
      },
      shareLink({ dispatch }, payload) {
        // const { selected = [], button = null, alert = true } = payload;
        return new Promise((resolve) => {
          dispatch('alert', { text: 'Shareable links, coming soon!', error: true }, { root: true })
          resolve(payload)
        })
      }
    }
  }
}
