export default {
  fieldName: 'stage_id',
  getSuggestion: async ({ $store, object, parent }) => {
    const { sorted, rank } = await $store.dispatch('Stage/getSuggestedStage', {
      itemName: object.cost_type_name,
      itemDesc: object.cost_type_desc,
      parentName: parent.quote_name || parent.assembly_name
    })

    const id = sorted[0]?.stage_id
    return {
      best: id,
      all: sorted.map((st, index) => ({
        object: st,
        rank: index,
        score: rank[String(st.stage_id)].total
      }))
    }
  }
}
