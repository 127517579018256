<template>
  <ActivityItemLayout :activity="activity" @onReply="onReply">
    <div
      class="activity-channel-item--message bg-surface-100 rounded-3xl px-4 py-2 flex-1 h-max overflow-x-clip"
    >
      <div class="activity-channel-item--message--header w-full flex justify-between items-center">
        <div class="flex items-center mr-2">
          <font-awesome-icon
            :icon="['fas', 'code-compare']"
            class="text-sm mr-2"
          ></font-awesome-icon>
          <p class="max-w-full text-xs block break-words mr-2" v-html="activityMessage"></p>
          <p
            v-if="showChanges"
            class="text-secondary text-xs cursor-pointer hover:underline"
            @click="toggleShowDetails"
          >
            {{ showDetails ? 'Hide details' : 'Show details' }}
          </p>
        </div>
        <small
          class="activity-channel-item--message--header--time text-xs font-normal text-surface-300 h-max"
          >{{ formattedTimeCreatedHour }}</small
        >
      </div>
      <TransitionExpand>
        <change-audit
          v-if="showDetails"
          class="mt-2 text-xs"
          :changes="denormalizedChanges"
          :type="targetType"
        />
      </TransitionExpand>
    </div>
  </ActivityItemLayout>
</template>

<script setup>
import { ref } from 'vue'
import useActivityItem from '@/components/composables/ActivityItem'
import ActivityItemLayout from '@/components/Activities/ActivityItemType/ActivityItemLayout.vue'
import TransitionExpand from '@/components/transitions/TransitionExpand.vue'

// ======== Props ======== //
const props = defineProps({
  activity: {
    type: Object,
    required: true
  },
  prevActivity: {
    type: Object,
    required: true
  }
})
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['onReply'])
// ====================== //

// ======== Data ======== //
const showDetails = ref(false)
// ====================== //

// ======== Methods ======== /
const onReply = () => {
  emit('onReply')
}
const toggleShowDetails = () => {
  showDetails.value = !showDetails.value
}
// ====================== //

// ======== Composables ======== //
const { targetType, activityMessage, formattedTimeCreatedHour, denormalizedChanges, showChanges } =
  useActivityItem(props)
// ====================== //
</script>

<style scoped></style>
