<template>
  <div class="quote-presentation-toc--wrapper hidden 2xl:block w-[400px]">
    <div
      :class="{
        opened: tocOpen
      }"
      class="h-full bottom-0 w-[400px] bg-flame-white flex flex-col"
    >
      <!--   Header section  -->
      <div
        class="sticky w-[400px] flex-none"
        :class="{
          'top-[52px]': $route.query?.tab === 'Preview' && !isQuotingEngine,
          'top-[66px]': $route.name === 'Project preview',
          'top-0': $route.path.includes('presentation') || isQuotingEngine
        }"
      >
        <!--    Search bar    -->
        <div class="w-full flex items-center p-4 justify-center shadow-md bg-flame-white gap-4">
          <IconField iconPosition="left" class="w-full">
            <InputIcon>
              <font-awesome-icon :icon="['far', 'search']" class="text-pitch-black text-lg" />
            </InputIcon>
            <InputText
              v-model="searchQuery"
              ref="searchInput"
              :placeholder="'Search'"
              class="h-full text-base font-light"
              :ptOptions="{ mergeProps: true }"
              :pt="{
                root: '!h-10 !text-cool-gray-700 !hover:border-pitch-black !hover:bg-pitch-black !hover:text-level-yellow !rounded-md'
              }"
            />
          </IconField>
          <Btn
            v-if="!isQuotingEngine"
            severity="tertiary-borderless"
            :class="{
              '!p-2': true
            }"
            @click="$emit('update-show-summary', 1)"
          >
            <font-awesome-icon icon="print" style="font-size: 1.2em" />
          </Btn>
        </div>
      </div>
      <!-- Content section -->
      <div class="h-full max-h-fit">
        <div
          class="fixed w-[400px] py-4 px-6 overflow-y-auto h-full"
          :class="{
            'pb-[230px]': $route.query?.tab === 'Preview',
            'pb-[170px]': $route.name === 'Project preview',
            'pb-[110px]': $route.path.includes('presentation')
          }"
        >
          <RecursiveTOCItem
            v-for="(ref, index) in filteredGroups"
            :key="ref"
            :ref-id="ref"
            :parent-ref-id="ref"
            :normalized="normalized"
            :disabled="disabled"
            :active-item="activeItem"
            :open-sections="openSections"
            :index="index"
            :search-query="searchQuery"
            @update:openSections="updateOpenSections"
            @item-click="scrollTo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import IconField from 'primevue/iconfield'
import RecursiveTOCItem from '@/components/quote/presentation/RecursiveTOCItem.vue'

export default {
  name: 'QuotePresentationTOC',
  props: {
    normalized: {
      type: Object
    },
    groups: {
      type: Object
    },
    disabled: {
      type: Array
    },
    isInQuoteEditor: {
      type: Boolean
    },
    isQuotingEngine: {
      default: false
    }
  },
  data() {
    return {
      tocOpen: true,
      scroller: null,
      scrollCallback: null,
      searchQuery: '',
      activeItem: null,
      openSections: [],
      itemIcons: {
        Optional: {
          icon: ['fas', 'sparkles'],
          color: 'text-blue-300'
        },
        Selections: {
          icon: 'swatchbook',
          color: 'text-level-yellow'
        }
      }
    }
  },
  created() {
    // Listen for the event from Selections
    eventBus.$on('toggle-section-from-selections', ({ ref, isOpen }) => {
      this.$set(this.openSections, ref, isOpen)
    })
  },
  components: { IconField, InputText, InputIcon, RecursiveTOCItem },
  computed: {
    filteredGroups() {
      const query = this.searchQuery.toLowerCase()
      const groups = this.groups.filter((ref) => this.shouldShowRef(ref))

      const filterRecursive = (subRef, accumulator) => {
        const subRefName = this.getNormalizedName(subRef)?.toLowerCase()

        if (subRefName?.includes(query)) {
          accumulator.push(subRef)
        }

        if (this.normalized[subRef]?.aoChildren?.length > 0) {
          this.normalized[subRef].aoChildren.forEach((childRef) => {
            filterRecursive(childRef, accumulator)
          })
        }

        return accumulator
      }

      const filtered = []

      groups.forEach((subRef) => {
        const matches = filterRecursive(subRef, [])
        if (matches.length > 0) {
          filtered.push(subRef)
        }
      })

      return query ? filtered : groups
    }
  },
  methods: {
    shouldShowRef(ref) {
      const item = this.normalized[ref]

      if (item) {
        if (item.assembly_emphasis && item.assembly_emphasis < -2) return false
        if (item.cost_type_emphasis && item.cost_type_emphasis < 0) return false
        if (item.oMeta?.itemType === 'task') return false
      }

      return true
    },
    toggleSection(ref) {
      if (this.openSections.includes(ref)) {
        this.openSections = this.openSections.filter((section) => section !== ref)
      } else if (this.normalized[ref].type === 'assembly') {
        this.openSections.push(ref)
      }

      eventBus.$emit('toggle-section-from-toc', { ref, isOpen: this.isSectionOpen(ref) })
    },
    isSectionOpen(ref) {
      return this.openSections.includes(ref)
    },
    updateOpenSections(newOpenSections) {
      this.openSections = newOpenSections
    },
    scrollTo(ref) {
      const element = document.querySelector(`#${ref}`)
      const isAssembly = this.normalized[ref].item_type === 'assembly'
      const isInteractive = this.itemType(ref)
      const shouldScroll = !isAssembly || (isAssembly && isInteractive)

      if (shouldScroll && element) {
        this.activeItem = ref
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })

        const allItems = document.querySelectorAll('.border-2')
        allItems.forEach((item) => {
          item.classList.remove('border-2')
          item.classList.remove('!border-pitch-black')
        })

        setTimeout(() => {
          element.classList.add('border-2')
          element.classList.add('!border-pitch-black')
        }, 300)
      }
    },
    getNormalizedName(ref) {
      return (
        this.normalized[ref]?.item_name ||
        this.normalized[ref]?.assembly_name ||
        this.normalized[ref]?.cost_type_name
      )
    },
    itemType(ref) {
      if (this.normalized[ref].cost_item_is_optional || this.normalized[ref].assembly_is_optional)
        return 'Optional'
      if (
        (this.normalized[ref].aoAddons && this.normalized[ref].aoAddons.length) ||
        this.normalized[ref].cost_type_is_variation_parent
      )
        return 'Selections'
      return null
    }
  },
  async mounted() {
    // Initialize all sections as open
    this.openSections = Object.keys(this.normalized).filter((ref) => {
      return this.normalized[ref].type === 'assembly'
    })

    await this.$nextTick()
    this.scroller = document.querySelector('.scroll-container--container')
    const anchors = document.getElementsByClassName('toc-anchor')
    const content = document.querySelector('.quote-pres--content')
    const toc = document.querySelector('.quote-presentation--selections-wrapper')
    this.scrollCallback = () => {
      anchors.forEach((anchor) => {
        const distanceToTop = anchor.getBoundingClientRect().top
        const contentTop = content.getBoundingClientRect().top
        const el = document.getElementById(`title-${anchor.id}`)
        const { parent } = el.dataset
        const container = document.querySelector(`#container-${parent}`)
        const classes = el.classList
        // mark toc section as active
        if (distanceToTop < 1000 && distanceToTop > 0) {
          classes.add('active')
          // open if the section is triggered
          if (!container.classList.contains('open')) {
            container.classList.add('open')
          }
        } else {
          classes.remove('active')
        }

        // close if past the section
        if (
          distanceToTop < -1 * (anchor.offsetHeight + 200) &&
          container.classList.contains('open')
        ) {
          container.classList.remove('open')
        }

        if (contentTop < 0) {
          toc.classList.remove('trigger')
        }
      })
    }
    await this.$nextTick()
  },
  beforeUnmount() {
    eventBus.$off('toggle-section-from-selections')
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-presentation-toc--wrapper {
  position: relative;
  .quote-presentation-toc--item {
    &:not(.open) {
      .quote-presentation-toc--item--sub {
        display: none;
      }
    }
    > .quote-presentation-toc--title {
      color: $cool-gray-700;
      font-size: 0.9em;
      font-weight: 500;
      display: flex;
    }
    .quote-presentation-toc--item--sub {
      > .quote-presentation-toc--title {
        color: $cool-gray-600;
        font-size: 0.8em;
        display: flex;
      }

      &.disabled {
        > .quote-presentation-toc--title {
          color: $cool-gray-400 !important;
        }
      }
    }
  }
}
</style>
