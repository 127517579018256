import { ref, onBeforeUnmount, onMounted, getCurrentInstance, computed, nextTick } from 'vue'
import eventBus from '@/eventBus.js'

export default {
  usePics(args) {
    const { dataSheets, cellPositionsVisible, getColDef, getRowSheet, moveCell } = args

    const $this = getCurrentInstance().proxy

    const dragoverCell = ref([null, null])
    const dropzone = ref({})

    const picColumns = computed(() =>
      dataSheets.value.reduce(
        (acc, sh) => [
          ...acc,
          sh.columns.map((colDef, index) => index).filter((i) => sh.columns[i].attachments)
        ],
        []
      )
    )
    const fullRowPicColumns = computed(() =>
      dataSheets.value.reduce(
        (acc, sh) => [
          ...acc,
          sh.columns
            .map((colDef, index) => index)
            .filter((i) => sh.columns[i].attachments?.dropzone === 'row')
        ],
        []
      )
    )

    const dragging = ref(false)

    const handleDragoverCanvas = (eventData) => {
      const { col = null, row = null } = eventData.element?.position ?? {}
      dragoverCell.value = [col, row]
      dragging.value = true
    }

    const dropzoneStyle = computed(() => {
      if (!dragging.value) {
        return []
      }

      const locations = picColumns.value.reduce(
        (acc, col) => [
          ...acc,
          ...Object.keys(cellPositionsVisible.value).map((row) => ({
            col,
            row,
            ...cellPositionsVisible.value[row][col]
          }))
        ],
        []
      )

      return locations.map((loc) => {
        const colDef = getColDef(loc.col, getRowSheet(loc.row))
        const { x: left, y: top, width, height, col, row } = loc

        return {
          col,
          row,
          colDef,
          style: {
            top: `${top}px`,
            left: `${left}px`,
            width: `${width}px`,
            height: `${height}px`
          }
        }
      })
    })

    const handleDragleaveCanvas = () => {
      dragoverCell.value = [null, null]
      dragging.value = false
    }

    const handleDropCell = async (e) => {
      const { col = null, row = null } = e.element?.position ?? {}

      if (col === null || row === null) return e.originalEvent.preventDefault()
      dragging.value = false
      dragoverCell.value = [null, null]

      moveCell(col, row)
      await nextTick()

      eventBus.$emit('sheetFilesDrop', e)

      return e.originalEvent.preventDefault()
    }

    onMounted(() => {
      $this.$on('dragoverCanvas', handleDragoverCanvas)
      // $this.$on('dragleaveCanvas', handleDragleaveCanvas);
      window.addEventListener('dragleave', handleDragleaveCanvas)
      $this.$on('dropCell', handleDropCell)
      $this.$on('mousedownCanvas', handleDragleaveCanvas)
    })

    onBeforeUnmount(() => {
      $this.$off('dragoverCanvas', handleDragoverCanvas)
      // $this.$off('dragleaveCanvas', handleDragleaveCanvas);
      window.removeEventListener('dragleave', handleDragleaveCanvas)
      $this.$off('dropCell', handleDropCell)
      $this.$off('mousedownCanvas', handleDragleaveCanvas)
    })

    return {
      dropzone,
      dragoverCell,
      dropzoneStyle,
      fullRowPicColumns
    }
  }
}
