import _ from '../../../../imports/api/Helpers.js'
import AutoCost from '../../../../imports/api/AutoCost.js'

export default {
  assignee: (obj) => ({
    text: `${obj.assignee_name}`,
    html: `${obj.assignee_name}`,
    value: String(obj[`${obj.type}_id`]),
    icon: obj.assignee_object_type === 'user' ? 'user' : 'truck'
  }),
  default: (obj) => ({
    company: obj.company_id || null,
    user: obj.user_id || obj[`${obj.type}_owner`] || obj[`${obj.type}_creator`] || null,
    uid: obj.uid || `${obj.text}.${obj.value}.${_.uniqueId()}`,
    text: obj[`${obj.type}_name`],
    html: obj[`${obj.type}_name`],
    value: String(obj[`${obj.type}_id`])
  }),

  labor_type: (lt, store, mod = null) => {
    const defaultMod = store.getters.defaultMod
    let modUsed =
      mod?.mod_labor_net ||
      (store.state.session.company && store.state.session.company.mod_labor_net) ||
      defaultMod
    const isAutoCost = AutoCost.isAutoCostLaborTypeId(lt.labor_type_id)
    const isAutoCostEnabled = AutoCost.hasAutoCostSubscription(store.state.session.company)
    let icon = isAutoCost ? 'svg:autocostDark' : null

    let html = `
        <div class="flex flex-col justify-center items-start gap-2 ${!isAutoCostEnabled && isAutoCost ? 'opacity-25' : ''}">
          <div class="flex flex-row justify-start items-center gap-2">
            <span class="font-medium">${lt.labor_type_name.replace(/\\/g, '')}</span>
          </div>
          <div class="flex flex-row justify-start items-center gap-2">
            <span class="badge info text-monospace">
                ${!isAutoCostEnabled && isAutoCost ? '$XX.XX' : _.format(lt.labor_type_is_indexed ? lt.labor_type_rate_net_index * modUsed : lt.labor_type_rate_net_index, 'currency')}/hr
            </span>
            <span class="text-xs">
              ${lt.labor_type_is_indexed ? `<span class="glyph2 glyph2-map-marker circled info icon--component mx-1 xs"></span>` : ''}
              ${!isAutoCostEnabled && isAutoCost ? 'Upgrade to AutoCost for instant access to labor rates in your local area' : lt.labor_type_is_indexed && isAutoCost ? `Base rate, select for rate local to ${mod.mod_id || 'your area'} (US/CA Only)` : ''}
            </span>
          </div>
        </div>`

    return {
      company: lt.company_id,
      user: lt.labor_type_creator || null,
      html,
      icon,
      text: lt.labor_type_name,
      value: lt.labor_type_id,
      disabled: !isAutoCostEnabled && isAutoCost ? true : false
    }
  },

  dimension: (dim) => {
    const html = `
          <div class="flex flex-column justify-center items-start" style="width: 100%">
            <div>
              ${dim.dimension_name}
              
              
            </div>
            <div class="pl-3 flex flex-column justify-center items-start" style="max-width: 70%; overflow: hidden; text-overflow: ellipsis; line-height: 1;">
            
            
              <div style="font-size: 0.7em;" class="my-1" >
                <strong class='text-info' style="text-transform: uppercase">${dim.dimension_measure_type}</strong>
              
                <strong
                  class='text-monospace mono monospace'
                  style="padding: 0.1em 0.25em; border-radius:0.25em; background:#${dim.dimension_color}; color:#444;">
                  ${dim.dimension_id}
                </strong>
              
              </div>
            
              <small class="text-muted" style="white-space: nowrap; width: 100%">
                ${dim.dimension_desc}
              </small>
            </div>
          </div>
        `
    return {
      company: dim.company_id,
      user: null,
      html,
      text: dim.dimension_name,
      value: dim.dimension_id,
      icon: dim.dimension_icon
    }
  },

  stage: (state) => ({
    company: state.company_id,
    user: null,
    html: `<div class="flex flex-column justify-center items-start align-content-start font-medium">
            ${state.stage_name}
          </div>`,
    text: `${state.stage_name}`,
    value: state.stage_id,
    status: state.stage_status
  }),

  tax: (tax) => {
    const sett = tax.oTaxSettings
    const catTitle = {
      ct: 'General',
      ihlt: 'Lab',
      mt: 'Mat',
      slt: 'Subs'
    }
    const summary = Object.keys(sett).reduce(
      (acc, cat) =>
        sett[cat].length
          ? `${acc ? `${acc}, ` : ''}${catTitle[cat]} (${sett[cat].map((t) => t.name).join(',')}): ${_.toCurrency(sett[cat].reduce((acco, t) => acco + Number(t.pcnt), 0) * 100.0)}%`
          : acc,
      ''
    )
    const html = `
        <div class="flex flex-column justify-center items-start">
          <div>
            <span class="text-info ml-1">
              ${tax.tax_name}
            </span>
            
          </div>
          <div class="pl-3"><small class="text-muted">${summary}</small></div>
        </div>`

    return {
      company: tax.company_id,
      user: tax.tax_creator || null,
      html,
      text: tax.tax_name,
      value: tax.tax_id
    }
  },

  csi_code: (cc) => {
    const html = `
        <div class="flex flex-row justify-start items-center">
          <div>${cc.csi_code_code}</div>
          <div class="mx-1">${cc.csi_code_name ? '-' : ''}</div>
          <div>${cc.csi_code_name ?? ''}</div>
        </div>`
    return {
      company: cc.company_id,
      user: null,
      html,
      text: cc.csi_code_name,
      value: cc.csi_code_id
    }
  },

  unit_of_measure: (uom) => {
    let html
    let icon
    if (
      uom.dimension_measure_type &&
      /[a-zA-Z]/.test(uom.unit_of_measure_id) &&
      !/each/.test(uom.unit_of_measure_id)
    ) {
      switch (uom.dimension_measure_type) {
        case 'weight':
          icon = 'weight-scale'
          break
        case 'length':
          icon = 'ruler'
          break
        case 'area':
          icon = 'square'
          break
        case 'count':
          icon = 'hashtag'
          break
        case 'volume':
          icon = 'cube'
          break
        default:
          icon = 'ruler-triangle'
          break
      }

      html = `
        <div class="flex flex-row justify-between items-center gap-4 w-full">
          <div class="flex gap-4 justify-start items-center w-full">
            <span>${uom.unit_of_measure_name}</span>
            <span class="text-white text-monospace text-xs bg-blue-print-500 rounded-sm py-0.5 px-1.5">
              ${uom.unit_of_measure_abbr}
            </span>
            
          </div>
          <div><small class="text-muted">${uom.dimension_measure_type}</small></div>
        </div>`
    } else {
      html = `
        <div class="flex flex-column justify-center items-start">
          <div>${uom.unit_of_measure_name} • <small class="text-muted">${uom.unit_of_measure_abbr}</small></div>
        </div>`
    }
    return {
      company: null,
      user: null,
      html,
      icon,
      text: uom.unit_of_measure_abbr,
      value: uom.unit_of_measure_id
    }
  },

  client: (client) => ({
    company: null,
    user: null,
    text: `${client.user_name}`,
    html: `
      <div class="flex flex-col">
        <div>${client.user_name}</div>
        <div class="text-sm">${client.user_email}</div>
      </div>`,
    value: String(client.client_id)
  })
}
