<template>
  <modal v-bind="$props" ref="modal" :clickAway="false">
    <template #header>
      <font-awesome-icon :icon="['fas', 'cubes']" />
      Build Assembly
    </template>
    <template #body>
      <EntitySelect :object="{ ...object, ...(modal.embue ?? {}) }" type="assembly">
        <template #default="{ refId, store }">
          <assembly-body ref="body" :refId="refId" />

          <div class="flex justify-end items-center">
            <Btn link size="xl" :action="close">Cancel</Btn>
            <Btn
              severity="bolster"
              hotkey="enter"
              size="xl"
              :action="() => saveAndClose3({ refId, store })"
              >Save</Btn
            >
          </div>
        </template>
      </EntitySelect>
    </template>
  </modal>
</template>

<script>
import AssemblyBody from '../bodies/Assembly.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import EntitySelect from '@/components/ui/EntitySelect.vue'

export default {
  name: 'AssemblyNew',
  mixins: [ObjectModalMixin],
  components: { EntitySelect, AssemblyBody },
  props: {
    type: {
      default: 'assembly'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
