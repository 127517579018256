export default {
  root: ({ props, context, parent }) => ({
    class: [
      // Font
      'font-sans leading-none',

      // Size
      'lg:min-w-[200px] lg:w-[300px] h-10',

      // Flex
      { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

      // Sizes
      { 'h-10 min-h-10': props.size === 'large' || props.size === 'lg' },

      // Spacing
      'm-0 px-4',

      // Shape
      { 'rounded-sm': parent.instance.$name !== 'InputGroup' },
      {
        'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup'
      },
      { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
      { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },

      // Colors
      'text-pitch-black dark:text-surface-200',
      'placeholder:text-cool-gray-700 dark:placeholder:text-surface-500',
      'bg-transparent dark:bg-surface-900',
      'focus:border-pitch-black focus:ring-1 focus:ring-pitch-black focus:bg-flame-white transition',
      'hover:bg-surface-100',
      'border',
      { 'border-cool-gray-500 dark:border-surface-600': !props.invalid },

      // Invalid State
      { 'border-red-500 dark:border-red-400': props.invalid },

      // States
      {
        'hover:border-cool-gray-700 hover:bg-flame-white dark:hover:border-surface-300':
          !context.disabled && !props.invalid,
        'focus:outline-none focus:outline-offset-0 focus:z-10': !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled
      },

      // Filled State *for FloatLabel
      { filled: parent.instance?.$name == 'FloatLabel' && context.filled },

      // Misc
      'appearance-none',
      'transition-colors duration-200'
    ]
  })
}
