<template>
  <!-- Root container -->
  <div class="flex flex-col w-full" :class="gridHeightClass">
    <!-- Header -->
    <slot name="toolbar" v-if="showToolbar">
      <PageHeader :title="title" :grid="true">
        <!-- Title and reload -->
        <template #left>
          <Btn rounded link class="text-md" :action="() => reload(true)">
            <font-awesome-icon icon="arrows-rotate" v-tooltip="'Reload table...'" />
          </Btn>
        </template>
        <!-- Search, filters, and create new -->
        <template #right>
          <div class="flex flex-row gap-x-2 md:gap-x-4 h-fit">
            <IconField iconPosition="left">
              <InputIcon>
                <icon icon="search" class="text-surface-800" />
              </InputIcon>
              <InputText
                v-model="searchPhraseLocal"
                placeholder="Search this table..."
                size="large"
                type="text"
              />
            </IconField>
            <Btn
              size="lg"
              v-if="numberOfFilters && !isAtDefaultSetting"
              class="!text-deep-red-500 !border-deep-red-500"
              severity="tertiary"
              @click="loadDefaults"
            >
              <font-awesome-icon icon="arrow-rotate-left" />
              <span class="hidden xl:inline"> Reset filters </span>
            </Btn>
            <btn size="lg" class="max-w-10 md:max-w-none" severity="tertiary" @click="openFilters">
              <font-awesome-icon icon="filter-list" size="lg" />

              <span class="hidden xl:inline"> Columns </span>
            </btn>
            <btn
              v-if="createNew"
              size="lg"
              severity="bolster"
              class="max-w-10 md:max-w-none"
              @click="create"
            >
              <font-awesome-icon :icon="['fas', 'plus']" />
              <span class="hidden md:inline whitespace-nowrap"> Create new </span>
            </btn>
          </div>
        </template>
      </PageHeader>
    </slot>

    <!-- Filter presets and grid toolbar -->
    <div v-if="type !== 'pipeline'" class="mb-6 md:mb-8">
      <!-- Before (above) filter presets -->
      <slot name="filterPresetBefore"></slot>

      <!-- Filter preset cards -->
      <div v-show="!selectedObjects.length" class="flex flex-row gap-x-4 w-full">
        <a
          v-show="$refs?.presetCards?.scrollWidth > $refs?.presetCards?.clientWidth"
          class="hidden md:flex items-center px-2 hover:bg-surface-100 rounded transition cursor-pointer"
          @click="scrollPresetCards(-1)"
        >
          <icon icon="chevron-left" />
        </a>
        <div
          v-show="
            $slots.filterPresetBefore || $slots.filterPresetAfter || filterPresetsLocal.length
          "
          class="flex flex-row gap-4 w-full max-w-full animate-fadeIn overflow-x-auto scrollbar-hide scroll-smooth sm:fade-r md:fade-r"
          ref="presetCards"
        >
          <a
            class="flex items-center justify-between p-4 gap-4 min-h-20 min-w-56 w-full max-w-full rounded transition border border-surface-300 text-pitch-black cursor-pointer select-none"
            v-for="(preset, index) in filterPresetsLocal"
            :key="preset.title"
            :class="
              index === presetIndex
                ? 'bg-cement-400 !border-cement-800 !text-cement-950/90 hover:bg-cement-300'
                : 'hover:bg-surface-100'
            "
            @click="onPreset(preset, index)"
            v-tooltip="preset.description"
          >
            <p class="font-normal">
              {{ preset.title }}
            </p>
            <p class="font-bold">
              <count :type="type" :filterPresets="preset.filters" :filters="filters" />
            </p>
          </a>
        </div>
        <a
          v-show="$refs?.presetCards?.scrollWidth > $refs?.presetCards?.clientWidth"
          class="hidden md:flex items-center px-2 hover:bg-surface-100 rounded transition cursor-pointer"
          @click="scrollPresetCards(1)"
        >
          <icon icon="chevron-right" />
        </a>
      </div>

      <!-- Grid toolbar, appears when item is selected -->
      <div
        v-if="showToolbar && selectedObjects.length"
        class="w-full flex justify-between items-center p-4 gap-x-4 text-pitch-black bg-cement-300 rounded animate-fadeIn"
      >
        <grid-toolbar
          class="grow justify-self-start overflow-x-auto scrollbar-hide fade-r pr-2"
          ref="toolbar"
          :grid="$this"
          :type="type"
          :showActions="showActions"
          :selected="selectedObjects"
        />
        <a
          class="flex justify-center items-center hover:bg-surface-200 rounded-full h-8 w-8 transition cursor-pointer"
          @click="deselectAll"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" class="text-2xl" />
        </a>
      </div>

      <!-- After (below) filter presets -->
      <slot name="filterPresetAfter"></slot>
    </div>

    <!-- No items found -->
    <div v-if="!set.length && !altSet.length && !loading && type !== 'pipeline'">
      <div class="flex flex-col justify-center items-center mt-8 gap-y-6 *:text-surface-400">
        <icon icon="magnifying-glass" class="text-4xl text-surface-400" />
        <p class="text-2xl">Nothing found.</p>
        <span class="flex flex-row gap-3">
          <Btn
            v-if="(numberOfFilters && !filtersAtDefault) || searchPhraseLocal"
            severity="tertiary"
            size="lg"
            class="text-surface-400 border-surface-300"
            @click="loadDefaults"
          >
            Reset filters to default
          </Btn>
          <Btn
            severity="tertiary"
            class="text-surface-400 border-surface-300"
            size="lg"
            v-if="createNew"
            @click="create"
          >
            Create new
          </Btn>
        </span>
      </div>
    </div>

    <!-- Main grid -->
    <div
      v-else
      class="flex flex-col overflow-x-hidden"
      ref="eventWrapper"
      @click.prevent.stop.shift="rowShiftClick"
      @click.prevent.stop.ctrl="rowCtrlClick"
      @click.prevent.stop.meta="rowCtrlClick"
      @touchstart="rowTap"
      @click.exact="rowClick"
    >
      <slot
        name="customView"
        :hasLoadedOnce="hasFetched"
        :hasFetched="hasFetched"
        :loading="loading"
        :set="set"
        :selected="selected"
        :selectedIds="selectedIds"
        :searchPhrase="searchPhraseLocal"
        :highlighted="highlighted"
        :endOfSet="endOfSet"
      >
        <!-- Before row items -->
        <div>
          <slot name="before"></slot>
          <slot name="above"></slot>
        </div>

        <!-- Column headers -->
        <div
          v-if="showHeader && !showInlineAction"
          id="header"
          class="flex flex-row text-nowrap border-b pb-2 border-surface-200 overflow-x-auto scrollbar-hide"
          :class="columnWidths.length ? '' : 'opacity-0'"
          @scroll="onScroll('header')"
        >
          <div
            v-for="(column, index) in visibleColumns"
            :key="index"
            :style="`width:${columnWidths[index]}px; min-width:${columnWidths[index]}px`"
            :ref="`columnHeader-${column.column}`"
            @dblclick.prevent.stop.capture="openFilters"
            @click.prevent.stop.capture="setOrderBy(column.sortBy)"
            class="select-none"
          >
            <span
              class="text-surface-400 hover:text-cement-700 cursor-pointer transition"
              :class="index === 0 ? 'ml-2' : ''"
            >
              {{ $f.capitalize(column.name) }}
              <font-awesome-icon
                icon="arrow-down"
                v-if="
                  orderLocal &&
                  orderLocal.find(
                    (o) => o[0] === column.sortBy && o[1] && o[1].toLowerCase() === 'desc'
                  )
                "
              />
              <font-awesome-icon
                icon="arrow-up"
                v-if="
                  orderLocal &&
                  orderLocal.find(
                    (o) => o[0] === column.sortBy && o[1] && o[1].toLowerCase() === 'asc'
                  )
                "
              />
            </span>
          </div>
        </div>

        <!-- Virtual scroller -->
        <VirtualScroller
          ref="gridscroller"
          :items="set"
          class="h-screen w-full"
          :item-size="itemSize"
          lazy
          @scroll="onScroll('gridScroller')"
          @lazy-load="onLazyLoad()"
        >
          <!-- Rows -->
          <template #item="{ item, options }">
            <div
              :key="item[this.idField]"
              :ref="`row${options.index}`"
              :data-index="options.index"
              :data-id="item[this.idField]"
              @dblclick="rowDoubleClick"
              @click="rowTap"
              @touchstart="rowTap"
              @doubletap="rowDoubleClick"
              class="flex flex-row border-b border-surface-200 min-h-16 text-pitch-black transition cursor-pointer items-center"
              :class="{
                'bg-cement-300': selected.indexOf(options.index) > -1,
                'hover:bg-surface-200/50': selected.indexOf(options.index) <= -1,
                'bg-surface-200': highlighted.indexOf(options.index) > -1
              }"
            >
              <!-- Columns -->
              <div v-if="showInlineAction">
                <btn-bar
                  rounded
                  :selected="[item]"
                  :actions="typeActions"
                  :grid="$this"
                  :editing="false"
                  :contextual="true"
                  :collapse="true"
                />
              </div>

              <div
                v-for="(column, index) in visibleColumns"
                class="flex items-center w-full h-full p-1"
                :style="`width:${columnWidths[index]}px`"
                :class="/number|currency/.test(column.formatType) ? 'number' : ''"
                :key="`${column.column}${item[idField]}`"
              >
                <!-- Raw data column -->
                <div
                  v-if="!column.component"
                  v-html="column.format(item) || '<strong class=\'text-muted\'>-</strong>'"
                  :class="[
                    /number|currency|time|date/i.test(column.formatType) ? 'number' : '',
                    column.class
                  ]"
                  class="select-none"
                />

                <!-- Component column -->
                <component
                  v-else-if="item[column.sortBy]"
                  v-bind="column.componentProps"
                  :is="column.component"
                  :style="column.componentStyle"
                  :object="item"
                  :selected="selected.indexOf(options.index) > -1"
                  :showCheck="multiple"
                  :go="false"
                  :grid="this"
                  class="select-none"
                />
                <strong class="text-muted" v-else>-</strong>
              </div>
            </div>
          </template>
        </VirtualScroller>

        <div v-if="set.length" class="flex justify-center text-center items-center border-t py-2">
          <div>
            Showing {{ $f.number(set.length) }}{{ endOfSet ? ` / ${set.length}` : '' }} results
          </div>
        </div>
      </slot>
    </div>

    <MiniModal :pt="{ content: '!bg-white', header: '!bg-white' }" scrollable ref="filters">
      <template #header>
        <font-awesome-icon icon="filter-list" />
        Columns and filters
      </template>
      <template #body>
        <CardSection>
          <template #label>Columns</template>

          <CardList>
            <CardListItem>
              <div class="flex justify-stretch gap-2 w-full">
                <Choose
                  class="grow"
                  full
                  :value="visibleLocal"
                  @input="(visibleColumns) => onVisibleColumns(visibleColumns)"
                  :staticSet="columnsChooseListColumn"
                  :multiple="true"
                  size="lg"
                />

                <Btn
                  v-if="visible.length"
                  @click="onVisibleColumns(visible)"
                  severity="tertiary"
                  class="shrink-0"
                  size="lg"
                >
                  <font-awesome-icon icon="ban" />
                  Reset columns
                </Btn>
              </div>
            </CardListItem>
          </CardList>
        </CardSection>

        <CardSection v-if="adhocFilterColumns.length || suggestedFilterColumns.length">
          <template #label>Filters</template>

          <Warning v-if="numberOfFilters && !isAtDefaultSetting">
            Some filters are restricting what you see.
            <btn @click="loadDefaults()" size="lg" severity="tertiary"> Reset filters </btn>
          </Warning>

          <card-list class="!shadow-none !px-4">
            <!-- ad hoc filters -->
            <card-list-collapse
              class="!shadow-none"
              :cloak="true"
              v-for="column in adhocFilterColumns"
              :key="column.sortBy"
            >
              <template #title>
                {{ column.name }}
                <template v-if="column.sortBy in filterTextLocal">
                  <span
                    class="badge badge-dark"
                    v-text="`Displaying: ${filterTextLocal[column.sortBy]}`"
                  ></span>
                </template>
              </template>
              <filter-field
                :filters="filtersLocal"
                :filterText="filterTextLocal"
                :column="column"
                :type="type"
                :value="filtersLocal[column.sortBy]"
                @input="singleFilterChange"
                @formatted="singleFilterTextChange"
              ></filter-field>
            </card-list-collapse>

            <!-- suggested filters -->
            <card-list-collapse
              class="!shadow-none"
              :cloak="true"
              v-for="column in suggestedFilterColumns"
              :key="column.sortBy"
            >
              <template #title>
                {{ column.name }}
                <template v-if="filterTextLocal && column.sortBy in filterTextLocal">
                  <span
                    class="badge badge-dark"
                    v-text="`Displaying: ${filterTextLocal[column.sortBy]}`"
                  ></span>
                </template>
              </template>
              <filter-field
                :filters="filtersLocal"
                :filterText="filterTextLocal"
                :column="column"
                :type="type"
                :value="filtersLocal[column.sortBy]"
                @input="singleFilterChange"
                @formatted="singleFilterTextChange"
              ></filter-field>
            </card-list-collapse>
          </card-list>
        </CardSection>
      </template>
    </MiniModal>

    <slot name="below" />
    <slot name="after" />
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import VirtualScroller from 'primevue/virtualscroller'
import Skeleton from 'primevue/skeleton'
import GridMixin from './GridMixin'
import GridSubItemTag from './GridSubItemTag.vue'
import GridToolbar from './GridToolbar.vue'
import FilterField from '../FilterField.vue'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import PageHeader from '@/components/layout/page/PageHeader.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
InputText.compatConfig = { MODE: 3 }

export default {
  name: 'Grid',
  mixins: [GridMixin],
  props: {
    title: {
      default: ''
    },
    type: {
      required: true
    }
  },
  components: {
    InputText,
    IconField,
    InputIcon,
    GridSubItemTag,
    GridToolbar,
    FilterField,
    VirtualScroller,
    Skeleton,
    PageHeader
  },
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  data() {
    return {
      columnWidths: [],
      columnSpacing: 64,
      firstLoad: true
    }
  },
  methods: {
    /**
     * onPreset
     * Called when a preset is selected or deselected.
     */
    onPreset(preset, index) {
      if (index === this.presetIndex) {
        this.setPreset({})
      } else {
        this.prevLastPlacePos = 0
        this.setPreset(preset)
      }
    },
    /**
     * onRowUnselect
     * Called when a row is deselected
     */
    onRowUnselect(event) {
      if (this.doubleClickToOpen && this.selected.includes(event.index)) {
        this.rowDoubleClick(event)
      } else {
        this.deselectAll()
      }
    },
    /**
     * onLazyLoad
     * Called when the VirtualScroller attempts to lazy load data.
     */
    async onLazyLoad() {
      const requested = await this.requestMore(this.set.length)
      if (requested || this.firstLoad) {
        this.firstLoad = false
      }
    },
    /**
     * onScroll
     * Triggers when either the table header or content are scrolled. Needed due to limitations with the
     * PrimeVue VirtualScroller component, which doesn't support non-scrolling elements (like the header).
     * To get around this, we keep the header outside the VirtualScroller and syncronize it's horizontal
     * scroll amount to the VirtualScroller.
     */
    onScroll(element) {
      const header = document.getElementById('header')
      const gridScroller = this.$refs.gridscroller.element
      if (gridScroller && header) {
        if (element === 'header') {
          gridScroller.scrollLeft = header.scrollLeft
        } else {
          header.scrollLeft = gridScroller.scrollLeft
        }
      }
    },
    /**
     * onVisibleColumns
     * Called when the visible columns are edited.
     */
    async onVisibleColumns(visibleColumns) {
      this.setVisibleColumns(visibleColumns)
      await this.reload(true, true)
    },
    /**
     * setColumnWidths
     * Dynamically calculates the width each column should be based on the widest row
     * currently being rendered.
     */
    setColumnWidths() {
      if (!this.$refs.gridscroller) return
      const gridScroller = this.$refs.gridscroller.element
      const rows = gridScroller.children[0].children

      rows.forEach((row) => {
        let index = 0
        row.children.forEach((child) => {
          // Default to 0 width
          if (!this.columnWidths[index]) {
            this.columnWidths[index] = 0
          }
          // If narrower than the header, set to the header's width
          const header = document.getElementById('header')?.children[index]?.children[0]
          if (header) {
            if (header.offsetWidth > this.columnWidths[index]) {
              this.columnWidths[index] = header.offsetWidth + this.columnSpacing
            }
            // Widen if list elements exceed header width
            const element = child.children[0]
            const width = element?.getBoundingClientRect()?.width
            if (width > this.columnWidths[index]) {
              this.columnWidths[index] = width + this.columnSpacing
            }
          }
          index++
        })
      })
    },
    scrollPresetCards(direction) {
      const presetCards = this.$refs.presetCards
      presetCards.scrollLeft += 550 * direction
    }
  },
  computed: {
    gridHeightClass() {
      if (this.type !== 'pipeline' && !this.$slots.customView) {
        return this.smallFormat ? 'h-screen-less-header' : 'h-screen'
      }
      return 'h-full pb-20'
    }
  },
  mounted() {
    eventBus.$on('fetched', () => {
      c.throttle(
        () => {
          this.setColumnWidths()
        },
        { delay: 100 }
      )
    })
  },
  unmounted() {
    eventBus.$off('fetched')
  }
}
</script>

<style lang="scss" scoped></style>
