import { computed, getCurrentInstance, onMounted, onBeforeUnmount, ref, watch, nextTick } from 'vue'
import xlsx from 'xlsx'
import { useStore } from 'vuex'
import Loading from './Loading.js'
import Canvas from './Canvas.js'
import DrawCache from './DrawCache.js'
import DataTransform from './DataTransform.js'
import SuperHeaders from './SuperHeaders'
import DrawGrid from './DrawGrid'
import CellResize from './CellResize'
import CellEditing from './CellEditing'
import DrawUtilities from './DrawUtilities'
import CellPositions from './CellPositions'
import CanvasEvents from './CanvasEvents'
import SelectCells from './SelectCells'
import ColumnOptions from './ColumnOptions'
import RowOptions from './RowOptions'
import CollapseGroups from './CollapseGroups'
import Pics from '@/components/Sheets/Pics.js'
import Session from '@/components/Sheets/Session.js'
import UndoRedo from '@/components/composables/UndoRedo.js'
import eventBus from '@/eventBus.js'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'

const { black, gray, presetFormats, concrete } = DrawUtilities

export default {
  useSheet(args) {
    const { props: reactiveProps, emit, selectedRowActions } = args

    let localClipboard = ''
    const $this = getCurrentInstance().proxy
    const $store = useStore()

    const props = reactiveProps.value
    const propSheets = computed(() => reactiveProps.value.sheets)
    const canvasCursorType = ref('cell')

    const {
      loadData,
      ingressData,

      dataSheets,

      cellData,
      cellDefault,
      refIdToColRow,
      getCellData,
      getRefId,
      getCellType,
      getCellObjectType,
      setCellValues,
      parseCellData,
      calculateTotals,

      getColFormatting,
      getRowFormatting,
      formatCellValue,

      rowCount,
      colCount,

      addFetchedName,

      rowsMap,
      getRowData,
      getRowId,
      isRowLastOfGroup,
      isRowFirstOfGroup,
      isRowLastOfSheet,
      isRowFirstOfSheet,
      getColDef,
      getColIndexesByField,

      groupMap,
      getRowGroup,

      getSheet,
      getRowSheet,

      sortZa,
      sortAz,

      getSuperHeaders,
      hasSuperHeaders,
      superHeadersHidden,
      collapsedSuperHeaders,
      resetOrder,

      moveRowTo,
      addRow,
      getRowFromId,
      isCollapseGroupParent,
      deleteRows,
      duplicateRows,
      turnIntoAssembly,
      rereference,
      isRowParent,
      getRowParent,
      initiateComputedCellValues,
      addNewRow,
      getChildren,
      fetchedNames,
      columnDependencies,
      sheetColsWithComputed,

      collapseSuperHeader,
      uncollapseSuperHeader,

      isCellDisabled,
      isCellReadOnly,

      getCellValue,
      loaded,
      addNewCell,
      getRowCollapseGroupKeys,
      addRowToCollapseGroups,
      rebuildCollapseGroupsFromIds,

      fetchNames,

      triggerComputeDependantsByField,
      addSourceRows,
      addRowData
    } = DataTransform.useDataTransform({
      propSheets,
      fitToData: props.fitToData
    })

    const sheetCollapseGroupsEnabled = computed(() =>
      dataSheets.value.map((sheet) => sheet.collapseGroups && !!sheet.collapseGroups.groups)
    )

    const sheetCollapseGroupsVisible = computed(() =>
      sheetCollapseGroupsEnabled.value.map(
        (enabled, i) =>
          enabled &&
          (!dataSheets.value[i].sorting ||
            dataSheets.value[i].sorting.length < 1 ||
            !dataSheets.value[i].grouping ||
            dataSheets.value[i].grouping.length < 1)
      )
    )

    const getColLabel = (col, sheet = topSheet.value) => {
      let colLabel = ''
      const colDef = getColDef(col, sheet)

      if (colDef.title) {
        return colDef.title
      }

      if (colDef.field) {
        return c.getFieldName(colDef.field)
      }

      let colNum = col
      while (colNum >= 0) {
        const remainder = colNum % 26
        colLabel = String.fromCharCode(65 + remainder) + colLabel
        colNum = Math.floor(colNum / 26) - 1
      }

      return colLabel
    }

    const { addLoading, removeLoading, loadingMessages } = Loading.useLoading()

    const sheetOptionsOffsets = ref({})
    const disableMouse = ref(false)
    const showHeadings = ref(true)
    const sheetFocused = ref(false)

    const showSearch = ref(false)
    const searchText = ref('')
    const searchResults = ref([])
    const searchPosition = ref(-1)

    const scaleFactor = ref(1)
    const freezeCol = ref(null)
    const tempStyles = ref({})

    const shiftKeyPressed = ref(false)
    const optionKeyPressed = ref(false)
    const ctrlKeyPressed = ref(false)

    const refStep = ref([])

    const refScrollbarThumbX = ref({})
    const refScrollbarThumbY = ref({})
    const refHorizontalScrollbar = ref({})
    const refVerticalScrollbar = ref({})
    const refSheetChooser = ref({})

    const refExpanderRow = ref({})
    const refExpanderCol = ref({})
    const refCopyPaste = ref({})
    const refColumnButton = ref({})
    const refContainer = ref({})
    const refSearchInput = ref({})
    const refSelectHoverCheckbox = ref({})
    const refSelectedRowsCheckboxes = ref([])
    const refMultiSelectIndicator = ref({})
    const refColumnActions = ref({})

    const defaultSelectedRowActions = computed(() => [
      {
        name: 'Download selected rows',
        icon: 'download',
        action: (rows) => {
          exportToXlsx(rows)
        }
      }
    ])

    const collapsedColumns = computed(() =>
      Object.keys(collapsedSuperHeaders.value).reduce(
        (acc, sheetIndex) => ({
          ...acc,
          [sheetIndex]: Object.keys(collapsedSuperHeaders.value[sheetIndex]).reduce(
            (acc2, headerIndex) => [
              ...acc2,
              ...(collapsedSuperHeaders.value[sheetIndex][`${headerIndex}`] || [])
            ],
            []
          )
        }),
        {}
      )
    )

    const sheetOptions = computed(() =>
      dataSheets.value.map((sheet, index) => ({
        value: index,
        text: sheet.title
      }))
    )

    const defaultGroupHeadingFormatting = computed(() => ({
      background: concrete,
      color: black,
      align: 'left',
      fontSize: 16,
      verticalAlign: 'middle',
      strokeColor: 'rgba(0, 0, 0, 0.1)',
      padding: [0, 0, 0, 5],
      bold: true,
      borders: {
        right: {
          thickness: 0
        },
        left: {
          thickness: 0
        }
      }
    }))

    const defaultGroupTotalsFormatting = computed(() => ({
      bold: false,
      background: '#e5e3e078',
      color: gray,
      verticalAlign: 'middle',
      strokeColor: 'rgba(0, 0, 0, 0.1)',
      right: {
        thickness: 0
      },
      left: {
        thickness: 0
      }
    }))

    const hoverCellData = computed(() => {
      const data = cellData.value // superfluous but must be here to trigger VUE watcher
      if (!data || !hoverCell.value || !hoverCell.value.length || hoverCell.value.length < 2)
        return { ...cellDefault }
      const [col, row] = hoverCell.value
      return getCellData(col, row)
    })

    const currentCellData = computed(() => {
      const data = cellData.value // superfluous but must be here to trigger VUE watcher
      if (!data || !currentCell.value || !currentCell.value.length || currentCell.value.length < 2)
        return { ...cellDefault }
      return getCellData(...currentCell.value)
    })

    const refCanvas = ref({})
    const { initializeMainCanvas, mainCtx, canvasWidth, canvasHeight, pixelRatio } =
      Canvas.useCanvas({
        canvas: refCanvas
      })
    watch(canvasCursorType, (is) => {
      refCanvas.value.style.cursor = is
    })

    const getSelectionRange = ([col1, row1], [col2, row2]) => {
      let top
      let bottom
      if (row1 > row2) {
        top = row2
        bottom = row1
      } else {
        top = row1
        bottom = row2
      }

      let left
      let right
      if (col1 > col2) {
        left = col2
        right = col1
      } else {
        left = col1
        right = col2
      }

      return [
        [left, top],
        [right, bottom]
      ]
    }

    const {
      // cellPositions,
      getSelectionRangePosition,
      getRowSheetHeadingHeight,
      getGroupHeadingHeight,
      getGroupTotalsHeight,
      getLeftColOffset,
      maxSheetWidth,
      superHeaderPositions,
      getCellBoundingRect,
      getRowSheetSpacing,
      getRowGroupSpacing,
      getRowHeight,
      getColWidth,
      columnHeadingHeight,
      sheetHeadingHeight,
      superHeadingHeight,
      rowHeadingWidth,
      fullHeadingHeight,
      sheetColPositions,
      rowHeights,
      sheetWidths,
      collapseGroupGutterPositionsVisible,
      collapseGroupGutterPositions,

      rowPositions,
      totalGutterWidth,

      colPositionsVisible,
      rowPositionsVisible,

      sheetColsVisible,
      fullHeadingWidth,
      getFreezeWidth,
      cellPositionsVisible,

      visibleRowRange,
      visibleColRange,
      visibleRows,
      visibleCols,

      topRow,
      leftCol,
      scrollToX,
      scrollToY,
      allColsVisible,
      allRowsVisible,

      isDraggingX,

      isDraggingY,

      scrollDown,
      scrollUp,
      scrollRight,
      scrollLeft,
      topSheet,
      rowHeadingBoundingRect,
      sheetPositionsVisible,
      columnHeadingPositions,

      groupPositionsVisible,
      getElementFromCoords,
      scrollOffsetX,
      getFirstVisibleRowOfSheet,
      collapsedGroupRows,
      collapseGroups,
      collapsedGroups,
      maxDepth,
      scrollableCols
    } = CellPositions.useCellPositions({
      props,
      dataSheets,
      getRowFromId,

      freezeCol,
      collapsedColumns,

      rowsMap,

      getSelectionRange,

      getColDef,
      getRowSheet,
      getSheet,
      isRowFirstOfGroup,
      isRowFirstOfSheet,
      isRowLastOfSheet,

      collapsedSuperHeaders,
      superHeadersHidden,
      isRowLastOfGroup,
      canvasHeight,
      canvasWidth,

      colCount,
      rowCount,
      getSuperHeaders,

      scrollContainer: refContainer,
      verticalScrollbar: refVerticalScrollbar,
      verticalThumb: refScrollbarThumbY,

      horizontalScrollbar: refHorizontalScrollbar,
      horizontalThumb: refScrollbarThumbX
    })

    if (props.session?.saveCollapseGroups) {
      watch(collapsedGroups, (cg = []) => setSessionValue('collapsedGroups', cg))
    }

    const deselect = (commit = cellFocused.value) => {
      if (commit) commitValue()
      currentCell.value = [null, null]
      cellFocused.value = false
      showEditableDiv.value = false
      showActionDiv.value = false
      showChooseDiv.value = false
      showOriginLabel.value = false
      showCalculatorDiv.value = false
      multiSelect.value = null
    }

    $this.$on('removedRows', () => deselect(false))

    const tooltip = ref('')
    const tooltipCoords = ref({
      left: -500,
      top: -500
    })

    const {
      mouseDown,
      currentCell,
      hoverCell,
      multiSelect,
      selectDown,
      selectUp,
      selectMultipleCells,
      rightClick,
      selectRight,
      selectLeft,
      originalBoundingRect,
      moveCell,
      cellFocused
    } = SelectCells.useSelectCells({
      shiftKeyPressed,
      ctrlKeyPressed,
      rowsMap,
      getRowSheet,
      getSheet,
      getCellBoundingRect,
      getSelectionRange,
      deselect,
      visibleCols,
      scrollableCols,
      tooltip,
      tooltipCoords,
      dataSheets,
      isCellDisabled,
      isCellReadOnly,
      getCellType
    })

    const {
      mousemoveElement: hoverElement,

      disableEventType,
      enableEventType
    } = CanvasEvents.useCanvasEvents({
      canvas: refCanvas,
      initializeMainCanvas,
      getElementFromCoords,
      disableMouse,
      emit
    })

    const contextMenuLocation = computed(() => {
      let fix = refEditableDiv.value

      if (multiSelect.value) {
        fix = refMultiSelectIndicator.value
      } else if (showEditableDiv.value) {
        fix = refEditableDiv.value
      }

      return fix
    })

    const hoverElementPosition = computed(() => {
      const hovel = hoverElement.value
      if (!hovel || !hovel.element || !hovel.element.position) {
        return {
          x: 0,
          y: 0,
          height: 0,
          width: 0
        }
      }

      return {
        ...hovel.element.position,
        name: hovel.element.name
      }
    })

    const hoverDisplayStyle = computed(() => {
      const { x, y, width, height } = hoverElementPosition.value

      return {
        display: 'block',
        top: `${y}px`,
        left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
        border: '1px solid red',
        position: 'absolute',
        fontSize: '0.5em',
        color: 'red',
        pointerEvents: 'none'
      }
    })

    const hoverElementName = computed(() => {
      if (!hoverElement.value || !hoverElement.value.element || !hoverElement.value.element.name) {
        return 'none'
      }

      return hoverElement.value.element.name
    })

    const sheetOptionsOffset = computed(() => sheetOptionsOffsets.value[topSheet.value])

    const selectedRowActionsStyle = computed(() => {
      if (
        ((!selectedRowActions || !selectedRowActions.length) &&
          !defaultSelectedRowActions.value.length) ||
        !selectedRows.value.length
      ) {
        return {
          display: 'none'
        }
      }

      return {
        position: 'absolute',
        top: `${5}px`,
        left: `${rowHeadingWidth.value / 2 - 12}px`,
        zIndex: '100'
      }
    })

    const sheetOptionsStyle = computed(() => ({
      position: 'absolute',
      left: `${sheetOptionsOffset.value}px`,
      top: `${4}px`
    }))

    const actionStyle = computed(() => {
      const [col, row] = currentCell.value
      const { top, left, height, width } = getCellBoundingRect(col, row)
      const value = getCurrentCellValue()

      if (!showActionDiv.value || value === '') {
        return {
          display: 'none'
        }
      }

      return {
        left: `${left}px`,
        top: `${top}px`,
        height: cellFocused.value ? 'fit-content' : `${height}px`,
        width: cellFocused.value ? 'fit-content' : `${width}px`,
        minWidth: `${width}px`
      }
    })

    const searchDivStyle = computed(() => {
      if (!showSearch.value) {
        return {
          display: 'none'
        }
      }

      const [col, row] = currentCell.value
      const { bottom, left } = getCellBoundingRect(col, row)
      return {
        left: `${left}px`,
        top: `${bottom}px`
      }
    })

    const selectionRangeStyle = computed(() => {
      const pos = getSelectionRangePosition(multiSelect.value)

      if (pos.width === 0 || pos.height === 0) {
        return {
          position: 'absolute',
          display: 'none',
          left: '-1000px',
          right: '-1000px'
        }
      }
      return {
        position: 'absolute',
        top: `${pos.top - 1}px`,
        left: `${pos.left - 1}px`,
        width: `${pos.width}px`,
        height: `${pos.height}px`
      }
    })

    const currentCellLabel = computed(() => {
      const [col, row] = currentCell.value
      return `${getColLabel(col)}${row + 1}`
    })

    const cellSelected = computed(
      () => currentCell.value[0] !== null && currentCell.value[1] !== null
    )

    const { applyOffscreen, applyOrDraw, clearFragments, buffer, scaledBuffer } =
      DrawCache.useDrawCache({
        pixelRatio,
        canvasWidth,
        canvasHeight
      })

    const saveSessionEnabled = ref(props.session.save ?? true)
    const saveSession = ref(false)
    const { setSessionValue, getSessionValue, session, sessionsWaiter } = Session.useSession({
      props,
      saveSession
    })

    // watch only after loaded
    watch([dataSheets, freezeCol], () => {
      if (!saveSession.value) return

      c.throttle(
        () => {
          // freeze
          setSessionValue('freezeCol', freezeCol.value)

          for (let i = 0; i < dataSheets.value.length; i++) {
            // sorting
            setSessionValue('sorting', dataSheets.value[i].sorting, i)
            // grouping
            setSessionValue('grouping', dataSheets.value[i].grouping, i)
            // colwidths
            setSessionValue('colWidths', dataSheets.value[i].colWidths, i)
            // super headers
            setSessionValue(
              'superHeaders',
              [...dataSheets.value[i].superHeaders].map((sh) =>
                sh.expanded !== null && sh.expanded !== undefined ? { expanded: sh.expanded } : {}
              ),
              i
            )
          }
        },
        { delay: 2000 }
      )
    })

    const initializeKey = ref(0)
    const reinitialize = async () => {
      await initialize()
      initializeKey.value += 1
    }

    // Lifecycle hooks
    onMounted(async () => {
      window.addEventListener('keydown', handleWindowKeydown)
      window.addEventListener('keyup', handleWindowKeyUp)
      window.addEventListener('resize', handleResize)

      await initialize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleWindowKeydown)
      window.removeEventListener('keyup', handleWindowKeyUp)
      window.removeEventListener('resize', handleResize)
    })

    // Reactive variables

    const getCurrentCellFormula = () => getCellEquation(...currentCell.value)

    const getCurrentCellValue = () => {
      if (
        !currentCell.value ||
        typeof currentCell.value[0] === 'undefined' ||
        typeof currentCell.value[1] === 'undefined'
      )
        return ''

      return getCellValue(currentCell.value[0], currentCell.value[1])
    }

    const forceRedraw = () => {
      clearFragments()
      triggerRedraw()
    }
    window.forceRedraw = forceRedraw
    watch(collapsedSuperHeaders, () => {
      forceRedraw()
    })

    watch(currentCell, (cc) => {
      const [col, row] = cc
      const colDef = getColDef(col, getRowSheet(row))

      if (colDef.onSelect) colDef.onSelect(getCellData(col, row), getRowData(row))
    })

    const handleResize = async () => {
      initializeMainCanvas()
      clearFragments()
      triggerRedraw()
    }

    const {
      showColumnButton,
      columnButtonLabel,
      columnButtonPosition,
      columnButtonStyle,
      actionColumn,
      actionSheet
    } = ColumnOptions.useColumnOptions({
      getColDef,
      getFirstVisibleRowOfSheet,
      getCellBoundingRect,
      getColLabel,
      refColumnButton
    })

    const handleSort = async (direction) => {
      const load = addLoading('Sorting')

      c.throttle(
        () =>
          direction === 'az'
            ? sortAz(actionColumn.value, topSheet.value)
            : sortZa(actionColumn.value, topSheet.value),
        { delay: 50 }
      )

      triggerRedraw(topSheet.value)
      removeLoading(load)
    }

    // Watchers
    //     watch(props.rows, async () => {
    //       await ingressData();
    //       triggerRedraw();
    //     });
    //
    //     watch(props.columns, async () => {
    //       await ingressData();
    //       triggerRedraw();
    //     });

    // watch([
    //     fetchedNames,
    //   ],
    //   () => {
    //     clearFragments('row-');
    //     triggerRedraw();
    //   });
    watch([canvasWidth, scaleFactor, canvasHeight], () => {
      triggerRedraw()
    })
    watch([dataSheets], () => {
      triggerRedraw()
    })
    watch(currentCell, (cc) => {
      const [col, row] = cc

      const cd = getCellData(col, row)
      emit('cellSelected', {
        visibleCols: [...visibleCols.value],
        visibleRowRange: [...visibleRowRange.value],
        cell: [...currentCell.value],
        ...cd,
        coords: getCellBoundingRect(col, row),
        id: getRowId(row)
      })
    })

    // Methods

    const exportToXlsx = async (rows = _.range(rowsMap.value?.length || 0)) => {
      const wb = xlsx.utils.book_new()

      const sheets = []
      for (let row = 0; row < rowsMap.value.length; row += 1) {
        if (rows === null || (rows.length && rows.includes(row))) {
          const sheetIndex = getRowSheet(row)

          if (!sheets[sheetIndex]) sheets[sheetIndex] = []

          const rowData = rowsMap.value[row].columns.map((refId) => cellData.value[refId].fmt)

          sheets[sheetIndex].push(rowData)
        }
      }

      for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex += 1) {
        sheets[sheetIndex].unshift(dataSheets.value[sheetIndex].columns.map((col) => col.title))
        const ws = xlsx.utils.aoa_to_sheet(sheets[sheetIndex])
        xlsx.utils.book_append_sheet(wb, ws, dataSheets.value[sheetIndex].title.slice(0, 30))
      }

      const name = `${dataSheets.value[0].title.slice(0, 30)}_Export`
      xlsx.writeFile(wb, `${name}.xlsx`)
    }

    const handleSelectBoxChange = (e, id) => {
      const sr = [...selectedRows.value]
      const index = selectedRows.value.indexOf(id)

      if (index < 0) {
        sr.push(id)
      } else {
        sr.splice(index, 1)
      }

      // Maintained proper checked status
      refSelectHoverCheckbox.value.checked = false
      ;(refSelectedRowsCheckboxes.value || []).forEach((reference) => {
        reference.querySelector('input').checked = true
      })

      selectedRows.value = sr
    }

    // API
    const setCellsText = async (values, doEmit = true, doRedraw = true, triggerComputed = true) => {
      if (!values.length) return

      const { changes } = await setCellValues(values, doEmit, triggerComputed, false)

      changes.map((ch) => tempHighlight(ch.refId))

      if (doRedraw) redrawCells(values, true)
    }
    const setCellText = async (col, row, text, doEmit = true) => {
      await setCellValues([[col, row, text]], doEmit)

      redrawCells([[col, row]], true)
    }

    const setFieldValues = async (normSet, normEquations = {}, mock = false, doEmit = false) => {
      let values = []

      let {
        dataSheets: ds = [...dataSheets.value],
        rowsMap: rm = [...rowsMap.value],
        cellData: cd = { ...cellData.value }
      } = mock || {}

      const msg = addLoading('Sorting')

      const combined = NormalizeUtilities.mergeChanges(normSet, normEquations)
      const ids = Object.keys(combined)
      const nonColComputed = []
      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i]
        const row = getRowFromId(id)

        if (row < 0) continue
        ;({ dataSheets: ds } = addSourceRows(
          { dataSheets: ds },
          {
            id,
            sheetIndex: rm[row].sheetIndex,
            originalRowIndex: rm[row].originalRowIndex,
            data: normSet[id],
            equations: normEquations[id] ?? {}
          }
        ))

        const sheetIndex = getRowSheet(row)

        const fields = Object.keys(combined[id])
        for (let fi = 0; fi < fields.length; fi += 1) {
          const field = fields[fi]

          const cols = getColIndexesByField(field, sheetIndex)

          // If it is a field that is NOT a column, but IS a recorded dependant because it is provided
          // in the mapping, and may be required for a computed value, add it to the computes list
          if (!cols.length || cols.every((col) => col < 0)) {
            nonColComputed.push([field, row])
            continue
          }

          for (let coli = 0; coli < cols.length; coli += 1) {
            const col = cols[coli]
            if (col < 0) continue

            let refId = getRefId(col, rm[row].originalRowIndex, sheetIndex, ds)
            const original = cd[refId]

            // Since EITHER a change in value, OR a change in equation,
            // can trigger this method, we need to default to the originals
            // in case one is provided and not the other for the same cell
            const value = normSet[id]?.[field] ?? original.raw ?? null

            const forceTakeEquation = field in (normSet[id] ?? {})
            // If it is a true value change like from normSet, then force it to take
            // the given equation as well or set to the value. DO NOT TAKE the original.eq equation
            let equation = original.eq ?? value ?? null
            if (forceTakeEquation) {
              equation = normEquations[id]?.[field] || value
            } else if (field in (normEquations[id] ?? {})) {
              equation = normEquations[id]?.[field] || equation
            }

            nonColComputed.push([field, row])
            if (!mock) values.push([col, row, value, equation || value, id, field])
            else {
              const {
                refId: assignedRefId,
                data: cdb,
                rowsMap: rmb
              } = addNewCell(col, row, refId, value, equation || value, cd, rm)
              cd = cdb
              rm = rmb
              refId = assignedRefId
            }
          }
        }
      }

      if (!mock) {
        dataSheets.value = ds
        rowsMap.value = rm
        cellData.value = cd

        if (values.length) {
          // If this is being called from a change originating from VUEX or auditing etc,
          // doEmit should be false, because you dont' want to emit another change and have
          // a loop. But when its originating fro somewhere else like a component
          // that hasn't affected store directly etc, then it should trigger so that
          // things like onCHnage can be fired and the right fragments can be redrawn etc
          await setCellsText(values, doEmit, false, false)
        }

        ds = dataSheets.value
        rm = rowsMap.value
        cd = cellData.value
      }

      redrawCells(values, true)

      if (nonColComputed.length) {
        nonColComputed.forEach(([f, r]) => triggerComputeDependantsByField(f, r))
      }

      removeLoading(msg)

      return {
        cellData: cd,
        rowsMap: rm,
        dataSheets: ds
      }
    }
    const setFieldValue = (sheet, id, field, value) => {
      const refId = getRefId(field, id, sheet)

      if (!(refId in refIdToColRow.value)) {
        throw new Error(`${refId} is not found in map`)
      }

      const [col, row] = refIdToColRow.value[refId]

      setCellText(col, row, value)
    }

    const tempHighlight = (refId) => {
      const [col, row] = refIdToColRow.value[refId] ?? [null, null]
      const type = getCellType(col, row)

      if (/enum|progress|action|checkbox/.test(type)) return

      setTempStyle(refId, {
        ...presetFormats.highlight
      })
    }

    // const tempHighlightRowId = (rowId) =>
    //   rowsMap.value[getRowFromId(rowId)].columns.forEach((refId) => tempHighlight(refId))
    // $this.$on('movedRows', ({ rows }) => rows.forEach((r) => tempHighlightRowId(r.id)))
    // $this.$on('addedRows', ({ rows }) => rows.forEach((r) => tempHighlightRowId(r.id)))
    // $this.$on('rereferencedRows', ({ rows }) => rows.forEach((r) => tempHighlightRowId(r.id)))

    const getTempStyle = (col, row) => {
      const refId = rowsMap.value[row] && rowsMap.value[row].columns[col]
      return (refId && tempStyles.value[refId]) || {}
    }

    const setTempStyle = (refId, format = {}, delay = 10000) => {
      const styles = { ...tempStyles.value }

      const key = refId
      styles[key] = format

      tempStyles.value = styles
      const [col, row] = refIdToColRow.value[refId] ?? [null, null]
      redrawCells([[col, row]], true)

      c.throttle(
        () => {
          removeTempStyle(refId)
        },
        {
          delay,
          key
        }
      )
    }

    const removeTempStyle = (refId) => {
      const styles = { ...tempStyles.value }
      const key = refId
      // eslint-disable-next-line no-unused-vars
      const { [key]: Omit, ...rest } = styles

      tempStyles.value = rest
      const [col, row] = refIdToColRow.value[refId] ?? [null, null]
      if (col !== null && row !== null) {
        redrawCells([[col, row]], true)
      }
    }

    const setFreezeCol = (col = actionColumn.value) => {
      if (col === freezeCol.value) {
        freezeCol.value = null
        leftCol.value = 0
        clearFragments()
        triggerRedraw()
      } else {
        freezeCol.value = col
        leftCol.value = col + 1
        clearFragments()
        triggerRedraw()
      }
    }

    const getColScrollX = (sheet = topSheet.value, col = leftCol.value) =>
      sheetColPositions.value[sheet] &&
      sheetColPositions.value[sheet][col] &&
      'x' in sheetColPositions.value[sheet][col]
        ? sheetColPositions.value[sheet][col].x
        : sheetColPositions.value[sheet][dataSheets.value[sheet].columns.length - 1].cumulativeWidth

    const handleSheetSelected = (selectedSheet) => {
      const { value } = selectedSheet

      const tr = value && dataSheets.value[value] && dataSheets.value[value].topRow

      if (tr || tr === 0) scrollToY(tr)
    }

    const handleSheetChooser = () => {
      refSheetChooser.value.open()
    }

    const handleSheetFilter = () => {
      if (
        !cellInRange(currentCell.value, [
          [visibleColRange.value[0], visibleRowRange.value[0]],
          [visibleColRange.value[1], visibleRowRange.value[1]]
        ])
      ) {
        scrollToX(currentCell.value[0])
        scrollToY(currentCell.value[1])
      }

      if (!showSearch.value) filter()
    }

    const enterSearchHandler = (e) => {
      if (e.key === 'Enter') {
        searchNext()
      }

      return e.preventDefault()
    }

    const handleSearchInput = (text) => {
      searchText.value = text

      c.throttle(() => getSearchResults(), {
        debounce: true,
        delay: 300
      })
    }

    const getSearchResults = () => {
      const text = searchText.value

      let results = []

      if (!text) {
        searchResults.value = results
        return
      }

      const lower = searchText.value.toLowerCase()
      results = Object.values(cellData.value).filter((row) => row.searchIndex.includes(lower))

      searchResults.value = results

      searchPosition.value = -1
    }

    const searchNext = () => {
      searchPosition.value = Math.min(searchResults.value.length - 1, searchPosition.value + 1)
      const cell = searchResults.value[searchPosition.value]
      const refId = cell.refId

      const [col, row] = refIdToColRow.value[refId]

      moveCell(col, row)
      scrollToY(Math.max(0, row - 4))
      scrollToX(Math.max(0, col - 4))
    }

    const searchPrev = () => {
      searchPosition.value = Math.max(0, searchPosition.value - 1)
      const cell = searchResults.value[searchPosition.value]
      const refId = cell.refId

      const [col, row] = refIdToColRow.value[refId]

      moveCell(col, row)
      scrollToY(Math.max(0, row - 4))
      scrollToX(Math.max(0, col - 4))
    }

    const reparseCellData = (doMoveCell = true, doTotalsCalculation = false) => {
      const load = addLoading('Sorting data')

      // const [curcol, currow] = currentCell.value;
      // const currentCellRefId = currow !== null
      //   && rowsMap.value[currow]
      //   && curcol !== null
      //   && rowsMap.value[currow].columns[curcol];

      parseCellData()
      if (doTotalsCalculation) calculateTotals()
      clearFragments()

      if (doMoveCell) {
        // I am empty
      }

      removeLoading(load)
    }

    const ungroup = () => {
      dataSheets.value[actionSheet.value].grouping = []
      const load = addLoading('Ungrouping')

      c.throttle(() => {
        reparseCellData(false, false, actionSheet.value)
        clearFragments(`^${actionSheet.value}`)
        triggerRedraw(actionSheet.value)
        removeLoading(load)
      })
    }

    const groupByActionColumn = () => {
      dataSheets.value[actionSheet.value].grouping = [actionColumn.value]
      const load = addLoading('Grouping')

      c.throttle(() => {
        reparseCellData(false, true, actionSheet.value)
        clearFragments(`^${actionSheet.value}`)

        triggerRedraw(actionSheet.value)
        removeLoading(load)
      })
    }

    const getCellFormatting = (col, row) => {
      const rowsMapValue = rowsMap.value
      const dataSheetsValue = dataSheets.value
      const rowMap = rowsMapValue[row]
      const sheetIndex = getRowSheet(row)
      const dataSheet = dataSheetsValue[sheetIndex]

      const colDef = getColDef(col, sheetIndex)
      const colFormatting = getColFormatting(col, sheetIndex)
      const rowFormatting = getRowFormatting(row)
      const cell = getCellData(col, row)
      const rowData = getRowData(row)
      const value = cell.raw

      const pseudoRow = rowMap?.pseudoRow

      let conditionalFormatting = {}
      if (!pseudoRow && colDef?.conditionalFormatting) {
        conditionalFormatting = colDef.conditionalFormatting(value, cell, rowData) || {}
      }

      const disabledFormatting = isCellDisabled(col, row) ? presetFormats.disabled : {}
      const readOnlyFormatting = isCellReadOnly(col, row) ? presetFormats.readOnly : {}

      let parentFormatting = {}
      if (rowMap?.id && dataSheet?.collapseGroups) {
        const groups = dataSheet.collapseGroups.groups || {}
        if (groups[rowMap.id]) {
          parentFormatting = dataSheet.collapseGroups.parentFormatting || {}
        }
      }

      const tempFormatting = getTempStyle(col, row)

      // Merge formatting using Object.assign to avoid creating intermediate objects
      const merged = Object.assign(
        {},
        props.defaultFormatting,
        colFormatting,
        rowFormatting,
        conditionalFormatting,
        parentFormatting,
        tempFormatting
      )

      const preset = merged.preset ? presetFormats[merged.preset] : {}

      // Final formatting, again using Object.assign
      return Object.assign({}, readOnlyFormatting, disabledFormatting, merged, preset)
    }

    const getCellValueFormatted = (col, row) => getCellData(col, row).fmt
    const getCellEquation = (col, row) => {
      const data = getCellData(col, row)
      const eq = getCellData(col, row).eq
      const format = getCellFormatting(col, row).format
      const isEq =
        (/percent|number|currency|\$|int|float/.test(format) && c.isEquation(eq)) ||
        (data.field && c.isNumericField(data.field) && c.isEquation(eq)) ||
        /^=/.test(eq)

      if (isEq) return `=${eq}`

      return eq
    }

    const filter = () => {
      showSearch.value = !showSearch.value

      // Check if its in view
      const [col, row] = currentCell.value
      const [firstCol, lastCol] = visibleColRange.value
      const [firstRow, lastRow] = visibleRowRange.value
      if (
        col === null ||
        row === null ||
        firstCol > col ||
        lastCol < col ||
        firstRow > row ||
        lastRow < row
      ) {
        moveCell(leftCol.value, topRow.value)
      }

      if (showSearch.value) {
        c.throttle(
          () => {
            refSearchInput.value.focus()
          },
          {
            delay: 25
          }
        )
      } else {
        searchResults.value = []
        searchPosition.value = -1
        searchText.value = ''
        refSearchInput.value.innerText = ''
      }
    }

    const handleSheetsFocus = () => {
      sheetFocused.value = true
    }

    const handleSheetsBlur = () => {
      sheetFocused.value = false
    }

    const checkOutsideEvent = (event) => {
      if (refContainer.value && !refContainer.value.contains(event.target)) {
        handleSheetsBlur()
      } else {
        handleSheetsFocus()
      }
    }

    onMounted(() => {
      document.addEventListener('click', checkOutsideEvent)
      document.addEventListener('focusin', checkOutsideEvent)
    })
    onBeforeUnmount(() => {
      document.removeEventListener('click', checkOutsideEvent)
      document.removeEventListener('focusin', checkOutsideEvent)
    })

    const handleWindowKeyUp = () => {
      ctrlKeyPressed.value = false
      shiftKeyPressed.value = false
      optionKeyPressed.value = false
    }

    const isActiveElementInput = () => {
      const activeElement = document.activeElement
      const tagName = activeElement.tagName.toLowerCase()
      const inputTypes = ['input', 'textarea', 'select']
      const isInput = inputTypes.includes(tagName)
      const isContentEditable = activeElement.getAttribute('contenteditable') === 'true'

      return isInput || isContentEditable
    }

    const isNumericCell = computed(() => 'number' === getCellType(...currentCell.value))
    const isLongTextCell = computed(
      () =>
        !!(
          getColDef(currentCell.value[0], getRowSheet(currentCell.value[1]))?.formatting
            ?.wordWrap ?? false
        )
    )
    const forceMultiline = ref(false)
    const showMultiline = computed(
      () => (forceMultiline.value && isNumericCell.value) || isLongTextCell.value
    )
    const showCalcPocket = computed(() => {
      return (
        cellFocused.value &&
        currentCell.value[0] !== null &&
        currentCell.value[1] !== null &&
        (isNumericCell.value || isLongTextCell.value)
      )
    })

    const handleEnter = async () => {
      if (cellFocused.value) {
        commitValue()
        cellFocused.value = false
      }

      if (currentCell.value[1] !== null && isRowLastOfSheet(currentCell.value[1])) {
        await addRow(currentCell.value[1])
        moveCell(currentCell.value[0], currentCell.value[1] + 1)
      } else {
        setTimeout(() => {
          selectDown()
        })
      }
    }

    const handleTab = () => {
      if (cellFocused.value) {
        commitValue()
        cellFocused.value = false
      }

      if (shiftKeyPressed.value) {
        setTimeout(() => {
          selectLeft()
        })
      } else {
        setTimeout(() => {
          selectRight()
        })
      }
    }

    const handleWindowKeydown = async (e) => {
      // if sheet itself is not foucsed, ignore completely
      if (!sheetFocused.value) return
      // if an input outside of the canvas is focused, ignore completely
      if (!cellFocused.value && isActiveElementInput()) return

      ctrlKeyPressed.value = e.ctrlKey || e.metaKey
      shiftKeyPressed.value = e.shiftKey
      optionKeyPressed.value = e.altKey

      if (showSearch.value) return
      if (cellFocused.value || isActiveElementInput()) return

      if (cellSelected.value) {
        if ((e.ctrlKey || e.metaKey) && !cellFocused.value) {
          // Handle Ctrl on Windows/Linux or Cmd on Mac
          if (e.key === 'c' || e.key === 'C') {
            await copyToClipboard()
            e.preventDefault() // Prevent the default copy action
          } else if (e.key === 'v' || e.key === 'V') {
            await pasteFromClipboard()
            e.preventDefault() // Prevent the default paste action
          } else if (e.which === '38') {
            scrollUp()
          } else if (e.key === 'z' && !shiftKeyPressed.value) {
            undo()
          } else if (e.key === 'z' && shiftKeyPressed.value) {
            redo()
          }
        } else if (!cellFocused.value && (e.key === 'Delete' || e.key === 'Backspace')) {
          // Clear the contents of the selected cell(s)
          if (multiSelect.value) {
            // If multiple cells are selected, clear all of them
            const [[startCol, startRow], [endCol, endRow]] = multiSelect.value
            for (let row = startRow; row <= endRow; row += 1) {
              for (let col = startCol; col <= endCol; col += 1) {
                setCellText(col, row, '') // Assuming you have a function to set the cell value
              }
            }
          } else {
            // Only one cell is selected
            setCurrentCellValue('')
          }

          // Refresh the grid or whatever you need to update the UI
          e.preventDefault() // Prevent the default delete/backspace action
        } else {
          switch (e.which) {
            case 16: // Shift key
              // revert to the above
              if (cellFocused.value) return
              e.preventDefault()
              break
            case 13: // Enter
              if (cellFocused.value && showMultiline.value) break

              if (cellFocused.value && !showMultiline.value) {
                commitValue()
                cellFocused.value = false
              }

              if (currentCell.value[1] !== null && isRowLastOfSheet(currentCell.value[1])) {
                await addRow(currentCell.value[1])
                moveCell(currentCell.value[0], currentCell.value[1] + 1)
              } else {
                setTimeout(() => {
                  selectDown()
                })
              }

              e.preventDefault()
              break
            case 9: // Tab
              if (cellFocused.value) {
                commitValue()
                cellFocused.value = false
              }
              if (e.shiftKey) {
                setTimeout(() => {
                  selectLeft()
                })
              } else {
                setTimeout(() => {
                  selectRight()
                })
              }
              e.preventDefault()
              break
            case 37: // Arrow Left
              if (cellFocused.value) return
              selectLeft()
              e.preventDefault()
              break
            case 38: // Arrow Up
              if (cellFocused.value) return
              selectUp()
              e.preventDefault()
              break
            case 39: // Arrow Right
              if (cellFocused.value) return
              selectRight()
              e.preventDefault()
              break
            case 40: // Arrow Down
              if (cellFocused.value) return
              selectDown()
              e.preventDefault()
              break
            default:
              if (cellFocused.value || isActiveElementInput()) return
              // focuse on cell
              //
              if (e.key.length === 1 && !isCellDisabled(...currentCell.value)) {
                cellFocused.value = true
                e.preventDefault()
                e.stopPropagation()
                currentCellValue.value = ''
                refCalc.value?.focus?.()

                setTimeout(() => {
                  currentCellValue.value = e.key
                  refCalc.value?.prefillValue(e.key)
                })
              }
              break
          }
        }
      }
    }

    const copyToClipboard = async () => {
      if (multiSelect.value) {
        // Destructure multiSelect to get the top-left and bottom-right cells
        const [[startCol, startRow], [endCol, endRow]] = multiSelect.value

        // Initialize an array to hold the rows
        const rows = []

        // Iterate over each row in the selected range
        for (let row = startRow; row <= endRow; row += 1) {
          // Initialize an array to hold the cells in this row
          const cells = []

          // Iterate over each column in this row
          for (let col = startCol; col <= endCol; col += 1) {
            // Retrieve the value of the cell at (col, row)
            const cellValue = getCellValue(col, row)

            // Append the cell value to the row
            cells.push(cellValue)
          }

          // Join the cells into a string and append to the rows
          rows.push(cells.join('\t'))
        }

        // Join the rows into a single string
        const clipboardData = rows.join('\n')

        // Attempt to write the string to the clipboard
        try {
          await navigator.clipboard.writeText(clipboardData)
        } catch (err) {
          localClipboard = clipboardData
        }
      } else {
        // Copy the current cell value to the clipboard
        try {
          await navigator.clipboard.writeText(currentCellValue.value)
        } catch (err) {
          // Use the local clipboard as a fallback
          localClipboard = currentCellValue.value
        }
      }
    }

    const pasteFromClipboard = async () => {
      let clipboardData
      try {
        clipboardData = await navigator.clipboard.readText()
      } catch (err) {
        clipboardData = localClipboard
      }

      // Destructure multiSelect to get the top-left and bottom-right cells
      const [[startCol, startRow], [rangeEndCol, rangeEndRow]] = multiSelect.value || [
        currentCell.value,
        [null, null]
      ]
      // const currentCell = currentCell.value;
      deselect()

      // Split the clipboard data into rows and cells
      const rows = clipboardData.split('\n').map((row) => row.split('\t'))

      const oneCellCopied = rows.length === 1 && rows[0].length === 1
      const rangeSelected = rangeEndRow !== null

      const matrixToRange = rangeSelected && !oneCellCopied
      const repeatedToRange = rangeSelected && oneCellCopied

      const matrixFromCell = !rangeSelected && !oneCellCopied
      const singleCellCopy = !rangeSelected && oneCellCopied

      let rowsToCopy
      let colsToCopy

      const rangeRowLength = rangeEndRow - startRow + 1

      if (matrixToRange) {
        rowsToCopy = Math.min(rows.length, rangeRowLength)
      }

      if (repeatedToRange) {
        rowsToCopy = rangeRowLength
      }

      if (matrixFromCell) {
        rowsToCopy = rows.length
      }

      if (singleCellCopy) {
        rowsToCopy = 1
      }

      const endRow = startRow + rowsToCopy
      let endCol

      const updatedValues = []

      // Iterate over each row in the selected range and the clipboard data
      for (let row = startRow, clipboardRow = 0; row < endRow; row += 1, clipboardRow += 1) {
        clipboardRow = oneCellCopied ? 0 : row - startRow // no repeating on matrix copy

        const rangeColsLength = rangeEndCol - startCol + 1

        if (matrixToRange) {
          colsToCopy = Math.min(rows[clipboardRow].length, rangeColsLength)
        }

        if (repeatedToRange) {
          colsToCopy = rangeColsLength
        }

        if (matrixFromCell) {
          colsToCopy = rows[clipboardRow].length
        }

        if (singleCellCopy) {
          colsToCopy = 1
        }

        // Iterate over each column in this row and the clipboard data
        endCol = startCol + colsToCopy

        for (let col = startCol, clipboardCol = 0; col < endCol; col += 1, clipboardCol += 1) {
          // Get the clipboard value for this cell

          // put the same value in the all the selected cells
          clipboardCol = oneCellCopied ? 0 : col - startCol // no repeating on matrix copy

          const cellValue = rows[clipboardRow][clipboardCol]

          if (visibleColRange.value[1] < col || visibleRowRange.value[1] < row) continue

          // Set the value of the cell at (col, row)
          updatedValues.push([col, row, cellValue])
        }
      }
      setCellsText(updatedValues, true, 100)

      // if (currentCell) moveCell(...currentCell);
      selectMultipleCells([startCol, startRow], [rangeEndCol, rangeEndRow])

      // Refresh the grid or whatever you need to do to update the UI
      // triggerRedraw(0);
    }

    // const endLoading = async () => {
    //   await $this.$nextTick();
    //   c.throttle(() => {
    //     loadingMessages.value = [];
    //   }, {
    //     delay: 200,
    //   });
    // };

    const isCurrentCell = (col, row) => currentCell.value[0] === col && currentCell.value[1] === row

    const cellInRange = (cell, range = multiSelect.value) => {
      // Destructure the cell and range for easier comparison
      const [cellCol, cellRow] = cell
      const [[startCol, startRow], [endCol, endRow]] = range

      // Check if the cell is within the range
      const inColRange = cellCol >= startCol && cellCol <= endCol
      const inRowRange = cellRow >= startRow && cellRow <= endRow

      return inColRange && inRowRange
    }

    const handleRightClick = (eventData) => {
      const { event } = eventData

      const { col, row } = eventData.element.position

      handleWindowKeyUp()
      rightClick.value = true

      let ms = multiSelect.value

      if (!multiSelect.value || !cellInRange([col, row], ms)) {
        multiSelect.value = null
        ms = null
        moveCell(col, row, cellFocused.value)
      }

      // handleMouseDownSelect(event);
      setTimeout(() => {
        if (ms) selectMultipleCells(...ms)
        refCopyPaste.value.open()
      }, 20)

      return event.preventDefault()
    }
    onMounted(() => {
      $this.$on('contextmenuCanvas', handleRightClick)
    })
    onBeforeUnmount(() => {
      $this.$off('contextmenuCanvas', handleRightClick)
    })

    onMounted(() => {
      $this.$on('clickCell', handleClick)
    })
    onBeforeUnmount(() => {
      $this.$off('clickCell', handleClick)
    })
    const handleClick = (eventData) => {
      const { col = null, row = null } = eventData?.element?.position ?? {}

      const sheetIndex = getRowSheet(row)
      const colDef = getColDef(col, sheetIndex)
      const action = colDef.action

      if (action) {
        const value = getCellValue(col, row)
        const rowData = getRowData(row)
        action(value, rowData, colDef)
      }

      if (colDef.checkbox) {
        const curval = getCellValue(col, row)
        const trueVal = colDef.checkbox?.checked?.value ?? 1
        const falseVal = colDef.checkbox?.unchecked?.value ?? 0
        const newVal = curval ? falseVal : trueVal
        setCellValues([[col, row, newVal]], true, true, true)
      }
    }

    const getClickedCellBoundingRect = () => originalBoundingRect.value

    const isCellNumeric = (col, row) => {
      const type = getCellType(col, row)

      return /number/.test(type)
    }

    watch(currentCell, () => {
      const [col, row] = currentCell.value
      if (col === null || row === null) return

      currentCellValue.value = getCellEquation(col, row)

      const cellType = getCellType(col, row)
      const cellEmpty = currentCellValue.value === ''

      showOriginLabel.value = false
      showEditableDiv.value = false
      showActionDiv.value = false
      showChooseDiv.value = false
      showCalculatorDiv.value = false
      disableCellEdit.value = false

      switch (cellType) {
        case 'progress': // calculator
          enableCellProgress()
          break
        case 'disabled': // nothing
        case 'group_header': // nothing
        case 'group_total': // nothing
          disableCellEdit.value = true
          enableCellEdit()
          break
        case 'number': // calculator
          enableCellCalculator()
          break
        case 'action':
          if (cellEmpty) enableCellEdit()
          else enableCellAction()
          break
        case 'dimension': // dim calc
          enableCellCalculator()
          break
        case 'enum':
          enableCellEnum()
          break
        case 'choose':
          enableCellChoose()
          break
        default: // text
          enableCellEdit()
          break
      }
    })

    const handleBlur = (eventCoords) => {
      const { col, row } = eventCoords
      if (!cellFocused.value || (col === currentCell[0] && row === currentCell[1])) return
      commitValue()
      cellFocused.value = false
    }

    onMounted(() => {
      $this.$on('clickCanvas', handleBlur)
    })

    const handleFocus = () => {
      cellFocused.value = true
      currentCellValue.value = getCurrentCellFormula()
    }

    const handleScroll = (e) => {
      disableMouse.value = true // avoid overstimulating + slowing UI
      const moveX = Math.abs(e.deltaX)
      const dirX = e.deltaX > 0 ? 'right' : 'left'
      const moveY = Math.abs(e.deltaY)
      const dirY = e.deltaY > 0 ? 'down' : 'up'

      let yMethod = null
      let xMethod = null
      if (moveY >= 1) {
        yMethod = scrollDown
        if (dirY === 'up') {
          yMethod = scrollUp
        }
      }

      if (moveX > 2) {
        xMethod = scrollLeft
        if (dirX === 'right') {
          xMethod = scrollRight
        }
      }

      if (xMethod) {
        const xDelay = 250 / Math.min(25, moveX)
        c.throttle(xMethod, {
          debounce: true,
          delay: xDelay,
          key: 'x'
        })
      }

      if (yMethod) {
        const yDelay = 150 / Math.min(40, moveY)
        c.throttle(yMethod, {
          debounce: true,
          delay: yDelay,
          key: 'y'
        })
      }

      if ((yMethod || xMethod) && cellFocused.value) {
        showOriginLabel.value = true
      }

      c.throttle(
        () => {
          disableMouse.value = false
        },
        {
          delay: 150
        }
      )
    }

    const setScaleFactor = (text) => {
      scaleFactor.value = c.toNum(text, 2)
      clearFragments()
      triggerRedraw()
    }

    watch(topRow, () => {
      if (loaded.value) drawGrid()

      emit('scroll', {
        direction: 'y',
        leftCol: leftCol.value,
        topRow: topRow.value,
        visibleCols: visibleCols.value,
        visibleRowRange: visibleRowRange.value,
        id: getRowId(topRow.value)
      })
    })
    watch(leftCol, () => {
      if (loaded.value) drawGrid()

      emit('scroll', {
        direction: 'x',
        leftCol: leftCol.value,
        topRow: topRow.value,
        visibleCols: visibleCols.value,
        visibleRowRange: visibleRowRange.value
      })
    })

    const tooltipStyle = computed(() => {
      const { top, left } = tooltipCoords.value

      if (!tooltip.value) {
        return {
          display: 'none'
        }
      }

      return {
        display: 'flex',
        position: 'absolute',
        left: `${left - 10}px`,
        top: `${top - 30}px`,
        width: 'fit-content',
        height: 'fit-content'
      }
    })

    const {
      clickCollapseSuperHeadersHandler,
      superHeaderCollapseStyle,
      showSuperHeaderCollapse,
      handleClickSuperHeading
    } = SuperHeaders.useSuperHeaders({
      canvasCursorType,
      tooltip,
      tooltipCoords,
      dataSheets,
      superHeaderPositions,
      getSheet,
      collapsedSuperHeaders,
      hasSuperHeaders,
      collapseSuperHeader,
      uncollapseSuperHeader,
      scrollToX,
      leftCol
    })

    const { drawGrid, getStepsText, triggerRedraw, redrawCells } = DrawGrid.useDrawGrid({
      topRow,
      leftCol,
      freezeCol,
      fullHeadingWidth,
      totalGutterWidth,
      collapseGroupGutterPositionsVisible,
      cellPositionsVisible,
      rowPositionsVisible,
      rowHeadingBoundingRect,
      sheetPositionsVisible,

      colPositionsVisible,
      groupPositionsVisible,
      scrollOffsetX,

      scaleFactor,
      getFreezeWidth,
      defaultFormatting: props.defaultFormatting,
      borderRadius: props.borderRadius,

      // cellPositions,
      getCellValueFormatted,
      dataSheets,
      defaultHeadingFormat: props.defaultHeadingFormat,
      showColumnHeadings: props.showColumnHeadings,
      showRowHeadings: props.showRowHeadings,

      topSheet,
      getColDef,
      getCellValue,
      getCellFormatting,
      getCellType,
      getColWidth,
      clearFragments,
      getSheet,
      scaledBuffer,
      buffer,
      applyOffscreen,

      formatCellValue,
      getGroupHeadingHeight,

      isRowFirstOfSheet,
      isRowLastOfSheet,

      getRowGroup,
      isRowFirstOfGroup,
      isRowLastOfGroup,

      sheetWidths,
      applyOrDraw,
      getGroupTotalsHeight,
      pixelRatio,
      sheetColPositions,
      getRowHeight,
      fullHeadingHeight,
      rowHeadingWidth,

      getRowSheetHeadingHeight,
      getRowGroupSpacing,
      getLeftColOffset,
      getRowSheet,
      showHeadings,
      superHeadingHeight,
      sheetHeadingHeight,
      columnHeadingHeight,

      getColScrollX,
      getRowSheetSpacing,
      maxSheetWidth,
      rowCount,
      visibleRowRange,
      canvasWidth,
      sheetOptionsOffsets,
      collapsedSuperHeaders,
      getColLabel,
      getSuperHeaders,
      defaultGroupTotalsFormatting,
      groupMap,
      defaultGroupHeadingFormatting,
      visibleCols,
      visibleColRange,

      mainCtx,

      canvasHeight,
      columnHeadingPositions,
      sheetColsVisible,
      collapsedGroupRows,
      collapsedGroups,

      isCellDisabled,

      loaded,
      getRowData
    })

    $this.$on('RedrawCell', (cell) => {
      if (loaded.value) redrawCells([cell], true)
    })
    $this.$on('RedrawCells', (cells) => {
      if (loaded.value) redrawCells(cells, true)
    })
    $this.$on('movedRows', () => {
      triggerRedraw(0)
    })
    $this.$on('addedRows', () => {
      clearFragments('')
      triggerRedraw(0)
    })

    const { startX, startY, resizingRow, resizingColumn, disableRowResizeHover } =
      CellResize.useCellResize({
        refExpanderCol,
        refExpanderRow,
        getColWidth,
        rowHeadingWidth,
        fullHeadingHeight,
        colCount,
        showColumnButton,
        rowHeights,
        drawGrid: triggerRedraw,
        topSheet,
        clearFragments,
        getRowHeight,
        dataSheets,
        refCanvas,
        rowCount,
        canvasHeight,
        superHeadingHeight,
        sheetHeadingHeight,
        isRowFirstOfSheet,

        disableEventType,
        enableEventType,
        getRowId
      })

    const {
      setFieldEquation,
      showEditableDiv,
      showOriginLabel,
      hoverStepsText,
      stepOverlay,
      stepMouseupHandler,
      stepMouseoutHandler,
      stepMouseDownHandler,
      stepMouseoverHandler,
      progressSteps,
      currentCellValueFormatted,
      handleChooseSelected,
      chooseOptions,
      showChooseDiv,
      refChooseDiv,
      refActionDiv,
      handleInput,
      refCalc,
      refEditableDiv,
      currentCellValue,
      disableCellEdit,
      commitValue,
      showCalculatorDiv,
      showActionDiv,
      enableCellEdit,
      enableCellChoose,
      enableCellEnum,
      enableCellCalculator,
      enableCellAction,
      hoveringStep,
      stepMouseDown,
      enableCellProgress,
      setCurrentCellValue,
      focusCell,
      currentCellDisabled,
      calcVariables,
      injectValue,
      forceValue
    } = CellEditing.useCellEditing({
      getColWidth,
      getStepsText,
      getCellType,
      setCellText,
      getCellData,
      isCellNumeric,
      addFetchedName,
      getCellObjectType,
      $store,
      currentCellData,
      drawGrid: triggerRedraw,
      clearFragments,
      currentCell,
      cellSelected,
      refStep,
      hoverCell,
      getRowHeight,
      hoverCellData,
      getColDef,
      topSheet,
      getRowSheet,
      isCurrentCell,
      refIdToColRow,
      getRefId,
      getRowData,
      cellData,
      getCurrentCellFormula,
      getCurrentCellValue,
      getCellValue,
      cellFocused,
      handleWindowKeyUp,
      moveCell,
      getRowId,
      isCellDisabled,
      isCellReadOnly
    })

    watch(cellFocused, (focused) => {
      const name = focused ? 'cellFocused' : 'cellBlurred'
      const [col, row] = currentCell.value

      const cd = getCellData(col, row)
      emit(name, {
        visibleCols: visibleCols.value,
        visibleRowRange: visibleRowRange.value,
        cell: currentCell.value,
        ...cd,
        coords: getCellBoundingRect(col, row),
        id: getRowId(row)
      })
    })

    const copyPasteActionsArray = computed(() => {
      const startRow = multiSelect.value?.[0]?.[1] ?? currentCell.value[1]
      const endRow = multiSelect.value?.[1]?.[1] ?? null

      const hasRange = startRow !== null && endRow !== null && startRow > -1 && endRow > -1

      return [
        {
          title: 'Copy',
          icon: 'fa fa-copy',
          action: () => copyToClipboard()
        },
        {
          title: 'Paste',
          icon: 'fa fa-clipboard',
          action: () => pasteFromClipboard()
        },
        ...(hasRange
          ? [
              {
                title: `Select rows ${startRow + 1} to ${endRow + 1}`,
                icon: 'fa fa-paste',
                action: () => {
                  let selected = [...selectedRows.value]
                  const rows = Array.from(
                    {
                      length: endRow - startRow + 1
                    },
                    (_, index) => startRow + index
                  )
                  selected = [...selected, ...rows]
                  selected.sort()
                  selectedRows.value = selected
                }
              }
            ]
          : []),
        {
          title: 'Search',
          icon: 'fa fa-search',
          action: () => filter()
        }
      ]
    })

    const columnActionsArray = computed(() => {
      const sheetIndex = actionSheet.value || 0
      const sheet = dataSheets.value[sheetIndex]
      return [
        {
          title: 'Sort AZ',
          icon: 'arrow-up-a-z',
          action: () => handleSort('az')
        },
        {
          title: 'Sort ZA',
          icon: 'arrow-down-a-z',
          action: () => handleSort('za')
        },

        ...(sheet && sheet.grouping && sheet.grouping.includes(actionColumn.value)
          ? [
              {
                title: 'Ungroup',
                icon: 'grid-dividers',
                action: () => ungroup()
              }
            ]
          : [
              {
                title: 'Group',
                icon: 'grid-dividers',
                action: () => groupByActionColumn()
              }
            ]),

        ...(freezeCol.value === actionColumn.value
          ? [
              {
                title: 'Unfreeze column',
                icon: 'thumbtack',
                action: () => setFreezeCol(actionColumn.value)
              }
            ]
          : [
              {
                title: 'Freeze column',
                icon: 'thumbtack',
                action: () => setFreezeCol(actionColumn.value)
              }
            ]),
        {
          title: 'Search',
          icon: 'search',
          action: () => filter()
        }
      ]
    })

    watch(rowsMap, (before, after) => {
      if (!c.jsonEquals(before, after)) {
        clearFragments()
        triggerRedraw()
      }
    })
    watch(groupMap, (before, after) => {
      if (!c.jsonEquals(before, after)) {
        clearFragments()
        triggerRedraw()
      }
    })
    const chooseStyle = computed(() => {
      const [col, row] = currentCell.value
      const { top, left, height, width } = getCellBoundingRect(col, row)

      if (!showChooseDiv.value || width === 0 || height === 0) {
        return {
          display: 'none',
          position: 'absolute',
          left: '-1000px',
          top: '-1000px'
        }
      }

      let disStyle = {}
      if (currentCellDisabled.value) {
        disStyle = {
          color: 'transparent !important',
          pointerEvents: 'none !important',
          background: 'transparent !important'
        }
      }

      return {
        left: `${left - 1}px`,
        top: `${top - 1}px`,
        height: `${height}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        ...disStyle
      }
    })

    const progressDivStyle = computed(() => {
      const [col, row] = hoverCell.value
      const {
        x: left,
        y: top,
        width,
        height
      } = cellFocused.value ? getClickedCellBoundingRect(col, row) : getCellBoundingRect(col, row)

      const disabled = isCellDisabled(col, row)

      if (disabled || getCellType(col, row) !== 'progress' || width === 0 || height === 0) {
        return {
          display: 'none',
          position: 'absolute',
          left: '-1000px',
          top: '-1000px'
        }
      }

      return {
        display: 'flex',
        position: 'absolute',
        left: `${left - 1}px`,
        top: `${top - 1}px`,
        height: `${height}px`,
        fontFamily: props.defaultFormatting.fontFamily,
        fontSize: `${props.defaultFormatting.fontSize}px`,
        width: cellFocused.value ? 'fit-content' : `${width}px`,
        minWidth: `${width}px`
      }
    })
    const editableDivStyle = computed(
      () => {
        const [col, row] = currentCell.value

        const br = getCellBoundingRect(col, row)
        const cl = getClickedCellBoundingRect(col, row)
        const cf = cellFocused.value
        const {
          x: left,
          y: top,
          width,
          height
        } = cellFocused.value && showOriginLabel.value ? cl : br

        if (!showEditableDiv.value || width === 0 || height === 0) {
          return {
            left: `${left - 1}px`,
            top: `${top - 1}px`,
            height: `${height}px`,
            width: cf ? 'fit-content' : `${width || 100}px`,
            minWidth: `${width || 100}px`,
            display: 'none',
            position: 'absolute',
            pointerEvents: 'none'
          }
        }

        return {
          position: 'absolute',
          left: `${left - 1}px`,
          top: `${top - 1}px`,
          height: `${height}px`,
          width: cf ? 'fit-content' : `${width}px`,
          minWidth: `${width}px`
        }
      },
      { flush: 'before' }
    )
    const calcStyle = computed(() => ({
      ...editableDivStyle.value,
      display:
        cellFocused.value &&
        !currentCellDisabled.value &&
        currentCell.value[0] !== null &&
        currentCell.value[1] !== null
          ? 'block'
          : 'none'
    }))
    const originLabelStyle = computed(() => {
      const [col, row] = currentCell.value
      const { x: left, y: top, width, cheight } = getClickedCellBoundingRect(...currentCell.value)

      const common = {
        borderRadius: '0px',
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: '1px 2px',
        overflow: 'hidden',
        userSelect: 'none',
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        color: 'transparent'
      }

      if (col === null || row === null || width === 0 || cheight === 0) {
        return {
          ...common,
          display: 'none',
          position: 'absolute',
          left: '-1000px',
          top: '-1000px'
        }
      }

      const height = 20
      return {
        ...common,
        position: 'absolute',
        left: `${left - 1}px`,
        top: `${top - height}px`,
        height: `${height}px`,
        backgroundColor: '#0b4bff',
        outline: '0',
        borderStyle: 'solid',
        borderWidth: '2px',
        color: 'white',
        width: 'fit-content !important', // Note: 'fit-content' might not work as expected in all contexts for inline styles.
        userSelect: 'none',
        pointerEvents: 'none',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        border: 'none',
        padding: '0.15em 0.6em'
      }
    })

    const progressMsgStyle = computed(() => {
      const { top, left } = progressDivStyle.value
      return {
        height: '40px',
        top: `${c.n(top) - 42}px`,
        left
      }
    })

    const {
      collapseGroupHighlightCoords,
      showCollapseGroupHighlight,
      collapseGroupHighlightStyle,
      highlightCollapseGroup,
      hideCollapseGroupHighlight,
      collapseGroup
    } = CollapseGroups.useCollapseGroups({
      colPositionsVisible,
      refCanvas,
      collapseGroups,
      collapsedGroups,
      canvasCursorType,
      scrollToY,
      topRow,

      collapseGroupGutterPositionsVisible,
      getRowId
    })

    onMounted(() => {
      $this.$on('mouseoverCell', (e) => {
        const { col, row } = e?.element?.position ?? {}
        const ct = getCellType(col, row)
        canvasCursorType.value = /enum|progress|action|checkbox/.test(ct) ? 'pointer' : 'cell'

        // Highlight collapse group if hover over parent
        if (isRowParent(row)) highlightCollapseGroup({ row })
        else hideCollapseGroupHighlight()
      })
    })

    const refGripClick = ref({})
    const refRowOptions = ref({})
    const {
      showSelectBox,
      selectedRows,
      handleGripClick,
      handleAddClick,
      selectBoxStyle,
      selectBoxStyles,
      handleGripMouseup,
      handleGripMousedown,
      insertIndicatorStyle,

      handleGripMouseout,
      handleGripMouseover,
      grabStyle,
      gripRow,
      hideGripOptions
    } = RowOptions.useRowOptions({
      canvasCursorType,
      getRowId,
      totalGutterWidth,
      rowHeadingWidth,
      getColWidth,
      visibleRows,
      getRowHeight,
      fullHeadingHeight,
      getCellBoundingRect,
      refCanvas,
      refGripClick,
      disableRowResizeHover,
      highlightCollapseGroup,
      collapseGroupGutterPositionsVisible,
      hideCollapseGroupHighlight,
      isCollapseGroupParent,
      moveRowTo,
      addRow,
      deleteRows,
      duplicateRows,
      turnIntoAssembly,
      commitValue,
      cellFocused,
      topSheet,
      dataSheets,
      getRowFromId,
      cellPositionsVisible,
      rowPositionsVisible,
      rowHeights,
      clearFragments,
      triggerRedraw,
      getRowParent,
      rowsMap,
      getChildren,
      getRowSheet,
      moveCell,
      focusCell,
      multiSelect
    })

    watch(collapseGroupGutterPositionsVisible, () => {
      triggerRedraw()
    })

    watch(totalGutterWidth, () => {
      triggerRedraw()
    })

    watch(rowPositionsVisible, () => {
      triggerRedraw()
    })

    watch(cellPositionsVisible, () => {
      triggerRedraw()
    })

    const refHelperDrop = ref(null)
    const refHelperComponent = ref(null)
    const helperComponent = computed(() => {
      const [col, row] = currentCell.value ?? [null, null]
      const sheetIndex = getRowSheet(row)
      return dataSheets.value[sheetIndex]?.columns?.[col]?.component
    })
    const showFieldHelper = computed(() => {
      const component = helperComponent.value

      const [col, row] = currentCell.value

      if (!component || ((component.showIf ?? false) && !component.showIf(...currentCell.value)))
        return false

      return col !== null && row !== null && refEditableDiv.value && !multiSelect.value
    })
    watch([showFieldHelper, currentCell], async ([show]) => {
      await c.throttle(() => {}, { delay: 100 })
      if (show) refHelperDrop.value?.open()
    })

    const { dropzoneStyle, dragoverCell } = Pics.usePics({
      dataSheets,
      cellPositionsVisible,
      currentCell,
      getColDef,
      getRowSheet,
      refFileList: refHelperComponent,
      moveCell,
      emit
    })

    const afterSlotStyle = computed(() => {
      const finalRow = rowsMap.value.length - 1

      const finalRowPosition = rowPositionsVisible.value[finalRow]

      if (!finalRowPosition) {
        return {
          display: 'none'
        }
      }

      const { y: top, height } = finalRowPosition

      const y = top + height

      const x = totalGutterWidth.value + rowHeadingWidth.value

      return {
        position: 'absolute',
        top: `${y}px`,
        left: `${x}px`,
        width: 'fit',
        height: '40px'
      }
    })

    const setDataSheets = (ds) => {
      dataSheets.value = ds
    }
    const setRowsMap = (rm) => {
      rowsMap.value = rm
    }

    const setCellData = (cd) => {
      cellData.value = cd
    }

    const rowLoading = ref(null)
    const rowLoadingStyle = computed(() => {
      const row = rowLoading.value

      if (row === null || !cellPositionsVisible.value[row]) {
        return {
          display: 'none'
        }
      }

      const cellsVis = Object.values(cellPositionsVisible.value[row])
      const startCell = cellsVis[0]
      const endCell = cellsVis[cellsVis.length - 1]

      return {
        position: 'absolute',
        left: `${startCell.x}px`,
        top: `${startCell.y}px`,
        width: `${endCell.x + endCell.width - startCell.x}px`,
        height: `${startCell.height}px`
      }
    })

    const {
      addUndo,
      undo: handleUndo,
      redo: handleRedo,
      canUndo,
      canRedo
    } = UndoRedo.useUndoRedo({ bundle: 1000 })

    const listening = ref(true)

    const undoChangeHandler = (changes) => {
      if (!changes?.length || !listening.value) return

      const full = c.imm(changes).reduce(
        (acc, ch) => ({
          ...acc,
          [ch.previous.refId]: [ch.previous.eq || ch.previous.raw, ch.current.eq || ch.current.raw]
        }),
        {}
      )

      addUndo(c.imm(full))
    }
    const getChangeArrayFromHistory = (changes) =>
      Object.keys(changes).reduce((acc, refId) => {
        const [col, row] = refIdToColRow.value[refId]
        return [...acc, [col, row, changes[refId]]]
      }, [])

    const undo = async () => {
      const changes = handleUndo()
      if (!changes) return
      const changeArray = getChangeArrayFromHistory(changes)
      listening.value = false
      setCellValues(changeArray, true)
      await nextTick()
      await c.throttle(() => {}, { delay: 200 })
      listening.value = true
    }
    const redo = async () => {
      const changes = handleRedo()
      if (!changes) return
      const changeArray = getChangeArrayFromHistory(changes)
      listening.value = false
      setCellValues(changeArray, true)
      await nextTick()
      await c.throttle(() => {}, { delay: 200 })
      listening.value = true
    }
    onMounted(() => $this.$on('changes', undoChangeHandler))
    onBeforeUnmount(() => $this.$off('changes', undoChangeHandler))

    const initialize = async () => {
      saveSession.value = false
      const load = addLoading('Importing data')
      setFreezeCol(null)
      await sessionsWaiter.value
      await ingressData(props.sheets, session.value)

      setFreezeCol(getSessionValue('freeze') ?? props.freeze)

      const cg = getSessionValue('collapsedGroups') ?? null
      dataSheets.value.forEach((sh) =>
        Object.keys(sh.collapseGroups?.groups ?? {}).forEach((cgi) =>
          (cg && !cg.includes(cgi)) ||
          (!cg && sh.collapseGroups.groups[cgi].expanded) ||
          !cgi ||
          cgi === 'null'
            ? true
            : collapseGroup(cgi)
        )
      )

      triggerRedraw()
      removeLoading(load)

      saveSession.value = saveSessionEnabled.value
      eventBus.$emit('sheet-initialized')
    }

    const reload = async () => {
      await ingressData(props.sheets, session.value)
      triggerRedraw()
    }

    const silentReload = async () => {
      setFreezeCol(null)
      await sessionsWaiter.value
      await loadData(props.sheets, session.value)
      setFreezeCol(getSessionValue('freeze') ?? props.freeze)

      const cg = getSessionValue('collapsedGroups') ?? null
      dataSheets.value.forEach((sh) =>
        Object.keys(sh.collapseGroups?.groups ?? {}).forEach((cgi) =>
          (cg && !cg.includes(cgi)) ||
          (!cg && sh.collapseGroups.groups[cgi].expanded) ||
          !cgi ||
          cgi === 'null'
            ? true
            : collapseGroup(cgi)
        )
      )

      triggerRedraw()
    }

    return {
      undo,
      redo,
      canUndo,
      canRedo,

      rowLoading,
      rowLoadingStyle,

      setDataSheets,
      setRowsMap,
      setCellData,

      afterSlotStyle,

      refHelperDrop,
      showFieldHelper,
      helperComponent,
      refHelperComponent,

      dropzoneStyle,
      dragoverCell,

      collapseGroupHighlightCoords,
      showCollapseGroupHighlight,
      collapseGroupHighlightStyle,

      disableMouse,
      columnHeadingPositions,
      totalGutterWidth,
      collapseGroupGutterPositionsVisible,
      collapseGroupGutterPositions,
      currentCell,
      handleClick,
      refContainer,
      handleSheetsFocus,
      handleScroll,
      handleRightClick,
      refCanvas,
      selectBoxStyle,
      showSelectBox,
      handleSelectBoxChange,
      selectBoxStyles,
      refSelectedRowsCheckboxes,
      selectedRowActionsStyle,
      defaultSelectedRowActions,
      cellFocused,
      editableDivStyle,
      currentCellValue,
      refEditableDiv,
      refCalc,
      handleBlur,
      handleFocus,
      handleInput,
      actionStyle,
      refActionDiv,
      chooseStyle,
      refChooseDiv,
      showChooseDiv,
      chooseOptions,
      handleChooseSelected,
      currentCellValueFormatted,
      progressDivStyle,
      progressSteps,
      stepMouseoverHandler,
      stepMouseoutHandler,
      stepMouseDownHandler,
      stepMouseupHandler,
      hoveringStep,
      hoverCellData,
      stepOverlay,
      progressMsgStyle,
      hoverStepsText,
      originLabelStyle,
      showOriginLabel,
      currentCellLabel,
      refExpanderCol,
      refExpanderRow,
      refVerticalScrollbar,
      allRowsVisible,
      refScrollbarThumbY,
      refHorizontalScrollbar,
      allColsVisible,
      refScrollbarThumbX,
      loadingMessages,
      refColumnActions,
      showColumnButton,
      isDraggingX,
      isDraggingY,
      resizingColumn,
      resizingRow,
      startX,
      startY,
      columnButtonPosition,
      columnButtonLabel,
      refColumnButton,
      multiSelect,
      showEditableDiv,
      selectionRangeStyle,
      mouseDown,
      refMultiSelectIndicator,
      searchDivStyle,
      refSearchInput,
      handleSearchInput,
      enterSearchHandler,
      searchResults,
      searchPosition,
      searchPrev,
      searchNext,
      filter,
      refCopyPaste,
      contextMenuLocation,
      copyPasteActionsArray,
      sheetOptionsStyle,
      handleSheetChooser,
      handleSheetFilter,
      setScaleFactor,
      scaleFactor,
      exportToXlsx,
      selectedRows,
      refSheetChooser,
      topSheet,
      handleSheetSelected,
      sheetOptions,
      superHeaderCollapseStyle,
      showSuperHeaderCollapse,
      handleClickSuperHeading,
      collapsedSuperHeaders,
      clickCollapseSuperHeadersHandler,
      tooltipStyle,
      tooltip,
      setFieldEquation,
      setFieldValue,
      setFieldValues,
      columnActionsArray,
      stepMouseDown,
      refStep,
      originalBoundingRect,

      fullHeadingHeight,
      rowPositions,
      hoverDisplayStyle,
      hoverElementName,
      hoverElementPosition,
      hoverElement,
      dataSheets,
      resetOrder,

      collapseGroup,
      collapsedGroupRows,
      collapseGroups,
      collapsedGroups,
      rowPositionsVisible,
      cellPositionsVisible,

      handleGripClick,
      handleAddClick,

      handleGripMouseup,
      handleGripMousedown,
      insertIndicatorStyle,
      refGripClick,
      refRowOptions,
      handleGripMouseout,
      handleGripMouseover,
      grabStyle,
      selectedRowActions,
      deleteRows,
      addRow,
      duplicateRows,
      turnIntoAssembly,
      moveRowTo,
      gripRow,
      getRowData,
      hideGripOptions,
      rowsMap,
      sheetCollapseGroupsEnabled,
      sheetCollapseGroupsVisible,
      getRowFromId,
      rereference,
      isRowParent,
      rowHeights,
      calcStyle,
      reinitialize,
      topRow,
      leftCol,
      addNewRow,
      cellData,
      getRowId,
      moveCell,
      focusCell,
      rowHeadingBoundingRect,
      scrollToY,
      scrollToX,
      fetchedNames,
      colPositionsVisible,
      columnDependencies,
      sheetColsWithComputed,
      getRowParent,
      getChildren,
      getRowSheet,
      getCellBoundingRect,
      getClickedCellBoundingRect,
      calcVariables,
      injectValue,
      forceValue,
      maxDepth,
      loaded,
      showActionDiv,
      getCellValue,
      getCellEquation,
      setCellText,
      getRefId,
      addNewCell,
      triggerRedraw,
      getRowCollapseGroupKeys,
      addRowToCollapseGroups,
      rebuildCollapseGroupsFromIds,

      fetchNames,
      parseCellData,
      initiateComputedCellValues,
      getColDef,
      getCellType,

      isNumericCell,
      showMultiline,
      forceMultiline,
      isLongTextCell,
      showCalcPocket,
      clearFragments,

      commitValue,
      handleEnter,
      handleTab,
      canvasCursorType,
      hoverCell,
      tempHighlight,
      deselect,
      saveSession,
      forceRedraw,
      colCount,
      canvasWidth,
      canvasHeight,
      addSourceRows,
      addRowData,
      columnButtonStyle,
      reload,
      silentReload

      // rowPositions,
      // colPositions,
      // // cellPositions,
      // // cellPositionsVisible,
      // colPositionsVisible,
      // rowPositionsVisible,
      // //
      // // sheetColsVisible,
      // // freezeCols,
      // // sheetColPositions,
      // sheetColsVisible,
      // cellPositions,
      // cellPositionsVisible,
      // visibleColRange,
    }
  }
}
