<template>
  <div class="file-upload-container flex !justify-start md:!justify-end md:!mt-0">
    <input
      class="hidden-input"
      type="file"
      ref="hiddenInput"
      multiple
      @click.stop.capture="() => {}"
      @change.stop.prevent="(event) => addFiles(event.target.files)"
    />

    <!-- button -->
    <slot name="button" v-if="showButton">
      <btn severity="tertiary" size="lg" @click="() => upload($event)">
        <font-awesome-icon icon="link" class="mr-4" />
        {{ buttonText }}
      </btn>
    </slot>

    <!-- drag and drop container -->
    <slot name="drop" :files="files">
      <div class="file-drop-watcher" :class="{ active: hoveringWithFile }">
        <div class="file-drop-container flex gap-3" :class="{ active: files.length }">
          <span class="message">
            <font-awesome-icon icon="cloud-arrow-down" />
            &nbsp;&nbsp;{{ placeholder }}
          </span>
          <div
            class="removable"
            v-for="(file, index) in filesShown"
            :key="index"
            @click.stop.prevent="() => removeFile(file)"
          >
            <progress-container :progress="file.progress" :size="90">
              <file :object="file" :imageFile="file.original" />
            </progress-container>
            <div class="remove">&times;</div>
          </div>
        </div>
      </div>
    </slot>
    <mini-modal size="sm" :width="300" ref="confirmUpload">
      <div class="text-center">
        <btn-group>
          <btn :stop="false" :prevent="false" class="btn lg primary" style="position: relative">
            <template #icon>
              <font-awesome-icon icon="cloud-arrow-up" />
            </template>
            Choose file to upload..

            <input
              type="file"
              @change.stop.prevent="
                () => {
                  addFiles($event.target.files)
                  $refs.confirmUpload.close()
                }
              "
              style="
                opacity: 0.001;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
              "
            />
          </btn>

          <btn class="btn muted mt-3" @click="() => $refs.confirmUpload.close()"> Cancel </btn>
        </btn-group>
      </div>
    </mini-modal>
    <MiniModal size="sm" scrollable :width="300" ref="cameraModal">
      <Camera ref="camera" />

      <template #footer>
        <Btn severity="bolster" size="xl" :action="addCameraPhotos">
          <font-awesome-icon icon="check" />Add photos</Btn
        >
      </template>
    </MiniModal>
  </div>
</template>

<script>
import mimeTypes from 'mime-types'
import File from '../Files/File.vue'
import ProgressContainer from './ProgressContainer.vue'
import MiniModal from '../modals/MiniModal.vue'
import CameraComponent from '@/components/ui/Camera/Camera.vue'

/**
 * Emits:
 *    filesAdded([file,..]) - when file is added, with intention of uploading it
 *    rawFilesAdded([file,..]) - raw files from event
 *    fileUploaded(uploadedFile) - after file uploaded and saved
 *    doneUploading(null) - after all files uploaded
 */
export default {
  name: '',
  emits: ['rawFilesAdded', 'doneUploading', 'fileUploaded', 'filesAdded'],
  data() {
    return {
      files: [],
      hoveringWithFile: false,
      defaultFilesToIgnore: [
        '.DS_Store', // OSX indexing file
        'Thumbs.db' // Windows indexing file
      ]
    }
  },
  computed: {
    filesShown() {
      return [...this.getTopLevelFolders(this.files), ...this.getTopLevelFiles(this.files)]
    },
    visible() {
      return this.allDone
    },
    allDone() {
      return this.files.reduce((acc, f) => acc + (f.done ? 1 : 0), 0) === this.files.length
    }
  },
  mounted() {
    if (this.grid) {
      this.grid.$on('upload', this.upload)
    }
    this.addDragEventListeners()
  },
  beforeUnmount() {
    if (this.grid) {
      this.grid.$off('upload', this.upload)
    }

    this.removeDragEventListeners()
  },
  methods: {
    triggerCamera() {
      this.$refs.cameraModal.open()
    },
    getOffsetParent() {
      if (!this.osparent) this.osparent = $(this.$el).offsetParent()[0]
      return this.osparent
    },
    addDragEventListeners() {
      const offsetParent = this.getOffsetParent()
      offsetParent.addEventListener('dragenter', this.dragenter)
      offsetParent.addEventListener('dragleave', this.dragleave)
      offsetParent.addEventListener('dragover', this.dragover)
      offsetParent.addEventListener('drop', this.drop)
    },
    removeDragEventListeners() {
      const offsetParent = this.getOffsetParent()
      offsetParent.removeEventListener('dragenter', this.dragenter)
      offsetParent.removeEventListener('dragleave', this.dragleave)
      offsetParent.removeEventListener('dragover', this.dragover)
      offsetParent.removeEventListener('drop', this.drop)
    },
    dragenter(e) {
      e.preventDefault()
      e.stopPropagation()
      this.hoveringWithFile = true
    },
    dragover(e) {
      e.preventDefault()
      e.stopPropagation()
      this.hoveringWithFile = true
    },
    dragleave(e) {
      e.preventDefault()
      e.stopPropagation()
      this.hoveringWithFile = false
    },
    drop(e, force = false) {
      if (
        force ||
        e.target === this.getOffsetParent() ||
        $(e.target).closest(this.getOffsetParent()).length
      ) {
        e.preventDefault()
        e.stopPropagation()
        return this.getDroppedOrSelectedFiles(e).then((files) => {
          this.addFiles(files)
          this.hoveringWithFile = false
          return Promise.resolve(files)
        })
      }
      this.hoveringWithFile = false
      return Promise.resolve()
    },

    async addCameraPhotos() {
      this.$refs.cameraModal.close()
      console.log(this.$refs.camera)
      const files = await this.$refs.camera.getFiles()
      const packaged = files.map((f) => this.packageFile(f.File, null, f.geolocation))
      this.addFiles(packaged)
    },

    packageFile(file, entry, geolocation = {}) {
      let fileTypeOverride = ''
      // handle some browsers sometimes missing mime types for dropped files
      const hasExtension = file.name.lastIndexOf('.') !== -1
      if (hasExtension && !file.type) {
        fileTypeOverride = mimeTypes.lookup(file.name)
      }
      return {
        fileObject: file,
        type: file.type ? file.type : fileTypeOverride,
        name: file.name,
        size: file.size,
        fullPath: entry ? entry.fullPath : file.name,
        geolocation
      }
    },
    shouldIgnoreFile(file) {
      return !file || !file.name || this.defaultFilesToIgnore.indexOf(file.name) >= 0
    },
    traverseDirectory(entry) {
      const reader = entry.createReader()
      // Resolved when the entire directory is traversed
      return new Promise((resolveDirectory) => {
        const iterationAttempts = []
        const errorHandler = () => {}
        const readEntries = () => {
          // According to the FileSystem API spec, readEntries() must be called until
          // it calls the callback with an empty array.
          reader.readEntries((batchEntries) => {
            if (!batchEntries.length) {
              // Done iterating this particular directory
              resolveDirectory(Promise.all(iterationAttempts))
            } else {
              // Add a list of promises for each directory entry.  If the entry is itself
              // a directory, then that promise won't resolve until it is fully traversed.
              iterationAttempts.push(
                Promise.all(
                  batchEntries.map((batchEntry) => {
                    if (batchEntry.isDirectory) {
                      return this.traverseDirectory(batchEntry)
                    }
                    return Promise.resolve(batchEntry)
                  })
                )
              )
              // Try calling readEntries() again for the same dir, according to spec
              readEntries()
            }
          }, errorHandler)
        }
        // initial call to recursive entry reader function
        readEntries()
      })
    },
    getFile(entry) {
      if (!entry || !entry.file) return Promise.resolve()
      return new Promise((resolve) => {
        entry.file((file) => {
          resolve(this.packageFile(file, entry))
        })
      })
    },
    handleFilePromises(promises, fileList) {
      return Promise.all(promises).then((files) => {
        files.forEach((file) => {
          if (!this.shouldIgnoreFile(file)) {
            fileList.push(file)
          }
        })
        return fileList
      })
    },
    getDataTransferFiles(dataTransfer) {
      const dataTransferFiles = []
      const folderPromises = []
      const filePromises = []

      ;[].slice.call(dataTransfer.items).forEach((listItem) => {
        if (typeof listItem.webkitGetAsEntry === 'function') {
          const entry = listItem.webkitGetAsEntry()

          if (entry && entry.isDirectory) {
            folderPromises.push(this.traverseDirectory(entry))
          } else {
            filePromises.push(this.getFile(entry))
          }
        } else {
          dataTransferFiles.push(listItem)
        }
      })
      if (folderPromises.length) {
        const flatten = (array) =>
          array.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])
        return Promise.all(folderPromises).then((fileEntries) => {
          const flattenedEntries = flatten(fileEntries)
          // collect async promises to convert each fileEntry into a File object
          flattenedEntries.forEach((fileEntry) => {
            filePromises.push(this.getFile(fileEntry))
          })
          return this.handleFilePromises(filePromises, dataTransferFiles)
        })
      } else if (filePromises.length) {
        return this.handleFilePromises(filePromises, dataTransferFiles)
      }
      return Promise.resolve(dataTransferFiles)
    },

    /**
     * Get list of files from event
     * @param event
     * @returns {Promise<void> | * | Promise.<Array>}
     */
    getDroppedOrSelectedFiles(event) {
      const dataTransfer = event.dataTransfer
      if (dataTransfer && dataTransfer.items) {
        return this.getDataTransferFiles(dataTransfer).then((fileList) => Promise.resolve(fileList))
      }
      const files = []
      const dragDropFileList = dataTransfer && dataTransfer.files
      const inputFieldFileList = event.target && event.target.files
      const fileList = dragDropFileList || inputFieldFileList || []
      this.$emit('rawFilesAdded', fileList)
      // convert the FileList to a simple array of File objects
      fileList.forEach((f) => files.push(this.packageFile(f)))
      return Promise.resolve(files)
    },

    allFilesUploaded() {
      this.$emit('doneUploading')
      if (this.grid) {
        this.grid.reload().then(() => {
          const ids = this.files.map((f) => f.file_id)
          this.grid.setSelectedIds(ids)
          this.grid.setHighlightedIds(ids)
          setTimeout(() => {
            this.files = []
            this.grid.scrollToActiveRow()
          })
        })
      } else {
        this.files = []
      }
    },
    uploadNext() {
      return new Promise((resolve) => {
        const incompleteFiles = this.files.map((f) => f).filter((f) => !f.sent)

        if (incompleteFiles.length) {
          this.sendUpload(incompleteFiles[0]).then((uploadedFile) => {
            this.$emit('fileUploaded', uploadedFile)
            this.uploadNext()
            resolve(uploadedFile)
          })
        } else {
          resolve()
        }
      })
    },
    fileDoneUploading(index, newFile) {
      const newFileCombined = {
        ...this.files[index],
        ...newFile,
        progress: 100,
        done: true
      }
      this.files.splice(index, 1, newFileCombined)
      setTimeout(() => {
        if (this.files.reduce((acc, f) => acc + (f.done ? 1 : 0), 0) === this.files.length) {
          setTimeout(this.allFilesUploaded, 500)
        }
      })
      return Promise.resolve(newFileCombined)
    },
    async sendUpload(file) {
      const fileIndex = this.files.indexOf(file)
      const newFileSent = {
        ...this.files[fileIndex],
        sent: true,
        progress: 20
      }
      this.files.splice(fileIndex, 1, newFileSent)
      const original = this.files[fileIndex].original
      const { parent_file_id: parent, ...tags } = this.tags

      let object = {}
      try {
        const { object: temp } = await this.$store.dispatch('File/sendUpload', {
          alert: true,
          go: false,
          scope: Object.keys(this.scope).length ? this.scope : null,
          file: {
            ...file,
            ...newFileSent,
            parent_file_id: newFileSent.parent_file_id || parent || null
          },
          data: original,
          tags,
          progress: (progress) => {
            const newFile = {
              ...this.files[fileIndex],
              progress
            }
            this.files.splice(fileIndex, 1, newFile)
          }
        })
        object = temp

        this.fileDoneUploading(fileIndex, object)
      } catch (e) {
        this.allFilesUploaded()
        throw e
      }

      return object
    },
    getRelativePath(f) {
      return (f.fullPath || f.webkitRelativePath || f.mozFullPath || '').replace('\\', '/')
    },
    upload() {
      // Because the input is of type "file", it implicitly has Camera/Filesystem/Photo Library
      // functionality on iOS/Android
      this.$refs.hiddenInput.click()
    },
    removeFile(file) {
      this.files.splice(this.files.indexOf(file), 1)
    },
    getTopLevelFolders(files) {
      const topLevelNames = _.uniq(
        files
          .filter(
            (f) =>
              this.getRelativePath(f)
                .replace(/^\/(.*?)/, '$1')
                .split('/').length > 1
          )
          .map((f) =>
            this.getRelativePath(f)
              .replace(/^\/(.*?)/, '$1')
              .split('/')
              .slice(0, -1)
              .shift()
          )
      )
      const sumProgress = files.reduce((progress, f) => progress + (f.progress || 0), 0)
      return topLevelNames.map((fileName) => ({
        ...c.buildDefaultObject('file'),
        file_name: fileName,
        file_type: 'folder',
        file_is_folder: 1,
        progress: sumProgress / files.length,
        done: false
      }))
    },
    getTopLevelFiles(files) {
      return files.filter(
        (f) =>
          this.getRelativePath(f)
            .replace(/^\/(.*?)/, '$1')
            .split(/\//).length === 1
      )
    },
    getRequiredFolders(files) {
      return _.uniq(
        files.map((f) =>
          this.getRelativePath(f)
            .replace(/^\/(.*?)/, '$1')
            .split('/')
            .slice(0, -1)
            .join('/')
        )
      )
    },
    buildFolderStructure(files) {
      return new Promise((resolve) => {
        const allPaths = this.getRequiredFolders(files)
        const structure = {}
        allPaths.forEach((path) => {
          const parts = path.split('/')
          let lastSet = structure
          parts.forEach((folderName) => {
            if (folderName && folderName !== '') {
              if (!(folderName in lastSet)) {
                lastSet[folderName] = {}
              }
              lastSet = lastSet[folderName]
            }
          })
        })

        // First set all parent_file_id to the directory we are in
        // which will be changed later if a multi-folder structure exists
        this.files = this.files.map((f) => ({
          ...f,
          parent_file_id: this.folder ? this.folder : this.tags.parent_file_id || null
        }))

        if (Object.keys(structure).length) {
          this.$store
            .dispatch('ajax', {
              path: 'file/buildFolderStructure',
              data: {
                ...this.tags,
                ...(this.folder ? { parent_file_id: this.folder } : {}),
                structure: JSON.stringify(structure)
              }
            })
            .then(({ set }) => {
              const structs = set.sort(
                (a, b) => b.fullPath.split('/').length - a.fullPath.split('/').length
              )

              const folderKeys = {}
              structs.forEach((folder) => {
                folderKeys[folder.fullPath] = folder.file_id
              })
              const folderIndexes = Object.keys(folderKeys)

              // match files to their new parent_file_ids:
              this.files = this.files.map((file) => {
                const newFile = file
                const pathWithoutFile = `${newFile.fullPath.split('/').slice(0, -1).join('/')}/`

                // Go until we find  a match
                folderIndexes.some((path) => {
                  if (pathWithoutFile === path) {
                    newFile.parent_file_id = folderKeys[path]
                    return true
                  }
                  return false
                })

                return newFile
              })

              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    addFiles(files) {
      let newFiles = [...files]
      if (!newFiles.length) return
      this.$emit(
        'rawFilesAdded',
        newFiles[0].fileObject ? newFiles.map((f) => f.fileObject) : files
      )
      if (this.skipUpload) return
      newFiles = newFiles.map((f) => ({
        ...c.buildDefaultObject('file'),
        file_name: f.name,
        file_size: f.size,
        file_type: f.type,
        fullPath: this.getRelativePath(f),
        progress: 0,
        done: false,
        original: f.fileObject || f,
        file_geolocation: f.geolocation || {}
      }))
      this.$emit('filesAdded', newFiles)
      this.files = [...this.files, ...newFiles]
      this.buildFolderStructure(this.files).then(() => {
        this.$nextTick(() => {
          this.uploadNext()
        })
      })
    }
  },
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    folder: {
      default: null
    },
    skipUpload: {
      default: false
    },
    buttonText: {
      default: 'Upload'
    },
    draggable: {
      default: true
    },
    showButton: {
      default: true
    },
    tags: {
      type: Object,
      default: () => ({})
    },
    grid: {
      required: false,
      default: false
    },
    placeholder: {
      default: 'Drop files'
    },
    hoverOn: {
      default: false
    }
  },
  components: { File, ProgressContainer, MiniModal, Camera: CameraComponent }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.hidden-input {
  display: none;
}

.file-upload-container {
  margin: 0 !important;
  padding: 0 !important;
  position: static;
}
.file-drop-watcher {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  pointer-events: none;
  align-items: center;
  justify-content: center;

  .file-drop-container {
    z-index: 10;
    position: absolute;
    opacity: 0;
    display: block;
    pointer-events: none;
    padding: 0.5em;
    margin: 0.5em;
    top: 0px;
    left: 0px;
    width: auto;
    height: auto;

    transition: opacity 0.5s;

    background: rgba($cool-gray-100, 0.9);
    border: 2px dashed $cool-gray-200;
    border-radius: 10px;

    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    user-select: none;

    box-shadow: 0px 0px 20px 15px rgba($pitch-black, 0.1);
    display: flex;
    width: calc(100% - 1em);
    height: calc(100% - 1em);
    min-width: 5em;
    min-height: 1em;
    align-items: center;
    justify-content: center;

    span.message {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 1.2em;
      font-weight: bold;
    }

    &.active {
      display: flex;
      opacity: 1;
      z-index: $z-layout + 1;

      .message {
        top: -5em;
      }
    }

    .removable {
      position: relative;

      .remove {
        opacity: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 40px;
        top: 15px;
        font-weight: bold;
        color: $deep-red-800;
        padding-bottom: 3px;
      }
      &:hover {
        cursor: pointer;
        .remove {
          opacity: 1;
        }
        .file {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }

  &.active {
    .file-drop-container {
      opacity: 1;
      pointer-events: none;
    }
  }
}
</style>
