import { computed, ref, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import AutoCost from '../../../imports/api/AutoCost.js'
import Debug from '@/components/Sheets/Debug.js'

export default {
  useCostItemNameDropdown(args, { emit }) {
    const { refSheet, importAddedItems, norm, setMeta, store, refId, searchPhrase } = args

    const { bench, startBench, debug } = Debug.useDebug()
    debug.value = true

    const showItemSelectorDropdown = computed(() => {
      const [col, row] = refSheet.value?.currentCell ?? [null, null]

      if (row === null) return false

      const rm = refSheet.value.rowsMap
      const nn = norm.value
      const fc = refSheet.value.cellFocused
      if (col === null || row === null || !rm[row]) return false

      const refId = rm[row].id
      const type = nn[refId]?.type
      const saved = nn[refId]?.cost_type_id
      const blankType = !nn[refId]?.oMeta?.itemType || nn[refId]?.oMeta?.itemType === 'costItem'
      return fc && col === 0 && type === 'cost_item' && !saved && blankType
    })

    const closeDrop = ref(false)
    const component = getCurrentInstance().proxy
    const itemSelectorDropdownStyle = computed(() => {
      const sh = refSheet.value
      const [col, row] = sh?.currentCell ?? [null, null]
      const forceClose = closeDrop.value
      if (row === null || forceClose) {
        return {
          display: 'none'
        }
      }

      const br = sh.getCellBoundingRect(col, row)
      const cl = sh.getClickedCellBoundingRect(col, row)

      const { x: left, y: top, width, height } = sh.cellFocused && sh.showOriginLabel ? cl : br
      if (!sh.showEditableDiv || width === 0 || height === 0 || !showItemSelectorDropdown.value) {
        return {
          display: 'none'
        }
      }

      const bannerOffset =
        (document.getElementById('estimate-toggle-banner')?.getBoundingClientRect()?.height || 0) +
        (document.getElementById('estimate-autocost-banner')?.getBoundingClientRect()?.height || 0)

      const scrollParent = c.getScrollParent(component.$el)
      const { height: parentHeight } = scrollParent.getBoundingClientRect()

      const y = top + height + bannerOffset
      const maxHeight = parentHeight - y

      return {
        position: 'absolute',
        top: `${y}px`,
        left: `${left}px`,
        width: `${width}px`,
        maxHeight: `${maxHeight}px`
      }
    })

    const forceCloseHandler = (e) => {
      if (e.target.closest('.item-name-drop') !== null) return
      closeDrop.value = true
      cantForce()
    }

    const added = ref(false)
    const cantForce = () => {
      window.removeEventListener('click', forceCloseHandler)
    }
    const canForce = () => {
      if (!added.value) window.addEventListener('click', forceCloseHandler)
    }

    watch(showItemSelectorDropdown, (show, before) => {
      // reset force close
      cantForce()
      if (show && !before) {
        closeDrop.value = false
        setTimeout(() => canForce(), 1000)
      }
    })

    const basicDropOptions = computed(() => {
      let text = refSheet.value.currentCellValue
      if (text?.length > 12) {
        text = `${text.slice(0, 12).trim()}...`
      }
      return [
        {
          icon: 'cube',
          label: `Cost item${text ? `: ${text}` : ''}`,
          desc: 'Just start typing to keep adding a new item.',
          hotkey: 'enter',
          action: 'newItem'
        },
        {
          icon: 'cubes',
          label: `Assembly${text ? `: ${text}` : ''}`,
          desc: 'Assemblies are templates/groups of items.',
          hotkey: 'cmd-enter',
          action: 'newAssembly'
        },

        {
          icon: 'box-open-full',
          label: 'Browse full item catalog',
          desc: 'Your saved items, assemblies and templates.',
          hotkey: 'cmd-shift',
          action: 'browseCatalog'
        },
        {
          icon: 'square-check',
          label: 'Task',
          desc: 'A simple assignable task, with no cost associated.',
          action: 'newTask',
          badgeSeverity: 'success'
        },
        {
          icon: 'align-left',
          label: `Text${text ? `: ${text}` : ''}`,
          desc: 'A block of text to display to your clients.',
          action: 'newText'
        },
        {
          icon: 'images',
          label: 'Image gallery',
          desc: 'Show off your skills with an image gallery.',
          action: 'newGallery'
        },
        {
          divider: true
        },
        {
          icon: 'box-open-full',
          label: 'More...',
          desc: 'Your saved items, assemblies and templates.',
          hotkey: 'cmd-shift',
          action: 'browseCatalog'
        }
      ]
    })

    const dropOptions = computed(() => {
      const basics = [...basicDropOptions.value]

      return [
        ...basics.slice(0, 7),
        ...inlineSearchResults.value.map((r) => {
          const files = c.makeArray(r.file_ids ?? [])
          const image =
            (files.length &&
              c.link(
                `file/view/${files[0]}`,
                { size: 200, square: true },
                true,
                _.getStorage('scope')
              )) ||
            null
          return {
            icon: r.type === 'cost_type' ? 'cube' : 'cubes',
            image,
            label: _.truncate(r.cost_type_name || r.assembly_name || '', { length: 25 }),
            desc: _.truncate(r.cost_type_desc || r.quote_notes || '', { length: 25 }),
            action: `${r.type}:${r.assembly_id || r.cost_type_id}`,
            badge:
              String(r.company_id) === String($store.state.session.company.company_id)
                ? 'Your catalog'
                : 'Bolster template',
            badgeSeverity: 'bolster'
          }
        }),
        ...basics.slice(7)
      ]
    })

    const addingProgress = ref(0)

    const handleItemDropOption = async (option) => {
      const [, row] = refSheet.value.currentCell
      const sh = refSheet.value
      closeDrop.value = true
      sh.cellFocused = false

      const [type, id] = option.action.split(':')
      switch (option.action) {
        case 'newItem':
          // do nothing, let it continue
          setMeta({ key: 'itemType', value: 'costItem', refId: sh.getRowId(row), row })
          break
        case 'newAssembly':
          sh.turnIntoAssembly([row])
          await c.throttle(() => {})
          sh.moveCell(0, row)
          sh.focusCell()
          setMeta({ key: 'itemType', value: 'assembly', refId: sh.getRowId(row), row })
          break
        case 'newTask':
          setMeta({ key: 'itemType', value: 'task', refId: sh.getRowId(row), row })
          break
        case 'newGallery':
          setMeta({ key: 'itemType', value: 'gallery', refId: sh.getRowId(row), row })
          break
        case 'newText':
          setMeta({ key: 'itemType', value: 'text', refId: sh.getRowId(row), row })
          break
        case 'browseAutoCost':
          openCatalog('autocost')
          break
        case 'browseCatalog':
          openCatalog()
          break
        default:
          addItems([{ type, [`${type}_id`]: id }])
          break
      }
    }

    const $store = useStore()
    const openCatalog = async (startAt) => {
      const mod = await $store.dispatch('Quote/getQuoteMod', {
        refId,
        store
      })
      const selected = await $store.dispatch('openItemSelector', {
        mod,
        startAt
      })
      if (selected?.length) addItems(selected)
    }

    const inlineSearch = async (phrase = '') => {
      const itemFilters = {}
      if (!$store.state.session.user || !$store.state.session.user.user_is_super_user) {
        itemFilters.cost_type_status = '!h&&!y&&!i'
        itemFilters.assembly_status = '!h&&!y&&!i'
      }
      let minScoreToUse = phrase.length * 20
      minScoreToUse = minScoreToUse >= 200 ? 200 : minScoreToUse
      const { set } = await $store.dispatch('CostType/search', {
        filters: itemFilters,
        minScore: minScoreToUse,
        searchPhrase: phrase,
        limit: 4,
        offset: 0
      })
      return set
    }

    const inlineSearchResults = ref([])
    watch(searchPhrase, () => {
      if (!showItemSelectorDropdown.value) return
      if (searchPhrase.value?.length > 0 && searchPhrase.value?.length < 3) return

      c.throttle(
        async () => {
          if (!showItemSelectorDropdown.value) return
          if (searchPhrase?.length > 0 && searchPhrase?.length < 3) return
          inlineSearchResults.value = await inlineSearch(searchPhrase.value)
        },
        {
          debounce: true,
          delay: 400
        }
      )
    })

    // Prime the list
    watch(showItemSelectorDropdown, async (a, b) => {
      if (!b && a && !inlineSearchResults.value.length)
        inlineSearchResults.value = await inlineSearch('')
    })

    const addItems = async (items, optional = 0, skipAudit = false) => {
      startBench('importAddedItems')
      bench('importAddedItems', JSON.stringify(items))
      // get already fetched ids - for addon loading
      const fetchedRefIds = await $store.dispatch('Quote/getQuoteRefIds', {})
      bench('importAddedItems', 'getQuoteRefIds')

      addingProgress.value = 0.15
      const [, row] = refSheet.value.currentCell
      const sh = refSheet.value
      const rowId = sh.getRowId(row)
      const parentId = sh.getRowParent(row)
      const children = sh.getChildren(parentId, sh.getRowSheet(row))
      const position = children.indexOf(rowId)
      refSheet.value.rowLoading = row

      const { addedChildrenRefIds } = await $store.dispatch(`${store}/addItems`, {
        items,
        parent: parentId,
        optional,
        skipAudit,
        position
      })
      addingProgress.value = 0.45
      bench('importAddedItems', 'addItems')

      try {
        // get all ids after adding new ones - for addon loading
        const quoteRefIds = await $store.dispatch('Quote/getQuoteRefIds', {})
        bench('importAddedItems', 'getQuoteRefIds 2')
        // in the last 10 minutes
        const unixTimestamp = Math.floor(Date.now() / 1000) - 600

        // get new ids to load their addons
        const newRefIds = quoteRefIds.filter((id) => !fetchedRefIds.includes(id))
        const livePriceRefIds = newRefIds.filter(
          (id) =>
            norm.value[id].live_price_reference &&
            norm.value[id].type === 'cost_item' &&
            norm.value[id].live_price_last_fetch < unixTimestamp
        )

        const livePriceLaborRefIds = newRefIds.filter(
          (id) =>
            norm.value[id].labor_type_id &&
            typeof norm.value[id].labor_type_id === 'string' &&
            AutoCost.isAutoCostLaborTypeId(norm.value[id].labor_type_id) &&
            norm.value[id].live_price_last_fetch < unixTimestamp
        )
        bench('importAddedItems', 'livePriceLaborRefIds 2')

        // load new items addons
        // $store.dispatch('Quote/recalcAddons', { refIds: newRefIds, loading: false })
        bench('importAddedItems', 'recalcAddons')

        if (livePriceRefIds && livePriceRefIds.length > 0) {
          // fetch AutoCosts
          $store.dispatch('Quote/fetchLivePricing', {
            refIds: livePriceRefIds,
            rootRefId: refId,
            reviewChanges: false
          })
        }
        bench('importAddedItems', 'livePriceRefIds')

        if (livePriceLaborRefIds && livePriceLaborRefIds.length > 0) {
          // fetch AutoCosts
          $store.dispatch('Quote/fetchLaborLivePricing', {
            refIds: livePriceLaborRefIds,
            rootRefId: refId,
            reviewChanges: false
          })
        }
        bench('importAddedItems', 'fetchLaborLivePricing')
        addingProgress.value = 0.6

        sh.deleteRows([row])
        bench('importAddedItems', 'deleteRows')
        await importAddedItems(addedChildrenRefIds, norm.value, row)
        // await sh.reinitialize();
        // sh.moveCell(0, row)
        bench('importAddedItems', 'moveCell')

        emit('addedChildren', { refIds: addedChildrenRefIds })

        return addedChildrenRefIds
      } catch (e) {
        $store.dispatch('alert', {
          message: e.userMessage || 'Something happened, please try again.',
          error: true
        })
        throw e
      } finally {
        setTimeout(() => (addingProgress.value = 0), 500)
        refSheet.value.rowLoading = null
      }
    }

    return {
      showItemSelectorDropdown,
      itemSelectorDropdownStyle,
      dropOptions,
      handleItemDropOption,
      addingProgress,
      inlineSearchResults
    }
  }
}
