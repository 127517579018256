<template>
  <div
    ref="root"
    :class="['relative', { 'h-full overflow-y-hidden': !list.length || loading || dropping }]"
  >
    <!-- Bolster catalog sticky banner -->
    <div v-if="inAutoCostCategory" class="sticky top-0 flex flex-col z-10">
      <!-- Location display -->
      <div class="flex flex-row items-center w-full *:gap-2 p-4 bg-flame-white border-b text-lg">
        <div
          v-if="$refs.root?.clientWidth > 700 || !livePriceLocation"
          class="flex flex-row items-center w-full"
        >
          <icon icon="svg:bolsterIconGrey" class="aspect-square h-4 p-0.5" />
          <div
            v-if="inAutoCostRootCategory"
            class="flex flex-row items-center w-full gap-2 text-lg"
          >
            <div>Bolster catalog</div>
            <Tag class="!border-blue-print !text-blue-print">New</Tag>
          </div>
          <div v-else-if="inAutoCostCategory">
            {{ autoCostCategoryText }}
          </div>
        </div>
        <div class="flex flex-col justify-end items-center w-full">
          <div v-if="livePriceLocation" class="flex flex-row items-center gap-2 w-full justify-end">
            <div
              v-tooltip="
                'Update your company or project zipcode to search AutoCost items elsewhere.'
              "
            >
              {{ livePriceLocation }}
            </div>
            <icon :icon="['fas', 'location-dot']" />
          </div>
          <div
            v-if="isSuper && inGlobalScope"
            class="flex flex-row items-center w-full justify-end gap-2"
          >
            <input
              v-model="customZipcode"
              placeholder="zip / postal code"
              class="rounded border border-surface-300 text-xs px-2 min-h-7 h-7 w-36"
            />
            <Btn severity="success" size="sm" @click="throttleFetch"> Apply </Btn>
          </div>
        </div>
      </div>
      <!-- Activate AutoCost subscription sticky banner -->
      <div
        v-if="!hasAutoCostSubscription && !inGlobalScope"
        class="flex flex-col lg:flex-row justify-between p-4 gap-2 bg-matcha-100 border-b border-matcha-500/30 text-md"
      >
        <div class="flex flex-row gap-2 items-center">
          <icon
            icon="svg:autocostWhite"
            class="bg-matcha-500 rounded-full aspect-square h-5 p-0.5 border border-surface-0"
          />
          AutoCost - Automatic, local pricing for 130,000+ construction materials.
        </div>
        <btn severity="tertiary" @click="goToAutoCostPage"> Upgrade now </btn>
      </div>
    </div>

    <!-- No supplier items found tag/alert -->
    <div
      v-if="inAutoCostCategory && !inAutoCostRootCategory && noSupplierItemsFound && list.length"
      class="absolute flex items-center right-0 mx-3 mt-3 py-2 px-4 gap-2 rounded-full bg-yellow-700/30 z-[1]"
      v-tooltip="
        'No supplier items could be found for this category. Increase the search distance, clear any filters, or adjust your search phrase.'
      "
    >
      <icon icon="magnifying-glass" class="text-lg text-yellow-900" />
      <p class="text-yellow-900">No supplier items found</p>
    </div>

    <!-- Top page selector -->
    <TraversePageSelector
      v-show="!dropping && !loading"
      :currentPage="currentPage"
      :pagesToDisplay="pagesToDisplay"
      :end="end"
      :total="total"
      @prevPage="prevPage"
      @nextPage="nextPage"
      @goToPage="(page) => goToPage(page)"
    />

    <!-- Item / assembly grid -->
    <div class="@container h-full w-full">
      <draggable
        v-show="!dropping && !loading"
        v-model="list"
        :group="{ name: 'myGroup', pull: false, put: false }"
        @start="onDragStart"
        @end="onDragEnd"
        :sort="false"
        :force-fallback="true"
        :disabled="
          (filters.company_id &&
            filters.company_id !== $store.state.session.scope.company &&
            !isSuper) ||
          !selected.length ||
          inAutoCostCategory
        "
        class="grid grid-cols-1 @[600px]:grid-cols-2 @[750px]:grid-cols-3 @[950px]:grid-cols-4 @[1400px]:grid-cols-5 @[1600px]:grid-cols-6 justify-start gap-3 w-full h-full p-4"
      >
        <template #item="{ element }">
          <TraverseListItem
            :object="element"
            :showHint="false"
            :selected="isSelected(element)"
            :modal="modal"
            @click="() => $emit('select', element)"
          />
        </template>
      </draggable>
    </div>

    <!-- Nothing found -->
    <div
      v-if="!list.length && !loading"
      class="flex flex-col justify-center items-center gap-y-6 h-full"
    >
      <font-awesome-icon icon="magnifying-glass" class="text-8xl text-surface-200" />
      <h1>Nothing found.</h1>
      <div
        v-if="inAutoCostCategory"
        class="flex flex-col justify center items-center text-center gap-4 mx-4 md:max-w-[50%]"
      >
        <p class="text-pitch-black">
          We couldn't find any results
          {{
            this.autocostFilters.distance
              ? `within ${this.autocostFilters.distance} of ${this.zipcode}`
              : `around ${this.zipcode}`
          }}. Increase the search distance, clear any filters,
          {{ inAutoCostRootCategory ? 'or' : '' }} adjust your search phrase{{
            inAutoCostRootCategory ? '.' : ', or:'
          }}
        </p>
        <Btn
          v-if="!inAutoCostRootCategory"
          class="w-fit px-16"
          severity="tertiary"
          @click="$emit('setParent', 'autocost')"
        >
          Search the entire Bolster catalog
        </Btn>
      </div>
    </div>

    <div
      v-show="dropping && !loading"
      class="flex flex-col justify-center items-center mt-64 gap-y-4 h-full animate-fadeIn"
    >
      <span class="text-2xl font-medium">Organizing your catalog...</span>
      <p>This may take a moment.</p>
    </div>

    <Loader :loading="loading"></Loader>

    <!-- Bottom page selector -->
    <TraversePageSelector
      v-show="!dropping && !loading"
      :currentPage="currentPage"
      :pagesToDisplay="pagesToDisplay"
      :end="end"
      :total="total"
      @prevPage="prevPage"
      @nextPage="nextPage"
      @goToPage="(page) => goToPage(page)"
    />
  </div>
</template>

<script>
import UserMeta from '../../mixins/UserMeta'
import UserError from '../../../../imports/api/UserError'
import TraverseListItem from './TraverseListItem.vue'
import TraversePageSelector from './TraversePageSelector.vue'
import Button from '../../mixins/Button'
import draggable from 'vuedraggable'
import eventBus from '@/eventBus'
import AutoCost from '../../../../imports/api/AutoCost'

export default {
  name: 'TraverseList',

  components: {
    TraverseListItem,
    TraversePageSelector,
    draggable
  },

  mixins: [UserMeta, Button],

  emits: ['select', 'aggregations', 'empty'],

  async mounted() {
    this.fetch()
    this.zipcode = await this.getZipcode()
  },

  data() {
    return {
      list: [],
      offset: 0,
      end: 0,
      total: 0,
      dropping: false,
      zipcode: null,
      customZipcode: ''
    }
  },

  computed: {
    currentPage() {
      return c.divide(this.offset, this.limit)
    },
    pagesToDisplay() {
      // Just show a single page in the root autocost category
      if (AutoCost.isAutoCostRootCategory(this.filters.parent_cost_type_id) && !this.searchPhrase) {
        return _.range(0, 1, 1)
      }

      // Don't show anything if at the first page and nothing was found
      if (!this.offset && !this.total) {
        return _.range(0, 0, 1)
      }

      // Otherwise compute the start and end
      else {
        const start = this.currentPage >= 1 ? this.currentPage - 1 : this.currentPage
        const end = !this.end ? this.currentPage + 2 : this.currentPage + 1
        return _.range(start, end, 1)
      }
    },
    selectedIndexes() {
      return this.selected.reduce(
        (acc, obj) => [...acc, `${obj.type}-${obj[`${obj.type}_id`]}`],
        []
      )
    },
    hasAutoCostSubscription() {
      return AutoCost.hasAutoCostSubscription(this.$store.state.session.company)
    },
    inAutoCostCategory() {
      return AutoCost.isAutoCostCategory(this.filters.parent_cost_type_id)
    },
    inAutoCostRootCategory() {
      return AutoCost.isAutoCostRootCategory(this.filters.parent_cost_type_id)
    },
    autoCostCategoryText() {
      if (!AutoCost.isAutoCostCategory(this.filters.parent_cost_type_id)) return ''
      return this.filters.parent_cost_type_id.replace('autocost', 'Bolster catalog')
    },
    autocostCountries() {
      return AutoCost.getAutoCostCountries(this.$store.state.session.company)
    },
    inGlobalScope() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.scope.company
      )
    },
    isSuper() {
      const session = this.$store.state.session
      return session.authorizedUser && session.authorizedUser.user_is_super_user
    },
    isShared() {
      return (
        (this.filters.company_id &&
          this.$store.state.session.scope.company !== this.filters.company_id) ||
        this.$store.state.session.scope.franchisor
      )
    },
    isFranchisor() {
      return !!this.$store.state.session.scope.franchisor
    },
    livePriceLocation() {
      if (this.autocostFilters.distance)
        return `Within ${this.autocostFilters.distance} of ${this.zipcode}`
      else return `${this.zipcode}`
    },
    noSupplierItemsFound() {
      const hasFilters = this.autocostFilters.vendor.length
      const supplierFilters = this.autocostFilters.vendor.filter((vendor) =>
        AutoCost.suppliers.includes(vendor)
      ).length
      return (
        !AutoCost.hasAutoCostSupplierObjects(this.list) &&
        (!hasFilters || (hasFilters && supplierFilters))
      )
    }
  },

  watch: {
    searchPhrase(a, b) {
      if (a !== b) this.throttleFetch()
    },
    offset(a, b) {
      if (a !== b) this.fetch()
    },
    filters(a, b) {
      if (!c.jsonEquals(a, b)) this.throttleFetch()
    }
  },

  methods: {
    async reload() {
      return this.fetch()
    },
    async nextPage() {
      if (this.end || !this.total) return
      await this.goToPage(this.currentPage + 1)
    },
    async prevPage() {
      await this.goToPage(Math.max(0, this.currentPage - 1))
    },
    async goToPage(page) {
      this.offset = page * this.limit
    },
    async throttleFetch() {
      this.addLoading()
      await c.throttle(
        () => {
          this.offset = 0
          this.fetch()
        },
        { delay: 500 }
      )
      this.removeLoading()
    },
    async scrollToTop() {
      return c.scrollTo(this.$refs.blankContainer)
    },
    async getZipcode() {
      // Check for custom zipcode (only visible in superuser global scope)
      if (this.isSuper && this.inGlobalScope && this.customZipcode) return this.customZipcode

      // Get AutoCost zipcode
      const rootRefId = await this.$store.dispatch('Quote/getRootRefId', {})
      const quote = this.$store.state.Quote?.normalized[rootRefId]
      const company = this.$store.state.session.company
      return AutoCost.getAutoCostZipcode(company, quote)
    },
    async fetch() {
      if (this.inAutoCostCategory) this.fetchFromAutoCost()
      else this.fetchFromLibrary()
    },
    async fetchFromLibrary() {
      this.addLoading()

      let itemFilters = _.imm(this.filters)
      if (
        itemFilters.parent_cost_type_id &&
        !/NULL|>|<|!|\|\|/.test(itemFilters.parent_cost_type_id) &&
        this.searchPhrase.trim()
      ) {
        // Adding the tilde will search for every item inside every category
        // that is a sub, sub-sub or sub-sub-sub... etc category of the
        // parent_cost_type_id provided. Only do this when searching items.
        // To maintain category hierarchy, the subdirectories/categories below
        // need to be searched literally the parent-child relationship.
        itemFilters.parent_cost_type_id = `~${itemFilters.parent_cost_type_id}`
      }

      if (/NULL/.test(itemFilters.parent_cost_type_id) && this.searchPhrase.trim()) {
        itemFilters = _.omit(itemFilters, 'parent_cost_type_id')
      }

      if (!this.$store.state.session.user || !this.$store.state.session.user.user_is_super_user) {
        itemFilters.cost_type_status = '!h&&!y&&!i'
        itemFilters.assembly_status = '!h&&!y&&!i'
      }

      // If searching in the root of the shared catalog as a franchisee, don't search for items,
      // only categories. This keeps uncategorized items only visible to the franchisor.
      if (this.isShared && !this.isFranchisor && this.filters.parent_cost_type_id === 'NULL') {
        itemFilters.cost_type_id = 'NULL'
      }

      let minScoreToUse = this.searchPhrase.length * 20
      minScoreToUse = minScoreToUse >= 200 ? 200 : minScoreToUse
      const { set, aggregations, total } = await this.$store.dispatch('CostType/search', {
        filters: itemFilters,
        minScore: this.searchPhrase ? minScoreToUse : 0,
        searchPhrase: this.searchPhrase,
        limit: this.limit,
        offset: this.offset,
        aggregations: this.aggregations
      })
      if (set.length) {
        this.$emit('aggregations', aggregations)
      }
      this.total = total
      this.end = set.length < this.limit
      this.list = set

      // Categories (cost_type parents)
      if (!this.searchPhrase && itemFilters.parent_cost_type_id) {
        // Show all downstream if empty
        itemFilters.parent_cost_type_id = `${itemFilters.parent_cost_type_id}`
        this.$emit('empty')
        const {
          set: subcatset,
          aggregations: subagg,
          total: subtotal
        } = await this.$store.dispatch('CostType/search', {
          filters: {
            company_id: this.filters?.company_id,
            parent_cost_type_id: itemFilters.parent_cost_type_id,
            cost_type_is_parent: 1,
            asSharedCompanies:
              this.isShared && !this.isFranchisor
                ? `INSET${this.$store.state.session.company.company_id}`
                : ''
          },
          limit: this.limit,
          offset: this.offset,
          aggregations: this.aggregations
        })
        if (subcatset.length) {
          this.$emit('aggregations', subagg)
        }
        this.total += subtotal
        this.end = set.length + subcatset.length < this.limit
        this.list = [...this.list, ...subcatset]
      } else if (!set.length && !this.searchPhrase) {
        this.$emit('empty')
      }
      this.scrollToTop()
      this.removeLoading()
    },
    async fetchFromAutoCost() {
      this.addLoading()

      try {
        this.zipcode = await this.getZipcode()
        if (!this.zipcode) {
          throw new UserError({ userMessage: 'Could not complete search, no zipcode set.' })
        }

        this.list = []
        this.total = 0

        // Only show custom items / assemblies when no vendor filters are applied
        if (!this.autocostFilters?.vendor?.length) {
          // Fetch custom items and assemblies
          const customItems = await this.$store.dispatch('Assembly/search', {
            filters: {
              assembly_country: this.autocostCountries,
              company_id: null,
              ...(this.searchPhrase
                ? { assembly_name: `LIKE${this.searchPhrase}` }
                : { parent_cost_type_id: this.filters.parent_cost_type_id })
            },
            offset: this.offset,
            limit: this.limit
          })
          this.list = [
            ...this.list,
            ...customItems.set
              .filter((item) => AutoCost.isAutoCostCategory(item.parent_cost_type_id))
              .sort((a, b) => !!a.assembly_country - !!b.assembly_country)
          ]
        }

        // Only fetch categories on the first page, as long as no search phrase is provided
        if (this.offset === 0 && !this.searchPhrase) {
          // Fetch categories
          const categories = await AutoCost.getAutoCostCategories(
            this.$store,
            this.filters.parent_cost_type_id
          )
          this.list = [...this.list, ...categories]
          if (AutoCost.isAutoCostRootCategory(this.filters.parent_cost_type_id)) this.end = true
          this.total += categories.length
        }

        // Don't fetch items in root autocost category, unless a search phrase is provided
        if (
          !AutoCost.isAutoCostRootCategory(this.filters.parent_cost_type_id) ||
          this.searchPhrase
        ) {
          // Fetch items
          const items = await this.$store.dispatch('ajax', {
            path: 'live_price/searchLivePriceItems',
            data: {
              search_term: this.searchPhrase,
              category_path: this.filters.parent_cost_type_id,
              zipcode: this.zipcode,
              filters: this.autocostFilters,
              offset: this.offset,
              limit: this.limit
            }
          })
          this.list = [...this.list, ...items.payload.items]
          this.end = !items.payload.hasNextPage
          this.total += items.payload.items.length
        }
        if (!this.list.length) this.end = true
      } catch (e) {
        console.error(e)
      }
      this.removeLoading()
    },
    isSelected(object) {
      const type = object.type
      const idField = `${type}_id`
      const index = `${type}-${object[idField]}`
      return this.selectedIndexes.includes(index)
    },
    /**
     * onDragStart
     * Called when a list element starts to be dragged.
     */
    onDragStart() {
      eventBus.$emit('traverse-drag', true)
    },
    /**
     * onDragEnd
     * Called when a list element is dropped.
     */
    async onDragEnd(event) {
      const category = event.originalEvent.target.id
      if (category && this.selected.length) await this.updateCostItemCategories(category)
      eventBus.$emit('traverse-drag', false)
    },
    /**
     * updateCostItemCategories
     * Update the currently selected list elements to the given category.
     * Ie. Update the parent_cost_type_id of each item / assembly.
     */
    async updateCostItemCategories(category) {
      if (category === 'NULL') category = null
      const items = this.selected.filter(
        (element) => element.type === 'cost_type' && !element.cost_type_is_parent
      )
      const assemblies = this.selected.filter((element) => element.type === 'assembly')
      this.dropping = true
      if (items.length) {
        await this.$store.dispatch('CostType/partialUpdate', {
          selected: items.map((item) => ({
            type: 'cost_type',
            cost_type_id: item.id,
            parent_cost_type_id: category
          }))
        })
      }
      if (assemblies.length) {
        await this.$store.dispatch('Assembly/partialUpdate', {
          selected: assemblies.map((assembly) => ({
            type: 'assembly',
            assembly_id: assembly.id,
            parent_cost_type_id: category
          }))
        })
      }
      this.$emit('dropped')
      await this.$nextTick()
      await this.throttleFetch()
      c.throttle(() => (this.dropping = false), { delay: 1000 })
    },
    async goToAutoCostPage() {
      if (this.modal) await this.modal.close()
      this.$router.push({ name: 'AutoCost' })
    }
  },

  props: {
    modal: {
      required: false,
      default: null
    },
    filters: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },

    autocostFilters: {
      type: Object,
      required: false
    },

    aggregations: {
      type: Array,
      default: () => []
    },

    limit: {
      default: 50
    },

    searchPhrase: {
      default: ''
    },

    selected: {
      default: () => []
    },
    autocostVendors: {
      default: () => {}
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
// Needed to override the browser's default drag/drop styling
.sortable-chosen {
  padding: 0 !important;
  margin: 0 !important;
  zoom: 1 !important;
  border-radius: 0 !important;
  opacity: 100%;
  background: none;
}
</style>
