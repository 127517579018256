<template>
  <div class="relative" :class="{ hidden: !showItem && !isInQuoteEditor }">
    <div
      class="relative flex flex-col bg-flame-white mb-1"
      :class="{ 'opacity-50': isInQuoteEditor && !showItem }"
    >
      <!--   Item flag   -->
      <div
        v-if="flagType"
        class="absolute flex items-center gap-4 -left-4 xl:-left-5 top-6"
        :class="{ hidden: disabled, 'opacity-50': !showItem }"
      >
        <CostItemFlag :type="flagType" />
        <div class="flex gap-2" :class="[showItem ? itemStatusClass : 'text-gray-600']">
          <font-awesome-icon :icon="['fas', itemStatusIcon]" fixed-width />
          <span class="text-sm">{{ itemStatus }}</span>
        </div>
      </div>
      <div
        class="border-2 border-transparent min-h-32"
        :class="{ 'min-h-32': !isGallery && !isTextBlock }"
      >
        <div
          class="h-full flex flex-col-reverse md:flex-row justify-between selection-list-item p-4 md:p-6 gap-6"
          :class="{
            'opacity-30 pointer-events-none': disabled,
            'min-h-32': !isGallery && !isTextBlock
          }"
        >
          <div class="flex flex-col">
            <!--    Item name    -->
            <div
              class="w-full flex flex-col justify-center h-full"
              :class="{
                'md:mt-12': flagType && !isGallery && !isTextBlock,
                'mt-16': flagType && addons && addons.length
              }"
            >
              <div
                v-if="name"
                class="flex items-center text-lg md:text-xl font-medium md:font-normal gap-2"
              >
                <StringField :disabled="!isInQuoteEditor" class="mr-1" v-model="name" />
                <template v-if="optional && !variation">
                  <span v-if="included" class="font-light text-sm">({{ $t('Included') }})</span>
                  <span v-else class="font-light text-sm">({{ $t('Not included') }})</span>
                </template>
              </div>
              <!--    Quantity and price    -->
              <p v-if="showQuantityAndPrice">
                <span class="text-lg">
                  <template v-if="showQty">
                    {{ $f.number(qty) }} <span class="lowercase">{{ units }}</span>
                  </template>
                  <template v-if="showPrice && showQty"> &nbsp;•&nbsp; </template>
                  <template v-if="showPrice">{{
                    price ? `$ ${$f.$(price)}` : $t('Included')
                  }}</template>
                </span>
              </p>
              <!--     Item description     -->
              <div v-if="desc" class="text-base font-normal md:font-light py-4 text-surface-700">
                <StringField v-model="desc" :disabled="!isInQuoteEditor">
                  {{ desc }}
                  <PresentationProperties :object="object" />
                </StringField>
                <div v-if="itemProperties.length" class="py-4">
                  <ul class="list-disc pl-5">
                    <li v-for="([attribute, value], index) in itemProperties" :key="index">
                      <span v-if="attribute" class="text-pitch-black">{{ attribute }}:</span>
                      <span v-if="value" class="text-surface-400" :class="{ 'ml-2': attribute }">{{
                        value
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--     Selections/Variations     -->
              <template v-if="showOptionality">
                <div class="pt-4" v-if="variation">
                  <a v-if="showVariationSelector" class="see-available">
                    <Btn class="w-full md:w-52 py-2" severity="tertiary" @click="openVariations()">
                      {{ $t('View options') }}
                    </Btn>
                  </a>
                </div>
                <!--     Optional       -->
                <div
                  class="flex gap-6 justify-between mt-2 w-full md:w-fit"
                  v-if="optional && !variation"
                >
                  <Btn
                    class="w-full md:w-52 py-2"
                    :class="{ active: included }"
                    severity="tertiary"
                    @click="showOptionalModal"
                  >
                    <span v-if="!included">{{ $t('Add to estimate') }}</span>
                    <span v-if="included">{{ $t('Remove from estimate') }}</span>
                  </Btn>
                </div>
                <!--        Color input        -->
                <div class="optional-toggle mt-6" v-if="input && colorInput">
                  <a @click="() => $refs.selectionColor.openSelector()">
                    <span class="toggle-title"> Color </span>
                    <span class="toggle-price">
                      <SelectionColor
                        ref="selectionColor"
                        :allow-deselect="false"
                        class="compact text-sm"
                        :value="colorInputData"
                      />
                    </span>
                  </a>
                </div>
                <!--        Text input       -->
                <div v-if="input && textInput" class="mt-4 mb-2">
                  <div class="toggle-title">
                    {{ textInputMessage }}
                  </div>
                </div>
                <div class="optional-toggle" v-if="input && textInput">
                  <Field
                    :class="{ 'validate-invalid': !textInputValue }"
                    class="w-full h-12 rounded-md"
                    v-model="textInputValue"
                    placeholder="Type here..."
                    :validate="{ required: textInputRequired }"
                  />
                </div>
              </template>
            </div>
          </div>
          <!--    Image container    -->
          <div
            class="flex !items-center min-h-full md:mt-0"
            :class="{ 'filter grayscale': isInQuoteEditor && !showItem, 'mt-4 md:mt-0': flagType }"
            v-if="!(addons && addons.length) && !isGallery"
          >
            <div
              class="rounded-lg min-h-48 h-48 min-w-72 max-w-72 overflow-hidden"
              :class="{ 'mt-14': flagType }"
              v-if="itemPictures.length || images.length || fileUrls.length"
            >
              <Image
                preview
                :src="fileUrls[0]"
                image-class="object-cover cursor-pointer rounded-lg mix-blend-multiply min-h-48"
                width="296"
                height="196"
              />
            </div>
          </div>
        </div>
        <div v-if="isGallery" class="p-3">
          <ItemGallery
            v-if="itemPictures.length || images.length || fileUrls.length"
            :images="fileUrls"
            :pt="{ indicators: 'hidden md:flex flex-row justify-center flex-wrap' }"
          />
        </div>
        <!--   Upgrades/Addons   -->
        <div
          class="optional-toggle w-full flex-col p-4 md:p-6 pt-2 bg-flame-white"
          v-if="showOptionality && addons && addons.length && included && !disabled"
          :class="{
            disabled,
            'mt-3': optional,
            'filter grayscale ': isInQuoteEditor && !showItem
          }"
        >
          <QuotePresentationAddonList
            class=""
            :title="false"
            :reference="object.refId"
            :optionals="false"
            :dimensions="dimensions"
            :showCurrent="true"
            :upgrades="true"
            :showHeadline="false"
            :value="addons"
          />
        </div>
      </div>

      <modal
        ref="variations"
        v-if="showOptionality"
        class="modal-mini"
        :scrollable="true"
        :full="true"
        :clickAway="true"
        :expandable="false"
        :pt="{
          header: 'border-b'
        }"
      >
        <template #header>Make your selections</template>
        <template #body>
          <div class="selection-body h-full w-full flex justify-center overflow-hidden">
            <div class="selection-container flex flex-col w-full max-w-screen-xl">
              <div class="selection-container--header pt-8">
                <small class="text-sm text-surface-500">
                  {{ this.object.asAssemblyPath[this.object.asAssemblyPath.length - 2] }}
                </small>
                <div class="header--item-meta flex justify-between">
                  <p class="text-xl font-medium">
                    {{ this.name }}
                  </p>
                  <p class="text-xl font-medium">
                    <span v-if="showQty">
                      {{ $f.number(qty) }} <span class="lowercase">{{ units }}</span>
                    </span>
                    <span v-if="showQty && showPrice" class="text-surface-400">&nbsp;•&nbsp;</span>
                    <span v-if="showPrice">{{ formatCurrencySymbol(price, 2) }}</span>
                  </p>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-5 gap-6 mt-6">
                <!--   Left     -->
                <div class="flex flex-col justify-start items-start col-span-2 max-w-full">
                  <!--    Image gallery    -->
                  <div v-if="variationImages.length" class="overflow-hidden w-full">
                    <Galleria
                      :images="variationImages"
                      numVisible="6"
                      thumbnailsPosition="bottom"
                      hideArrows="true"
                      :pt="{
                        thumbnailContainer: 'w-fit pt-2',
                        thumbnailItem: 'overflow-hidden pr-2'
                      }"
                    />
                  </div>
                  <div
                    v-else
                    class="flex justify-center items-center rounded-md w-full bg-surface-200 h-[350px]"
                  >
                    <font-awesome-icon :icon="['fas', 'cube']" class="text-7xl text-surface-300" />
                  </div>

                  <!--    Description    -->
                  <p class="text-base font-light pt-8 text-surface-700">
                    {{ desc }}
                  </p>

                  <!--     CTA     -->
                  <div
                    class="flex flex-col md:flex-row gap-6 justify-center md:justify-between mt-[28px] w-full"
                  >
                    <Btn
                      class="w-full md:w-1/2 p-4"
                      severity="bolster"
                      size="item-price"
                      @click="closeVariations()"
                    >
                      Confirm selections
                    </Btn>
                    <Btn
                      class="w-full md:w-1/2 p-4"
                      severity="tertiary"
                      :size="'item-price'"
                      @click="closeVariations()"
                    >
                      Cancel
                    </Btn>
                  </div>
                </div>

                <!--    Right    -->
                <div class="col-span-3">
                  <CostItemVariationSelector
                    v-bind="$props"
                    :reference="refId"
                    :store="store"
                    :deselectOnDestroy="false"
                    :object="object"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!--  Hidden item banner  -->
    <div
      class="absolute text-pitch-black inset-0 flex items-center justify-center"
      :class="{
        'block !opacity-100': isInQuoteEditor && !showItem,
        hidden: (!isInQuoteEditor && !showItem) || showItem
      }"
    >
      <p class="bg-surface-400 text-flame-white p-4 shadow rounded">
        <font-awesome-icon :icon="['fas', 'eye-slash']" class="mr-1" />
        Item hidden from the client
      </p>
    </div>
    <!-- Visibility controls -->
    <VisibilityControls :isInQuoteEditor="isInQuoteEditor" :object="object" />
  </div>
</template>

<script>
import { getCurrentInstance, computed, ref, watch } from 'vue'
import TranslationMixin from './languages/TranslationMixin'
import CostItemVariationSelector from '../item/CostItemVariationSelector.vue'
import ItemInputsMixin from '../item/ItemInputsMixin'
import SelectionColor from '../../ui/SelectionColor/SelectionColor.vue'
import QuotePresentationAddonList from './QuotePresentationAddonList.vue'
import ItemPicturesMixin from './ItemPicturesMixin'
import PresentationProperties from './PresentationProperties.vue'
import CostItemFlag from '@/components/quote/presentation/CostItemFlag.vue'
import SelectionItemMixin from '@/components/quote/presentation/SelectionItemMixin'
import useItemReviewal from '@/components/composables/ItemReviewal'
import StringField from '@/components/ui/Calculator/StringField.vue'
import ItemGallery from '@/components/quote/presentation/ItemGallery.vue'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import Image from 'primevue/image'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'
import ItemVariations from '@/components/composables/ItemVariations'
import VisibilityControls from '@/components/quote/presentation/VisibilityControls.vue'

export default {
  name: 'SelectionsItem',
  mixins: [TranslationMixin, ItemInputsMixin, SelectionItemMixin, ItemPicturesMixin],
  data() {
    return {
      fullRemoval: [],
      notOptional: [],
      showVisOptions: 0,
      visoptions: [
        {
          key: 'price',
          name: 'Price',
          desc: 'If hidden, the price will be hidden from the buyer.'
        },
        {
          key: 'qty',
          name: 'Quantity',
          desc: 'If hidden, the quantity will be hidden from the buyer.'
        }
      ],
      assemblyEmphasis: 0
    }
  },
  watch: {},
  computed: {
    name: {
      get() {
        // If the item is a selection it could have an option group name
        const groupName = this.object.oMeta?.optionGroupName
        const itemName = this.isAssembly ? this.object.assembly_name : this.object.cost_type_name
        // groupName should override itemName if it exists
        return groupName || itemName
      },
      set(value) {
        // If the item is a selection it could have an option group name
        const hasGroupName = this.object.oMeta?.optionGroupName
        const itemNameField = this.isAssembly ? 'assembly_name' : 'cost_type_name'
        const fieldName = hasGroupName ? 'oMeta' : itemNameField
        const nameValue = hasGroupName ? { ...this.object.oMeta, optionGroupName: value } : value
        this.setField(fieldName, nameValue)
      }
    },
    desc: {
      get() {
        // If the item is a selection it could have an option group desc
        const groupDesc = this.object.oMeta?.optionGroupDesc
        const itemDesc = this.object.cost_type_desc || this.object.quote_notes || ''
        // groupDesc should override itemDesc if it exists
        return groupDesc || itemDesc
      },
      set(value) {
        // If the item is a selection it could have an option group desc
        const hasGroupDesc = this.object.oMeta?.optionGroupDesc
        const fieldName = hasGroupDesc ? 'oMeta' : 'cost_type_desc'
        const descValue = hasGroupDesc ? { ...this.object.oMeta, optionGroupDesc: value } : value
        this.setField(fieldName, descValue)
      }
    },
    visibility() {
      return (
        (this.object.oViewOptions && this.object.oViewOptions.pres) || {
          price: 1,
          qty: 1,
          isVisible: 1
        }
      )
    },
    showPrice() {
      const inheritedVisibility =
        this.object.cost_item_show_itemized_prices ||
        this.object.quote_show_itemized_prices ||
        this.presentationSettings.forceTemplateSettings

      return (
        (this.visibility.price == null &&
          inheritedVisibility &&
          this.presentationSettings.showCostItemPrices &&
          this.presentationSettings.showItemizedPrices) ||
        this.visibility.price
      )
    },
    showQty() {
      const inheritedVisibility = this.presentationSettings.showQuantities

      return (this.visibility.qty == null && inheritedVisibility) || this.visibility.qty
    },
    showItem() {
      return this.visibility.isVisible
    },
    // if all variations are locked or hidden, selector should not be shown
    showVariationSelector() {
      if (!this.object) return true
      const isCompanyUser = this.$store.getters.isCompanyUser
      const isInternalSelection = (this.object?.oMeta?.selectionAudience || 'client') === 'company'
      if (isInternalSelection && !isCompanyUser) return false

      // filter through variation types to see if one is not hidden or locked
      let response = false
      Object.values(this.object.oVariations?.variationTypes || []).forEach((variationType) => {
        // if response is already true or variationTypeOptions does not exist - skip
        if (response) return

        // if a variationType is not locked or hidden change response to show selector
        if (!variationType.hidden && !variationType.locked) {
          response = true
        }
      })
      return response
    },
    flagType() {
      if (this.optional) return 'Optional'
      if ((this.addons && this.addons.length) || this.variation) return 'Selections'
      return null
    },
    showQuantityAndPrice() {
      return (
        this.qty &&
        (this.showQty || this.showPrice) &&
        this.included &&
        !this.isTextBlock &&
        !this.isGallery
      )
    },
    itemProperties() {
      return this.object.aoProperties.filter((property) => {
        const [attribute, value] = property
        return attribute.trim() !== '' || value.trim() !== ''
      })
    }
  },
  methods: {
    formatCurrencySymbol,
    previewImage() {
      this.$refs.gallery.preview(this.fileUrls[0])
    },
    modifyAddons(addons) {
      if (!this.$store.getters.isGuestUser) {
        this.setField('aoAddons', addons)
      }
    },
    async showOptionalModal() {
      await this.$store.dispatch('Quote/openOptionalModal', {
        itemName: this.name,
        description: this.desc,
        object: this.object,
        thumbs: this.fileUrls,
        properties: this.properties,
        refId: this.object.refId,
        go: false
      })
    }
  },
  components: {
    PresentationProperties,
    QuotePresentationAddonList,
    SelectionColor,
    CostItemVariationSelector,
    CostItemFlag,
    StringField,
    Galleria,
    ItemGallery,
    Image,
    VisibilityControls
  },
  props: {
    showOptionality: {
      default: true
    },
    maxHeight: {
      default: '30em'
    },
    disabled: {
      default: false
    },
    presentationSettings: {
      type: Object
    },
    isInQuoteEditor: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { reviewalItems } = useItemReviewal()
    const instance = getCurrentInstance()
    const refId = computed(() => props.refId)
    const store = computed(() => props.store)

    const { selectedVariationTraitImages, object: rObject } = ItemVariations.useItemVariations({
      refId,
      store,
      object: instance.proxy.object,
      type: 'CostItem'
    })

    const galleryActiveIndex = ref(0)
    const itemReviewal = computed(() =>
      reviewalItems.value.find((reviewal) => reviewal.itemId === instance.proxy.object.item_id)
    )
    const isItemViewed = computed(() => {
      return itemReviewal.value && itemReviewal.value.isReviewed
    })
    const itemStatus = computed(() => {
      return isItemViewed.value ? 'Viewed' : 'Not viewed'
    })
    const itemStatusIcon = computed(() => {
      return isItemViewed.value ? 'circle-check' : 'eye'
    })
    const itemStatusClass = computed(() => {
      return isItemViewed.value ? 'text-gray-400' : 'text-red-700'
    })
    const itemImages = computed(() => {
      let aoImages = []
      let fileIds = []

      if (rObject.value?.aoImages) {
        aoImages = rObject.value.aoImages.map((image) => {
          if (rObject.value.live_price_reference) {
            return `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${image}`
          }
          return image.file_url
        })
      }
      if (rObject.value?.file_ids) {
        fileIds = rObject.value?.file_ids.map((id) =>
          c.link(`file/view/${id}`, {}, true, _.getStorage('scope'))
        )
      }

      return [...aoImages, ...fileIds]
    })
    const variationImages = computed(() => [
      ...(itemImages.value || []),
      ...(selectedVariationTraitImages.value || [])
    ])

    watch(variationImages, () => (galleryActiveIndex.value = 0))

    return {
      galleryActiveIndex,
      itemReviewal,
      isItemViewed,
      itemStatus,
      itemStatusIcon,
      itemStatusClass,
      variationImages,
      itemImages
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.selection-list-item {
  &:not(:last-child) {
    padding-bottom: 0;
  }
}
.selection-list-item + .selection-list-item {
  border-top: 1px solid $cool-gray-400;
}

.selection-graphic,
.selection-text {
  @media (max-width: 576px) {
    max-width: 100vw;
  }
  @media (max-width: 768px) {
    max-width: 100vw;
  }
}

.selection-text {
  flex: 2;
}

@media (max-width: 992px) {
  .selection-text {
    flex: 2.5;
  }
}

.selection-list-item {
  @media (max-width: 768px) {
    > div {
      flex-direction: column;
    }
  }
}

#app.small-format {
  .selection-list-item {
    .selection-graphic {
      text-align: center;
      margin: 0 auto;
      max-width: 100vw;
    }
    .selection-text {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}
</style>
