<template>
  <div class="">
    <ScrollTrigger
      v-if="scroll"
      @in="() => scrollToSelected()"
      :disableOnTrigger="true"
      :showLoading="false"
      :checkOnMount="true"
      ref="scrollTrigger"
      style="height: 1px"
    />
    <div class="variation-selector--type" v-for="type in visibleVariationTypes" :key="type.name">
      <div class="flex items-center min-w-full w-full">
        <Accordion class="min-w-full w-full" :multiple="true" :active-index="[0, 1]">
          <AccordionTab :noBorder="true" :noPadding="true">
            <template #header>
              <div class="flex justify-start items-center pl-2 gap-6">
                {{
                  `${type.name}${variations?.selectedItem?.variations?.[type.name] ? `: ${variations?.selectedItem?.variations?.[type.name]}` : ''}`
                }}
              </div>
            </template>
            <div class="flex flex-wrap gap-4" ref="choices">
              <div
                v-for="ch in visibleDownstreamVariationPossibilities(type.name)"
                :class="{
                  'opacity-50': unavailableCombos?.[type.name]?.[ch]
                }"
                :key="`${type.name}:${ch}`"
              >
                <div
                  @click="selectVariation(type.name, ch)"
                  class="variation-selector--type-choice p-1 overflow-hidden flex items-center justify-center gap-2 border border-surface-300 hover:border-cement-400 rounded"
                  :key="`${type.name}:${ch}`"
                  :class="{
                    '!border-primary !border-2':
                      variations?.selectedItem?.variations?.[type.name] === ch,
                    [variationsTypesObject?.[type.name]?.imageShape]: true
                  }"
                >
                  <div
                    v-if="variationTraitsObject?.[type.name]?.[ch]?.imgs?.length"
                    class="image w-16 h-16"
                  >
                    <div
                      class="background rounded-sm"
                      :key="variationTraitsObject[type.name][ch].imgs[0]"
                      :style="{
                        backgroundImage: `url(${getFileLink(`file/view/${variationTraitsObject[type.name][ch].imgs[0]}?size=250`)})`,
                        backgroundSize: variationTraitsObject[type.name][ch].backgroundSize
                      }"
                    >
                      &nbsp;
                    </div>
                  </div>
                  <div v-else class="w-32 h-12 image">
                    <span class="label w-full">
                      {{ ch }}
                    </span>
                  </div>
                </div>

                <p
                  v-if="variationTraits?.[type.name]?.[ch]?.imgs?.length"
                  class="label sub-label !text-pitch-black w-full !text-left"
                >
                  {{ ch }}
                </p>

                <p
                  v-if="
                    variations?.selectedItem?.variations?.[type.name] !== ch &&
                    priceDeltas?.[type.name]?.[ch]?.[0] !== null &&
                    priceDeltas?.[type.name]?.[ch]?.[0] !== undefined
                  "
                  class="price-delta font-medium text-xs text-surface-500"
                >
                  <template v-if="priceDeltas?.[type.name]?.[ch]?.[0] >= 1">
                    add {{ $f.currency(priceDeltas[type.name][ch][0]) }}
                  </template>
                  <template v-else-if="priceDeltas?.[type.name]?.[ch]?.[0] <= -1">
                    save {{ $f.currency(priceDeltas[type.name][ch][0]) }}
                  </template>
                  <template v-else> same price </template>
                </p>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import ItemVariations from '@/components/composables/ItemVariations'

// ===== Props ===== //
const props = defineProps({
  store: {
    default: 'Quote'
  },
  showHighlights: {
    default: true
  },
  scroll: {
    default: true
  },
  focusOnMount: {
    default: false
  },
  object: {
    type: Object,
    required: true
  },
  refId: {
    type: String,
    required: true
  },
  upgradingAllowed: {
    default: true
  },
  editable: {
    default: 0
  }
})

const object = computed(() => props.object)
// =================== //

// ===== Composables ===== //
const {
  variationTypes,
  variationsTypesObject,
  variationTraits,
  unlockedVariations,
  lockedSelections,
  variations,
  priceDeltas,
  allVariationTypePossibilities,
  unavailableCombos,
  variationTraitsObject,
  getDownstreamPossibleVariations,
  getFullItems,
  itemsByUid,
  selectVariation
} = ItemVariations.useItemVariations({
  type: 'CostItem',
  object,
  store: props.store,
  refId: props.refId,
  autoload: false
})
// =================== //

// ====== refs ====== //
const choicesRef = ref(null)
// =================== //

// ===== Computed ===== //
const visibleVariationTypes = computed(() => {
  return variationTypes.value?.filter((type) => {
    return props.editable || !type.hidden
  })
})
// =================== //

// ===== Methods ===== //
const scrollToSelected = async () => {
  await nextTick()
  if (choicesRef.value) {
    choicesRef.value.forEach((row) => c.scrollToX($(row).find('.selected')[0]))
  }
}
const visibleDownstreamVariationPossibilities = (type) => {
  return allVariationTypePossibilities.value[type]?.filter((ch) => {
    return !(type in lockedSelections.value) || unlockedVariations.value?.[type]?.[ch]
  })
}
const getFileLink = (url) => {
  return c.link(url)
}
// =================== //

// ===== Lifecycle ===== //
onMounted(() => {
  getFullItems(Object.keys(itemsByUid.value))
  getDownstreamPossibleVariations(
    lockedSelections.value,
    variations.value?.selectedItem?.variations
  )
})
// =================== //
</script>

<style lang="scss" rel="stylesheet/scss">
.variation-selector--container {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-color: flex-start;
  max-width: 100%;
  padding: 0 0.5em;

  .variation-selector--type {
    max-width: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;

    &:not(:first-child) {
      margin-top: 0.25em;
    }

    .type-label {
      color: $cool-gray-600;
    }

    /*>strong {*/
    /*  display: flex;*/
    /*  min-width: 5em;*/
    /*  max-width: 5em;*/
    /*  text-align: center;*/
    /*  justify-content: center;*/
    /*  align-items: center;*/
    /*}*/
    .fader {
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      width: 1px;
      pointer-events: none;
      box-shadow: 0em 0 3em 3em $flame-white;
    }
  }

  .variation-selector--types {
    margin: 0 0 0 0;

    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    // @extend %scrollbar;
    display: flex;
    flex-direction: row;
    position: relative;

    $scrollBarWidth: 15px;

    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      height: $scrollBarWidth;
      display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      width: $scrollBarWidth;
      height: $scrollBarWidth;
    }
  }
}

.variation-selector--type-choice {
  cursor: pointer;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &.square .image {
    height: 5em;
    width: 5em;
    border-radius: 0.25em;
  }
  &.fit .image {
    max-width: 5em;
    max-height: 5em;
    min-width: unset;
    min-height: unset;
    border-radius: 0.25em;
  }

  .label {
    font-weight: normal;
    font-size: 0.75em;
    max-width: 100%;
    word-wrap: break-word;
    padding: 0.1em;
    text-align: center;
    line-height: 1.1;
    color: $pitch-black;
    margin: 0.25em 0;
  }

  .sub-label {
    display: flex;
    justify-content: start;
    align-items: start;
    max-width: 100%;
    flex-direction: column;
    color: $surface-500;
    min-height: 2em;

    font-weight: 400;
  }

  .background {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>
