import * as types from './store/mutation-types'
import Objects from '../imports/api/Objects'
import Parallel from '../imports/api/Parallel'
import VueHelpers from './Helpers'
import Normalize from '../imports/api/Normalize'
import Auditing from '../imports/api/Auditing'
import Changes from '../imports/api/Changes/Changes'
import Utilities from '../imports/api/Changes/Utilities'
import Perf from '../imports/api/Perf'
import CostType from '../imports/api/schemas/CostType'
import NormalizeUtilities from '../imports/api/NormalizeUtilities'
import Assembly from '../imports/api/schemas/Assembly'
import Quote from '../imports/api/schemas/Quote'
import UserError from '../imports/api/UserError'
import VersionControl from '@/VersionControl.js'
import eventBus from '@/eventBus.js'

const _ = VueHelpers

const {
  normalize,
  denormalize,
  normalizeSet,
  denormalizeSet,
  getNormalizedRootRefId,
  shouldNormalizeField,
  rereference,
  generateNormalizedRefId,
  removeOrphaned,
  getOrphanedRefIds,
  removeNormalized,
  removeChild,
  replaceChild,
  replaceChildren
} = Normalize

const {
  objectList,
  getConstructor,
  buildDefaultObject,
  getFieldTitle
  // pruneNormalized,
} = Objects

const { fieldComparison, diffNormalizedRaw, integrateChangesToSet, diffNormalized } = Utilities

const { cascadeDependencies, auditDependencies } = Auditing

/**
 * Get default audit delay depending on the size of the normalized state
 * @param state
 * @returns {number}
 */
const getDefaultDelay = (state) => {
  const refsCount = Object.keys(state.normalized).length
  if (refsCount < 150) return 400
  if (refsCount < 500) return 700
  if (refsCount < 800) return 1500
  return 2000
}

let changeManagers = {}

const originalState = {
  /**
   * Original fetched objects of this type, stored with their
   *  server-side unique id as the key,
   *  ie: [3432]: { type: 'quote', .. }, ['user-25']: { type: 'contact', .. }
   */
  all: {},
  fetching: {},

  /**
   * An array of refIds of selected objects of this type, the selected objects
   *  of which occupy normalized object
   */
  selected: [],
  trash: [],

  /**
   * A de-constructed list of objects, and sub objects related the the selected[]
   *  objects of this type.  Inputted by their refId:object key/value pairs.
   *  ie: ['quote-23-23434']: {quote},
   *      ['cost_item-2343-343']: {cost_item},
   *      ['file-2343-343']: {file},
   * Objects here are manipulated directly with mutations, then reconstructed in the
   *  denormalized JSON hierarchical form ie:
   *  {
   *    type: 'quote',
   *    id: 1234,
   *    aoChildren: [
   *       {
   *         type: 'cost_item',
   *         cost_item_name: 'abc',
   *       },
   *       {
   *         type: 'quote',
   *         aoChildren: [
   *           {cost_items..}, ...
   *         ]
   *       }
   *    ]
   * }
   */
  normalized: {},

  /**
   * random
   */
  meta: {},

  /**
   * Entity type
   */
  type: null,

  /**
   * {
   *    [refId]: [
   *      fieldName,
   *      fieldName2,
   *    ],
   * }
   */
  unauditedChanges: {},

  /**
   * If 1, then audit will go throught whether unaudited fields
   *  requires an audit or not.
   */
  forceAudit: 0,

  /**
   * maxRequests
   * how many processes can go concurrently?
   * Inside of a single store this should be 1
   * so that one process finishes before another can begin,
   * for exmaple, audit() needs to complete
   * before save() is dispatched, so that save uses
   * all the audited values.  If saving a cost type inside
   * a quote, you save the CostType, then save the quote, before
   * the cost type finishes, the quote might be saved without the
   * cost type's newly created ID.
   */
  maxRequests: 20,

  /**
   *  The queue for making ajax requests from this specific module. It is
   *  limited by maxRequests abvoe, for this store. Other stores also have
   *  a 1 request with a separate queue.
   */
  requestQueue: [],

  /**
   *  The resolvers for each request above, technical requirement only
   */
  resolveQueue: [],

  /**
   * If it is auditing now or not
   */
  processing: 0,
  auditing: 0,
  auditLoading: 0
}

const checkDependenciesFirst = false

const dependencyTriggers = Object.keys({
  ...CostType.getFieldDependencies(),
  ...Assembly.getFieldDependencies(),
  ...Quote.getFieldDependencies(),
  ...Quote.getChildDependencies(),
  ...Quote.getParentDependencies(),
  ...Assembly.getChildDependencies(),
  ...Assembly.getParentDependencies()
})

/**
 * Can ONLY be called from a mutation
 * @param state
 * @param refId
 * @returns {*}
 */
const verifyChangeManager = (state, refId = null) => {
  const rootRefId = getNormalizedRootRefId(state.normalized, refId) || refId
  if (!(rootRefId in changeManagers)) {
    const root = state.normalized[rootRefId]
    if (!root || !root.type) {
      return null
    }
    const objType = root.type
    const id = root[`${objType}_id`]
    const original =
      id in state.all ? state.all[id] : normalize(buildDefaultObject(objType), false, rootRefId)
    changeManagers[rootRefId] = new Changes(original, state.normalized, rootRefId)
  }

  return changeManagers[rootRefId]
}

/**
 * For each entity/object type, build an individual store
 * namespaced by the title-type version of the entity type, ie:
 * entity template_type => TemplateType
 * entity cost_item => CostItem
 * entity quote => Quote etc
 *
 * @type {Object}
 */
const modules = Object.keys(objectList).reduce((combinedModules, titleType) => {
  const type = _.underCase(titleType)
  const constructor = getConstructor(type)

  // Get actions that are defined in the schema
  // and specific to that object type
  const objectActions = constructor.generateVueActions ? constructor.generateVueActions() : {}

  return {
    ...combinedModules,

    [titleType]: {
      /**
       * Will allow it to be accessed via namespace, and
       * actions and mutations for the same store to be accessed without namespace
       */
      namespaced: true,

      /**
       * Generate the initial starting state for each module
       * This method generates the VUEX.state for each one.
       */
      state: _.imm({ ...originalState, type }),

      /**
       * Generate the actions for each module
       * This method generates the VUEX dispatches for each one.
       */
      actions: {
        async getVersionHash({ dispatch }, payload) {
          let { object } = await dispatch('resolveObject', payload)
          object.type = payload.coerceType ?? object.type
          return VersionControl.getVersionHash(object)
        },
        verifyChangeManager({ state }, { refId = null }) {
          return verifyChangeManager(state, refId)
        },
        /**
         * See Utilties.js -> diffNormalizedRaw
         * @param from
         * @param to
         * @param refId
         * @param strict
         * @returns {Promise<{}>}
         */
        async diffNormalizedRaw(state, { from, to, refId = null, strict = false }) {
          return diffNormalizedRaw(from, to, refId, strict)
        },
        /**
         * See Utilties.js -> diffNormalized
         * @param from
         * @param to
         * @param refId
         * @param strict
         * @returns {Promise<{}>}
         */
        async diffNormalized(state, { from, to, refId = null, strict = false }) {
          return diffNormalized(from, to, refId, strict)
        },
        /**
         * See Utilties.js -> integrateChangesToSet
         * @param from
         * @param to
         * @param refId
         * @param strict
         * @returns {Promise<{}>}
         */
        async integrateChangesToSet(state, { startingSet, combinedSet, changeSchemas }) {
          return integrateChangesToSet(startingSet, combinedSet, ...changeSchemas)
        },
        /**
         * Open modal or go to page required to edit an entity of this type
         * @see general/index.js for details about edit
         * @param dispatch
         * @param payload
         * @returns {*}
         */
        async edit({ dispatch }, payload) {
          const { object, id } = await dispatch('resolveObject', payload)
          return dispatch(
            'edit',
            {
              id,
              type: payload.type || object.type || type
            },
            { root: true }
          )
        },

        /**
         * Open modal or go to page required to create a new object
         * @see general/index.js for details about create
         * @param dispatch
         * @param payload
         * @returns {*}
         */
        create({ dispatch }, payload) {
          return dispatch(
            'create',
            {
              ...payload,
              type: payload.type || type
            },
            { root: true }
          )
        },

        /**
         * Shortcut to global dispatch that can be called locally
         * @see general/index.js for detailed paramaters list etc
         * @returns {Promise}
         */
        ajax({ dispatch }, payload) {
          return dispatch('ajax', payload, { root: true })
        },

        /**
         * Shortcut to global dispatch that can be called locally
         * @see general/index.js for detailed paramaters list etc
         * @returns {Promise}
         */
        link({ dispatch }, payload) {
          return dispatch('link', payload, { root: true })
        },

        /**
         * Get the rootRefId from the current normalized set,
         * with optional but recommended refId.  If there are
         * multiple normalized sets mixed in the state.normalized
         * object, definitely provide the refId otherwise it will
         * find the root of a random set.
         *
         * @param state
         * @param refId
         * @returns {Promise<*>}
         */
        async getRootRefId({ state }, { normalized = state.normalized, refId = null }) {
          return getNormalizedRootRefId(normalized, refId)
        },

        /**
         *
         * @param state
         * @param dispatch
         * @param refId
         * @param norm
         * @returns {Promise<Changes>}
         */
        async getChangeManager({ state, commit }, { refId }) {
          const rootRefId = getNormalizedRootRefId(state.normalized, refId)
          // Save current state to change manager
          commit({
            type: 'ENSURE_CHANGE_MANAGER',
            refId: rootRefId
          })

          return changeManagers[rootRefId]
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param refId
         * @param norm
         * @returns {Promise<Changes>}
         */
        async resetChanges({ commit, state, dispatch }, { refId, normalized: norm = null }) {
          // Save current state to change manager
          const changeManager = await dispatch('getChangeManager', { refId })
          const normalized = norm || state.normalized

          commit(types.SET_CURRENT_CHANGE_MANAGER, { changeManager, normalized })
          commit(types.SET_ORIGINAL_CHANGE_MANAGER, { changeManager, normalized })
          commit(types.RESET_CHANGE_MANAGER, { changeManager })
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param refId
         * @param norm
         * @returns {Promise<Changes>}
         */
        async resetChangeManager({ commit }, { changeManager }) {
          commit(types.RESET_CHANGE_MANAGER, { changeManager })
        },

        /**
         *d
         * @param original
         * @returns {Promise<Changes>}
         */
        async setOriginal({ commit, dispatch }, { refId, original }) {
          // Save current state to change manager
          const changeManager = await dispatch('getChangeManager', { refId })
          if (!changeManager) {
            return {}
          }
          commit({
            type: 'SET_ORIGINAL',
            changeManager,
            refId,
            original
          })
        },

        /**
         *
         * @param original
         * @returns {Promise<Changes>}
         */
        async setWatchers({ dispatch, commit }, { refId, store }) {
          // Save current state to change manager
          const changeManager = await dispatch('getChangeManager', { refId })
          if (!changeManager) {
            return {}
          }
          commit({
            type: 'SET_WATCHERS',
            changeManager,
            refId,
            store
          })
        },

        /**
         *
         * @param original
         * @returns {Promise<Changes>}
         */
        async destroyWatchers({ dispatch, commit }, { refId, callback }) {
          // Save current state to change manager
          const changeManager = await dispatch('getChangeManager', { refId })
          if (!changeManager) {
            return {}
          }
          commit({
            type: 'DESTROY_WATCHERS',
            changeManager,
            refId,
            callback
          })
        },

        /**
         * Get list of refIds that have been removed
         *
         * @param refId
         * @returns {Promise}
         */
        async getRemoved({ dispatch }, { refId }) {
          const changeManager = await dispatch('getChangeManager', { refId })
          // state.changeManagers[rootRefId].setCurrent(state.normalized, false);
          if (!changeManager) {
            return {}
          }

          return changeManager.getRemoved()
        },

        /**
         * Get list of changes, gets the changes for the entire root + children, even
         * if you only provide a child refId
         *
         * @param refId
         * @returns {Promise}
         */
        async getChanges({ dispatch }, { refId, normalized = false }) {
          const changeManager = await dispatch('getChangeManager', { refId })
          // state.changeManagers[rootRefId].setCurrent(state.normalized, false);
          if (!changeManager) {
            return {}
          }

          return changeManager.getChanges(false, normalized, true)
        },

        /**
         * Get list of changes, gets the changes for the entire root + children, even
         * if you only provide a child refId
         *
         * @param refId
         * @returns {Promise}
         */
        async getExplicitChanges({ dispatch }, { refId, normalized = true }) {
          const changeManager = await dispatch('getChangeManager', { refId })

          if (!changeManager) {
            return {}
          }

          // Even though explicit changes are explicitly set,
          // we must check them
          // state.changeManagers[rootRefId].setCurrent(state.normalized, false);
          const changes = changeManager.getExplicitChanges(false, normalized)

          return changes
        },

        /**
         * Denormalize normalized set
         *
         * @see Normalize.js for more detail
         *
         * @param state
         * @param payload
         *    @param object set
         *    @param string rootRef
         *    @param bool keepRefIds
         *    @param bool keepWhole
         * @returns {Promise<*>}
         */
        async denormalize(state, payload = {}) {
          const { set = {}, rootRef = null, keepRefIds = true, keepWhole = true } = payload

          return denormalize(set, rootRef, keepRefIds, keepWhole)
        },

        /**
         * Normalize denormalized object
         *
         * @see Normalize.js for more detail
         *
         * @param state
         * @param payload
         * @returns {Promise<{}>}
         */
        async normalize({ state }, payload = {}) {
          const {
            object = {},
            overrideRefIds = null,
            rootRefId = false,
            currentRefIds = Object.keys(state.normalized)
          } = payload

          return normalize(object, overrideRefIds, rootRefId, currentRefIds)
        },

        /**
         * Get the root ref id in provided set
         *
         * @see Normalize.js for more detail
         *
         * @param state
         * @param payload
         * @returns {Promise<{}>}
         */
        async getNormalizedRootRefId(state, payload = {}) {
          const { normalized = {}, refId = null } = payload

          return getNormalizedRootRefId(normalized, refId)
        },

        /**
         * Simply returns the changes and explicit changes provided after auditing (locally)
         * and if a refId is provided, it will update the vuex model.
         *
         * @param state
         * @param dispatch
         * @param rootState
         * @param payload
         *    @param object changes                 object full of all the changes you want to report
         *                                          for the item given by EITHER refId or object
         *    @param object|null explicitChanges    the explicit changes, if different from changes.  if
         *                                          explicit changes is not provided, it assumes
         *                                          all changes are explicit.
         *    @param string|null store              name of the store, defaults to the store called
         *    @param string|null refId              if you do not provide the refId, you MUST provide
         *                                          an object param in payload that is equivalent
         *                                          to a refId. NB: if a refId is provided the changes
         *                                          will be added to the VUEX normalized model given by
         *                                          the refId.  If not, it will just return an object
         *                                          with changes and explicitChanges
         *    @param object|null object             If you are not providing a refId you must provide
         *                                          an object.
         *    @param bool|null auditLocal           wehther to audit it before we update/return
         * @returns {Promise<{changes: *, explicitChanges: *}>}
         */
        async reportChanges({ dispatch, rootState }, payload) {
          let changes = payload.changes ?? {}

          const store = payload.store ?? titleType
          const refId = payload.refId ?? null
          const storeNormalized = rootState[store].normalized
          const object = payload.object ?? (refId ? storeNormalized[refId] : {}) ?? {}
          const explicitChanges = payload.explicitChanges ?? changes
          const auditLocal = payload.auditLocal ?? true
          const auditFull = payload.auditFull ?? false
          const providedEquations = payload.equations ?? null

          const hasChanges = changes && Object.keys(changes).length > 0
          const objectRef = refId ?? object.refId ?? null

          // Explicit changes need to have their equations tested or removed
          const setEquations = storeNormalized[objectRef]?.oEquations ?? {}
          const objectEquations = object.oEquations ?? {}

          const equations = Object.assign({}, setEquations, objectEquations)

          // Unset all equations that have been explicitly set
          const newEquations = {}
          for (const field in equations) {
            if (!(field in changes)) {
              newEquations[field] = equations[field]
            }
          }

          if (Object.keys(equations).length > 0) {
            changes.oEquations = Object.assign({}, newEquations, providedEquations)
          }

          if (objectRef && auditFull && hasChanges) {
            const possibleDimensions = await dispatch(
              'Dimension/getPossibleDimensions',
              {},
              { root: true }
            )

            const updatedSet = Object.assign({}, storeNormalized)
            updatedSet[objectRef] = Object.assign(
              {},
              storeNormalized[objectRef] ?? {},
              object,
              changes
            )
            const changeSet = (
              await Parallel.work('Audit:auditDependencies', [
                _.imm(updatedSet),
                objectRef,
                possibleDimensions
              ])
            )[1]

            const changeSetForObjectRef = changeSet[objectRef] ?? {}

            changes = Object.assign({}, changes, changeSetForObjectRef)
          } else if (auditLocal && hasChanges) {
            const ref = objectRef ?? 'a'
            const updatedSet = Object.assign({}, storeNormalized)
            updatedSet[ref] = Object.assign({}, storeNormalized[ref] ?? {}, object, changes)

            const { changes: allChanges } = await dispatch('cascadeDependencies', {
              changes: {
                [ref]: changes
              },
              set: updatedSet,
              refId: ref,
              store
            })

            const allChangesForRef = allChanges[ref] ?? {}

            changes = Object.assign({}, changes, allChangesForRef)
          }

          if (refId) {
            dispatch(
              `${store}/field`,
              {
                refId,
                changes,
                explicit: explicitChanges,
                skipAudit: payload.skipAudit,
                skipLocalAudit: payload.skipLocalAudit,
                auditLocal
              },
              { root: true }
            )
          }

          return { ...payload, changes }
        },

        /**
         * Build a default object with all fields required based on the schema found in /schemas
         *
         * @param rootState
         * @param payload
         *    @param embue
         *    @param type
         * @returns {Promise<{object: {type}}>}
         */
        async buildDefaultObject({ dispatch }, payload = {}) {
          const { embue = {}, type: specType = embue.type || type } = payload
          // Put default_object objecst in after embue, before raw defaults
          // figures type based on first type, so make sure always an object
          const defaultedEmbue = _.merge(
            {},
            await dispatch(`${_.titleCase(specType)}/getObjectDefaults`, {}, { root: true }),
            _.imm(embue)
          )
          const object = buildDefaultObject(specType, defaultedEmbue)
          return { ...payload, object }
        },

        /**
         * Get object defaults
         * @param rootState
         * @returns {Promise<any>}
         */
        async getObjectDefaults({ rootState }) {
          return rootState.session.user &&
            rootState.session.user.aoObjectDefaults &&
            rootState.session.user.aoObjectDefaults[type]
            ? _.imm(rootState.session.user.aoObjectDefaults[type])
            : {}
        },

        /**
         * Same as above, but for normalize dset
         *
         * @param rootState
         * @param payload
         *    @param embue    // Must be normalized
         *    @param type     // fully deteced
         * @returns {Promise<{object: {type}}>}
         */
        async buildDefaultObjectNormalized(state, payload = {}) {
          const { embue = {} } = payload

          const newNormalized = {}

          Object.keys(embue).forEach((refId) => {
            const obj = embue[refId]
            const objType = obj.type

            if (!objType) {
              return
            }

            newNormalized[refId] = buildDefaultObject(objType, obj)
          })

          return { object: newNormalized, normalized: newNormalized }
        },

        /**
         * Add entity defaults for things like 'client_owner', if the user is constantly
         * setting it to the same person, save that and next time a blank user is created
         * it will automatically have that value;
         *
         * @param state
         * @param dispatch
         * @param payload
         *    @param array defaults         array of fields to set defaults for. Leave empty to save all
         *                                    possible defaults
         *    @param stirng type            object type
         *    @param string store           store name
         *    @param object object          the object full of values
         * @returns {Promise<*>}
         */
        async addDefaults({ rootState, dispatch }, payload) {
          const {
            type: payloadType = type,
            object: presetObject = null,
            defaults = Object.keys(presetObject)
          } = payload

          if (
            !rootState.session.scope ||
            !rootState.session.scope.company ||
            !rootState.session.scope.user
          ) {
            return payload
          }

          const object = presetObject || (await dispatch('resolveObject', payload)).object
          if (!Object.keys(object).length) {
            throw new UserError({
              userMessage: 'Could not find related object. 3',
              message: 'Could not find related object.',
              hintMessage: 'Object could not be resolved',
              object,
              action: 'addDefaults'
            })
          }

          const constr = getConstructor(payloadType)
          if (!constr || !constr.fields) {
            throw new UserError({
              userMessage: 'Could not find related object. 2',
              message: 'Could not find related object.',
              hintMessage: 'Constructor could not be built for that object type',
              object,
              action: 'addDefaults'
            })
          }

          const selectedDefaults = {}
          Object.keys(defaults).forEach((f) => {
            if (
              constr.fields[f]?.defaultSetting &&
              (!defaults || !defaults.length || defaults.includes(f)) &&
              f in object
            ) {
              selectedDefaults[f] = object[f]
            }
          })

          if (Object.keys(selectedDefaults).length) {
            await dispatch('ajax', {
              path: `${payloadType}/addDefaults`,
              data: selectedDefaults,
              scope: payload.scope || null
            })

            await dispatch('getBaseValues', { cloak: false }, { root: true })
          }

          return {
            ...payload,
            userMessage: 'Defaults successfully added.'
          }
        },

        /**
         * The following actions are state independant,
         *   and can be dispatched from the c.getActions() function.
         *
         *   They all must have the payload object as a second argument,
         *   with:
         *    @param selected (array), (or can havcae refId, one or the other)
         *    @param refId (array), (or can have selected, one or the other)
         *    @param button (VueComponent, optional),
         *    @param alert (boolean, default true)
         *    @param context (object, optiona) additional contextual data
         *    @param grid (Grid VueComponent, optional),
         *
         *    They all return promises.
         *
         *    Object/module/store specific actions are found in the
         *       the individual object type files in this directory
         *       under: static generateViewActions()  If there are none,
         *       that object uses the generic actions. Actions can be
         *       added and overriden.
         */

        /**
         *
         * @param dispatch
         * @returns {Promise<*>}
         */
        async getNames({ dispatch, rootState }, payload = {}) {
          const { ids = [] } = payload

          const { object } = await _.throttle(
            () =>
              dispatch('ajax', {
                path: `${type}/names`,
                data: {
                  ...(_.isEmpty(ids) ? {} : { ids })
                }
              }),
            {
              key: type
            }
          )

          Object.keys(object).forEach((id) => {
            if (object[id]) {
              _.setCacheItem(`names.${type}.${id}`, object[id], type, rootState.session)
            }
          })

          return object
        },

        /**
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<*>}
         */
        async getName({ dispatch, rootState }, payload = {}) {
          const { getIndividual = false, id } = payload

          const existing = _.getCacheItem(`names.${type}.${id}`, type, rootState.session)
          if (existing) return existing

          if (!getIndividual) {
            return (await dispatch('getNames', { ids: [id] }))[String(id)]
          }

          const { object } = await _.throttle(
            () =>
              dispatch('ajax', {
                path: `${type}/name/${id}`
              }),
            {
              key: `${type}/${id}`
            }
          )

          if (object.name) {
            _.setCacheItem(`names.${type}.${id}`, object.name, type, rootState.session)
          }

          return object.name
        },

        /**
         * Takes a payload with:
         *  -selected (array)
         *  -refId (string, array)
         *  -id (int, string, array)
         *
         *  And returns a full object based on that data.  Useful for
         *  actions that can be versatile and be called from an objectManipulator component
         *  by just the refId, or an array of refIds, or from a Grid or some other component
         *  that has a list of full objects by just sending it selected: [object], or
         *  from somewhere else where all you have is the id, or an array of Ids, that need to be
         *  fetched first, then returned.
         *
         *  dispatch('quote/resolveObject', { id: 123 }).then((object) =>  object);
         *  *    >> { quote_id: 123, type: 'quote', quote_name: 'my quote', etc }
         *
         * @param state
         * @param payload
         *    @param string store                 store to find it in, ie: 'CostType'
         *    @param string type                  entity type ie: 'cost_type'
         *    @param object object                will return this object right back
         *    @param string|int id                id of entity of this type, will fetch
         *    @param array selected               full objects in
         *                                        array ie: [{
         *                                          type: 'client',
         *                                          user_fname: 'joe',
         *                                          ...
         *                                        }]. Will grab first viable option.
         *    @param string|array refId           optional reference point
         *    @param bool alert                   whether to alert on error and success
         *                                          default true
         *
         * @returns {Promise<{ object: {}, set: [], id: int|string }>}
         */
        async resolveObject({ dispatch, rootState }, payload) {
          const {
            selected = false,
            refId: ref = false,
            store: storeName = titleType,
            id: payloadId = false,
            object: payloadObject = false,
            quick = false,
            forceFull = false
          } = payload

          let { type: objectType = type } = payload

          let fetchId = payloadId

          try {
            const storeState = rootState[storeName]

            let isArray = false
            let object = {}
            let set = []
            let idField = `${objectType}_id`
            let refId = ref

            if (!_.isempty(selected)) {
              // 'selected'
              isArray = Array.isArray(selected)
              set = _.makeArray(selected)
              object = (set.length && selected[0]) || null
              refId = refId || object.refId

              if (set.some((o) => !o.full) && forceFull) {
                fetchId = set.map((o) => o[idField])
              }
            } else if (!_.isempty(refId)) {
              // 'refId'
              isArray = Array.isArray(refId)
              set = denormalizeSet(storeState.normalized, _.makeArray(refId), true, true)
              object = (set.length && set[0]) || null
              refId = refId || object.refId

              if (!object.full && forceFull) {
                fetchId = set.map((o) => o[idField])
              }
            } else if (payloadObject) {
              // 'object'
              isArray = Array.isArray(payloadObject)
              object = payloadObject
              set = _.makeArray(object)
              refId = refId || object.refId

              if (!object.full && forceFull) {
                fetchId = set.map((o) => o[idField])
              }
            } else if (!_.isempty(fetchId)) {
              // 'id'

              // searching
              isArray = Array.isArray(fetchId)
              if (isArray && fetchId.length > 1) {
                const { set: searchSet = [] } = await dispatch('search', {
                  saveSet: true,
                  type: objectType,
                  filters: {
                    [idField]: _.makeArray(fetchId).join('||')
                  },
                  alert: false,
                  quick,
                  searchMethod: 'filter'
                })

                set = searchSet
                object = set.length ? set[0] : {}
                refId = refId || object.refId
              }

              // fetching
              const { object: fetchedObject } = await dispatch('fetch', {
                type: objectType,
                id: _.makeArray(fetchId)[0],
                alert: false,
                quick
              })

              object = fetchedObject
              set = [object]
              refId = refId || object.refId
            }

            objectType = object.type || objectType
            idField = `${objectType}_id`
            return {
              ...payload,
              refId,
              object,
              set,
              type: objectType,
              id: object[idField] || null
            }
          } catch (e) {
            console.log(e)
            const { userMessage = null } = e
            const message = userMessage || 'Could not fetch that object.'
            if (alert) dispatch('alert', { error: true, message }, { root: true })
            throw new UserError(
              {
                userMessage: 'Could not find related object. 1',
                message: 'Could not find related object.',
                hintMessage: 'Error in resolve object',
                action: 'resolveObject'
              },
              e
            )
          }
        },

        /**
         * First try to get the abbrevated / country -version of a
         * format, ie: postal_us or postal_ca, then if a type isn't available
         * default to a general 'postal' when 'postal' is provided as validation.
         *
         * @param rootState
         * @param validation
         * @returns {Promise<OBJECT>}
         */
        async getValidationTest({ rootState }, { validation = 'postal' }) {
          const country = String(
            rootState.session.user.country_id || rootState.session.company.country_id
          )

          const abbr = country in _.countries && _.countries[country].abbr

          return (
            (abbr && _.validations[`${validation}_${abbr}`]) || _.validations[validation] || null
          )
        },

        /**
         *
         * @param state
         * @param dispatch
         * @param rootState
         * @param payload
         * @returns {Promise<object>}
         */
        async validate({ dispatch, rootState }, payload) {
          const { object } = await dispatch('resolveObject', payload)
          const fields = payload.validationSchema || (object.type && getConstructor(type).fields)

          if (!fields) {
            return {}
          }

          const issues = {}

          // Get country-specific validation
          const getValidation = (validation) => {
            const country = String(
              rootState.session.user.country_id || rootState.session.company.country_id
            )

            const abbr = country in _.countries && _.countries[country].abbr

            return (
              (abbr && _.validations[`${validation}_${abbr}`]) || _.validations[validation] || null
            )
          }

          Object.keys(fields).forEach((field) => {
            const validation = fields[field].validate || fields[field]
            const validationTest = validation.type && getValidation(validation.type)

            if (!validation) {
              return
            }

            if (
              validation.required &&
              (validation.required === true ||
                (typeof validation.required === 'function' &&
                  validation.required(object, rootState))) &&
              !object[field]
            ) {
              issues[field] = `${getFieldTitle(field)}: This field is required.`
            } else if (
              validationTest &&
              object[field] &&
              !validationTest.test.test(object[field])
            ) {
              issues[field] = `${getFieldTitle(field)}: ${validationTest.message}`
            }
          })

          if (Object.keys(issues).length > 0) {
            throw new UserError({
              userMessage: `Please correct the following issues before saving:${Object.keys(issues)
                .map((field) => `\n    • ${issues[field]}`)
                .join('')}`
            })
          }

          return {}
        },

        /**
         * Make an object default, if possible
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param string store                 store to find it in, ie: 'CostType'
         *    @param string type                  entity type ie: 'cost_type'
         *    @param object object                will return this object right back
         *    @param string|int id                id of entity of this type, will fetch
         *    @param array selected               full objects in
         *                                        array ie: [{
         *                                          type: 'client',
         *                                          user_fname: 'joe',
         *                                          ...
         *                                        }]. Will grab first viable option.
         *    @param string|array refId           optional reference point
         *    @param VueComponent|null button     to add loading indicator
         *    @param bool alert                   whether to alert on error and success
         *                                          default true
         * @returns {Promise}
         */
        async makeDefault({ state, dispatch }, payload) {
          const {
            selected = [],
            refId = null,
            button = null,
            alert = true,
            go = false,
            grid = null
          } = payload

          const object = (
            refId
              ? _.makeArray(refId).map((r) => denormalize(state.normalized, r))
              : _.makeArray(selected)
          )[0]

          const subConstructor = getConstructor(object.type)

          if (
            !subConstructor ||
            !subConstructor.fields ||
            !(`${object.type}_is_default` in subConstructor.fields)
          ) {
            throw new UserError({
              userMessage: 'Cannot set a default on this type of object.'
            })
          }

          try {
            const resolvePayload = await dispatch('ajax', {
              path: `${object.type}/setDefault/${object[`${object.type}_id`]}`,
              button
            })

            let reload = true

            // Alert
            if (alert) {
              dispatch('alert', { text: 'Successfully updated item!' }, { root: true })
            }

            // Go to page
            if (go) {
              if (go) dispatch('go', { object }, { root: true })
              reload = false
            }

            if (grid) {
              grid.reload(true)
            }
            return { ...payload, ...resolvePayload, reload }
          } catch (e) {
            throw new UserError(
              {
                userMessage: 'Cannot set a default on this type of object.'
              },
              e
            )
          }
        },

        /**
         * Mark multiple objects with a status
         *  -Works with multiple objects
         *  -Does NOT work with multiple object types, only
         *    works with the objecst of same type as the store
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    -- General
         *    @param VueComponent|null button     to add loading indicator
         *    @param bool alert                   whether to alert on error and success
         *                                          default true. See modal/index.js => alert()
         *    @param bool go                      whether to close all modals, and go
         *                                          to view the saved/modified item
         *                                          in a list. See general/index.js => go()
         *
         *    -- For resolve-object:
         *    @param string store                 store to find it in, ie: 'CostType'
         *    @param string type                  entity type ie: 'cost_type'
         *    @param object object                will return this object right back
         *    @param string|int id                id of entity of this type, will fetch
         *    @param array selected               full objects in
         *                                        array ie: [{
         *                                          type: 'client',
         *                                          user_fname: 'joe',
         *                                          ...
         *                                        }]. Will grab first viable option.
         *    @param string|array refId           optional reference point
         *
         *   -- For this method after object/set to be modified is determined
         *   @param string markAs                 full status name ie: Pending, InProgress.
         *                                            Required.
         *
         * @returns {Promise}
         */
        async markMultiple({ dispatch }, payload) {
          const {
            markAs,
            button = null,
            alert = true,
            go = true,
            grid = null,
            embue = {}
          } = payload

          const { set } = await dispatch('resolveObject', payload)

          const objects = set.map((o) => ({
            type: o.type || type,
            [`${o.type || type}_id`]: o[`${o.type || type}_id`],
            activity_desc: o.activity_desc || '',
            ...embue
          }))

          const updatePayload = await dispatch('ajax', {
            path: `${objects[0].type}/markMultiple/${String(markAs).replace(' ', '')}`,
            data: objects,
            button,
            scope: payload.scope || null
          })

          // Clear other caches
          dispatch('clearCache')

          // Get updated counts for user objects
          dispatch('updateCounts', {}, { root: true })

          // Alert
          if (alert) {
            dispatch(
              'alert',
              { text: `Successfully updated ${set.length} item${set.length > 1 ? 's' : ''}!` },
              { root: true }
            )
          }

          // Go to page
          if (go) {
            await dispatch(
              'go',
              {
                set:
                  Array.isArray(updatePayload.object) && updatePayload.object.length
                    ? updatePayload.object
                    : objects
              },
              { root: true }
            )
          }

          if (grid) {
            setTimeout(() => {
              grid.reload(true)
            }, 0)
          }
          return { ...payload, ...updatePayload, reload: false, objects, set: objects }
        },

        /**
         * Partial update multiple objects
         *  -Works with multiple objects
         *  -Works with different object types found within
         *    same store
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload = {
         *   @param selected Array
         *   @param refId String
         *   @param button
         *   @param alert
         *   @param go
         * }
         * @returns {Promise}
         */
        async partialUpdate({ commit, state, dispatch }, payload) {
          const {
            selected = [],
            refId = null,
            alert = true,
            grid = null,
            go = false,
            progress = () => {},
            scope = null
          } = payload

          const objects = refId
            ? _.makeArray(refId).map((r) => denormalize(state.normalized, r))
            : selected
          const refIds = _.makeArray(objects.map((o) => o.refId || null))

          const byType = {}
          objects.forEach((sel) => {
            if (!(sel.type in byType)) byType[sel.type] = []
            byType[sel.type].push(sel)
          })

          const typesToSave = Object.keys(byType)

          const returnByType = await Promise.all(
            typesToSave.map(async (t) => {
              const chunks = _.chunk(byType[t], 20)

              let returned = []
              const attempt = async () =>
                _.waterfall(
                  chunks.map((chunk, index) => async () => {
                    const r = await dispatch('ajax', {
                      path: `${t || type}/crupdateMultiple`,
                      data: chunk,
                      scope: scope || null
                    })
                    progress(((index + 1) / chunks.length) * 0.9)

                    return r
                  })
                )

              try {
                returned = await attempt()
              } catch (e) {
                returned = await attempt()
              }

              return returned.reduce((acc, r) => {
                const objects = Array.isArray(r.object)
                  ? r.object
                  : r.object
                    ? Object.values(r.object)
                    : []
                return {
                  ...acc,
                  ...r,
                  set: [...(acc.set || []), ...(r.set || [])],
                  object: [...(acc.object || []), ...objects],
                  warnings: [...(acc.warnings || []), ...(r.warnings || [])]
                }
              }, {})
            })
          )

          if (alert) {
            dispatch(
              'alert',
              {
                text: `Successfully updated ${objects.length} item${objects.length > 1 ? 's' : ''}!`
              },
              { root: true }
            )
          }

          if (typesToSave.length) {
            typesToSave.map((t) => dispatch(`${_.titleCase(t)}/clearCache`, {}, { root: true }))
          }

          if (refIds && refIds.length && Object.keys(state.normalized).length) {
            // Go through each item that can be reloaded
            await Promise.all(
              refIds.map(async (r) => {
                if (!(r in state.normalized)) return

                // Get whats there now
                const denormExisting = denormalize(state.normalized, r, true, true)
                // Merge, and default
                const correlatedObj = objects.find((o) => o.refId === r)
                const merged = _.merge(denormExisting, correlatedObj)
                const { object: defaultedObject } = await dispatch('buildDefaultObject', {
                  type: merged.type || type,
                  object: merged
                })

                // Build a set of reload changes
                // based only on the fields we tried to save
                const at = Object.keys(state.normalized).indexOf(r)
                commit({
                  type: types.REMOVE_NORMALIZED,
                  refId: r
                })
                commit({
                  type: types.REMOVE_ORPHANED
                })
                commit({
                  type: types.ADD_NORMALIZED,
                  object: normalize(
                    defaultedObject,
                    null,
                    null,
                    Object.keys(state.normalized || {})
                  ),
                  at,
                  refId: r
                })
              })
            )
          }

          if (go) {
            const ids = objects.map((o) => o[`${typesToSave[0]}_id`]).join('||')
            const text = `${objects.length} updated`
            dispatch(
              'view',
              {
                type: typesToSave[0],
                filters: {
                  [`${typesToSave[0]}_id`]: ids
                },
                filterText: {
                  [`${typesToSave[0]}_id`]: text
                }
              },
              { root: true }
            )
          } else if (grid) {
            grid.reload(true)
          }

          const combinedSet = returnByType.reduce((acc, re) => [...acc, ...re.set], [])
          progress(1)
          return { ...payload, set: combinedSet, object: combinedSet[0], reload: true }
        },

        /**
         * Duplicate multiple objects
         *  -Works with multiple objects
         *  -Works with different object types found within
         *    same store
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload = {
         *   @param selected Array
         *   @param refId String
         *   @param button
         *   @param alert
         *   @param go
         * }
         * @returns {Promise}
         */
        async duplicate({ dispatch, state }, payload) {
          const { selected = [], refId = null, alert = true, go = true } = payload

          let objectsByType = {}
          if (selected.length || refId) {
            const objects = refId
              ? _.makeArray(refId).map((r) => denormalize(state.normalized, r))
              : selected

            objectsByType = objects.reduce(
              (acc, o) => ({
                ...acc,
                [o.type]: [...(acc[o.type] || []), o]
              }),
              {}
            )
          } else {
            const { set } = await dispatch('resolveObject', payload)
            objectsByType = set.reduce(
              (acc, o) => ({
                ...acc,
                [o.type]: [...(acc[o.type] || []), o]
              }),
              {}
            )
          }

          const all = Object.keys(objectsByType).map(async (t) => {
            const objects = objectsByType[t]

            const allObjects = objects.map(async (object) => {
              if (!object.full) {
                const { object: full } = await dispatch('ajax', {
                  path: `${t}/fetch/${object[`${t}_id`]}`,
                  scope: payload.scope || null
                })
                object = full
              }

              const data = {
                ...object,
                [`${t}_id`]: null,
                [`${t}_name`]: `${object[`${t}_name`]} • copy ${c.format(Date.now(), 'datetime')}`
              }

              const createPayload = await dispatch('ajax', {
                path: `${t}/create`,
                data: {
                  object: data
                },
                scope: payload.scope || null
              })

              return createPayload
            })

            const payloads = await Promise.all(allObjects)

            return payloads
          })

          const payloads = await Promise.all(all)

          dispatch('updateCounts', {}, { root: true })

          if (alert) {
            dispatch('alert', { text: 'Successfully duplicated!' }, { root: true })
          }

          Object.keys(objectsByType).forEach((t) => {
            dispatch(`${_.titleCase(t)}/clearCache`, {}, { root: true })
          })

          if (go) {
            dispatch('go', { object: payloads[0][0].object }, { root: true })
          }

          return payloads
        },

        /**
         * Meant to be overridden, will make no changes here
         * @param dispatch
         * @param payload
         * @returns {Promise<*>}
         */
        async resetFlags({ dispatch }, payload = {}) {
          const { object } = await dispatch('resolveObject', payload)
          const changes = {
            // Override this method
          }

          const newObject = {
            ...object,
            ...changes
          }

          return dispatch('reportChanges', {
            ...payload,
            changes,
            explicitChanges: changes,
            object: newObject,
            auditLocal: payload.auditLocal || false,
            auditFull: payload.auditFull || false
          })
        },

        /**
         * Delete multiple objects
         *  -Works with multiple objects
         *  -Works with different object types found within
         *    same store
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    -- General
         *    @param VueComponent|null button     to add loading indicator
         *    @param bool alert                   whether to alert on error and success
         *                                          default true. See modal/index.js => alert()
         *    @param bool go                      whether to close all modals, and go
         *                                          to view the saved/modified item
         *                                          in a list. See general/index.js => go()
         *
         *    -- For resolve-object:
         *    @param string store                 store to find it in, ie: 'CostType'
         *    @param string type                  entity type ie: 'cost_type'
         *    @param object object                will return this object right back
         *    @param string|int id                id of entity of this type, will fetch
         *    @param array selected               full objects in
         *                                        array ie: [{
         *                                          type: 'client',
         *                                          user_fname: 'joe',
         *                                          ...
         *                                        }]. Will grab first viable option.
         *    @param string|array refId           optional reference point
         *
         *   -- For this method after object/set to be modified is determined
         *   @param bool confirm                 Ask for confirmation first before deleting?
         *                                          see modal/index.js => confirm();
         *
         * @returns {Promise}
         */
        async delete({ commit, dispatch }, payload) {
          const {
            alert = true,
            confirm = true,
            refId = null,
            progress: progressCallback = () => {}
          } = payload

          const { set } = await dispatch('resolveObject', payload)
          const selectedTypes = _.uniq(set.map((o) => o.type))
          let progress = 0
          const progressModifier = _.divide(1, selectedTypes.length)

          if (
            !confirm ||
            (await dispatch(
              'modal/asyncConfirm',
              {
                message: `Are you sure you want to delete ${set.length} item${set.length > 1 ? 's' : ''}?`
              },
              { root: true }
            ))
          ) {
            await Promise.all(
              selectedTypes.map(async (t) => {
                const data = set
                  .filter((o) => o.type === t)
                  .map((o) => ({ [`${t}_id`]: o[`${t}_id`], type: t }))
                const chunks = _.chunk(data, 5)

                await _.waterfall(
                  chunks.map((chunk, index) => async () => {
                    await dispatch('ajax', {
                      path: `${t}/deleteMultiple`,
                      data: chunk,
                      scope: payload.scope || null
                    })
                    progress += progressModifier * _.divide(index + 1, chunks.length)
                    progressCallback(progress)
                  })
                )
                // try {
                // } catch (e) {
                //   dispatch('alert', {
                //     text: e.userMessage
                //         || 'Could not delete those objects, please logout, login and refresh your browswer and try again.',
                //     error: true,
                //   },
                //   { root: true });
                // }

                dispatch(`${_.titleCase(t)}/clearCache`, {}, { root: true })
              })
            )

            if (refId) {
              commit({
                type: types.REMOVE_NORMALIZED,
                refId
              })
            }

            if (alert) {
              dispatch(
                'alert',
                {
                  text: `Successfully deleted ${set.length} item${set.length > 1 ? 's' : ''}!`
                },
                { root: true }
              )
            }

            dispatch('updateCounts', {}, { root: true })
          }

          return payload
        },

        /**
         * The following actions fill the state, whether with a blank object of a
         *   given type, or a fetched object from the server
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param rootState
         * @param payload
         *    @param string id
         *    @param object button
         *    @param bool force       default false
         *    @param bool full        whether we can save time and use an abbreviated version if it can
         *                              be found or we MUST have a full version, full = true for require
         *                              a full version. An abbreviated version typically comes from a search()
         *                              call and a full version typically comes from a fetch() call which
         *                              takes longer and is done one-at-a-time.  So if there has already been
         *                              a search and the item already exists from that retreived set, it will
         *                              use that, unless full = true.
         *    @param string|null refId
         *    @param string|null parentRefId
         *    @param string type      object type, defaults to type this method store is in
         *    @param string|null leadRequestId id of Lead Request to override scope for client
         * @returns {Promise<*>}
         */
        async selectExisting({ commit, state, dispatch, rootState }, payload) {
          const {
            id,
            force = false,
            full = false,
            refId = null,
            parentRefId = null,
            type: objectType = type,
            replace = false,
            keepChanges = false,
            override = true,
            leadRequestId = null,
            showLatestSentQuote = false
          } = payload

          const storeName = _.titleCase(objectType)
          const store = rootState[storeName]

          const setId = String(id)

          let rootRef = refId

          const currentNormalized = state.normalized

          if (!state.fetching[id]) {
            commit({
              type: 'SET_FETCHING',
              id,
              value: null
            })
          }
          const changes =
            rootRef && keepChanges
              ? await dispatch('getChanges', {
                  refId: rootRef,
                  normalized: true
                })
              : null

          const shouldFetch =
            force ||
            !(id in store.all) ||
            (full && !store.all[setId][getNormalizedRootRefId(store.all[setId])].full)

          Perf.bench('select existing 1')
          if (shouldFetch) {
            const { rootRefId } = await dispatch(
              `${storeName}/fetchNormalized`,
              {
                id,
                force,
                full,
                refId,
                leadRequestId,
                showLatestSentQuote
              },
              { root: true }
            )
            rootRef = rootRefId
            // now it should be added to state.added[id]
          }
          Perf.bench('select existing 2')

          const {
            refId: finalRefId,
            object: finalObject,
            normalized
          } = await dispatch('addSelectedObject', {
            refId: rootRef,
            object: store.all[setId],
            parentRefId,
            normalized: true,
            replace,
            audit: !shouldFetch, // fetch audits as well
            override
          })
          Perf.bench('select existing 3')

          if (keepChanges && changes) {
            const setAfterFetched = _.imm(state.normalized)
            const newSet = integrateChangesToSet(
              setAfterFetched,
              {
                ...setAfterFetched,
                ...currentNormalized
              },
              changes
            )
            commit({
              type: types.ADD_NORMALIZED,
              object: newSet
            })
          } else {
            commit({
              type: types.RESET_CHANGES,
              rootRefId: finalRefId
            })
          }

          commit({
            type: 'SET_FETCHING',
            id,
            value: finalRefId
          })

          return { ...payload, refId: finalRefId, object: finalObject, normalized }
        },

        /**
         * Add object to NORMALIZED for this store, so that it can be manipulated
         * and tracked with components.
         *
         * @param dispatch
         * @param commit
         * @param state
         * @param payload
         *    @param object object              denormalized object, or normalized set
         *    @param bool normalized            whether the object param is a normalized set (true)
         *                                         already or a denormalized object (false) default false
         *    @param string|null parentRefId    the parentRefId of the given set if there are multiple
         * @returns {Promise<{refId: *, object: *, normalized: *}>}
         */
        async addSelectedObject({ dispatch, commit, state }, payload = {}) {
          const {
            object: original = {},
            normalized = false,
            parentRefId = null,
            replace = false,
            audit = true,
            override = true
          } = payload

          let set
          let refId
          let object = {}
          if (!normalized) {
            // const objType = original.type || type;
            refId = payload.refId
            object = { ...original, refId, parentRefId }
            set = normalize(object, false, refId)
          } else {
            set = original
            refId = getNormalizedRootRefId(set, refId)
            object = set[refId]
          }

          // Remove if already in there and we requested a full
          // so if the previously used one is not full, it gets overriden.
          // If the previous one is full and this one is not full, then we should
          // rather use the full one so we leave it in.
          if (replace || (object.full && refId in state.normalized && override)) {
            commit({
              type: types.REMOVE_NORMALIZED,
              refId
            })
            commit({
              type: types.REMOVE_ORPHANED
            })
          }

          // Commit changes
          if (!(refId in state.normalized) || override) {
            commit({
              type: types.ADD_NORMALIZED,
              object: set
            })
          }

          if (audit) {
            await dispatch('auditDependencies', {
              refId,
              immediate: true,
              force: true,
              queue: false,
              full: true
            })
          }

          return { ...payload, refId, object, normalized: set }
        },

        /**
         * Select and normalize a bunch of items at once
         * @param state
         * @param commit
         * @param payload
         *    @param array ids
         */
        selectSet({ state, commit }, payload) {
          const { ids = [] } = payload
          const normalized = ids.reduce((acc, id) => ({ ...acc, ...state.all[String(id)] }), {})
          const refIds = Object.keys(normalized)

          if (refIds.length) {
            commit({
              type: types.ADD_NORMALIZED,
              object: normalized
            })

            commit({
              type: types.ADD_SELECTED,
              refId: refIds
            })
          }
        },

        /**
         * Create a new object and select it in Normalized with starting values in object parameter
         *  payload = { object: { key: value }}
         *
         * @param commit
         * @param payload
         *    @param object|null object   pre-fill blank object with these field: value pairs
         *    @param string  type         defaults to store type if null or not set
         * @returns {Promise}
         */
        async selectBlank({ commit, dispatch, state }, payload = {}) {
          const { object = {}, type: objectType = type, refId: existingRefId = null } = payload

          const refId = existingRefId || generateNormalizedRefId(objectType)
          commit({
            type: 'SET_FETCHING',
            id: 'blank',
            value: refId
          })

          const { object: defaultedObject } = await dispatch('buildDefaultObject', {
            type: objectType,
            embue: object
          })

          const normalized = normalize(
            { ...defaultedObject, full: 1 },
            true,
            refId,
            Object.keys(state.normalized || {})
          )

          commit({
            type: types.ADD_NORMALIZED,
            object: normalized
          })

          commit({
            type: types.ADD_SELECTED,
            refId
          })

          await dispatch('setOriginal', { refId, original: state.normalized })
          await dispatch('auditDependencies', { refId, immediate: true, force: true })

          commit({
            type: types.RESET_CHANGES,
            refId
          })

          return {
            ...payload,
            refId
          }
        },

        /**
         * From here are the state-dependant actions,
         *   these require a refId to function
         *   At minimum the payload second argument for these functions
         *     will ask for:
         *   @param string refId
         *   @param VueComponent|null button opetional
         *
         *   Each action may or may not require more entries in the payload
         *     object.
         */
        async deselect({ commit, state }, { refId }) {
          if (!(refId in state.normalized)) {
            return { refId }
          }

          const norm = state.normalized[refId]
          const id = String(norm[`${norm.type}_id`])
          const fetchedId = id in state.all ? id : `${norm.type}-${id}`

          commit({
            type: types.REMOVE_NORMALIZED,
            refId: [..._.makeArray(refId), ...state.trash]
          })

          commit({
            type: types.REMOVE_SELECTED,
            refId
          })

          if (fetchedId in state.all) {
            commit({
              type: types.REMOVE_FETCHED,
              id: fetchedId
            })
          }

          return { refId }
        },

        /**
         * Get the lat lon based on the provided address or query
         * @param dispatch
         * @param state
         * @param location
         * @returns {Promise<(string|Object<string, number>|boolean|{int64: number; uint64: number; sint64: number;
         *   fixed64: number; sfixed64: number}|string|number|{action: function(): *, title: string, class:
         *   string})[]|number[]>}
         */
        async getLatLong({ dispatch }, { location }) {
          const { payload } = await dispatch('ajax', {
            host: 'https://api.opencagedata.com',
            path: `/geocode/v1/json?key=5c4301e5f9514ca095dd4a00d870af49&q=${encodeURIComponent(location)}&pretty=1&no_annotations=1`
          })

          if (payload.results.length) {
            return [payload.results[0].geometry.lat, payload.results[0].geometry.long]
          }

          return [51.08754, -115.335624]
        },

        /**
         * Audit dependencies on the local object only. Will not go down or up the
         * parent/children chain.
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param rootState
         * @param payload
         *    @param string store       store name, ie: 'CostType', defaults to local store
         *    @param object set         normalized set to use to audit local dependencies,
         *                                defaults to local state's normalized
         *    @param string refId       defaults to first rootRefId found in provided set or
         *                                state.normalized
         *    @param object changes     embue these changes into the normalized to set to test
         *                                their effects etc. Indexed by refId ie: {
         *                                  [refId]: { [field]: changedValue, ...},
         *                                  ...,
         *                               }
         * @returns {Promise<{changes, latestSet, set}>}
         */
        async cascadeDependencies({ dispatch, rootState }, payload) {
          const {
            store = titleType,
            changes: preChanges = {},
            possibleDimensions = await dispatch(
              'Dimension/getPossibleDimensions',
              {},
              { root: true }
            )
          } = payload

          let { set = _.imm(rootState[store].normalized) } = payload

          const { refId = getNormalizedRootRefId(set) } = payload

          set = {
            ...set,
            [refId]: {
              ...set[refId],
              ...preChanges[refId]
            }
          }

          if (set) {
            set = JSON.parse(JSON.stringify(set))
          }

          // Unwrap the changes from their Proxy if there is one
          const unwrappedChanges = _.imm(preChanges)

          const [latestSet, changes] = await Parallel.work('Audit:cascadeDependencies', [
            set,
            refId,
            unwrappedChanges,
            possibleDimensions
          ])

          return {
            ...payload,
            changes,
            latestSet,
            set: latestSet
          }
        },

        /**
         * Audit this object so all fields/values equal what they should, but ONLY
         * audit the local context, given by refId, do not audit other objects in
         * the same normalized set.
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param string refId
         *    @param object changes       provide changes made and it will determine
         *                                  whether an audit should be done or not
         *                                  if not provided, an audit will always be done
         *    @param bool immediate       if set to true, will do audit immediately, if false
         *                                  will perform audit on a throttle
         * @returns {Promise<*>}
         */
        async auditLocalDependencies({ commit, state, dispatch, rootState }, payload = {}) {
          const {
            refId = null,
            // Local changes, ie; not normalized
            changes = {},
            immediate = null,
            delay: initialDelay = 750
          } = payload
          // const time = new Date().valueOf();
          let localConstructor = false
          const localType = (state.normalized[refId] && state.normalized[refId].type) || type
          if (refId && refId in state.normalized && localType) {
            localConstructor = getConstructor(localType)
          }

          const delay =
            (localConstructor &&
              (!localConstructor.fields.aoChildren || localConstructor.fastAudit === true) &&
              immediate !== false) ||
            immediate === true
              ? 0
              : initialDelay
          let newSet = {}
          let changeSet = {}
          let audited = false
          const oldSet = _.imm(state.normalized)

          if (
            state.normalized[refId] &&
            !state.normalized[refId].full &&
            state.normalized[refId][`${localType}_id`]
          ) {
            _.log('object not full, skipping local audit')
            return {
              ...payload,
              changeSet: {},
              newSet: oldSet,
              oldSet,
              audited
            }
          } else if (localConstructor && localConstructor.skipAudit) {
            _.log('skipping local audit', refId, localConstructor.skipAudit)
            return {
              ...payload,
              changeSet: {},
              newSet: oldSet,
              oldSet,
              audited
            }
          }

          const changeKeys = Object.keys(changes)
          if (changeKeys.length) {
            commit({
              type: types.ADD_UNAUDITED_CHANGES,
              changes: {
                [refId]: changes
              }
            })
          }

          const throttleKey = `local-audit-${localType}/${refId}/${delay}`

          // Throttle them
          await _.throttle(
            async () => {
              const t = new Date().valueOf()
              _.log('  local audit in throt: ', new Date().valueOf() - t, 'ms', refId)
              if (
                !state.unauditedChanges[refId] ||
                !Object.keys(state.unauditedChanges[refId]).length ||
                (await dispatch('shouldCascadeField', {
                  type: localType,
                  fields: Object.keys(state.unauditedChanges[refId])
                }))
              ) {
                _.log('  local audit decision: ', new Date().valueOf() - t, 'ms', refId)
                // All waiting done, so grab the state and clear list of unaudited fields (of this item only)
                // so that they can re-accumulate while local audit is running
                const norm = _.imm(state.normalized)
                const unaudited = { [refId]: state.unauditedChanges[refId] }
                commit({
                  type: types.CLEAR_UNAUDITED_CHANGES,
                  refId
                })
                _.log('  local audit clear unaudited: ', new Date().valueOf() - t, 'ms', refId)

                const possibleDimensions = _.getCacheItem(
                  'PossibleDimensions',
                  'dimension',
                  rootState.session
                )

                const changeSetPre = unaudited
                ;[newSet, changeSet] = cascadeDependencies(
                  norm,
                  refId,
                  changeSetPre,
                  possibleDimensions
                )
                // const [cascadeSet, cascadeChanges] = await Parallel.work('Audit:cascadeDependencies',
                //   [norm, refId, changeSetPre, possibleDimensions]);

                _.log('  local audit cascade done: ', new Date().valueOf() - t, 'ms', refId)
                audited = true

                commit({
                  type: types.ADD_NORMALIZED,
                  object: {
                    [refId]: newSet[refId]
                  }
                })
                dispatch('addChanges', {
                  changes: { [refId]: changeSet[refId] ?? {} }
                })
                _.log('  local audit added normalized: ', new Date().valueOf() - t, 'ms', refId)

                // if (Object.keys(changeSet).length) {
                //   // Pickup the changes that happend while audit was running, integrate them
                //   // over top of the new set derived from the audit
                //   const normAfter = _.imm(state.normalized);
                //   const intermittentChanges = diffNormalized(norm, normAfter);
                //   const combined = {
                //     ...normAfter,
                //     ...norm,
                //     ...newSet,
                //   };
                //   const integrated = integrateChangesToSet(newSet, combined, intermittentChanges);
                //   _.log('  local audit integrated: ', new Date().valueOf() - t, 'ms', refId);
                //
                //   commit({
                //     type: types.ADD_NORMALIZED,
                //     object: integrated,
                //   });
                //   _.log('  local audit added normalized: ', new Date().valueOf() - t, 'ms', refId);
                // }
              }
              _.log('local audit took: ', new Date().valueOf() - t, 'ms', refId)

              return Promise.resolve()
            },
            { delay, key: throttleKey }
          )

          return {
            ...payload,
            changeSet,
            newSet,
            oldSet,
            audited
          }
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @returns {Promise<void>}
         */
        async auditInPlace({ state, dispatch, rootGetters: getters }, payload) {
          const {
            targetRefId,
            object,
            insertType: addonType = 'replace', // replace|add
            normalized: norm = _.imm(state.normalized),
            target = norm[targetRefId],
            changes = {}
          } = payload

          let newNorm = {}

          // Add only ancestors necessary to newNorm so audit is fast
          let parentRefId = targetRefId || false
          while (parentRefId) {
            newNorm[parentRefId] = norm[parentRefId]
            parentRefId = norm[parentRefId]?.parentRefId
          }

          const replacing = !!(
            addonType === 'replace' ||
            (target.type !== 'assembly' && target.type !== 'quote')
          )

          let refIdToUse
          let parentRefIdToUse
          if (replacing) {
            refIdToUse = targetRefId
            parentRefIdToUse = target.parentRefId
          } else {
            // If we are adding to the parent, we add to target as child
            refIdToUse = 'mocked'
            parentRefIdToUse = targetRefId
            // Also we need to set the parent object's
            // children to ONLy the one item we are testing.
            newNorm[targetRefId].aoChildren = ['mocked'] // TODO should go to else?
          }

          const embue = {
            ...object
            // Qutantities should already be set,
            // cost_item_link_qty: target.cost_item_link_qty,
            // cost_item_qty_net: target.cost_item_qty_net,
            // cost_item_qty_net_base: target.cost_item_qty_net_base,
            // quote_qty_net: target.quote_qty_net,
            // quote_qty_net_base: target.quote_qty_net_base,
          }

          const defaulted = normalize(
            {
              ...buildDefaultObject(object.type === 'assembly' ? 'assembly' : 'cost_item', embue),
              refId: refIdToUse,
              parentRefId: parentRefIdToUse
            },
            false,
            refIdToUse
          )

          const mod = await dispatch(
            'Quote/getQuoteMod',
            {
              store: titleType,
              refId: targetRefId
            },
            { root: true }
          )

          Object.keys(defaulted).forEach((ref) => {
            defaulted[ref] = CostType.reMod(defaulted[ref], mod, getters.defaultMod).item
          })

          newNorm = {
            ...newNorm,
            ...defaulted
          }

          // const denorm = c.denormalize(newNorm, refIdToUse, true, true);
          // const renorm = c.normalize(denorm);
          const possibleDimensions = await dispatch(
            'Dimension/getPossibleDimensions',
            {},
            { root: true }
          )

          const dimensions = possibleDimensions
          const [set] = await Parallel.work(
            'Audit:auditDependencies',
            _.imm([newNorm, refIdToUse, dimensions, changes])
          )
          const auditedObject = denormalize(set, refIdToUse, true, true)

          return {
            object: auditedObject
          }
        },

        async shouldCascadeField(state, { type: localType, fields = [] }) {
          const fieldsClone = (fields && JSON.parse(JSON.stringify(fields))) || []
          return Parallel.work('Audit:shouldCascadeField', [localType, fieldsClone])
        },

        /**
         * Audit this object to make sure all the fields
         * equal what they should.
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param string refId       refId of object, will audit whole tree, but
         *                                will use refId to get rootRefId
         *    @param object changes     optional, { [field]: fieldValue }
         *    @param bool immediate     true = right away, false = 1500ms throttle
         *    @param bool force         force will audit even when changes don't dictate
         *                                it should need to.  If there are no changes
         *                                provided, it will also force an audit.
         *    @param bool queue         used to decide whether to queue on the entity level,
         *                                separately from force or immediate
         * @returns {Promise<*>}
         */
        async auditDependencies({ commit, state, dispatch }, payload = {}) {
          const {
            refId = null,
            // NORMALIZED changes only
            changes = {},
            immediate = null,
            // force = false,
            delay: initialDelay = getDefaultDelay(state),
            queue = true,
            integrate = false
          } = payload

          const norm = state.normalized
          const rootRefId = getNormalizedRootRefId(norm, refId)
          const rootObject = rootRefId && rootRefId in norm && norm[rootRefId]
          const objectType = rootObject && rootObject.type
          const localConstructor = objectType && getConstructor(objectType)

          if (
            !rootObject ||
            !objectType ||
            !localConstructor ||
            localConstructor.skipAudit ||
            (!rootObject.full && rootObject[`${objectType}_id`])
          ) {
            _.log('skipping audit')
            commit({
              type: types.SET_PROCESSING,
              processing: 0
            })
            return {
              ...payload,
              audited: false
            }
          }

          const delay =
            ((!localConstructor.fields.aoChildren || localConstructor.fastAudit === true) &&
              immediate !== false) ||
            immediate === true
              ? 0
              : initialDelay

          let newSet = {}
          let changeSet = {}
          let audited = false

          const changeKeys = Object.keys(changes)

          if (changeKeys.length && refId) {
            commit({
              type: types.ADD_UNAUDITED_CHANGES,
              changes
            })
          }
          commit({
            type: types.SET_PROCESSING,
            processing: 1
          })

          const throttleKey = `audit-${type}/${rootRefId}/${delay}`
          const { key: queueKey } = await dispatch('queueRequest', {
            ...payload,
            queue,
            key: `audit-${rootRefId}`,
            collapseIdenticalRequests: true
          })

          const possibleDimensions = await dispatch(
            'Dimension/getPossibleDimensions',
            {},
            { root: true }
          )

          // Throttle them
          try {
            await _.throttle(
              async () => {
                Perf.bench('audit start', rootRefId)
                let i = Date.now()
                commit({
                  type: types.SET_AUDITING,
                  auditing: 1 // clear all, because a full audit audits every item
                })
                console.debug('AUDITING - setauditing', Date.now() - i)
                i = Date.now()
                // const localType = (state.normalized[refId] && state.normalized[refId].type) || type;

                console.debug('AUDITING - refs', Date.now() - i)
                i = Date.now()
                // const allFields = allUnauditedRefs.length
                //   ? allUnauditedRefs.reduce((acc, ref) => ([
                //     ...acc,
                //     ...state.unauditedFields[ref],
                //   ]), [])
                //   : [];

                const normalized = state.normalized
                console.debug('AUDITING - normalized', Date.now() - i)
                i = Date.now()
                const unauditedChanges = _.imm(state.unauditedChanges)
                // );
                console.debug('AUDITING - unauditedChanges', Date.now() - i)
                i = Date.now()

                commit({
                  type: types.CLEAR_UNAUDITED_CHANGES,
                  refId: null // clear all, because a full audit audits every item
                })
                console.debug('AUDITING - CLEAR_UNAUDITED_CHANGES', Date.now() - i)
                i = Date.now()

                const shouldAudit =
                  !checkDependenciesFirst ||
                  Object.values(unauditedChanges)
                    .reduce((acc, obj) => [...acc, ...Object.keys(obj)], [])
                    .some((field) => dependencyTriggers.includes(field))
                console.debug('AUDITING - shouldAudit', Date.now() - i)
                i = Date.now()

                if (shouldAudit) {
                  try {
                    // );
                    // ;[newSet, changeSet] = auditDependencies(
                    //   normalized,
                    //   rootRefId,
                    //   possibleDimensions,
                    //   unauditedChanges
                    // )
                    ;[newSet, changeSet] = await Parallel.work('Audit:auditDependencies', [
                      _.imm(normalized),
                      rootRefId,
                      possibleDimensions,
                      unauditedChanges
                    ])
                    console.debug('AUDITING - Parallel', Date.now() - i)
                    i = Date.now()
                  } catch (e) {
                    console.log('parallel releases error as normal')
                  }

                  audited = true

                  if (Object.keys(changeSet).length) {
                    console.debug('AUDITING - changeSet', Date.now() - i)
                    i = Date.now()
                    // const normAfter = { ...state.normalized }
                    // const intermittentChanges = diffNormalized(normalized, normAfter)
                    // const combined = {
                    //   ...normAfter,
                    //   ...normalized,
                    //   ...newSet
                    // }
                    console.debug('AUDITING - diffNormalized', Date.now() - i)
                    i = Date.now()

                    const integrated = NormalizeUtilities.mergeChanges(newSet, changeSet, {
                      ...state.unauditedChanges
                    })
                    console.debug('AUDITING - integrateChangesToSet', Date.now() - i)
                    i = Date.now()

                    commit({
                      type: types.SET_NORMALIZED,
                      object: integrated,
                      integrate
                    })
                    console.debug('AUDITING - ADD_NORMALIZED', Date.now() - i)
                    i = Date.now()

                    dispatch('addChanges', {
                      changes: changeSet
                    })
                    console.debug('AUDITING - addChanges', Date.now() - i)
                    i = Date.now()
                    return true
                  }
                }

                Perf.bench('audit done', rootRefId, true)

                return true
              },
              { delay, key: throttleKey }
            )
          } finally {
            commit({
              type: types.SET_AUDITING,
              auditing: 0 // clear all, because a full audit audits every item
            })
            commit({
              type: types.SET_PROCESSING,
              processing: 0
            })
            dispatch('queueNext', { key: queueKey })
          }

          return {
            ...payload,
            changeSet,
            newSet,
            audited
          }
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         * @returns {Promise<any>}
         */
        async fieldEquation({ commit }, payload) {
          const { field, equation, refId } = payload

          commit({
            type: types.FIELD_EQUATION,
            refId,
            field,
            equation
          })

          return payload
        },

        /**
         *
         * @param commit
         * @param payload
         *    @param array fields     fields whose equations should be reset
         *    @param string refId     object to remove equations from
         * @returns {Promise<any>}
         */
        async unsetEquations({ commit }, payload) {
          const { fields, refId } = payload

          commit({
            type: types.FIELD_EQUATION_UNSET,
            refId,
            fields
          })

          return payload
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param object payload
         *    @param object changes             object full of key value pair changes { field: value }
         *    @param string|null refId          refId to reference object in vuex normalized state. if
         *                                         not set, it will assume the chnages object provided
         *                                         has keys that are refIds, with field value pairs
         *                                         inside the object the refId is the key for
         *                                         and we are setting multiple objects. If setting
         *                                         multiple objects, the explicit object must be in
         *                                         the same {[refId]: {[field]: value}} format as well.
         *    @param bool|null skipAudit        whether to skip audit of the whole state
         *    @param bool|null skipLocalAudit   whether to skip audit of this object only
         *    @param bool|object explicit         whether changes were explicit (a change not resulting from
         *                                          an audit). Provide an object if true, with field: value
         *                                          pairs of the changes that were explicit. These should
         *                                          also be in the changes object.
         *    @param bool|null immediate        whether to update and audit immediately or to make changes
         *                                           on a throttle.
         * @returns {Promise}
         */
        async field({ commit, dispatch }, payload) {
          const {
            changes,
            refId = false,
            skipAudit = false,
            skipLocalAudit = false,
            explicit = false,
            immediate = false
          } = payload

          const isNormalized = refId === false
          const normalizedChanges = isNormalized ? changes : { [refId]: changes }

          commit({
            type: isNormalized ? types.FIELDS : types.FIELD,
            changes,
            refId,
            explicit: false // managed directly, below
          })

          // Add explicit changes or regular changes in a single dispatch
          const explicitChanges = explicit && typeof explicit === 'object' ? explicit : changes
          const normalizedExplicitChanges = explicit
            ? isNormalized
              ? explicitChanges
              : { [refId]: explicitChanges }
            : normalizedChanges

          dispatch('addChanges', {
            explicitChanges: explicit ? normalizedExplicitChanges : undefined,
            changes: explicit ? undefined : normalizedChanges
          })

          if (!skipAudit || (!skipLocalAudit && isNormalized)) {
            // Full audit when skipAudit is false or skipLocalAudit is false in normalized mode
            const auditPayload = await dispatch('auditDependencies', {
              changes: normalizedChanges,
              immediate,
              force: isNormalized
            })
            return { ...payload, ...auditPayload }
          }

          if (skipAudit && !skipLocalAudit) {
            // Local audit when skipAudit is true and skipLocalAudit is false
            const localChanges = isNormalized ? changes : changes[refId]
            const auditPayload = await dispatch('auditLocalDependencies', {
              refId,
              changes: localChanges,
              immediate
            })
            return { ...payload, ...auditPayload }
          }

          return payload
        },

        /**
         * Transfer children from one parent to another using only refIds and parentRefId.
         * This is the leanest way to move children around, without affecting any other
         * data points on the parent or child.
         * @param state
         * @param commit
         * @param dispatch
         * @param payload {
         *    @param parentRefId required 'parentRefId1'
         *    @param childRefIds required ['refId1', 'refId2']
         *    @param field default 'aoChildren'
         *  }
         * @returns {Promise}
         */
        async transferChildren({ state, commit, dispatch }, payload) {
          const { parentRefId, childRefIds, field = 'aoChildren', skipAudit = false } = payload
          let changedParents = false
          // Remove any children that don't exist in the set
          const refIds = _.makeArray(childRefIds).filter(
            (childRefId) => childRefId in state.normalized
          )

          // check that no children are are actually parents being put into itself
          const ancestors = []
          let ar = parentRefId
          while (ar) {
            ancestors.push(ar)
            ar = state.normalized[ar].parentRefId
          }

          if (_.intersection(ar, childRefIds).length) {
            throw new UserError({
              userMessage: 'Children can only be their own ancestor in back to the future'
            })
          }

          const fieldCommits = []
          refIds.forEach((refId) => {
            // Remove from old parent's field (default aoChildren)
            let child = state.normalized[refId]

            if (!child) return

            fieldCommits.push({
              [refId]: { parentRefId }
            })

            if (child.parentRefId && child.parentRefId !== parentRefId) {
              changedParents = true
              let parent = state.normalized[child.parentRefId]
              let oldParentsNewChildren = [...parent[field]]
              let oldIndex = oldParentsNewChildren.indexOf(refId)
              if (oldParentsNewChildren.indexOf(refId) > -1) {
                oldParentsNewChildren.splice(oldIndex, 1)

                const changes = {
                  [parent.refId]: {
                    [field]: oldParentsNewChildren
                  }
                }
                fieldCommits.push(changes)

                // commit({
                //   type: types.ADD_EXPLICIT_CHANGES,
                //   rootRefId: getNormalizedRootRefId(state.normalized, parentRefId),
                //   changes: {
                //     [parentRefId]: {
                //       [field]: oldParentsNewChildren,
                //     },
                //   },
                // });
              }
              parent = null
              oldParentsNewChildren = null
              oldIndex = null
            }
            child = null
          })

          // Set final children for parents
          fieldCommits.push({
            [parentRefId]: { [field]: refIds }
          })

          let changeSet = NormalizeUtilities.mergeChanges(...fieldCommits)
          commit({
            type: types.FIELDS,
            changes: changeSet
          })
          dispatch('addChanges', { explicitChanges: changeSet })

          // commit({
          //   type: types.ADD_EXPLICIT_CHANGES,
          //   rootRefId: getNormalizedRootRefId(state.normalized, parentRefId),
          //   changes: {
          //     [parentRefId]: {
          //       [field]: refIds,
          //     },
          //   },
          // });

          // Now change new parents childrent to childRefids

          if (field === 'aoChildren' && changedParents && !skipAudit) {
            // audit on throttle
            const auditPayload = await dispatch('auditDependencies', {
              refId: parentRefId,
              force: true
            })
            const auditChangeSet = auditPayload.changeSet
            changeSet = NormalizeUtilities.mergeChanges(changeSet, auditChangeSet)
          }

          return {
            ...payload,
            changeSet
          }
        },

        /**
         *
         * @param dispatch
         * @param state
         * @param payload
         *    @param refId    refId of item to duplicate
         * @returns {Promise<void>}
         */
        async duplicateItem({ dispatch, state }, payload) {
          const { refId } = payload

          const { object } = await dispatch('resolveObject', { refId })

          const { object: resetObject } = await dispatch(
            `${_.titleCase(object.type)}/resetFlags`,
            { object },
            { root: true }
          )

          const parentRef = state.normalized[refId].parentRefId
          const at = state.normalized[parentRef].aoChildren.indexOf(refId)

          const {
            set: newSet,
            refId: newRefId,
            convertedRefIds,
            changeSet: addChildChanges
          } = NormalizeUtilities.addChild(
            state.normalized,
            resetObject,
            parentRef,
            at > -1 ? at + 1 : null
          )

          const auditPayload = await dispatch('auditUpAndSet', {
            set: newSet,
            prune: false,
            skipDefaulting: true,
            refId: newRefId
          })
          dispatch('addChanges', {
            explicitChanges: addChildChanges
          })
          const changeSet = NormalizeUtilities.mergeChanges(addChildChanges, auditPayload[1])

          return {
            set: newSet,
            refId: newRefId,
            convertedRefIds,
            changeSet
          }
        },

        /**
         *
         * @param state
         * @param commit
         * @param dispatch
         * @param payload
         *    @param parent   parent refId
         *    @param child    child refId to be replaced
         *    @param type     new child to replace with   cost_type, cost_item or assembly
         *    @param id       new child id to replace with (or 'blank')
         * @returns {Promise<void>}
         */
        async swapItem({ state, commit, dispatch }, payload) {
          const { parent, child, type: newType, id, field = 'aoChildren' } = payload

          const newChildType = newType === 'assembly' ? newType : 'cost_item'

          const { object: oldChild } = await dispatch('resolveObject', {
            refId: child
          })

          let embue = {}
          if (oldChild.type === 'assembly' || oldChild.type === 'quote') {
            if (newChildType === 'assembly') {
              embue = {
                quote_qty_net_base: oldChild.quote_qty_net_base,
                quote_qty_net: oldChild.quote_qty_net
              }
            } else {
              embue = {
                cost_item_qty_net_base: oldChild.quote_qty_net_base,
                cost_item_qty_net: oldChild.quote_qty_net,
                cost_item_link_qty: 1
              }
            }
          } else if (newChildType === 'assembly') {
            embue = {
              quote_qty_net_base:
                oldChild.unit_of_measure_id === 2 ? oldChild.cost_item_qty_net_base : 1,
              quote_qty_net:
                oldChild.unit_of_measure_id === 2
                  ? oldChild.cost_item_qty_net
                  : oldChild.quantity_multiplier
            }
          } else {
            embue = {
              cost_item_link_qty: oldChild.cost_item_link_qty
            }
            if (+oldChild.unit_of_measure_id === 2) {
              embue = {
                ...embue,
                cost_item_qty_net_base: oldChild.cost_item_qty_net_base,
                cost_item_qty_net: oldChild.cost_item_qty_net
              }
            } else {
              embue = {
                ...embue,
                cost_item_qty_net_base: 1,
                cost_item_qty_net: 1 * _.n(oldChild.quantity_multiplier)
              }
            }
          }

          const { object } = await dispatch(
            'Quote/getQuoteItem',
            {
              parent,
              embue,
              type: newType === 'assembly' ? 'assembly' : 'cost_type',
              id,
              at: state.normalized[parent][field].indexOf(child)
            },
            { root: true }
          )

          const { set: newSet, refId } = replaceChild(state.normalized, object, child)

          commit({
            type: types.SET_NORMALIZED,
            object: newSet,
            refId: parent
          })
          dispatch('addChanges', {
            explicitChanges: NormalizeUtilities.extractDescendants(newSet, [refId], true),
            added: [refId],
            removed: [child]
          })

          await dispatch('auditDependencies', {
            refId: parent,
            immediate: true,
            force: true
          })

          return {
            object,
            refId
          }
        },

        async fetchField({ dispatch }, payload) {
          const { field, id } = payload

          const { message: value } = await dispatch(
            'ajax',
            {
              path: `/${type}/fetchField/${id}/${field}`,
              scope: payload.scope || null
            },
            { root: true }
          )

          return value
        },

        /**
         * Replace child with refId given by 'child' in the aoChildren
         * @param state
         * @param commit
         * @param dispatch
         * @param payload
         * @returns {Promise}
         */
        async replaceChild({ commit, dispatch, rootState, rootGetters: getters }, payload) {
          const {
            // provide a refId, selected: [], object: {}, or id (and type) for resolveObject, to
            // find the object that you want to replace child with, below,

            // child refId to replace
            child,

            // parent field that child can be found
            // field = 'aoChildren',

            // name of the store
            store = titleType,

            // norm
            norm = rootState[store].normalized,

            // parentRefId
            parent = norm[child].parentRefId,

            // new child object type, used by resolveObject(),
            //  not picked up here because not used locally
            // --> type,

            // object to embue new values to fetched object
            embue = {}
          } = payload

          let { object } = await dispatch('resolveObject', payload)
          object = {
            ...object,
            ...embue
          }

          const mod =
            object.mod_labor_net && object.mod_materials_net
              ? {
                  mod_labor_net: object.mod_labor_net,
                  mod_materials_net: object.mod_materials_net,
                  mod_id: object.mod_id || null
                }
              : await dispatch(
                  'Quote/getQuoteMod',
                  {
                    store,
                    refId: parent
                  },
                  { root: true }
                )

          const { set: newSet, refId } = replaceChild(norm, object, child)

          const children = NormalizeUtilities.extractDescendants(
            newSet,
            [refId],
            true,
            (it) =>
              newSet[it].type !== 'assembly' &&
              (newSet[it].cost_type_is_indexed || newSet[it].labor_type_is_indexed)
          )

          Object.keys(children).forEach((ref) => {
            children[ref] = CostType.reMod(children[ref], mod, getters.defaultMod).item
          })

          commit({
            type: types.SET_NORMALIZED,
            object: {
              ...newSet,
              ...children
            },
            refId: parent
          })
          dispatch('addChanges', {
            explicitChanges: children,
            added: Object.keys(children),
            removed: Object.keys(NormalizeUtilities.extractDescendants({ ...norm }, child, true))
          })

          await dispatch('auditDependencies', {
            refId: parent,
            immediate: true,
            force: true
          })

          return {
            ...payload,
            object,
            refId
          }
        },

        /**
         * For a refId (e.g. - Task.refId), replaces all children references in normalized
         * with new normalized children references
         * - replaces aoChildren by default
         * @param state
         * @param commit
         * @param dispatch
         * @param payload
         *    @param refId parent ref id
         *    @param children array of children ref ids
         *    @param field  child field
         *    @param embue  object to fill in children
         * @returns {Promise}
         */
        async replaceChildren({ state, commit, dispatch }, payload) {
          const {
            refId,
            children = [],
            field = 'aoChildren',
            embue = {},
            normalized: isNormalized = false
          } = payload

          const parentObject = state.normalized[refId]
          const parentConstructor = getConstructor(parentObject.type)
          let normalized = []
          let refIds = []

          if (!children.length && !isNormalized) {
            const changes = {
              [refId]: {
                [field]: parentConstructor.fields[field].type === 'object' ? null : []
              }
            }
            commit({
              type: types.FIELDS,
              changes
            })
            dispatch('addChanges', { changes })
          } else if (
            !shouldNormalizeField(parentConstructor, field) &&
            typeof children[0] === 'object'
          ) {
            const changes = {
              [refId]: {
                [field]: children
              }
            }
            commit({
              type: types.FIELDS,
              changes
            })
            dispatch('addChanges', { changes })
          } else if (!isNormalized) {
            const arrayChildren = _.makeArray(children)
            ;[normalized, refIds] = normalizeSet(arrayChildren, { parentRefId: refId, ...embue })

            normalized[refId][field] =
              parentConstructor.fields[field].type === 'object' ? refIds[0] : refIds

            commit({
              type: types.ADD_NORMALIZED,
              object: normalized
            })
            dispatch('addChanges', {
              explicitChanges: { [refId]: { [field]: normalized[refId][field] } }
            })
          } else {
            const { set: newSet } = replaceChildren(state.normalized, refId, children)
            commit({
              type: types.SET_NORMALIZED,
              refId,
              object: newSet,
              prune: false // must be false because if child is moved to different parent,
              // we don't want it to be removed yet
            })
            dispatch('addChanges', {
              explicitChanges: { [refId]: { [field]: newSet[refId][field] } }
            })
          }

          const audit =
            field === 'aoChildren' &&
            (!isNormalized || _.difference(children, state.normalized[refId].aoChildren).length)

          if (audit) {
            dispatch('auditDependencies', {
              refId,
              force: true
            })
          }

          return { ...payload, set: normalized }
        },

        /**
         *
         * @returns {Promise<*>}
         */
        async getStoreName() {
          return _.titleCase(type)
        },
        /**
         *
         * @param state
         * @param commit
         * @param dispatch
         * @param payload
         *    @param string refId         refId of parent that you're adding child to.
         *    @param object child         Required. Full child to add, denormalized.
         *    @param string field         defaults 'aoChildren', field child resides in
         *    @param bool skipAudit       defaults false
         * @returns {Promise}
         */
        async addChildren({ state, commit, dispatch, rootGetters: getters }, payload) {
          const {
            refId,
            children = [],
            field = 'aoChildren',
            position = -1,
            skipAudit = false
          } = payload

          let resetSet = (
            await Promise.all(
              children.map((child) =>
                dispatch(
                  `${_.titleCase(child.type)}/resetFlags`,
                  {
                    resetAddons: true,
                    object: child
                  },
                  { root: true }
                )
              )
            )
          ).map((returnPayload) => {
            if (returnPayload.object.type === 'cost_item') {
              returnPayload.object.cost_item_markup_net_adjusted =
                returnPayload.object.cost_matrix_markup_net
            }
            return returnPayload.object
          })
          resetSet = await dispatch('Dimension/verifyDimensions', { set: resetSet }, { root: true })
          if (resetSet.length === 0) return { ...payload }

          let [set, refIds] = normalizeSet(resetSet, { parentRefId: refId }, false)
          const { set: rereferenced, convertedRefIds } = rereference(set, Object.keys(set))
          refIds = refIds.map((r) => convertedRefIds[r])
          set = rereferenced

          // Apply modification factors to each cost_item
          const mod = await dispatch(
            'Quote/getQuoteMod',
            {
              store: titleType,
              refId
            },
            { root: true }
          )

          const descendants = NormalizeUtilities.extractDescendants(
            rereferenced,
            refIds,
            true,
            (it) =>
              set[it].type !== 'assembly' &&
              (set[it].cost_type_is_indexed || set[it].labor_type_is_indexed)
          )

          Object.keys(descendants).forEach((ref) => {
            descendants[ref] = CostType.reMod(descendants[ref], mod, getters.defaultMod).item
          })

          const pruned = {
            ...set,
            ...descendants
          }

          const combined = {
            ..._.imm(state.normalized),
            ...pruned
          }

          const childrenRefIds = [...combined[refId][field]]

          childrenRefIds.splice(position, 0, ...refIds)

          refIds.forEach((rref) => {
            combined[rref].parentRefId = refId
          })

          combined[refId][field] = childrenRefIds
          commit({
            type: types.SET_NORMALIZED,
            object: combined,
            refId
          })
          const changes = {
            ...pruned,
            [refId]: {
              [field]: childrenRefIds
            }
          }
          dispatch('addChanges', {
            added: Object.keys(pruned),
            explicitChanges: changes
          })

          const changeManager = await dispatch('getChangeManager', { refId })
          changeManager.trigger('added', childrenRefIds)

          if (field === 'aoChildren' && !skipAudit) {
            await dispatch('auditDependencies', {
              refId,
              immediate: true,
              force: true
            })
          }

          return {
            ...payload,
            object: combined,
            set: combined,
            childSet: descendants,
            childrenRefIds,
            addedChildrenRefIds: refIds,
            convertedRefIds
          }
        },

        async addChild({ dispatch }, payload) {
          const { child } = payload
          return dispatch('addChildren', {
            children: [child],
            ...payload
          })
        },

        /**
         *
         * @param state
         * @param commit
         * @param dispatch
         * @param payload
         * @returns {*}
         */
        async removeChildren({ state, commit, dispatch }, payload) {
          const { refIds } = payload
          if (!refIds.length) return payload

          // Find parents but only the ones that will remain
          const parents = _.difference(
            _.cleanArray(refIds.map((rr) => state.normalized[rr].parentRefId)),
            refIds
          )
          if (!parents.length) return payload

          let newSet = _.imm(state.normalized)
          const changes = []
          refIds.forEach((rref) => {
            const { set, changes: ch } = removeChild(newSet, rref, false)
            newSet = set
            changes.push(ch)
          })

          const fullChanges = NormalizeUtilities.mergeChanges(...changes)
          const deorphaned = removeOrphaned(newSet)

          const changeManager = await dispatch('getChangeManager', { parentRefId: parents[0] })
          changeManager.trigger('removed', refIds)

          commit({
            type: types.SET_NORMALIZED,
            object: deorphaned,
            prune: false,
            skipDefaulting: true,
            refId: parents[0]
          })
          dispatch('addChanges', {
            added: refIds,
            explicitChanges: fullChanges
          })

          _.throttle(() =>
            dispatch('auditDependencies', {
              force: true,
              immediate: true,
              refId: parents[0],
              integrate: true
            })
          )

          return payload
        },

        /**
         * Provide the refId of the child to remove.  It will also remove itself from the parent.
         * @param state
         * @param commit
         * @param dispatch
         * @param payload
         * @returns {Promise}
         */
        async removeChild({ dispatch }, payload) {
          const { refId } = payload
          return dispatch('removeChildren', { refIds: [refId] })
        },

        async auditUpAndSet({ commit, dispatch }, payload) {
          const { set, refId, prune = false, skipDefaulting = true } = payload

          const [audited, changeSet] = await dispatch('auditUp', { set, refId })

          commit({
            type: types.SET_NORMALIZED,
            object: audited,
            prune,
            skipDefaulting,
            refId
          })
          dispatch('addChanges', {
            changes: changeSet
          })

          return [audited, changeSet]
        },

        async auditUp({ dispatch }, { set, refId }) {
          const possibleDimensions = await dispatch(
            'Dimension/getPossibleDimensions',
            {},
            { root: true }
          )
          const [newSet, changeSet] = await Parallel.work('Audit:auditUp', [
            set,
            refId,
            possibleDimensions
          ])

          return [newSet, changeSet]
        },

        /**
         * Adds the server-retreived model to the cached 'all' item in state.
         * @param commit
         * @param state
         * @param id
         * @param button
         * @returns {Promise}
         */
        async fetch({ commit, state, dispatch }, payload) {
          const {
            id,
            button = null,
            unset = false,
            force = true,
            full = true,
            quick = false,
            type: objectType = type,
            refId = null
          } = payload

          const storeName = _.titleCase(objectType)

          const stringId = String(id)

          if (!force && stringId in state.all) {
            const denorm = denormalize(state.all[stringId], null, true, true)
            if (denorm && (denorm.full || !full)) {
              return {
                id,
                button,
                object: denorm
              } // Already in there, resolve
            }
          }

          const fetchPayload = await _.throttle(
            () =>
              dispatch('ajax', {
                path: `${objectType}/${quick ? 'quickF' : 'f'}etch/${id}`,
                button,
                scope: payload.scope || null
              }),
            { delay: 150, key: `${objectType}:${id}` }
          )

          // Clear previous fetched, if required
          if (unset) {
            commit({
              type: types.UNSET_FETCHED
            })
          }

          const { object: defaultedObject } = await dispatch('buildDefaultObject', {
            embue: fetchPayload.object
          })

          commit(
            `${storeName}/${types.ADD_FETCHED}`,
            {
              object: defaultedObject,
              refId,
              normalized: false
            },
            { root: true }
          )

          return { ...payload, ...fetchPayload }
        },

        /**
         * rereferences
         * @param state
         * @param normalized
         * @param refsToConvert
         * @param refsToConvertTo
         * @returns {{set, convertedRefIds, changeSet}}
         */
        rereference(
          state,
          { normalized, refsToConvert = Object.keys(normalized), refsToConvertTo = [] }
        ) {
          return rereference(normalized, refsToConvert, refsToConvertTo)
        },

        /**
         * Adds the server-retreived model to the cached 'all' item in state.
         * Fetches as normalized, which is quicker.
         *
         * @param commit
         * @param state
         * @param id
         * @param button
         * @returns {normalized, object, rootRefId}
         */
        async fetchNormalized({ commit, state, dispatch }, payload) {
          const {
            id,
            button = null,
            unset = false,
            force = false,
            type: objectType = type,
            refId = null,
            leadRequestId = null,
            showLatestSentQuote = false
          } = payload

          const storeName = _.titleCase(objectType)

          const stringId = String(id)

          if (!force && stringId in state.all) {
            const norm = state.all[stringId]
            const rootRefId = getNormalizedRootRefId(norm)

            // If we need to have a specific refId, skip this,
            // fetch and allow to rereference,
            if (!refId || refId === rootRefId) {
              return {
                normalized: norm,
                object: norm,
                rootRefId
              } // Already in there, resolve
            }
          }

          Perf.bench('fetch norm 1', `${type}${id}`)
          let result = null

          if (leadRequestId) {
            result = await dispatch('ajax', {
              path: 'lead_request/fetchNormalizedQuote',
              data: {
                lead_request_id: leadRequestId,
                quote_id: id
              }
            })
          } else {
            result = await dispatch('ajax', {
              path: `${objectType}/fetchNormalized/${id}`,
              button,
              scope: payload.scope || null,
              data: {
                showLatestSentQuote
              }
            })
          }
          const fetched = result.object

          const pruned = fetched.normalized // pruneNormalized(fetched.normalized);

          Perf.bench('fetch norm 2', `${type}${id}`)
          let rootRefId = fetched.rootRefId
          let normalized = removeOrphaned(pruned)

          if (refId && refId !== rootRefId) {
            const { set: rereferenced } = rereference(normalized, [rootRefId], [refId])

            rootRefId = refId
            normalized = rereferenced
          }
          Perf.bench('fetch norm 3', `${type}${id}`)

          // Clear previous fetched, if required
          if (unset) {
            commit({
              type: types.UNSET_FETCHED
            })
          }
          Perf.bench('fetch norm before default', `${type}${id}`)

          const { object: defaultedNormalized } = await dispatch('buildDefaultObjectNormalized', {
            embue: normalized
          })

          Perf.bench('fetch norm 4 after default', `${type}${id}`, true)
          const dimensions = await dispatch('Dimension/getPossibleDimensions', {}, { root: true })

          const auditPayload = await auditDependencies(defaultedNormalized, rootRefId, dimensions)
          const audited = auditPayload[0]

          commit(
            `${storeName}/${types.ADD_FETCHED}`,
            {
              object: { ...audited },
              refId: rootRefId,
              normalized: true,
              audit: false
            },
            { root: true }
          )

          return { normalized: audited, object: audited, rootRefId }
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         * @returns {Promise<void>}
         */
        async selectFromObject({ dispatch }, payload = {}) {
          const { object = {} } = payload
          const { id: setId } = await dispatch('fillFetch', {
            object
          })

          await dispatch('selectExisting', {
            id: setId
          })

          return payload
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param object
         * @param button
         * @param unset
         * @param force
         * @returns {Promise<*>}
         */
        async fillFetch(
          { commit, dispatch, rootState },
          { object, button = null, unset = false, force = true }
        ) {
          const objectType = object.type || type
          const storeName = _.titleCase(objectType)
          const store = rootState[storeName]
          const id = object[`${objectType}_id`]

          if (!force && String(id) in store.all) {
            return {
              id,
              button,
              normalized: store.all[String(id)]
            } // Already in there, resolve
          }

          if (unset) {
            commit({
              type: types.UNSET_FETCHED
            })
          }
          const { object: defaultedObject } = await dispatch('buildDefaultObject', {
            embue: object
          })

          commit(
            `${storeName}/${types.ADD_FETCHED}`,
            {
              object: defaultedObject,
              normalized: false,
              id
            },
            { root: true }
          )

          return {
            id,
            normalized: store.all[String(id)],
            button
          }
        },

        /**
         * -Inserts ID's, for example you create a new object where the ID is determined
         * in the server, this function inserts that id into the new object
         * -Reloads other fields marked as reload: true, in field schemas from
         * (api/schemas/{Entity}.js).fields..
         * -If 'originalRefId' is present in the data that is returned, AND that originalRefId
         * is found in the normalized set, then swap refIds from originalRefId to 'refId' so that
         * server-determined refIds are updated on update too (like for items).
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param object object    denormalized object (optional,
         *                              or can provded normalized, a normalized set)
         *    @param string refId     if providing a normalized set this is required
         *    @param object normalized
         * @returns {Promise<*>}
         */
        async selectiveReload({ commit, state, dispatch }, payload) {
          const { object: rootObject, refId: originalRootRefId = rootObject.refId } = payload

          let { normalized = null } = payload

          if (!normalized) {
            if (!rootObject) {
              throw new Error('Selective reload requires at least one of object, or normalized')
            }
            normalized = normalize(rootObject, false, originalRootRefId)
          }

          // Do a deep merge so that field changes since change are not lost
          const stateNorm = _.imm(state.normalized)
          let current = stateNorm

          const explicit = {}
          let refIdsToRemove = []

          let rootRefId = originalRootRefId
          if (!rootRefId || !(rootRefId in state.normalized)) {
            return payload
          }

          // FIRST Rereference ids where originalRefId != refId
          // then carryon
          let refIds = Object.keys({ ...current, ...normalized })
          const originalRefIds = Object.keys(stateNorm)
          const refsToChangeTo = refIds.filter(
            (refId) =>
              !originalRefIds.includes(refId) &&
              normalized[refId] &&
              normalized[refId].originalRefId &&
              originalRefIds.includes(normalized[refId].originalRefId)
          )
          const refsToChange = refsToChangeTo.map((refId) => normalized[refId].originalRefId)

          let firstConvertedRefIds = {}
          if (refsToChange.length) {
            const { set, convertedRefIds } = rereference(current, refsToChange, refsToChangeTo)
            firstConvertedRefIds = convertedRefIds

            current = set

            // Remove all rereferenced items now
            if (Object.keys(convertedRefIds).length) {
              if (rootRefId in convertedRefIds) {
                rootRefId = convertedRefIds[rootRefId]
              }
              refIdsToRemove = [...refIdsToRemove, ...Object.keys(convertedRefIds)]
            }
          }

          // Make sure root isn't removed
          if (refIdsToRemove.includes(originalRootRefId)) {
            refIdsToRemove.splice(refIdsToRemove.indexOf(refIdsToRemove), 1)
          }

          // If the rootRef "Should" be rereferenced, we actually make sure it
          // is rereferenced BACK to the original because components
          // will reference the root ref directly
          const newRootRefId = getNormalizedRootRefId(current)
          if (newRootRefId !== originalRootRefId) {
            const { set } = rereference(current, [newRootRefId], [originalRootRefId])

            rootRefId = originalRootRefId

            current = set
          }

          // // Commit field changes
          // commit({
          //   type: types.FIELDS,
          //   changes: _.merge(rerefChangeSet, rerefChangeSetBack),
          //   explicit: false,
          // });

          // NOW do regular reload process after refIds have been updated
          refIds = Object.keys(current)
          refIds.forEach((refId) => {
            // get new refid (even if it was converted back later)
            const newRefId = refId in firstConvertedRefIds ? firstConvertedRefIds[refId] : refId

            // If there is no existing entry, then a selective reload is not necessary
            if (!(newRefId in normalized) || !(refId in current)) {
              return
            }

            const object = normalized[newRefId]
            const objType = object.type
            const denormExisting = current[refId]

            const reloadConstructor = getConstructor(objType)
            const reloadFields = reloadConstructor.fields || {}

            // Add all ID fields, always, if it is new or changed
            Object.keys(object)
              .filter((f) => /_id$/.test(f))
              .forEach((idField) => {
                if (
                  (!(idField in reloadFields) ||
                    !reloadFields[idField].reload ||
                    reloadFields[idField].reload !== false) &&
                  !fieldComparison(
                    denormExisting[idField] || null,
                    object[idField],
                    idField,
                    reloadConstructor.fields,
                    false,
                    false
                  )
                ) {
                  const idValue = object[idField] || state.normalized[refId][idField]
                  explicit[refId] = {
                    ...(explicit[refId] || {}),
                    [idField]: idValue
                  }

                  current[refId] = {
                    ...(current[refId] || {}),
                    [idField]: idValue
                  }
                }
              })

            // Do a selective reload, based on the { reload: true }
            // setting in the field schema of this object type
            const fields = reloadFields
            const fieldKeys = Object.keys(fields)

            fieldKeys.forEach((f) => {
              if (fields[f].reload !== true) {
                return
              }

              /*
              For a normalized field, we need to addChild procedure instead
              which roughly follows the same as addChild()
              if (shouldNormalizeField(reloadConstructor, f)
                && object[f]
                && ((typeof object[f] === 'object' && Object.keys(object[f]).length)
                  || (Array.isArray(object[f]) && object[f].length))) {
                const a = _.makeArray(object[f]);

                const typeArray = f in reloadConstructor.fields
                  && reloadConstructor.fields[f].type === 'array';

                a.forEach((child) => {
                  const normed = normalize(child, false, null, refIds);

                  current = {
                    ...current,
                    ...normed,
                  };

                  const normedRefIds = Object.keys(normed);
                  current[refId][f] = typeArray ? normedRefIds : normedRefIds[0];
                });

                return true;
              }
              */

              current[refId] = {
                ...current[refId],
                [f]: object[f]
              }

              // Otherwise, just add in field as normal
              if (
                denormExisting[f] !== object[f] &&
                !fieldComparison(denormExisting[f], object[f], f, fields, false, true)
              ) {
                // Only add to explicit when we track changes on the field
                // and it is not substantially the same
                explicit[refId] = {
                  ...(explicit[refId] || {}),
                  [f]: object[f]
                }
              }
            })
          })

          // Remove any items that were not specifically re-referenced,
          // but may have had refIds change, for example aoStageTasks,
          // and are no longer part of the quote schema
          const orphans = getOrphanedRefIds(current)
          refIdsToRemove = [...refIdsToRemove, ...orphans]

          // Get current chagnes
          const changes = await dispatch('getChanges', {
            normalized: true,
            refId: originalRootRefId
          })
          const integrated = integrateChangesToSet(
            current,
            {
              ..._.imm(state.normalized),
              ...current
            },
            changes
          )

          // Add back full set, overwriting
          commit({
            type: types.ADD_NORMALIZED,
            object: integrated,
            integrate: true
          })

          commit({
            type: types.REMOVE_NORMALIZED,
            refIds: refIdsToRemove
          })

          dispatch('addChanges', {
            explicitChanges: explicit
          })

          return {
            ...payload,
            set: _.imm(current)
          }
        },

        /**
         *
         * @param state
         * @returns {Promise<void>}
         */
        async clearRequestQueue({ state, commit }) {
          Object.keys(state.resolveQueue).forEach((key) =>
            commit({
              type: types.SHIFT_REQUEST_QUEUE,
              key
            })
          )
        },

        /**
         *
         * @param state
         * @param commit
         * @param payload
         *    @param bool queue
         *    @param string|null key                    grouping key
         *    @param bool collapseIdenticalRequests     if two request are similar, disregard
         *                                                previous?
         * @returns {*}
         */
        async queueRequest({ state, commit }, payload = {}) {
          const { queue = true, key: givenKey = null, collapseIdenticalRequests = false } = payload

          const requestQueue = Object.values({ ...state.requestQueue })
          const key = collapseIdenticalRequests
            ? givenKey
            : `${givenKey || 'new'}-${new Date().valueOf()}`

          if (queue) {
            // Completes when existing queue items are complete, or if
            // there are no queue items, then resolves immediately.
            let continueResolve
            const continuePromise = new Promise((r) => {
              continueResolve = r
            })
            // Completes when the chain that calls the queue request completes
            let doneResolve
            const donePromise = new Promise((r) => {
              doneResolve = r
            })

            commit({
              type: types.ADD_REQUEST_QUEUE,
              key,
              promise: donePromise,
              resolve: () => doneResolve()
            })

            if (requestQueue.length) {
              // - immediately add donePromise to queue
              // - wait for all previous promises to complete before continuing
              // - when current chain finalizes:
              //    - call doneResolve
              //    - remove donePromise from queue
              Promise.all(requestQueue).finally(() => {
                continueResolve({ key })
              })
            } else {
              // - immediately add donePromise to queue
              // - immidately continue
              // - when current chain finalizes:
              //    - call doneResolve
              //    - remove donePromise from queue
              continueResolve({ key })
            }

            await continuePromise

            return { key }
          }

          // Do nothing
          // add blanks
          commit({
            type: types.ADD_REQUEST_QUEUE,
            key,
            promise: Promise.resolve(),
            resolve: () => {}
          })

          return { key }
        },

        /**
         *
         * @param commit
         * @param state
         * @param key
         * @returns {Promise<void>}
         */
        queueNext({ commit, state }, { key }) {
          if (!key) {
            throw new Error('No key provided to queue next.  This will hang all actions')
          }

          if (Object.keys(state.requestQueue).length) {
            commit({
              type: types.SHIFT_REQUEST_QUEUE,
              key
            })
          }

          return Promise.resolve()
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param asNew
         *    @param embue
         *    @param force
         *    @param changes
         *    @param grid
         *    @param go
         *    @param button
         *    @param reset
         *    @param alert
         * reload - loads server values back into normalized, default = false
         * @returns {Promise}
         */
        async save({ commit, state, dispatch }, payload) {
          const {
            // reset = false,
            alert = true,
            go = true,
            grid = null,
            changes = true,
            force = false,
            embue = {},
            asNew = false,
            method = 'save',
            except = [],
            scope = null //  requestedScope
          } = payload
          let queueKey

          try {
            Perf.bench('queue start', 'sv')
            const { key } = await dispatch('queueRequest', {
              ...payload,
              key: `save-${type}`
            })
            queueKey = key

            const { object, refId = null } = payload.object
              ? payload
              : await dispatch('resolveObject', {
                  ...payload,
                  audit: true,
                  queue: false
                })
            let data = object

            let expChanges = []
            let allChanges = []
            if (changes && refId) {
              allChanges = [
                await dispatch('getChanges', {
                  refId,
                  normalized: true
                })
              ]
              expChanges = [
                await dispatch('getExplicitChanges', {
                  refId,
                  normalized: true
                })
              ]
            }

            if (Object.keys(embue).length) {
              data = { ...data, ...embue }
            }

            if (
              asNew ||
              (data.type === 'cost_type' && data.cost_type_status === '@' && scope.company)
            ) {
              data[`${type}_id`] = null
            }

            // Remove exceptions
            if (except.length) {
              data = Object.keys(data)
                .filter((field) => !except.includes(field))
                .reduce(
                  (acc, field) => ({
                    ...acc,
                    [field]: data[field]
                  }),
                  {}
                )
            }

            // Localized ajax, automatically delays for processing
            const ajaxPayload = await dispatch('ajax', {
              path: `${type}/${method}`,
              data: {
                object: data,
                changes: allChanges,
                explicitChanges: expChanges,
                force
              },
              scope: scope || null,
              queue: false
            })
            const { object: saved } = ajaxPayload
            Perf.bench('saving done', 'sv', 1)

            dispatch('clearCache')

            const { object: defaulted } = await dispatch('buildDefaultObject', {
              object: saved,
              embue: saved,
              queue: false,
              type
            })

            const normalized = normalize(defaulted, false, refId)
            const dimensions = await dispatch('Dimension/getPossibleDimensions', {}, { root: true })
            const audited = (
              await Parallel.work('Audit:auditDependencies', [normalized, refId, dimensions])
            )[0]

            const rootRefId = getNormalizedRootRefId(state.normalized, refId)

            const react = () => {
              if (alert) {
                dispatch('alert', { text: 'Saved!' }, { root: true })
              }
              if (go) {
                dispatch('go', { object: defaulted }, { root: true })
              }
              if (grid) {
                grid.reload(true)
              }
            }

            const storeName = c.titleCase(type)
            commit(
              `${storeName}/${types.ADD_FETCHED}`,
              {
                object: audited,
                audit: false,
                normalized: true,
                refId
              },
              { root: true }
            )

            if (refId && state.normalized && refId in state.normalized) {
              // Do a selective reload in state.normalized.refId
              // from saved version
              // depending if reload: true on field schemas
              const { set: reloadedSet } = await dispatch('selectiveReload', {
                refId: data.refId,
                normalized: audited,
                queue: false
              })

              // changeManager.setCurrent(state.normalized);

              // Clear changes but only for this root item
              if (data.type === type && rootRefId === refId) {
                await dispatch('setOriginal', { refId, original: state.normalized })
                const changeManager = await dispatch('getChangeManager', { refId })
                await dispatch('resetChangeManager', { changeManager })
              }

              // build necessary objects for return
              const auditedObj = denormalize(reloadedSet, refId, true, true)

              const hybrid = {
                ...auditedObj,
                refId,
                parentRefId: auditedObj.parentRefId
              }

              react()

              return {
                ...payload,
                ...ajaxPayload,
                ajaxPayload,
                object: hybrid,
                set: [hybrid],
                reload: true
              }
            }

            react()

            const auditDenormalized = denormalize(audited)

            return {
              ...payload,
              ...ajaxPayload,
              ajaxPayload,
              newSet: {},
              changeSet: {},
              object: auditDenormalized,
              set: [auditDenormalized],
              reload: true
            }
          } catch (error) {
            const {
              userMessage = 'Could not save that object, try again and please contact support if the problem persists.'
            } = error

            if (alert) {
              dispatch(
                'alert',
                {
                  text: userMessage,
                  error: true
                },
                { root: true }
              )
            }

            throw new UserError(
              {
                userMessage,
                hint: error.message || 'save failed',
                action: 'save'
              },
              error
            )
          } finally {
            dispatch('queueNext', { key: queueKey })
          }
        },
        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload = {
         *    @param changes Object
         *      ie:
         *      {
         *        refId: {
         *          field: value,
         *          field: value,
         *          ...
         *        },
         *        refId: { ... },
         *        refId: { ... },
         *        ...
         *      }
         *    @param alert Boolean    whether to alert on error
         * }
         * @returns {Promise}
         */
        async saveChanges({ commit, state, dispatch }, payload) {
          const { changes = {}, alert = true } = payload

          const refIds = Object.keys(changes)

          // Get list of changes to send if items have been saved before
          // or send the whole object if never saved before.
          const objectTypes = []
          const sendData = refIds.map((refId) => {
            const obj = state.normalized[refId]
            const objType = obj.type
            const idField = `${objType}_id`
            const id = obj[idField]

            if (!objectTypes.includes(type)) {
              objectTypes.push(type)
            }

            return {
              ...(id ? changes[refId] : obj),
              type: obj.type,
              [idField]: id,
              refId
            }
          })

          // If provided with different object types, we do
          // one call per type
          await Promise.all(
            objectTypes.map(async (objType) => {
              const typeData = sendData.filter((data) => data.type === objType)

              let set = []
              try {
                const { set: savedSet } = await dispatch('ajax', {
                  path: `${objType}/saveChanges`,
                  data: typeData,
                  scope: payload.scope || null
                })
                set = savedSet
              } catch (error) {
                if (import.meta.env.DEV) {
                  console.log(error)
                }

                if (alert) {
                  dispatch(
                    'alert',
                    {
                      error: true,
                      message: error.userMessage || 'There was an error saving changes. Try again.'
                    },
                    { root: true }
                  )
                }

                throw new UserError(
                  {
                    userMessage:
                      error.userMessage || 'There was an error saving changes. Try again.'
                  },
                  error
                )
              }

              // Build out full set of objects saved for this object type
              const fullSet = set.map((obj) => ({
                ...buildDefaultObject(objType, obj),
                ..._.imm(state.all[String(obj[`${objType}_id`])][obj.refId] || {}),
                ...(_.imm(state.normalized, obj.refId) || {}),
                ..._.imm(obj)
              }))

              // Add as an array set
              commit({
                type: types.ADD_FETCHED_SET,
                set: fullSet
              })

              // Selective reload each object for each type
              await Promise.all(
                fullSet.map((object) => dispatch('selectiveReload', { object, queue: false }))
              )

              if (objType) {
                dispatch(`${_.titleCase(objType)}/clearCache`, {}, { root: true })
              }

              return true
            })
          )

          return payload
        },

        /**
         * Reset to last fetched state
         * @param commit
         * @param state
         * @param payload
         * @returns {*}
         */
        async reset({ commit, state, dispatch }, payload) {
          const { refId } = payload
          const norm = state.normalized[refId]
          const parentRefId = state.normalized[refId].parentRefId
          const id = `${norm.type === type ? '' : `${norm.type}-`}${norm[`${norm.type}_id`]}`

          commit({
            type: types.REMOVE_NORMALIZED,
            refId
          })

          const normalized = {
            ...(state.all[id] ||
              ((await dispatch('fetch', { type: norm.type, id: norm[`${norm.type}_id`] })) &&
                state.all[id]))
          }

          if (normalized[refId]) {
            normalized[refId].parentRefId = parentRefId
          }

          commit({
            type: types.ADD_NORMALIZED,
            object: normalized
          })

          commit({
            type: types.REMOVE_ORPHANED
          })

          dispatch('resetChanges', { refId, normalized })

          eventBus.$emit('reset', {
            store: _.titleCase(state.type),
            type: state.type,
            refId
          })

          return {
            ...payload,
            normalized
          }
        },

        /**
         *
         * @param payload
         * @returns {Promise<unknown[]>}
         */
        async clearAllCache({ dispatch }) {
          const titleTypes = Object.keys(objectList)
          const skipTypes = titleTypes.map((t) => _.underCase(t))
          return _.throttle(() =>
            Promise.all(
              Object.keys(objectList).map((t) =>
                dispatch(`${_.titleCase(t)}/clearCache`, { skipTypes }, { root: true })
              )
            )
          )
        },

        async getCacheItem({ rootState, dispatch }, { key }) {
          if (_.isCacheExpired(key, type, rootState.session)) {
            dispatch('clearCache')
            return null
          }

          return _.getCacheItem(key, type, rootState.session)
        },

        async setCacheItem({ rootState }, { key, value, expiry = null }) {
          return _.setCacheItem(key, value, type, rootState.session, expiry || undefined)
        },

        /**
         * Clear cache according to a given entity type
         * @param commit
         * @param state
         */
        clearCache({ commit, state, dispatch }, payload = {}) {
          const { type: objType = type, recursive = true } = payload

          const norm = _.imm(state.normalized)
          const normKeys = Object.keys(norm)
          const idField = `${objType}_id`

          const keepIds = normKeys
            .filter((refId) => !norm[refId].parentRefId && norm[refId].type === objType)
            .map((refId) => String(norm[refId][idField]))

          const idsInCache = Object.keys(state.all)

          const idsToDelete = _.difference(idsInCache, _.uniq(keepIds))

          // Clear browser cache of that type
          _.clearCacheOfType(objType)

          if (recursive) {
            const allTypes = []
            const getRelatedTypes = (t) => {
              if (!allTypes.includes(t)) {
                allTypes.push(t)

                const otherTypes = getConstructor(objType).cachedTypes || []

                otherTypes.forEach((ot) => getRelatedTypes(ot))
              }
            }
            getRelatedTypes(type)
            Perf.bench(`gotall related ${JSON.stringify(allTypes)}`, 'sv')

            allTypes.forEach((t) => {
              if (t === objType || t === type) {
                return
              }

              dispatch(`${_.titleCase(t)}/clearCache`, { recursive: false }, { root: true })
            })
          }

          Perf.bench('done clearing web cache', 'sv')

          commit({
            type: types.REMOVE_FETCHED,
            id: idsToDelete
          })
          Perf.bench('done clearing vuex', 'sv')
        },
        /**
         * Adds the normalized object into state.all as-is.  Any changes made
         * will not be known.
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         * @returns {Promise}
         */
        flush({ commit, state, dispatch }, payload) {
          const { refId } = payload
          const rootRef = getNormalizedRootRefId(state.normalized, refId)
          return new Promise((resolve) => {
            if (Object.values(state.added).indexOf(rootRef)) {
              const object = denormalize(state.normalized, rootRef)
              dispatch('buildDefaultObject', { embue: object }).then(
                ({ object: defaultedObject }) => {
                  // defaulted objects
                  commit({
                    type: types.ADD_FETCHED,
                    object: defaultedObject,
                    normalized: false
                  })
                  resolve(payload)
                }
              )
            } else resolve(payload)
          })
        },

        /**
         * Remove entry we have in state.all,
         *  refetch again from server, then select it.
         *
         * @param dispatch
         * @param state
         * @param payload
         *    @param string refId
         *    @param string|null parentRefId
         *    @param bool alert
         * @returns {Promise<Object*>}
         */
        async reload({ dispatch, state }, payload) {
          const { refId, parentRefId = null, alert = false, keepChanges = false } = payload

          const objType = state.normalized[refId].type

          const selectedPayload = await dispatch(
            `${_.titleCase(objType)}/selectExisting`,
            {
              ...payload,
              alert,
              force: true,
              id: state.normalized[refId][`${objType}_id`],
              refId,
              parentRefId,
              replace: true,
              keepChanges
            },
            { root: true }
          )

          return selectedPayload
        },

        /**
         *  Rollback to previously saved version
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param activityId
         *    @param refId
         * @returns {Promise<*>}
         */
        async checkoutPreviousVersion({ dispatch }, payload) {
          const { activityId } = payload

          const { object: resolvedObject } = await dispatch('resolveObject', payload)

          const { object } = await dispatch('ajax', {
            path: `${resolvedObject.type}/checkoutPreviousVersion`,
            data: {
              activity_id: activityId
            },
            scope: payload.scope || null
          })

          const refId =
            payload.refId ||
            object.refId ||
            generateNormalizedRefId(object.type, object[`${object.type}_id`])
          // Override ref ids must be set to false because we need old items
          // to override their counterparts in the new...
          const normalized = normalize(object, false, refId)

          const set = normalized

          return dispatch('loadNormalizedVersion', {
            normalized: set,
            refId
          })
        },

        async loadNormalizedVersion({ commit, state, dispatch }, payload) {
          const { normalized, refId } = payload

          const old = _.imm(state.normalized)

          commit({
            type: types.SET_NORMALIZED,
            object: normalized,
            refId
          })

          await dispatch('auditDependencies', {
            immediate: true
          })
          const diff = await dispatch('diffNormalizedRaw', {
            from: old,
            to: state.normalized,
            refId
          })
          dispatch('addChanges', {
            explicitChanges: diff,
            rootRefId: refId
          })

          return { normalized }
        },

        /**
         * Allows you to find an object of the stores' type
         * that might fall outside of the users allowed scope. For example
         * it will allow you to find a user, by an email address or phoen number
         * even if that user is not part of the current users' company.
         *
         * Only matches exactly to whatever fiedl is provided in 'by'
         *
         * @param payload
         * @returns {Promise<object{ set, object, ids, id }>}
         */
        async find({ dispatch }, payload = {}) {
          const { by, filters = {} } = payload

          const field = Object.keys(by)[0]
          const value = encodeURIComponent(Object.values(by)[0])

          const { object: returnedPayload } = await dispatch('ajax', {
            path: `${type}/find/${field}/${value}`,
            data: { filters },
            scope: payload.scope || null
          })

          const idField = `${type}_id`

          const set = returnedPayload.hits.map((hit) => hit._source)
          const object = set[0] || null
          const id = object && object[idField]
          const ids = set.map((obj) => obj[idField])
          return {
            set,
            object,
            id,
            ids
          }
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param filters
         *    @param offset
         *    @param limit
         *    @param searchPhrase
         *    @param quick
         *    @param type
         *    @param button
         * @returns {Promise<any>}
         */
        async search({ commit, dispatch, rootState }, payload = {}) {
          const {
            filters = {},
            offset = 0,
            limit = 0,
            minScore = 0,
            order = null,
            searchPhrase = '',
            quick = false,
            type: objectType = type,
            searchTypes = [type],
            saveSet = false,
            scope = null,
            fields = null,
            aggregations: aggs = []
          } = payload

          try {
            const processed = searchPhrase
              ? String(searchPhrase).replace(/[^A-Za-z0-9,-_'".@#&*\s]/, '')
              : null
            const path = `/${objectType}/search/${processed ? `${encodeURIComponent(processed)}/` : ''}${limit}/${offset}`
            const data = {
              filters,
              offset,
              limit,
              order,
              searchPhrase: processed,
              quick,
              minScore,
              searchTypes,
              fields,
              aggregations: aggs
            }

            const resp = await c.throttle(
              () =>
                dispatch(
                  'ajax',
                  {
                    path,
                    scope,
                    data,
                    setToken: !scope || _.jsonEquals(scope, rootState.session.scope)
                  },
                  { root: true }
                ),
              {
                key: `${path}-${JSON.stringify(data)}`,
                delay: 200
              }
            )

            const { object } = resp

            const set = object.hits.map((hit) =>
              Object.keys(hit._source).reduce(
                (acc, field) => ({
                  ...acc,
                  ...(!fields || fields.includes(field) ? { [field]: hit._source[field] } : {})
                }),
                {}
              )
            )
            const total = object.total.value
            const score = searchPhrase ? object.max_score : 10
            const aggregations = object.aggregations

            if (saveSet) {
              commit({
                type: types.ADD_FETCHED_SET,
                set
              })
            }

            return {
              object: set[0],
              set,
              stats: object,
              score,
              total,
              aggregations
            }
          } catch (e) {
            throw new UserError(
              {
                userMessage:
                  e.userMessage || 'Unable to search for for that at the moment. Try again.'
              },
              e
            )
          }
        },

        /**
         *
         * @param dispatch
         * @param rootState
         * @param isSuper
         * @returns {Promise<{scope: null, overrides: {}}|{scope: {public: number}, overrides: {company_id: null}}>}
         */
        async globalSaveCheck({ dispatch, rootState }, isSuper) {
          if (!isSuper || !rootState.session.user.user_is_super_user) {
            return {
              scope: rootState.session.scope || null,
              overrides: {}
            }
          }

          const message =
            type === 'cost_type'
              ? `When saving an item into the public scope:

              • this item will become visible to all users
              • if the item uses custom dimensions that are scoped to a company, that will cause errors
              • if the item uses a custom item type scoped to a company, that will cause errors
              • if the item uses a custom labor type/rate scoped to a company, that will cause errors
              
              Do you want to continue?`
              : `When saving an assembly into the public scope:

              • this assembly will become visible to all users
              • if the assembly contains items scoped to a company, that will cause errors
              • if the assembly contains other assemblies scoped to a company, that will cause errors
              • if the assembly uses custom dimensions that are scoped to a company, that will cause errors
              • if the assembly uses custom item types scoped to a company, that will cause errors
              • if the assembly uses custom labor types/rates scoped to a company, that will cause errors
              
              Do you want to continue?`

          if (
            !(await dispatch(
              'modal/asyncConfirm',
              {
                message
              },
              { root: true }
            ))
          ) {
            throw UserError({
              userMessage: 'aborted',
              message: 'aborted'
            })
          }

          return {
            scope: {
              user: rootState.session.user.user_id
            },
            overrides: {
              company_id: null,
              cost_type_use_company_markup: 1
            }
          }
        },

        /**
         *
         * @param commit
         * @param state
         * @param dispatch
         * @param payload
         *    @param filters
         *    @param offset
         *    @param limit
         *    @param quick
         *    @param type
         *    @param button
         * @returns {Promise<any>}
         */
        async filter({ commit, dispatch, rootState }, payload = {}) {
          const {
            filters = {},
            offset = 0,
            limit = 0,
            order = null,
            searchPhrase = '',
            quick = false,
            type: objectType = type,
            saveSet = false,
            scope = null,
            fields = null
          } = payload

          try {
            const processed = searchPhrase
              ? searchPhrase.replace(/[^A-Za-z0-9,-_'".@#&* ]/, '')
              : null
            const { set } = await dispatch(
              'ajax',
              {
                path: `/${objectType}/filter/${processed ? `${encodeURIComponent(processed)}/` : ''}${limit}/${offset}`,
                scope,
                data: {
                  filters,
                  offset,
                  limit,
                  order,
                  quick,
                  fields
                },
                setToken: !scope || _.jsonEquals(scope, rootState.session.scope)
              },
              { root: true }
            )

            const total = set.length
            const score = 0

            if (saveSet) {
              commit({
                type: types.ADD_FETCHED_SET,
                set
              })
            }

            return {
              object: {},
              set,
              stats: {},
              score,
              total
            }
          } catch (e) {
            throw new UserError(
              {
                userMessage:
                  e.userMessage || 'Unable to search for for that at the moment. Try again.'
              },
              e
            )
          }
        },

        async addChanges({ commit }, { explicitChanges = null, changes = null, rootRefId = null }) {
          if (!explicitChanges && !changes) return

          // const refIds = _.uniq([
          //   ...Object.keys(explicitChanges || {}),
          //   ...Object.keys(changes || {})
          // ])

          let hashes = {}
          // get hashes for quoting only
          // if (/quote|assembly/.test(type)) {
          //    - t)
          //   for (let i = 0; i < refIds.length; i++) {
          //     const refId = refIds[i]
          //     const current = { ...state.normalized[refId] }
          //
          //     if (!current.cost_type_id && !current.assembly_id) continue
          //
          //     const coerceType = current.type === 'cost_item' ? 'cost_type' : current.type
          //
          //     current.type = coerceType
          //
          //     const hash = await VersionControl.getVersionHash(current)
          //
          //     hashes[refId] = {
          //       cost_type_current_hash: hash
          //     }
          //   }
          //    - t)
          //
          //   //  - t, 'ms')
          //   setTimeout(
          //     () =>
          //       commit({
          //         type: types.FIELDS,
          //         changes: hashes
          //       }),
          //     1000
          //   )
          //    - t)
          //
          //   //  - t, 'ms')
          // }

          // if (added) {
          //   commit({
          //     type: types.ADD_EXPLICIT_CHANGES,
          //     added,
          //     rootRefId
          //   })
          // }
          //
          // if (removed) {
          //   commit({
          //     type: types.ADD_EXPLICIT_CHANGES,
          //     removed,
          //     rootRefId
          //   })
          // }

          if (explicitChanges) {
            commit({
              type: types.ADD_EXPLICIT_CHANGES,
              changes: explicitChanges,
              rootRefId
            })
            commit({
              type: types.ADD_CHANGES,
              changes: hashes,
              rootRefId
            })
          } else if (changes) {
            const full = NormalizeUtilities.mergeChanges(changes, hashes)
            commit({
              type: types.ADD_CHANGES,
              changes: full,
              rootRefId
            })
          }
        },

        // Add object specific actions
        ...objectActions
      },

      /**
       * Generate the mutations for each module
       * This method generates the VUEX commits for each one.
       */
      mutations: {
        /**
         * When seeing if we should audit on a throttle, we need to keep
         * track of fields that have been changed before an audit takes place.
         *
         * If the changes calls happen a number of times before the throttle
         * is triggered this will get called multiple times so it must be merged.
         * it can be cleared when the audit has completed.
         *
         * The throttle will go through the list of unaudited fields and determine
         * if an audit is required and whether a local audit will suffice to
         * be more efficient. Whether an audit is required or not, these fields will
         * be cleared.
         *
         * @param fields
         * @param refId
         */
        [types.ADD_UNAUDITED_CHANGES](state, { changes = {} }) {
          state.unauditedChanges = NormalizeUtilities.mergeChanges(state.unauditedChanges, changes)
        },

        /**
         * After an audit unaudted fields can be cleared.
         * @param refId
         */
        [types.CLEAR_UNAUDITED_CHANGES](state, { refId = null }) {
          if (!refId) {
            // clear all
            state.unauditedChanges = {}
          } else {
            // eslint-disable-next-line no-unused-vars
            const { [refId]: omit, ...rest } = state.unauditedChanges
            state.unauditedChanges = rest
          }
        },

        /**
         * If some field or other changes specify that we should do an
         * audit regardless of whether the fields change would ordinarily
         * stipulate for one, this will be triggered and then force an audit
         * to occur. It should be removed by UNSET_FORCE_AUDIT below after the audit
         * to resume audit checking in the most efficient manner.
         *
         * @param state
         */
        [types.SET_FORCE_AUDIT](state) {
          state.forceAudit = 1
        },

        /**
         * Remove forced audit for changes made.
         * @param state
         */
        [types.UNSET_FORCE_AUDIT](state) {
          state.forceAudit = 0
        },

        /**
         * Hold any other actions that affect ajax/auditing etc
         * until the promise provided is completed. This is so that
         * if an object is busy auditing changes, and the user presses
         * save, it doesn't save until the audit is finished. for example.
         *
         * @param state
         * @param promise
         * @param resolve
         * @param key
         */
        [types.ADD_REQUEST_QUEUE](state, { promise, resolve, key }) {
          const overriddenResolve = state.resolveQueue[key] || null
          state.requestQueue = {
            ...state.requestQueue,
            [key]: promise
          }
          state.resolveQueue = {
            ...state.resolveQueue,
            [key]: resolve
          }

          // If this key exists already, resolve immediately the previous one
          if (overriddenResolve) {
            setTimeout(() => overriddenResolve())
          }
        },

        /**
         * Resolve the next queue item,
         * and remove it from the queue.
         * @param key
         */
        [types.SHIFT_REQUEST_QUEUE](state, { key }) {
          const resolves = state.resolveQueue
          const promises = state.requestQueue

          if (key in resolves) {
            setTimeout(() => resolves[key]())
            // eslint-disable-next-line no-unused-vars
            const { [key]: omit, ...restResolves } = resolves
            state.resolveQueue = restResolves
          }
          if (key in promises) {
            // eslint-disable-next-line no-unused-vars
            const { [key]: omit, ...restPromises } = promises
            state.requestQueue = restPromises
          }
        },

        /**
         * Random global placeholder for any key=value pair,
         * given in the changes object
         * @param object changes
         */
        [types.META](state, { changes }) {
          state.meta = {
            ...state.meta,
            ...changes
          }
        },

        SET_FETCHING(state, { id, value = null }) {
          state.fetching[id] = value
        },

        /**
         *
         * @param state
         * @param object NORMALIZED set
         * @param audit
         * @param id
         * @returns {boolean|string}
         */
        [types.ADD_FETCHED](
          state,
          {
            object,
            // audit = true,
            id = false,
            normalized = false,
            refId: forceExistingRefId = null
          }
        ) {
          // Make sure dependencies are audited, before setting
          //  server version, so we can accurately track changes
          //  between working version (state.normalized) and server
          //  version in (state.all[id])
          let rootRefId =
            forceExistingRefId || (normalized && getNormalizedRootRefId(object)) || false
          const useType =
            (!normalized && object && object.type) ||
            (normalized && rootRefId && object[rootRefId] && object[rootRefId].type) ||
            type
          let processed

          if (!normalized) {
            rootRefId = forceExistingRefId || object.item_id || object.refId || null
            const defaulted = {
              ...buildDefaultObject(useType, _.imm(object)),
              refId: rootRefId
            }
            processed = normalize(defaulted, false, rootRefId)
            rootRefId = getNormalizedRootRefId(processed)
          } else {
            rootRefId = getNormalizedRootRefId(object)
            object[rootRefId].parentRefId = null
            processed = object
          }

          const setId =
            id || `${useType === type ? '' : `${useType}-`}${processed[rootRefId][`${useType}_id`]}`
          state.all = {
            ...state.all,
            [setId]: processed
          }
          return setId
        },

        /**
         * Add server originals for multiple items by given array of objects
         * @param array set
         */
        [types.ADD_FETCHED_SET](state, { set = [] }) {
          if (!set.length) return

          const processedSet = {}
          const normalized = {}
          const defaults = {}

          for (let i = 0; i < set.length; i++) {
            const obj = set[i]

            if (!(obj.type in defaults)) defaults[obj.type] = buildDefaultObject(obj.type)

            const allId = [`${obj.type === type ? '' : `${obj.type}-`}${obj[`${obj.type}_id`]}`]
            processedSet[allId] = {
              ...defaults[obj.type],
              ...obj
            }

            normalized[allId] = /quote|assembly/.test(obj.type)
              ? normalize(processedSet[allId])
              : { [obj.refId ?? allId]: processedSet[allId] }
          }

          state.all = {
            ...state.all,
            ...normalized
          }
        },

        /**
         * Remove server originals based on an original object, or id
         * @param object object
         * @param string|int|null id
         */
        [types.REMOVE_FETCHED](state, { originalObject: object, id = false }) {
          const objects = _.makeArray(object)
          const ids = objects.length ? objects.map((o) => o[`${o.type}_id`]) : _.makeArray(id)
          if (!ids.length) return

          let all = _.imm(state.all)
          // eslint-disable-next-line no-unused-vars
          let omit
          // eslint-disable-next-line no-unused-vars
          for (let id of ids) ({ [String(id)]: omit, ...all } = all)

          state.all = all
        },

        /**
         *
         * @param state
         */
        [types.UNSET_FETCHED](state) {
          state.all = {}
        },

        /**
         * Clears normalized, selected and all
         * @param state
         */
        [types.CLEAR](state) {
          const newState = _.imm({ ...originalState, type })
          Object.keys(newState).forEach((key) => {
            if (Array.isArray(newState[key])) {
              newState[key] = []
            } else if (typeof newState[key] === 'object') {
              newState[key] = {}
            } else {
              newState[key] = null
            }
          })
          state = newState
          return state
        },
        /**
         *
         * @param state
         * @param auditing
         */
        [types.SET_AUDITING](state, { auditing }) {
          state.auditing = auditing
        },

        /**
         *
         * @param state
         * @param processing
         */
        [types.SET_PROCESSING](state, { processing }) {
          state.processing = processing
        },

        /**
         * Merge provided object (normalized set) into the current normalized state.
         *
         * @param object object     normalized set ie: { [refId]: { field: value }, [refId]: {..}, .. }
         */
        [types.ADD_NORMALIZED](state, { object, integrate = false }) {
          // Enforce object structure
          // const clone = object;
          if (integrate) {
            const newNorm = NormalizeUtilities.mergeChanges(_.imm(state.normalized), object)

            state.normalized = newNorm
          } else {
            const newState = Object.assign({}, state.normalized, object)

            state.normalized = newState
          }

          const refIds = Object.keys(object)
          if (refIds.length) {
            const changeManager = verifyChangeManager(state, refIds[0])
            if (changeManager) changeManager.setCurrent(state.normalized)
          }
        },

        /**
         * Remove objects where the parent no longer exists
         * @param state
         */
        [types.REMOVE_ORPHANED](state) {
          // Rebuild without orphans
          const norm = removeOrphaned(_.imm(state.normalized))

          state.normalized = norm
        },

        /**
         * Remove only the object(s) represented by the refId(s) provded
         *    and their children from the normalized set.
         * @param refId string|array
         */
        [types.REMOVE_NORMALIZED](state, { refId, refIds }) {
          const refs = refIds || _.makeArray(refId)

          const newSet = removeOrphaned(removeNormalized(state.normalized, refs))
          state.normalized = newSet
        },

        /**
         * Replace normalized set, with object set provided
         * @param object object
         */
        [types.SET_NORMALIZED](state, payload) {
          // Enforce object structure
          let set = { ...payload.object }
          const refIds = Object.keys(set)

          // if (!payload.skipDefaulting) {
          //   const values = Object.values(set).map((o) => ({
          //     ...buildDefaultObject(o.type, o)
          //   }))
          //   set = { ..._.zipObject(refIds, values) }
          // }

          if (payload.prune) {
            set = removeOrphaned(set)
          }

          state.normalized = set

          if (refIds.length) {
            const changeManager = verifyChangeManager(state, payload.refId)
            changeManager.setCurrent(state.normalized)
          }
        },

        /**
         * Remove all / clear normalized objects in this object type.
         */
        [types.UNSET_NORMALIZED](state) {
          state.normalized = {}
          state.added = {}
          state.trash = []
        },

        [types.SET_CURRENT_CHANGE_MANAGER](state, { changeManager, normalized }) {
          changeManager.setCurrent(normalized)
        },
        [types.SET_ORIGINAL_CHANGE_MANAGER](state, { changeManager, normalized }) {
          changeManager.setOriginal(normalized)
        },
        [types.RESET_CHANGE_MANAGER](state, { changeManager }) {
          changeManager.reset()
        },
        [types.ADD_CHANGE_WATCHER](state, { changeManager, watcher }) {
          changeManager.watch(watcher)
        },
        [types.REMOVE_CHANGE_WATCHER](state, { changeManager, watcher }) {
          changeManager.unwatch(watcher)
        },
        [types.ADD_RESET_WATCHER](state, { changeManager, watcher }) {
          changeManager.watchReset(watcher)
        },
        [types.REMOVE_RESET_WATCHER](state, { changeManager, watcher }) {
          changeManager.unwatchReset(watcher)
        },
        [types.TRIGGER_WATCHERS_CHANGE_MANAGER](
          state,
          { changeManager, refIds = [], changes = {} }
        ) {
          changeManager.triggerWatchers(refIds, changes)
        },

        /**
         * Add chages to change controller
         * @param state
         * @param object      changes       indexed by refId (do not namespace by rootRefId)
         * @param string|null rootRefId
         */
        [types.ADD_CHANGES](state, { changes, rootRefId = null, trigger = true }) {
          const refIds = Object.keys(changes)
          if (!refIds.length) {
            return
          }

          const rref = rootRefId || getNormalizedRootRefId(state.normalized, refIds[0])

          verifyChangeManager(state, rref)

          changeManagers[rref].addChanges(changes, trigger, state.normalized)
        },

        /**
         * Add refIds provided to selection.
         * @param string|array refId
         */
        [types.ADD_SELECTED](state, { refId }) {
          const refIds = _.makeArray(refId)
          state.selected = [...state.selected, ...refIds]
          state.trash = _.difference(state.trash, refIds)
        },

        /**
         * Remove selections for all refIds provided
         * @param string|array refId
         */
        [types.REMOVE_SELECTED](state, { refId }) {
          const pruned = [...state.selected]
          _.makeArray(refId).forEach((subRef) => {
            pruned.splice(pruned.indexOf(subRef), 1)
          })
          state.selected = pruned
        },

        /**
         * Replace all selections with refIds provided
         * @param string|array refId
         */
        [types.SET_SELECTED](state, { refId }) {
          const refIds = _.makeArray(refId)
          state.selected = [...refIds]
          state.trash = _.difference(state.trash, refIds)
        },

        /**
         *
         */
        [types.UNSET_SELECTED](state) {
          state.selected = []
        },

        /**
         *
         * @param field
         * @param equation
         * @param refId
         */
        [types.FIELD_EQUATION](state, { field, equation, refId }) {
          const item = state.normalized[refId]
          if (!item) return

          if (!item.oEquations) {
            item.oEquations = {}
          }

          if (equation !== '' && equation !== null) {
            item.oEquations[field] = equation
          } else {
            delete item.oEquations[field]
          }
        },

        /**
         *
         * @param fields
         * @param refId
         */
        [types.FIELD_EQUATION_UNSET](state, { fields = [], refId }) {
          const item = state.normalized[refId]
          if (!item) return

          if (!item.oEquations) {
            item.oEquations = {}
          }

          fields.forEach((f) => {
            delete item.oEquations[f]
          })
        },

        /**
         * Changes must be object with keys being refIds
         * {
         *    refId: {
         *      field: value,
         *      ...
         *    },
         *    ...
         * }
         * @param state
         * @param changes
         * @param explicit bool|object   if true, all changes are explict
         *                               if false (default), no changes are explicit
         *                               if an object, only those changes are explicit
         */
        [types.FIELDS](state, { changes }) {
          state.normalized = NormalizeUtilities.mergeChanges(state.normalized, changes)
        },

        /**
         * If you are calling on the changeManager
         * externally, you can make sure it exists by calling
         * this mutation.
         * @param state
         * @param refId
         */
        [types.ENSURE_CHANGE_MANAGER](state, { refId }) {
          const rootRefId = getNormalizedRootRefId(state.normalized, refId)
          verifyChangeManager(state, rootRefId)
        },

        /**
         * Add explicit changes to change controller
         * @param object      changes       indexed by refId (do not namespace by rootRefId)
         * @param string|null rootRefId
         */
        [types.ADD_EXPLICIT_CHANGES](state, { changes = {}, rootRefId = null }) {
          const refIds = Object.keys(changes)
          if (!refIds.length) {
            return
          }

          const rref = getNormalizedRootRefId(state.normalized, rootRefId || refIds[0])

          verifyChangeManager(state, rref)
          if (rref) {
            changeManagers[rref].addExplicitChanges(changes)
          }
        },

        /**
         * Clear explicit changes from change controller
         * @param state
         * @param clearOrIgnore      clear|ignore - clear will reset current set to original set,
         *                                          and ignore will set original set to current set;
         * @param string|null rootRefId
         */
        [types.REMOVE_CHANGE_MANAGER](state, { rootRefId }) {
          if (!rootRefId) {
            return
          }

          const cm = verifyChangeManager(state, rootRefId)

          if (!cm) {
            return
          }

          // eslint-disable-next-line no-unused-vars
          const { [rootRefId]: omit, ...rest } = changeManagers

          changeManagers = rest
        },

        /**
         * Clear explicit changes from change controller
         * @param state
         * @param clearOrIgnore      clear|ignore - clear will reset current set to original set,
         *                                          and ignore will set original set to current set;
         * @param string|null rootRefId
         */
        [types.RESET_CHANGES](state, { refId }) {
          if (!refId) {
            return
          }

          const cm = verifyChangeManager(state, refId)

          if (!cm) {
            return
          }

          cm.reset()
        },
        [types.SET_ORIGINAL](state, { original, refId, changeManager = null }) {
          if (!refId) {
            return
          }
          const cm = changeManager ?? verifyChangeManager(state, refId)
          return cm.setOriginal(original)
        },

        [types.SET_WATCHERS](state, { changeManager, store, refId }) {
          if (!changeManager) {
            return
          }
          return changeManager.watch(store, refId)
        },

        [types.DESTROY_WATCHERS](state, { changeManager, callback }) {
          if (!changeManager) {
            return
          }
          return changeManager.unwatch(callback)
        },

        /**
         * @param state
         * @param changes object with field: value pairs for ONE refId normalized object
         * @param explicit bool|object      if true, all changes are explicit
         *                                  if false, no changes are explicit
         *                                  if object, changes in object are explicit
         * @param refId
         */
        [types.FIELD](state, { changes, refId }) {
          if (!(refId in state.normalized)) return

          // Directly mutate the existing `state.normalized[refId]` object
          Object.assign(state.normalized[refId], changes)
        }
      },

      /**
       * Generate the mutations for each module
       * This method generates the VUEX getters for each one.
       */
      getters: {}
    }
  }
}, {})

export default modules
