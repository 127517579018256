<template>
  <div v-if="templateFull">
    <!-- bottom panel -->
    <div class="grid grid-cols-1 md:grid-cols-4 h-full gap-4">
      <!-- Presentation settings action bar -->
      <div
        class="flex flex-col md:border-r border-cool-gray-900 col-span-1 pr-4 relative overflow-hidden"
      >
        <div
          :class="
            tab === 'settings'
              ? 'md:fixed md:top-14 min-w-24 md:min-w-48 lg:min-w-60 xl:min-w-72 2xl:min-w-96'
              : ''
          "
        >
          <div class="flex flex-col gap-3">
            <Btn
              class="!w-full"
              severity="dark"
              size="lg"
              @click="() => selectTab('theme')"
              :pt="{
                root: `${tab === 'theme' ? '' : 'bg-transparent border-none'} !text-flame-white !justify-start`
              }"
            >
              Theme
            </Btn>
            <Btn
              class="w-full"
              size="lg"
              severity="dark"
              @click="() => selectTab('settings')"
              :pt="{
                root: `${tab === 'settings' ? '' : 'bg-transparent border-none'} !text-flame-white !justify-start`
              }"
            >
              Settings
            </Btn>
          </div>
        </div>
      </div>

      <!-- Customizations -->
      <div class="flex flex-col justify-center col-span-3 px-4 relative pb-5">
        <div v-if="tab === 'theme'" class="grid grid-cols-2 gap-6 mt-5">
          <div class="flex flex-col justify-start items-center">
            <span class="mt-2 text-flame-white text-base">Background image</span>
            <ImageSelector
              size="md"
              classes="flex items-center justify-center w-full"
              :value="presentationSettings.screens[0].headingFileId"
              @input="changeHeadingPic"
            >
              <template #upload="{ change }">
                <div
                  @click="change"
                  class="m-auto bg-pitch-black cursor-pointer flex flex-col h-48 w-full justify-center border-cool-gray-500 hover:border-flame-white border rounded-md border-dashed py-4 px-8 items-center"
                >
                  <font-awesome-icon class="text-flame-white" :icon="['far', 'upload']" />
                  <span class="text-flame-white mt-2">Upload background image</span>
                </div>
              </template>
              <template #buttons="{ change, remove }">
                <div class="flex flex-col gap-2 ml-2">
                  <btn size="lg" severity="dark" @click="change">
                    <font-awesome-icon icon="folder-open" />
                  </btn>
                  <btn size="lg" severity="dark" @click="remove">
                    <font-awesome-icon icon="trash" />
                  </btn>
                </div>
              </template>
            </ImageSelector>
          </div>

          <div class="flex flex-col justify-start items-center">
            <span class="mt-2 text-flame-white text-base">Logo image</span>
            <ImageSelector
              size="md"
              classes="flex items-center justify-center w-full"
              v-model="presentationSettings.screens[0].logoFileId"
              @input="changeLogo"
            >
              <template #upload="{ change }">
                <div
                  @click="change"
                  class="bg-pitch-black m-auto cursor-pointer flex flex-col h-48 w-full justify-center border-cool-gray-500 hover:border-flame-white border rounded-md border-dashed py-4 px-8 items-center"
                >
                  <font-awesome-icon class="text-flame-white" :icon="['far', 'upload']" />
                  <span class="text-flame-white mt-2">Upload logo</span>
                </div>
              </template>
              <template #buttons="{ change, remove }">
                <div class="flex flex-col gap-2 ml-2">
                  <btn size="lg" severity="dark" @click="change">
                    <font-awesome-icon icon="folder-open" />
                  </btn>
                  <btn size="lg" severity="dark" @click="remove">
                    <font-awesome-icon icon="trash" />
                  </btn>
                </div>
              </template>
            </ImageSelector>
          </div>
        </div>
        <ScrollPanel class="w-full h-full" v-if="tab === 'settings'">
          <PresentationSettings class="!text-flame-white" v-model="oPresentationSettings" />
        </ScrollPanel>
      </div>
    </div>
  </div>
  <div class="mt-2" v-else>
    <div class="grid grid-cols-1 md:grid-cols-4 h-full gap-4">
      <div class="col-span-1">
        <Skeleton height="3em" width="100%" class="mb-3 !bg-pitch-black" />
        <Skeleton height="3em" width="100%" class="!bg-pitch-black" />
      </div>
      <div class="col-span-3">
        <Skeleton height="3em" width="100%" class="!bg-pitch-black" />
        <div class="grid grid-cols-2 gap-6 mt-3">
          <Skeleton height="8em" width="100%" class="!bg-pitch-black" />
          <Skeleton height="8em" width="100%" class="!bg-pitch-black" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PresentationMixin from './PresentationMixin'
import ObjectDistinct from '../../mixins/ObjectDistinct'
import PresentationSettings from './PresentationSettings.vue'
import ScrollPanel from 'primevue/scrollpanel'
import Skeleton from 'primevue/skeleton'

export default {
  name: 'QuotePresentationSettings',

  mixins: [ObjectDistinct('quote'), PresentationMixin],
  emits: ['unsavedChanges'],

  data() {
    return {
      tab: 'theme'
    }
  },

  components: {
    PresentationSettings,
    ScrollPanel,
    Skeleton
  },

  watch: {
    oPresentationSettings(a) {
      if (!this.templateFull || !this.templateFull.oMeta) return

      const original = {
        ...this.templateFull
      }
      const current = {
        ...this.templateFull,
        oMeta: a
      }

      const eq = c.jsonEquals(original.oMeta, current.oMeta, true)

      if (!eq) {
        this.unsavedChanges = 1
      }
    }
  },

  methods: {
    selectTab(tab) {
      this.tab = tab
    }
  },

  mounted() {
    this.loadTemplate(this.presentation_template_id)
  },

  async beforeUnmount() {
    const template = this.getTemplate()

    if (this.unsavedChanges) {
      this.$emit('unsavedChanges', template)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-presentation-settings {
  background-color: rgba($pitch-black, 0.95) !important;
}
.quote-presentation-settings--header {
  background-color: transparent !important;
}
.quote-presentation-mask {
  height: 0% !important;
}
</style>
