<template>
  <btn-bar
    v-if="showActions"
    size="lg"
    :selected="selected"
    :actions="actions"
    :collapse="false"
    :scope="scope"
    :grid="grid"
  />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import BtnBar from '@/components/ui/BtnBar.vue'

const props = defineProps({
  showActions: {
    type: Boolean,
    default: true
  },
  scope: {
    default: null
  },
  type: {
    type: String,
    required: true
  },
  selected: {
    type: Array,
    required: false,
    default() {
      return []
    }
  },
  grid: {
    required: true
  }
})

const actions = ref({})

onMounted(() => {
  actions.value = c.getActions(props.type)
})

onBeforeUnmount(() => {
  actions.value = null
})
</script>

<style lang="scss" scoped></style>
