<template>
  <AuthenticatedView v-if="isLoggedIn" />
  <PublicView v-else-if="inPublicPage" />

  <LoadingCurtain v-if="opacity && loading" style="opacity: 0.8" />

  <Alerts />
  <Modals />
  <Notification />
  <GlobalSearch />
  <Traverse ref="traverse" />
  <MilestoneComplete ref="milestones" />
</template>

<script>
import { useDeviceStore } from '@/stores/device'
import { useSidePanel } from '@/stores/sidepanel'
import { SplashScreen } from '@capacitor/splash-screen'
import eventBus from './eventBus'
import ChatSubscriptions from '@/components/mixins/ChatSubscriptions'
import DisplayLanguage from '@/components/mixins/DisplayLanguage'
import UserMeta from '@/components/mixins/UserMeta'
import Alerts from '@/components/layout/Alerts.vue'
import Notification from '@/components/notifications/Notification.vue'
import LoadingCurtain from '@/components/layout/LoadingCurtain.vue'
import GlobalSearch from '@/components/modals/GlobalSearch.vue'
import AuthenticatedView from '@/views/AuthenticatedView.vue'
import PublicView from '@/views/PublicView.vue'
import Modals from '@/components/layout/Modals.vue'
import MilestoneComplete from '@/components/milestones/MilestoneComplete.vue'
import { useStore } from 'vuex'
import { watch, computed, onBeforeUnmount } from 'vue'
import { useActivityChannels } from '@/components/composables/ActivityChannels'
import { useActivityChatPushNotifications } from '@/components/composables/ActivityChatPushNotifications'
import { useMediaQuery } from '@/composables/mediaQuery'

window.$$ = $

export default {
  setup() {
    useActivityChatPushNotifications()
    const store = useStore()
    const deviceStore = useDeviceStore()
    const sidePanelStore = useSidePanel()
    const {
      initActivityNotifications,
      initCompanyChannel,
      unsubscribeToNotifications,
      unsubscribeToNewChannels
    } = useActivityChannels()

    // computed //
    const inCompany = computed(() => store.state.session?.company?.company_id ?? null)
    const loggedIn = computed(() => store.state.session.isLoggedIn)

    const { smallFormat } = useMediaQuery()

    // watchers //
    watch(
      inCompany,
      async (val) => {
        if (!val) return
        if (
          !store.state.session.company?.aoUsers?.find(
            (user) =>
              user.user_id.toString() ===
              (
                store.state.session.authorizedUser.user_id || store.state.session.user.user_id
              ).toString()
          )
        )
          return

        // Activity chat init
        await initCompanyChannel(
          store.state.session.company.company_id,
          store.state.session.company.company_name
        )
      },
      {
        immediate: true
      }
    )
    watch(
      loggedIn,
      (val) => {
        if (!val) return
        initActivityNotifications(
          store.state.session.authorizedUser.user_id || store.state.session.user.user_id,
          store.state.session.company.company_id
        )
      },
      {
        immediate: true
      }
    )
    onBeforeUnmount(() => {
      unsubscribeToNotifications()
      unsubscribeToNewChannels()
    })

    return {
      deviceStore,
      sidePanelStore,
      smallFormat
    }
  },
  data() {
    return {
      opacity: 0,
      routing: false,
      sidebarOpen: 0,
      padding: 10,
      notificationInterval: 6000000, // 100 minutes
      intervalObject: null,
      showFirstLoginWelcome: null,
      showLockModal: null,
      /**
       * To keep a page alive, include regex to determine 'name:'
       * value for the component here:
       */
      letDie: /^ObjectManipulator/,
      /**
       * emit $root.$emit('scrollOn') and $root.$emit('scrollOff') to affect this.
       */
      forcePreventPageScrolling: false,
      forceBlur: false,
      openPaymentOnboarding: false
    }
  },
  mixins: [UserMeta, DisplayLanguage, ChatSubscriptions],
  watch: {
    loading(a) {
      if (a === 0) {
        this.removeHtmlLoader()
      }
    },
    $route() {
      this.$store.dispatch('hideAllTooltips')
    },
    itemSelectorResolve(a) {
      if (a) {
        this.$refs.traverse.open(true)
      }
    },
    searchBoxOpen(a, b) {
      if (a && a !== b) {
        this.$refs.search.open()
      } else if (!a && a !== b) {
        this.$refs.search.close()
      }
    },
    inCompany(b, before) {
      if (b && !before) {
        this.showLockModal = this.shouldLock()
        if (this.showLockModal) return

        this.showLockModal = 0

        eventBus.$emit('hideSupportChat')
        this.shouldOpenFirstLoginWelcome().then((showFirstLoginWelcome) => {
          this.showFirstLoginWelcome = showFirstLoginWelcome
          if (this.inCompany && showFirstLoginWelcome && this.counterpartyStatus === 'u') {
            this.openPaymentOnboarding = true
          }
        })

        // Open starting options?
        this.shouldOpenStartingOptions().then((shouldOpen) => {
          if (this.inCompany && shouldOpen) {
            this.openStartingOptions()
          }
        })
      }
      // initialize chat
      const session = this.$store.state.session
      let aoModules = (session.company && session.company.aoModules) || null
      if (b && session.user.user_is_company_user && aoModules && aoModules.chat === '1') {
        this.$store
          .dispatch('contractorInitializeChats')
          .then(this.subscribeToNewMessages)
          .then(this.subscribeToNewChats)
      }
      if (!b && session && session.company && aoModules && aoModules.chat === '1') {
        this.unsubscribeToNewMessages()
        this.unsubscribeToNewChats()
      }

      // check to see if the quickbooks refresh token has expired
      if (
        b &&
        session.user.user_is_company_user &&
        aoModules &&
        aoModules.quickbooks_export &&
        aoModules.quickbooks_export === '1'
      ) {
        this.$store
          .dispatch('ExportToken/checkIfExportTokenHasExpired', { exporter: 'quickbooks' })
          .then((hasExporterExpired) => {
            if (this.inCompany && hasExporterExpired) {
              this.$store.dispatch('guessPath', { path: '/accounting' }).then((path) => {
                this.$store.dispatch('addBanner', {
                  message: `Quickbook's connection has expired, please <a href="${path}">reconnect</a>.`,
                  open: true
                })
              })
            }
          })
      }
    }
  },
  provide() {
    return {
      user: this.$store.state.session.user
    }
  },
  computed: {
    showHeader() {
      return this.$route.meta.header || (this.isLoggedIn && this.smallFormat)
    },
    userPic() {
      if (this.$store?.state?.session?.user?.profile_file_id) {
        return c.link(
          `file/pic/${this.$store.state.session.user.profile_file_id}`,
          {},
          true,
          _.getStorage('scope')
        )
      }
      return null
    },
    counterpartyStatus() {
      return this.$store.state.session.company?.counterparty_status
        ? this.$store.state.session.company.counterparty_status
        : 'u'
    },
    inCompany() {
      return this.$store.getters.inCompany
    },
    searchBoxOpen() {
      return this.$store.state.search.searchBoxOpen
    },
    itemSelectorResolve() {
      return this.$store.state.general.itemSelectorResolve
    },
    isLocked() {
      return this.shouldLock()
    },

    activatePaywall() {
      return this.$store.getters.activatePaywall
    },
    /**
     * Type of user
     * @returns {string}
     */
    userAgent() {
      return this.$store.state.session.userAgent
    },
    inPublicPage() {
      return this.$route.meta.public
    },
    loading() {
      return this.$store.state.session.loading
    },
    isLoggedIn() {
      return this.$store.state.session.isLoggedIn
    },
    hasScopableObjects() {
      return Object.keys(this.$store.state.session.scopableObjects).length
    },
    blur() {
      return this.$store.state.modal.visible.length
    },
    headerHeight() {
      return 50 + (this.$route.meta.subNav ? 32 : 0)
    },
    subNav() {
      if ('subNav' in this.$route.meta && this.$route.meta.subNav) {
        return this.$route.meta.subNav
      }
      return false
    }
  },
  mounted() {
    if (this.$store.state.session.testing) {
      window.dispatch = this.$store.dispatch
      window.vuex = this.$store.state
    }

    c.throttle(() => {
      if (this.inPublicPage) {
        this.removeHtmlLoader()
      }
    })

    this.checkUserAgent()

    SplashScreen.hide()
  },
  methods: {
    createHandler() {
      this.$refs.createDongle?.open()
    },
    dismissBanner() {
      this.$store.dispatch('toggleBanner')
    },
    shouldLock() {
      return (
        !this.$store.state.session.authorizedUser.user_is_super_user &&
        this.$store.getters.inCompany &&
        this.$store.state.session.isLoggedIn &&
        !this.$route.path.includes('subscription') &&
        this.$store.state.session.company &&
        this.$store.state.session.company.company_is_locked
      )
    },
    async tutorialDone() {
      if (await this.shouldOpenStartingOptions()) {
        this.openStartingOptions()
      }
    },
    async tutorialExit() {
      await this.$nextTick()
      if (await this.shouldOpenStartingOptions()) {
        this.openStartingOptions()
      }
    },
    // check first if we should open, then open if we should
    async shouldOpenStartingOptions() {
      await c.throttle(() => {})

      if (!this.inCompany) return false

      if (this.shouldLock()) return false

      if (await this.shouldOpenFirstLoginWelcome()) return false

      return this.$store.getters.trialing
    },
    openStartingOptions() {
      if (this.$refs.startingOptions) this.$refs.startingOptions.open()
    },
    async startTour() {
      if (this.smallFormat) {
        this.$store.dispatch('modal/asyncConfirm', {
          message:
            "Guided tutorials don't work great on a small screen. Come back when you're on your desktop computer!",
          actions: {
            cancel: {
              visible: () => false
            }
          },
          yes: 'Ok, got it'
        })
        return
      }
      await this.$store.dispatch('to', 'pipeline')
      if (!this.userHasPermissionsForTutorial()) return
      if (this.$refs.tour) this.$refs.tour.start()
    },
    tourDone() {
      this.setMetaItem(this.metaConstants.DoneTutorial, 1)
      this.$store.dispatch('triggerEvent', 'TutorialCompleted')
    },
    userHasPermissionsForTutorial() {
      const perms = this.$store.state.session.user.aUserPerms
      return (
        perms.quote.create > 0 &&
        perms.quote.update > 0 &&
        perms.quote.read > 0 &&
        perms.client.create > 0 &&
        perms.client.update > 0 &&
        perms.client.read > 0
      )
    },
    async shouldOpenFirstLoginWelcome() {
      // Don't show for super users
      const skip =
        this.$store.state.session.authorizedUser.user_is_super_user && import.meta.env.PROD

      if (skip) return false

      if (!this.$store.getters.inCompany) return false
      if (this.shouldLock()) return false
      // only new users
      if (!this.$store.state.session.user.user_terms_time_accepted) return true
      if (this.$store.state.session.user.user_terms_time_accepted) return false

      // if admin welcome hasn't been done then this definitely neds to be done
      const adminWelcomeDone = await this.$store.dispatch('Keyvalue/get', 'AdminWelcomeDone')
      this.adminWelcomeDone = adminWelcomeDone
      if (!adminWelcomeDone) return true

      const ignore = await this.getMetaItem(this.metaConstants.UserFirstLoginIgnore)

      if (ignore) return false

      return true
    },
    checkUserAgent() {
      const firefox = this.$store.state.session.userAgent.toLowerCase().includes('firefox')

      if (firefox && !this.$store.getters.isGuestUser) {
        this.$store.dispatch('modal/confirm', {
          message:
            'We recommend using Google Chrome, Microsoft Edge or Safari with Bolster. Firefox can cause some issues with security and scrolling so it is not recommended.'
        })
      }
    },
    removeHtmlLoader() {
      if (!$('.app-loading--container').length) {
        this.opacity = 1
        return
      }

      this.$nextTick(() => {
        const event = new Event('costcertified-loaded')
        window.dispatchEvent(event)
        $('.app-loading--container').remove()
        this.opacity = 1
      })
    },
    getAvailableToken() {
      return this.$route.query.token || this.$route.query.lt || _.getStorage('token')
    },
    getAvailableShortToken() {
      return this.$route.query.shortToken || this.$route.query.st || _.getStorage('shortToken')
    },
    getAvailableAccessToken() {
      return this.$route.query.accessToken || this.$route.query.at || _.getStorage('accessToken')
    },
    getAvailableIdToken() {
      return this.$route.query.idToken || this.$route.query.at || _.getStorage('idToken')
    },
    getAvailableRefreshToken() {
      return this.$route.query.refreshToken || this.$route.query.rt || _.getStorage('refreshToken')
    },
    checkGuestTokens() {
      return Promise.resolve().then(() => {
        const lt = this.getAvailableToken()
        if (lt) {
          return this.$store.dispatch('setToken', { token: lt })
        }
        const st = this.getAvailableShortToken()
        if (st) {
          return this.$store.dispatch('setShortToken', { shortToken: st })
        }
        const at = this.getAvailableAccessToken()
        if (at) {
          return this.$store.dispatch('setAccessToken', { accessToken: at })
        }
        const it = this.getAvailableIdToken()
        if (it) {
          return this.$store.dispatch('setIdToken', { idToken: it })
        }
        const rt = this.getAvailableRefreshToken()
        if (rt) {
          return this.$store.dispatch('setRefreshToken', { refreshToken: rt })
        }
        return Promise.reject()
      })
    },
    browserOffline() {
      _.log('!!!browser offline')
      this.$store.dispatch('browserOnline', 0)
      eventBus.$emit('offline')
    },
    browserOnline() {
      _.log('!!!browser online')
      this.$store.dispatch('browserOnline', 1)
      eventBus.$emit('online')
    },
    browserFocus() {
      _.log('!!!browser focused')
      this.$store.dispatch('browserFocus', 1)
      eventBus.$emit('focus')
      _.throttle(
        () => {
          if (this.$store.state.session.isOnline) {
            this.$store.dispatch('updateCounts')
          }
        },
        { delay: 30000 }
      )
      this.setDeviceSize()
    },
    browserBlur() {
      _.log('!!!browser blurred')
      this.$store.dispatch('browserFocus', 0)
      eventBus.$emit('blur')
    },
    setDeviceSize() {
      this.$store.dispatch(
        'setDeviceSize',
        {
          size: c.docSize(),
          width: c.viewPortSize(),
          height: c.viewPortHeight()
        },
        { debounce: true }
      )

      // Set header/toolbar height based on if in web or app mode
      if (this.deviceStore.isNative) {
        this.$store.dispatch('setHeaderHeight', { height: this.headerHeight })
      }
    },
    initiateNotifications() {
      if (this.intervalObject) {
        window.clearInterval(this.intervalObject)
      }
    },
    controlKey(e) {
      if (!e) return true

      if ((e.metaKey || e.ctrlKey) && !/meta|control/.test(String(e.key).toLowerCase())) {
        return eventBus.$emit(`ctrl-${String(e.key).toLowerCase()}`, e)
      } else if (e.which === 13) {
        return eventBus.$emit('enter', e)
      }
      return true
    }
  },
  created() {
    // Set initial device size
    this.setDeviceSize()
    window.dispatch = this.$store.dispatch
    window.c = c
    window.$root = this.$root

    // Global events
    window.addEventListener('resize', () => {
      c.throttle(() => {
        this.setDeviceSize()
        eventBus.$emit('resize')
      })
    })

    setTimeout(() => {
      this.setDeviceSize()
    }, 5000)

    window.addEventListener('blur', this.browserBlur)
    window.addEventListener('focus', this.browserFocus)
    window.addEventListener('offline', this.browserOffline)
    window.addEventListener('online', this.browserOnline)

    if (window.navigator.onLine) {
      this.browserOnline()
    } else {
      this.browserOffline()
    }

    $(document).on('keydown', window, this.controlKey)

    // eslint-disable-next-line vue/no-deprecated-events-api
    this.$on('create', this.createHandler)

    eventBus.$on('ctrl-f', (e) => {
      this.$store.dispatch('search/open')
      return e.preventDefault()
    })

    eventBus.$on('scrollOn', () => {
      this.forcePreventPageScrolling = false
    })
    eventBus.$on('scrollOff', () => {
      this.forcePreventPageScrolling = true
    })
    eventBus.$on('blurContents', () => {
      this.forceBlur = true
    })
    eventBus.$on('unblurContents', () => {
      this.forceBlur = false
    })
    eventBus.$on('startTour', () => {
      this.startTour()
    })
  },
  beforeUnmount() {
    // eslint-disable-next-line vue/no-deprecated-events-api
    this.$off('create', this.createHandler)
    $(document).off('keydown', window, this.controlKey)
  },
  components: {
    Modals,
    PublicView,
    AuthenticatedView,
    Alerts,
    LoadingCurtain,
    GlobalSearch,
    MilestoneComplete,
    Notification
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  display: flex;
  flex-direction: column;
}

.slide-enter-active {
  &.loggedin {
    transition: all 1s;
  }
  &.loggedout {
    // @extend .animated;
    // @extend .bounceInDown;
    opacity: 1;
  }
}
.slide-leave-active {
  &.loggedin {
    transition: all 1s;
  }
  &.loggedout {
    // @extend .animated;
    // @extend .bounceOutUp;
    opacity: 1;
  }
}
.slide-enter {
  &.loggedin {
    opacity: 0;
  }
  &.loggedout {
    opacity: 1;
  }
}
.slide-leave-to {
  &.loggedin {
    opacity: 0;
  }
  &.loggedout {
    opacity: 0;
  }
}

.h-screen-less-header {
  height: calc(100vh - 60px - env(safe-area-inset-top));
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.fade-r {
  mask: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}
.fade-l {
  mask: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
