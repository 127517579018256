import { computed, ref, watch, onBeforeUnmount, onMounted } from 'vue'

export default {
  useGuide(args) {
    const { refSheet } = args

    const guide = {
      steps: [
        {
          icon: 'pencil',
          tooltip: 'Enter a name for your first item in this estimate, then press enter.',
          cell: [0, 0],
          position: 'right'
        },
        {
          icon: 'ruler-triangle',
          tooltip: 'Now, choose a unit of measure for this item, or press enter to continue.',
          cell: [14, 0],
          focus: false
        },
        {
          icon: 'money-bill-1-wave',
          tooltip:
            'Now, add a unit cost for this item. This is what this item costs you, per 1 unit.',
          cell: [9, 0],
          focus: false
        },
        {
          icon: 'sack-dollar',
          tooltip:
            'With your default profit, this is what the price comes out to per unit. Change the profit or price directly if you want.',
          cell: [11, 0],
          focus: false
        },
        {
          icon: 'hashtag',
          tooltip:
            'Set your quantity for how many items you would like to include in this estimate.',
          cell: [13, 0],
          focus: false,
          position: 'left'
        },
        {
          icon: 'sack-dollar',
          tooltip:
            "With the quantity you've set, this is what the price comes out to. You can also change this directly if you like, and it will adjust the markup accordingly.",
          cell: [21, 0],
          focus: false,
          position: 'left'
        },
        {
          icon: 'plus',
          tooltip:
            'Hover your mouse around here, then click the [fa-solid fa-plus] button to add another item.',
          cell: [-1, 0],
          focus: false,
          triggerNext: 'addedRows'
        },
        {
          icon: 'pencil',
          tooltip: 'Now name this item as well.',
          cell: [0, 1],
          focus: true
        },
        {
          icon: 'fa-cubes',
          tooltip:
            'Now, click the [fa-solid fa-grip-dots-vertical] button for options, and choose "Turn into assembly".',
          cell: [-1, 1],
          focus: false,
          triggerNext: 'turnItemIntoAssembly',
          position: 'right'
        },
        {
          icon: 'cubes',
          tooltip:
            "This becomes a group of items, that you can re-use to save time. This assembly adopts the name of your item by default. Change it if you'd like.",
          cell: [0, 1],
          focus: true,
          position: 'right'
        },
        {
          icon: 'fa-solid fa-grip-dots-vertical',
          tooltip:
            'Now, click down and hold onto the [fa-solid fa-grip-dots-vertical] button, then drag [hand-back-fist] it into your new assembly.',
          cell: [-1, 0],
          focus: false,
          triggerNext: 'movedRows',
          position: 'right'
        }
      ]
    }

    const step = ref(null)
    const currentStep = computed(() => guide.steps[step.value])
    const tooltip = ref(null)

    const handleCommit = () => {
      if (step.value === null) return

      throttleNext()
    }

    const handleMoved = () => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'movedRows') return

      throttleNext()
    }
    const handleAdded = () => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'addedRows') return

      throttleNext()
    }
    const turnHandler = () => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'turnItemIntoAssembly') return

      throttleNext()
    }

    // Avoid overstimulating
    const throttleNext = () => c.throttle(() => nextStep(), { delay: 200 })

    const handleEnter = (e) => {
      if (step.value === null) return

      // Check for Enter, Tab, or Right Arrow keys
      if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'ArrowRight') {
        throttleNext()
      }
      // Check for Left Arrow key
      else if (e.key === 'ArrowLeft') {
        prevStep()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleEnter)
      refSheet.value.$on('changes', handleCommit)
      refSheet.value.$on('addedRows', handleAdded)
      refSheet.value.$on('movedRows', handleMoved)
      refSheet.value.$on('turnItemIntoAssembly', turnHandler)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleEnter)
      refSheet.value.$off('changes', handleCommit)
      refSheet.value.$off('addedRows', handleAdded)
      refSheet.value.$off('movedRows', handleMoved)
      refSheet.value.$off('turnItemIntoAssembly', turnHandler)
    })

    const completed = ref(false)
    const nextStep = () => {
      const nextStep = step.value + 1

      if (nextStep >= guide.steps.length) {
        completed.value = true
        endGuide()
      }

      step.value = nextStep
    }

    const prevStep = () => {
      let nextStep = step.value - 1

      if (nextStep < 0) {
        nextStep = 0
      }

      step.value = nextStep
    }

    const goToStep = async (newStep) => {
      const step = guide.steps[newStep]

      if (!step) return

      await moveCell(...step.cell, step.focus ?? true)
    }
    watch(step, (newStep) => {
      if (newStep !== null) {
        goToStep(newStep)
      }
    })

    const tooltipText = computed(() => currentStep.value?.tooltip ?? '')
    const tooltipPosition = computed(() => {
      const [col, row] = currentStep.value.cell
      const position = currentStep.value.position ?? 'bottom'

      let left = 0
      let top = 0
      let bottom = 0
      let right = 0
      let height = 0
      let width = 0
      if (col === -1) {
        // row header
        left = refSheet.value.rowHeadingBoundingRect.x
        width = refSheet.value.rowHeadingBoundingRect.width
      } else if (col === -2) {
        // gutter
        left = refSheet.value.collapseGroupGutterPositionsVisible.value[0].x
        width = 100
      } else {
        let visible = refSheet.value.cellPositionsVisible[`${row}`]?.[`${col}`] ?? false

        if (!visible) {
          refSheet.value.scrollToY(row)
          refSheet.value.scrollToX(col)
          visible = refSheet.value.cellPositionsVisible[`${row}`][`${col}`]
        }

        left = visible.x
        width = visible.width
      }

      let vertVis = refSheet.value.rowPositionsVisible[`${row}`] ?? false
      if (!vertVis) {
        refSheet.value.scrollToY(row)
        vertVis = refSheet.value.rowPositionsVisible[`${row}`]
      }
      height = vertVis.height
      top = vertVis.y
      bottom = top + height
      right = left + width

      if (position === 'bottom') {
        top = bottom + 8
        bottom = null
      } else if (position === 'right') {
        top -= 10
        left = right - 1
      } else if (position === 'left') {
        top -= 10
        left = left - 400 - 4
      } else if (position === 'top') {
        top = top - 100
        height = 100
      }

      return {
        top,
        left,
        bottom,
        right,
        width,
        height
      }
    })
    const tooltipStyle = computed(() => {
      if (!tooltipText.value) {
        return {
          display: 'none'
        }
      }

      const { top, left } = tooltipPosition.value

      const vert = top ? 'top' : 'bottom'
      const horiz = left ? 'left' : 'right'

      return {
        display: 'flex',
        position: 'absolute',
        [vert]: `${tooltipPosition.value[vert]}px`,
        [horiz]: `${tooltipPosition.value[horiz]}px`,
        maxWidth: '400px',
        minWidth: `${tooltipPosition.value.width}px`,
        height: 'fit-content'
      }
    })

    const moveCell = async (col, row, focus = true) => {
      refSheet.value.moveCell(col, row)
      await c.throttle(() => {}, { delay: 500 })
      if (focus) refSheet.value.focusCell()
    }

    const startGuide = async () => {
      step.value = 0
    }

    const endGuide = () => {
      step.value = null
      tooltip.value = null
    }

    return {
      step,
      currentStep,
      startGuide,
      endGuide,
      tooltipStyle,
      tooltipText,
      prevStep,
      nextStep,
      completed
    }
  }
}
