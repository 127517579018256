<template>
  <!-- Root modal -->
  <Modal
    class="bg-flame-white"
    size="full"
    :full="true"
    :fixed="true"
    :hide-only="true"
    :closeable="true"
    :scrollable="false"
    :showCloseButton="false"
    :includeGutter="true"
    :hasFooter="false"
    :hasHeaderBorder="true"
    @close="close"
    :pt="{
      content: { class: '!bg-flame-white  dark:bg-surface-800' },
      footer: { class: '!bg-flame-white  dark:bg-surface-800' },
      header: { class: '!bg-flame-white  dark:bg-surface-800' },
      mask: { class: '!z-[2020]' }
    }"
    ref="modal"
  >
    <!-- Modal header -->
    <template #header>
      <div class="flex flex-row justify-between items-center w-full text-xl md:text-md">
        <div class="flex flex-row justify-start items-center text-pitch-black gap-4">
          <icon icon="box-open-full" />
          <p>Catalog</p>
        </div>

        <div class="mr-8" v-if="$refs.body">
          <template v-if="$refs.body?.selected?.length">
            <Btn size="lg" severity="bolster" :action="$refs.body.done">
              <font-awesome-icon icon="arrow-down-to-bracket" />
              Add {{ $refs.body?.selected?.length }} to estimate
            </Btn>
          </template>
        </div>
      </div>
    </template>

    <!-- Modal body -->
    <template #body>
      <TraverseBody
        ref="body"
        :startingCompany="$store.state.session.scope.company"
        :modal="$refs.modal"
      />
    </template>

    <!-- Modal footer -->
    <template #footer v-if="this.$refs.body?.selected.length">
      <btn
        severity="primary-black"
        :action="this.$refs.body?.done"
        :disabled="!this.$refs.body?.selected.length"
        class="text-nowrap h-14 w-full animate-fadeIn md:hidden"
      >
        <icon icon="basket-shopping-plus" />
        {{
          this.$refs.body?.selected.length
            ? `Add ${this.$refs.body?.selected.length} selected`
            : `Select items to add`
        }}
      </btn>
    </template>
  </Modal>
</template>

<script>
import TraverseBody from './TraverseBody.vue'

export default {
  components: {
    TraverseBody
  },
  methods: {
    async open() {
      await this.$refs.modal.open()
    }
  }
}
</script>
