<template>
  <div class="pdf-container-outer">
    <div class="pdf-container card">
      <div class="pdf-error" v-if="pdfError">
        There was an error and the data could not be loaded. Try again.
      </div>

      <VuePdfEmbed
        class="viewer-container"
        v-if="absoluteUrl"
        ref="pdf"
        :source="absoluteUrl"
        :page="currentPage"
        :scale="scaleLocal"
        @loaded="loaded"
        @progress="progress"
        @page-loaded="pageLoaded"
        @numpages="numPages"
        @loading-failed="error"
      >
        <template #loading>
          <LoadingIndicator />
        </template>
      </VuePdfEmbed>
    </div>

    <Container class="controls-container">
      <btn-group>
        <Btn class="default" @click="upscale()">
          <font-awesome-icon icon="plus" />
        </Btn>
        <Btn class="default" @click="downscale()">
          <font-awesome-icon icon="minus" />
        </Btn>
      </btn-group>

      <btn-group>
        <!--        <Btn class="info"-->
        <!--          v-if="currentPage > 0"-->
        <!--          @click="currentPage = Math.max(0, currentPage - 1)">Prev</Btn>-->
        <Btn
          :class="{
            info: currentPage === index + 1,
            default: currentPage !== index + 1
          }"
          v-for="(page, index) in allPages"
          :key="index"
          @click="currentPage = index + 1"
          >{{ index + 1 }}</Btn
        >
        <!--        <Btn class="info"-->
        <!--          v-if="currentPage < allPages.length - 1"-->
        <!--          @click="currentPage = Math.min(allPages.length - 1, currentPage + 1)">Next</Btn>-->
      </btn-group>
    </Container>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
import LoadingIndicator from '../ui/LoadingIndicator.vue'

export default {
  name: 'Pdf',
  emits: ['progress', 'pageLoaded', 'numPages', 'error'],
  data() {
    return {
      pdfError: null,
      loadProgress: 0,
      numberOfPages: 0,
      load: 0,
      currentPage: 1,
      loadedPage: 0,
      absoluteUrl: '',
      scaleLocal: 1,
      allPages: Array(0)
    }
  },
  props: {
    ...VuePdfEmbed.props
  },
  watch: {
    src() {
      this.getUrl()
    }
  },
  methods: {
    upscale() {
      this.scaleLocal += 0.1
    },
    downscale() {
      this.scaleLocal -= 0.1
    },
    async getUrl() {
      let src = this.src

      if (!/^https?:\/\//.test(this.src)) {
        src = await this.$store.dispatch('link', {
          path: this.src,
          query: { load: this.load }
        })
      }

      this.absoluteUrl = src
    },
    nextPage() {
      if (this.currentPage < this.numberOfPages) {
        this.currentPage = this.currentPage + 1
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1
      }
    },
    reload() {
      this.$refs.loader.addLoading()
      this.loadProgress = 0
      this.load += 1
      this.$refs.loader.addLoading()
    },
    print() {
      return this.$refs.pdf.print()
    },
    loaded(...args) {
      console.log('loaded handler', args)
      this.$refs.loader.endLoading()
    },
    progress(n) {
      this.loadProgress = n
      this.$emit('progress', n)
    },
    pageLoaded(p) {
      console.log('page loaded handler', p)
      this.loadedPage = p
      this.$emit('pageLoaded', p)
    },
    numPages(n) {
      console.log('num pages handlers', n)
      this.numberOfPages = n
      this.allPages = Array(n)
      this.$emit('numPages', n)
    },
    error(e) {
      this.$refs.loader.endLoading()
      this.pdfError = e
      this.$emit('error', e)
    }
  },
  mounted() {
    // this.$refs.loader.addLoading();
    this.getUrl()
  },
  components: { LoadingIndicator, VuePdfEmbed }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.viewer-container {
  #viewerContainer {
    div.page {
      > .textLayer {
        display: none;
        position: absolute !important;
      }
    }
  }
}

.pdf-container-outer {
  .controls-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2em;

    /*column-gap: 1em;*/
    > *:not(:last-child) {
      margin-right: 1em !important;
    }
  }
  position: relative;
  overflow: hidden;
  max-width: 90vw;
}
.pdf-container {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid $cool-gray-500;
  margin: 0.5em 0px 1.5em 0px;
  position: relative;
  max-width: 90vw;
  overflow: scroll;
  // @extend %scrollbarBlue;

  .pdf-error {
    position: absolute;
    height: 100px;
    width: 200px;
    top: calc(50% - 50px);
    left: calc(50% - 100px);
    border-radius: 15px;
    line-height: 1.1;
    background: rgba(0, 0, 0, 0.5);
    color: $flame-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    padding: 2em;
  }
  .pdf-doc {
    position: relative;
    .pdf-nav {
      position: absolute;
      bottom: 10px;
      font-size: 2em;
      opacity: 0.6;
      color: $green-500;
      &.prev-page {
        left: 20px;
      }
      &.next-page {
        right: 20px;
      }
      &:hover {
        opacity: 1;
        color: $green-500;
      }
    }
  }
}
</style>
