<template>
  <div
    :data-category="dataLabel"
    :class="{
      selected: selected || (parentSelected && searchPhrase),
      downstream: (selected || parentSelected) && searchPhrase
    }"
    class="group mr-4 !pt-1 select-none"
  >
    <!-- Parent title -->
    <div
      v-if="showInitial"
      :data-label="dataLabel"
      class="cursor-pointer relative flex flex-row items-center justify-between py-1.5 px-2 transition rounded relative"
      :class="selected ? 'bg-cement-300 hover:bg-cement-200' : 'hover:bg-cement-200'"
    >
      <div class="flex items-center gap-2 h-full !w-[100%]">
        <!-- Expand / hide button -->
        <a
          @click="toggleCategory(false)"
          class="flex justify-center items-center rounded-full transition cursor-pointer px-1 h-6"
          :class="selected ? 'hover:bg-surface-300' : 'hover:bg-surface-200'"
        >
          <icon :icon="open ? 'chevron-down' : 'chevron-right'" class="text-surface-400 w-4" />
        </a>
        <a
          @click="toggleCategory(true)"
          class="flex items-center w-full cursor-pointer leading-tight"
        >
          <!-- Root title -->
          <template v-if="startAs && costTypeId === null">
            <div class="flex items-center gap-2">
              <!-- Shared icon -->
              <div
                v-if="isShared"
                class="flex items-center text-pitch-black-950 w-7"
                v-tooltip="'Shared items and assemblies.'"
              >
                <icon :icon="['fas', 'share-nodes']" class="text-lg" />
              </div>
              <div
                v-else
                class="flex items-center text-pitch-black-950 w-7"
                v-tooltip="'Your company catalog.'"
              >
                <icon :icon="['fas', 'shop']" class="text-md" />
              </div>
              {{ startAs }}
            </div>
          </template>
          <!-- Autocost title -->
          <template v-else-if="isAutoCostCategory">
            <div class="flex items-center leading-tight">
              <div
                v-if="startAs"
                class="flex items-center text-pitch-black-950 w-7"
                v-tooltip="
                  'Browse our curated collection of items, or gain access to thousands of industry items with an Autocost subscription.'
                "
              >
                <icon icon="svg:bolsterIconGrey" class="aspect-square h-4 p-0.5" />
              </div>
              {{ parentObject.cost_type_name }}
              <Tag v-if="startAs" class="ml-2 !border-blue-print !text-blue-print">New</Tag>
            </div>
          </template>
          <!-- Category title -->
          <template v-else>
            <!-- Shared category icon and counter (franchisor only) -->
            <div
              v-if="isSharedCategory && isFranchisor"
              class="flex flex-row items-center justify-center mr-3"
              :class="sharedCompaniesCount ? '' : 'text-surface-300'"
              v-tooltip="
                sharedCompaniesCount
                  ? `Shared with ${parentObject.asSharedCompanies.length} ${sharedCompaniesCount === 1 ? 'company' : 'companies'}.`
                  : 'Not shared with any companies yet.'
              "
            >
              <icon :icon="['fas', 'share']" />
              <span class="fixed text-xs font-medium aspect-square ml-6 mb-4">
                {{ sharedCompaniesCount }}
              </span>
            </div>
            <!-- Title -->
            {{ parentObject.cost_type_name }}
          </template>
        </a>
        <!-- Loading spinner -->
        <Loader :loading="loading" />
      </div>
      <!-- Category options -->
      <a
        v-if="
          ((company && company === $store.state.session.scope.company) || isSuper) &&
          !isAutoCostCategory
        "
        class="flex justify-center items-center"
      >
        <btn-bar
          size="md"
          btnClass="!border-none !text-xl !text-surface-500 !p-0 !min-h-0"
          :actions="categoryActions"
          :collapse="true"
        />
      </a>
      <!-- Drag and drop target -->
      <draggable
        v-if="
          dragging &&
          ((company && company === $store.state.session.scope.company) ||
            (isSuper && !isAutoCostCategory))
        "
        class="absolute inset-0 border border-dashed rounded border-surface-300"
        :id="`${object.id || 'NULL'}`"
      >
        <template #item> </template>
      </draggable>
    </div>

    <!-- Children -->
    <template v-if="open && childParents.length">
      <div v-for="child in childParents" ref="children" :key="child.cost_type_id">
        <TraverseParent
          ref="child"
          class="ml-4 !mr-0 pl-2 border-l-[2px] group-hover:border-cement-300 border-transparent transition"
          :costTypeId="child.cost_type_id"
          :value="value"
          :object="child"
          :filters="childfilters"
          :company="company"
          :selected-company="selectedCompany"
          :search-phrase="searchPhrase"
          :parentSelected="selected || (parentSelected && searchPhrase)"
          @open="childOpenedOrClosed"
          @input="inputHandler"
          @deleted="onChildDeleted"
        />
      </div>
    </template>

    <!-- Share modal -->
    <modal
      ref="shareModal"
      size="md"
      :full="false"
      :clickAway="false"
      :expandable="false"
      :scrollable="true"
    >
      <template #header>
        <icon icon="share" />
        <h1>Share category</h1>
      </template>
      <template #body>
        <TraverseFranchiseShare :object="object" :modal="$refs.shareModal" />
      </template>
    </modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import eventBus from '../../../eventBus'
import TraverseFranchiseShare from '@/components/ui/Traverse/TraverseFranchiseShare.vue'
import AutoCost from '../../../../imports/api/AutoCost'

export default {
  name: 'TraverseParent',

  components: {
    draggable,
    TraverseFranchiseShare
  },

  emits: ['open', 'close', 'input'],

  data() {
    return {
      childParents: [],
      open: this.startOpen,
      fetched: 0,
      count: 0,
      loading: 0,
      dragging: false
    }
  },

  watch: {
    selected(b) {
      if (b) {
        if (!this.open && this.isAutoCostCategory) this.fetch()
        this.openList()
      }
    },
    async childParents() {
      this.childOpenedOrClosed()
    },
    open(open) {
      if (open && !this.fetched) this.fetch()
      this.childOpenedOrClosed()
    },
    searchPhrase(a, b) {
      if (a !== b && this.isAutoCostCategory && this.costTypeId === this.value) this.fetch()
    }
  },

  methods: {
    getStaticClientFilters() {
      const isSuperUser =
        this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
      if (!isSuperUser) {
        if (this.object.cost_type_id === 'co-4r87fy4xfyioc') {
          // Mamadou did this I do not condone this
          const arrayOfCategories = [
            'co-50xm7wi8bzksk', // Basement
            'co-50xm7wi8btogg', // Bathroom
            'co-a2695a5f24dvd0zp', // Custom Home
            'co-4r87fya68oe88', // Decks
            'co-50xm7wi888848', // Demolition
            'co-238b2743255szqnp', // Drywall, Insulation & Finishing Products
            'co-50xm7wi7v3wgs', // Fencing
            'co-6324fde825s7bcoi', // Flooring
            'co-4ryuhd972ww04', // Foundation
            'co-285cd7c424zlgld6', // Framing
            'co-50xm7wi5qsg08', // Garage
            'co-4r87fyck95kws', // Garage Doors
            'co-5b4244f425hq9fwu', // Hardscaping
            'co-h731qivhjz4v', // Kitchen
            'co-9f5d69e025gqm1rx', // Landscaping
            'co-e7e7a0e625s7auoz', // Lighting
            'co-5ce3e03225rtl007', // Painting
            'co-c4cbc4fe25ofr78n', // Permits
            'co-3c9035f025ofraz0', // Project Management
            'co-50xm7wi5qgow0', // Roofing
            'co-50xm7wm2nqckg', // Siding & Soffit
            'co-4rywg9ebso84s', // Success Templates
            'co-f19a322224ebejc5' // Windows
          ]
          return { cost_type_id: arrayOfCategories, cost_type_status: null }
        }
      }
      return this.filters
    },
    openList() {
      this.$emit('open')
      if (this.disableOpen) return false
      this.open = true
      return true
    },
    toggleList() {
      if (this.open) this.closeList()
      else this.openList()
    },
    closeList() {
      this.$emit('close')
      this.open = false

      return true
    },
    async openParent() {
      if (this.parent === this.costTypeId) {
        this.openList()
        return
      }

      this.$refs.child.forEach((child) => {
        child.openParent()
      })
    },
    inputHandler(parent, company) {
      this.$emit('input', parent, company)
    },
    async getChildCount() {
      if (!this.costTypeId) return 0

      const { object } = await this.$store.dispatch('ajax', {
        path: `/cost_type/getChildCount/${this.costTypeId}`
      })
      const { count } = object
      this.count = count
      return this.count
    },

    toggleCategory(select = true) {
      if (select)
        this.inputHandler(this.costTypeId || (this.limitToRoot ? 'NULL' : null), this.company)
      if (!this.disableOpen) this.toggleList()
    },

    childOpenedOrClosed() {
      this.$emit('open', open)
    },

    fetch() {
      if (this.isAutoCostCategory) this.fetchFromAutoCost()
      else this.fetchFromLibrary()
    },

    async throttleFetch() {
      await c.throttle(
        () => {
          this.fetch()
        },
        { delay: 500 }
      )
    },

    async fetchFromLibrary() {
      this.fetched = 1
      this.loading = 1
      const filters = this.getStaticClientFilters()

      if (!this.$store.state.session.user || !this.$store.state.session.user.user_is_super_user) {
        filters.cost_type_status = 'a||@'
      }

      const { set } = await this.$store.dispatch('CostType/search', {
        filters: {
          parent_cost_type_id: this.parentValue,
          cost_type_is_parent: 1,
          ...filters,
          company_id: this.company,
          asSharedCompanies:
            this.isShared && !this.isFranchisor && this.parentValue === 'NULL'
              ? `INSET${this.$store.state.session.scope.company}`
              : ''
        },
        order: [[this.company ? 'cost_type_name' : 'company_id', 'asc']]
      })
      // Filter by company id outside of /search dispatch
      // Needed because if company_id is null then data from all companies will be pulled
      this.childParents = set.filter((element) => `${element.company_id}` === `${this.company}`)
      this.loading = 0
    },

    async fetchFromAutoCost() {
      if (!this.object.hasSubCategories && !this.isSuper) {
        this.childParents = []
        return
      }
      if (this.childParents.length) return

      try {
        this.childParents = await AutoCost.getAutoCostCategories(
          this.$store,
          this.object.cost_type_id
        )
      } catch (e) {
        console.error(e)
      }
    },

    /**
     * rename
     * Rename this category.
     */
    async rename() {
      const object = this.object
      const name = await this.$store.dispatch('modal/prompt', {
        message: `Enter a new name for ${object.cost_type_name || object.assembly_name}`,
        default: object.cost_type_name || object.assembly_name
      })
      if (!name) return false
      const store = c.titleCase(object.type)
      const toSave = {
        type: object.type,
        [`${object.type}_id`]: object[`${object.type}_id`],
        [`${object.type}_name`]: name
      }
      await this.$store.dispatch(`${store}/partialUpdate`, {
        selected: [toSave],
        confirm: false
      })
      this.parentObject.cost_type_name = name
      this.throttleFetch()
      eventBus.$emit('traverse-reload')
      return true
    },

    /**
     * addCategory
     * Add a new category as a child of this category.
     */
    async addCategory() {
      const parent = this.parentObject
      const name = await this.$store.dispatch('prompt', { message: 'Name your category' })

      const { object } = await this.$store.dispatch('CostType/buildDefaultObject', {
        type: 'cost_type',
        embue: {
          parent_cost_type_id: parent?.cost_type_id,
          cost_type_is_parent: 1,
          cost_type_is_child: !parent ? 0 : 1,
          cost_type_name: name
        }
      })

      await this.$store.dispatch('CostType/save', {
        object,
        go: false,
        alert: false
      })
      await this.$nextTick()
      this.throttleFetch()
      eventBus.$emit('traverse-reload')
    },

    /**
     * delete
     * Delete this category.
     */
    async delete() {
      await this.$store.dispatch(`${c.titleCase(this.object.type)}/delete`, {
        id: this.object[`${this.object.type}_id`]
      })
      this.$emit('deleted')
      eventBus.$emit('traverse-reload')
    },

    /**
     *
     */
    async share() {
      this.$refs.shareModal.open()
    },

    /**
     * onChildDeleted
     * When a child category is deleted, re-fetch the parent.
     */
    onChildDeleted() {
      this.throttleFetch()
    }
  },

  computed: {
    isAutoCostCategory() {
      return AutoCost.isAutoCostCategory(this.costTypeId)
    },
    isSuper() {
      const session = this.$store.state.session
      return session.authorizedUser && session.authorizedUser.user_is_super_user
    },
    isShared() {
      return (
        (this.company && this.$store.state.session.scope.company !== this.company) ||
        this.$store.state.session.scope.franchisor
      )
    },
    isFranchisor() {
      return !!this.$store.state.session.scope.franchisor
    },
    isSharedCategory() {
      return this.isShared && this.parentObject.parent_cost_type_id === null
    },
    sharedCompaniesCount() {
      return this.parentObject.asSharedCompanies ? this.parentObject.asSharedCompanies.length : 0
    },
    dataLabel() {
      let label = ''
      if (this.startAs && this.costTypeId === null) {
        label = this.startAs
      } else if (this.parentObject.cost_type_name) {
        label = this.parentObject.cost_type_name
      } else {
        label = 'dynamic'
      }

      return String(label || '')
        .toLowerCase()
        .replace(/[^a-z]/g, '_')
    },
    selected() {
      return (
        (String(this.costTypeId) === String(this.value) ||
          (this.costTypeId === null && this.limitToRoot && this.value === 'NULL')) &&
        String(this.company) === String(this.selectedCompany)
      )
    },

    showInitial() {
      return this.costTypeId !== null || this.startAs || this.isAutoCostCategory
    },

    parentValue() {
      if (this.costTypeId === null) return 'NULL'
      return this.costTypeId
    },

    parentObject() {
      return this.object
    },

    categoryActions() {
      return [
        ...[
          {
            title: 'New category',
            action: this.addCategory,
            icon: 'level-down'
          }
        ],
        ...(!this.startAs
          ? [
              ...(this.$store.state.session.scope.franchisor &&
              this.object.parent_cost_type_id === null
                ? [
                    {
                      title: 'Share',
                      action: this.share,
                      icon: 'share'
                    }
                  ]
                : []),
              {
                action: this.rename,
                title: 'Rename',
                icon: 'pencil'
              },
              {
                title: 'Delete',
                action: this.delete,
                icon: 'trash'
              }
            ]
          : [])
      ]
    }
  },

  created() {
    eventBus.$on('category-added', () => {
      if (this.startOpen || this.open) this.fetch()
    })
    eventBus.$on('traverse-drag', (event) => {
      this.dragging = event
    })
  },

  beforeUnmount() {
    eventBus.$off('category-added', () => this.fetch())
  },

  async mounted() {
    if (this.selected) this.openList()
    if (!this.fetched && (this.startOpen || this.open)) this.fetch()
  },

  props: {
    childfilters: {
      default: () => ({})
    },
    costTypeId: {
      required: true
    },
    value: {
      required: true
    },
    company: {
      required: true
    },
    selectedCompany: {
      required: true
    },
    disableOpen: {
      type: Boolean,
      default: false
    },
    startAs: {
      type: String,
      default: ''
    },
    startOpen: {
      default: false
    },
    object: {
      default: () => ({})
    },
    filters: {
      default: () => ({})
    },
    searchPhrase: {
      default: ''
    },
    parentSelected: {
      default: 0
    },
    limitToRoot: {
      type: Boolean,
      default: false
    }
  }
}
</script>
