const costItemFieldSetters = {
  file_ids: ({ value }) => ({
    changes: {
      file_ids: c.makeArray(value)
    }
  }),
  cost_type_materials_purchase_price_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {}
  }) =>
    $store.dispatch('CostType/setMaterialsPurchasePrice', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      materialsPurchasePrice: value,
      equations,
      auditLocal: false,
      auditFull: false
    }),
  cost_type_is_addon_group: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {}
  }) =>
    $store.dispatch('CostType/setIsAddonGroup', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      isAddonGroup: value,
      equations,
      auditLocal: false,
      auditFull: false
    }),
  cost_type_is_variation_parent: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {}
  }) =>
    $store.dispatch('CostType/setIsVariationParent', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      isVariationParent: value,
      equations,
      auditLocal: false,
      auditFull: false
    }),
  assignee_ids: ({ $store, store, refId = null, object = null, value, equations = {} }) =>
    $store.dispatch('CostItem/setAssignees', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      assignees: value,
      equations,
      auditLocal: false,
      auditFull: false
    }),
  cost_item_qty_net: ({ $store, store, refId = null, object = null, value, equations = {} }) =>
    $store.dispatch('CostItem/setQty', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      qty: value,
      equations,
      auditLocal: false,
      auditFull: false
    }),
  cost_item_qty_net_base: ({
    $store,
    store,
    norm,
    refId = null,
    object = norm[refId],
    value,
    equations = {},
    equation
  }) => {
    return $store.dispatch('CostItem/setQty', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      qty: value * (object.quantity_multiplier || 1),
      equation,
      equations,
      auditLocal: false,
      auditFull: false
    })
  },
  purchase_cost_item_qty_net: ({
    $store,
    store,
    norm,
    refId = null,
    object = norm[refId],
    value,
    equations = {},
    equation = null
  }) => {
    if (value < object.cost_type_minimum_qty_net) {
      $store.dispatch('alert', {
        error: true,
        message: `${object.cost_type_name} has a minimum qty of ${c.format(object.cost_type_minimum_qty_net, 'number')}`
      })

      return {
        changes: {}
      }
    }

    const qtyPer = c.n(
      object.cost_type_materials_purchase_qty_per_unit === null
        ? 1
        : object.cost_type_materials_purchase_qty_per_unit,
      1
    )
    const wastage = 1 + c.n(object.cost_type_material_waste_factor_net, 0)
    const withoutWastage = c.divide(value, wastage)

    const net = withoutWastage * qtyPer

    return $store.dispatch('CostItem/setQty', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      qty: net,
      equation,
      equations,
      auditLocal: false,
      auditFull: false
    })
  },
  cost_item_materials_cost_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostItem/setMaterialsCost', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      materialsCost: value,
      holdPrice: true,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_item_profit_percentage: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) => {
    const targetMarkup = c.marginToMarkup(Math.min(99, value) / 100)
    return $store.dispatch('CostItem/setTargetMarkup', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      targetMarkup,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    })
  },
  cost_item_markup_net_adjusted: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostItem/setTargetMarkup', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      targetMarkup: value,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_type_has_labor: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setHasLabor', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      hasLabor: value,
      auditLocal: false,
      auditFull: false
    }),
  cost_type_has_materials: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setHasMaterials', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      hasMaterials: value,
      auditLocal: false,
      auditFull: false
    }),
  cost_type_is_subcontracted: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setIsSubcontracted', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      isSubcontracted: value,
      auditLocal: false,
      auditFull: false
    }),
  stage_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setStage', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      stage: value,
      confirm: false,
      auditLocal: false,
      auditFull: false
    }),
  tax_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setTax', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state?.[store]?.normalized?.[object?.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      tax: value,
      auditLocal: false,
      auditFull: false
    }),
  vendor_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setVendor', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      vendor: value,
      auditLocal: false,
      auditFull: false
    }),
  trade_type_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setTradeType', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      tradeType: value,
      auditLocal: false,
      auditFull: false
    }),
  weight_unit_of_measure_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setWeightUnitOfMeasure', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      weightUnitOfMeasure: value,
      auditLocal: false,
      auditFull: false
    }),
  purchase_unit_of_measure_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setPurchaseUnitOfMeasure', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      purchaseUnitOfMeasure: value,
      auditLocal: false,
      auditFull: false
    }),
  unit_of_measure_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setUnitOfMeasure', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      unitOfMeasure: value,
      auditLocal: false,
      auditFull: false
    }),
  labor_type_id: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setLaborType', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      laborType: value,
      auditLocal: false,
      auditFull: false
    }),
  labor_type_rate_net: ({ $store, store, refId = null, object = null, value }) =>
    $store.dispatch('CostType/setLaborRate', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      laborRate: value,
      auditLocal: false,
      auditFull: false
    }),
  cost_item_total_hours: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostType/setTotalHours', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      totalHours: value,
      holdPrice: true,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_matrix_labor_cost_net: ({
    $store,
    store,
    refId = null,
    norm,
    object = norm[refId],
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostType/setLaborCost', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      laborCost: value,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_item_profit_net: ({
    $store,
    store,
    norm,
    refId = null,
    object = norm[refId],
    value,
    equations = {},
    equation = null
  }) => {
    const targetMarkup = c.divide(
      value + object.cost_item_total_cost_net,
      object.cost_item_total_cost_net || 1
    )

    return $store.dispatch('CostItem/setTargetMarkup', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      targetMarkup,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    })
  },
  cost_item_total_cost_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostItem/setTotalCost', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      totalCost: value,
      auditLocal: false,
      auditFull: false,
      equation,
      equations
    }),
  cost_item_actual_total_cost_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostItem/setTotalActualCost', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      totalActualCost: value,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_item_labor_cost_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostItem/setLaborCost', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      laborCost: value,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_matrix_aggregate_cost_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostType/setAggregateCost', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      aggregateCost: value,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    }),
  cost_matrix_rate_net: ({ $store, store, refId = null, object = null, value, equations = {} }) =>
    $store.dispatch('CostType/setPrice', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      price: value,
      auditLocal: false,
      auditFull: false,
      equations
    }),
  cost_item_price_net: ({
    $store,
    store,
    refId = null,
    object = null,
    value,
    equations = {},
    equation = null
  }) =>
    $store.dispatch('CostItem/setTotalPrice', {
      store,
      object: refId
        ? undefined
        : {
            ...($store.state[store]?.normalized?.[object.refId] ?? {}),
            ...(object ?? {})
          },
      refId,
      totalPrice: value,
      auditLocal: false,
      auditFull: false,
      equations,
      equation
    })
}

export default {
  cost_item: costItemFieldSetters,
  cost_type: costItemFieldSetters,
  assembly: {
    file_ids: ({ value }) => ({
      changes: {
        file_ids: c.makeArray(value)
      }
    }),
    quote_subtotal_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/setTotalPrice', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        totalPrice: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_price_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/setTotalPrice', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        totalPrice: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_profit_net: ({
      $store,
      store,
      norm,
      refId = null,
      object = norm[refId],
      value,
      equations = {},
      equation = null
    }) => {
      const p = value
      const cost = object.quote_total_cost_net
      const margin = c.divide(p, cost)

      return $store.dispatch('Assembly/setTargetMarkup', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        targetMarkup: c.marginToMarkup(margin),
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      })
    },
    quote_total_cost_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/field', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        totalCost: value,
        auditLocal: false,
        auditFull: false,
        equations,
        holdPrice: false,
        equation
      }),
    quote_price_net_per_unit: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/setPrice', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        price: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_profit_net_per_unit: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/setProfit', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        profit: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_cost_net_per_unit: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/setCost', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        cost: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_markup_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Assembly/setTargetMarkup', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        targetMarkup: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    assembly_profit_percentage: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) => {
      const targetMarkup = c.marginToMarkup(Math.min(99, value) / 100)
      return $store.dispatch('Assembly/setTargetMarkup', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        targetMarkup,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      })
    }
  },
  quote: {
    file_ids: ({ value }) => ({
      changes: {
        file_ids: c.makeArray(value)
      }
    }),
    quote_price_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setNetPrice', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        netPrice: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_price_gross: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setGrossPrice', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        grossPrice: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_markup_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setTargetMarkup', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        targetMarkup: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    targetMargin: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setTargetMargin', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        targetMargin: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_discount_percentage: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setDiscountPercentage', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        discountPercentage: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_discount_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setDiscountNet', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        discountNet: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    tax_id: ({ $store, store, refId = null, object = null, value }) =>
      $store.dispatch('Quote/setTax', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        tax: value,
        auditLocal: false,
        auditFull: false
      }),
    quote_profit_net: ({
      $store,
      store,
      refId = null,
      object = null,
      value,
      equations = {},
      equation = null
    }) =>
      $store.dispatch('Quote/setProfit', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        profit: value,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      }),
    quote_profit_percentage: ({
      $store,
      norm,
      store,
      refId = null,
      object = norm[refId],
      value,
      equations = {},
      equation = null
    }) => {
      const p = Math.min(0.99, value)
      const cost = object.quote_total_cost_net
      const profit = cost / (1 - p) - cost

      return $store.dispatch('Quote/setProfit', {
        store,
        object: refId
          ? undefined
          : {
              ...($store.state[store]?.normalized?.[object.refId] ?? {}),
              ...(object ?? {})
            },
        refId,
        profit,
        auditLocal: false,
        auditFull: false,
        equations,
        equation
      })
    }
  }
}
