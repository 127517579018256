<template>
  <card-list-item class="meta-list-item--outer-container">
    <div class="meta-list-item--container">
      <div class="meta-list-item--trash">
        <btn rounded class="more px-0 p-2" @click="() => $emit('remove')">
          <font-awesome-icon icon="remove" />
        </btn>
      </div>

      <field :placeholder="firstPlaceholder" :value="first" @input="(v) => change(v, 0)" />

      <field
        v-if="showSecond"
        placeholder="Value (optional)"
        :value="second"
        @input="(v) => change(v, 1)"
      />
    </div>
  </card-list-item>
</template>

<script>
export default {
  name: 'MetaListItem',
  emits: ['remove', 'input'],
  methods: {
    change(v, index) {
      const current = [this.first, this.second]
      current.splice(index, 1, v)
      this.$emit('input', current)
    }
  },
  computed: {
    first() {
      return (typeof this.value[0] === 'string' && this.value[0]) || ''
    },
    second() {
      return (typeof this.value[1] === 'string' && this.value[1]) || ''
    }
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    showSecond: {
      default: true
    },
    firstPlaceholder: {
      default: 'Property name'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.meta-list-item--outer-container {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5em;
}
.meta-list-item--container {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  height: 100%;

  > * {
    flex: 1 1 50%;
  }

  .meta-list-item--trash {
    margin-right: 0.25em;
    flex: 0 0 auto;
  }

  input[type='text'] {
    border-radius: 0;
    border-color: transparent;
    padding: 0.25em 0.25em;
    background: transparent;

    &:not(:last-child) {
      font-weight: bold;
    }

    &:last-child {
      border-left: 1px solid $cool-gray-300;
    }
  }
}
</style>
