import eventBus from '../../../eventBus'

export default {
  computed: {
    files() {
      return ((this.object && this.object.aoFiles) || []).map(
        (r) => this.$store.state[this.store].normalized[r]
      )
    },
    fileUrls() {
      return c.uniq(this.filesListWithUrl.map((f) => f.url))
    },
    thumbUrls() {
      return c.uniq(this.filesListWithUrl.map((f) => f.thumb))
    },
    filesListWithUrl() {
      let aoImages = []
      if (this.object && this.object.aoImages) {
        aoImages = this.object.aoImages.map((image) => {
          if (this.object.live_price_reference) {
            const cloudFrontUrl = `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${image}`
            return {
              thumb: cloudFrontUrl,
              url: cloudFrontUrl
            }
          }
          return {
            thumb: image.file_url,
            url: image.file_url
          }
        })
      }
      const fileIds = this.fileIds.map((id) => ({
        id,
        url: c.link(`file/view/${id}`, {}, true, _.getStorage('scope')),
        thumb: c.link(
          `file/pic/thumb/${id}`,
          { size: 200, square: true },
          true,
          _.getStorage('scope')
        )
      }))
      return [...aoImages, ...fileIds]
    },
    fileIds: {
      get() {
        return c.uniq(this.itemPictures.map((f) => f.file_id))
      },
      set(ids) {
        eventBus.$emit('PresentationModifyItem', {
          refId: this.object.refId,
          changes: {
            file_ids: ids
          }
        })
      }
    },
    images: {
      get() {
        return (this.object.aoImages || []).map((image) => ({
          type: 'file',
          ...image,
          file_is_image: true,
          file_id: null,
          file_name: image.file_name,
          thumbSrc: image.file_url,
          src: image.file_url
        }))
      },
      set(images) {
        eventBus.$emit('PresentationImages', {
          refId: this.object.refId,
          images
        })

        c.throttle(
          () => {
            if (this.autoSave && this.object && this.object.type) {
              this.saveImages(images)
            }
          },
          { key: this.object.refId, delay: 5000 }
        )
      }
    },
    itemPictures() {
      const ids = []
      const fileIds = Array.isArray(this.object.file_ids) ? this.object.file_ids : []

      return [...fileIds.map((id) => c.buildDefaultObject('file', { file_id: id }))].filter(
        (file) => {
          // Remove duplicates
          const id = String(file.file_id)
          const inc = !ids.includes(id)
          ids.push(id)
          return !c.isempty(id) && inc
        }
      )
    }
  }
}
