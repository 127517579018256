<template>
  <div class="flex justify-between w-full">
    <div class="payment-method--type flex items-center">
      <Checkbox
        v-if="!hideRadio"
        class="radio mr-4"
        v-model="localValue"
        :deselectedValue="0"
        :selected-value="method.virtual_account_id"
      />
      <font-awesome-icon
        v-if="!hideIcon"
        class="ml-4"
        :icon="['far', paymentMethodIcon(method.type)]"
      />
      <div :class="['flex flex-col justify-center payment-method--card', hideIcon ? '' : 'ml-4']">
        <span class="mb-1 font-black text-pitch-black">
          {{ method.name || method.bank_name }}
          <span
            v-if="method.default"
            class="bg-matcha-500 rounded-full ml-2 px-2 py-0.5 text-white text-sm font-bold"
            >Default</span
          >
        </span>
        <span class="flex">
          <small>{{ method.routing_number }}</small>
          <small class="ml-3"> **** {{ method.last4 }} </small>
        </span>
      </div>
    </div>
    <div>
      <Btn
        @click="() => $emit('onDelete', method.id)"
        v-if="!hideDelete && !method.default"
        v-tooltip="'Delete bank account'"
        class="bg-white"
      >
        <font-awesome-icon icon="trash" />
      </Btn>
      <Btn
        @click="() => $emit('onDefault', method.id)"
        v-if="!method.default && !hideDefault"
        v-tooltip="'Set bank account as default'"
        class="bg-white"
      >
        <font-awesome-icon icon="square-check" />
      </Btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BankAccountPaymentMethod',
  props: {
    method: {
      type: Object,
      required: true
    },
    value: {
      type: Number
    },
    hideRadio: {
      type: Boolean,
      default: false
    },
    hideDefault: {
      type: Boolean,
      default: true
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    hideDelete: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onDelete', 'onDefault', 'input'],
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    localValue() {
      this.$emit('input', this.localValue)
    },
    value() {
      if (this.value !== this.localValue) {
        this.localValue = this.value
      }
    }
  },
  methods: {
    /**
     * Lookup the credit card icon to display
     */
    paymentMethodIcon(type) {
      if (type === 'card') {
        return 'credit-card'
      }
      if (type === 'bank_account' || type === 'acss_debit') {
        return 'university'
      }
      return 'university'
    }
  }
}
</script>
