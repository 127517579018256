<template>
  <div>
    <Wizard :steps="steps" class="pt-8">
      <WizardStep>
        <CounterpartyOnboardingOwner @warning="setWarning" :errors="errors" :owner="owner" />
      </WizardStep>

      <WizardStep>
        <CounterpartyOnboardingAddress
          ref="ownerAddress"
          reference="owner"
          :errors="errors"
          :counterparty="{
            owner
          }"
        />
      </WizardStep>

      <WizardStep>
        <div class="flex justify-center mt-4">
          <card class="max-w-md mx-auto p-6">
            <div class="text-center">
              <h3 class="pb-2">Verify Identity</h3>
              <p class="pt-3 pb-4">
                To complete your account and unlock the power of instant payments, owner
                verification is required. The upload of a passport or license is needed.
              </p>
              <DocumentVerification v-if="owner" @verified="onDocumentVerified" :owner="owner" />
            </div>
          </card>
        </div>
      </WizardStep>

      <WizardStep>
        <h5 class="mb-1 mt-4">Personal details</h5>
        <ul class="list-group">
          <li class="list-group-item flex justify-between">
            <span class="text-muted">First name:</span>
            <span>{{ owner.first_name }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Last name:</span>
            <span>{{ owner.last_name }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Email:</span>
            <span>{{ owner.email }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Date of birth:</span>
            <span>{{ owner.dob.day }}/{{ owner.dob.month }}/{{ owner.dob.year }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Is Executive</span>
            <span>{{ owner.relationship.executive }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Is Owner (25% or more)</span>
            <span>{{ owner.relationship.owner }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Title</span>
            <span>{{ owner.relationship.title }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Ownership percentage</span>
            <span>{{ owner.relationship.percent_ownership }}</span>
          </li>
        </ul>

        <h5 class="mb-1">Address</h5>
        <ul class="list-group">
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Address:</span>
            <span>{{ owner.address.line1 }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">City:</span>
            <span>{{ owner.address.city }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">State:</span>
            <span>{{ owner.address.state }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">Country:</span>
            <span>{{ owner.address.country }}</span>
          </li>
          <li class="list-group-item flex justify-between">
            <span class="text-muted">ZIP code:</span>
            <span>{{ owner.address.postal_code }}</span>
          </li>
        </ul>
      </WizardStep>
    </Wizard>

    <warning class="mt-4 sm" v-if="warning">
      <template #title>{{ warning }}</template>
    </warning>

    <danger class="mt-5 sm" v-if="errors && errors.length > 0">
      {{
        `Please fill out the following fields: ${errors
          .toString()
          .replaceAll('.', ' ')
          .replaceAll('_', ' ')
          .replaceAll(',', ', ')}`
      }}
    </danger>
    <danger class="mt-5" v-if="error">
      {{ error }}
    </danger>
  </div>
</template>

<script>
import CounterpartyOnboardingOwner from './CounterpartyOnboardingOwner.vue'
import CounterpartyOnboardingAddress from './CounterpartyOnboardingAddress.vue'
import DocumentVerification from '@/components/payments/verification/DocumentVerification.vue'
import OnboardingMixin from './OnboardingMixin'
import Wizard from '../../../ui/wizard/Wizard.vue'
import WizardStep from '../../../ui/wizard/WizardStep.vue'

export default {
  name: 'OwnerOnboarding',
  emits: ['success'],
  data() {
    return {
      steps: [
        {
          label: 'Owner details',
          action: this.beforeNext
        },
        {
          label: 'Owner address',
          action: this.checkAddress
        },
        {
          label: 'Identity verification',
          action: this.verifyIdentity
        },
        {
          label: 'Review details',
          action: this.createOwner
        }
      ],
      startingStep: 1,
      loading: 1,
      step: 1,
      saveBeforeNext: false,
      error: null
    }
  },
  props: {
    owner: {
      type: Object
    }
  },
  components: {
    CounterpartyOnboardingOwner,
    CounterpartyOnboardingAddress,
    DocumentVerification,
    Wizard,
    WizardStep
  },
  mixins: [OnboardingMixin],
  computed: {
    valdationSchemas() {
      return [
        [
          'owner.first_name',
          'owner.last_name',
          'owner.phone',
          'owner.email',
          'owner.dob.month',
          'owner.dob.day',
          'owner.id_number',
          'owner.relationship.title',
          'owner.relationship.owner||owner.relationship.executive'
        ],
        [
          'owner.address.line1',
          'owner.address.city',
          'owner.address.state',
          'owner.address.country',
          'owner.address.postal_code'
        ],
        []
      ]
    }
  },
  methods: {
    createOwner() {
      this.$emit('success', this.owner)
      this.step = 1
    },
    checkAddress() {
      this.error = this.$refs.ownerAddress.validateAddress(this.owner.address)
      if (this.error === null) {
        return this.beforeNext(1)
      }
      return false
    },
    verifyIdentity() {
      // this.error = this.$refs.ownerAddress.validateAddress(this.owner.address);
      if (this.error === null) {
        return this.beforeNext(2)
      }
      return false
    },
    /**
     * After user has gone through the document verification reload
     */
    async onDocumentVerified() {
      await this.init()
      this.currentlyDue = null
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-onboarding {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $flame-white;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.owner-onboarding h4 {
  padding-left: 0 !important;
}
</style>
