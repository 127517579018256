<template>
  <Drop :hidePointer="true" ref="dropRef">
    <div
      @click="triggerSelector"
      class="rounded-sm mb-2 border-2 border-surface-200 bg-flame-white w-16 h-16 flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition"
    >
      <font-awesome-icon icon="folder-open" size="xl" class="text-surface-500" />
      <span class="leading-none text-primary block text-[8pt] text-center">Bolster drive</span>
    </div>
    <FileUpload
      ref="uploadRef"
      mode="basic"
      accept="image/*, text/*, video/*, audio/*, application/*"
      @uploader="onFilesSelected"
      :showUploadButton="false"
      :showCancelButton="false"
      :multiple="true"
      :auto="true"
      :customUpload="true"
      :maxFileSize="10000000"
      choose-label="Device"
      :pt="{
        uploadIcon: 'svg-inline--fa fa-xl text-surface-500',
        label: 'leading-none text-primary block text-[8pt] text-center',
        chooseButton:
          'rounded-sm text-primary border-2 border-surface-200 bg-flame-white w-16 h-16 flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition'
      }"
    >
      <template #uploadicon>
        <font-awesome-icon :icon="['fal', 'folder-arrow-up']" size="xl" class="text-surface-500" />
      </template>
    </FileUpload>
  </Drop>
  <div @click="handleAddClick" class="flex items-center h-full w-max ml-3">
    <div
      :class="
        'button--container relative gap-2 activity-channel-footer--send bg-transparent text-primary ' +
        'relative items-center inline-flex text-center align-bottom justify-center leading-[normal] p-1.5 text-sm size-8 max-w-8 ' +
        'max-h-8 min-w-8 min-h-8 bg-surface-900 hover:bg-cool-gray-900 hover:text-flame-white active:bg-pitch-black rounded-md ' +
        'focus:outline-none focus:outline-offset-0 transition duration-200 ease-in-out cursor-pointer overflow-hidden'
      "
    >
      <font-awesome-icon :icon="['fas', 'plus']" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import FileUpload from 'primevue/fileupload'
import Drop from '@/components/ui/Drop.vue'

// ====== Composables ====== //
const store = useStore()
// ======================== //

// ======== Emits ======== //
const emit = defineEmits(['added'])
// ======================== //

// ======== Refs ======== //
const uploadRef = ref(null)
const dropRef = ref(null)
// ======================== //

// ======== Methods ======== //
const onFilesSelected = async (data) => {
  emit('added', data.files)
}
const handleAddClick = (event) => {
  dropRef.value.open(event)
}
const triggerSelector = async () => {
  const { selected } = await store.dispatch('modal/selector', {
    type: 'file'
  })

  const files = await Promise.all(
    selected.map(async (fileObj) => {
      const fileUrl = c.link(`file/view/${fileObj.file_id}`)
      const fetchResp = await fetch(fileUrl)
      const fetchBlob = await fetchResp.blob()
      return new File([fetchBlob], fileObj.file_name, {
        type: fetchBlob.type
      })
    })
  )

  emit('added', files)
}
// ======================== //
</script>
