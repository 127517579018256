<template>
  <div class="quote-pres--container" v-if="!bodyLoading">
    <!-- Welcome message -->
    <mini-modal
      ref="messageWelcome"
      :showCloseButton="false"
      :closeable="true"
      closeClass="success"
      :scrollable="true"
      :full="true"
      size="full"
    >
      <Container>
        <CardSection class="mb-5 mt-0">
          <div class="coverHeader">
            <img :src="logo" style="max-width: 300px; max-height: 300px; border-radius: 5px" />

            <div class="quoteHeading">
              <span>{{ $f.capitalize(quote_name) }}</span>
              <span class="text-muted"
                >{{ change_order_name ? `(${change_order_name})` : '' }} •
                {{ $f.date(change_order_time_sent) }}</span
              >
            </div>
          </div>

          <div
            class="quote-pres--presenter my-2"
            :class="{
              'large-presenter': showLargeProfilePic
            }"
            v-if="currentScreen.showPresenter && showLargeProfilePic"
          >
            <div class="quote-pres--presenter-avatar">
              <div
                class="avatar profile-avatar"
                :style="`background-image: url(${userPic});`"
              ></div>
            </div>
            <div class="quote-pres--presenter-name">
              <span class="text-dark quote-pres--presenter-fname">{{ owner.user_fname }}</span>
              <span class="text-dark quote-pres--presenter-lname">{{ owner.user_lname }}</span>
            </div>
          </div>
        </CardSection>

        <CardSection class="coverLetterChangeOrder">
          <div
            v-if="coverLetter && coverLetter.length > 0"
            class="coverLetter"
            v-html="coverLetterProcessed"
          />

          <div v-if="change_order_message && coverLetter && coverLetter.length > 0"><hr /></div>

          <div v-if="change_order_message" class="changeOrderMessage">
            {{ change_order_message }}
          </div>
        </CardSection>

        <CardSection>
          <div>
            <btn class="lg info round block" @click="() => $refs.messageWelcome.close()">
              {{ l('See') }} {{ l(documentName) }}...
            </btn>
          </div>
        </CardSection>
      </Container>
    </mini-modal>

    <div>
      <!-- Edit toolbar -->
      <PresentationToolbar
        v-if="$store.getters.isCompanyUser && isInQuoteEditor"
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :type="type"
        :editing="editingLocal"
        @editing="(b) => (editingLocal = b)"
        v-model="subComponentInterface"
      >
        <slot>
          {{ editingLocal }}
        </slot>
      </PresentationToolbar>

      <!-- Contents -->
      <PresentationContents
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :isInQuoteEditor="isInQuoteEditor"
        :editing="editingLocal"
        :type="type"
        v-model="subComponentInterface"
      />
    </div>

    <!-- Editing -->
    <Sidebar
      v-model:visible="editingLocal"
      position="bottom"
      :modal="false"
      :dismissable="false"
      :showCloseIcon="false"
      :blockScroll="true"
      :pt="{
        root: 'max-h-screen quote-presentation-settings',
        content: '!pb-0',
        header: 'quote-presentation-settings--header !px-5 !py-0 h-14',
        mask: '!bottom-0 !top-auto quote-presentation-mask'
      }"
      :ptOptions="{ mergeProps: true }"
      style="height: auto"
    >
      <template #header>
        <QuotePresentationSettingsHeader
          v-if="$store.getters.isCompanyUser && editingLocal"
          v-bind="$props"
          :reference="refId"
          :store="storeName"
          :editing="editingLocal"
          @cancel="editingLocal = 0"
          :type="type"
          v-model="subComponentInterface"
        />
      </template>

      <QuotePresentationSettings
        v-if="$store.getters.isCompanyUser && editingLocal"
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :editing="editingLocal"
        @unsaved-changes="unsavedChangesHandler"
        @editing="(b) => (editingLocal = b)"
        :type="type"
        v-model="subComponentInterface"
      />
    </Sidebar>
  </div>
  <div
    v-else
    style="
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 100%;
    "
  >
    <LoadingIndicator />
  </div>
</template>

<script>
import Sidebar from 'primevue/sidebar'
import PresentationMixin from './PresentationMixin'
import PresentationToolbar from './PresentationToolbar.vue'
import LoadingIndicator from '../../ui/LoadingIndicator.vue'
import PresentationContents from './PresentationContents.vue'
import ObjectManipulator from '../../mixins/ObjectManipulator'
import QuotePresentationSettings from './QuotePresentationSettings.vue'
import QuotePresentationSettingsHeader from './QuotePresentationSettingsHeader.vue'
import TranslationMixin from './languages/TranslationMixin'
import eventBus from '../../../eventBus'

export default {
  name: 'QuotePresentation',

  mixins: [ObjectManipulator('quote', false), PresentationMixin, TranslationMixin],

  components: {
    QuotePresentationSettings,
    PresentationContents,
    LoadingIndicator,
    PresentationToolbar,
    Sidebar,
    QuotePresentationSettingsHeader
  },

  methods: {
    async unsavedChangesHandler(template = this.getTemplate()) {
      if (
        await this.$store.dispatch('modal/asyncConfirm', {
          message: 'Do you want to save changes to your presentation template?'
        })
      ) {
        console.log('chose yes')
        await this.saveTemplate(template)
      } else {
        await this.loadTemplate(this.presentation_template_id)
      }
    }
  },
  props: {
    isInQuoteEditor: {
      default: false
    }
  },
  mounted() {
    const sel = async () => {
      this.addLoading()
      if (
        this.$store.getters.isGuestUser &&
        String(this.$store.state.session.user.user_id) === String(this.client.client_user_id) &&
        (this.coverLetter || this.change_order_message)
      ) {
        c.throttle(() => {
          this.$refs.messageWelcome.open()
        })

        this.$store.dispatch('ChangeOrder/markMultiple', {
          markAs: 'seen',
          id: this.change_order_id,
          go: false,
          alert: false
        })
      }

      this.originalSettings = this.presentationSettings
    }

    if (!this.selected) {
      eventBus.$once(`${this.uid}-selected`, sel)
    } else {
      sel()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '@/theme/presentations/colors.scss';
@import '@/theme/presentations/mixins.scss';
@import '@/theme/presentations/variables/quote.scss';
@import '@/theme/presentations/structure/quote.scss';

$headingHeight: 50vh;

.element-replacer {
  height: 5em;
  width: 100%;
  background: transparent;
}
.quote-pres--container {
  min-height: 0;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  background-color: $flame-white;

  .quote-pres--fixed-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 5em;
    background: rgba($pitch-black, 0.8);
    z-index: $z-layout + 100;
    padding: 1em;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .quote-pres--fixed-nav-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 5em;
      height: 100%;

      &.quote-pres--fixed-btn-nav-prev {
        color: $cool-gray-300 !important;
        > a {
          background: $cool-gray-300 !important;
          color: $cool-gray-700 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $pitch-black !important;
            color: $flame-white !important;
          }
        }
      }

      &.quote-pres--fixed-btn-nav-next {
        color: $deep-red-800 !important;
        > a {
          background: $deep-red-400 !important;
          color: $deep-red-800 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $deep-red-800 !important;
            color: $flame-white !important;
          }
        }
      }
    }
  }
}

.coverHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .quoteHeading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: right;
    font-weight: bold;
    padding-left: 1em;
    min-width: 230px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.changeOrderMessage {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: left;
  white-space: pre-line;
}

.coverLetter {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: left;
  white-space: pre-line;
}

#app.small-format {
  .coverHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .quoteHeading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      min-width: 230px;
      padding-left: 0;
      padding-top: 1em;
    }
  }
}
</style>
