<template>
  <InputSwitch
    v-bind="$props"
    @input="emitValue"
    :modelValue="localValue"
    :false-value="offValue"
    :true-value="onValue"
  />
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import InputSwitch from 'primevue/inputswitch'
InputSwitch.compatConfig = { MODE: 3 }

const props = defineProps({
  ...InputSwitch.props,
  value: {
    required: true
  },
  onValue: {
    default: 1
  },
  offValue: {
    default: 0
  }
})

const emits = defineEmits(['input'])

const localValue = ref(props.value)

const emitValue = () => {
  if (localValue.value === props.onValue) {
    emits('input', props.offValue)
  } else {
    emits('input', props.onValue)
  }
}

watch(
  () => props.value,
  (newVal) => {
    localValue.value = newVal
  }
)
</script>

<style lang="scss" rel="text/scss"></style>
