<template>
  <div class="flex cursor-pointer" @click="onNotificationClick">
    <Avatar
      v-if="avatar"
      shape="circle"
      :label="avatar"
      class="mr-2"
      :pt="{
        root: 'inline-flex items-center justify-center h-10 w-10 rounded-full uppercase bg-transparent border'
      }"
    />
    <div class="flex-1">
      <p v-if="title" class="text-sm mb-2">{{ title }}</p>
      <p
        class="flex-1 relative text-xs break-all max-h-12 overflow-y-auto"
        v-html="formatMessage(message)"
      ></p>
    </div>
  </div>
</template>

<script setup>
import Avatar from 'primevue/avatar'

// ======== Props ======== //
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: null
  },
  message: {
    type: String,
    required: true
  },
  avatar: {
    type: String,
    required: false,
    default: null
  }
})
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['click'])
// ====================== //

// ======== Methods ======== //
const onNotificationClick = () => {
  emit('click')
}
const formatMessage = (message) => message.replaceAll(' ', '&nbsp;').replaceAll('\n', '<br>')
// ====================== //
</script>

<style lang="scss" scoped></style>
