<template>
  <spin-container ref="spinner" :loading="bodyLoading">
    <!-- Receipt details -->
    <div class="flex justify-between">
      <div>
        <div class="flex">
          <h2>{{ $f.currency(invoice_gross) }}</h2>
          <Chip class="ml-3" :label="invoiceStatus[0]" />
        </div>
        <p>
          {{ $f.date(invoice_time) }}
        </p>
      </div>
      <div v-if="invoice_id" class="flex flex-col items-end">
        <btn-bar :actions="invoiceActions" :collapse="false" />
        <p class="mt-2" v-if="invoice_time_sent">Sent on {{ $f.date(invoice_time_sent) }}</p>
      </div>
    </div>
    <!-- End receipt details -->
    <InvoicePresentationAmounts
      v-bind="$props"
      :reference="refId"
      :invoiceSettings="oSettings"
      :hideWarning="true"
      :hidePercentageOfAmount="true"
      :store="storeName"
      :type="type"
      v-model="subComponentInterface"
    />

    <card-section v-if="invoice_desc">
      <card>
        <p class="quote-pres--heading-description">
          {{
            invoice_desc.includes('is the invoice description')
              ? $t('default invoice description')
              : invoice_desc
          }}
        </p>
      </card>
    </card-section>

    <InvoicePresentationTotals
      v-bind="$props"
      :quote="quote"
      :invoiceSettings="oSettings"
      :reference="refId"
      :store="storeName"
      :type="type"
      :forceShowItemizedInvoicing="true"
      :isBundled="isBundled"
      v-model="subComponentInterface"
      :selectedItems="invoiceItems"
    />
  </spin-container>
</template>

<script>
import Chip from 'primevue/chip'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import CurrencyFilter from '../mixins/CurrencyFilter'
import InvoicePDFMixin from '../invoice/InvoicePDFMixin'
import eventBus from '@/eventBus'

import InvoicePresentationTotals from '../invoice/presentation/InvoicePresentationTotals.vue'
import InvoicePresentationAmounts from '../invoice/presentation/InvoicePresentationAmounts.vue'

import ItemizedMixin from '../invoice/itemized/ItemizedMixin'

export default {
  name: 'Receipt',
  mixins: [
    ObjectManipulator('invoice', true, 'Invoice'),
    BodyMixin,
    CurrencyFilter,
    InvoicePDFMixin,
    ItemizedMixin
  ],
  computed: {
    invoiceActions() {
      return [
        {
          icon: 'glyphicon glyphicon-download-alt',
          class: 'btn muted',
          action: () => this.downloadURL(this.docDownloadUrl, `${this.invoice_name}-invoice.pdf`),
          text: 'Download'
        }
      ]
    },
    invoiceStatus() {
      if (!this.invoice_status) return ['Uninvoiced', 'warning']
      switch (this.invoice_status) {
        case 'o':
          return ['Unpaid', 'danger']
        case '$':
        case 'p':
          return ['Pending', 'secondary']
        case 'e':
          return ['Paid', 'success']
        default:
          return ['', 'muted']
      }
    },
    isBundled() {
      const aggregatedPayments = parseInt(this.oSettings.aggregatedPayments, 10)
      return (this.oQuote && this.oQuote.quote_is_aggregated_payment) || aggregatedPayments
    }
  },
  components: {
    InvoicePresentationTotals,
    InvoicePresentationAmounts,
    Chip
  },
  async mounted() {
    eventBus.$on('selected', () => {
      // setup the settings
      this.setItemizedItems()
    })
  },
  beforeUnmount() {
    eventBus.$off('selected')
  }
}
</script>
