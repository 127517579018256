<template>
  <div
    class="file-list-container flex flex-wrap gap-2"
    :style="{
      position: 'static'
    }"
  >
    <draggable
      v-if="showList"
      class="flex flex-wrap gap-2"
      :class="{
        'empty-set': !files.length,
        'w-full max-w-full': files.length && heroFirst
      }"
      :is="allowDragging && !deviceStore.isTouch ? 'draggable' : 'div'"
      v-model="files"
      v-bind="dragOptions"
      item-key="id"
    >
      <template #item="{ element: file }">
        <div
          :class="[
            'rounded-sm border-2 bg-flame-white flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition relative',
            {
              'border-blue-print-500': highlightFirst && file.index === 0,
              'border-surface-200': !(highlightFirst && file.index === 0),
              'w-full max-w-full h-fit': heroFirst && file.index === 0,
              'w-16 h-16': !heroFirst || file.index !== 0
            }
          ]"
          @click.stop.prevent="() => click(file)"
          @mouseover="
            () => {
              if (/image/.test(file.file_type)) {
                previewedList.push(file.id)
                c.throttle(() => {
                  if ($refs[`preview-${file.id}`] && $refs[`preview-${file.id}`][0]) {
                    $refs[`preview-${file.id}`][0].open()
                  }
                })
              }
            }
          "
          @mouseout="
            () => {
              c.throttle(() => {
                if (
                  $refs[`preview-${file.id}`] &&
                  $refs[`preview-${file.id}`][0] &&
                  typeof $refs[`preview-${file.id}`][0].close === 'function'
                ) {
                  $refs[`preview-${file.id}`][0].close()
                }
              })
            }
          "
        >
          <div
            v-if="(!heroFirst || file.index !== 0) && !forceImage"
            class="absolute inset-0 background-cover"
            :ref="`image-${file.id}`"
            :style="{ background: `url(${file.thumbSrc})` }"
          >
            <div
              v-if="allowEdit"
              class="absolute inset-0 opacity-0 hover:opacity-100 flex justify-center items-center"
            >
              <font-awesome-icon icon="arrow-up-right" size="xl" />
            </div>
            <div class="name">
              {{ file.file_name }}
            </div>
          </div>

          <div v-else class="w-full h-fit">
            <div
              v-if="forceImage"
              class="absolute inset-0 opacity-0 hover:opacity-100 flex justify-center items-center"
            >
              <font-awesome-icon icon="arrow-up-right" size="xl" />
            </div>
            <img :src="file.src" class="max-w-full" />
          </div>

          <a
            v-if="allowRemove"
            class="absolute size-6 -top-2 -right-2 bg-black text-white rounded-full flex items-center justify-center z-10"
            @mouseover.stop="() => {}"
            @click.stop.prevent.capture="() => remove(file)"
          >
            <font-awesome-icon icon="remove" size="sm" />
          </a>
          <!-- preview drop -->
          <!--          <drop-->
          <!--            position="top left"-->
          <!--            :fixTo="$refs[`image-${file.id}`][0]"-->
          <!--            :ref="`preview-${file.id}`"-->
          <!--            v-if="previewedList.indexOf(file.id) > -1"-->
          <!--          >-->
          <!--            <div class="flex flex-column items-center justify-center">-->
          <!--              <img :src="file.src" class="preview-pic" />-->
          <!--              <small class="text-danger" v-if="file.id === 0 && highlightFirst">Primary</small>-->
          <!--            </div>-->
          <!--          </drop>-->
        </div>
      </template>

      <template #footer>
        <template v-if="allowCreate">
          <div
            v-tooltip="'Add an image/file'"
            @click="handleAddClick"
            class="rounded-sm border-2 border-surface-200 bg-flame-white w-16 h-16 flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition"
          >
            <font-awesome-icon
              :icon="showAddOptions ? 'minus' : 'plus'"
              size="xl"
              class="text-surface-500"
            />
          </div>
        </template>

        <!-- add button -->
        <template v-if="allowCreate && showAddOptions">
          <div
            @click="triggerSelector"
            class="rounded-sm border-2 border-surface-200 bg-flame-white w-16 h-16 flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition"
          >
            <font-awesome-icon icon="folder-open" size="xl" class="text-surface-500" />
            <span class="leading-none block text-[8pt] text-center">Bolster drive</span>
          </div>
          <div
            @click="triggerDevice"
            class="rounded-sm border-2 border-surface-200 bg-flame-white w-16 h-16 flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition"
          >
            <font-awesome-icon icon="laptop-mobile" size="xl" class="text-surface-500" />
            <span class="leading-none block text-[8pt] text-center">Your device</span>
          </div>
          <div
            @click="triggerCamera"
            v-if="deviceStore.hasCamera"
            class="rounded-sm border-2 border-surface-200 bg-flame-white w-16 h-16 flex flex-col justify-center items-center gap-1 p-1 cursor-pointer hover:border-primary transition"
          >
            <font-awesome-icon icon="camera" size="xl" class="text-surface-500" />
            <span class="leading-none block text-[8pt] text-center">Take picture</span>
          </div>
        </template>
      </template>
    </draggable>

    <template v-if="allowCreate">
      <file-upload
        ref="upload"
        :folder="startingFolder"
        :hoverOn="hoverOn"
        :placeholder="placeholder"
        v-if="allowUpload"
        @file-uploaded="fileUploaded"
        :showButton="false"
      />
    </template>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import FileUpload from './FileUpload.vue'

import pdfPic from '../../assets/pdf.png'
import excelPic from '../../assets/excel.png'
import wordPic from '../../assets/word.png'
import { useDeviceStore } from '@/stores/device.js'

Draggable.compatConfig = { MODE: 3 }

/**
 * Emits:
 * input (allFiles array)
 * added (filesAdded array)
 */
export default {
  name: 'FileList',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  emits: ['input'],
  data() {
    let startingFolderLocal = this.startingFolder

    if (!startingFolderLocal) {
      if (this.$store.state.session.scope.company) {
        startingFolderLocal = `company-${+this.$store.state.session.scope.company}`
      } else if (this.$store.state.session.scope.user) {
        startingFolderLocal = `user-${+this.$store.state.session.scope.user}`
      }
    }

    return {
      previewedList: [],
      pdfPic,
      excelPic,
      wordPic,
      startingFolderLocal,
      showAddOptions: false,
      dragOptions: {
        group: 'files',
        fallbackOnBody: true,
        filter: '.file-list-file.add-file, .remove'
      }
    }
  },
  methods: {
    handleAddClick() {
      this.showAddOptions = !this.showAddOptions
    },
    triggerDevice() {
      this.$refs.upload.upload()
    },
    triggerCamera() {
      this.$refs.upload.triggerCamera()
    },
    fileTypePic(file) {
      if (file.file_is_virtual) {
        if (file.file_virtual_object_format && file.file_virtual_object_format === 'excel') {
          return this.excelPic
        } else if (file.file_virtual_object_format && file.file_virtual_object_format === 'word') {
          return this.wordPic
        }
        return this.pdfPic
      }
      return null
    },
    previewDrop() {},
    emit(files) {
      let toEmit = []
      if (this.idList) {
        toEmit = files.map((file) => file.file_id)
      } else {
        toEmit = files
      }
      this.$emit('input', c.makeArray(toEmit))
    },
    click(file) {
      if (!this.allowEdit) return
      this.previewedList = []
      this.$store.dispatch('edit', {
        type: 'file',
        id: file.file_id
      })
    },
    remove(file) {
      if (this.allowRemove) {
        const files = this.files
        files.splice(this.files.indexOf(file), 1)
        this.emit(files)
      }
    },
    triggerSelector() {
      const joined = this.files.map((f) => `!${f.file_id}`).join('&&')
      const fileId = joined ? { file_id: joined } : {}
      this.$store
        .dispatch('modal/selector', {
          type: 'file',
          filters: {
            ...{ parent_file_id: this.startingFolderLocal },
            ...fileId
          }
        })
        .then(({ selected }) => {
          this.filesAdded(selected)
        })
    },
    fileIsInList(file) {
      return (
        this.files.find((f) => String(f.file_id) === String(file.file_id)) ||
        (file.file_is_virtual &&
          this.files.find(
            (f) => String(f.file_virtual_object_id) === String(file.file_virtual_object_id)
          ))
      )
    },
    fileUploaded(file) {
      if (!this.fileIsInList(file)) {
        this.filesAdded([file])
      } else {
        this.$store.dispatch('alert', { text: 'That file is already in the list.', error: true })
      }
    },
    filesAdded(files = []) {
      this.$emit('added', c.makeArray(files))
      this.files = c.imm([...this.files, ...c.makeArray(files)])
    }
  },
  computed: {
    files: {
      get() {
        const val = c.makeArray(this.value)
        let fileList = this.idList
          ? val.map((id, index) => ({ type: 'file', file_id: id, index }))
          : val

        fileList = fileList.map((file) => {
          let full
          let thumb
          if (!file.file_url && (file.file_is_virtual || file.file_is_signable)) {
            thumb = full = this.fileTypePic(file)
          } else {
            full = file.file_url || c.link(`file/view/${file.file_id}`)
            thumb = file.file_url || c.link(`file/pic/thumb/${file.file_id}`)
          }

          return {
            ...file,
            src: full,
            thumbSrc: thumb
          }
        })

        return fileList
      },
      set(files) {
        this.emit(files)
      }
    },
    c() {
      return c
    }
  },
  mounted() {},
  props: {
    forceImage: {
      type: Boolean,
      default: false
    },
    heroFirst: {
      type: Boolean,
      default: false
    },
    highlightFirst: {
      default: false,
      type: Boolean
    },
    idList: {
      default: false,
      type: Boolean
    },
    startingFolder: {
      default: null
    },
    value: {
      required: true
      // type: Array, // of objects, ie: aoFiles field can be string comma delineated
    },
    allowCreate: {
      required: false,
      default: true
    },
    allowUpload: {
      required: false,
      default: true
    },
    allowRemove: {
      required: false,
      default: true
    },
    allowEdit: {
      required: false,
      default: true
    },
    allowDragging: {
      required: false,
      default: true
    },
    showList: {
      default: true
    },
    placeholder: {
      default: 'Drop files'
    },
    hoverOn: {
      default: false
    }
  },
  components: { FileUpload, Draggable }
}
</script>

<style rel="stylesheet/scss" lang="scss">
$size: 4em;

.preview-pic {
  max-width: 400px;
  max-height: 300px;
}

.file-list-container {
  position: relative;

  .file-list-file.primary-file {
    > .file-image {
      border: 2px solid $deep-red-800 !important;
    }
  }
  .file-tray {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin: 0;
    .file-list-file {
      flex-shrink: 0;
      flex-grow: 0;
      width: $size;
      height: $size;
      margin-left: 0.25em;
      position: relative;

      .remove {
        position: absolute;
        top: -5px;
        right: -5px;
        background: rgba($pitch-black, 0.9);
        color: $flame-white;
        font-size: 20px;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-bottom: 3px;
        &:hover {
          background: $deep-red-800;
          cursor: pointer;
        }
      }
      .file-image {
        width: $size;
        height: $size;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;
        border: 1px solid $cool-gray-500;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px rgba($pitch-black, 0.1);
        background-repeat: none;
        background-size: cover !important;
        overflow: hidden;
        .expand {
          opacity: 0;
          font-size: 2em;
          font-weight: bold;
          display: flex;
          align-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-align: center;
          justify-content: center;
          color: $flame-white;
          .glyphicon {
            color: $flame-white;
            opacity: 0.7;
          }
        }
        .name {
          opacity: 0;
          width: 100%;
          position: absolute;
          bottom: 0.15em;
          left: 0.15em;
          right: 0.15em;
          text-align: center;
          width: 100%;
          text-overflow: ellipsis;
          margin: 0px;
          font-size: 0.6em;
          text-shadow: 1px 1px $flame-white;
          line-height: 0.9;
          white-space: nowrap;
        }

        &:hover {
          .expand,
          .name {
            cursor: pointer;
            opacity: 1;
          }
          .expand {
            background: rgba($pitch-black, 0.2);
          }
        }
      }
      &.add-file {
        .file-image {
          background: $cool-gray-600;
          transition: border 0.5s $cool-gray-600;
          .glyphicon {
            transition: color 0.5s;
            font-size: 2em;
            color: $cool-gray-600;
          }
        }
        &:hover {
          cursor: pointer;
          .file-image {
            border-color: $cool-gray-700;
            .glyphicon {
              color: $cool-gray-700;
            }
          }
        }
      }
    }
  }

  &.left {
    .file-tray {
      flex-direction: row-reverse;
      .file-list-file {
        margin-right: 0.25em;
        margin-left: 0;
      }
    }
  }
}
</style>
