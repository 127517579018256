<template>
  <div class="document-container">
    <iframe :src="viewerSrc"> </iframe>
  </div>
</template>

<script>
export default {
  name: 'OfficeDocViewer',
  data() {
    return {
      load: new Date().valueOf()
    }
  },
  computed: {
    viewerSrc() {
      return `https://view.officeapps.live.com/op/embed.aspx?load=${this.load}&src=${encodeURIComponent(this.absoluteViewUrl)}&wdAllowInteractivity=True&wdHideGridlines=False&wdHideHeaders=True&wdDownloadButton=False&wdInConfigurator=True&wdScale=0.7&wdHideSheetTabs=True`
    },
    absoluteViewUrl() {
      if (/^http/.test(this.src)) return this.src
      return c.link(this.src, { load: this.load })
    }
  },
  methods: {
    refresh() {
      this.load += 1
    },
    reload() {
      this.load += 1
    }
  },
  props: {
    src: {
      required: true
    }
  },
  components: {}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
document-container > iframe {
  width: 100%;
  min-width: 800px;
  min-height: 600px;
  border: 1px solid $cool-gray-500;
  border-radius: 5px;
}

@media (max-width: 768px) {
  document-container > iframe {
    min-width: 400px;
  }
}
</style>
