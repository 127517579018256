<template>
  <Container>
    <CardSection>
      <template #title> Custom construction stage </template>

      <CardList>
        <CardListField>
          <span>Name</span>

          <Field schema="stage:stage_name" v-model="stage_name" :validate="{ required: true }" />
        </CardListField>
        <CardListField>
          <span>Description</span>

          <Field
            type="textarea"
            v-model="stage_desc"
            schema="stage:stage_desc"
            placeholder="Describe what kind of construction stage this is"
          />
        </CardListField>
        <CardListField>
          <span
            >Keywords
            <small class="text-info">
              Comma-separated keywords for automated searching and suggestions
            </small></span
          >

          <Field v-model="stage_keywords" schema="stage:stage_keywords" />
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection>
      <CardList>
        <CardListField>
          <span>
            Order of operations - the stage this construction stage occurs after
            <small class="text-info">
              Choose a stage that your new construction stage will be placed after in the order of
              operations.
            </small>
          </span>

          <Choose
            schema="stage:stage_id"
            type="stage"
            :order="[['stage_order', 'asc']]"
            :customOptions="[
              {
                value: -1,
                text: 'Project start',
                html: 'Project start'
              }
            ]"
            :return-array="false"
            :multiple="false"
            v-model="after"
          />
        </CardListField>

        <CardListField>
          <Help>
            <template #before> Crew type </template>

            <p>The crew type is the type of worker that would do an item of this type.</p>

            <p>
              For example, rough plumbing and finishing plumbing might be two different item types,
              but in both cases the work would be done by the plumber crew type.
            </p>
          </Help>

          <Choose
            :validate="{ required: true }"
            schema="stage:trade_type_id"
            v-model="tradeTypeId"
          />
        </CardListField>

        <CardListField>
          <Help>
            <template #before> Default labor rate </template>

            <p>
              Choose a labor rate that will be associated with this construction stage. When you set
              this construction stage on an item that includes labor costs, this labor rate will
              automatically be selected.
            </p>

            <p>
              If you choose a publicly available labor rate, it will be indexed to your postal/zip
              code (North America only), and will be updated around every quarter or when special
              adjustments may be necessary. This will result in your item's price increasing
              automatically over time.
            </p>

            <p>
              If you do not want your item to automatically change, you must create your own labor
              rate or connect this to an existing labor rate that you created.
            </p>

            <p>
              In the list of labor rates, the ones with a star beside it belong to your company only
              so those will not update unless you change them later.
            </p>
          </Help>

          <Choose
            :allow-deselect="true"
            :order="[['company_id', 'desc']]"
            schema="labor_type:labor_type_id"
            v-model="labor_type_id"
          />
        </CardListField>
      </CardList>
    </CardSection>
  </Container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import eventBus from '../../eventBus'

export default {
  name: 'Stage',
  mixins: [
    ObjectManipulator(
      'stage' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */
    ),
    BodyMixin /* AutoSave */
  ],
  emits: ['saving', 'saved'],
  data() {
    return {
      insertAfter: -1,
      forceChangeAfter: 0
    }
  },
  methods: {
    async save(button, reset = false, alert = true, go = this.go, force = false) {
      try {
        await this.commit()
        this.beforeSave()

        if (!force && !(await this.validate())) {
          return false
        }

        this.addLoading()
        this.$emit('saving')
        eventBus.$emit('saving', { changes: this.changes, object: this.cast() })
        eventBus.$emit(`saving-${this.type}-${this.refId}`)
        eventBus.$emit(`saving-${this.type}`)

        this.addLoading()

        // Now save trade type
        let tradeType = {
          trade_type_id: this.trade_type_id
        }
        if (!this.trade_type_id) {
          const { object } = await this.$store.dispatch('TradeType/save', {
            selected: [
              {
                trade_type_name: this.trade_type_name,
                labor_type_id: this.labor_type_id
              }
            ],
            go: false,
            alert: false
          })

          tradeType = object
          this.trade_type_id = object.trade_type_id
        }

        // Now save labor type
        if (!this.labor_type_id && this.labor_type_rate_net) {
          const { object } = await this.$store.dispatch('LaborType/save', {
            selected: [
              {
                labor_type_name: this.labor_type_name || this.trade_type_name,
                labor_type_rate_net: this.labor_type_rate_net,
                trade_type_ids: [tradeType.trade_type_id]
              }
            ],
            go: false,
            alert: false
          })
          this.labor_type_id = object.labor_type_id
        } else if (this.labor_type_id) {
          const { object: lt } = await this.$store.dispatch('LaborType/resolveObject', {
            id: this.labor_type_id
          })

          let tradeTypeIds = c.makeArray(lt.trade_type_ids)
          tradeTypeIds.unshift(tradeType.trade_type_id)
          tradeTypeIds = c.uniq(tradeTypeIds)

          await this.$store.dispatch('LaborType/partialUpdate', {
            selected: [
              {
                labor_type_id: this.labor_type_id,
                type: 'labor_type',
                trade_type_ids: tradeTypeIds
              }
            ],
            go: false,
            alert: false
          })
        }

        const savePayload = await this.$store.dispatch(`${this.storeName}/save`, {
          refId: this.refId,
          go: false,
          reset: false,
          alert: false,
          changes: true,
          force
        })

        this.$store.dispatch('alert', {
          message: 'Custom construction stage saved!'
        })

        this.endLoading()

        await this.afterSave()
        this.isCheckingOutOldVersion = 0
        this.$emit('saved', { reset, alert, go, ...savePayload })
        eventBus.$emit('saved', savePayload)
        eventBus.$emit(`saved-${this.type}-${this.refId}`)
        eventBus.$emit(`saved-${this.type}`)
        return { reset, alert, go, ...savePayload }
      } catch (error) {
        eventBus.$emit(`error-${this.type}`, error)
        if (alert) {
          this.$store.dispatch('alert', {
            error: true,
            message: error.userMessage || 'An error occurred. Changes may not have been saved.'
          })
        }

        throw new Error(error.message)
      } finally {
        this.endLoading()
      }
    },
    afterSelect() {
      this.originalDimensionId = this.dimension_id
    },
    afterSave() {
      this.originalDimensionId = this.originalDimensionId || this.dimension_id
    },
    async setAfter(id) {
      this.addLoading()
      await c.throttle(async () => {
        const { object } = await this.$store.dispatch('Stage/search', {
          filters: {
            stage_id: id
          },
          limit: 1
        })

        if (!object) return // not found?

        if (String(object.stage_id) !== String(this.insertAfter)) {
          return // has changed since sending out
        }

        this.after_stage_name = object.stage_name
        this.stage_order = (object.stage_order || object.stage_id) + 1
      })
      this.endLoading()
    }
  },
  computed: {
    after: {
      get() {
        return this.after_stage_id || this.stage_order - 1
      },
      set(after) {
        const n = c.n(after)
        if (n > -1) {
          this.after_stage_id = n
          this.setAfter(n)
        } else {
          this.after_stage_id = -1
          this.stage_order = 0
          this.after_stage_name = 'Project start'
        }
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
